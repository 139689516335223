import { gql } from '@apollo/client'

export const GetXeroOrganisations = gql`
  query GetXeroOrganisations($connectionType: ConnectionType = XERO_BANKFEEDS) {
    organisations: getXeroOrganisations(connectionType: $connectionType) {
      id
      name
    }
  }
`
