import { gql } from '@apollo/client'

import { SelectAccountRadioGroupWithSearch } from 'pages/GlobalModals/AddCardModal/pages/shared/SelectAccountRadioGroupWithSearch/SelectAccountRadioGroupWithSearch'

export const GetDebitCardAccountsForCardCreation = gql`
  query GetDebitCardAccountsForCardCreation {
    getDebitCardAccountsV2(limit: 50) {
      accounts {
        __typename
        type
        ...SelectAccountRadioGroupWithSearchDebitCardAccountV2Fragment
      }
    }
  }
  ${SelectAccountRadioGroupWithSearch.fragments.DebitCardAccountV2}
`
