import { useCallback, useState } from 'react'
import {
  GetItemFilterInput,
  GetItemsPriceFilterColumnName,
} from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { ButtonFill, ButtonGhost, Flex } from '@npco/zeller-design-system'

import { AmountRange } from 'components/AmountRange/AmountRange'
import * as styled from 'components/MobileFilters/MobileFilters.styled'
import { translationsShared } from 'translations'

const MAX = 99999
const MIN = 0

type NextFilterInput = NonNullable<GetItemFilterInput> | null

interface FilterByPriceProps {
  nextFilterInput: NextFilterInput
  rangeDebounceDuration?: number
  setDefaultVisibilityState: () => void
  setNextFilterInput: (nextFilterInput: NextFilterInput) => void
}

export const FilterByPrice = ({
  nextFilterInput,
  rangeDebounceDuration = 300,
  setDefaultVisibilityState,
  setNextFilterInput,
}: FilterByPriceProps) => {
  const tShared = useTranslations(translationsShared)

  const [nextLocalFilterInput, setNextLocalFilterInput] =
    useState<NextFilterInput>(nextFilterInput)

  const [rangeInitialValues, setRangeInitialValues] = useState<
    [number, number]
  >(() => [
    nextLocalFilterInput?.priceFilter?.from
      ? nextLocalFilterInput.priceFilter.from / 10000
      : MIN,
    nextLocalFilterInput?.priceFilter?.to
      ? nextLocalFilterInput.priceFilter.to / 10000
      : MAX,
  ])

  const handleApply = useCallback(() => {
    setNextFilterInput(nextLocalFilterInput)
    setDefaultVisibilityState()
  }, [nextLocalFilterInput, setDefaultVisibilityState, setNextFilterInput])

  const handleClear = useCallback(() => {
    setRangeInitialValues([MIN, MAX])

    setNextLocalFilterInput((previous) => ({
      ...previous,
      // NOTE: the ui will show 0-99999, but we need to set null for these to be
      // classed as defaults
      priceFilter: null,
    }))
  }, [setNextLocalFilterInput])

  const handleRangeChange = useCallback(
    (changedRange) => {
      setNextLocalFilterInput((previous) => ({
        ...previous,
        priceFilter: {
          columnName: GetItemsPriceFilterColumnName.price,
          to: changedRange[1] * 10000,
          from: changedRange[0] * 10000,
        },
      }))
    },
    [setNextLocalFilterInput]
  )

  return (
    <Flex
      data-testid="items-mobile-filters-price"
      flexDirection="column"
      flexGrow={1}
    >
      <Flex flexDirection="column" flexGrow={1} marginTop="24px">
        <AmountRange
          debounceDuration={rangeDebounceDuration}
          initialValues={rangeInitialValues}
          onChange={handleRangeChange}
        />
      </Flex>
      <styled.MobileFiltersButtonWrapper>
        <ButtonGhost
          dataTestId="items-mobile-filters-clear"
          onClick={handleClear}
        >
          {tShared('clear')}
        </ButtonGhost>
        <ButtonFill
          dataTestId="items-mobile-filters-apply"
          onClick={handleApply}
        >
          {tShared('apply')}
        </ButtonFill>
      </styled.MobileFiltersButtonWrapper>
    </Flex>
  )
}
