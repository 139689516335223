import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { cashflowReportingAccountFragementDoc } from './cashflowReportingAccountFragement.generated'

const defaultOptions = {} as const
export type CashflowReportingAccountsListQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type CashflowReportingAccountsListQueryResponse = {
  __typename?: 'Query'
  getDebitCardAccountsV2: {
    __typename?: 'DebitCardAccountConnectionV2'
    accounts: Array<{
      __typename: 'DebitCardAccountV2'
      status: Types.DebitCardAccountStatus
      type: Types.DebitCardAccountType
      id: string
      name: string
      icon: {
        __typename?: 'Icon'
        colour: string | null
        letter: string | null
        animation: string | null
        image: string | null
        images: Array<{
          __typename?: 'Image'
          url: string
          size: Types.ImageSize
        }> | null
      } | null
    } | null>
  }
}

export const CashflowReportingAccountsListQuery = gql`
  query CashflowReportingAccountsListQuery {
    getDebitCardAccountsV2(limit: 50) {
      accounts {
        ...cashflowReportingAccountFragement
      }
    }
  }
  ${cashflowReportingAccountFragementDoc}
` as unknown as TypedDocumentNode<
  CashflowReportingAccountsListQueryResponse,
  CashflowReportingAccountsListQueryVariables
>

/**
 * __useCashflowReportingAccountsListQuery__
 *
 * To run a query within a React component, call `useCashflowReportingAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashflowReportingAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashflowReportingAccountsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCashflowReportingAccountsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CashflowReportingAccountsListQueryResponse,
    CashflowReportingAccountsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CashflowReportingAccountsListQueryResponse,
    CashflowReportingAccountsListQueryVariables
  >(CashflowReportingAccountsListQuery, options)
}
export function useCashflowReportingAccountsListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CashflowReportingAccountsListQueryResponse,
    CashflowReportingAccountsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CashflowReportingAccountsListQueryResponse,
    CashflowReportingAccountsListQueryVariables
  >(CashflowReportingAccountsListQuery, options)
}
export type CashflowReportingAccountsListQueryHookResult = ReturnType<
  typeof useCashflowReportingAccountsListQuery
>
export type CashflowReportingAccountsListQueryLazyQueryHookResult = ReturnType<
  typeof useCashflowReportingAccountsListQueryLazyQuery
>
export type CashflowReportingAccountsListQueryQueryResult = Apollo.QueryResult<
  CashflowReportingAccountsListQueryResponse,
  CashflowReportingAccountsListQueryVariables
>
