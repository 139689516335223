import { Dispatch, SetStateAction, useState } from 'react'
import TextTruncate from 'react-text-truncate'
import { ConnectionStatus, Source } from '@npco/mp-gql-types'
import { Box, DrawerBasic, Tabs } from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'

import { getIsMobileDevice } from 'utils/device'
import { getNonNullString } from 'utils/string'
import { DeviceDetails } from 'pages/Devices/DeviceDetails/DeviceDetails'
import {
  DEVICE_TAB_TYPE,
  usePaymentDeviceTabs,
} from 'pages/Devices/Devices.hooks'
import { DeviceWithSiteInfo } from 'pages/Devices/Devices.types'
import { LastReassignedDeviceType } from 'pages/Devices/hooks/useDeviceAssignment'
import { PosDetails } from 'pages/Devices/PosDetails/PosDetails'
import { ReassignDeviceModal } from 'components/ReassignDeviceModal/ReassignDeviceModal'
import { component, page } from 'translations'

import { ScreenSettings } from '../ScreenSettings/ScreenSettings'
import * as styled from './DeviceDrawer.styled'
import { useGetOraclePosConnectionStateQuery } from './graphql/getOraclePosConnectionState.generated'

export interface DeviceDrawerProps {
  selectedDevice: DeviceWithSiteInfo | null
  areDetailsOpen: boolean
  setAreDetailsOpen: Dispatch<SetStateAction<boolean>>
  setSelectedDevice: Dispatch<SetStateAction<DeviceWithSiteInfo | null>>
  handleReassign: ({ siteId, deviceId }: LastReassignedDeviceType) => void
  isSiteDevicesPage?: boolean
}

export const DeviceDrawer = ({
  selectedDevice,
  areDetailsOpen,
  setAreDetailsOpen,
  setSelectedDevice,
  handleReassign,
  isSiteDevicesPage = false,
}: DeviceDrawerProps) => {
  const [posSource, setPosSource] = useState<Source | null | undefined>(null)
  const { isModalOpen, openModal, closeModal } = useModalState()
  const isMobile = getIsMobileDevice(selectedDevice?.model)
  const { data: oraclePosData } = useGetOraclePosConnectionStateQuery()

  const isOraclePosConnected =
    oraclePosData?.getConnectionState?.status === ConnectionStatus.CONNECTED ||
    oraclePosData?.getConnectionState?.status ===
      ConnectionStatus.CONNECTED_WITH_ERROR

  const openTransferModal = (terminal: DeviceWithSiteInfo) => {
    setSelectedDevice(terminal)
    openModal()
  }

  const onDetailsClose = () => {
    setAreDetailsOpen(false)
  }

  const isPosDataAvailable = Boolean(posSource) || isOraclePosConnected
  const { selectedTab, tabs } = usePaymentDeviceTabs(
    areDetailsOpen,
    isMobile,
    isPosDataAvailable
  )

  return (
    <>
      <DrawerBasic
        title={
          <TextTruncate
            line={2}
            text={`${getNonNullString(
              selectedDevice?.name
            )} (${getNonNullString(selectedDevice?.siteName)})`}
          />
        }
        isOpen={areDetailsOpen}
        onClose={onDetailsClose}
        headerContent={
          <Box
            width={{
              _: isMobile ? '59px' : '100%',
            }}
          >
            <Tabs currentTab={selectedTab}>
              {tabs.map((tab) => (
                <Tabs.Item key={tab.value} {...tab} />
              ))}
            </Tabs>
          </Box>
        }
        overlayClassName="animated-drawer-overlay"
      >
        {selectedTab === DEVICE_TAB_TYPE.DETAILS && selectedDevice && (
          <DeviceDetails
            isSiteDevicesPage={isSiteDevicesPage}
            selectedDevice={selectedDevice}
            openTransferModal={openTransferModal}
            setSelectedDevice={setSelectedDevice}
            setPosSource={setPosSource}
            areDetailsOpen={areDetailsOpen}
          />
        )}
        {selectedTab === DEVICE_TAB_TYPE.POS_SETTINGS && selectedDevice && (
          <PosDetails
            device={selectedDevice}
            posSource={isOraclePosConnected ? Source.ORACLE_POS : posSource}
          />
        )}
        {selectedTab === DEVICE_TAB_TYPE.SCREEN_SETTINGS && selectedDevice && (
          <ScreenSettings terminal={selectedDevice} />
        )}
      </DrawerBasic>

      {selectedDevice && isModalOpen && (
        <styled.ModalWrapper
          key={selectedDevice.id}
          isOpen={isModalOpen}
          onCancel={closeModal}
        >
          <ReassignDeviceModal
            isSiteDevicesPage={isSiteDevicesPage}
            siteId={selectedDevice.siteId}
            terminalId={selectedDevice.id}
            modalTitle={`${
              component.modal.reassignTerminal.sitesTitle
            } - ${getNonNullString(selectedDevice.name)}`}
            modalDescription={page.terminals.reassignDeviceModal.description}
            handleReassign={(...args) => {
              handleReassign(...args)
              setAreDetailsOpen(false)
            }}
            closeModal={closeModal}
          />
        </styled.ModalWrapper>
      )}
    </>
  )
}
