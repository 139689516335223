import {
  createContext,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import {
  FeatureFlagsManager,
  type FeatureFlagsSource,
} from '@npco/utils-feature-flags'
import { LaunchDarklySource } from '@npco/utils-feature-flags-launch-darkly'
import { createFeatureFlagsEnvironment } from '@npco/utils-feature-flags-react'
import * as Sentry from '@sentry/react'

import { LAUNCH_DARKLY_ENV_KEY } from './const/envs'
import {
  FeatureFlagsLocalSource,
  writeFeatureFlagsLocalValues,
} from './FeatureFlagsLocalSource'
import { type FeatureFlags, FeatureFlagsSchema } from './FeatureFlagsSchema'

const defaultValues = FeatureFlagsSchema.parse({})

const Environment = createFeatureFlagsEnvironment<FeatureFlags>(defaultValues)

export type SourceKey = 'Remote' | 'Local'

export const FeatureFlagsMetaContext = createContext<
  Partial<Record<SourceKey, FeatureFlags | undefined>>
>({})

const deps = {
  getLaunchDarklyEnvKey: () => LAUNCH_DARKLY_ENV_KEY,
}

export { deps as FeatureFlagsProviderDeps }

export const FeatureFlagsProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element | null => {
  const [values, setValues] = useState<Partial<FeatureFlags>>()
  const [valuesMap, setValuesMap] = useState<
    Partial<Record<SourceKey, FeatureFlags | undefined>>
  >({})
  const { userData } = useLoggedInUser()
  const entityUuid = userData?.entityUuid

  const FeatureFlagsRemoteSource = useMemo(():
    | ReturnType<typeof LaunchDarklySource>
    | undefined => {
    const bail = (reason: string) =>
      // eslint-disable-next-line no-console
      console.warn(`[FeatureFlags.LaunchDarkly] Bailing. ${reason}`)

    if (window.Cypress) {
      bail('Disabled for Cypress tests!')
      return undefined
    }

    const envKey = deps.getLaunchDarklyEnvKey()
    if (!envKey) {
      bail('LAUNCH_DARKLY_ENV_KEY is not set!')
      return undefined
    }

    if (!entityUuid) {
      bail('Entity ID is not available!')
      return undefined
    }

    const context = { kind: 'entity', key: entityUuid }
    // eslint-disable-next-line no-console
    console.info(`[FeatureFlags.LaunchDarkly] Initialising.`, { context })
    return LaunchDarklySource({ envKey, context })
  }, [entityUuid])

  useEffect(() => {
    const sources: ((FeatureFlagsSource & { key: SourceKey }) | undefined)[] = [
      FeatureFlagsRemoteSource &&
        Object.assign(FeatureFlagsRemoteSource, { key: 'Remote' as const }),
      Object.assign(FeatureFlagsLocalSource, { key: 'Local' as const }),
    ]
    const disposer = FeatureFlagsManager(sources, {
      onValues: (valuesUnknown) => {
        const valuesResult =
          FeatureFlagsSchema.partial().safeParse(valuesUnknown)
        if (!valuesResult.success) {
          Sentry.captureException(
            new Error(`FeatureFlags(Values): Invalid values.`, {
              cause: valuesResult.error,
            })
          )
          return
        }
        const finalValues = valuesResult.data
        setValues(finalValues)
      },
      onSourceValues: (key, valuesUnknown) => {
        const valuesResult = FeatureFlagsSchema.partial().safeParse(
          valuesUnknown ?? {}
        )
        if (!valuesResult.success) {
          if (key === 'Remote') {
            Sentry.captureException(
              new Error(`FeatureFlags(SourceValues:${key}): Invalid values.`, {
                cause: valuesResult.error.format(),
              })
            )
          }
          if (key === 'Local') {
            writeFeatureFlagsLocalValues({})
            // eslint-disable-next-line no-console
            console.warn(`FeatureFlags(SourceValues:${key}): Invalid values.`, {
              cause: valuesResult.error.format(),
            })
          }
          return false
        }
        const finalValues = valuesResult.data
        setValuesMap((prevValuesMap) => ({
          ...prevValuesMap,
          [key]: finalValues,
        }))
        return true
      },
    })

    return () => {
      disposer()
    }
  }, [FeatureFlagsRemoteSource])

  useEffect(() => {
    const mergedValues = { ...defaultValues, ...values }
    Sentry.setContext('feature-flags', mergedValues)
  }, [values])

  if (!values) {
    return null
  }

  return (
    <Environment.FeatureFlagsProvider values={values}>
      <FeatureFlagsMetaContext.Provider value={valuesMap}>
        {children}
      </FeatureFlagsMetaContext.Provider>
    </Environment.FeatureFlagsProvider>
  )
}

export const { useFeatureFlags } = Environment

export const { FeatureFlagsProvider: MockedFeatureFlagsProvider } = Environment
