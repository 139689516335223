import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetCustomerItemPermissionsQueryVariables = Types.Exact<{
  customerUuid: Types.Scalars['ID']['input']
}>

export type GetCustomerItemPermissionsQueryResponse = {
  __typename?: 'Query'
  getCustomer: {
    __typename?: 'Customer'
    permissions: {
      __typename?: 'CustomerPermissions'
      allowItemManagement: boolean
      allowDiscountManagement: boolean
    } | null
  }
}

export const GetCustomerItemPermissions = gql`
  query GetCustomerItemPermissions($customerUuid: ID!) {
    getCustomer(customerUuid: $customerUuid) {
      permissions {
        allowItemManagement
        allowDiscountManagement
      }
    }
  }
` as unknown as TypedDocumentNode<
  GetCustomerItemPermissionsQueryResponse,
  GetCustomerItemPermissionsQueryVariables
>

/**
 * __useGetCustomerItemPermissionsQuery__
 *
 * To run a query within a React component, call `useGetCustomerItemPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerItemPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerItemPermissionsQuery({
 *   variables: {
 *      customerUuid: // value for 'customerUuid'
 *   },
 * });
 */
export function useGetCustomerItemPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerItemPermissionsQueryResponse,
    GetCustomerItemPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCustomerItemPermissionsQueryResponse,
    GetCustomerItemPermissionsQueryVariables
  >(GetCustomerItemPermissions, options)
}
export function useGetCustomerItemPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerItemPermissionsQueryResponse,
    GetCustomerItemPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCustomerItemPermissionsQueryResponse,
    GetCustomerItemPermissionsQueryVariables
  >(GetCustomerItemPermissions, options)
}
export type GetCustomerItemPermissionsQueryHookResult = ReturnType<
  typeof useGetCustomerItemPermissionsQuery
>
export type GetCustomerItemPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetCustomerItemPermissionsLazyQuery
>
export type GetCustomerItemPermissionsQueryResult = Apollo.QueryResult<
  GetCustomerItemPermissionsQueryResponse,
  GetCustomerItemPermissionsQueryVariables
>
