import { Formik } from 'formik'

import { SettingsPayments } from 'types/settings'
import { useSitePayments } from 'pages/Settings/SitePayments/SitePayments.hooks'
import {
  handleUpdateSiteResponse,
  prepareUpdateSitePaymentsPayloads,
} from 'pages/Settings/SitePayments/SitePayments.utils'
import { SettingsFormLayout } from 'layouts'
import { SpinnerWrapped } from 'components/Spinner'

import { SitePaymentsForm } from './SitePaymentForm/SitePaymentsForm'

export const SitePayments = () => {
  const { loadingUpdateSite, paymentsData, updateSite, siteData } =
    useSitePayments()
  const onSubmit = async (values: SettingsPayments) => {
    const { variables, updatedSiteDetails } =
      prepareUpdateSitePaymentsPayloads(values)

    const response = await updateSite({
      variables,
    })

    handleUpdateSiteResponse(
      Boolean(response?.data?.updateSite),
      siteData,
      updatedSiteDetails
    )
  }

  if (loadingUpdateSite || !siteData) {
    return <SpinnerWrapped variant="centre" />
  }

  return (
    <Formik<SettingsPayments>
      enableReinitialize
      initialValues={paymentsData}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, resetForm, submitForm }) => (
        <SettingsFormLayout
          dataTestId="site-payments"
          isSubmitButtonDisabled={isSubmitting}
          onBackButtonClick={resetForm}
          onSubmitButtonClick={submitForm}
        >
          <SitePaymentsForm siteData={siteData} />
        </SettingsFormLayout>
      )}
    </Formik>
  )
}
