import { useCallback } from 'react'
import { useReactiveVar } from '@apollo/client'
import { BREAKPOINT } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { AccountMobileFiltersList } from 'layouts/AccountLayout/AccountMobileFiltersList/AccountMobileFiltersList'
import { DashboardFiltersButton } from 'layouts/DashboardLayout/DashboardControls/DashboardFiltersButton/DashboardFiltersButton'
import { useAccountFiltersList } from 'components/DebitCardTransactionFilters/hooks/useAccountFiltersList/useAccountFiltersList'

import { useContactContext } from '../../../ContactContext/ContactContext'
import { useContactAccountTransactions } from '../../../hooks/useContactAccountTransactions/useContactAccountTransactions'
import { rvSelectedContact } from '../../../rv-deprecated/contacts'

export const AccountsFilterButtonDeps = {
  useContactContext,
  useIsMobileResolution,
}

export const AccountsFilterButton = () => {
  const { useContactContext, useIsMobileResolution } = AccountsFilterButtonDeps

  const { isAccountsFiltersVisible, setIsAccountsFiltersVisible } =
    useContactContext()

  const isMobileOrTablet = useIsMobileResolution(BREAKPOINT.MD)
  const selectedContact = useReactiveVar(rvSelectedContact)

  const defaultFilters = {
    selectedAccountID: undefined,
    selectedContactId: selectedContact?.id,
  }

  const { mobileFilters } = useAccountFiltersList({
    defaultFilters,
  })

  const {
    groupedTransactions,
    isLoadingTransactions,
    areFiltersInDefaultState,
  } = useContactAccountTransactions()

  const handleOnClick = useCallback(() => {
    setIsAccountsFiltersVisible((prev) => !prev)
  }, [setIsAccountsFiltersVisible])

  const isDisabled =
    isLoadingTransactions ||
    (groupedTransactions.length === 0 && areFiltersInDefaultState)

  if (isMobileOrTablet) {
    return (
      <AccountMobileFiltersList filters={mobileFilters} disabled={isDisabled} />
    )
  }

  return (
    <DashboardFiltersButton
      disabled={isDisabled}
      isActive={isAccountsFiltersVisible}
      onClick={handleOnClick}
      showIndicator={!areFiltersInDefaultState}
    />
  )
}
