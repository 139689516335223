import {
  BodyDefaultTypography,
  BodySmallTypography,
  ButtonLink as ZellerButtonLink,
  Flex,
  H5Style,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const FlexItemLabel = styled(Flex)`
  ${H5Style}
  min-width: 25%;
  gap: 4px;
`

export const divItemValue = styled.div`
  ${BodyDefaultTypography}
  text-align: right;
`

export const divTooltipContent = styled.div`
  ${BodySmallTypography}
  max-width: 192px;
  text-align: center;
`

export const ButtonLink = styled(ZellerButtonLink)`
  align-self: flex-start;
`

export const CategoryIconContainer = styled.div`
  position: relative;
  top: 2px;
`
