import { gql } from '@apollo/client'

export const GetSims = gql`
  query GetSims($limit: Int!, $nextToken: ID) {
    getSims(limit: $limit, nextToken: $nextToken) {
      sims {
        id
        state
        subscriptionTermsAccepted
        entityUuid
        deviceUuid
        deviceName
        siteUuid
        siteName
        nextBillingDate
        billingAmount
        billingStatus
        lastSuccessfulChargeDate
        subscriptionTermsAcceptedDate
        deactivateDate
      }
      nextToken
    }
  }
`

export const ExportSimBillingStatement = gql`
  query ExportSimBillingStatement(
    $filter: BillFilterInput!
    $format: SimBillingStatementExportFormat!
  ) {
    exportSimBillingStatement(filter: $filter, format: $format) {
      downloadLink
      expire
    }
  }
`

export const GetSimBills = gql`
  query GetSimBills(
    $filter: BillFilterInput
    $limit: Int!
    $nextToken: SimBillNextTokenInput
  ) {
    getSimBills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      bills {
        amount
        billingAccountName
        simId
        billingAccountUuid
        siteName
        siteUuid
        status
        taxAmount
        timestamp
      }
      nextToken {
        id
        type
        entityUuid
      }
    }
  }
`

export const GetBillingAccount = gql`
  query GetBillingAccount {
    getBillingAccount {
      id
      accountBsb
      accountName
      accountNumber
    }
  }
`
