import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { TabItemProps } from '@npco/zeller-design-system'

import { useEztaTermsOfService } from 'hooks/useEztaTermsOfService/useEztaTermsOfService'
import { ROOT } from 'const/routes'
import { isNotNull } from 'utils/common'

export const useDashboardTabs = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { hasRejectedTermsOfService } = useEztaTermsOfService()
  const { userRole } = useLoggedInUser()

  const isAdmin = userRole === CustomerRole.ADMIN

  const tabs: TabItemProps[] = useMemo(
    () =>
      [
        {
          id: 'payments-overview-tab',
          children: 'Payments',
          value: ROOT.PORTAL.OVERVIEW.PAYMENTS.path,
          onClick: () => navigate(ROOT.PORTAL.OVERVIEW.PAYMENTS.relative),
        },
        hasRejectedTermsOfService || !isAdmin
          ? null
          : {
              id: 'finance-overview-tab',
              children: 'Finance',
              value: ROOT.PORTAL.OVERVIEW.FINANCE.path,
              onClick: () => navigate(ROOT.PORTAL.OVERVIEW.FINANCE.relative),
            },
      ].filter(isNotNull),
    [hasRejectedTermsOfService, navigate, isAdmin]
  )

  return {
    tabs,
    currentTab: pathname,
  }
}
