import { useMemo } from 'react'
import { WatchQueryFetchPolicy } from '@apollo/client'

import { useGetEntityForCardListLayoutQuery } from '../graphql/getEntityForCardListLayout.generated'

interface UseGetEntityForCardsListLayoutProps {
  fetchPolicy?: WatchQueryFetchPolicy
}

export const useGetEntityForCardsListLayout = ({
  fetchPolicy = 'cache-first',
}: UseGetEntityForCardsListLayoutProps = {}) => {
  const {
    loading: isLoadingEntity,
    error,
    data,
  } = useGetEntityForCardListLayoutQuery({
    fetchPolicy,
  })

  const entity = useMemo(() => {
    return data?.getEntity
  }, [data?.getEntity])

  return {
    isLoadingEntity,
    error,
    entity,
  }
}
