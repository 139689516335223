import dayjs from 'utils/dayjs'
import type { GetInvoices_getInvoices_invoices as Invoices } from 'types/gql-types/GetInvoices'

export type InvoiceYearGroup = {
  [key: string]: Invoices[]
}

const groupItemsByYear = (items: null | Invoices[] = []): Invoices[][] => {
  if (items === null || items.length === 0) return []

  const byYear: InvoiceYearGroup = {}

  items.forEach((item) => {
    const year: string = (
      item.createdTime ? dayjs.unix(item.createdTime) : dayjs()
    ).format('YYYY')

    if (!byYear[year]) {
      byYear[year] = []
    }

    byYear[year].push(item)
  })

  return Object.keys(byYear)
    .sort((a, b) => Number.parseInt(b, 10) - Number.parseInt(a, 10))
    .map((key) => byYear[key])
}

export const groupInvoicesByYear = groupItemsByYear
