import { useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import {
  AvatarBasic,
  Box,
  Flex,
  PillSize,
  PillYellow,
} from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { ROOT } from 'const/routes'
import { AVATAR_BADGE_SIZE } from 'types/common'
import { translations } from 'translations/shared.i18n'

import { TagPills } from '../components/TagPills/TagPills'
import { getContactAvatarBadgeOptions } from '../Contact/ContactGeneral/ContactGeneral.util'
import * as styled from '../Contacts.styled'
import { ContactsContentWrapper } from '../Contacts.styled'
import { getSortedSubContacts } from '../Contacts.utils'
import { ContactCoreFieldsFragment } from '../graphql/ContactCoreFields.generated'
import { rvSelectedContact } from '../rv-deprecated/contacts'
import { peopleListItemTranslations } from './PeopleListItem.i18n'
import {
  StyledBusinessListItemWrapper,
  StyledPeopleDetails,
  StyledPeopleName,
} from './PeopleListItem.styled'

interface PeopleListItemProps {
  contact: ContactCoreFieldsFragment | null
}

export const PeopleListItem = ({ contact }: PeopleListItemProps) => {
  const tShared = useTranslations(translations)
  const t = useTranslations(peopleListItemTranslations)
  const navigate = useNavigate()
  const isMobile = useIsMobileResolution()

  if (!contact) {
    return null
  }

  const sortedContacts = getSortedSubContacts(contact)
  let contactsText = `${sortedContacts.length} businesses`
  const displayBusinessCount = isMobile ? 2 : 4

  if (sortedContacts.length < displayBusinessCount) {
    contactsText = sortedContacts
      .map((subContact) => subContact?.businessName)
      .join(', ')
  }

  const { id, firstName, lastName, tags } = contact
  const avatarOptions = getContactAvatarBadgeOptions(contact)

  return (
    <styled.ContactItemContainer
      key={id}
      onClick={() => {
        // NOTE: route is driven by contact id but this isn't yet used to fetch
        // contact, still needs uplift to remove use of rvSelectedContact
        rvSelectedContact(contact)
        navigate(ROOT.PORTAL.CONTACTS.PEOPLE.CONTACT(id).path)
      }}
      aria-label={t('listItemAriaLabel', {
        firstName: firstName ?? '',
        lastName: lastName ?? '',
      })}
    >
      <AvatarBasic
        badgeSize={AVATAR_BADGE_SIZE.SMALL}
        bgColor={avatarOptions.bgColor}
        text={avatarOptions.text}
        userDefinedContent={avatarOptions.userDefinedContent}
        withCursorPointer
      />

      <ContactsContentWrapper>
        <StyledPeopleDetails>
          <StyledPeopleName>
            {firstName} {lastName}
          </StyledPeopleName>
          <StyledBusinessListItemWrapper data-testid="linked-business">
            {contactsText}
          </StyledBusinessListItemWrapper>
        </StyledPeopleDetails>
        <Flex alignItems="center" justifyContent="flex-end">
          <TagPills tags={tags} />
          {contact.isSelf && (
            <Box px="6px">
              <PillYellow text={tShared('you')} size={PillSize.Small} />
            </Box>
          )}
        </Flex>
      </ContactsContentWrapper>
    </styled.ContactItemContainer>
  )
}
