import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import {
  Flex,
  showApiErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'

import { ReactComponent as HLLogo } from 'assets/svg/logos/HL.svg'
import { ROOT } from 'const/routes'
import { UNEXPECTED_ERROR } from 'types/errors'
import { useGetDashboardSites } from 'pages/Dashboard/DashboardTransactions/hooks/useGetDashboardSites/useGetDashboardSites'

import { EditVenue, EditVenueProps } from './components/EditVenue'
import { VenueList, VenueListProps } from './components/VenueList'
import { translations } from './HlPosManage.i18n'
import * as styled from './HlPosManage.styled'
import { Venue } from './HlPosManage.types'
import { useHlPosConfiguration } from './hooks/useHlPosConfiguration'
import { usePairHlPos } from './hooks/usePairHlPos'

export const HlPosManage = () => {
  const navigate = useNavigate()
  const t = useTranslations(translations)

  const {
    posPairState: pairingsData,
    loading,
    error,
    venues,
    refetch: refetchPairings,
  } = useHlPosConfiguration()

  const pairings = useMemo(() => {
    const assignedSiteIds = new Set<string>()
    return pairingsData.reduce((acc, pairing) => {
      const { siteUuid } = pairing
      if (!assignedSiteIds.has(siteUuid)) {
        assignedSiteIds.add(siteUuid)
        acc.push(pairing)
      }
      return acc
    }, [] as typeof pairingsData)
  }, [pairingsData])

  type VenueMap = NonNullable<EditVenueProps['venueMap']>
  const venueMap = useMemo(
    () =>
      venues.reduce((acc, venue) => {
        acc[venue.id] = venue
        return acc
      }, {} as VenueMap),
    [venues]
  )

  const { pairHlPos } = usePairHlPos()
  const [selectedVenue, setSelectedVenue] = useState<Venue>()
  const onSaveVenue: EditVenueProps['onSave'] = useCallback(
    async (nextPairings) => {
      const result = await pairHlPos(nextPairings)
      if (result === UNEXPECTED_ERROR || result === undefined) {
        showApiErrorToast()
        return
      }

      setSelectedVenue(undefined)
      showSuccessToast(t('saveSuccess'))
    },
    [pairHlPos, t]
  )

  const onCloseVenue = useCallback(() => {
    setSelectedVenue(undefined)
  }, [setSelectedVenue])

  const onCloseDrawer = useCallback(() => {
    navigate(ROOT.PORTAL.SETTINGS.CONNECTIONS.path)
  }, [navigate])

  const { sites } = useGetDashboardSites()
  const options: NonNullable<EditVenueProps['options']> = sites.map((site) => ({
    label: site.name,
    value: site.id,
  }))

  const venueListState = useMemo<VenueListProps['state']>(() => {
    if (loading) {
      return { is: 'Loading' }
    }
    if (error) {
      return { is: 'Error', onRetry: refetchPairings }
    }
    const hasVenues = !!venues.length
    if (!hasVenues) {
      return { is: 'NotConnected' }
    }
    return {
      is: 'Ready',
      venues: venues.map((venue) => {
        const hasAssignments = pairings.some(
          (pairing) => pairing.venueId === venue.id
        )
        return {
          id: venue.id,
          name: venue.name,
          hasAssignments,
        }
      }),
      onVenue: (venueId) => {
        const venue = venues?.find(($venue) => $venue.id === venueId)
        setSelectedVenue(venue)
      },
    }
  }, [error, loading, pairings, refetchPairings, venues])

  return (
    <>
      <styled.Drawer
        isOpen
        icon={
          <Flex justifyContent="left">
            <HLLogo width="48px" height="48px" />
          </Flex>
        }
        onClose={onCloseDrawer}
        drawerTitle={t('title')}
        overlayClassName="animated-drawer-overlay"
      >
        <VenueList state={venueListState} />
      </styled.Drawer>
      {selectedVenue && (
        <EditVenue
          venue={selectedVenue}
          venueMap={venueMap}
          initialPairings={pairings}
          options={options}
          onSave={onSaveVenue}
          onCancel={onCloseVenue}
        />
      )}
    </>
  )
}
