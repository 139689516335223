import { useCallback } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'

import { GetEntityAddress as GetEntityAddressResponse } from 'types/gql-types/GetEntityAddress'

import { GetEntityAddress } from '../../graphql/entity'

export const useEntityAddressQuery = () => {
  const { data, loading, error, refetch, networkStatus } =
    useQuery<GetEntityAddressResponse>(GetEntityAddress, {
      notifyOnNetworkStatusChange: true,
    })

  const retry = useCallback(() => {
    refetch()
  }, [refetch])

  return {
    data: data?.getEntity,
    isLoading: loading || networkStatus === NetworkStatus.refetch,
    error,
    retry,
  }
}
