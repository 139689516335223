import { useCallback } from 'react'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { OnboardingDataSummary } from 'types/onboarding'

import { useSaveEntityOnboardingDetailsMutation } from './graphql/saveEntityOnboardingDetails.generated'
import { useOnboardingDataSummary } from './useOnboardingDataSummary'
import { useTrackOnboardingErrors } from './useTrackOnboardingErrors'

export const useSaveOnboarding = () => {
  const { getOnboardingDataSummary } = useOnboardingDataSummary()
  const { trackOnboardingErrors, setTrackOnboardingErrorsDataPayload } =
    useTrackOnboardingErrors()

  const [save, { loading }] = useSaveEntityOnboardingDetailsMutation({
    onError: (error) => {
      trackOnboardingErrors(error)
    },
  })

  const saveOnboarding = useCallback(
    async (payload: OnboardingDataSummary = {}) => {
      const input = {
        ...getOnboardingDataSummary(),
        ...payload,
        entityUuid: payload.entityUuid ?? rvEntityDetails().id,
      }

      setTrackOnboardingErrorsDataPayload(JSON.stringify(input))

      return save({
        variables: {
          input,
        },
      })
    },
    [save, getOnboardingDataSummary, setTrackOnboardingErrorsDataPayload]
  )

  return {
    saveOnboarding,
    isSavingOnboarding: loading,
  }
}
