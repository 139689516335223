import { gql } from '@apollo/client'

import { ContactContent } from '../../ContactContent'

export const TransactionContactQuery = gql`
  query TransactionContactQuery($debitCardTransactionUuid: ID!) {
    getDebitCardTransactionV2(
      debitCardTransactionUuid: $debitCardTransactionUuid
    ) {
      __typename
      id
      merchant {
        __typename
        id
        ...ContactContentMerchantDetailsFragment
      }
    }
  }

  ${ContactContent.fragments.MerchantDetails}
`
