import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { ErrorLogger } from '@npco/utils-error-logger'
import { DeleteAttachment } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/debitCardTransactions'

import {
  DeleteAttachment as DeleteAttachmentResponse,
  DeleteAttachmentVariables,
} from 'types/gql-types/DeleteAttachment'

const ERROR_MESSAGE = 'An error occurred while deleting the receipt'

export const useDeleteTransactionReceipt = () => {
  const [deleteAttachment] = useMutation<
    DeleteAttachmentResponse,
    DeleteAttachmentVariables
  >(DeleteAttachment)

  const deleteTransactionReceipt = useCallback(
    async (documentUuid: string, transactionUuid: string) => {
      try {
        const result = await deleteAttachment({
          variables: {
            documentUuid,
            transactionUuid,
          },
        })

        if (!result.data?.removeDebitCardTxnAttachment) {
          ErrorLogger.report(
            '[Banking] delete transaction receipt',
            result.errors
          )
          throw new Error(ERROR_MESSAGE)
        }

        return result.data
      } catch (err) {
        throw new Error(ERROR_MESSAGE)
      }
    },
    [deleteAttachment]
  )

  return { deleteTransactionReceipt }
}
