import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  InputClearButton,
  InputWithoutLabel,
  SvgIcon,
  TEXT_INPUT_SIZE,
} from '@npco/zeller-design-system'

import { ReactComponent as Search } from 'assets/svg/search.svg'
import { getInputAdaptiveSizeFromTextInputSize } from 'components/InputAdaptiveManagers/InputSizeConverter'

interface ContactsFiltersInputProps {
  defaultValue: string
  onChange: (value: string) => void
  size: TEXT_INPUT_SIZE
}

export const ContactsFiltersInput = ({
  defaultValue,
  onChange: handleChange,
  size,
}: ContactsFiltersInputProps) => {
  const [isCloseIconVisible, setIsCloseIconVisible] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef?.current?.focus()
  }, [inputRef])

  const onClearButtonClick = useCallback(() => {
    if (inputRef?.current?.value) {
      inputRef.current.value = ''
      inputRef.current.focus()

      setIsCloseIconVisible(false)
      handleChange('')
    }
  }, [handleChange])

  const onInputValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value

      if (value && !isCloseIconVisible) {
        setIsCloseIconVisible(true)
      } else if (!value && isCloseIconVisible) {
        setIsCloseIconVisible(false)
      }

      handleChange(value)
    },
    [handleChange, isCloseIconVisible]
  )

  const LeftControls = useCallback(
    () => (
      <Box pr="1rem">
        <SvgIcon
          dataTestId="contacts-filters-input-search-icon"
          width="16"
          height="16"
        >
          <Search />
        </SvgIcon>
      </Box>
    ),
    []
  )

  const RightControls = useCallback(() => {
    if (!inputRef?.current?.value) {
      return null
    }
    return <InputClearButton onClick={onClearButtonClick} />
  }, [onClearButtonClick])

  return (
    <InputWithoutLabel
      name="contacts-filters-input"
      data-testid="contacts-filters-input"
      defaultValue={defaultValue}
      onChange={onInputValueChange}
      placeholder="Search"
      ref={inputRef}
      renderLeftControls={LeftControls}
      renderRightControls={RightControls}
      size={getInputAdaptiveSizeFromTextInputSize(size)}
      icon={Search}
    />
  )
}
