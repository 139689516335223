import { gql } from '@apollo/client'

import { YourCardsListView } from '../../../YourCardsListView'

export const YourCardsListQuery = gql`
  query YourCardsListQuery {
    getDebitCardsV2(limit: 50) {
      cards {
        __typename
        ...YourCardsListViewDebitCardV2Fragment
        customer {
          __typename
          id
        }
      }
    }
    getEntity {
      __typename
      ...YourCardsListViewEntityFragment
    }
  }

  ${YourCardsListView.fragments.DebitCardV2}
  ${YourCardsListView.fragments.Entity}
`
