import { TableCellText } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'
import { isNil } from 'lodash-es'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { CategoryTableData } from '../CategoriesTable.types'

export const CategoriesRowItemsCell = (
  cellProps: CellContext<CategoryTableData, number>
) => {
  const isMobile = useIsMobileResolution()

  const { cell, getValue, row, table } = cellProps

  const isLoading = row.original?.id === null || table.options.meta?.isLoading
  const value = getValue<number>()
  const text = isNil(value) ? '' : String(value)

  const desktopLoadingWidth = row.index % 2 ? '50%' : '70%'
  const mobileLoadingWidth = '50%'
  const width = isMobile ? mobileLoadingWidth : desktopLoadingWidth

  return (
    <TableCellText
      dataTestId={cell.id}
      forwardedProps={{ style: { padding: 0 } }}
      hasDivider={false}
      isLoading={isLoading}
      justifyContent="flex-end"
      key={cell.id}
      skeletonProps={{ width }}
      text={text}
    />
  )
}
