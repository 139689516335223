import { useCallback, useMemo, useRef } from 'react'
import { useReactiveVar } from '@apollo/client'
import { rvSelectedAccountTransaction } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useModalState } from 'design-system/Components/Modal'

import { useAccounts } from 'hooks/banking'
import dayjs from 'utils/dayjs'
import { useDrawerTabEvents } from 'services/Analytics/useDrawerTabEvents'
import {
  DebitCardTransaction,
  TransactionDrawer,
  TransactionDrawerTabs,
} from 'pages/DebitCardTransactions'
import { useNextPreviousDebitCardTransaction } from 'pages/DebitCardTransactions/TransactionDrawer/hooks/useNextPreviousDebitCardTransaction/useNextPreviousDebitCardTransaction'
import { renderGroup } from 'components/Lists/GroupedList/Group/Group.utils'
import { GroupedList } from 'components/Lists/GroupedList/GroupedList'
import { ListGroupTitle } from 'components/Lists/ListGroupTitle/ListGroupTitle'

import { AccountListItem } from './AccountListItem'

interface AccountsListProps {
  accountById: ReturnType<typeof useAccounts>['accountById']
  groupedTransactions: DebitCardTransaction[][]
  fetchMore?: () => void
  hasMore?: boolean
  handleSelectedTransactionUpdate: (
    update: Partial<DebitCardTransaction>
  ) => void
}

export const AccountsList = ({
  accountById,
  groupedTransactions,
  fetchMore,
  hasMore,
  handleSelectedTransactionUpdate,
}: AccountsListProps) => {
  const selectedTransaction = useReactiveVar(rvSelectedAccountTransaction)
  const transactionsRefs = useRef<Record<string, HTMLLIElement | null>>({})
  const initialTime = useMemo(() => dayjs(), [])
  const { onDrawerOpen, onDrawerClose } = useDrawerTabEvents({
    drawerName: 'Transaction',
  })

  const { onSelectNextTransaction, onSelectPreviousTransaction } =
    useNextPreviousDebitCardTransaction({
      groupedTransactions,
      selectedTransaction,
      transactionsRefs,
    })

  const { isModalOpen, openModal, closeModal } = useModalState()

  const handleRenderItem = useCallback(
    (listItemsData: DebitCardTransaction) => (
      <AccountListItem
        key={listItemsData.id}
        transaction={listItemsData}
        account={accountById(listItemsData.debitCardAccountUuid)}
        showDetails={(transaction: DebitCardTransaction) => {
          rvSelectedAccountTransaction(transaction)
          openModal()
          onDrawerOpen(TransactionDrawerTabs.DETAILS)
        }}
        isNew={dayjs(listItemsData.timestamp).isAfter(initialTime)}
      />
    ),
    [accountById, initialTime, openModal, onDrawerOpen]
  )

  const handleOnClose = useCallback(() => {
    rvSelectedAccountTransaction(null)
    onDrawerClose()
    closeModal()
  }, [onDrawerClose, closeModal])

  return (
    <>
      <GroupedList
        data={groupedTransactions}
        onScrollEnd={fetchMore}
        renderItem={handleRenderItem}
        hasMore={hasMore}
        renderGroup={renderGroup({
          getGroupKey: ([firstElement]) => {
            return dayjs(firstElement?.timestamp).startOf('year')
          },
          renderHeader: ([firstElement]) => (
            <ListGroupTitle data-testid="list-group-header">
              {dayjs(firstElement?.timestamp).year()}
            </ListGroupTitle>
          ),
        })}
      />
      <TransactionDrawer
        transaction={selectedTransaction}
        updateTransaction={handleSelectedTransactionUpdate}
        isOpen={isModalOpen}
        onClose={handleOnClose}
        selectNextTransaction={onSelectNextTransaction}
        selectPreviousTransaction={onSelectPreviousTransaction}
      />
    </>
  )
}
