// This was adopted from https://github.com/zendesk/laika -> createLazyLoadableLaikaLink.ts

import { createLazyLoadableLink } from './createLazyLoadableLink'

export const createLazyLoadableTestingLink = () =>
  createLazyLoadableLink(
    import('./createGlobalTestingLink').then(
      ({ createGlobalTestingUtilsLink }) => createGlobalTestingUtilsLink()
    )
  )
