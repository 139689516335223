import { useNavigate } from 'react-router-dom-v5-compat'
import { ContactType } from '@npco/mp-gql-types'
import { AvatarBasic, COLOR } from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { ROUTE } from 'const/routes'

import { getContactName } from '../../../../Contacts.utils'
import { useGetContact } from '../../../../hooks/useGetContact/useGetContact'
import { rvSelectedContact } from '../../../../rv-deprecated/contacts'
import { getContactAvatarBadgeOptions } from '../../../ContactGeneral/ContactGeneral.util'
import {
  StyledButtonClose,
  StyledContact,
  StyledContactWrapper,
  StyledSubContactName,
} from './SubContactListItem.styled'
import { SubContactListItemLoaderSkeleton } from './SubContactListItemLoaderSkeleton/SubContactListItemLoaderSkeleton'

type SubContact = NonNullable<ContactCoreFieldsFragment['contacts']>[0]

export const SUB_CONTACTS_DATA_TESTID = 'contact-sub-contact-section'
export const UNLINK_BUTTON_TEST_ID = 'unlink-button'
export const SUB_CONTACTS_LIST_ITEM_TESTID = 'contact-sub-contact-list-item'

interface SubContactListItemProps {
  onOpenMobileModal: () => void
  setSubContact: () => void
  openUnlinkModal: () => void
  subContact: SubContact | null
}
export const SubContactListItem = ({
  setSubContact,
  openUnlinkModal,
  onOpenMobileModal,
  subContact,
}: SubContactListItemProps) => {
  const navigate = useNavigate()
  const isMobile = useIsMobileResolution()

  const { data: contact, loading } = useGetContact({
    id: subContact?.id,
  })

  if (loading) {
    return <SubContactListItemLoaderSkeleton />
  }

  const route =
    contact?.contactType === ContactType.BUSINESS
      ? ROUTE.PORTAL_CONTACTS_BUSINESSES_GENERAL
      : ROUTE.PORTAL_CONTACTS_PEOPLE_GENERAL

  const handleOnClick = () => {
    rvSelectedContact(contact)
    navigate(route)
  }

  const avatarOptions = getContactAvatarBadgeOptions(contact)
  const name = getContactName(contact)

  if (isMobile) {
    return (
      <StyledContactWrapper
        key={subContact?.id}
        data-testid={SUB_CONTACTS_DATA_TESTID}
        onClick={() => {
          setSubContact()
          onOpenMobileModal()
        }}
      >
        <StyledContact
          data-testid={SUB_CONTACTS_LIST_ITEM_TESTID}
          onClick={handleOnClick}
        >
          <AvatarBasic
            bgColor={avatarOptions.bgColor}
            text={avatarOptions.text}
            userDefinedContent={avatarOptions.userDefinedContent}
            withCursorPointer
          />
          <StyledSubContactName>{name}</StyledSubContactName>
        </StyledContact>
      </StyledContactWrapper>
    )
  }

  return (
    <StyledContactWrapper data-testid={SUB_CONTACTS_DATA_TESTID}>
      <StyledContact
        data-testid={SUB_CONTACTS_LIST_ITEM_TESTID}
        onClick={handleOnClick}
      >
        <AvatarBasic {...avatarOptions} />
        <StyledSubContactName>{name}</StyledSubContactName>
      </StyledContact>
      <StyledButtonClose
        color={COLOR.GREY_550}
        hoverColor={COLOR.BLUE_700}
        onClick={() => {
          setSubContact()
          openUnlinkModal()
        }}
        data-testid={UNLINK_BUTTON_TEST_ID}
      />
    </StyledContactWrapper>
  )
}
