import { CardScheme, TransactionIssuer } from '@npco/mp-gql-types'
import {
  createTranslations,
  type InferTranslationsVariables,
  useTranslations,
} from '@npco/utils-translations'

export const relativeTimeTranslations = createTranslations({
  future: 'in %s',
  past: '%s ago',
  s: 'Just now',
  m: '1m',
  mm: '%dm',
  h: '1h',
  hh: '%dh',
  // NOTE: dayjs doesn't support weeks, so we use additional token processing
  // for the `dd` token to convert days to weeks.
  d: '%dd',
  dd: 'd',
  w: 'w',
  M: '1mo',
  MM: '%dmo',
  y: '1y',
  yy: '%dy',
})

export const translations = createTranslations({
  [CardScheme.AMEX]: 'American Express logo',
  [CardScheme.CUP]: 'Union Pay logo',
  [CardScheme.DINERS]: 'Diners Card International logo',
  [CardScheme.EFTPOS]: 'eftpos logo',
  [CardScheme.JCB]: 'JCB logo',
  [CardScheme.MC]: 'Mastercard logo',
  [CardScheme.OTHER]: 'Other card icon',
  [CardScheme.VISA]: 'Visa logo',
  [TransactionIssuer.AFTERPAY]: 'Afterpay logo',
  accept: 'Accept',
  accounts: 'Accounts',
  activeCard: 'Activate Card',
  addCard: 'Add Card',
  allSelected: 'All selected',
  amount: 'Amount',
  appStore: 'App Store',
  apply: 'Apply',
  apr: 'Apr',
  available: 'Available',
  back: 'Back',
  cancel: 'Cancel',
  card: 'Card',
  changesCouldNotBeSaved: 'Changes could not be saved, please try again.',
  changesSaved: 'Changes saved',
  clear: 'Clear',
  clearSearch: 'Clear search',
  close: 'Close',
  closed: 'Closed',
  comingSoon: 'Coming Soon',
  confirm: 'Confirm',
  contactSupport: 'Contact Support',
  contactUs: 'Contact Us',
  continue: 'Continue',
  copied: 'Copied!',
  copyright: '© {year} Zeller Australia. All rights reserved.',
  create: 'Create',
  createCard: 'Create Card',
  createNew: '+ Create new',
  currencySymbol: '$',
  daily: 'Daily',
  dataCollectionStatement: 'Data Collection Statement',
  dec: 'Dec',
  delete: 'Delete',
  discardChanges: 'Discard Changes',
  dismiss: 'Dismiss',
  done: 'Done',
  download: 'Download',
  edit: 'Edit',
  failedToLoadOptions: 'Failed to load options.',
  finish: 'Finish',
  fortnightly: 'Fortnightly',
  from: 'From',
  googlePlay: 'Google Play',
  gotIt: 'Got it',
  help: 'Help',
  jan: 'Jan',
  jul: 'Jul',
  jun: 'Jun',
  learnMore: 'Learn More',
  letsGo: "Let's Go!",
  linkCopyConfirmation: 'Link copied',
  loading: 'Loading',
  locked: 'Locked',
  logout: 'Log Out',
  mar: 'Mar',
  max: 'Max',
  maximiseMenu: 'Maximise menu',
  min: 'Min',
  minimiseMenu: 'Minimise menu',
  monthly: 'Monthly',
  nSelected: '{count} selected',
  new: 'New',
  next: 'Next',
  noAvailableOptions: 'No available options.',
  oct: 'Oct',
  ok: 'OK',
  quarterTitle: 'Q{quarter} ({start} - {end}) {year}',
  quarterly: 'Quarterly',
  questionMark: '?',
  reject: "Don't Accept",
  remove: 'Remove',
  reset: 'Reset',
  retry: 'Retry',
  save: 'Save',
  search: 'Search',
  select: 'Select',
  selectAll: 'Select all',
  selectOptions: 'Select options',
  send: 'Send',
  sep: 'Sep',
  showAll: 'Show all',
  showLess: 'Show less',
  signOut: 'Sign out',
  site: 'Site',
  somethingWentWrong: 'Something went wrong, please try again.',
  suspended: 'Suspended',
  to: 'To',
  toggleMenu: 'Toggle menu',
  transfer: 'Transfer',
  tryAgain: 'Try Again',
  updated: 'Updated',
  upload: 'Upload',
  weekly: 'Weekly',
  weCouldntFindAnythingThatMatchesInput:
    'We couldn’t find anything that matches “{input}”.',
  you: 'You',
})

// Not used as a hook, never called. Used only for type inference.
// eslint-disable-next-line react-hooks/rules-of-hooks
const tSharedFn = () => useTranslations(translations)

/**
 * The type of the tShared function as returned by useTranslations(translationsShared).
 */
export type TShared = ReturnType<typeof tSharedFn>

type TSharedKeyVariables = InferTranslationsVariables<typeof translations>

/**
 * All translation keys of translationsShared that don't have any variables.
 */
export type TSharedKey<TVariablesKeys extends string = never> = {
  [TKey in keyof TSharedKeyVariables]: [TVariablesKeys] extends [never]
    ? [TSharedKeyVariables[TKey]] extends [never]
      ? TKey
      : never
    : [TSharedKeyVariables[TKey]] extends [never]
    ? never
    : [TSharedKeyVariables[TKey]] extends [TVariablesKeys]
    ? TKey
    : never
}[keyof TSharedKeyVariables]
