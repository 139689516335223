import { gql } from '@apollo/client'

import { AccountStatementsView } from '../../../AccountStatementsView'

export const AccountStatementsQuery = gql`
  query AccountStatementsQuery($input: GetBankingAccountStatementsInput!) {
    getBankingAccountStatements(input: $input) {
      ...AccountStatementsViewBankingAccountStatementFragment
    }
  }

  ${AccountStatementsView.fragments.BankingAccountStatement}
`
