import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  description1:
    'Based on the imported file, new items will be added and existing ones updated. {link}',
  description2:
    'Match the columns in your imported file to those in Zeller Items.',
  downloadTemplateButton: 'Download Item File Template',
  errorDuplicateHeaders: 'File format invalid: Duplicate column headers.',
  errorFileRequired: 'File upload required.',
  errorFileTooLarge: 'File size is too large.',
  errorInvalidFileType:
    'File format invalid: Please upload a .csv, .xlsx or .xls.',
  errorMergedColumns: 'File format invalid: Merged column headers.',
  errorMinimumColumns:
    'File format invalid: File requires Item Name, Category, Price and Default GST. Please check the file and try again.',
  errorNodata:
    'File format invalid: File is empty. Please review the file and try again.',
  findOutMore: 'Find out more about how Items are imported.',
  unknownError: 'There was an error importing your file, please try again.',
  uploadLabel: 'Drag and drop or',
  uploaderSubLabel: 'browse files from your device',
  uploadDescription:
    'Files must be smaller than 5MB. Supported file types are .csv, .xlsx and .xls.',
})
