import {
  AccountCreated,
  Attributes,
  Funds,
  HelpBuildYourBusiness,
  IllustrationProps,
  ItemManagement,
  ItemsCategories,
  ItemsDiscounts,
  MagnifyingGlassNoResults,
  Modifiers,
  SIMCard,
  SomethingWentWrong,
  SvgIcon,
  TapToPayIphone,
  useIsMobileResolution,
  UserAdded,
} from '@npco/zeller-design-system'

import { ReactComponent as LockAndKey } from 'assets/svg/lock-and-key.svg'

const IllustrationMap = {
  AccountCreated,
  Funds: ({ size }: IllustrationProps) => (
    <Funds size={size} variant="outlined-blue" alt="funds" />
  ),
  HelpBuildYourBusiness,
  ItemManagement,
  Items: ItemManagement,
  MagnifyingGlassNoResults,
  SimCard: SIMCard,
  SomethingWentWrong,
  TapToPayIphone,
  UserAdded,
  Attributes,
  Modifiers,
  ItemsCategories,
  ItemsDiscounts,
  // NOTE: The LockAndKey illustration in the design system has an incorrect
  // viewBox value rendering it not centred, needs to be fixed in the design
  // system before using it here.
  LockAndKey: ({ alt }: IllustrationProps) => (
    <SvgIcon alt={alt}>
      <LockAndKey />
    </SvgIcon>
  ),
} as const

export type IllustrationName = keyof typeof IllustrationMap

export const useGetIllustrationFromName = (name: IllustrationName) => {
  const isMobile = useIsMobileResolution()
  const illustrationSize = isMobile ? 'medium' : 'large'

  return IllustrationMap[name]({
    size: illustrationSize,
  })
}
