import { useCallback } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'
import { showErrorToast } from '@npco/zeller-design-system'

import {
  Address,
  ReplaceCardAddressState,
} from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage.types'
import { AUSTRALIA_COUNTRY_CODE } from 'const/common'
import { translate } from 'utils/translations'

import { useReportLostAndReplaceCard } from '../../hooks/useReportLostAndReplaceCard'
import { useReportLostAndReplaceCardState } from '../../hooks/useReportLostAndReplaceCardState'

export const useReportLostAndReplaceCardAddressForm = () => {
  const { state } = useReportLostAndReplaceCardState<ReplaceCardAddressState>()

  const { reportLostAndReplacePhysicalCard, isReportingLostAndReplaceCard } =
    useReportLostAndReplaceCard({
      velocityControl: state?.velocityControl ?? null,
      entityAddressTimezone: state?.entityAddressTimezone ?? null,
    })

  const submitAddressPage = useCallback(
    (address: Address) => {
      if (state === null) {
        ErrorLogger.report(
          `[Banking] ${translate(
            'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardAddressPrompt.submitErrorMessage'
          )}`
        )
        showErrorToast()
        return
      }

      reportLostAndReplacePhysicalCard({
        cardId: state.cardId,
        cardColor: state.cardColor,
        address,
        isAdmin: state.isAdmin,
      })
    },
    [state, reportLostAndReplacePhysicalCard]
  )

  const submitCustomAddressPage = useCallback(
    (address: Omit<Address, 'country'>) => {
      submitAddressPage({
        ...address,
        country: AUSTRALIA_COUNTRY_CODE,
      })
    },
    [submitAddressPage]
  )

  return {
    submitCustomAddressPage,
    submitPredefinedAddressPage: submitAddressPage,
    isSubmitting: isReportingLostAndReplaceCard,
  }
}
