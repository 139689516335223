import { gql } from '@apollo/client'

export const SendInvoice = gql`
  mutation SendInvoice($referenceNumber: String!) {
    sendInvoice(referenceNumber: $referenceNumber) {
      __typename
      id
      referenceNumber
      status
    }
  }
`
