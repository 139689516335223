import { Box } from '@npco/zeller-design-system'

import { useLogout } from 'hooks/useLogout'
import { SUPPORT_EMAIL } from 'const/externalLinks'
import { translate } from 'utils/translations'

import { OnboardingLayout } from '../../../features/OnboardingApp/OnboardingLayout'
import { OnboardingPageLayout } from '../../../features/OnboardingApp/OnboardingPageLayout'
import { StyledEmail } from './LoginErrorEntityAbandoned.styled'

export const LoginErrorEntityAbandoned = () => {
  const { logout } = useLogout()

  return (
    <OnboardingLayout>
      <OnboardingPageLayout
        title={translate('page.errorPages.abnadonedEntity.title')}
        description={
          <>
            <Box mb="1.5rem">
              {translate('page.errorPages.abnadonedEntity.description1')}
            </Box>

            <Box mb="1.5rem">
              {translate('page.errorPages.abnadonedEntity.description2', {
                email: <StyledEmail>{SUPPORT_EMAIL}</StyledEmail>,
              })}
            </Box>

            {translate('page.errorPages.abnadonedEntity.description3')}
          </>
        }
        isForm={false}
        nextButtonLabel={translate('shared.signOut')}
        onConfirmClick={logout}
        isBackButtonHidden
      />
    </OnboardingLayout>
  )
}
