import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { KycStatus } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import {
  Flex,
  Message,
  MessageProps,
  PageTemplate,
} from '@npco/zeller-design-system'

import { ReactComponent as LockAndKey } from 'assets/svg/lock-and-key.svg'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'

export type IDVRequirementScreenProps = Pick<
  MessageProps,
  'title' | 'description'
>

export const IDVRequirementScreen = ({
  title,
  description,
}: IDVRequirementScreenProps) => {
  const navigate = useNavigate()

  const user = useLoggedInUser()
  const userKycStatus = user.userData?.kycStatus ?? undefined

  const titleResolved = useMemo(() => {
    if (title) {
      return title
    }
    if (userKycStatus === KycStatus.IN_REVIEW) {
      return translate('component.idvRequirementScreen.titlePending')
    }
    return translate('component.idvRequirementScreen.title')
  }, [userKycStatus, title])

  const descriptionResolved = useMemo(() => {
    if (description) {
      return description
    }
    return translate('component.idvRequirementScreen.description')
  }, [description])

  const onClickCompleteVerification = useMemo(() => {
    if (
      userKycStatus === KycStatus.IN_REVIEW ||
      userKycStatus === KycStatus.RC_ABANDONED
    ) {
      return undefined
    }
    return () => {
      navigate(ROOT.PORTAL.KYC.YOUR_INFORMATION.path)
    }
  }, [userKycStatus, navigate])

  return (
    <PageTemplate HeaderPrimaryRow={null}>
      <Flex
        role="status"
        height="100%"
        mx="auto"
        alignItems="center"
        justifyContent="content"
      >
        <Message
          image={<LockAndKey />}
          title={titleResolved}
          description={descriptionResolved}
          primaryButton={
            onClickCompleteVerification && {
              onClick: onClickCompleteVerification,
              label: translate(
                'component.idvRequirementScreen.actionCompleteVerification'
              ),
            }
          }
        />
      </Flex>
    </PageTemplate>
  )
}
