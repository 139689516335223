import { useReactiveVar } from '@apollo/client'
import { LabelType } from '@npco/mp-gql-types'
import { showErrorToast } from '@npco/zeller-design-system'
import { uniqBy } from 'ramda'

import { errorMessages } from 'translations'

import {
  GetLabelsQueryResponse,
  useGetLabelsQuery,
} from '../../graphql/getLabels.generated'
import {
  rvBusinessLabels,
  rvBusinessLabelsDeleted,
  rvPersonLabels,
  rvPersonLabelsDeleted,
} from '../../LabelControl.utils'

type Label = NonNullable<GetLabelsQueryResponse['getLabels'][number]>

export const useGetLabels = (labelType: LabelType) => {
  const isBusinessLabelType = labelType === LabelType.BUSINESS

  const rvLabels = isBusinessLabelType ? rvBusinessLabels : rvPersonLabels
  const rvDeletedLabels = isBusinessLabelType
    ? rvBusinessLabelsDeleted
    : rvPersonLabelsDeleted

  const labels = useReactiveVar(rvLabels)

  const { loading } = useGetLabelsQuery({
    onCompleted: (data) => {
      if (!data?.getLabels) {
        return
      }

      const results =
        data?.getLabels?.filter((label): label is Label => Boolean(label)) ?? []

      // NOTE: ensure optimistic deleted or updated labels take precedent
      // We currently fetch from network once and then only from cache however
      // should we change this the logic below will handle this as intended
      const nextLabels = results.reduce((memo: Label[], label) => {
        const isDeletedOptimistically = rvDeletedLabels().includes(label.id)

        // NOTE: remove labels which have been deleted locally
        if (isDeletedOptimistically) {
          return memo
        }

        const optimisticLabel = labels.find(
          (rvLabel) => rvLabel.id === label.id
        )

        // NOTE: ensure labels which have been updated locally are persisted
        memo.push(optimisticLabel || label)

        return memo
      }, [])

      // NOTE: ensure labels added optimistically are also returned
      rvLabels(uniqBy((label) => label.id, [...rvLabels(), ...nextLabels]))
    },
    onError: () => showErrorToast(errorMessages.getLabelsError),
    variables: {
      type: labelType,
    },
  })

  return {
    isLoadingLabels: loading,
    labels,
  }
}
