import {
  CMSContentActionClickedPayload,
  CMSContentClosePayload,
} from 'services/Analytics/events/cmsContent'

import {
  ContactArchivedPaymentInstrumentPayload,
  ContactAttachedPaymentInstrumentPayload,
  ContactCreatedPayload,
  ContactDeletedPayload,
  ContactLinkedCardholderPayload,
  ContactLinkedContactPayload,
  ContactUnlinkedCardholderPayload,
  ContactUnlinkedContactPayload,
  ContactUnlinkedPaymentInstrumentPayload,
} from './events/contact'
import { DebitCardTransactionsExportPayload } from './events/debitCardTransaction'
import { DepositsFilteredPayload } from './events/deposit'
import {
  InvoiceAction,
  InvoiceAnalyticsProperties,
  InvoiceBeNotified,
  InvoiceCustomisationAnalyticsAccentColoursProperties,
  InvoiceCustomisationAnalyticsLogoProperties,
  InvoiceCustomisationAnalyticsProperties,
  InvoiceGeneralSettingsProperties,
  InvoiceReminderSettingsProperties,
} from './events/invoice'
import {
  ItemsAddCategoryProperties,
  ItemsAddItemProperties,
  ItemsEditCategoryProperties,
  ItemsEditItemProperties,
  ItemsExportProperties,
  ItemsGeneralSettingsProperties,
  ItemsImportCompleteProperties,
} from './events/items'
import {
  OnboardingCompletedPayload,
  OnboardingStatusPayload,
  OnfidoAnalyticsEventPayload,
  OnfidoCompleteEventPayload,
} from './events/onboarding'
import {
  PaymentInstrumentCreatedPayload,
  PaymentInstrumentEditedPayload,
} from './events/paymentInstrument'
import { SardineDeviceResponsePayload } from './events/sardine'
import {
  TransactionDownloadedSummaryPayload,
  TransactionReceiptSentPayload,
  TransactionsExportedPayload,
  TransactionsFilteredPayload,
} from './events/transaction'
import {
  EcommerceCreatedPayload,
  EcommercePayload,
} from './events/virtualTerminal'

export enum AnalyticsEventNames {
  CONTACT_CREATED = 'Contact.Created',
  CONTACT_DELETED = 'Contact.Deleted',
  CONTACT_LINKED_CONTACT = 'Contact.LinkedContact',
  CONTACT_UNLINKED_CONTACT = 'Contact.UnlinkedContact',
  CONTACT_ATTACHED_PAYMENT_INSTRUMENT = 'Contact.AttachedPaymentInstrument',
  CONTACT_UNLINKED_PAYMENT_INSTRUMENT = 'Contact.UnlinkedPaymentInstrument',
  CONTACT_ARCHIVED_PAYMENT_INSTRUMENT = 'Contact.ArchivedPaymentInstrument',
  CONTACT_LINKED_CARDHOLDER = 'Contact.LinkedCardholder',
  CONTACT_UNLINKED_CARDHOLDER = 'Contact.UnlinkedCardholder',

  DEBIT_CARD_TRANSACTIONS_EXPORT = 'DebitCardTransactionsExport.Exported',

  INVOICE_BE_NOTIFIED = 'Invoice.BeNotified',
  INVOICE_CANCELLED = 'Invoice.Cancelled',
  INVOICE_DISCARDED = 'Invoice.Discarded',
  INVOICE_DOWNLOAD_PDF = 'Invoice.Download.PDF',
  INVOICE_DELETED = 'Invoice.Deleted',
  INVOICE_DUPLICATED = 'Invoice.Duplicated',
  INVOICE_EDIT = 'Invoice.Edit',
  INVOICE_INITIATED = 'Invoice.Initiated',
  INVOICE_MARK_AS_PAID = 'Invoice.MarkedAsPaid',
  INVOICE_PREVIEWED_PDF = 'Invoice.Previewed.PDF',
  INVOICE_RECORD_PAYMENT = 'Invoice.RecordPayment',
  INVOICE_SAVED = 'Invoice.Saved',
  INVOICE_SEND_REMINDER = 'Invoice.SendReminder',
  INVOICE_SKIP_REMINDER = 'Invoice.SkipReminder',
  INVOICE_SEND_INVOICE = 'Invoice.SendInvoice',
  INVOICE_SEND_NOW = 'Invoice.SendNow',
  INVOICE_SENT = 'Invoice.Sent',
  INVOICE_SHARE_LINK = 'Invoice.ShareLink',
  INVOICE_GENERAL_SETTINGS_UPDATED = 'Invoice.GeneralSettingsUpdated',
  INVOICE_REMINDER_SETTINGS_UPDATED = 'Invoice.ReminderSettingsUpdated',
  INVOICE_CUSTOMISATION_SETTINGS_UPDATED = 'Invoice.SettingsCustomisation.Updated',
  INVOICE_CUSTOMISATION_LOGOS_UPDATED = 'Invoice.SettingsCustomisation.LogoUpdated',
  INVOICE_CUSTOMISATION_ACCENT_COLOUR_UPDATED = 'Invoice.SettingsCustomisation.AccentColourUpdated',
  INVOICE_CUSTOMISATION_SETTINGS_PDF_PREVIEW = 'Invoice.SettingsCustomisation.PdfPreview',

  ITEMS_ADD_CATEGORY = 'Items.AddCategory',
  ITEMS_ADD_ITEM = 'Items.AddItem',
  ITEMS_DELETE_ALL = 'Items.DeleteAll',
  ITEMS_DELETE_CATEGORY = 'Items.DeleteCategory',
  ITEMS_DELETE_ITEM = 'Items.DeleteItem',
  ITEMS_EDIT_CATEGORY = 'Items.EditCategory',
  ITEMS_EDIT_ITEM = 'Items.EditItem',
  ITEMS_EXPORT = 'Items.ItemExport',
  ITEMS_GENERAL_SETTINGS_UPDATED = 'Items.GeneralSettingsUpdated',
  ITEMS_IMPORT_COMPLETE = 'Items.ImportComplete',
  ITEMS_IMPORT_STARTED = 'Items.ImportStarted',

  ONBOARDING_SIGNUP = 'Onboarding.Signup',
  ONBOARDING_STATUS = 'Onboarding.Status',
  ONBOARDING_COMPLETED = 'Onboarding.Completed',
  ONBOARDING_ONFIDO_COMPLETED = 'Onfido.Completed',
  ONBOARDING_ONFIDO_ANALYTICS = 'Onfido.Analytics',

  ONBOARDING_SHOP_CHECKOUT_PAY_BUTTON_CLICKED = 'Onboarding.ShopCheckoutPayButtonClicked',
  ONBOARDING_SHOP_CHECKOUT_PAYMENT_COMPLETED = 'Onboarding.ShopCheckoutPaymentCompleted',
  ONBOARDING_SHOP_CHECKOUT_CARD_DECLINED = 'Onboarding.ShopCheckoutCardDeclined',
  ONBOARDING_SHOP_CHECKOUT_CART_API_ERRORED = 'Onboarding.ShopCheckoutCartApiErrored',
  ONBOARDING_SHOP_CHECKOUT_PAYMENT_API_ERRORED = 'Onboarding.ShopCheckoutPaymentApiErrored',

  PAYMENT_INSTRUMENT_CREATED = 'PaymentInstrument.Created',
  PAYMENT_INSTRUMENT_EDITED = 'PaymentInstrument.Edited',

  SARDINE_DEVICE_RESPONSE = 'Sardine.DeviceResponse',

  TRANSACTIONS_EXPORTED = 'TransactionsExport.Exported',
  TRANSACTIONS_FILTERED = 'Transactions.Filtered',
  TRANSACTION_DOWNLOADED_SUMMARY = 'Transaction.DownloadedSummary',
  TRANSACTION_RECEIPT_SENT = 'TransactionReceipt.Sent',

  CMS_BANNER_CALL_TO_ACTION_LINK = 'CMSBanner.CallToActionLink',
  CMS_BANNER_CLOSE = 'CMSBanner.Close',
  CMS_MODAL_CALL_TO_ACTION_LINK = 'CMSModal.CallToActionLink',
  CMS_MODAL_CLOSE = 'CMSModal.Close',

  DEPOSITS_FILTERED = 'Deposits.Filtered',

  VT_CREATED = 'VT.Created',
  VT_RESENT = 'VT.Resent',
  VT_CANCELLED = 'VT.Cancelled',
  VT_LINK_COPIED = 'VT.LinkCopied',
}

export type Events = keyof EventMapsForPayload

export type CMSEventMapsForPayload = {
  [AnalyticsEventNames.CMS_BANNER_CLOSE]: CMSContentClosePayload
  [AnalyticsEventNames.CMS_MODAL_CLOSE]: CMSContentClosePayload
  [AnalyticsEventNames.CMS_MODAL_CALL_TO_ACTION_LINK]: CMSContentActionClickedPayload
  [AnalyticsEventNames.CMS_BANNER_CALL_TO_ACTION_LINK]: CMSContentActionClickedPayload
}

export type ItemsEventMapsForPayload = {
  [AnalyticsEventNames.ITEMS_ADD_ITEM]: ItemsAddItemProperties
  [AnalyticsEventNames.ITEMS_ADD_CATEGORY]: ItemsAddCategoryProperties
  [AnalyticsEventNames.ITEMS_DELETE_ITEM]: never
  [AnalyticsEventNames.ITEMS_DELETE_CATEGORY]: never
  [AnalyticsEventNames.ITEMS_EDIT_CATEGORY]: ItemsEditCategoryProperties
  [AnalyticsEventNames.ITEMS_EDIT_ITEM]: ItemsEditItemProperties
  [AnalyticsEventNames.ITEMS_DELETE_ALL]: never
  [AnalyticsEventNames.ITEMS_IMPORT_STARTED]: never
  [AnalyticsEventNames.ITEMS_IMPORT_COMPLETE]: ItemsImportCompleteProperties
  [AnalyticsEventNames.ITEMS_EXPORT]: ItemsExportProperties
  [AnalyticsEventNames.ITEMS_GENERAL_SETTINGS_UPDATED]: ItemsGeneralSettingsProperties
}

export type InvoiceActionEventMapsForPayload = {
  [AnalyticsEventNames.INVOICE_CANCELLED]: InvoiceAction
  [AnalyticsEventNames.INVOICE_DOWNLOAD_PDF]: InvoiceAction
  [AnalyticsEventNames.INVOICE_DELETED]: InvoiceAction
  [AnalyticsEventNames.INVOICE_DUPLICATED]: InvoiceAction
  [AnalyticsEventNames.INVOICE_EDIT]: InvoiceAction
  [AnalyticsEventNames.INVOICE_MARK_AS_PAID]: InvoiceAction
  [AnalyticsEventNames.INVOICE_PREVIEWED_PDF]: InvoiceAction
  [AnalyticsEventNames.INVOICE_RECORD_PAYMENT]: InvoiceAction
  [AnalyticsEventNames.INVOICE_SEND_REMINDER]: InvoiceAction
  [AnalyticsEventNames.INVOICE_SKIP_REMINDER]: InvoiceAction
  [AnalyticsEventNames.INVOICE_SHARE_LINK]: InvoiceAction
  [AnalyticsEventNames.INVOICE_SEND_INVOICE]: InvoiceAction
  [AnalyticsEventNames.INVOICE_SEND_NOW]: InvoiceAction
}

export type InvoiceEventMapsForPayload = {
  [AnalyticsEventNames.INVOICE_BE_NOTIFIED]: InvoiceBeNotified
  [AnalyticsEventNames.INVOICE_DISCARDED]: InvoiceAnalyticsProperties
  [AnalyticsEventNames.INVOICE_INITIATED]: never
  [AnalyticsEventNames.INVOICE_SAVED]: InvoiceAnalyticsProperties
  [AnalyticsEventNames.INVOICE_SENT]: InvoiceAnalyticsProperties
  [AnalyticsEventNames.INVOICE_GENERAL_SETTINGS_UPDATED]: InvoiceGeneralSettingsProperties
  [AnalyticsEventNames.INVOICE_REMINDER_SETTINGS_UPDATED]: InvoiceReminderSettingsProperties
  [AnalyticsEventNames.INVOICE_CUSTOMISATION_SETTINGS_UPDATED]: InvoiceCustomisationAnalyticsProperties
  [AnalyticsEventNames.INVOICE_CUSTOMISATION_LOGOS_UPDATED]: InvoiceCustomisationAnalyticsLogoProperties
  [AnalyticsEventNames.INVOICE_CUSTOMISATION_ACCENT_COLOUR_UPDATED]: InvoiceCustomisationAnalyticsAccentColoursProperties
} & InvoiceActionEventMapsForPayload

export type OnboardingEventMapsForPayload = {
  [AnalyticsEventNames.ONBOARDING_SIGNUP]: never
  [AnalyticsEventNames.ONBOARDING_STATUS]: OnboardingStatusPayload
  [AnalyticsEventNames.ONBOARDING_COMPLETED]: OnboardingCompletedPayload
  [AnalyticsEventNames.ONBOARDING_ONFIDO_ANALYTICS]: OnfidoAnalyticsEventPayload
  [AnalyticsEventNames.ONBOARDING_ONFIDO_COMPLETED]: OnfidoCompleteEventPayload
  [AnalyticsEventNames.ONBOARDING_SHOP_CHECKOUT_PAY_BUTTON_CLICKED]: never
  [AnalyticsEventNames.ONBOARDING_SHOP_CHECKOUT_PAYMENT_COMPLETED]: never
  [AnalyticsEventNames.ONBOARDING_SHOP_CHECKOUT_CART_API_ERRORED]: never
  [AnalyticsEventNames.ONBOARDING_SHOP_CHECKOUT_CARD_DECLINED]: never
  [AnalyticsEventNames.ONBOARDING_SHOP_CHECKOUT_PAYMENT_API_ERRORED]: never
}

export type VirtualTerminalEventMapsForPayload = {
  [AnalyticsEventNames.VT_CREATED]: EcommerceCreatedPayload
  [AnalyticsEventNames.VT_RESENT]: EcommercePayload
  [AnalyticsEventNames.VT_CANCELLED]: EcommercePayload
  [AnalyticsEventNames.VT_LINK_COPIED]: EcommercePayload
}

export type EventMapsForPayload = {
  [AnalyticsEventNames.CONTACT_CREATED]: ContactCreatedPayload
  [AnalyticsEventNames.CONTACT_DELETED]: ContactDeletedPayload
  [AnalyticsEventNames.CONTACT_LINKED_CONTACT]: ContactLinkedContactPayload
  [AnalyticsEventNames.CONTACT_UNLINKED_CONTACT]: ContactUnlinkedContactPayload
  [AnalyticsEventNames.CONTACT_ATTACHED_PAYMENT_INSTRUMENT]: ContactAttachedPaymentInstrumentPayload
  [AnalyticsEventNames.CONTACT_UNLINKED_PAYMENT_INSTRUMENT]: ContactUnlinkedPaymentInstrumentPayload
  [AnalyticsEventNames.CONTACT_ARCHIVED_PAYMENT_INSTRUMENT]: ContactArchivedPaymentInstrumentPayload
  [AnalyticsEventNames.CONTACT_LINKED_CARDHOLDER]: ContactLinkedCardholderPayload
  [AnalyticsEventNames.CONTACT_UNLINKED_CARDHOLDER]: ContactUnlinkedCardholderPayload

  [AnalyticsEventNames.DEBIT_CARD_TRANSACTIONS_EXPORT]: DebitCardTransactionsExportPayload

  [AnalyticsEventNames.DEPOSITS_FILTERED]: DepositsFilteredPayload

  [AnalyticsEventNames.PAYMENT_INSTRUMENT_CREATED]: PaymentInstrumentCreatedPayload
  [AnalyticsEventNames.PAYMENT_INSTRUMENT_EDITED]: PaymentInstrumentEditedPayload

  [AnalyticsEventNames.TRANSACTION_DOWNLOADED_SUMMARY]: TransactionDownloadedSummaryPayload
  [AnalyticsEventNames.TRANSACTIONS_EXPORTED]: TransactionsExportedPayload
  [AnalyticsEventNames.TRANSACTIONS_FILTERED]: TransactionsFilteredPayload
  [AnalyticsEventNames.TRANSACTION_RECEIPT_SENT]: TransactionReceiptSentPayload
  [AnalyticsEventNames.SARDINE_DEVICE_RESPONSE]: SardineDeviceResponsePayload
} & InvoiceEventMapsForPayload &
  OnboardingEventMapsForPayload &
  ItemsEventMapsForPayload &
  VirtualTerminalEventMapsForPayload &
  CMSEventMapsForPayload
