import { NetworkStatus, useQuery } from '@apollo/client'

import { TransactionContactQuery } from './graphql/TransactionContactQuery'
import {
  TransactionContactQueryResponse,
  TransactionContactQueryVariables,
} from './graphql/TransactionContactQuery.generated'

const TRANSACTION_CONTACT_DEBOUNCE_IN_MS = 500

interface UseTransactionContactQueryProps {
  debitCardTransactionUuid: string
}

export const useTransactionContactQuery = ({
  debitCardTransactionUuid,
}: UseTransactionContactQueryProps) => {
  const { data, loading, error, refetch, networkStatus } = useQuery<
    TransactionContactQueryResponse,
    TransactionContactQueryVariables
  >(TransactionContactQuery, {
    variables: { debitCardTransactionUuid },
    notifyOnNetworkStatusChange: true,
    context: {
      debounceKey: 'debitCardTransactionContactQuery',
      debounceTimeout: TRANSACTION_CONTACT_DEBOUNCE_IN_MS,
    },
  })

  return {
    refetchTransaction: refetch,
    isLoading: loading || networkStatus === NetworkStatus.refetch,
    error,
    transaction: data?.getDebitCardTransactionV2,
  }
}
