import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetSavingsAccountsQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type GetSavingsAccountsQueryResponse = {
  __typename?: 'Query'
  getSavingsAccounts: Array<{
    __typename?: 'DebitCardAccountV2'
    id: string
    status: Types.DebitCardAccountStatus
    balance: { __typename?: 'Money'; value: string } | null
  } | null>
}

export const GetSavingsAccountsQuery = gql`
  query GetSavingsAccountsQuery {
    getSavingsAccounts {
      id
      balance {
        value
      }
      status
    }
  }
` as unknown as TypedDocumentNode<
  GetSavingsAccountsQueryResponse,
  GetSavingsAccountsQueryVariables
>

/**
 * __useGetSavingsAccountsQuery__
 *
 * To run a query within a React component, call `useGetSavingsAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavingsAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavingsAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSavingsAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSavingsAccountsQueryResponse,
    GetSavingsAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSavingsAccountsQueryResponse,
    GetSavingsAccountsQueryVariables
  >(GetSavingsAccountsQuery, options)
}
export function useGetSavingsAccountsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSavingsAccountsQueryResponse,
    GetSavingsAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSavingsAccountsQueryResponse,
    GetSavingsAccountsQueryVariables
  >(GetSavingsAccountsQuery, options)
}
export type GetSavingsAccountsQueryHookResult = ReturnType<
  typeof useGetSavingsAccountsQuery
>
export type GetSavingsAccountsQueryLazyQueryHookResult = ReturnType<
  typeof useGetSavingsAccountsQueryLazyQuery
>
export type GetSavingsAccountsQueryQueryResult = Apollo.QueryResult<
  GetSavingsAccountsQueryResponse,
  GetSavingsAccountsQueryVariables
>
