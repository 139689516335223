import { useCallback, useEffect, useRef, useState } from 'react'
import { InputMaybe } from '@npco/mp-gql-types'
import { INPUT_SIZE } from '@npco/zeller-design-system'

import { SearchIconButtonWithIndicator } from 'components/SearchIconButtonWithIndicator/SearchIconButtonWithIndicator'
import { SearchInput } from 'components/SearchInput/SearchInput'

import * as styled from './HeaderSearch.styled'

type HeaderSearchProps = {
  debounceDuration?: number
  isActive?: boolean
  openWidth?: string
  placeholder?: string
  textSearchFilter: InputMaybe<string>
  setTextSearchFilterInput: (input: string) => void
} & (
  | {
      isMobile: boolean
      openMobileFiltersModal: () => void
    }
  | { isMobile?: never; openMobileFiltersModal?: never }
)

export const HeaderSearch = ({
  debounceDuration,
  isActive,
  isMobile,
  openMobileFiltersModal,
  openWidth = '242px',
  placeholder,
  textSearchFilter,
  setTextSearchFilterInput,
}: HeaderSearchProps) => {
  const [searchIsOpen, setSearchIsOpen] = useState(
    !isMobile && Boolean(textSearchFilter)
  )

  useEffect(() => {
    if (isMobile) {
      setSearchIsOpen(false)
    } else if (textSearchFilter) {
      setSearchIsOpen(true)
    }
  }, [isMobile, textSearchFilter])

  const handleChange = useCallback(
    (search: string) => setTextSearchFilterInput(search),
    [setTextSearchFilterInput]
  )

  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <styled.SearchWrapper
      $isOpen={searchIsOpen}
      $isMobile={isMobile}
      $openWidth={openWidth}
    >
      <styled.SearchIconWrapper $isOpen={searchIsOpen}>
        <SearchIconButtonWithIndicator
          isActive={isActive ?? (isMobile ? Boolean(textSearchFilter) : false)}
          onClick={() => {
            if (isMobile) {
              openMobileFiltersModal?.()
            } else {
              if (inputRef.current) {
                inputRef.current.value = ''
                inputRef.current.focus()
              }
              setSearchIsOpen(true)
            }
          }}
        />
      </styled.SearchIconWrapper>
      {!isMobile && (
        <styled.SearchInputWrapper $isOpen={searchIsOpen}>
          <SearchInput
            debounceDuration={debounceDuration}
            defaultValue={textSearchFilter ?? ''}
            onChange={handleChange}
            onClear={() => {
              setSearchIsOpen(false)
              setTextSearchFilterInput('')
            }}
            placeholder={placeholder}
            ref={inputRef}
            size={INPUT_SIZE.SMALL}
          />
        </styled.SearchInputWrapper>
      )}
    </styled.SearchWrapper>
  )
}
