import { NetworkStatus, useQuery } from '@apollo/client'

import { TransactionDetailsQuery } from './graphql/TransactionDetailsQuery'
import {
  TransactionDetailsQueryResponse,
  TransactionDetailsQueryVariables,
} from './graphql/TransactionDetailsQuery.generated'

interface UseTransactionDetailsQueryProps {
  debitCardTransactionUuid: string
}

const DETAILS_DEBOUNCE_IN_MS = 500

export const useTransactionDetailsQuery = ({
  debitCardTransactionUuid,
}: UseTransactionDetailsQueryProps) => {
  const { data, loading, error, refetch, networkStatus } = useQuery<
    TransactionDetailsQueryResponse,
    TransactionDetailsQueryVariables
  >(TransactionDetailsQuery, {
    variables: {
      debitCardTransactionUuid,
    },
    context: {
      debounceKey: 'debitCardTransactionDetails',
      debounceTimeout: DETAILS_DEBOUNCE_IN_MS,
    },
    notifyOnNetworkStatusChange: true,
  })

  return {
    isLoading: loading || networkStatus === NetworkStatus.refetch,
    transaction: data?.getDebitCardTransactionV2,
    error,
    refetch,
  }
}
