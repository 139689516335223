import { gql } from '@apollo/client'

export const CreateSite = gql`
  mutation CreateSite($input: CreateSiteInput!) {
    createSite(input: $input) {
      id
    }
  }
`

export const UpdateSite = gql`
  mutation UpdateSite($input: UpdateSiteInput!) {
    updateSite(input: $input)
  }
`

export const AssignDeviceToSite = gql`
  mutation AssignDeviceToSite($deviceUuid: ID!, $siteUuid: ID!) {
    assignDeviceToSite(deviceUuid: $deviceUuid, siteUuid: $siteUuid)
  }
`

export const RemoveSiteLogo = gql`
  mutation RemoveSiteLogo($siteUuid: ID!) {
    removeSiteLogo(siteUuid: $siteUuid)
  }
`

export const UpdateDeviceSettings = gql`
  mutation UpdateDeviceSettings($deviceSettings: UpdateDeviceSettingsInput!) {
    updateDeviceSettings(deviceSettings: $deviceSettings)
  }
`
