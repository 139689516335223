import { useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import {
  AccountCreated,
  Box,
  ButtonLink,
  DrawerWithIcon,
  Flex,
  MessageWithIcon,
  Typography,
} from '@npco/zeller-design-system'

import { ReactComponent as OracleLogo } from 'assets/svg/logos/oracle.svg'
import { ZELLER_SUPPORT_URL } from 'const/externalLinks'
import { ROOT } from 'const/routes'

import { translations } from './OraclePosConnection.i18n'

export const OraclePosConnection = () => {
  const navigate = useNavigate()
  const t = useTranslations(translations)
  return (
    <DrawerWithIcon
      isOpen
      icon={
        <Flex justifyContent="left">
          <OracleLogo width={48} height={48} />
        </Flex>
      }
      drawerTitle={t('title')}
      onClose={() => navigate(ROOT.PORTAL.SETTINGS.CONNECTIONS.path)}
      overlayClassName="animated-drawer-overlay"
    >
      <Flex flexDirection="column" height="100%">
        <Typography variant="body-base">{t('description')} </Typography>
        <Box pt="40px" />
        <MessageWithIcon
          title={t('contactSupport')}
          image={<AccountCreated size="large" />}
          description={
            <Typography variant="body-sm">
              {t('contactSupportDescription', {
                support: (
                  <ButtonLink
                    isInline
                    onClick={() => {
                      window.open(ZELLER_SUPPORT_URL, '_blank', 'noopener')
                    }}
                  >
                    <Typography variant="body-sm">
                      {t('zellerSupport')}
                    </Typography>
                  </ButtonLink>
                ),
              })}
            </Typography>
          }
        />
      </Flex>
    </DrawerWithIcon>
  )
}
