import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type ValidateBpayPaymentQueryVariables = Types.Exact<{
  input: Types.ValidateBpayPaymentInput
}>

export type ValidateBpayPaymentQueryResponse = {
  __typename?: 'Query'
  validateBpayPayment: {
    __typename?: 'ValidateBpayPaymentResponse'
    isValid: boolean
    invalidReason: {
      __typename?: 'InvalidBpayPaymentReason'
      responseCode: string
      errorDescription: string
    } | null
  }
}

export const ValidateBpayPaymentQuery = gql`
  query ValidateBpayPaymentQuery($input: ValidateBpayPaymentInput!) {
    validateBpayPayment(input: $input) {
      isValid
      invalidReason {
        responseCode
        errorDescription
      }
    }
  }
` as unknown as TypedDocumentNode<
  ValidateBpayPaymentQueryResponse,
  ValidateBpayPaymentQueryVariables
>

/**
 * __useValidateBpayPaymentQuery__
 *
 * To run a query within a React component, call `useValidateBpayPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateBpayPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateBpayPaymentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateBpayPaymentQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateBpayPaymentQueryResponse,
    ValidateBpayPaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ValidateBpayPaymentQueryResponse,
    ValidateBpayPaymentQueryVariables
  >(ValidateBpayPaymentQuery, options)
}
export function useValidateBpayPaymentQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateBpayPaymentQueryResponse,
    ValidateBpayPaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ValidateBpayPaymentQueryResponse,
    ValidateBpayPaymentQueryVariables
  >(ValidateBpayPaymentQuery, options)
}
export type ValidateBpayPaymentQueryHookResult = ReturnType<
  typeof useValidateBpayPaymentQuery
>
export type ValidateBpayPaymentQueryLazyQueryHookResult = ReturnType<
  typeof useValidateBpayPaymentQueryLazyQuery
>
export type ValidateBpayPaymentQueryQueryResult = Apollo.QueryResult<
  ValidateBpayPaymentQueryResponse,
  ValidateBpayPaymentQueryVariables
>
