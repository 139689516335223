import { useEffect, useState } from 'react'
import { rvProfileSettings } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import VerificationImagePath from 'assets/images/verification-required.png'
import { CancelButton } from 'components/Modal/ModalElements/ModalElements'
import { component } from 'translations'

import {
  StyledImage,
  StyledVerificationCopy,
  StyledVerificationHeader,
  StyledVerificationRedirect,
  StyledVerificationWrapper,
} from './ModalVerificationRequired.styled'

interface Props {
  onCancel: () => void
  onRedirect: () => void
  header?: string
  informationCopy?: string
  actionCopy?: string
  actionCopyEnding?: string
  redirectTimer?: number
}
const defaultCopies = {
  header: component.modalVerificationRequired.defaultHeader,
  informationCopy: component.modalVerificationRequired.defaultInformationCopy,
  actionCopy: component.modalVerificationRequired.defaultActionCopy,
  actionCopyEnding: component.modalVerificationRequired.defaultActionCopyEnding,
}

const LAST_DIGITS_PHONE_NUMBER = 4

const defaultRedirectTimer = 5
export const ModalVerificationRequired = ({
  onCancel,
  onRedirect,
  header = defaultCopies.header,
  informationCopy = defaultCopies.informationCopy,
  actionCopy = defaultCopies.actionCopy,
  actionCopyEnding = defaultCopies.actionCopyEnding,
  redirectTimer = defaultRedirectTimer,
}: Props) => {
  const [timer, setTimer] = useState<number>(redirectTimer)
  const customer = rvProfileSettings()
  const [phoneNumber] = useState<string | null>(
    () => customer?.phone?.slice(-LAST_DIGITS_PHONE_NUMBER) || null
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((currentTime) => currentTime - 1)
    }, 1000)
    if (timer === 0) {
      clearInterval(interval)
      onRedirect()
    }
    return () => clearInterval(interval)
  }, [onRedirect, timer])

  return (
    <StyledVerificationWrapper data-testid="verify-modal-wrapper">
      <StyledImage src={VerificationImagePath} />
      <StyledVerificationHeader>{header}</StyledVerificationHeader>
      <StyledVerificationCopy>{informationCopy}</StyledVerificationCopy>
      <StyledVerificationCopy>
        {actionCopy}&nbsp;
        <span>
          {actionCopyEnding}&nbsp;{phoneNumber}
        </span>
      </StyledVerificationCopy>
      <StyledVerificationRedirect>
        {component.modalVerificationRequired.redirecting} {timer}
      </StyledVerificationRedirect>
      <CancelButton onClick={onCancel}>Cancel</CancelButton>
    </StyledVerificationWrapper>
  )
}
