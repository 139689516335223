import { SvgIcon } from '@npco/zeller-design-system'

import { StyledContainer, StyledInput } from './Search.styled'

export interface SearchProps {
  value: string
  icon: React.FC
  isSmall?: boolean
  onChange?: (e: React.SyntheticEvent) => void
  placeholder?: string
}

export const Search = ({
  icon: Icon,
  isSmall = false,
  onChange,
  ...rest
}: SearchProps) => (
  <StyledContainer data-testid="search" isSmall={isSmall}>
    <SvgIcon width="16" height="16">
      <Icon />
    </SvgIcon>
    <StyledInput
      data-testid="search-input"
      isSmall={isSmall}
      onChange={onChange}
      {...rest}
    />
  </StyledContainer>
)
