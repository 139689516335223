import { createTranslations } from '@npco/utils-translations'

export const signUpSidebarTranslations = createTranslations({
  title: 'Australia’s favourite way to get paid just got better.',
  subtitle: 'The next generation of payments is here.',
  benefit1: 'Accept payments from cards and devices.',
  benefit2: 'Zeller POS Lite built-in to Zeller Terminal 2.',
  benefit3: 'Free Transaction Accounts and Debit Cards.',
  benefit4: 'Send online invoices for free.',
  benefit5: '7-day support via phone and email.',
})
