import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  ButtonLink,
  Flex,
  Heading,
  HeadingStyles,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_SIZE,
} from '@npco/zeller-design-system'
import { rvNewUserType } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ZELLER_SUPPORT_USER_ACCESS_LEVELS_URL } from 'const/externalLinks'
import { ROOT } from 'const/routes'
import { useRedirectToUsers } from 'utils/settings'
import { SettingsFormLayout } from 'layouts'
import { Radiobutton } from 'components/Input/Radiobutton'
import { translationsShared } from 'translations'

import { translations } from './CreateUserType.i18n'
import * as styled from './CreateUserType.styled'

const { ADMIN, MANAGER } = CustomerRole

export interface CreateUserTypeProps {
  isUsers?: boolean
}

export const CreateUserType = ({ isUsers = false }: CreateUserTypeProps) => {
  const [type, setType] = useState(rvNewUserType())

  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const navigate = useNavigate()
  const redirect = useRedirectToUsers(isUsers)

  const submitRoute = useMemo(() => {
    if (isUsers) {
      return ROOT.PORTAL.SETTINGS.USERS.USER_CREATE_DETAILS.path
    }
    return ROOT.PORTAL.PAYMENTS.SITES.SITE.USERS.CREATE_DETAILS.path
  }, [isUsers])

  const onClickStyledTile = useCallback((user: CustomerRole) => {
    setType(user)
    rvNewUserType(user)
  }, [])

  return (
    <SettingsFormLayout
      dataTestId="create-user-type"
      onBackButtonClick={() => redirect()}
      onSubmitButtonClick={() => navigate(submitRoute)}
      submitButtonLabel={tShared('next')}
      isSubmitButtonDisabled={!type}
    >
      <styled.StyledWrapper>
        <InfoBox priority={INFOBOX_PRIORITY.DEFAULT} size={INFOBOX_SIZE.SMALL}>
          <span>
            {t('info', {
              link: (
                <ButtonLink
                  isInline
                  onClick={() =>
                    window.open(
                      ZELLER_SUPPORT_USER_ACCESS_LEVELS_URL,
                      '_blank',
                      'noopener'
                    )
                  }
                >
                  {t('infoLinkText')}
                </ButtonLink>
              ),
            })}
          </span>
        </InfoBox>
        <styled.Tile
          $isActive={type === ADMIN}
          onClick={() => onClickStyledTile(ADMIN)}
        >
          <Flex flexDirection="column" flex={1} gap="8px">
            <Heading.H2 withStyles={HeadingStyles.H5}>
              {t('adminTitle')}
            </Heading.H2>
            <styled.TileDescription>
              {t('adminDescription')}
            </styled.TileDescription>
          </Flex>
          <Box alignSelf="flex-start">
            <Radiobutton
              id="admin"
              name="admin"
              checked={type === ADMIN}
              onChange={() => undefined}
              withoutMargin
            />
          </Box>
        </styled.Tile>
        <styled.Tile
          $isActive={type === MANAGER}
          onClick={() => onClickStyledTile(MANAGER)}
        >
          <Flex flexDirection="column" flex={1} gap="8px">
            <Heading.H2 withStyles={HeadingStyles.H5}>
              {t('managerTitle')}
            </Heading.H2>
            <styled.TileDescription>
              {t('managerDescription')}
            </styled.TileDescription>
          </Flex>
          <Box alignSelf="flex-start">
            <Radiobutton
              id="manager"
              name="manager"
              checked={type === MANAGER}
              onChange={() => undefined}
              withoutMargin
            />
          </Box>
        </styled.Tile>
      </styled.StyledWrapper>
    </SettingsFormLayout>
  )
}
