import { gql } from '@apollo/client'

export const CancelSim = gql`
  mutation CancelSim($simId: ID!) {
    cancelSim(simId: $simId)
  }
`

export const AbortSimCancellation = gql`
  mutation AbortSimCancellation($simId: ID!) {
    abortSimCancellation(simId: $simId)
  }
`

export const ReactivateSim = gql`
  mutation ReactivateSim($simId: ID!) {
    activateSim(simId: $simId)
  }
`

export const SelectBillingAccount = gql`
  mutation SelectBillingAccount($input: SelectBillingAccountInput!) {
    selectBillingAccount(input: $input)
  }
`
