import { FormikHelpers } from 'formik'

import { ItemBaseModalForm } from '../ItemBaseModalForm'
import { EditItemFormFields } from '../ItemBaseModalForm.types'

export interface ItemsEditModalFormProps<T> {
  initialValues: T
  isUpdatingItem: boolean
  onClose: () => void
  onSubmit: (values: T, helpers: FormikHelpers<T>) => void
  title: string
  skuDuplicateError?: string
}

export const ItemEditModalForm = <T extends EditItemFormFields>({
  isUpdatingItem: isLoading,
  ...props
}: ItemsEditModalFormProps<T>) => {
  return <ItemBaseModalForm isLoading={isLoading} {...props} />
}
