import { useQuery } from '@apollo/client'
import { SiteType } from '@npco/mp-gql-types'
import { GetSite } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/sites'
import {
  rvSiteDetails,
  rvSiteFetchError,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables/sites'

import { GetCustomerDetails_getCustomer as GetCustomerDetailsCustomer } from 'types/gql-types/GetCustomerDetails'
import {
  GetSite as GetSiteType,
  GetSite_getSite as siteData,
  GetSite_getSite_devices as GetSiteDevices,
  GetSite_getSite_moto as Moto,
  GetSite_getSite_schemes as Schemes,
  GetSite_getSite_schemesMoto as SchemesMoto,
  GetSite_getSite_surchargesTaxes as SurchargesTaxes,
  GetSiteVariables,
} from 'types/gql-types/GetSite'
import { ReceiptProps } from 'types/settings'

export const updateSiteDetails = (site: siteData) => {
  rvSiteDetails({
    id: site.id,
    name: site.name,
    devices: site.devices as GetSiteDevices[],
    pin: site?.pin,
    type: site.type as SiteType,
    address: site.address,
    customers: site.customers as GetCustomerDetailsCustomer[],
    surchargesTaxes: site.surchargesTaxes as SurchargesTaxes,
    moto: site.moto as Moto,
    tipping: site.tipping,
    schemes: site.schemes as Schemes[],
    schemesMoto: site.schemesMoto as SchemesMoto[],
    receipt: site.receipt as ReceiptProps,
    refundRequiresPin: site.refundRequiresPin,
    refundPinType: site.refundPinType,
    refundPin: site.refundPin,
    features: {
      restrictReportAccessEnabled: Boolean(
        site.features?.restrictReportAccessEnabled
      ),
      splitPaymentEnabled: Boolean(site.features?.splitPaymentEnabled),
    },
    screensaver: site.screensaver,
    vtEnabled: site.vtEnabled,
  })
}

export const useGetSite = (siteUuid: string) => {
  const { data, loading } = useQuery<GetSiteType, GetSiteVariables>(GetSite, {
    variables: {
      siteUuid,
    },
    onCompleted: (response) => {
      updateSiteDetails(response.getSite)
      rvSiteFetchError(null)
    },
    onError: (response) => {
      rvSiteFetchError(response)
    },
    fetchPolicy: 'network-only',
  })

  return { siteData: data?.getSite, isLoading: loading }
}
