import { useEffect, useMemo, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { GetSitesForPricing } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/sites'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import {
  GetSitesForPricing as GetSitesType,
  GetSitesForPricing_getSites_nextToken as NextToken,
  GetSitesForPricing_getSites_sites as SiteData,
  GetSitesForPricingVariables as GetSitesVariables,
} from 'types/gql-types/GetSitesForPricing'

import {
  getSitesWithFeesAndPricing,
  getSitesWithUniqueId,
} from './FeesAndPricing.utils'

export const SITES_LIMIT = 100
export const useSitesWithFeesAndPricing = () => {
  const sitesVariable = useMemo(() => ({ limit: SITES_LIMIT }), [])

  const client = useApolloClient()

  const [allSites, setAllSites] = useState<SiteData[]>([])

  const [hasError, setHasError] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const fetchSites = async (
    currentSites: SiteData[],
    nextToken?: NextToken
  ): Promise<SiteData[]> => {
    const { data, error } = await client.query<GetSitesType, GetSitesVariables>(
      {
        query: GetSitesForPricing,
        fetchPolicy: 'no-cache',
        variables: { ...sitesVariable, nextToken: nextToken || undefined },
        errorPolicy: 'all',
      }
    )

    if (!data?.getSites?.sites) {
      throw new Error(
        `fail to get sites for fees and pricing: ${error?.message}`
      )
    }

    const newNextToken = data?.getSites?.nextToken
    if (newNextToken?.type) {
      return fetchSites(
        getSitesWithUniqueId(currentSites, data?.getSites.sites),
        newNextToken
      )
    }
    return getSitesWithUniqueId(currentSites, data?.getSites.sites)
  }

  useEffect(() => {
    async function getAllSites() {
      setIsLoading(true)
      try {
        const sites = await fetchSites([])
        const entityFees = rvEntityDetails().feeRateSettings
        if (!entityFees) {
          throw new Error('no entity fees and pricing')
        }

        const sitesWithFeesAndPricing = getSitesWithFeesAndPricing(
          sites,
          entityFees
        )
        setAllSites(sitesWithFeesAndPricing)
        setHasError(false)
      } catch (e: any) {
        setAllSites([])
        setHasError(true)
      }
      setIsLoading(false)
    }
    getAllSites()
    // componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    error: hasError,
    isLoading,
    allSites,
  }
}
