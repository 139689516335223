import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { DebitCardStatus } from '@npco/mp-gql-types'
import { ErrorLogger } from '@npco/utils-error-logger'
import { UnlockDebitCard } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/card'

import { cacheDebitCardStatus } from 'utils/banking/cacheDebitCardStatus/cacheDebitCardStatus'
import { UNEXPECTED_ERROR } from 'types/errors'
import {
  UnlockDebitCard as UnlockDebitCardResponse,
  UnlockDebitCardVariables,
} from 'types/gql-types/UnlockDebitCard'

export const useUnlockCard = (cardId: string | undefined) => {
  const [unlockCardMutation, { loading }] = useMutation<
    UnlockDebitCardResponse,
    UnlockDebitCardVariables
  >(UnlockDebitCard, {
    update: (cache, result) => {
      if (result.data?.unlockDebitCard && cardId) {
        cacheDebitCardStatus({
          cache,
          cardId,
          status: DebitCardStatus.ACTIVE,
        })
      }
    },
  })

  const unlockCard = useCallback(async () => {
    try {
      const lockCardResult = await unlockCardMutation({
        variables: {
          cardId: { cardUuid: cardId },
        },
      })

      if (!lockCardResult.data?.unlockDebitCard) {
        throw new Error('Failed to lock card with false flag')
      }

      return lockCardResult.data.unlockDebitCard
    } catch (err) {
      ErrorLogger.report('[Banking] Error locking card', err)
      return UNEXPECTED_ERROR
    }
  }, [unlockCardMutation, cardId])

  return { unlockCard, isUnlockingCard: loading }
}
