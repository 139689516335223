import { useCallback } from 'react'

import { UNEXPECTED_ERROR } from '../../../../const/errors'
import { cacheNewSavingsAccount } from '../../utils/cacheNewSavingsAccount'
import { useActivateSavingsAccountMutation } from './graphql/ActivateSavingsAccount.generated'
import {
  NewSavingsAccountFragment,
  NewSavingsAccountFragmentDoc,
} from './graphql/NewSavingsAccountFragment.generated'

type ActivateSavingsAccountArgs = {
  tfn: string
}

export const useActivateSavingsAccount = () => {
  const [
    activateSavingsAccountMutation,
    { loading: isActivatingSavingsAccount },
  ] = useActivateSavingsAccountMutation({
    update: (cache, result) => {
      if (
        // eslint-disable-next-line no-underscore-dangle
        result.data?.createSavingsAccount.__typename === 'DebitCardAccountV2'
      ) {
        cache.writeFragment<NewSavingsAccountFragment>({
          fragment: NewSavingsAccountFragmentDoc,
          id: cache.identify({
            __typename: 'DebitCardAccountV2',
            id: result.data.createSavingsAccount.id,
          }),
          data: result.data.createSavingsAccount,
        })
        cacheNewSavingsAccount(
          cache,
          {
            ...result.data.createSavingsAccount,
            __typename: 'DebitCardAccountV2',
          },
          'start-of-list'
        )
      }
    },
  })

  const activateSavingsAccount = useCallback(
    async ({ tfn }: ActivateSavingsAccountArgs) => {
      try {
        const result = await activateSavingsAccountMutation({
          variables: { tfn },
        })

        if (
          // eslint-disable-next-line no-underscore-dangle
          result.data?.createSavingsAccount.__typename !== 'DebitCardAccountV2'
        ) {
          return UNEXPECTED_ERROR
        }

        return result.data.createSavingsAccount
      } catch (err) {
        return UNEXPECTED_ERROR
      }
    },
    [activateSavingsAccountMutation]
  )

  return {
    activateSavingsAccount,
    isActivatingSavingsAccount,
  }
}
