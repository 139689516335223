import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useMutation, useReactiveVar } from '@apollo/client'
import { SiteType } from '@npco/mp-gql-types'
import { Box, Flex, showApiErrorToast } from '@npco/zeller-design-system'
import { UpdateSite } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/sites'
import { rvSelectedSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvSiteDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/sites'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'
import { Formik } from 'formik'

import { ROOT } from 'const/routes'
import { SITE_TYPE } from 'const/settings'
import {
  UpdateSite as UpdateSiteType,
  UpdateSiteVariables,
} from 'types/gql-types/UpdateSite'
import { Site, SiteCache } from 'types/site'
import {
  composeUpdateData,
  getIsSiteFormDisabled,
} from 'pages/Settings/SiteGeneral/SiteGeneral.helpers'
import { SettingsFormLayout, SettingsPageLayout, Title } from 'layouts'
import { Site as SiteForm } from 'forms/formViews/Site'
import {
  CancelButton,
  ConfirmButton,
  ModalDescription,
} from 'components/Modal/ModalElements/ModalElements'
import { component, page, shared } from 'translations'

export const SiteGeneralEdit = () => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  const siteDetails = useReactiveVar(rvSiteDetails) as SiteCache

  const navigate = useNavigate()

  const [updateSite, { loading: updateSiteLoading }] = useMutation<
    UpdateSiteType,
    UpdateSiteVariables
  >(UpdateSite, {
    onError: (error) => {
      showApiErrorToast(error)
    },
  })

  useEffect(() => {
    if (!siteDetails) {
      showApiErrorToast()

      navigate(ROOT.PORTAL.PAYMENTS.SITES.path)
    }
  }, [siteDetails, navigate])

  const onSubmit = async (values: Site) => {
    const updatedData = composeUpdateData(values, siteDetails)
    const response = await updateSite({
      variables: {
        input: {
          id: rvSelectedSite(),
          ...updatedData,
        },
      },
    })

    if (response.data) {
      rvSiteDetails({
        ...siteDetails,
        ...updatedData,
      })

      navigate(ROOT.PORTAL.PAYMENTS.SITES.SITE.GENERAL.path)
    }
  }

  if (!siteDetails) {
    return null
  }

  return (
    <SettingsPageLayout>
      <Formik<Site>
        enableReinitialize
        initialValues={{
          name: siteDetails?.name,
          pin: siteDetails?.pin,
          postcode: siteDetails.address?.postcode ?? '',
          state: siteDetails.address?.state ?? '',
          street: siteDetails.address?.street ?? '',
          suburb: siteDetails.address?.suburb ?? '',
          siteType:
            siteDetails.type === SiteType.FIXED
              ? SITE_TYPE.FIXED
              : SITE_TYPE.MOBILE,
          businessName: siteDetails.receipt?.name ?? '',
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, submitForm, dirty }) => {
          const isFormDisabled = getIsSiteFormDisabled(values)

          return (
            <SettingsFormLayout
              dataTestId="site-general-edit"
              isSubmitButtonDisabled={isSubmitting || isFormDisabled}
              onBackButtonClick={() =>
                dirty
                  ? openModal()
                  : navigate(ROOT.PORTAL.PAYMENTS.SITES.SITE.GENERAL.path)
              }
              onSubmitButtonClick={submitForm}
              isLoading={updateSiteLoading}
              customHeight="100%"
            >
              <Title>{page.settings.general.title}</Title>
              <SiteForm />
              <ModalBasic
                title={component.modal.discardChangesModal.title}
                isOpen={isModalOpen}
                onCancel={closeModal}
              >
                <Box>
                  <ModalDescription>
                    {component.modal.discardChangesModal.description}
                  </ModalDescription>
                  <Flex>
                    <ConfirmButton
                      isLoading={updateSiteLoading}
                      onClick={() =>
                        navigate(ROOT.PORTAL.PAYMENTS.SITES.SITE.GENERAL.path)
                      }
                    >
                      {component.modal.discardChangesModal.primaryButtonLabel}
                    </ConfirmButton>
                    <CancelButton onClick={closeModal}>
                      {shared.cancel}
                    </CancelButton>
                  </Flex>
                </Box>
              </ModalBasic>
            </SettingsFormLayout>
          )
        }}
      </Formik>
    </SettingsPageLayout>
  )
}
