import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CardAccountCellDebitCardV2FragmentDoc } from '../components/CardAccountCell/CardAccountCell.generated'
import { CorporateCardBalanceCellDebitCardV2FragmentDoc } from '../components/CardBalanceCell/CorporateCardBalanceCell.generated'
import { CardNameIconProgressCellDebitCardV2FragmentDoc } from '../components/CardNameIconCell/CardNameIconProgressCell.generated'
import { CardOwnerCellDebitCardV2FragmentDoc } from '../components/CardOwnerCell/CardOwnerCell.generated'
import { CardSpendProgressCellDebitCardV2FragmentDoc } from '../components/CardSpendProgressCell/CardSpendProgressCell.generated'
import { CardTransactionsMissingDetailsCountCellDebitCardV2FragmentDoc } from '../components/CardTransactionsMissingDetailsCountCell/CardTransactionsMissingDetailsCountCell.generated'

export type CorporateCardsTableDebitCardV2Fragment = {
  __typename: 'DebitCardV2'
  name: string
  owner: string | null
  outstandingTransactions: number | null
  id: string
  status: Types.DebitCardStatus
  colour: Types.DebitCardColour
  maskedPan: string
  debitCardAccount: {
    __typename: 'DebitCardAccountV2'
    id: string
    name: string
    icon: { __typename?: 'Icon'; colour: string | null } | null
  }
  customer: { __typename?: 'Customer'; id: string } | null
  velocityControl: {
    __typename?: 'VelocityControlType'
    resetsAt: any
    velocityWindow: Types.VelocityWindowEnum
    availableAmount: { __typename?: 'Money'; value: string }
    amountLimit: { __typename?: 'Money'; value: string }
    totalSpentAmount: { __typename?: 'Money'; value: string }
  } | null
}

export const CorporateCardsTableDebitCardV2FragmentDoc = gql`
  fragment CorporateCardsTableDebitCardV2Fragment on DebitCardV2 {
    __typename
    ...CardNameIconProgressCellDebitCardV2Fragment
    ...CardOwnerCellDebitCardV2Fragment
    ...CardAccountCellDebitCardV2Fragment
    ...CardTransactionsMissingDetailsCountCellDebitCardV2Fragment
    ...CardSpendProgressCellDebitCardV2Fragment
    ...CorporateCardBalanceCellDebitCardV2Fragment
  }
  ${CardNameIconProgressCellDebitCardV2FragmentDoc}
  ${CardOwnerCellDebitCardV2FragmentDoc}
  ${CardAccountCellDebitCardV2FragmentDoc}
  ${CardTransactionsMissingDetailsCountCellDebitCardV2FragmentDoc}
  ${CardSpendProgressCellDebitCardV2FragmentDoc}
  ${CorporateCardBalanceCellDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  CorporateCardsTableDebitCardV2Fragment,
  undefined
>
