import { useMutation } from '@apollo/client'
import { LinkContacts } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/contacts'

import {
  LinkContacts as LinkContactsType,
  LinkContactsVariables,
} from 'types/gql-types/LinkContacts'

export const useLinkContacts = () => {
  const [linkContacts, { loading: isLinkingContacts }] = useMutation<
    LinkContactsType,
    LinkContactsVariables
  >(LinkContacts)

  return {
    linkContacts: (contact1Uuid: string, contact2Uuid: string) =>
      linkContacts({ variables: { contact1Uuid, contact2Uuid } }),
    isLinkingContacts,
  }
}
