import { useMemo, useState } from 'react'
import { gql } from '@apollo/client'

import { getCustomerDisplayName } from 'utils/customers'

import { SearchableCardholderFragment as SearchableCardholderFragmentType } from './useSearchCardholders.generated'

interface UseSearchCardholdersProps<
  T extends SearchableCardholderFragmentType
> {
  cardholders: T[]
}

export const useSearchCardholders = <
  T extends SearchableCardholderFragmentType
>({
  cardholders,
}: UseSearchCardholdersProps<T>) => {
  const [searchInput, setSearchInput] = useState('')

  const filteredCardholders = useMemo(() => {
    return cardholders.filter((cardholder) => {
      return getCustomerDisplayName(cardholder)
        .toLowerCase()
        .includes(searchInput.toLowerCase())
    })
  }, [cardholders, searchInput])

  return { searchInput, setSearchInput, filteredCardholders }
}

export const SearchableCardholderFragment = gql`
  fragment SearchableCardholderFragment on Customer {
    firstname
    lastname
  }
`
