import { TableCellIconText, TileIcon } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'
import { isNil } from 'lodash-es'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { CategoryTableData } from '../CategoriesTable.types'

export const CategoriesRowNameCell = (
  cellProps: CellContext<CategoryTableData, string>
) => {
  const isMobile = useIsMobileResolution()

  const { cell, getValue, row, table } = cellProps

  const isLoading = row.original.id === null || table.options.meta?.isLoading

  const icon = row.original.color ? (
    <TileIcon backgroundColor={row.original.color} />
  ) : null

  const value = getValue<string>()
  const text = isNil(value) ? '' : String(value)

  const desktopLoadingWidth = row.index % 2 ? '50%' : '70%'
  const mobileLoadingWidth = '100%'
  const width = isMobile ? mobileLoadingWidth : desktopLoadingWidth

  return (
    <TableCellIconText
      dataTestId={cell.id}
      forwardedProps={{ style: { padding: 0 } }}
      hasDivider={false}
      icon={icon}
      isLoading={isLoading}
      key={cell.id}
      skeletonProps={{ width }}
      text={text}
    />
  )
}
