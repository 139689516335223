import { AUSTRALIA_COUNTRY_CODE } from 'const/common'
import countryCodes from 'const/country-codes.json'

/**
 * Grab the 3-letter code country which is used in our app
 * @param alpha2Code 2-letter code country from google
 * @returns 3-letter code country from local country json
 */
export const toAlpha3CodeCountry = (alpha2Code?: string | null) => {
  // For most common use case, no need to execute find
  if (alpha2Code === 'AU') {
    return AUSTRALIA_COUNTRY_CODE
  }
  return alpha2Code
    ? countryCodes.find((country) => country.alpha2Code === alpha2Code)
        ?.alpha3Code
    : ''
}

/**
 * Grab 2-letter code used by external libraries
 * @param alpha3Code 3-letter country code from Zeller
 * @returns 2-letter country code
 */
export const toAlpha2CodeCountry = (alpha3Code?: string | null) => {
  // For most common use case, no need to execute find
  if (alpha3Code === AUSTRALIA_COUNTRY_CODE) {
    return 'AU'
  }
  return alpha3Code
    ? countryCodes.find((country) => country.alpha3Code === alpha3Code)
        ?.alpha2Code
    : ''
}
