import { useCallback, useRef } from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import { InvoiceStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Box, showErrorToast } from '@npco/zeller-design-system'
import { useGetInvoice } from 'features/Invoicing/components/Invoices/hooks/useGetInvoice'
import { Formik, setNestedObjectValues } from 'formik'

import { ROOT, ROUTE_PARAM_NAME } from 'const/routes'
import { ErrorType, ServerError } from 'types/errors'
import { BreadcrumbsBase } from 'components/Breadcrumbs/BreadcrumbsBase'

import { useGetZellerInvoiceSettings } from '../../../../Settings/hooks/useGetZellerInvoiceSettings'
import { useInvoicesBreadcrumbs } from '../../../hooks/useInvoicesBreadcrumbs/useInvoicesBreadcrumbs'
import { INVOICE_FORM_CONTAINER } from '../../Invoice.constants'
import { validateInvoiceForm } from '../../Invoice.utils'
import { initialValues as defaultInitialValues } from '../InvoiceCreateForm/InvoiceCreateForm.utils'
import { InvoiceFormClosePrompt } from '../InvoiceFormClosePrompt/InvoiceFormClosePrompt'
import { InvoiceLayout } from '../InvoiceLayout/InvoiceLayout'
import { InvoicePreview } from '../InvoicePreview/InvoicePreview'
import { InvoiceScheduleModal } from '../InvoiceScheduleModal/InvoiceScheduleModal'
import { InvoiceScheduleSuccessModal } from '../InvoiceScheduleSuccessModal/InvoiceScheduleSuccessModal'
import { InvoiceSendSuccessModal } from '../InvoiceSendSuccessModal/InvoiceSendSuccessModal'
import { InvoiceSkeletonLoader } from '../InvoiceSkeletonLoader/InvoiceSkeletonLoader'
import { useInvoiceUpdateFormHandlers } from './hooks/useInvoiceUpdateFormHandlers'
import { translations } from './InvoiceEditForm.i18n'
import { FormWrapper } from './InvoiceEditForm.styled'
import { convertGetInvoiceResponseToFormFields } from './InvoiceEditForm.utils'
import { InvoiceEditFormContent } from './InvoiceEditFormContent'
import { InvoiceEditFormSendModal } from './InvoiceEditFormSendModal/InvoiceEditFormSendModal'

const ALLOWED_INVOICE_STATUS_FOR_EDIT = [
  InvoiceStatus.DRAFT,
  InvoiceStatus.OVERDUE,
  InvoiceStatus.PART_PAID,
  InvoiceStatus.SCHEDULED,
  InvoiceStatus.SEND_FAILED,
  InvoiceStatus.SENT,
]

export const InvoiceEditForm = () => {
  const t = useTranslations(translations)

  const { invoiceRefNumber: referenceNumber = '' } = useParams<{
    [ROUTE_PARAM_NAME.PORTAL_INVOICE_REF_NUMBER]: string
  }>()

  const breadcrumbParts = useInvoicesBreadcrumbs()

  const {
    closePreviewModal,
    closeScheduleModal,
    closeSendModal,
    closeSendSuccessModal,
    closeScheduleSuccessModal,
    handleClickPreview,
    handleClickSettings,
    handleClose,
    handleSave,
    handleSend,
    invoicePdf,
    isLoadingPreview,
    isScheduleModalOpen,
    isSendingInvoice,
    isSendModalOpen,
    isSendSuccessModalOpen,
    isScheduleSuccessModalOpen,
    isUpdatingInvoice,
    redirectTo,
    setIsUpdateFormDirty,
  } = useInvoiceUpdateFormHandlers(referenceNumber)

  const { invoiceData, isLoading: isLoadingInvoice } = useGetInvoice({
    fetchPolicy: 'cache-and-network',
    referenceNumber,
    onCompleted: (data) => {
      if (!ALLOWED_INVOICE_STATUS_FOR_EDIT.includes(data.getInvoice.status)) {
        showErrorToast(t('getInvoiceError', { referenceNumber }))
        redirectTo(ROOT.PORTAL.INVOICING.INVOICES.INVOICE(referenceNumber).path)
      }
    },
    onError: (error) => {
      const isNotFound = error.graphQLErrors.some(
        (gqlError) =>
          (gqlError as unknown as ServerError).errorType === ErrorType.NOT_FOUND
      )

      const errorMessage = t(
        isNotFound ? 'invoiceDoesNotExist' : 'getInvoiceError',
        { referenceNumber }
      )

      showErrorToast(errorMessage)

      redirectTo(
        isNotFound
          ? ROOT.PORTAL.INVOICING.INVOICES.path
          : ROOT.PORTAL.INVOICING.INVOICES.INVOICE(referenceNumber).path
      )
    },
  })

  const { isLoading: isLoadingInvoiceSettings, invoiceSettings } =
    useGetZellerInvoiceSettings()

  const invoice = invoiceData?.getInvoice ?? null
  const closeRedirectUrl = useRef('')
  const isSendingInvoiceRef = useRef(false)

  const handleSubmitEditForm =
    (isSending: boolean, handleSubmitCallback: () => void) => () => {
      isSendingInvoiceRef.current = isSending
      handleSubmitCallback()
    }

  const handleScheduleSendFromModal =
    (callback: () => Promise<void>) => async () => {
      isSendingInvoiceRef.current = true
      await callback()
      closeScheduleModal()
    }

  const handleRedirect = () => {
    if (!closeRedirectUrl.current) {
      closeSendModal()
      return
    }
    redirectTo(closeRedirectUrl.current)
  }

  const handleSubmitForm = useCallback(
    async (values, { resetForm }) => {
      const reset = () =>
        resetForm({
          errors: {},
          values,
          // Reset touched fields on save to apply correct accordion form states
          touched: setNestedObjectValues(values, true),
        })

      if (isSendingInvoiceRef.current) {
        await handleSend(values)
        // Reset form to prevent close prompt to show on redirect
        reset()
        return
      }

      const isSuccess = await handleSave(values, closeRedirectUrl.current)
      if (!closeRedirectUrl.current && isSuccess) {
        reset()
      }
    },
    [handleSave, handleSend]
  )

  const handleValidateForm = useCallback(
    (values) => {
      if (invoice && invoiceSettings) {
        const initialValues = convertGetInvoiceResponseToFormFields(
          invoice,
          invoiceSettings
        )

        const isDraft = values.status === InvoiceStatus.DRAFT
        const isFullValidation = !isDraft || isSendingInvoiceRef.current

        return validateInvoiceForm({
          initialValues,
          isFullValidation,
          values,
        })
      }

      return {}
    },
    [invoice, invoiceSettings]
  )

  return (
    <Formik
      initialValues={defaultInitialValues}
      initialStatus={{ hasResetInitialValues: false }}
      onSubmit={handleSubmitForm}
      validate={handleValidateForm}
    >
      {({ dirty, handleSubmit, submitForm, values }) => {
        setIsUpdateFormDirty(dirty)
        return (
          <FormWrapper
            data-testid="invoicing-edit-invoice-form"
            onSubmit={handleSubmit}
          >
            <InvoiceLayout
              isLoadingPreview={isLoadingPreview}
              isSavingInvoice={isUpdatingInvoice && !closeRedirectUrl.current}
              isScheduled={values.schedule.sendEnabled}
              isSendingInvoice={isSendingInvoice}
              Title={<BreadcrumbsBase breadcrumbParts={breadcrumbParts} />}
              onClickClose={handleClose}
              onClickPreview={() => handleClickPreview(values)}
              onClickSave={handleSubmitEditForm(false, submitForm)}
              onClickSend={handleSubmitEditForm(true, submitForm)}
              onClickSettings={handleClickSettings}
            >
              <Box
                id={INVOICE_FORM_CONTAINER}
                data-testid="invoicing-edit-invoice-container"
                position="relative"
              >
                {isLoadingInvoice || isLoadingInvoiceSettings ? (
                  <InvoiceSkeletonLoader dataTestId="invoice-edit-loader" />
                ) : (
                  <InvoiceEditFormContent
                    invoice={invoice}
                    invoiceSettings={invoiceSettings}
                    isInvoiceScheduled={values.schedule.sendEnabled}
                    isSendingInvoice={isSendingInvoiceRef.current}
                    shouldResetInitialExpandedItem={!isSendSuccessModalOpen}
                  />
                )}
              </Box>
              <InvoiceSendSuccessModal
                isOpen={isSendSuccessModalOpen}
                onCancel={closeSendSuccessModal}
                onClickPrimary={handleClose}
                onClickSecondary={() => {
                  closeSendSuccessModal()
                  redirectTo(ROOT.PORTAL.INVOICING.INVOICES.NEW.path)
                }}
                payerName={values.customer.payerContact?.contactName ?? ''}
                referenceNumber={referenceNumber}
              />
              <InvoiceScheduleSuccessModal
                isOpen={isScheduleSuccessModalOpen}
                onCancel={closeScheduleSuccessModal}
                onClickPrimary={handleClose}
                onClickSecondary={() => {
                  closeScheduleSuccessModal()
                  redirectTo(ROOT.PORTAL.INVOICING.INVOICES.NEW.path)
                }}
                payerName={values.customer.payerContact?.contactName ?? ''}
                referenceNumber={referenceNumber}
                sendDate={values.schedule.sendDate}
              />
              <InvoiceFormClosePrompt
                isLoading={isUpdatingInvoice || isSendingInvoice}
                onDiscardChanges={handleRedirect}
                onSave={handleSubmitEditForm(false, submitForm)}
                onRedirectPrompt={(redirectUrl) => {
                  closeRedirectUrl.current = redirectUrl
                }}
                shouldClosePrompt={isSendModalOpen}
              />
            </InvoiceLayout>
            <InvoiceEditFormSendModal
              isLoading={isSendingInvoice}
              isOpen={isSendModalOpen}
              closeModal={closeSendModal}
              onSend={handleSubmitEditForm(true, submitForm)}
              onCancel={handleRedirect}
            />
            <InvoiceScheduleModal
              isLoading={isSendingInvoice}
              isOpen={isScheduleModalOpen}
              closeModal={closeScheduleModal}
              onSend={handleScheduleSendFromModal(submitForm)}
            />
            {invoicePdf && (
              <InvoicePreview
                invoiceId={invoice?.id}
                pdfFile={invoicePdf}
                onClose={closePreviewModal}
                referenceNumber={referenceNumber}
              />
            )}
          </FormWrapper>
        )
      }}
    </Formik>
  )
}
