import { useCallback } from 'react'
import { ApolloError } from '@apollo/client'
import { ErrorLogger } from '@npco/utils-error-logger'

import { useStartupModalsQuery } from './graphql/StartupModalsQuery.generated'

export const useStartupModalsData = () => {
  const logErrorsToSentry = useCallback((err: ApolloError) => {
    if (!err.graphQLErrors) {
      // Don't log non graphql (network) errors to sentry to reduce noise
      return
    }

    ErrorLogger.reportError(
      '[SetupModals] - Failed to load setup modals data',
      err
    )
  }, [])

  const { data, loading } = useStartupModalsQuery({
    errorPolicy: 'all',
    onError: logErrorsToSentry,
  })

  return {
    xeroBankFeed: data?.xeroBankFeed ?? undefined,
    xeroPaymentServices: data?.xeroPaymentServices ?? undefined,
    isLoading: loading,
  }
}
