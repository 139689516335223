import { Dispatch, SetStateAction } from 'react'
import { slideSideAnimationVariants } from '@npco/zeller-design-system'

import { TransferStateCondition as Condition } from 'pages/Transfer/states/TransferState/TransferStateCondition'
import { TransferGeneralState } from 'pages/Transfer/Transfer.stateMachine'
import { useTransferActionEvents } from 'pages/Transfer/TransferFields/TransferActionButtons/TransferActionButtons.hooks'
import { StyledActionButtonsWrapper } from 'pages/Transfer/TransferFields/TransferActionButtons/TransferActionButtons.styled'
import { TransferSubmitButton } from 'pages/Transfer/TransferFields/TransferSubmitButton/TransferSubmitButton'
import { ActionButtons } from 'components/Buttons/ActionButtonsGroup/ActionButtonsGroup.styled'
import { ButtonBack } from 'components/Buttons/ButtonBack'

interface TransferActionButtonsProps {
  setContactValidationError: Dispatch<SetStateAction<string | undefined>>
}

export const TransferActionButtons = ({
  setContactValidationError,
}: TransferActionButtonsProps) => {
  const { sendBackEvent } = useTransferActionEvents()

  return (
    <StyledActionButtonsWrapper>
      <Condition
        animationVariants={slideSideAnimationVariants}
        allowedStates={[
          TransferGeneralState.BpayTransfer,
          TransferGeneralState.ContactTransfer,
          TransferGeneralState.ZellerTransfer,
        ]}
      >
        <ActionButtons>
          <TransferSubmitButton
            setContactValidationError={setContactValidationError}
          />
          <ButtonBack onClick={sendBackEvent} />
        </ActionButtons>
      </Condition>
    </StyledActionButtonsWrapper>
  )
}
