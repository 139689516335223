import { gql } from '@apollo/client'

export const CheckForAdditionalEntityInfo = gql`
  query CheckForAdditionalEntityInfo {
    checkForAdditionalEntityInfo
  }
`

export const RetrieveEntityOnboardingDetails = gql`
  query RetrieveEntityOnboardingDetails {
    retrieveEntityOnboardingDetails {
      entityUuid
      flowFlags
      type
      name
      goodsServicesProvided
      customerDiscovery
      website
      instagram
      facebook
      twitter
      tradingName
      hasNoTradingName
      regulatorBody {
        name
        referenceNumber
        type
      }
      registeredAddress {
        street1
        street2
        suburb
        state
        postcode
      }
      businessAddress {
        street1
        street2
        suburb
        state
        postcode
      }
      members {
        abn
        acn
        address {
          postcode
          state
          street
          suburb
          country
        }
        beneficialOwner
        beneficialOwnerAlt
        beneficiary
        ceo
        companyTrustName
        director
        dob
        firstname
        lastname
        middlename
        partner
        secretary
        settlor
        shareholder
        trustee
        type
        temporaryId
        isCurrentUser
        treasurer
        chair
        companyProfileData
      }
      categoryGroup
      category
      estimatedAnnualRevenue
      lastCheckPoint
      lastRoute
      isAfterFullSearch
      onboardingFlowType
      governmentRole
      initialCustomerData {
        street
        suburb
        state
        postcode
        country
        roles
        companyTrustName
        abn
        firstName
        lastName
        middleName
        dob
        temporaryId
      }
      helperFields {
        isNotRegulated
        hasNoPlaceOfBusiness
        settledSum
        trustee
      }
      membersFilters {
        director
        beneficialOwner
        settlor
        trustee
        beneficiary
        partner
        chair
        secretary
        treasurer
      }
      uploadedFileNames
      kycInitialData {
        documents
        isAgreed
        attempts {
          DRIVING_LICENCE
          MEDICARE_CARD
          PASSPORT
        }
        personalData {
          street
          suburb
          state
          postcode
          firstName
          lastName
          middleName
          dob
        }
      }
    }
  }
`
