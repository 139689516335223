import { useMemo } from 'react'
import { Box } from '@npco/zeller-design-system'
import { Form, Formik } from 'formik'

import { AnalyticsEventNames } from 'services/Analytics/events'
import { ContactType as AnalyticsContactType } from 'services/Analytics/events/contact'
import { useAnalyticsContext } from 'services/Analytics/useAnalyticsContext'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import { ModalFormScrollable } from 'components/ModalFormScrollable/ModalFormScrollable'
import { shared } from 'translations'

import { BusinessFields } from '../../../../Businesses/Business.fields'
import { ContactFormData } from '../../../../Contacts.types'
import {
  getBusinessCreateContactPayload,
  getContactName,
  getPersonCreateContactPayload,
  getUpdatedContactFromFormValues,
  initialValues,
  transformCreateContactToGetContact,
} from '../../../../Contacts.utils'
import { useCreateContacts } from '../../../../hooks/useCreateContacts/useCreateContacts'
import { PersonFields } from '../../../../People/Person.fields'
import {
  rvSelectedContact,
  rvSelectedSubContact,
} from '../../../../rv-deprecated/contacts'
import { saveContactsToRv } from '../../../../rv-deprecated/contacts.utils'
import {
  displaySubContactErrorToast,
  displaySubContactSuccessToast,
  getSubContactFormTitle,
  getSubContactPersonNames,
  updateRvSelectedContactWithSubContact,
} from './AddSubContact.utils'

interface AddSubContactFormProps {
  isModalOpen: boolean
  closeModal: () => void
  isSubContactTypeBusiness: boolean
  contactDefaultName: string
}

export const AddSubContactForm = ({
  isModalOpen,
  closeModal,
  contactDefaultName,
  isSubContactTypeBusiness,
}: AddSubContactFormProps) => {
  const { createContact, isCreatingContact } = useCreateContacts()
  const { locationName } = useAnalyticsContext()
  const { trackAnalyticsEvent } = useAnalyticsLogger()
  const formTitle = getSubContactFormTitle(isSubContactTypeBusiness)

  const initialFormikValues: ContactFormData = useMemo(() => {
    const contactName = isSubContactTypeBusiness
      ? {
          business: {
            ...initialValues.business,
            businessName: contactDefaultName,
          },
        }
      : {
          person: {
            ...initialValues.person,
            ...getSubContactPersonNames(contactDefaultName),
          },
        }
    return { ...initialValues, ...contactName }
  }, [contactDefaultName, isSubContactTypeBusiness])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={async (formValues, { resetForm }) => {
        const contact = rvSelectedContact()
        const linkedContactUuid = contact?.id

        if (contact && linkedContactUuid) {
          const payload = isSubContactTypeBusiness
            ? await getBusinessCreateContactPayload(formValues)
            : await getPersonCreateContactPayload(formValues)

          const updatedContact = getUpdatedContactFromFormValues(
            isSubContactTypeBusiness,
            contact,
            formValues,
            payload
          )

          const contactName = getContactName(updatedContact)

          try {
            const response = await createContact(payload, linkedContactUuid)

            if (response.data?.createContact.id) {
              rvSelectedSubContact(
                transformCreateContactToGetContact(response.data.createContact)
              )

              // NOTE: Save newly created sub contact to rv with current contact as sub contact
              saveContactsToRv(response.data.createContact, contact, true)

              updateRvSelectedContactWithSubContact()
              displaySubContactSuccessToast(contactName)
              resetForm()
              trackAnalyticsEvent(AnalyticsEventNames.CONTACT_CREATED, {
                Type: isSubContactTypeBusiness
                  ? AnalyticsContactType.Business
                  : AnalyticsContactType.Person,
                Linked: true,
                Multi: false,
                Location: locationName.current,
              })
              closeModal()
            } else {
              displaySubContactErrorToast(contactName)
            }
          } catch (error) {
            displaySubContactErrorToast(contactName)
          }
        }
      }}
    >
      {({ submitForm, resetForm }) => (
        <Form>
          <ModalFormScrollable
            cancelLabel={shared.cancel}
            confirmLabel={shared.save}
            isConfirmButtonDisabled={isCreatingContact}
            isLoading={isCreatingContact}
            isOpen={isModalOpen}
            onCancel={() => {
              closeModal()
              resetForm()
            }}
            onSave={submitForm}
            title={formTitle}
          >
            <Box>
              {isSubContactTypeBusiness ? <BusinessFields /> : <PersonFields />}
            </Box>
          </ModalFormScrollable>
        </Form>
      )}
    </Formik>
  )
}
