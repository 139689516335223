import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { ItemExportFormat } from '@npco/mp-gql-types'
import { renameAndDownloadFile } from '@npco/utils-file'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { DATE_FULL_SAVE_DISPLAY_FORMAT } from 'const/date'
import dayjs from 'utils/dayjs'

import { useItemsAnalytics } from '../../../hooks/useItemsAnalytics'
import { useExportItemsMutation } from '../graphql/exportItems.generated'
import { translations } from '../ItemsExport.i18n'

export const useExportItemsModal = () => {
  const { trackItemExport } = useItemsAnalytics()
  const t = useTranslations(translations)
  const [exportItems] = useExportItemsMutation()

  const handleDownload = useCallback(() => {
    async function sendRequest() {
      showSuccessToast(t('downloadStarted'))

      const { data } = await exportItems({
        variables: { format: ItemExportFormat.CSV },
      })

      if (!data?.exportItems?.downloadLink) {
        showErrorToast(t('downloadFailed'))
        return
      }

      trackItemExport(ItemExportFormat.CSV)

      await renameAndDownloadFile({
        filename: `items-${dayjs().format(DATE_FULL_SAVE_DISPLAY_FORMAT)}`,
        format: ItemExportFormat.CSV,
        link: data.exportItems.downloadLink,
      })
    }

    sendRequest().catch(() => {
      toast.dismiss()
      showErrorToast(t('downloadFailed'))
    })
  }, [exportItems, t, trackItemExport])

  return { handleDownload }
}
