import { useMemo } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { TabItemProps } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'

import { cardsSettingsTabsTranslations } from '../CardsSettingsTabs.i18n'

export const useCardsSettingsTabs = () => {
  const t = useTranslations(cardsSettingsTabsTranslations)
  const { pathname } = useLocation()

  const tabs: TabItemProps[] = useMemo(
    () => [
      {
        id: 'outstanding-expenses',
        children: t('outstandingExpenseTabName'),
        value: ROOT.PORTAL.CARDS.SETTINGS.OUTSTANDING_EXPENSES.path,
      },
    ],
    [t]
  )

  return {
    tabs,
    currentTab: pathname,
  }
}
