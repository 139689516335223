import { useState } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'
import {
  ModalForm,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { Formik, FormikHelpers } from 'formik'

import dayjs from 'utils/dayjs'
import {
  getIsOverdrawnLimitExceededError,
  getIsZellerDeclinedError,
} from 'utils/errors'
import { convertLocaleStringToNumber } from 'utils/localeString'
import { translate } from 'utils/translations'
import { GetTransactions_getTransactions_transactions as Transaction } from 'types/gql-types/GetTransactions'
import { Notification } from 'components/TransactionDetails/Notification/Notification'
import { shared, transactionsErrors } from 'translations'

import { useRefundTransaction } from './hooks/useRefundTransaction'
import { RefundTransactionForm } from './RefundTransactionForm/RefundTransactionForm'
import { getRefundPaymentSchema } from './RefundTransactionForm/RefundTransactionForm.schemas'
import {
  RefundTransactionFormValues,
  RefundType,
} from './RefundTransactionModal.types'
import { isSuccessReponseCode } from './RefundTransactionModal.utils'

const OVERDRAW_LIMIT_EXCEEDED_ERROR_CODE = '1009'
const ZELLER_DECLINE_ERROR_CODE = 'zellerDecline'

interface RefundTransactionModalProps {
  onCancel: () => void
  isOpen: boolean
  transaction: Transaction
}

type TransactionErrorKey = keyof typeof transactionsErrors

export const RefundTransactionModal = ({
  onCancel,
  isOpen,
  transaction,
}: RefundTransactionModalProps) => {
  const { isRefunding, refundTransaction } = useRefundTransaction()
  const [hasError, setHasError] = useState(false)
  const [errorCode, setErrorCode] = useState(shared.unknown)

  const refundBalance = transaction.amount - Number(transaction.refundedAmount)

  const handleSubmit = async (
    values: RefundTransactionFormValues,
    formikHelpers: FormikHelpers<RefundTransactionFormValues>
  ) => {
    const refundAmount =
      values.refundType === RefundType.FullRefund
        ? refundBalance
        : 100 * convertLocaleStringToNumber(values.amount)

    const today = dayjs()
    const response = await refundTransaction({
      input: {
        transactionUuid: crypto.randomUUID(),
        originalTransactionUuid: transaction.id,
        amount: Math.round(refundAmount),
        timestamp: today.toISOString(),
        timestampLocal: today.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        currency: 'AUD',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })

    if (response.errors) {
      if (getIsOverdrawnLimitExceededError(response.errors)) {
        setHasError(true)
        setErrorCode(OVERDRAW_LIMIT_EXCEEDED_ERROR_CODE)
        return
      }
      if (getIsZellerDeclinedError(response.errors)) {
        setHasError(true)
        setErrorCode(ZELLER_DECLINE_ERROR_CODE)
        return
      }
      showErrorToast()
      return
    }

    if (isSuccessReponseCode(response.data?.refundTransaction.responseCode)) {
      showSuccessToast(
        translate(
          'page.refundTransactionModal.formSections.successfulRefundMessage'
        )
      )
      onCancel()
      formikHelpers.resetForm()
      return
    }
    setHasError(true)
    setErrorCode(
      response.data?.refundTransaction.responseCode ?? shared.unknown
    )
  }

  return (
    <Formik<RefundTransactionFormValues>
      initialValues={{ refundType: RefundType.FullRefund, amount: '0.01' }}
      validationSchema={getRefundPaymentSchema(refundBalance)}
      onSubmit={async (values, formikHelpers) => {
        try {
          await handleSubmit(values, formikHelpers)
        } catch (error) {
          showErrorToast()
          ErrorLogger.report('[Payment] Failed to refund a transaction', error)
        }
      }}
    >
      {({ submitForm, isValid }) => (
        <ModalForm
          title={translate('page.refundTransactionModal.title')}
          isOpen={isOpen}
          onCancel={onCancel}
          onClickPrimary={() => {
            submitForm().catch(() => undefined)
          }}
          primaryButtonLabel={translate('page.refundTransactionModal.refund')}
          secondaryButtonLabel={translate('shared.cancel')}
          isPrimaryButtonDisabled={!isValid || isRefunding}
          isLoading={isRefunding}
        >
          {hasError && (
            <Notification>
              {
                transactionsErrors[errorCode as TransactionErrorKey].details
                  .heading
              }
              {
                transactionsErrors[errorCode as TransactionErrorKey].details
                  .text
              }
            </Notification>
          )}
          <RefundTransactionForm transaction={transaction} />
        </ModalForm>
      )}
    </Formik>
  )
}
