import { useMemo } from 'react'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import {
  getDocumentTypeOptionsByEntity,
  getDocumentTypeWithOtherOptionsByEntity,
} from './useGetDocumentTypeOptionsByEntity.utils'

export const useGetDocumentTypeOptionsByEntity = () => {
  const { type } = rvEntityDetails()
  const documentTypeItemOptions = useMemo(
    () => getDocumentTypeOptionsByEntity(type),
    [type]
  )

  const onboardingDocumentTypeItemOptions = useMemo(
    () => getDocumentTypeWithOtherOptionsByEntity(type),
    [type]
  )

  return {
    documentTypeItemOptions,
    onboardingDocumentTypeItemOptions,
  }
}
