import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { GetEntityTags } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/entityTags'

import { GetEntityTags as GetEntityTagsResponse } from 'types/gql-types/GetEntityTags'

interface UseEntityTagsProps {
  skip?: boolean
}

export const useEntityTags = ({ skip }: UseEntityTagsProps = {}) => {
  const { data, loading: isLoadingEntityTags } =
    useQuery<GetEntityTagsResponse>(GetEntityTags, { skip })

  const entityTags = useMemo(() => data?.getEntityTags ?? [], [data])

  return {
    entityTags,
    isLoadingEntityTags,
  }
}
