import type { ItemContactOrBiller } from '../../useBPayTargetItems'
import type { useGetBillersByCodeLazyQuery } from '../GetBillersByCode.generated'
import type { useGetContactsByBillerCodeLazyQuery } from '../GetContactsByBillerCode.generated'
import type { getItemsByQuery } from '../getItemsByQuery'
import { getBillerItemsFromResponse } from './shared/getBillerItemsFromResponse'
import { getContactItemsFromResponse } from './shared/getContactItemsFromResponse'

type GetItemsByCodeOptions = {
  code: string
  getBillersByCode: ReturnType<typeof useGetBillersByCodeLazyQuery>[0]
  getContactsByBillerCode: ReturnType<
    typeof useGetContactsByBillerCodeLazyQuery
  >[0]
}

export const getItemsByCode = async ({
  code,
  getBillersByCode,
  getContactsByBillerCode,
}: GetItemsByCodeOptions): Promise<ReturnType<typeof getItemsByQuery>> => {
  const billersRequest = getBillersByCode({
    variables: { code },
  })
  const contactsRequest = getContactsByBillerCode({
    variables: { code },
  })

  const billers = await billersRequest
  const contacts = await contactsRequest

  if (billers.error || !billers.data || contacts.error || !contacts.data) {
    return { type: 'Error' }
  }

  const itemsBillers = getBillerItemsFromResponse(
    billers.data.searchBpayBillers.bpayBillers
  )
  const itemsContacts = getContactItemsFromResponse(
    contacts.data.getContactsByBpayBillerCode
  )

  const items: ItemContactOrBiller[] = [...itemsContacts, ...itemsBillers]

  return { type: 'Ok', value: { items } }
}
