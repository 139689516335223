import { useCallback, useEffect, useMemo, useState } from 'react'
import { gql } from '@apollo/client'

import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'services/sessionStorage/utils'

import { useStartupModals } from '../useStartupModals/useStartupModals'
import { useSetStartupModalForSession } from './useSetStartupModalForSession'
import { UseStartupModalsSessionConnectionFragment } from './useStartupModalsSession.generated'
import { StartUpModal } from './useStartupModalsSession.types'

type UseStartupModalsSessionProps = {
  isLoading: boolean
  xeroBankFeed: UseStartupModalsSessionConnectionFragment | undefined
  xeroPaymentServices: UseStartupModalsSessionConnectionFragment | undefined
}

export const setStartupModal = (modal: StartUpModal | null) => {
  setSessionStorageItem('startupModal', modal)
}

export const getStartupModal = () => {
  return (
    getSessionStorageItem<StartUpModal | null | undefined>('startupModal') ??
    null
  )
}

export const useStartupModalsSession = ({
  isLoading,
  xeroBankFeed,
  xeroPaymentServices,
}: UseStartupModalsSessionProps) => {
  const { showSetupFlow, showXeroErrorModal, showPushAppModal } =
    useStartupModals({
      xeroBankFeed,
      xeroPaymentServices,
    })

  // We track changes in state to re-render to trigger modal to show
  const [modalState, setModalState] = useState<StartUpModal | null>(
    getStartupModal()
  )

  const startupModalToFlag: Record<StartUpModal, boolean> = useMemo(
    () => ({
      setup: showSetupFlow,
      xero: showXeroErrorModal,
      pushApp: showPushAppModal,
    }),
    [showPushAppModal, showSetupFlow, showXeroErrorModal]
  )

  const updateStartupModalAndReRender = useCallback(
    (startupModal: StartUpModal) => {
      setStartupModal(startupModal)
      setModalState(startupModal)
    },
    [setModalState]
  )

  const { setStartupModalForSession } = useSetStartupModalForSession({
    updateStartupModalAndReRender,
    showPushAppModal,
  })

  useEffect(() => {
    if (getStartupModal()) {
      return
    }

    if (showSetupFlow) {
      updateStartupModalAndReRender('setup')
      return
    }

    if (isLoading) {
      return
    }

    if (showXeroErrorModal) {
      updateStartupModalAndReRender('xero')
      return
    }

    setStartupModalForSession()
  }, [
    showSetupFlow,
    showXeroErrorModal,
    updateStartupModalAndReRender,
    isLoading,
    setStartupModalForSession,
  ])

  const startupModal = getStartupModal()

  return {
    startupModal,
    shouldShow: startupModal ? startupModalToFlag[startupModal] : false,
    modalState,
  }
}

useStartupModalsSession.fragments = {
  Connection: gql`
    fragment UseStartupModalsSessionConnectionFragment on Connection {
      ...UseStartupModalsConnectionFragment
    }
    ${useStartupModals.fragments.Connection}
  `,
}
