import { useMemo } from 'react'
import { NetworkStatus } from '@apollo/client'
import { getHasInverseExpenseOrIncome } from '@npco/mp-feature-cashflow-reporting'
import dayjs from 'dayjs'

import { useAccountsIncomeAndExpenseQuery } from './graphql/AccountsIncomeAndExpenseQuery.generated'

type UseTotalIncomeAndExpenseDataProps = {
  date: string
  timeZone: string | undefined
  skip: boolean
}

const isNotNull = <T,>(maybeNull: T | null): maybeNull is T => !!maybeNull

export const useAccountsIncomeAndExpenseData = ({
  date,
  timeZone,
  skip,
}: UseTotalIncomeAndExpenseDataProps) => {
  const { data, error, refetch, networkStatus } =
    useAccountsIncomeAndExpenseQuery({
      fetchPolicy:
        dayjs(date).month() < dayjs().month()
          ? 'cache-first'
          : 'cache-and-network',
      variables: {
        date,
        timeZone,
      },
      skip,
      notifyOnNetworkStatusChange: true,
    })

  const accountsIncomeAndExpenses = useMemo(
    () =>
      data?.getCashFlowTotalAmounts.cashFlowPeriodicTotalAmounts?.filter(
        isNotNull
      ) ?? [],
    [data?.getCashFlowTotalAmounts.cashFlowPeriodicTotalAmounts]
  )

  const isFetchingMore = useMemo(() => {
    return (
      accountsIncomeAndExpenses.length === 0 &&
      networkStatus === NetworkStatus.setVariables
    )
  }, [accountsIncomeAndExpenses, networkStatus])

  const hasInverseAccountsExpenseOrIncome = useMemo(() => {
    return getHasInverseExpenseOrIncome(accountsIncomeAndExpenses)
  }, [accountsIncomeAndExpenses])

  return {
    accountsIncomeAndExpenses,
    hasInverseAccountsExpenseOrIncome,
    isFetchingMore,
    isLoadingIncomeAndExpenses: networkStatus === NetworkStatus.loading,
    isRefetching: networkStatus === NetworkStatus.refetch,
    error,
    refetch,
  }
}
