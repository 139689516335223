import { Navigate } from 'react-router-dom-v5-compat'

import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { ROOT } from 'const/routes'

export const CardsActivateRoutes = () => {
  return (
    <Navigate
      to={ROOT.PORTAL.CARDS.DEBIT.path}
      state={{
        AddCardModal: {
          stage: AddCardStage.LinkCardIdStage,
        },
      }}
      replace
    />
  )
}
