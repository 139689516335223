import { gql } from '@apollo/client'

export const UpdateInvoiceItem = gql`
  mutation UpdateInvoiceItem($item: UpdateItemInput!) {
    updateItem(input: $item) {
      id
      name
      description
      price
      taxes {
        enabled
        name
        percent
      }
    }
  }
`
