import { useCallback } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'

import { cacheNewCorporateCard } from 'utils/banking/cacheNewCorporateCard/cacheNewCorporateCard'

import {
  RequestNewCorporateCardMutation,
  RequestNewCorporateCardMutationResponse,
  RequestNewCorporateCardMutationVariables,
} from './graphql/RequestCorporateCard.generated'
import { getVelocityControlFromInput } from './useRequestNewCard.utils'

export const useRequestCorporateCard = () => {
  const client = useApolloClient()

  const [requestCorporateCardMutation, { loading }] = useMutation<
    RequestNewCorporateCardMutationResponse,
    RequestNewCorporateCardMutationVariables
  >(RequestNewCorporateCardMutation, {})

  const requestCorporateCard = useCallback(
    async (options: {
      variables: RequestNewCorporateCardMutationVariables
      entityAddressTimezone?: string
    }) => {
      const velocityControlInput = options.variables.input.velocityControl
      if (!velocityControlInput) {
        throw new Error('velocityControl is required')
      }

      return requestCorporateCardMutation({
        variables: options.variables,
        update: (cache, { data }) => {
          const response = data?.requestNewDebitCard
          if (!response?.card) {
            return
          }
          cacheNewCorporateCard({
            cache,
            newCard: {
              ...response.card,
              velocityControl: {
                ...getVelocityControlFromInput({
                  velocityControlInput,
                  entityAddressTimezone: options.entityAddressTimezone,
                }),
                __typename: 'VelocityControlType',
              },
            },
            client,
          })
        },
      })
    },
    [requestCorporateCardMutation, client]
  )
  return { requestCorporateCard, isLoading: loading }
}
