import {
  Flex,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_VARIANT,
  ModalBasic,
} from '@npco/zeller-design-system'
import { Form, Formik } from 'formik'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

import { useInvoiceTax } from './hooks/useInvoiceTax'
import { validateForm } from './InvoiceTaxModal.utils'
import { InvoiceTaxOptions } from './InvoiceTaxOptions'

export const translations = {
  cancel: translate('shared.cancel'),
  headerTitle: translate(
    'page.invoice.formSections.items.invoiceTaxModalHeader'
  ),
  description: translate(
    'page.invoice.formSections.items.invoiceTaxModalDescription'
  ),
  infobox: translate('page.invoice.formSections.items.invoiceTaxModalInfobox'),
  save: translate('shared.save'),
}

export interface InvoiceTaxModalProps {
  closeModal: () => void
}

export const InvoiceTaxModal = ({ closeModal }: InvoiceTaxModalProps) => {
  const isMobileViewport = useIsMobileResolution()

  const { initialValues, handleSubmit } = useInvoiceTax({
    closeModal,
  })

  return (
    <ModalBasic
      isFullScreen={isMobileViewport}
      isOpen
      onCancel={closeModal}
      testId="invoicing-invoice-tax-modal"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        validate={(values) => validateForm(values)}
      >
        {({
          handleSubmit: handleFormikSubmit,
          values: { itemsTaxInclusive },
          submitForm: handleFormikSubmitForm,
        }) => (
          <Form
            data-testid="invoicing-invoice-tax-form"
            onSubmit={handleFormikSubmit}
          >
            <ModalBasic.HeaderForm
              onClickPrimary={handleFormikSubmitForm}
              onClickSecondary={closeModal}
              primaryButtonLabel={translations.save}
              secondaryButtonLabel={translations.cancel}
              title={translations.headerTitle}
            />
            <ModalBasic.Body canScroll={false}>
              <Flex flexDirection="column">
                <Flex paddingBottom="24px" paddingTop="12px">
                  {translations.description}
                </Flex>
                <InvoiceTaxOptions />
                {initialValues.itemsTaxInclusive !== itemsTaxInclusive && (
                  <InfoBox
                    priority={INFOBOX_PRIORITY.DEFAULT}
                    variant={INFOBOX_VARIANT.NEGATIVE}
                  >
                    <InfoBox.Message>{translations.infobox}</InfoBox.Message>
                  </InfoBox>
                )}
              </Flex>
            </ModalBasic.Body>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  )
}
