import { useMutation, useReactiveVar } from '@apollo/client'
import { UpdateThirdPartyBankAccount } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/deposits'
import { rvThirdPartyAccounts } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ThirdPartyBankAccount } from 'types/accounts'
import {
  UpdateThirdPartyBankAccount as UpdateThirdPartyBankAccountResponse,
  UpdateThirdPartyBankAccountVariables,
} from 'types/gql-types/UpdateThirdPartyBankAccount'

interface Params {
  onSuccess: (newAccount?: ThirdPartyBankAccount) => void
  onFailure: () => void
}

export const useEditThirdPartyAccountDetail = ({
  onSuccess,
  onFailure,
}: Params) => {
  const accounts = useReactiveVar(rvThirdPartyAccounts)

  const [editAccountMutation, { loading }] = useMutation<
    UpdateThirdPartyBankAccountResponse,
    UpdateThirdPartyBankAccountVariables
  >(UpdateThirdPartyBankAccount, {
    onError: onFailure,
  })

  const editAccountName = async (accountName: string, id: string) => {
    const { data, errors } = await editAccountMutation({
      variables: { input: { accountName, id } },
    })

    const hasErrors = Boolean(errors)
    const updateThirdPartyBankAccount =
      data?.updateThirdPartyBankAccount || null

    if (updateThirdPartyBankAccount) {
      const newAccounts = accounts.map((account) => {
        if (account.id === id) {
          return { ...account, accountName, name: accountName }
        }
        return account
      })
      rvThirdPartyAccounts(newAccounts)
      onSuccess(newAccounts.find((account) => account.id === id))
    } else if (!updateThirdPartyBankAccount && !hasErrors) {
      onFailure()
    }
  }
  return {
    editAccountName,
    loading,
  }
}
