import { useEffect, useState } from 'react'

import { usePrevious } from 'hooks/usePrevious'
import { useFileDrop } from 'components/ModalUploadImage/hooks/useFileDrop'
import { ModalUploadImage } from 'components/ModalUploadImage/ModalUploadImage'
import { component, shared } from 'translations'

import { useS3BucketForProfilePicture } from './hooks/useS3BucketForProfilePicture'

interface ModalContactProfilePictureProps {
  closeModal: () => void
  contactUuid: string
  onImageUploaded: (url: string) => void
  profilePictureUrl: string
}
export const ModalContactProfilePicture = ({
  closeModal,
  contactUuid,
  onImageUploaded,
  profilePictureUrl,
}: ModalContactProfilePictureProps) => {
  const {
    previewUrl: imageUrl,
    isPreviewLoading,
    isPreviewError,
    onDrop,
    fileType,
  } = useFileDrop(profilePictureUrl)
  const previousImageUrl = usePrevious(imageUrl)
  const [nextImageUrl, setNextImageUrl] = useState<string>('')

  // NOTE: we must ensure we updated the next image url when a user chooses to
  // add a new image when none previously or when changing an image from an
  // existing one
  useEffect(() => {
    const isAddingNewImage = !profilePictureUrl && !previousImageUrl && imageUrl
    const isChangingImage = previousImageUrl && previousImageUrl !== imageUrl

    if (isAddingNewImage || isChangingImage) {
      setNextImageUrl(imageUrl)
    }
  }, [imageUrl, previousImageUrl, profilePictureUrl])

  const { isUploadingFileToS3, uploadFileToS3Bucket } =
    useS3BucketForProfilePicture({
      imageUrl: nextImageUrl,
      onImageUploaded,
      closeModal,
    })

  // NOTE: next image url is only set once we have a new image, changed the
  // image or updated the crop on an existing image set
  const isSaveDisabled = isPreviewLoading || Boolean(!nextImageUrl)

  return (
    <ModalUploadImage
      description={component.modal.uploadImage.description}
      imageUrl={imageUrl}
      isCroppingImageMode
      isPreviewLoading={isPreviewLoading}
      isPreviewError={isPreviewError}
      isProcessing={isUploadingFileToS3}
      isSaveDisabled={isSaveDisabled}
      onCrop={setNextImageUrl}
      onDrop={onDrop}
      primaryButtonLabel={shared.save}
      secondaryButtonLabel={component.modal.uploadImage.changeImage}
      uploadFile={() => uploadFileToS3Bucket(contactUuid)}
      fileType={fileType}
    />
  )
}
