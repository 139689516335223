import {
  BodySmall,
  Box,
  Flex,
  Heading,
  HeadingStyles,
} from '@npco/zeller-design-system'

import { NoTagsPlaceholderMessageProps } from '../TagPicker.types'

interface Props {
  placeholderMessage: NoTagsPlaceholderMessageProps
}

export const NoTagsPlaceholder = ({ placeholderMessage }: Props) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      textAlign="center"
      minHeight="9.5rem"
      height="100%"
      p="1.5rem"
    >
      <Box mb="0.5rem">
        <Heading.H4 withStyles={HeadingStyles.H5}>
          {placeholderMessage.title}
        </Heading.H4>
      </Box>
      <Box as={BodySmall} m="0">
        {placeholderMessage.description}
      </Box>
    </Flex>
  )
}
