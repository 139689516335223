import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  addCategoryAriaLabel: 'Add Category',
  addItemAriaLabel: 'Add Item',
  addItemLabel: 'Add Item',
  categoriesAriaLabel: 'Open Categories',
  categoriesLabel: 'Categories',
  exportItemsAriaLabel: 'Export all Items',
  importItemAriaLabel: 'Import Items',
  manageAddCategory: 'Add Category',
  manageAddItem: 'Add Single Item',
  manageExportItems: 'Export All Items',
  manageImportItems: 'Import Items',
  manageLabel: 'Manage',
  manageSettings: 'Settings',
  manageDeleteAllItems: 'Delete All Items',
  deleteAllItemsAriaLabel: 'Delete all Items',
  searchPlaceholder: 'Item or Category',
  settingsAriaLabel: 'Open Items Settings',
  settingsLabel: 'Settings',
})
