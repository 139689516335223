import { gql } from '@apollo/client'

import { useSensitiveCardInformation } from '../hooks/useSensitiveCardInformation'
import { CardholderInformation } from './components/CardholderInformation'
import { CvcInformation } from './components/CvcInformation'
import { ExpiryInformation } from './components/ExpiryInformation'
import { PanInformation } from './components/PanInformation'
import { SensitiveCardInformationDebitCardV2Fragment } from './SensitiveCardInformation.generated'

const PAN_CONFIG = {
  id: 'mq-card-pan',
  color: 'white',
}

const EXPIRY_CONFIG = {
  id: 'mq-card-exp',
  color: 'white',
}

const CVV_CONFIG = {
  id: 'mq-card-cvv',
  color: 'white',
}

interface CardInformationProps {
  isVisible: boolean
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  card: SensitiveCardInformationDebitCardV2Fragment
  onError?: () => void
}

export const SensitiveCardInformation = ({
  isVisible,
  isLoading,
  setIsLoading,
  card,
  onError,
}: CardInformationProps) => {
  useSensitiveCardInformation({
    isVisible,
    onError,
    setIsLoading,
    pan: PAN_CONFIG,
    expiry: EXPIRY_CONFIG,
    cvv: CVV_CONFIG,
    debitCardUuid: card.id,
  })

  if (!isVisible) {
    return null
  }

  return (
    <>
      <PanInformation isLoading={isLoading} />
      {!isLoading && card.customer && (
        <CardholderInformation cardOwner={card.customer} />
      )}
      <ExpiryInformation isLoading={isLoading} />
      <CvcInformation isLoading={isLoading} />
    </>
  )
}

SensitiveCardInformation.fragments = {
  DebitCardV2: gql`
    fragment SensitiveCardInformationDebitCardV2Fragment on DebitCardV2 {
      id
      customer {
        ...CardholderInformationCustomerFragment
      }
    }

    ${CardholderInformation.fragments.Customer}
  `,
}
