import { gql } from '@apollo/client'

export const LinkContact = gql`
  mutation LinkContact($contactUuid: ID!, $senderUuid: ID!) {
    linkContactWithDebitCardAccountTransactionSender(
      contactUuid: $contactUuid
      senderUuid: $senderUuid
    )
  }
`
