import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { GetItemsTableCoreFieldsDoc } from './getItemsTableCoreFields.generated'

const defaultOptions = {} as const
export type GetItemsTableQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input']
  filter: Types.InputMaybe<Types.GetItemFilterInput>
  sort: Types.InputMaybe<Types.GetItemsSortInput>
  nextToken: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type GetItemsTableQueryResponse = {
  __typename?: 'Query'
  getItems: {
    __typename?: 'ItemConnection'
    nextToken: string | null
    items: Array<{
      __typename: 'Item'
      id: string
      referenceNumber: string | null
      name: string
      sku: string | null
      price: number
      status: Types.ItemStatus | null
      categories: Array<{
        __typename?: 'ItemCategory'
        color: string
        id: string
        name: string
      }> | null
      taxes: Array<{
        __typename?: 'ItemTax'
        enabled: boolean
        name: string
        percent: number | null
      }> | null
    }> | null
  }
}

export const GetItemsTable = gql`
  query GetItemsTable(
    $limit: Int!
    $filter: GetItemFilterInput
    $sort: GetItemsSortInput
    $nextToken: String
  ) {
    getItems(
      limit: $limit
      filter: $filter
      sort: $sort
      nextToken: $nextToken
    ) {
      items {
        ...GetItemsTableCoreFields
      }
      nextToken
    }
  }
  ${GetItemsTableCoreFieldsDoc}
` as unknown as TypedDocumentNode<
  GetItemsTableQueryResponse,
  GetItemsTableQueryVariables
>

/**
 * __useGetItemsTableQuery__
 *
 * To run a query within a React component, call `useGetItemsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsTableQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetItemsTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetItemsTableQueryResponse,
    GetItemsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetItemsTableQueryResponse,
    GetItemsTableQueryVariables
  >(GetItemsTable, options)
}
export function useGetItemsTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetItemsTableQueryResponse,
    GetItemsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetItemsTableQueryResponse,
    GetItemsTableQueryVariables
  >(GetItemsTable, options)
}
export type GetItemsTableQueryHookResult = ReturnType<
  typeof useGetItemsTableQuery
>
export type GetItemsTableLazyQueryHookResult = ReturnType<
  typeof useGetItemsTableLazyQuery
>
export type GetItemsTableQueryResult = Apollo.QueryResult<
  GetItemsTableQueryResponse,
  GetItemsTableQueryVariables
>
