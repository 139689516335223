import {
  Box,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { ModalWithButtons } from 'design-system/Components/Modal'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { translate } from 'utils/translations'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { ContactType as AnalyticsContactType } from 'services/Analytics/events/contact'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'

import { useUnlinkContacts } from '../../../../hooks/useUnlinkContacts'
import { StyledContentContainer } from './UnlinkSubContactModal.styled'
import { removeContactWithSubContact } from './UnlinkSubContactModal.utils'

type Contact = ContactCoreFieldsFragment
type SubContact = NonNullable<ContactCoreFieldsFragment['contacts']>[0]

interface UnlinkSubContactModalProps {
  business: SubContact | Contact | null
  person: SubContact | Contact | null
  closeModal: () => void
  isContactTypeBusiness: boolean
  isModalOpen: boolean
}

export const UnlinkSubContactModal = ({
  business,
  person,
  closeModal,
  isContactTypeBusiness,
  isModalOpen,
}: UnlinkSubContactModalProps) => {
  const { isUnlinking, unlinkContacts } = useUnlinkContacts()
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  const personName = `${person?.firstName} ${person?.lastName}`
  const businessName = business?.businessName

  const handleOnConfirm = async () => {
    const contactName = isContactTypeBusiness ? personName : businessName

    if (business?.id && person?.id) {
      try {
        await unlinkContacts(business.id, person.id)

        showSuccessToast(
          translate('page.contacts.notifications.unlinkSuccessMessage', {
            contactName,
          })
        )

        trackAnalyticsEvent(AnalyticsEventNames.CONTACT_UNLINKED_CONTACT, {
          Type: isContactTypeBusiness
            ? AnalyticsContactType.Person
            : AnalyticsContactType.Business,
        })
        removeContactWithSubContact(isContactTypeBusiness ? person : business)

        closeModal()
      } catch (error) {
        showErrorToast(
          translate('page.contacts.notifications.unlinkFailMessage', {
            contactName,
          })
        )
      }
    }
  }

  return (
    <ModalWithButtons
      title={translate('page.contacts.unlinkSubContactModal.title', {
        contactType: isContactTypeBusiness ? 'Business' : 'Person',
        subContact: isContactTypeBusiness ? 'Person' : 'Business',
      })}
      primaryButtonLabel="Confirm"
      secondaryButtonLabel="Cancel"
      isPrimaryButtonLoading={isUnlinking}
      isOpen={isModalOpen}
      onConfirm={handleOnConfirm}
      onCancel={closeModal}
      hasCancelButton
    >
      <StyledContentContainer>
        <Box my="0.75rem">
          {translate(
            'page.contacts.unlinkSubContactModal.confirmationMessage',
            {
              contactName: isContactTypeBusiness ? businessName : personName,
              subContactName: isContactTypeBusiness ? personName : businessName,
            }
          )}
        </Box>
      </StyledContentContainer>
    </ModalWithButtons>
  )
}
