import {
  TableCellIconText,
  TileIcon,
  TileMultipleIcon,
} from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'
import { isNil } from 'lodash-es'

import { ItemsTableData } from '../ItemsTable.types'

export const ItemsRowCategoryCell = (
  cellProps: CellContext<ItemsTableData, string>
) => {
  const { cell, getValue, row, table } = cellProps

  const isLoading = row.original?.id === null || table.options.meta?.isLoading
  const value = getValue<string>()
  const text = isNil(value) ? '' : String(value)
  const width = row.index % 2 ? '80%' : '90%'

  const [color1, color2] = row.original?.categoryColors || []

  const icon = color2 ? (
    <TileMultipleIcon backgroundColorTwo={color1} backgroundColorOne={color2} />
  ) : (
    <TileIcon backgroundColor={color1} />
  )

  return (
    <TableCellIconText
      dataTestId={cell.id}
      forwardedProps={{ style: { padding: 0 } }}
      hasDivider={false}
      icon={icon}
      isLoading={isLoading}
      key={cell.id}
      skeletonProps={{ width }}
      text={text}
      whiteSpace="normal"
    />
  )
}
