import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { ContactType } from '@npco/mp-gql-types'
import { ButtonLink, Flex, Heading } from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { rvSelectedContact } from 'features/Contacts/rv-deprecated/contacts'

import { ROUTE } from 'const/routes'
import { translate } from 'utils/translations'

import { Body } from './ViewContactSection.styled'
import { ViewContactSectionLoader } from './ViewContactSectionLoader'

interface ViewContactSectionProps {
  contact: ContactCoreFieldsFragment | null
  isLoading?: boolean
}

export const ViewContactSection = ({
  contact,
  isLoading,
}: ViewContactSectionProps) => {
  const navigate = useNavigate()

  const handleViewContact = useCallback(() => {
    if (contact) {
      rvSelectedContact(contact)

      const contactRoute =
        contact.contactType === ContactType.BUSINESS
          ? ROUTE.PORTAL_CONTACTS_BUSINESSES_GENERAL
          : ROUTE.PORTAL_CONTACTS_PEOPLE_GENERAL

      navigate(contactRoute)
    }
  }, [contact, navigate])

  if (isLoading) {
    return <ViewContactSectionLoader />
  }

  if (!contact) {
    return null
  }

  return (
    <Flex flexDirection="column">
      <Heading.H3>
        {translate('page.transactions.contact.contactDetailsHeader')}
      </Heading.H3>
      <Body>
        {translate('page.transactions.contact.contactDetailsDescription')}
      </Body>
      <div>
        <ButtonLink onClick={handleViewContact} dataTestId="view-contact">
          {translate('page.transactions.contact.viewContact')}
        </ButtonLink>
      </div>
    </Flex>
  )
}
