import {
  BodyDefault,
  BodyLarge,
  ButtonFill,
  Flex,
  getHeadingStyle,
  H2,
  H5,
  HeadingStyles,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const NumberedList = styled.ol`
  counter-reset: section;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Item = styled.li`
  display: flex;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 16px;
  }

  &::before {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.BLUE_80};
    border-radius: 24px;
    color: ${({ theme }) => theme.colors.BLUE_1000};
    content: counter(section);
    counter-increment: section;
    display: flex;
    height: 32px;
    justify-content: center;
    min-height: 32px;
    min-width: 32px;
    width: 32px;
  }
`

export const ItemHeading = styled.b`
  ${getHeadingStyle(HeadingStyles.H5)}
  display: block;
  margin: 4px 0px 8px 0;
`

export const SubTitle = styled(BodyLarge)`
  margin: 0 0 24px 0;
`

export const Text = styled(BodyDefault)`
  margin: 0;
`

export const BalanceExpirationValue = styled(H2)`
  font-weight: 400;
  margin: 0;
`
export const ExpirationInfo = styled(Flex)`
  border-radius: 7px;

  & svg {
    flex-shrink: 0;
  }

  ${BodyDefault} {
    margin: 0;
  }
`

export const ReferralLinkActionWrapper = styled.div`
  display: block;
  position: relative;
  margin-bottom: 16px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: inline-block;
  }
`

export const ReferralLinkButton = styled(ButtonFill)`
  width: 100%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: auto;
  }
`

export const ConfirmationWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: -42px;
  margin: 0 auto;
  transform: translateX(-50%);
`
export const BalanceExpiryLabel = styled(H5)`
  margin: 0;
`

export const ItemDescription = styled(BodyDefault)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0;
`
