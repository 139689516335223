import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useFormikContext } from 'formik'

import { ROOT } from 'const/routes'
import { TransferConfirmationModal } from 'pages/Transfer/TransferConfirmationModal/TransferConfirmationModal'

import { BPAY_TRANSFER_MODAL_HEIGHT } from '../../../Transfer.utils'
import { TransferContactValues } from '../ContactTransferModal.types'
import { useGoToContactTransferModal } from '../hooks/useGoToContactTransferModal/useGoToContactTransferModal'
import { ContactTransferReceipt } from './ContactTransferReceipt/ContactTransferReceipt'

type ContactTransferSuccessProps = {
  values: TransferContactValues
  transactionTimestamp: string
  onNewTransfer?: () => void
  transactionID: string
}

export const ContactTransferSuccess = ({
  values,
  transactionTimestamp,
  onNewTransfer,
  transactionID,
}: ContactTransferSuccessProps) => {
  const { resetForm } = useFormikContext()
  const { closeContactTransferModal } = useGoToContactTransferModal()
  const navigate = useNavigate()

  const goToAccountsTransactions = useCallback(() => {
    navigate(ROOT.PORTAL.ACCOUNTS.TRANSACTIONS.path)
  }, [navigate])

  const startNewTransfer = useCallback(() => {
    onNewTransfer?.()
    resetForm()
    closeContactTransferModal()
  }, [resetForm, closeContactTransferModal, onNewTransfer])

  return (
    <TransferConfirmationModal
      decisionModalProps={{
        isOpen: true,
        onCancel: startNewTransfer,
        primaryButtonLabel: 'Close',
        onClickPrimary: goToAccountsTransactions,
        secondaryButtonLabel: 'New Transfer',
        onClickSecondary: startNewTransfer,
        shouldCloseOnEsc: false,
        shouldCloseOnOverlayClick: false,
      }}
      paddingTop="120px"
      paddingBottom="120px"
      modalMinHeight={BPAY_TRANSFER_MODAL_HEIGHT}
      transactionID={transactionID}
      contactEmail={values.toContact.email?.email}
      contactPhone={values.toContact.phoneV2?.phone ?? values.toContact.phone}
    >
      <ContactTransferReceipt
        values={values}
        transactionTimestamp={transactionTimestamp}
      />
    </TransferConfirmationModal>
  )
}
