import { useQuery } from '@apollo/client'
import { GetEntityTags } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/entityTags'

import { GetEntityTags as GetEntityTagsType } from 'types/gql-types/GetEntityTags'

interface UseAccountFilterTagsProps {
  skip?: boolean
}

export const useAccountFilterTags = ({
  skip = false,
}: UseAccountFilterTagsProps = {}) => {
  const { data } = useQuery<GetEntityTagsType>(GetEntityTags, { skip })

  return data?.getEntityTags ?? []
}
