import { gql } from '@apollo/client'

export const GetSubcategories = gql`
  query GetSubcategories($category: EntityCategories!) {
    getEntitySubcategories(category: $category) {
      id
      name
      predefined
    }
  }
`
