import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import {
  AccountLayoutQuery as AccountLayoutQueryResponse,
  AccountLayoutQueryVariables,
} from 'types/gql-types/AccountLayoutQuery'

import { AccountLayoutQuery } from './graphql/AccountLayoutQuery'

interface UseAccountLayoutQueryProps {
  accountId: string
}

export const useAccountLayoutQuery = ({
  accountId,
}: UseAccountLayoutQueryProps) => {
  const { data, loading, error } = useQuery<
    AccountLayoutQueryResponse,
    AccountLayoutQueryVariables
  >(AccountLayoutQuery, {
    variables: { accountId },
  })

  const account = useMemo(() => {
    return data?.getDebitCardAccountV2 || null
  }, [data?.getDebitCardAccountV2])

  const entity = useMemo(() => {
    return data?.getEntity
  }, [data?.getEntity])

  return { account, entity, isLoadingAccount: loading, error }
}
