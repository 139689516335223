import { useCallback } from 'react'
import {
  GetItemsPriceFilterColumnName,
  GetItemsSortColumnName,
} from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  ButtonGhost,
  Divider,
  Flex,
  TableHeaderCellIcon,
} from '@npco/zeller-design-system'
import { HeaderContext } from '@tanstack/react-table'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { PriceFilter } from 'components/Filters/PriceFilter/PriceFilter'
import { SortAmountList, SortIndicator } from 'components/Sort'
import { translationsShared } from 'translations'

import { useItemManagementContext } from '../../ItemManagementContext/ItemManagementContext'
import { translations } from '../ItemsTable.i18n'
import { ItemsTableData } from '../ItemsTable.types'

export const ItemsHeaderRowPriceCell = ({
  header,
}: HeaderContext<ItemsTableData, string>) => {
  const {
    items: { filterInput, sortInput, setSortInput, setFilterInput },
  } = useItemManagementContext()

  const isMobile = useIsMobileResolution()

  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const isSorting = sortInput?.columnName === GetItemsSortColumnName.Price

  const isFiltering =
    filterInput?.priceFilter?.columnName === GetItemsPriceFilterColumnName.price

  const isAscending = sortInput?.ascending === true

  const handleClearClick = useCallback(
    (setIsOpen) => () => {
      setIsOpen(false)
      if (sortInput?.columnName === GetItemsSortColumnName.Price) {
        setSortInput(null)
      }
      if (filterInput?.priceFilter) {
        setFilterInput({ ...filterInput, priceFilter: null })
      }
    },
    [setSortInput, setFilterInput, sortInput, filterInput]
  )

  const handleClick = useCallback(
    (setIsOpen: (state: boolean) => void) => (ascending: boolean) => {
      setIsOpen(false)

      if (
        sortInput?.ascending === ascending &&
        sortInput?.columnName === GetItemsSortColumnName.Price
      ) {
        setSortInput(null)
      } else {
        setSortInput({ ascending, columnName: GetItemsSortColumnName.Price })
      }
    },
    [sortInput, setSortInput]
  )

  const icon = useCallback(
    () => <SortIndicator $ascending={isAscending} />,
    [isAscending]
  )

  if (isMobile) {
    return null
  }

  return (
    <TableHeaderCellIcon
      dataTestId={header.id}
      forwardedProps={{ style: { padding: 0, width: 104 } }}
      icon={icon}
      isFiltering={isSorting || isFiltering}
      isSorting={isSorting}
      justifyContent="flex-end"
      key={header.id}
      label={t('priceHeader')}
      popperWidth="320px"
    >
      {({ setIsOpen }) => (
        <Flex
          data-testid="header-row-price-popper"
          flexDirection="column"
          padding="8px"
        >
          <SortAmountList
            onClick={handleClick(setIsOpen)}
            sort={isSorting ? isAscending : null}
          />
          <Divider margin="20px 0px" />
          <PriceFilter
            filterInput={filterInput}
            setFilterInput={setFilterInput}
          />
          <Divider margin="20px 0px" />
          <Flex
            alignItems="flex-end"
            data-testid="categories-filter-clear"
            flexDirection="column"
          >
            <ButtonGhost onClick={handleClearClick(setIsOpen)}>
              {tShared('clear')}
            </ButtonGhost>
          </Flex>
        </Flex>
      )}
    </TableHeaderCellIcon>
  )
}
