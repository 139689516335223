import { WatchQueryFetchPolicy } from '@apollo/client'

import { useAccountTransactionsQuery } from '../graphql/getAccountTransactionsQuery.generated'

interface UseGetAccountTypeProps {
  accountId: string
  fetchPolicy?: WatchQueryFetchPolicy
}

export const useGetAccountType = ({
  accountId,
  fetchPolicy = 'cache-first',
}: UseGetAccountTypeProps) => {
  const {
    loading: isLoadingAccountType,
    error,
    data,
  } = useAccountTransactionsQuery({
    variables: { debitCardAccountUuid: accountId },
    fetchPolicy,
  })

  return {
    isLoadingAccountType,
    error,
    account: data?.getDebitCardAccountV2 ?? null,
  }
}
