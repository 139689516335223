import { gql } from '@apollo/client'

export const CustomerNameQuery = gql`
  query CustomerNameQuery($customerUuid: ID!) {
    getCustomer(customerUuid: $customerUuid) {
      id
      firstname
      lastname
    }
  }
`
