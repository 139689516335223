import { NetworkStatus } from '@apollo/client'

import { useContactDetailsQuery as useContactDetailsQueryApollo } from './graphql/ContactDetailsQuery.generated'

type UseContactDetailsQueryProps = {
  contactUuid: string
}

export const useContactDetailsQuery = ({
  contactUuid,
}: UseContactDetailsQueryProps) => {
  const { data, loading, error, refetch, networkStatus } =
    useContactDetailsQueryApollo({
      variables: { contactUuid },
      notifyOnNetworkStatusChange: true,
    })

  return {
    contact: data?.getContact ?? null,
    isLoadingContact: loading || networkStatus === NetworkStatus.refetch,
    error,
    refetch,
  }
}
