import { useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { BREAKPOINT, useIsMobileResolution } from '@npco/zeller-design-system'

import { ACCOUNT_ROUTE, EmptyStateBanner } from '../EmptyStateBanner'
import NoTransactionsDesktopSrc from './assets/no-transactions-desktop.png'
import NoTransactionsMobileSrc from './assets/no-transactions-mobile.png'
import { noTransactionsBannerTranslations } from './NoTransactionsBanner.i18n'

export const NoTransactionsBanner = () => {
  const isMobileOrTabletBreakpoint = useIsMobileResolution(BREAKPOINT.SM)
  const navigate = useNavigate()

  const t = useTranslations(noTransactionsBannerTranslations)

  return (
    <EmptyStateBanner
      data-testid="no-transactions-banner"
      image={
        <img
          src={
            isMobileOrTabletBreakpoint
              ? NoTransactionsMobileSrc
              : NoTransactionsDesktopSrc
          }
          alt={t('alt')}
          width="417px"
        />
      }
      ctaPrimary={{
        text: t('goToAccounts'),
        onClick: () => navigate(ACCOUNT_ROUTE),
      }}
      title={t('title')}
      description={t('description')}
      items={[t('item1'), t('item2'), t('item3'), t('item4')]}
      ctaDescription={t('ctaDescription')}
    />
  )
}
