import { useMemo, useState } from 'react'
import { CashFlowReportType, DebitCardAccountType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  ButtonLink,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { CategoryDrawer } from '../../CategoryDrawer/CategoryDrawer'
import { useCashflowReportingTransactionsContext } from '../../hooks/useCashflowReportingTransactionsContext'
import { useSelectedDateRange } from '../../hooks/useSelectedDateRange'
import { InverseIncomeOrExpenseMessage } from '../../InverseIncomeOrExpenseMessage'
import { convertToAWSDate } from '../../utils/convertToAWSDate/convertToAWSDate'
import { IncomeExpenseTabs } from '../IncomeExpenseTabs'
import * as styled from '../TransactionsSummary.styled'
import { CategoriesBanner } from './CategoriesBanner/CategoriesBanner'
import { CategoriesChart } from './CategoriesChart/CategoriesChart'
import { CategoriesTable } from './CategoriesTable/CategoriesTable'
import { useCashflowCategoriesNetAmounts } from './hooks/useCashflowCategoriesNetAmounts'
import { useCategoryNavigation } from './hooks/useCategoryNavigation/useCategoryNavigation'
import { translations } from './TransactionsSummaryCategory.i18n'
import { HoveredCategoryNetAmount } from './TransactionsSummaryCategory.types'
import { getGroupedNetAmounts } from './utils/getGroupedNetAmounts'

interface TransactionsSummaryCategoryProps {
  timezone?: string
  accountUuid?: string
  isLoadingTimezone: boolean
  accountType?: DebitCardAccountType
}
export const TransactionsSummaryCategory = ({
  isLoadingTimezone,
  timezone,
  accountUuid,
  accountType,
}: TransactionsSummaryCategoryProps) => {
  const t = useTranslations(translations)

  const isMobile = useIsMobileResolution(BREAKPOINT.SM)
  const isMobileOrTablet = useIsMobileResolution(BREAKPOINT.MD)

  const [isExpanded, setIsExpanded] = useState(false)

  const [hoveredCategoryNetAmount, setHoveredCategoryNetAmount] =
    useState<HoveredCategoryNetAmount>()

  const { incomeOrExpenseSelection } = useCashflowReportingTransactionsContext()
  const isOnIncomeTab = useMemo(
    () => incomeOrExpenseSelection === 'income',
    [incomeOrExpenseSelection]
  )

  const { selectedDateRange } = useSelectedDateRange()
  const reportType = useMemo(
    () =>
      selectedDateRange === 'Last 12 Months' || selectedDateRange.type === 'TTM'
        ? CashFlowReportType.TTM
        : CashFlowReportType.MONTHLY,
    [selectedDateRange]
  )
  const isAccountSaving = accountType === DebitCardAccountType.SAVINGS

  const {
    isLoading: isLoadingCategoryNetAmounts,
    categoryNetAmounts,
    error,
    refetch,
  } = useCashflowCategoriesNetAmounts({
    skip: isLoadingTimezone || isOnIncomeTab || isAccountSaving,
    variables: {
      accountUuid,
      timeZone: timezone,
      reportType,
      date: convertToAWSDate(selectedDateRange, timezone),
    },
  })

  const groupedNetAmounts = useMemo(
    () => getGroupedNetAmounts(categoryNetAmounts),
    [categoryNetAmounts]
  )

  const netAmounts = useMemo(
    () => (isExpanded ? categoryNetAmounts : groupedNetAmounts),
    [categoryNetAmounts, groupedNetAmounts, isExpanded]
  )

  const hasInverseNetAmount = useMemo(() => {
    return categoryNetAmounts.some(
      (netAmount) => parseInt(netAmount.total.value, 10) > 0
    )
  }, [categoryNetAmounts])

  const isLoading = useMemo(
    () => isLoadingTimezone || isLoadingCategoryNetAmounts,
    [isLoadingTimezone, isLoadingCategoryNetAmounts]
  )

  const {
    selectedCategory,
    setSelectedCategory,
    selectNextCategory,
    selectPrevCategory,
  } = useCategoryNavigation({
    categoryNetAmounts,
    isOtherCategoriesExpanded: isExpanded,
    expandOtherCategories: () => setIsExpanded(true),
  })

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <>
      <styled.TransactionsSummaryContentLayout>
        {!isOnIncomeTab && (
          <styled.TransactionsSummaryContentLayoutTable>
            <CategoriesTable
              netAmounts={netAmounts}
              isLoading={isLoading}
              transactionDirection="expense"
              expandOtherCategories={() => setIsExpanded(true)}
              openDrawer={() => setIsDrawerOpen(true)}
              onCategoryHovered={setHoveredCategoryNetAmount}
              onCategoryUnhovered={() => setHoveredCategoryNetAmount(undefined)}
              setSelectedCategory={setSelectedCategory}
            />
            {isExpanded && (
              <ButtonLink onClick={() => setIsExpanded(false)}>
                {t('showLess')}
              </ButtonLink>
            )}
            <CategoryDrawer
              isOpen={isDrawerOpen}
              setIsOpen={setIsDrawerOpen}
              selectedCategory={selectedCategory}
              timeZone={timezone}
              reportType={reportType}
              selectPreviousCategory={selectPrevCategory}
              selectNextCategory={selectNextCategory}
            />
          </styled.TransactionsSummaryContentLayoutTable>
        )}

        {!isMobile && (
          <styled.TransactionsSummaryContentLayoutChart>
            {!isMobileOrTablet && <IncomeExpenseTabs />}
            {(isLoading || netAmounts.length > 0) && (
              <CategoriesChart
                netAmounts={groupedNetAmounts}
                isLoading={isLoading}
                transactionDirection="expense"
                hoveredCategoryNetAmount={hoveredCategoryNetAmount}
              />
            )}
            {!isLoading && netAmounts.length > 0 && hasInverseNetAmount && (
              <InverseIncomeOrExpenseMessage />
            )}
          </styled.TransactionsSummaryContentLayoutChart>
        )}
      </styled.TransactionsSummaryContentLayout>
      <CategoriesBanner
        isOnIncomeTab={isOnIncomeTab}
        isLoading={isLoading}
        isAccountSaving={isAccountSaving}
        isNetAmountsEmpty={netAmounts.length === 0}
        error={error}
        onRetry={() => refetch()}
      />
    </>
  )
}
