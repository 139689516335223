import { gql } from '@apollo/client'

import { CorporateCardsTable } from 'components/CardsView/components/CardsTable/CorporateCardsTable/CorporateCardsTable'
import { DebitCardsTable } from 'components/CardsView/components/CardsTable/DebitCardsTable/DebitCardsTable'

export const ReportLostDebitCard = gql`
  mutation ReportLostDebitCardMutation($input: ReportLostDebitCardInput!) {
    reportLostDebitCard(input: $input) {
      replacementCard {
        productType
        ...DebitCardsTableDebitCardV2Fragment
        ...CorporateCardsTableDebitCardV2Fragment
      }
      lostCard {
        __typename
        id
        status
      }
    }
  }

  ${DebitCardsTable.fragments.DebitCardV2}
  ${CorporateCardsTable.fragments.DebitCardV2}
`
