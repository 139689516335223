import { gql, type TypedDocumentNode } from '@apollo/client'

import { ItemDescriptionFragmentDoc } from '../ItemDrawerDescription/ItemDrawerDescription.generated'

export type ItemDetailsFragment = {
  __typename?: 'Item'
  name: string
  sku: string | null
  price: number
  createdTime: number | null
  id: string
  referenceNumber: string | null
  description: string | null
  categories: Array<{
    __typename?: 'ItemCategory'
    name: string
    color: string
    id: string
  }> | null
  taxes: Array<{
    __typename?: 'ItemTax'
    enabled: boolean
    name: string
    percent: number | null
  }> | null
}

export const ItemDetailsFragmentDoc = gql`
  fragment ItemDetailsFragment on Item {
    name
    sku
    categories {
      name
      color
      id
    }
    price
    taxes {
      enabled
      name
      percent
    }
    createdTime
    id
    referenceNumber
    ...ItemDescriptionFragment
  }
  ${ItemDescriptionFragmentDoc}
` as unknown as TypedDocumentNode<ItemDetailsFragment, undefined>
