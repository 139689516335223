import { useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { AccordionSimple, ButtonLink } from '@npco/zeller-design-system'
import {
  rvLastSelectedDates,
  rvToggleFilters,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ROOT } from 'const/routes'
import { currencyFormatter } from 'utils/common'
import { formatWeekdayDateTime } from 'utils/date'
import dayjs from 'utils/dayjs'
import { TIME_FILTER_ENABLED_COMPONENTS } from 'types/picker'
import { Transaction } from 'types/transactions'
import { DetailsField } from 'components/DetailsField/DetailsField'

import { translations } from './SplitPayment.i18n'
import * as styled from './SplitPayment.styled'
import {
  getRemainingAmount,
  getSplitPaymentTypeDisplayValue,
} from './SplitPayment.utils'

interface SplitPaymentProps {
  transaction: Transaction
  closeDrawer: () => void
}

export const SplitPayment = ({
  transaction,
  closeDrawer,
}: SplitPaymentProps) => {
  const navigate = useNavigate()
  const t = useTranslations(translations)

  return (
    <>
      <styled.CustomizedDivder />
      <styled.CustomizedAccordionWrapper>
        <AccordionSimple title={t('splitPaymentDetails')}>
          <DetailsField
            label={t('splitType')}
            value={getSplitPaymentTypeDisplayValue({
              portions: transaction.splitPayment?.portions,
              type: transaction.splitPayment?.type,
            })}
          />
          <DetailsField
            label={t('initialKeyAmount')}
            value={currencyFormatter(
              transaction?.splitPayment?.targetAmount || 0
            )}
          />
          <DetailsField
            label={t('remaining')}
            value={currencyFormatter(getRemainingAmount(transaction))}
          />
          <DetailsField
            label={t('date')}
            value={formatWeekdayDateTime(transaction.timestamp)}
          />
          <ButtonLink
            onClick={() => {
              navigate(
                `${
                  ROOT.PORTAL.TRANSACTIONS.SPLITPAYMENT.SPLIT_PAYMENT_ID(
                    transaction?.splitPayment?.id
                  ).path
                }`
              )
              rvToggleFilters(false)
              const now = dayjs()
              const splitPaymentDate = dayjs(transaction.timestamp)
              const sevenDaysBefore = splitPaymentDate.subtract(7, 'day')
              const sevenDaysAfter = splitPaymentDate.add(7, 'day')
              rvLastSelectedDates({
                ...rvLastSelectedDates(),
                [TIME_FILTER_ENABLED_COMPONENTS.TRANSACTIONS_SPLIT_PAYMENT]: {
                  from: sevenDaysBefore.toDate(),
                  to: sevenDaysAfter.isBefore(now)
                    ? sevenDaysAfter.toDate()
                    : now.toDate(),
                },
              })
              closeDrawer()
            }}
          >
            {t('viewRelatedTransactions')}
          </ButtonLink>
        </AccordionSimple>
      </styled.CustomizedAccordionWrapper>
    </>
  )
}
