import { useEffect, useState } from 'react'
import { CatalogItemType } from '@npco/mp-gql-types'
import currency from 'currency.js'
import { useGetRecentlyUsedItems } from 'features/Invoicing/components/Invoices/hooks/useGetRecentlyUsedLineItems'
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs'

import {
  InvoiceNameSelectedItem,
  InvoiceNameSelectedTab,
} from '../../../../../InvoiceFormAccordions.types'
import {
  DEFAULT_INVOICE_ITEMS_GET_LIMIT,
  useGetItems,
} from '../../hooks/useGetItems'

export interface UseInvoiceNameItemsProps {
  debounceDuration?: number
  name$: Subject<string>
  selectedItem: InvoiceNameSelectedItem | null
  selectedTab: InvoiceNameSelectedTab
}

export const useInvoiceNameItems = ({
  debounceDuration = 500,
  name$,
  selectedItem,
  selectedTab,
}: UseInvoiceNameItemsProps) => {
  const [hasSavedItems, setHasSavedItems] = useState<boolean>(false)
  const [hasRecentlyUsedItems, setHasRecentlyUsedItems] =
    useState<boolean>(false)
  const [nextSavedItems, setNextSavedItems] = useState<
    InvoiceNameSelectedItem[]
  >([])

  const [nextRecentlyUsedLineItems, setNextRecentlyUsedLineItems] = useState<
    InvoiceNameSelectedItem[]
  >([])

  // NOTE: although this hook is used for the same component multiple times,
  // apollo prevents multiple duplicate requests from being sent by default
  // https://www.apollographql.com/docs/react/v2/networking/network-layer/#query-deduplication
  const {
    loadMore: loadMoreSavedItems,
    getItems: getSavedItems,
    hasMore: hasMoreSavedItems,
    isLoading: isLoadingSavedItems,
    items: savedItems,
  } = useGetItems({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const {
    getRecentlyUsedLineItems,
    items: recentlyUsedLineItems,
    isLoading: isLoadingRecentlyUsedLineItems,
  } = useGetRecentlyUsedItems({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  useEffect(() => {
    const nameSubscription = new Subscription()
    nameSubscription.add(
      name$
        .pipe(debounceTime(debounceDuration), distinctUntilChanged())
        .subscribe((nextInputValue) => {
          getSavedItems({
            variables: {
              input: {
                limit: DEFAULT_INVOICE_ITEMS_GET_LIMIT,
                filter: { name: nextInputValue.trim() },
                nextToken: null,
              },
            },
          })
        })
    )

    // NOTE: we can't paginate so only call this once
    getRecentlyUsedLineItems()

    // NOTE: send initial request
    name$.next(selectedItem?.label || '')

    return () => {
      nameSubscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isSavedTab = selectedTab === InvoiceNameSelectedTab.SavedItems

  useEffect(() => {
    const items = (savedItems || []).map((value) => ({
      catalogItemUuid: value.id,
      description: value.description || '',
      label:
        value.type === CatalogItemType.VARIANT
          ? `${value.parentName} - ${value.name}`
          : value.name,
      // NOTE: Must wrap in another instance of currency as an issue when
      // performing subsequent calculations on an instance using the `fromCents`
      // option https://github.com/scurker/currency.js/issues/425
      price: currency(
        currency(value.price.value, { fromCents: true, precision: 4 }),
        { precision: 4 }
      ),
      taxApplicable: value.taxes?.some((tax) => tax.enabled) || false,
      value: value.id,
    }))

    setHasSavedItems(Boolean(items.length))
    setNextSavedItems(items)
  }, [hasSavedItems, savedItems])

  useEffect(() => {
    const items = (recentlyUsedLineItems || []).map((value) => ({
      catalogItemUuid: value.catalogItem?.id || '',
      description: value.description || '',
      label: value.name || '',
      // NOTE: Must wrap in another instance of currency as an issue when
      // performing subsequent calculations on an instance using the `fromCents`
      // option https://github.com/scurker/currency.js/issues/425
      price: currency(
        currency(value.price, { fromCents: true, precision: 4 }),
        { precision: 4 }
      ),
      taxApplicable: value.taxes?.some((tax) => tax.enabled) || false,
      value: value.id,
    }))

    setHasRecentlyUsedItems(Boolean(items.length))
    setNextRecentlyUsedLineItems(items)
  }, [hasRecentlyUsedItems, recentlyUsedLineItems])

  if (isSavedTab) {
    return {
      hasItems: hasSavedItems,
      hasMore: hasMoreSavedItems,
      isLoading: isLoadingSavedItems,
      items: nextSavedItems,
      loadMore: loadMoreSavedItems,
    }
  }

  return {
    hasItems: hasRecentlyUsedItems,
    hasMore: false,
    isLoading: isLoadingRecentlyUsedLineItems,
    items: nextRecentlyUsedLineItems,
    loadMore: undefined,
  }
}
