import { ROOT, ROUTE } from 'const/routes'
import { page } from 'translations'

import { SubItem } from '../Sidebar.types'

interface UseSettingsSubItemsProps {
  connectionErrorsCount?: number
}
export const useSettingsSubItems = ({
  connectionErrorsCount,
}: UseSettingsSubItemsProps): SubItem[] => {
  return [
    {
      subitemTitle: page.title.users,
      subitemPath: ROUTE.PORTAL_SETTINGS_USERS,
    },
    {
      subitemTitle: page.title.profileSettings,
      subitemPath: ROUTE.PORTAL_SETTINGS_PROFILE,
    },
    {
      subitemTitle: page.title.feesAndPricing,
      subitemPath: ROUTE.PORTAL_SETTINGS_FEES_AND_PRICING,
    },
    {
      subitemTitle: page.title.connections,
      subitemPath: ROOT.PORTAL.SETTINGS.CONNECTIONS.path,
      numberBadgeCount: connectionErrorsCount,
    },
  ]
}
