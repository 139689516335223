import { useCallback } from 'react'
import { FetchMoreQueryOptions } from '@apollo/client'

import {
  GetContactInvoicesQueryVariables,
  useGetContactInvoicesLazyQuery,
} from '../../graphql/getContactInvoices.generated'

export const useGetContactInvoices = () => {
  const [
    getInvoices,
    { error, loading, data, fetchMore, refetch, subscribeToMore },
  ] = useGetContactInvoicesLazyQuery({
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const invoices = data?.getInvoices?.invoices
  const nextToken = data?.getInvoices?.nextToken

  const loadMore = useCallback(
    (options: FetchMoreQueryOptions<GetContactInvoicesQueryVariables>) =>
      fetchMore?.(options),
    [fetchMore]
  )

  return {
    getInvoices,
    getInvoicesError: error,
    invoices,
    isLoadingInvoices: loading,
    loadMore,
    nextToken,
    refetch,
    subscribeToMore,
  }
}
