import { useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  BodyDefault,
  Box,
  ButtonLink,
  ErrorMessageForm,
  Flex,
  InputSelectComboboxStandard,
  ModalForm,
} from '@npco/zeller-design-system'

import { getRandomItemColour } from 'components/ItemCategories'
import { translationsShared } from 'translations'

import { CategoryCreateModal } from '../../../CategoryCreateModal/CategoryCreateModal'
import { translations } from './CategoryDeleteModal.i18n'
import { CategoryDeleteModalItemsReassignFragment } from './CategoryDeleteModalItemsReassign.generated'
import { useCategoriesCombobox } from './hooks/useCategoriesCombobox'

const DEFAULT_MAX_ITEMS_TO_DISPLAY = 3

interface CategoryDeleteModalItemsReassignProps
  extends CategoryDeleteModalItemsReassignFragment {
  isDeletingCategory: boolean
  items: Array<{ id: string; name: string }>
  onCancel: () => void
  onDelete: (newCategoryId: string) => void
}

export const CategoryDeleteModalItemsReassign = ({
  id,
  isDeletingCategory,
  items,
  name,
  onCancel: handleCancel,
  onDelete: handleDelete,
}: CategoryDeleteModalItemsReassignProps) => {
  const [showAll, setShowAll] = useState(false)
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const {
    categoriesItems,
    handleChange,
    handleCloseCreateCategoryModal,
    handleClose,
    handleCreateCategory,
    handleInputChange,
    handleInputClear,
    handleLoadMore,
    hasMore,
    inputValue,
    isLoading,
    selectedItem,
    setValidationError,
    showCreateCategoryModal,
    validationError,
  } = useCategoriesCombobox(id)

  const itemsCount = items.length
  const itemsCountLabel =
    itemsCount > 1
      ? t('itemsCountPlural', { count: itemsCount })
      : t('itemsCountSingular')
  const itemLabel =
    itemsCount > 1 ? t('itemLabelPlural') : t('itemLabelSingular')

  const handleSubmit = () => {
    if (!selectedItem) {
      setValidationError(t('categoriesRequiredError'))
      return
    }
    handleDelete(selectedItem.value)
  }

  return (
    <ModalForm
      isOpen
      isLoading={isDeletingCategory}
      onClickPrimary={handleSubmit}
      primaryButtonLabel={tShared('delete')}
      secondaryButtonLabel={tShared('cancel')}
      onCancel={handleCancel}
      title={t('modalTitle')}
    >
      <Flex flexDirection="column" gridGap="24px">
        <BodyDefault as="div">
          {t('modalDescription', { itemLabel, items: itemsCountLabel, name })}
        </BodyDefault>
        <Box as="ul">
          {items
            .slice(0, showAll ? itemsCount : DEFAULT_MAX_ITEMS_TO_DISPLAY)
            .map((item) => (
              <BodyDefault as="li" key={item.id}>
                {item.name}
              </BodyDefault>
            ))}
          {itemsCount > DEFAULT_MAX_ITEMS_TO_DISPLAY && !showAll && (
            <li>
              <ButtonLink onClick={() => setShowAll(true)}>
                {t('viewMoreItems', {
                  itemsCount: `${itemsCount - DEFAULT_MAX_ITEMS_TO_DISPLAY}`,
                })}
              </ButtonLink>
            </li>
          )}
        </Box>
        <div>
          <InputSelectComboboxStandard
            addNewItemContent={tShared('createNew')}
            hasMore={hasMore}
            inputValue={inputValue}
            label={t('categoriesLabel')}
            name={name}
            onLoadMoreItems={handleLoadMore}
            isLoading={isLoading}
            items={categoriesItems}
            onClose={handleClose}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onInputClear={handleInputClear}
            selectedItem={selectedItem}
            hasError={!!validationError}
          />
          <ErrorMessageForm
            hasError={!!validationError}
            errorMessage={validationError}
          />
        </div>

        {showCreateCategoryModal && (
          <CategoryCreateModal
            initialValues={{
              name: inputValue,
              description: '',
              color: getRandomItemColour(),
            }}
            onClose={handleCloseCreateCategoryModal}
            onCreate={handleCreateCategory}
          />
        )}
      </Flex>
    </ModalForm>
  )
}
