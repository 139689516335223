import { useCallback, useState } from 'react'

import { fetchUploadLogoToS3Bucket } from 'components/ModalUploadLogo/ModalUploadLogo.utils'

import { useGetInvoiceSettingsLogoUploadUrlLazyQuery } from '../graphql/getInvoiceSettingsLogoUploadUrl.generated'

interface UseGetInvoiceSettingsLogoUploadUrlProps {
  onSuccess?: () => void
  onError?: () => void
}

export const useGetInvoiceSettingsLogoUploadUrl = ({
  onSuccess,
  onError,
}: UseGetInvoiceSettingsLogoUploadUrlProps = {}) => {
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [file, setFile] = useState<File | undefined>(undefined)

  const [getInvoiceSettingsLogoUploadUrl] =
    useGetInvoiceSettingsLogoUploadUrlLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: (responseData) => {
        fetchUploadLogoToS3Bucket({
          disableSuccessToast: true,
          disableErrorToast: true,
          s3BucketUrl: responseData.getInvoiceSettingsLogoUploadUrl,
          file,
          onSuccess: () => {
            onSuccess?.()
            setIsUploadingFile(false)
          },
          onError: () => {
            onError?.()
            setIsUploadingFile(false)
          },
        })
      },
      onError: () => {
        onError?.()
        setIsUploadingFile(false)
      },
    })

  const uploadInvoiceSettingsLogo = useCallback(
    (file: File) => {
      setFile(file)
      setIsUploadingFile(true)

      getInvoiceSettingsLogoUploadUrl()
    },
    [getInvoiceSettingsLogoUploadUrl]
  )

  return {
    isUploadingFile,
    uploadInvoiceSettingsLogo,
  }
}
