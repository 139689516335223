import { InterZellerMediumFont } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledHeading = styled.h1`
  ${InterZellerMediumFont};
  font-size: 22px;
  line-height: 1.27;
  margin: 0;
  margin-top: 8px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    font-size: 32px;
    line-height: 1.25;
  }
`
