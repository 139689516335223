import { gql } from '@apollo/client'

export const GetConnections = gql`
  query GetConnections {
    xeroBankFeeds: getConnectionState(connectionType: XERO_BANKFEEDS) {
      id
      status
      errorType
      __typename
    }
    xeroPaymentServices: getConnectionState(
      connectionType: XERO_PAYMENT_SERVICES
    ) {
      id
      status
      errorType
      __typename
    }
    payAtTable: getConnectionState(connectionType: HL_POS) {
      id
      status
      errorType
      __typename
    }
    oraclePos: getConnectionState(connectionType: ORACLE_POS) {
      id
      status
      errorType
      __typename
    }
  }
`
