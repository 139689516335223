import { useState } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { ButtonLink } from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { useIsInvoicingEnabled } from 'hooks/invoices/useIsInvoicingEnabled'
import { translate } from 'utils/translations'
import { page } from 'translations'

import { ContactForm } from '../../../components/ContactForm/ContactForm'
import { getContactFormInitialValues } from '../../../Contacts.utils'
import { useContactFormHelpers } from '../../../hooks/useContactFormHelpers/useContactFormHelpers'
import { useGetInvoiceDraftContactUsage } from '../../../hooks/useGetInvoiceDraftContactUsage/useGetInvoiceDraftContactUsage'
import { rvSelectedContact } from '../../../rv-deprecated/contacts'
import { updateRvContactsOnContactUpdate } from '../../../rv-deprecated/contacts.utils'
import { DeleteContact } from '../DeleteContact/DeleteContact'

export const EditContact = ({
  contact: existingContact,
}: {
  contact: ContactCoreFieldsFragment
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const isInvoicingEnabled = useIsInvoicingEnabled()

  const { invoiceDraftContactUsage } = useGetInvoiceDraftContactUsage({
    contactUuid: existingContact.id,
    skip: !isInvoicingEnabled,
  })

  const onEdit = (updatedContact: ContactCoreFieldsFragment) => {
    rvSelectedContact(updatedContact)
    updateRvContactsOnContactUpdate(updatedContact.id, updatedContact)
  }

  const {
    isLoading,
    handleSubmit,
    existingBusinessContactName,
    existingPersonContactName,
    setExistingBusinessContactName,
    setExistingPersonContactName,
  } = useContactFormHelpers({
    contactType: existingContact.contactType,
    contact: existingContact,
    onEdit,
  })

  const onEditClick = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <ButtonLink data-testid="contact-edit" onClick={onEditClick}>
        {translate('shared.edit')}
      </ButtonLink>
      {isModalOpen && (
        <ContactForm
          closeModal={() => {
            setIsModalOpen(false)
          }}
          contactType={existingContact.contactType}
          existingBusinessContactName={existingBusinessContactName}
          existingPersonContactName={existingPersonContactName}
          handleSubmit={handleSubmit}
          initialValues={getContactFormInitialValues(existingContact)}
          isLoading={isLoading}
          isModalOpen={isModalOpen}
          linkedInvoicesLength={invoiceDraftContactUsage}
          renderBottomSection={() => (
            <DeleteContact linkedInvoicesLength={invoiceDraftContactUsage} />
          )}
          setExistingBusinessContactName={setExistingBusinessContactName}
          setExistingPersonContactName={setExistingPersonContactName}
          title={
            existingContact.contactType === ContactType.BUSINESS
              ? page.contacts.form.editTitle.business
              : page.contacts.form.editTitle.person
          }
        />
      )}
    </>
  )
}
