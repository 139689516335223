import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { ButtonLink, Flex, Heading } from '@npco/zeller-design-system'

import { useIsInvoicingEnabled } from 'hooks/invoices/useIsInvoicingEnabled'
import { ROOT } from 'const/routes'
import { CompanyLogoLayoutProps } from 'components/InvoiceCompanyLogoUpload/InvoiceCompanyLogoUpload'
import { translationsShared } from 'translations'

import { SettingDescription } from '../SettingsGeneralForm/SettingsGeneralForm.styled'
import { translations } from './CompanyLogoLayout.i18n'
import * as styled from './CompanyLogoLayout.styled'

export const CompanyLogoLayout = ({
  children,
  onRemove,
}: CompanyLogoLayoutProps) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const navigate = useNavigate()

  const navigateToCustomisation = useCallback(() => {
    navigate(ROOT.PORTAL.INVOICING.INVOICES.SETTINGS.CUSTOMISATION.path)
  }, [navigate])

  const isInvoicingEnabled = useIsInvoicingEnabled()

  return (
    <>
      <Flex justifyContent="space-between" mb="16px">
        <Heading.H3>{t('logoTitle')}</Heading.H3>
        {onRemove && (
          <ButtonLink onClick={onRemove}>{tShared('remove')}</ButtonLink>
        )}
      </Flex>
      <SettingDescription>
        {isInvoicingEnabled
          ? t('logoDescriptionFeatureFlagged', {
              link: (
                <ButtonLink isInline onClick={navigateToCustomisation}>
                  {t('customisationLink')}
                </ButtonLink>
              ),
            })
          : t('logoDescription')}
      </SettingDescription>
      {children}
      <styled.ImageDisclaimer>{t('uploadDescription')}</styled.ImageDisclaimer>
    </>
  )
}
