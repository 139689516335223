import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'

import { SetupFlowState } from './useGoToSetupFlowStage.types'

export const useGoToSetupFlowStage = () => {
  const navigate = useNavigate()

  const goToSetupFlowStage = useCallback(
    (state: SetupFlowState | undefined) => {
      navigate(ROOT.PORTAL.OVERVIEW.PAYMENTS.path, {
        state: {
          SetupFlowModal: state,
        },
      })
    },
    [navigate]
  )

  return { goToSetupFlowStage }
}
