import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetSetupFlowDebitCardAccountsQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type GetSetupFlowDebitCardAccountsQueryResponse = {
  __typename?: 'Query'
  getDebitCardAccountsV2: {
    __typename?: 'DebitCardAccountConnectionV2'
    accounts: Array<{
      __typename?: 'DebitCardAccountV2'
      id: string
      accountDetails: {
        __typename?: 'BankAccountDetails'
        name: string | null
        bsb: string | null
        account: string | null
      } | null
    } | null>
  }
}

export const GetSetupFlowDebitCardAccounts = gql`
  query GetSetupFlowDebitCardAccounts {
    getDebitCardAccountsV2(limit: 50) {
      accounts {
        id
        accountDetails {
          name
          bsb
          account
        }
      }
    }
  }
` as unknown as TypedDocumentNode<
  GetSetupFlowDebitCardAccountsQueryResponse,
  GetSetupFlowDebitCardAccountsQueryVariables
>

/**
 * __useGetSetupFlowDebitCardAccountsQuery__
 *
 * To run a query within a React component, call `useGetSetupFlowDebitCardAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSetupFlowDebitCardAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSetupFlowDebitCardAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSetupFlowDebitCardAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSetupFlowDebitCardAccountsQueryResponse,
    GetSetupFlowDebitCardAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSetupFlowDebitCardAccountsQueryResponse,
    GetSetupFlowDebitCardAccountsQueryVariables
  >(GetSetupFlowDebitCardAccounts, options)
}
export function useGetSetupFlowDebitCardAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSetupFlowDebitCardAccountsQueryResponse,
    GetSetupFlowDebitCardAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSetupFlowDebitCardAccountsQueryResponse,
    GetSetupFlowDebitCardAccountsQueryVariables
  >(GetSetupFlowDebitCardAccounts, options)
}
export type GetSetupFlowDebitCardAccountsQueryHookResult = ReturnType<
  typeof useGetSetupFlowDebitCardAccountsQuery
>
export type GetSetupFlowDebitCardAccountsLazyQueryHookResult = ReturnType<
  typeof useGetSetupFlowDebitCardAccountsLazyQuery
>
export type GetSetupFlowDebitCardAccountsQueryResult = Apollo.QueryResult<
  GetSetupFlowDebitCardAccountsQueryResponse,
  GetSetupFlowDebitCardAccountsQueryVariables
>
