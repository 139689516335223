import { useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import {
  AvatarBasic,
  Box,
  Flex,
  PillSize,
  PillYellow,
} from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { AVATAR_BADGE_SIZE } from 'types/common'
import { translations } from 'translations/shared.i18n'

import { TagPills } from '../components/TagPills/TagPills'
import { getContactAvatarBadgeOptions } from '../Contact/ContactGeneral/ContactGeneral.util'
import * as styled from '../Contacts.styled'
import { ContactCoreFieldsFragment } from '../graphql/ContactCoreFields.generated'
import { rvSelectedContact } from '../rv-deprecated/contacts'
import { businessListItemTranslations } from './BusinessListItem.i18n'

interface BusinessListItemProps {
  contact: ContactCoreFieldsFragment | null
}

export const BusinessListItem = ({ contact }: BusinessListItemProps) => {
  const tShared = useTranslations(translations)
  const t = useTranslations(businessListItemTranslations)
  const navigate = useNavigate()
  if (!contact) {
    return null
  }

  const { id, businessName, tags } = contact

  const avatarOptions = getContactAvatarBadgeOptions(contact)

  return (
    <styled.ContactItemContainer
      key={id}
      onClick={() => {
        // NOTE: route is driven by contact id but this isn't yet used to fetch
        // contact, still needs uplift to remove use of rvSelectedContact
        rvSelectedContact(contact)
        navigate(ROOT.PORTAL.CONTACTS.BUSINESSES.CONTACT(id).path)
      }}
      aria-label={t('listItemAriaLabel', { businessName: businessName ?? '' })}
    >
      <AvatarBasic
        badgeSize={AVATAR_BADGE_SIZE.SMALL}
        bgColor={avatarOptions.bgColor}
        text={avatarOptions.text}
        userDefinedContent={avatarOptions.userDefinedContent}
        withCursorPointer
      />
      <styled.ContactsContentWrapper>
        <styled.ContactsListItemWrapper>
          {businessName}
        </styled.ContactsListItemWrapper>
      </styled.ContactsContentWrapper>
      <Flex alignItems="center" justifyContent="flex-end">
        <TagPills tags={tags} />
        {contact.isSelf && (
          <Box px="6px">
            <PillYellow text={tShared('you')} size={PillSize.Small} />
          </Box>
        )}
      </Flex>
    </styled.ContactItemContainer>
  )
}
