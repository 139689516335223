import { useState } from 'react'
import {
  InputSelectComboboxItemBasic,
  NEW_ITEM_VALUE,
  SelectStateChangeProps,
  SelectStateInputChangeProps,
} from '@npco/zeller-design-system'
import { UseComboboxStateChange } from 'downshift'
import { Subject } from 'rxjs'

import { InputComboboxValidationErrors } from './useInputCombobox.types'

interface UseInputComboboxProps<T extends InputSelectComboboxItemBasic> {
  isOptional?: boolean
  name$?: Subject<string>
  onChange?: (changes: SelectStateChangeProps<T>) => void
  onClose?: (changes: UseComboboxStateChange<T>) => void
  onInputChange?: (inputValue: string) => void
  onInputClear?: () => void
  onValidationError?: (validationError: string) => void
  validationErrorMessages?: Record<
    keyof typeof InputComboboxValidationErrors,
    string
  >
}

export const useInputCombobox = <T extends InputSelectComboboxItemBasic>({
  isOptional,
  name$,
  onChange,
  onClose,
  onInputChange,
  onInputClear,
  onValidationError,
  validationErrorMessages,
}: UseInputComboboxProps<T> = {}) => {
  const [selectedItem, setSelectedItem] = useState<T | null>(null)

  const [inputValue, setInputValue] = useState<string>('')

  const handleChange = (changes: SelectStateChangeProps<T>) => {
    onChange?.(changes)

    if (changes.selectedItem?.value !== NEW_ITEM_VALUE) {
      setSelectedItem(changes.selectedItem)
    }
  }

  const handleClose = (changes: UseComboboxStateChange<T>) => {
    // NOTE: when a user has an item, changes the input value and navigates away
    // from the menu causing the menu to close reset the input value to that of
    // the selected item
    if (
      changes.selectedItem &&
      changes.selectedItem.value !== NEW_ITEM_VALUE &&
      changes.selectedItem.value !== changes.inputValue
    ) {
      setInputValue(changes.selectedItem.value)
      onInputChange?.(changes.selectedItem.value)
    }

    // NOTE: reset validation error item is selected and input value
    if (changes.selectedItem && changes.inputValue) {
      onValidationError?.('')
    }

    // NOTE: reset validation error if no item is selected, no input value and
    // is marked as an optional field
    if (!changes.selectedItem && !changes.inputValue && isOptional) {
      onValidationError?.('')
    }

    // NOTE: if no selected item and input value validate as selection required
    if (!changes.selectedItem && changes.inputValue) {
      onValidationError?.(validationErrorMessages?.SelectionRequired ?? '')
    }

    // NOTE: if no selected item and no input value validate as required
    if (!changes.selectedItem && !changes.inputValue && !isOptional) {
      onValidationError?.(validationErrorMessages?.Required ?? '')
    }

    onClose?.(changes)
  }

  const handleInputChange = (changes: SelectStateInputChangeProps<T>) => {
    const value = changes.inputValue

    if (value === '') {
      setSelectedItem(null)
    }

    setInputValue(value)

    // NOTE: only fetch when no selected item or when current selected item
    // value does not match current input value
    if (!changes.selectedItem || changes.selectedItem.value !== value) {
      name$?.next(value)
    }

    onInputChange?.(value)
  }

  const handleInputClear = () => {
    setInputValue('')
    setSelectedItem(null)

    name$?.next('')

    onInputClear?.()
  }

  return {
    handleChange,
    handleClose,
    handleInputChange,
    handleInputClear,
    inputValue,
    selectedItem,
    setInputValue,
    setSelectedItem,
  }
}
