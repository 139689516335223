import { gql } from '@apollo/client'
import { DebitCardFormat, DebitCardProductType } from '@npco/mp-gql-types'
import {
  ButtonGhostIconOnly,
  ButtonGhostIconRight,
  SelectSize,
  SelectStandard,
} from '@npco/zeller-design-system'

import { ReactComponent as ArrowDown } from 'assets/svg/arrow-dropdown.svg'
import { ReactComponent as DotsVertical } from 'assets/svg/dots-vertical.svg'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

import { useCardId } from '../hooks/useCardId'
import { CardSingleManageDropdownDebitCardV2Fragment } from './CardSingleManageDropdown.generated'
import {
  CardSingleManageDropdownOption,
  useCardSingleManageDropdownOptions,
} from './hooks/useCardSingleManageDropdownOptions'

export interface CardSingleManageDropdownProps {
  card: CardSingleManageDropdownDebitCardV2Fragment
  unlockCard: () => void
  entityAddressTimezone: string | null
}

export const CardSingleManageDropdown = ({
  card,
  unlockCard,
  entityAddressTimezone,
}: CardSingleManageDropdownProps) => {
  const isMobile = useIsMobileResolution()
  const { cardId } = useCardId()

  const { cardSingleManageDropdownOptions } =
    useCardSingleManageDropdownOptions({
      status: card.status,
      colour: card.colour,
      cardName: card.name,
      cardFormat: card.format || DebitCardFormat.PHYSICAL,
      cardOwner: card.customer,
      productType: card.productType || DebitCardProductType.DEBIT,
      isAccessible: Boolean(card.accessibleProfile),
      unlockCard,
      velocityControl: card.velocityControl,
      accountUuid: card.debitCardAccount.id,
      entityAddressTimezone,
    })

  if (cardSingleManageDropdownOptions.length === 0) {
    return null
  }

  return (
    <SelectStandard<CardSingleManageDropdownOption>
      popperWidth="15rem"
      maxHeight="22rem"
      items={cardSingleManageDropdownOptions}
      placement="bottom-end"
      selectedItem={null}
      mobileLabel={translate('page.cardSingle.manage.buttonLabelMobile')}
      onChange={(item) => item?.onClick && item.onClick(cardId)}
      size={SelectSize.Small}
      renderTrigger={(renderProps) =>
        isMobile ? (
          <ButtonGhostIconOnly
            aria-label={translate('page.cardSingle.manage.buttonAriaLabel')}
            icon={DotsVertical}
            ref={renderProps.ref}
            onClick={renderProps.onClick}
          />
        ) : (
          <ButtonGhostIconRight
            icon={ArrowDown}
            ref={renderProps.ref}
            onClick={renderProps.onClick}
          >
            {translate('page.cardSingle.manage.buttonLabel')}
          </ButtonGhostIconRight>
        )
      }
    />
  )
}

CardSingleManageDropdown.fragments = {
  DebitCardV2: gql`
    fragment CardSingleManageDropdownDebitCardV2Fragment on DebitCardV2 {
      accessibleProfile
      name
      status
      colour
      format
      productType
      debitCardAccount {
        id
      }
      customer {
        id
        firstname
      }
      velocityControl {
        amountLimit {
          value
        }
        maxTransactionValue {
          value
        }
        velocityWindow
        modifiedBy {
          actingCustomerUuid
          updatedAt
        }
      }
    }
  `,
}
