import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import {
  Box,
  ButtonLink,
  Flex,
  InfoBox,
  showApiErrorToast,
} from '@npco/zeller-design-system'
import { rvSelectedSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import {
  rvSiteDetails,
  rvSiteFetchError,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables/sites'

import { ROOT } from 'const/routes'
import { SITE_TYPE } from 'const/settings'
import { getNewlyCreatedSiteFromStorage } from 'utils/newSitesHelper'
import { translate } from 'utils/translations'
import {
  getSiteTypeValue,
  hasCannotFindSiteError,
} from 'pages/Settings/SiteGeneral/SiteGeneral.helpers'
import { SiteGeneralField } from 'pages/Settings/SiteGeneral/SiteGeneralField/SiteGeneralField'
import { form, notifications, page } from 'translations'

import { StyledTitle } from './SiteGeneral.styled'

export const SiteGeneral = () => {
  const siteDetails = useReactiveVar(rvSiteDetails)
  const siteError = useReactiveVar(rvSiteFetchError)
  const siteUuid = rvSelectedSite()

  const [isInfoMessageShown, setIsInfoMessageShown] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (siteError) {
      if (hasCannotFindSiteError(siteError)) {
        setIsInfoMessageShown(true)
        rvSiteDetails(getNewlyCreatedSiteFromStorage(siteUuid))
      } else {
        showApiErrorToast(siteError)
        navigate(ROOT.PORTAL.PAYMENTS.SITES.path)
      }
    }
  }, [navigate, siteError, siteUuid])

  const isFixedSite = Boolean(
    siteDetails && siteDetails.type === SITE_TYPE.FIXED
  )

  return (
    <Box>
      {isInfoMessageShown && (
        <Box mb="32px">
          <InfoBox>
            <InfoBox.Message>
              {notifications.siteNotCreatedOnBackend}
            </InfoBox.Message>
          </InfoBox>
        </Box>
      )}
      <Flex justifyContent="space-between" alignItems="center">
        <StyledTitle>{page.settings.general.title}</StyledTitle>
        {!isInfoMessageShown && (
          <ButtonLink
            onClick={() =>
              navigate(ROOT.PORTAL.PAYMENTS.SITES.SITE.GENERAL.EDIT.path)
            }
          >
            {translate('page.settings.general.edit')}
          </ButtonLink>
        )}
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        flex="1 0 auto"
        my="32px"
      >
        <SiteGeneralField
          label={form.shared.siteName}
          value={siteDetails?.name}
        />
        <SiteGeneralField
          label={form.shared.businessName}
          value={siteDetails?.receipt?.name}
        />
        <SiteGeneralField label={form.shared.pin} value={siteDetails?.pin} />
        <SiteGeneralField
          label={form.shared.wayOfUsing}
          value={getSiteTypeValue(siteDetails)}
        />
        <SiteGeneralField
          label={form.shared.address}
          value={siteDetails?.address?.street}
          isVisible={isFixedSite}
        />
        <SiteGeneralField
          label={form.shared.suburb.label}
          value={siteDetails?.address?.suburb}
          isVisible={isFixedSite}
        />
        <SiteGeneralField
          label={form.shared.state.label}
          value={siteDetails?.address?.state}
          isVisible={isFixedSite}
        />
        <SiteGeneralField
          label={form.shared.postcode.label}
          value={siteDetails?.address?.postcode}
          isVisible={isFixedSite}
        />
      </Flex>
    </Box>
  )
}
