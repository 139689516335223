import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { VelocityControlUpdateInput } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { updateCachedCard } from 'features/utils-cards-cache'

import { getVelocityControlNewResetsAtApiFormat } from 'utils/banking/getVelocityControlNewResetsAt/getVelocityControlNewResetsAt'
import dayjs from 'utils/dayjs'
import { UNEXPECTED_ERROR } from 'types/errors'
import {
  EditSpendControls as EditSpendControlsResponse,
  EditSpendControlsVariables as UpdateDebitCardVariables,
} from 'types/gql-types/EditSpendControls'

import { EditSpendControls } from './graphql/EditSpendControls'

interface EditSpendControlsVariables {
  cardUuid: string
  velocityControl: VelocityControlUpdateInput
  entityAddressTimezone?: string
  isUpdatingVelocityWindow: boolean
}

export const useEditSpendControls = () => {
  const { userData } = useLoggedInUser()

  const [editSpendControlsMutation, { loading: isLoading }] = useMutation<
    EditSpendControlsResponse,
    UpdateDebitCardVariables
  >(EditSpendControls)

  const editSpendControls = useCallback(
    async ({
      cardUuid,
      velocityControl,
      entityAddressTimezone,
      isUpdatingVelocityWindow,
    }: EditSpendControlsVariables) => {
      try {
        const result = await editSpendControlsMutation({
          variables: {
            input: { cardUuid, velocityControl },
          },
          update: (cache, result) => {
            if (result.data?.updateDebitCard) {
              const sharedUpdatedFields = {
                amountLimit: velocityControl.amountLimit,
                maxTransactionValue: velocityControl.maxTransactionValue,
                velocityWindow: velocityControl.velocityWindow,
                modifiedBy: {
                  actingCustomerUuid: userData?.id || '',
                  updatedAt: dayjs().toISOString(),
                },
                resetsAt: getVelocityControlNewResetsAtApiFormat({
                  entityAddressTimezone,
                  velocityWindow: velocityControl.velocityWindow,
                }),
              }

              updateCachedCard({
                cache,
                cardUpdate: {
                  id: cardUuid,
                  velocityControl: (oldVelocityControl) => {
                    if (isUpdatingVelocityWindow) {
                      return {
                        ...oldVelocityControl,
                        ...sharedUpdatedFields,
                        isPendingSubscriptionUpdate: true,
                      }
                    }

                    const previouslyAvailableAmount = parseInt(
                      oldVelocityControl?.availableAmount?.value ?? '0',
                      10
                    )

                    const newAmountLimit = parseInt(
                      velocityControl.amountLimit.value,
                      10
                    )
                    const previousAmountLimit = parseInt(
                      oldVelocityControl?.amountLimit?.value ?? '0',
                      10
                    )

                    return {
                      ...oldVelocityControl,
                      availableAmount: {
                        currency: 'AUD',
                        value: Math.max(
                          previouslyAvailableAmount +
                            newAmountLimit -
                            previousAmountLimit,
                          0
                        ).toString(),
                      },
                      ...sharedUpdatedFields,
                    }
                  },
                },
              })
            }
          },
        })

        if (!result.data?.updateDebitCard) {
          return UNEXPECTED_ERROR
        }

        return { result: result.data.updateDebitCard }
      } catch (err) {
        return UNEXPECTED_ERROR
      }
    },
    [editSpendControlsMutation, userData?.id]
  )

  return { editSpendControls, isLoading }
}
