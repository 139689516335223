import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'

import { GetOraclePosLocalState } from './graphql/getOraclePosLocalState'
import {
  CacheLocalStateOraclePos,
  LocalStateOraclePos,
} from './oraclePos.types'

export const useOraclePosLocalCache = () => {
  const { cache } = useApolloClient()
  const updateCache = useCallback(
    async ({
      isOverridePairingModalOpen,
      currentDeviceUuid,
      currentDeviceName,
      newDeviceUuid,
      newDeviceName,
      workstationId,
    }: LocalStateOraclePos) =>
      cache.updateQuery<CacheLocalStateOraclePos>(
        {
          query: GetOraclePosLocalState,
        },
        () => ({
          local: {
            oraclePos: {
              isOverridePairingModalOpen,
              currentDeviceUuid,
              currentDeviceName,
              newDeviceUuid,
              newDeviceName,
              workstationId,
            },
          },
        })
      ),
    [cache]
  )

  return { updateCache }
}
