import { useEffect, useMemo } from 'react'
import { Redirect } from 'react-router'
import { useLocation } from 'react-router-dom'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'
import {
  Animation,
  Flex,
  slideSideAnimationVariants,
  Spinner,
} from '@npco/zeller-design-system'
import { useMachine } from '@xstate/react'
import { useBPayTransferMFAState } from 'features/MFA'
import { useContactTransferMFAState } from 'features/MFA/MFAContext/hooks/useContactTransferMFAState/useContactTransferMFAState'

import { IS_DEVELOPMENT } from 'const/envs'
import { ROUTE } from 'const/routes'
import { TransferStateType } from 'pages/Transfer/Transfer.context'
import { TransferProvider } from 'pages/Transfer/Transfer.provider'
import {
  createTransferMachine,
  TransferBpayState,
  TransferContactState,
  TransferGeneralState,
  TransferZellerState,
} from 'pages/Transfer/Transfer.stateMachine'

import { useGoToBlockedAction } from '../../features/BlockedAction/blocked-action-routing'
import { isNotNull } from '../../utils/common'
import { useTransferQuery } from './hooks/useTransferQuery/useTransferQuery'
import { useTransferRouteState } from './hooks/useTransferRouteState/useTransferRouteState'
import { StyledSpinnerWrapper } from './Transfer.styled'
import { TransferContent } from './TransferContent'

const REDIRECT_STATES = [
  `${TransferGeneralState.ZellerTransfer}.${TransferZellerState.RedirectToAccountList}`,
  `${TransferGeneralState.ContactTransfer}.${TransferContactState.RedirectToAccountList}`,
  `${TransferGeneralState.BpayTransfer}.${TransferBpayState.RedirectToAccountList}`,
]

export const Transfer = () => {
  const {
    allAccounts: availableZellerAccounts,
    entity,
    isLoadingAccounts,
    accountById,
  } = useTransferQuery()

  const { goToBlockedAction } = useGoToBlockedAction()

  const { state: locationState } = useLocation<{
    initialDropdownAccountId: string | null | undefined
  }>()
  const { state: routeState } = useTransferRouteState()
  const initialDropdownAccountId = locationState?.initialDropdownAccountId
  const { from, to, reference, transferType } = routeState ?? {}

  const {
    hasRedirectedBackToApp: hasRedirectedBackToAppBPay,
    bpayTransferState,
  } = useBPayTransferMFAState()

  const {
    hasRedirectedBackToApp: hasRedirectedBackToAppContact,
    transferState: contactTransferState,
  } = useContactTransferMFAState()

  useEffect(() => {
    const isTransferOutBlocked = entity?.canTransferOut === false
    if (isTransferOutBlocked) {
      goToBlockedAction({ blockedAction: 'transferOut' }, ROUTE.PORTAL_ACCOUNTS)
    }
  }, [entity?.canTransferOut, goToBlockedAction])

  const activeAccounts = useMemo(
    () =>
      availableZellerAccounts
        .filter(isNotNull)
        .filter((account) => account.status === DebitCardAccountStatus.ACTIVE),
    [availableZellerAccounts]
  )

  const filteredActiveAccounts = useMemo(() => {
    return activeAccounts.filter(
      (account) => account?.type === DebitCardAccountType.ZLR_DEBIT
    )
  }, [activeAccounts])

  const fromAccountId = useMemo(() => {
    if (hasRedirectedBackToAppContact && contactTransferState) {
      return contactTransferState.values.fromAccount.id
    }
    if (hasRedirectedBackToAppBPay && bpayTransferState) {
      return bpayTransferState.values.from.id
    }
    return (
      initialDropdownAccountId ?? from ?? filteredActiveAccounts[0]?.id ?? null
    )
  }, [
    bpayTransferState,
    contactTransferState,
    filteredActiveAccounts,
    from,
    hasRedirectedBackToAppBPay,
    hasRedirectedBackToAppContact,
    initialDropdownAccountId,
  ])

  const fromAccount = useMemo(
    () => activeAccounts.find((account) => account.id === fromAccountId),
    [activeAccounts, fromAccountId]
  )

  const initialTransferMachine = useMemo(
    () =>
      createTransferMachine({
        type: transferType,
        initialAccountType: fromAccount?.type,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const [state, send] = useMachine(initialTransferMachine, {
    devTools: IS_DEVELOPMENT,
  })

  const transferState = useMemo(() => [state, send], [send, state])

  if (REDIRECT_STATES.some(state.matches)) {
    return <Redirect to={ROUTE.PORTAL_ACCOUNTS} />
  }

  if (isLoadingAccounts) {
    return (
      <Flex flex={1} flexDirection="column">
        <StyledSpinnerWrapper>
          <Animation variants={slideSideAnimationVariants}>
            <Spinner dataTestId="loader" />
          </Animation>
        </StyledSpinnerWrapper>
      </Flex>
    )
  }

  return (
    <TransferProvider transferState={transferState as TransferStateType}>
      <TransferContent
        initialFromAccountId={fromAccountId}
        initialToAccountId={to}
        reference={reference}
        accountById={accountById}
      />
    </TransferProvider>
  )
}
