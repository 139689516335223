import { useMutation } from '@apollo/client'
import type { ContactInput } from '@npco/mp-gql-types'
import {
  CreateContact,
  CreateContacts,
} from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/contacts'

import type {
  CreateContact as CreateContactResponse,
  CreateContactVariables,
} from 'types/gql-types/CreateContact'
import type {
  CreateContacts as CreateContactsResponse,
  CreateContactsVariables,
} from 'types/gql-types/CreateContacts'

import { useContactCache } from '../useContactCache/useContactCache'

export const useCreateContacts = () => {
  const { writeToCache } = useContactCache()
  const [createContact, { loading: isCreatingContact }] = useMutation<
    CreateContactResponse,
    CreateContactVariables
  >(CreateContact)

  const [createContacts, { loading: isCreatingContacts }] = useMutation<
    CreateContactsResponse,
    CreateContactsVariables
  >(CreateContacts)

  return {
    createContact: (contact: ContactInput, linkedContactUuid?: string) => {
      return createContact({
        variables: {
          contact,
          linkedContactUuid,
        },
        update: (_, result) => {
          if (result.data?.createContact) {
            writeToCache(result.data.createContact)
          }
        },
      })
    },

    createContacts: (contact1: ContactInput, contact2: ContactInput) =>
      createContacts({
        variables: {
          contact1,
          contact2,
        },
      }),
    isCreatingContact,
    isCreatingContacts,
  }
}
