import { forwardRef, useMemo } from 'react'
import Truncate from 'react-truncate'
import { IconAvatar } from '@npco/mp-ui-icon-avatar'
import {
  Box,
  COLOR,
  Flex,
  Title,
  TooltipButton,
} from '@npco/zeller-design-system'

import { useAccounts } from 'hooks/banking'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { currencyFormatter } from 'utils/common'
import { formatDayMonth, formatTime } from 'utils/date'
import { parseApiMoney } from 'utils/parseMoney'
import { AVATAR_BADGE_SIZE } from 'types/common'
import { DebitCardTransaction } from 'pages/DebitCardTransactions'
import { getIsOutgoingTransaction } from 'pages/DebitCardTransactions/DebitCardTransactions.utils'
import { getDescription } from 'pages/DebitCardTransactions/DebitCardTransactionsList/ListGroupItemDebitCardTransactions/ListGroupItemDebitCardTransactions.utils'
import { AccountTransactionTypeIcon } from 'components/AccountTransactionTypeIcon/AccountTransactionTypeIcon'

import * as styled from './AccountListItem.styled'

export interface AccountListItemProps {
  account: ReturnType<ReturnType<typeof useAccounts>['accountById']>
  transaction: DebitCardTransaction
  showDetails: (transaction: DebitCardTransaction) => void
  isNew?: boolean
}

export const AccountListItem = forwardRef<HTMLLIElement, AccountListItemProps>(
  ({ account, transaction, showDetails, isNew }, ref) => {
    const { amount, timestamp } = transaction
    const isMobile = useIsMobileResolution()

    const parsedAmount = parseApiMoney(amount.value)
    const formattedAmount = currencyFormatter(parsedAmount)

    const isOutgoingTransaction = getIsOutgoingTransaction(transaction)

    const badgeOptions = useMemo(
      () =>
        account ? (
          <IconAvatar
            icon={null}
            badgeSize={AVATAR_BADGE_SIZE.SMALL}
            defaultLetter={account.icon.letter}
            defaultBgColor={account.icon.colour}
          />
        ) : null,
      [account]
    )

    if (isMobile) {
      return (
        <styled.MobileContainer
          ref={ref}
          data-testid="account-transaction-row"
          tabIndex={0}
          onClick={() => showDetails(transaction)}
          $isNew={isNew}
        >
          <Flex justifyContent="space-between" alignItems="center" mb="0.5rem">
            <Title>{account?.name}</Title>
            <Flex>
              <Box mr="0.2rem">
                <styled.StyledDebitCardTransactionStatusBadge
                  status={transaction.status}
                  isWithoutText={isMobile}
                />
              </Box>
              <styled.TransactionAmount
                $isNegativeAmount={isOutgoingTransaction}
              >
                {isOutgoingTransaction
                  ? `-${formattedAmount}`
                  : formattedAmount}
              </styled.TransactionAmount>
            </Flex>
          </Flex>

          <Flex justifyContent="space-between" alignItems="center">
            <styled.DescriptionText>
              <Truncate lines={2}>{getDescription(transaction)}</Truncate>
            </styled.DescriptionText>
            <AccountTransactionTypeIcon
              transaction={transaction}
              color={COLOR.BLACK}
            />
          </Flex>
        </styled.MobileContainer>
      )
    }

    return (
      <styled.Container
        ref={ref}
        data-testid="account-transaction-row"
        tabIndex={0}
        onClick={() => showDetails(transaction)}
        $isNew={isNew}
      >
        <TooltipButton
          placement="top"
          showArrow={false}
          tooltipIcon={<Title>{formatDayMonth(timestamp)}</Title>}
        >
          {formatTime(timestamp)}
        </TooltipButton>

        <Flex justifyContent="center">{badgeOptions}</Flex>

        <Title>
          <Truncate lines={2}>{account?.name}</Truncate>
        </Title>
        <AccountTransactionTypeIcon
          transaction={transaction}
          color={COLOR.BLACK}
        />

        <styled.DescriptionText>
          <Truncate lines={2}>{getDescription(transaction)}</Truncate>
        </styled.DescriptionText>

        <styled.StyledDebitCardTransactionStatusBadge
          status={transaction.status}
          isWithoutIcon
          isWithoutText={isMobile}
        />
        <styled.TransactionAmount $isNegativeAmount={isOutgoingTransaction}>
          {isOutgoingTransaction ? `-${formattedAmount}` : formattedAmount}
        </styled.TransactionAmount>
      </styled.Container>
    )
  }
)
