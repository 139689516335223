import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  descriptionLabel: 'Description (Optional)',
  descriptionMaxLengthError:
    'Category Description must not exceed {length} characters.',
  errorNotification: 'Category could not be added. Please try again.',
  heading: 'Add Category',
  nameLabel: 'Name',
  nameMaxLengthError: 'Category Name must not exceed {length} characters.',
  nameRequiredError: 'Name is required.',
  successNotification: 'Category added successfully.',
})
