import { useQuery } from '@apollo/client'
import { PaymentInstrumentType } from '@npco/mp-gql-types'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetPaymentInstrumentsWithoutContact } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/paymentInstruments'
import { rvUnlinkedPaymentInstruments } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/paymentInstruments'

import {
  BankAccountDetails,
  UnlinkedBsbPaymentInstrument,
} from 'hooks/paymentInstruments/PaymentInstruments.types'
import {
  GetPaymentInstrumentsWithoutContact as GetUnlinkedPaymentInstrumentsResponse,
  GetPaymentInstrumentsWithoutContactVariables,
} from 'types/gql-types/GetPaymentInstrumentsWithoutContact'

interface UseUnlinkedBsbPaymentInstrumentsProps {
  shouldSkipQuery?: boolean
}

export const useUnlinkedBsbPaymentInstruments = ({
  shouldSkipQuery = false,
}: UseUnlinkedBsbPaymentInstrumentsProps) => {
  const { loading } = useQuery<
    GetUnlinkedPaymentInstrumentsResponse,
    GetPaymentInstrumentsWithoutContactVariables
  >(GetPaymentInstrumentsWithoutContact, {
    errorPolicy: 'all',
    skip: shouldSkipQuery,
    onCompleted: (responseData) => {
      const paymentInstrument = (
        responseData?.getPaymentInstrumentsWithoutContact || []
      ).filter((item): item is UnlinkedBsbPaymentInstrument => {
        if (!item?.id) {
          return false
        }

        const details = item?.details as BankAccountDetails

        const account = details?.account
        const bsb = details?.bsb
        const name = details?.name

        return Boolean(account && bsb && name)
      })

      rvUnlinkedPaymentInstruments(paymentInstrument)
    },
    onError: (error) => {
      showApiErrorToast(error)
    },
    variables: {
      filter: { type: { eq: PaymentInstrumentType.BSB } },
    },
  })

  return {
    isLoadingUnlinkedPaymentInstruments: loading,
  }
}
