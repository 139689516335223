import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type AddItemCategoryMutationVariables = Types.Exact<{
  category: Types.CreateItemCategoryInput
}>

export type AddItemCategoryMutationResponse = {
  __typename?: 'Mutation'
  createItemCategory: {
    __typename: 'ItemCategory'
    color: string
    description: string | null
    id: string
    name: string
  }
}

export const AddItemCategory = gql`
  mutation AddItemCategory($category: CreateItemCategoryInput!) {
    createItemCategory(input: $category) {
      __typename
      color
      description
      id
      name
    }
  }
` as unknown as TypedDocumentNode<
  AddItemCategoryMutationResponse,
  AddItemCategoryMutationVariables
>
export type AddItemCategoryMutationFn = Apollo.MutationFunction<
  AddItemCategoryMutationResponse,
  AddItemCategoryMutationVariables
>

/**
 * __useAddItemCategoryMutation__
 *
 * To run a mutation, you first call `useAddItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemCategoryMutation, { data, loading, error }] = useAddItemCategoryMutation({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useAddItemCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddItemCategoryMutationResponse,
    AddItemCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddItemCategoryMutationResponse,
    AddItemCategoryMutationVariables
  >(AddItemCategory, options)
}
export type AddItemCategoryMutationHookResult = ReturnType<
  typeof useAddItemCategoryMutation
>
export type AddItemCategoryMutationResult =
  Apollo.MutationResult<AddItemCategoryMutationResponse>
export type AddItemCategoryMutationOptions = Apollo.BaseMutationOptions<
  AddItemCategoryMutationResponse,
  AddItemCategoryMutationVariables
>
