import { useTranslations } from '@npco/utils-translations'
import { Breadcrumb, PageTemplate } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'

import { cardsSettingsBreadcrumbsTranslations } from './CardsSettingsBreadcrumbs.i18n'

export const CardsSettingsBreadcrumbs = () => {
  const t = useTranslations(cardsSettingsBreadcrumbsTranslations)

  return (
    <PageTemplate.HeaderPrimaryRow>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Breadcrumb.Link to={ROOT.PORTAL.CARDS.CORPORATE.path}>
            {t('breadcrumb')}
          </Breadcrumb.Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item isCurrent>
          <Breadcrumb.Text>{t('subBreadcrumb')}</Breadcrumb.Text>
        </Breadcrumb.Item>
      </Breadcrumb>
    </PageTemplate.HeaderPrimaryRow>
  )
}
