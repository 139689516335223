import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'You have no Items',
  description:
    'Create items for products or services your business sells. Once you create an Item, it will be listed here.',
  cardHeading: 'Build consistent, accurate Invoices with Items',
  point1: 'Include price, descriptions and more',
  point2: 'Categorise, edit and delete items',
  point3: 'Bulk upload a list of items',
})
