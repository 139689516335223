import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GenerateDebitCardPanTokenMutationVariables = Types.Exact<{
  debitCardId: Types.Scalars['ID']['input']
}>

export type GenerateDebitCardPanTokenMutationResponse = {
  __typename?: 'Mutation'
  generatePanToken: string
}

export const GenerateDebitCardPanToken = gql`
  mutation GenerateDebitCardPanToken($debitCardId: ID!) {
    generatePanToken(debitCardId: $debitCardId)
  }
` as unknown as TypedDocumentNode<
  GenerateDebitCardPanTokenMutationResponse,
  GenerateDebitCardPanTokenMutationVariables
>
export type GenerateDebitCardPanTokenMutationFn = Apollo.MutationFunction<
  GenerateDebitCardPanTokenMutationResponse,
  GenerateDebitCardPanTokenMutationVariables
>

/**
 * __useGenerateDebitCardPanTokenMutation__
 *
 * To run a mutation, you first call `useGenerateDebitCardPanTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateDebitCardPanTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateDebitCardPanTokenMutation, { data, loading, error }] = useGenerateDebitCardPanTokenMutation({
 *   variables: {
 *      debitCardId: // value for 'debitCardId'
 *   },
 * });
 */
export function useGenerateDebitCardPanTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateDebitCardPanTokenMutationResponse,
    GenerateDebitCardPanTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GenerateDebitCardPanTokenMutationResponse,
    GenerateDebitCardPanTokenMutationVariables
  >(GenerateDebitCardPanToken, options)
}
export type GenerateDebitCardPanTokenMutationHookResult = ReturnType<
  typeof useGenerateDebitCardPanTokenMutation
>
export type GenerateDebitCardPanTokenMutationResult =
  Apollo.MutationResult<GenerateDebitCardPanTokenMutationResponse>
export type GenerateDebitCardPanTokenMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateDebitCardPanTokenMutationResponse,
    GenerateDebitCardPanTokenMutationVariables
  >
