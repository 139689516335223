import {
  MutableRefObject,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { usePopper } from 'react-popper'
import { Options } from '@popperjs/core'

import { Wrapper } from './PopperAvatarEdit.styled'

interface Props {
  children: React.ReactNode
  renderPopper: (
    ref: MutableRefObject<null>,
    attr:
      | {
          [key: string]: string
        }
      | undefined
  ) => React.ReactNode
  placement?: Options['placement']
  strategy?: Options['strategy']
  isDisabled?: boolean
}

export const PopperAvatarEdit = ({
  children,
  renderPopper,
  placement = 'bottom',
  strategy = 'absolute',
  isDisabled = false,
}: Props) => {
  const [showPopper, setShowPopper] = useState<boolean>(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const buttonRef = useRef(null)
  const popperRef = useRef(null)

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false)
    }
  }, [isFirstLoad])

  useOnclickOutside(() => setShowPopper(false), { refs: [buttonRef] })

  const { attributes } = usePopper(buttonRef.current, popperRef.current, {
    placement,
    strategy,
  })

  return (
    <Wrapper
      ref={buttonRef}
      onClick={
        isDisabled
          ? undefined
          : (event: SyntheticEvent) => {
              event.stopPropagation()
              setShowPopper((prevShowPopper) => !prevShowPopper)
            }
      }
    >
      {children}
      {showPopper && renderPopper(popperRef, attributes.popper)}
    </Wrapper>
  )
}
