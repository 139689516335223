import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetDrawerItemsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input']
  filter: Types.InputMaybe<Types.GetItemFilterInput>
  nextToken: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type GetDrawerItemsQueryResponse = {
  __typename?: 'Query'
  getItems: {
    __typename?: 'ItemConnection'
    nextToken: string | null
    items: Array<{
      __typename: 'Item'
      id: string
      name: string
      sku: string | null
    }> | null
  }
}

export const GetDrawerItems = gql`
  query GetDrawerItems(
    $limit: Int!
    $filter: GetItemFilterInput
    $nextToken: String
  ) {
    getItems(limit: $limit, filter: $filter, nextToken: $nextToken) {
      items {
        __typename
        id
        name
        sku
      }
      nextToken
    }
  }
` as unknown as TypedDocumentNode<
  GetDrawerItemsQueryResponse,
  GetDrawerItemsQueryVariables
>

/**
 * __useGetDrawerItemsQuery__
 *
 * To run a query within a React component, call `useGetDrawerItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDrawerItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrawerItemsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetDrawerItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDrawerItemsQueryResponse,
    GetDrawerItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDrawerItemsQueryResponse,
    GetDrawerItemsQueryVariables
  >(GetDrawerItems, options)
}
export function useGetDrawerItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDrawerItemsQueryResponse,
    GetDrawerItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDrawerItemsQueryResponse,
    GetDrawerItemsQueryVariables
  >(GetDrawerItems, options)
}
export type GetDrawerItemsQueryHookResult = ReturnType<
  typeof useGetDrawerItemsQuery
>
export type GetDrawerItemsLazyQueryHookResult = ReturnType<
  typeof useGetDrawerItemsLazyQuery
>
export type GetDrawerItemsQueryResult = Apollo.QueryResult<
  GetDrawerItemsQueryResponse,
  GetDrawerItemsQueryVariables
>
