import { createTranslations } from '@npco/utils-translations'

export const referralTranslations = createTranslations({
  headline: 'Refer a friend to Zeller, earn $100',
  copy: 'Share your unique referral link with a friend. When they sign up and start transacting with Zeller, you’ll both receive $100.',
  secondCopy:
    'Share your unique referral link with as many businesses as possible — there is no limit to how much you can earn with the Zeller Referral Program.',
  referralLinkLabel: 'Copy my referral link',
  howItWorksTitle: 'How it works',
  howItWorksSubHeading1: 'Share your referral link',
  howItWorksDescription1:
    'Copy your unique referral link and share it with any friends who run a business.',
  howItWorksSubHeading2: 'They join Zeller',
  howItWorksDescription2:
    'Using your unique referral link, they sign up and transact over $500 with Zeller.',
  howItWorksSubHeading3: 'You’re both rewarded',
  howItWorksDescription3:
    'For every eligible referral, you’ll both receive $100 to your nominated Zeller settlement account.',
  referralLinkCopied: 'Referral link copied to your clipboard',
  termsAndConditions: 'Read the full terms and conditions on',
  zellerReferralProgramPage: 'Zeller Referral Program page.',
  somethingWentWrong:
    'We are unable to retrieve your referral link. Please contact our support team for assistance.',
})
