import { useLocation } from 'react-router-dom-v5-compat'
import { DebitCardAccountStatus } from '@npco/mp-gql-types'
import { ACCOUNT } from '@npco/mp-utils-accounts-routing'
import { useAccountsTransactionsMetadata } from 'features/AccountsTransactions'

import { useAccountId } from 'hooks/banking'
import { getIsOnPage } from 'utils/common'

import { useAccountLayoutQuery } from '../useAccountLayoutQuery/useAccountLayoutQuery'

export const useAccountLayoutDeps = {
  useAccountId,
  useAccountLayoutQuery,
  useAccountsTransactionsMetadata,
  useLocation,
}

export const useAccountLayout = () => {
  const {
    useAccountId,
    useAccountLayoutQuery,
    useAccountsTransactionsMetadata,
    useLocation,
  } = useAccountLayoutDeps

  const { accountId } = useAccountId()

  const { account, entity, isLoadingAccount } = useAccountLayoutQuery({
    accountId,
  })

  const {
    areFiltersInDefaultState: isFiltersInDefaultState,
    isActionsVisible,
    isFiltersDisabled,
    isFiltersVisible,
    setIsActionsVisible,
    setIsFiltersVisible,
  } = useAccountsTransactionsMetadata()

  const accountBalance = account?.balance
    ? parseInt(account.balance.value, 10)
    : undefined

  const accountName = account?.name
  const accountType = account?.type

  const isTransferDisabled = Boolean(
    account?.status === DebitCardAccountStatus.SUSPENDED ||
      account?.status === DebitCardAccountStatus.CLOSED
  )

  const { pathname } = useLocation()

  const isTransactionsRoute = getIsOnPage(
    pathname,
    ACCOUNT(accountId).TRANSACTIONS.path
  )

  return {
    accountBalance,
    accountId,
    accountName,
    accountType,
    entity,
    isActionsVisible,
    isFiltersDisabled,
    isFiltersInDefaultState,
    isFiltersVisible,
    isLoadingAccount,
    isTransactionsRoute,
    isTransferDisabled,
    setIsActionsVisible,
    setIsFiltersVisible,
  }
}
