import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { NEW_ITEM_VALUE } from '@npco/zeller-design-system'
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs'

import { useInputCombobox } from 'hooks/useInputCombobox'

import { DEFAULT_GET_LIMIT } from '../../../../../ItemManagement.utils'
import { translations } from '../CategoryDeleteModal.i18n'
import { useGetCategoriesForItemsReassignLazyQuery } from '../graphql/getCategoriesForItemsReassign.generated'

export const useCategoriesCombobox = (categoryId: string) => {
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false)
  const [validationError, setValidationError] = useState('')
  const t = useTranslations(translations)
  const [name$] = useState(new Subject<string>())

  const {
    handleChange,
    handleClose,
    handleInputChange,
    handleInputClear,
    inputValue,
    selectedItem,
    setSelectedItem,
  } = useInputCombobox({
    name$,
    onChange: (changes) => {
      if (changes.selectedItem?.value === NEW_ITEM_VALUE) {
        setShowCreateCategoryModal(true)
      }
    },
    validationErrorMessages: {
      Required: t('categoriesRequiredError'),
      SelectionRequired: t('categoriesSelectionRequiredError'),
    },
    onValidationError: setValidationError,
  })

  const [
    getItemCategories,
    { data: categories, loading: isLoading, fetchMore },
  ] = useGetCategoriesForItemsReassignLazyQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const nextToken = categories?.getItemCategories.nextToken

  useEffect(() => {
    getItemCategories({
      variables: {
        filter: { name: '' },
        nextToken: undefined,
        limit: DEFAULT_GET_LIMIT,
      },
    })

    const subscription = new Subscription()

    subscription.add(
      name$
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((nextName) =>
          getItemCategories({
            variables: {
              filter: { name: nextName },
              limit: DEFAULT_GET_LIMIT,
              nextToken: undefined,
            },
          })
        )
    )

    return () => subscription.unsubscribe()
    // NOTE: Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseCreateCategoryModal = useCallback(() => {
    setShowCreateCategoryModal(false)
  }, [])

  const handleCreateCategory = useCallback(
    (category: { id: string; name: string }) => {
      setSelectedItem({ label: category.name, value: category.id })
    },
    [setSelectedItem]
  )

  const handleLoadMore = useCallback(() => {
    fetchMore?.({
      variables: {
        filter: { name: inputValue },
        limit: DEFAULT_GET_LIMIT,
        nextToken,
      },
    })
  }, [fetchMore, inputValue, nextToken])

  const categoriesItems = useMemo(() => {
    return (
      categories?.getItemCategories.categories
        //  Note: Filter out category to be deleted
        ?.filter((category) => category.id !== categoryId)
        .map((category) => ({
          label: category.name,
          value: category.id,
        })) ?? []
    )
  }, [categories, categoryId])

  return {
    categoriesItems,
    handleCloseCreateCategoryModal,
    handleChange,
    handleClose,
    handleCreateCategory,
    handleInputClear,
    handleInputChange,
    handleLoadMore,
    hasMore: !!nextToken,
    inputValue,
    isLoading,
    selectedItem,
    setValidationError,
    showCreateCategoryModal,
    validationError,
  }
}
