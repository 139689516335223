import { gql } from '@apollo/client'

export const AddEntityTagMutation = gql`
  mutation AddEntityTag($tag: String!) {
    addEntityTag(tag: $tag)
  }
`

export const DeleteEntityTagMutation = gql`
  mutation DeleteEntityTag($tag: String!) {
    removeEntityTag(tag: $tag)
  }
`
