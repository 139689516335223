import { Dispatch, SetStateAction } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'
import { useIsSingleAccountCase } from 'pages/Transfer/hooks/useIsSingleAccountCase/useIsSingleAccountCase'
import { useTransferState } from 'pages/Transfer/Transfer.context'
import { TransferGeneralState } from 'pages/Transfer/Transfer.stateMachine'
import {
  TransferBpayFields,
  TransferFieldTypes,
} from 'pages/Transfer/Transfer.types'
import { StyledNextButton } from 'components/Buttons/ActionButtonsGroup/ActionButtonsGroup.styled'

import { TransferContactFields } from '../../ContactTransfer'
import { transferFieldsTranslations } from '../TransferFields.i18n'

interface TransferContinueButtonProps {
  setContactValidationError: Dispatch<SetStateAction<string | undefined>>
}
export const TransferContinueButton = ({
  setContactValidationError,
}: TransferContinueButtonProps) => {
  const t = useTranslations(transferFieldsTranslations)

  const isSingleAccountCase = useIsSingleAccountCase()
  const { values } = useFormikContext<TransferFieldTypes>()

  const {
    transferState: [state],
  } = useTransferState()

  const isContactTransfer = (
    formValues: TransferFieldTypes
  ): formValues is TransferContactFields => {
    return state.matches(TransferGeneralState.ContactTransfer)
  }

  const isBpayTransfer = (
    formValues: TransferFieldTypes
  ): formValues is TransferBpayFields => {
    return state.matches(TransferGeneralState.BpayTransfer)
  }

  const isNewBpayDetails = (formValues: TransferBpayFields) => {
    return formValues.to?.type === 'Biller'
  }

  const handleOnClick = () => {
    if (isContactTransfer(values) && !values.contact) {
      setContactValidationError(t('contactRequired'))
    }

    if (
      isBpayTransfer(values) &&
      isNewBpayDetails(values) &&
      !values.reference
    ) {
      setContactValidationError(t('contactRequired'))
    }
  }

  return (
    <StyledNextButton
      disabled={isSingleAccountCase}
      onClick={handleOnClick}
      type="submit"
    >
      {translate('shared.continue')}
    </StyledNextButton>
  )
}
