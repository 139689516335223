import { useCallback } from 'react'
import { GetItemFilterInput } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { ButtonFill, ButtonGhost, Flex } from '@npco/zeller-design-system'
import { MultiSelectItem } from 'design-system/Components/MultiSelect/MultiSelectItem/MultiSelectItem'

import { ListLoader } from 'components/Lists'
import * as styled from 'components/MobileFilters/MobileFilters.styled'
import { translationsShared } from 'translations'

import { useFilterCategories } from '../../../../hooks/useFilterCategories'

type NextFilterInput = NonNullable<GetItemFilterInput> | null

interface FilterByCategoriesProps {
  nextFilterInput: NextFilterInput
  setDefaultVisibilityState: () => void
  setNextFilterInput: (nextFilterInput: NextFilterInput) => void
}

export const FilterByCategories = ({
  nextFilterInput,
  setNextFilterInput,
  setDefaultVisibilityState,
}: FilterByCategoriesProps) => {
  const tShared = useTranslations(translationsShared)
  const {
    filterCategories,
    handleApply,
    isLoadingMore,
    nextLocalFilterInput,
    observerContainer,
    setNextLocalFilterInput,
  } = useFilterCategories<NextFilterInput>({
    nextFilterInput,
    setNextFilterInput,
    setDefaultVisibilityState,
  })

  const handleClear = useCallback(() => {
    setNextLocalFilterInput((previous) => ({
      ...previous,
      categoryUuidFilter: { categoryUuids: [] },
    }))
  }, [setNextLocalFilterInput])

  const handleItemClick = useCallback(
    ({ id, isChecked }) =>
      () => {
        const selectedCategories =
          nextLocalFilterInput?.categoryUuidFilter?.categoryUuids ?? []

        const nextCategoryUuids = isChecked
          ? selectedCategories.filter((uuid) => uuid !== id)
          : [...selectedCategories, id]

        setNextLocalFilterInput((previous) => ({
          ...previous,
          categoryUuidFilter: { categoryUuids: nextCategoryUuids },
        }))
      },
    [nextLocalFilterInput, setNextLocalFilterInput]
  )

  const renderFilterCategory = (category: { name: string; id: string }) => {
    const categoryUuids =
      nextLocalFilterInput?.categoryUuidFilter?.categoryUuids ?? []
    const isChecked = categoryUuids.some((id) => id === category.id)

    // NOTE: using multi select item until we have the multiselect
    // popper item in the design system
    return (
      <MultiSelectItem
        data-testid={category.id}
        id={category.id}
        isChecked={isChecked}
        item={{ label: category.name, value: category.id }}
        key={category.id}
        label={category.name}
        onClick={handleItemClick({ id: category.id, isChecked })}
        role="option"
      />
    )
  }

  return (
    <Flex
      data-testid="items-mobile-filters-categories"
      flexDirection="column"
      flexGrow={1}
    >
      <Flex flexDirection="column" flexGrow={1}>
        {filterCategories?.map(renderFilterCategory)}
        <div ref={observerContainer} />
        {isLoadingMore && <ListLoader />}
      </Flex>
      <styled.MobileFiltersButtonWrapper>
        <ButtonGhost
          dataTestId="items-mobile-filters-clear"
          onClick={handleClear}
        >
          {tShared('clear')}
        </ButtonGhost>
        <ButtonFill
          dataTestId="items-mobile-filters-apply"
          onClick={handleApply}
        >
          {tShared('apply')}
        </ButtonFill>
      </styled.MobileFiltersButtonWrapper>
    </Flex>
  )
}
