import { useEffect } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { FormikErrors } from 'formik'
import { isUndefined, omitBy } from 'lodash-es'

import { useMultiStageModalProgressBar } from 'components/MultiStageModal'

import { useItemsAnalytics } from '../../../../hooks/useItemsAnalytics'
import { useItemsImportModalStage } from '../../hooks/useItemsImportModalStage'
import { ItemsImport, ItemsImportData } from '../../ItemsImportModal.types'
import { translations } from '../ItemsImportModalMatch.i18n'
import { useGetItemImportFileUploadUrlQuery } from './graphql/getItemImportFileUploadUrl.generated'
import {
  getAttributeValidations,
  getRowsData,
} from './useItemsImportModalMatch.utils'

interface UseItemsImportModalMatchProps {
  items: ItemsImport | null
}

export const useItemsImportModalMatch = ({
  items,
}: UseItemsImportModalMatchProps) => {
  const { trackImportCompleted } = useItemsAnalytics()

  const t = useTranslations(translations)
  // NOTE: useLazyQuery doesn't provide a promise, hence,
  // used useQuery -> refetch to call this query on demand with promise;
  // Should be fixed in latest apollo client version
  const { refetch: getUploadUrl } = useGetItemImportFileUploadUrlQuery({
    skip: true,
  })
  const { goToItems, goToUploadStage } = useItemsImportModalStage()

  useMultiStageModalProgressBar({ newCurrentStep: 9.5 })

  useEffect(() => {
    if (!items) {
      goToUploadStage()
    }
  }, [goToUploadStage, items])

  const handleFormSubmit = async (values: ItemsImportData) => {
    try {
      const { data } = await getUploadUrl({
        fileName: items?.file?.id,
        fileMeta: {
          columnMapping: values,
        },
      })

      await fetch(data.getItemImportFileUploadUrl.uploadUrl, {
        method: 'PUT',
        body: items?.file.file,
      })

      trackImportCompleted(items)

      goToItems()
      showSuccessToast(t('uploadFileSuccess'))
    } catch (error) {
      showErrorToast(t('uploadFileError'))
    }
  }

  const validateForm =
    (headers: (string | null)[], rows: string[][]) =>
    (values: ItemsImportData) => {
      const errors: FormikErrors<ItemsImportData> = {}

      const rowsData = getRowsData({ headers, mappedHeaders: values, rows })

      const {
        validateCategory,
        validateDefaultGst,
        validateItemName,
        validatePrice,
        validateSku,
      } = getAttributeValidations({ t, rowsData, values })

      errors.category = validateCategory()
      errors.gst = validateDefaultGst()
      errors.name = validateItemName()
      errors.price = validatePrice()
      errors.sku = validateSku()

      return omitBy(errors, isUndefined)
    }

  return {
    handleFormSubmit,
    validateForm,
  }
}
