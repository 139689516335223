import { useTranslations } from '@npco/utils-translations'
import {
  AnchorBasic,
  BodyDefault,
  Box,
  ButtonLink,
  DownloadIcon,
  Flex,
  ModalBasic,
  Uploader,
} from '@npco/zeller-design-system'

import { FileList } from 'components/File'
import { MultiStageModal } from 'components/MultiStageModal'
import { translationsShared } from 'translations'

import { ItemsImport } from '../ItemsImportModal.types'
import { useItemsImportModalUpload } from './hooks/useItemsImportModalUpload'
import { translations } from './ItemsImportModalUpload.i18n'

const ALLOWED_FILE_TYPES = {
  'text/csv': ['.csv'],
  'text/xlsx': ['.xlsx'],
  'text/xls': ['.xls'],
}
const MAX_SIZE = 5 * 1024 * 1024

interface ItemsImportModalUploadProps {
  items: ItemsImport | null
  setItems: (data: ItemsImport | null) => void
}

export const ItemsImportModalUpload = ({
  items,
  setItems,
}: ItemsImportModalUploadProps) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const {
    file,
    handleCancel,
    handleNextStage,
    handleRemoveFile,
    handleTemplateDownload,
    handleUploadAccepted,
    handleUploadRejected,
    validationError,
  } = useItemsImportModalUpload({ items, setItems })

  return (
    <>
      <ModalBasic.Body>
        <Box mb="24px">
          <BodyDefault as="div">
            {t('description1', {
              link: (
                <AnchorBasic
                  target="_blank"
                  href="https://support.myzeller.com/how-do-i-import-items"
                >
                  {t('findOutMore')}
                </AnchorBasic>
              ),
            })}
          </BodyDefault>
        </Box>

        {file && <FileList files={[file]} removeFile={handleRemoveFile} />}

        <Uploader
          allowedFileTypes={ALLOWED_FILE_TYPES}
          description={t('uploadDescription')}
          error={validationError}
          linkText={t('uploaderSubLabel')}
          icon="document"
          maxSize={MAX_SIZE}
          onUploadAccepted={handleUploadAccepted}
          onUploadRejected={handleUploadRejected}
          text={t('uploadLabel')}
        />

        <Box mt="24px">
          <ButtonLink onClick={handleTemplateDownload}>
            <Flex gridGap="8px" alignItems="center">
              <DownloadIcon />
              {t('downloadTemplateButton')}
            </Flex>
          </ButtonLink>
        </Box>
      </ModalBasic.Body>
      <MultiStageModal.FooterButtons
        backButtonLabel={tShared('cancel')}
        onConfirm={handleNextStage}
        onCancel={handleCancel}
      />
    </>
  )
}
