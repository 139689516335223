import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { AccountDetailsViewValueDebitCardAccountV2FragmentDoc } from '../../AccountDetailsViewValue/AccountDetailsViewValue.generated'

const defaultOptions = {} as const
export type AccountDetailsViewQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type AccountDetailsViewQueryResponse = {
  __typename?: 'Query'
  account: {
    __typename: 'DebitCardAccountV2'
    id: string
    name: string
    type: Types.DebitCardAccountType
    status: Types.DebitCardAccountStatus
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
      animation: string | null
      image: string | null
      images: Array<{
        __typename?: 'Image'
        url: string
        size: Types.ImageSize
      }> | null
    } | null
    savingsAccountDetails: {
      __typename?: 'SavingsAccountDetails'
      bonusRateEndsAt: any | null
      tfnProvided: boolean | null
      baseInterestRate: string | null
      bonusInterestRate: string | null
    } | null
    savingsAccountProduct: {
      __typename?: 'SavingsAccountProduct'
      id: string | null
      interestThreshold: { __typename?: 'Money'; value: string } | null
    } | null
    accountDetails: {
      __typename?: 'BankAccountDetails'
      account: string | null
      bsb: string | null
      name: string | null
    } | null
  } | null
}

export const AccountDetailsView = gql`
  query AccountDetailsView($id: ID!) {
    account: getDebitCardAccountV2(debitCardAccountUuid: $id) {
      __typename
      id
      ...AccountDetailsViewValueDebitCardAccountV2Fragment
    }
  }
  ${AccountDetailsViewValueDebitCardAccountV2FragmentDoc}
` as unknown as TypedDocumentNode<
  AccountDetailsViewQueryResponse,
  AccountDetailsViewQueryVariables
>

/**
 * __useAccountDetailsViewQuery__
 *
 * To run a query within a React component, call `useAccountDetailsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDetailsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDetailsViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountDetailsViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountDetailsViewQueryResponse,
    AccountDetailsViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AccountDetailsViewQueryResponse,
    AccountDetailsViewQueryVariables
  >(AccountDetailsView, options)
}
export function useAccountDetailsViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountDetailsViewQueryResponse,
    AccountDetailsViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AccountDetailsViewQueryResponse,
    AccountDetailsViewQueryVariables
  >(AccountDetailsView, options)
}
export type AccountDetailsViewQueryHookResult = ReturnType<
  typeof useAccountDetailsViewQuery
>
export type AccountDetailsViewLazyQueryHookResult = ReturnType<
  typeof useAccountDetailsViewLazyQuery
>
export type AccountDetailsViewQueryResult = Apollo.QueryResult<
  AccountDetailsViewQueryResponse,
  AccountDetailsViewQueryVariables
>
