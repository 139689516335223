import { useMemo } from 'react'
import { WatchQueryFetchPolicy } from '@apollo/client'

import { useGetEntityForAccountsLayoutQuery } from '../graphql/getEntityForAccountsLayout.generated'

interface UseGetEntityForAccountsLayoutProps {
  fetchPolicy?: WatchQueryFetchPolicy
}

export const useGetEntityForAccountsLayout = ({
  fetchPolicy = 'cache-first',
}: UseGetEntityForAccountsLayoutProps = {}) => {
  const {
    loading: isLoadingEntity,
    error,
    data,
  } = useGetEntityForAccountsLayoutQuery({
    fetchPolicy,
  })

  const entity = useMemo(() => {
    return data?.getEntity
  }, [data?.getEntity])

  return {
    isLoadingEntity,
    error,
    entity,
  }
}
