import { useQuery } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetBillingAccount } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/sim'
import { rvSimBillingAccount } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/simBillingAccount'

import { GetBillingAccount as GetBillingAccountResponse } from 'types/gql-types/GetBillingAccount'

export const useSimBillingAccount = () => {
  const hasCachedSimBillingAccount = !!rvSimBillingAccount()

  const { data, loading } = useQuery<GetBillingAccountResponse, never>(
    GetBillingAccount,
    {
      fetchPolicy: 'cache-first',
      skip: hasCachedSimBillingAccount,
      onError: (error) => {
        showApiErrorToast(error)
      },
    }
  )

  return {
    isLoadingSimBillingAccount: loading,
    simBillingAccount: data?.getBillingAccount,
  }
}
