import { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { GetContactImageUploadForm } from 'features/Contacts/contacts'

import { uploadImageToS3Bucket } from 'utils/uploadImageToS3Bucket'
import {
  GetContactImageUploadForm as GetContactImageUploadFormPayload,
  GetContactImageUploadFormVariables,
} from 'types/gql-types/GetContactImageUploadForm'
import { page } from 'translations'

export interface UseS3BucketForProfilePictureProps {
  imageUrl: string
  onImageUploaded: (url: string) => void
  closeModal: () => void
}

export const useS3BucketForProfilePicture = ({
  imageUrl,
  onImageUploaded,
  closeModal,
}: UseS3BucketForProfilePictureProps) => {
  const [isUploadingFileToS3, setIsUploadingFileToS3] = useState(false)

  const [upload] = useLazyQuery<
    GetContactImageUploadFormPayload,
    GetContactImageUploadFormVariables
  >(GetContactImageUploadForm, {
    fetchPolicy: 'network-only',
    onCompleted: (responseData: GetContactImageUploadFormPayload) => {
      uploadImageToS3Bucket({
        formFields: JSON.parse(
          responseData.getContactImageUploadForm.formFields
        ),
        imageUrl,
        onSuccess: () => {
          setIsUploadingFileToS3(false)
          showSuccessToast(page.contact.sections.imageUpload.successCopy)
          onImageUploaded(imageUrl)
          closeModal()
        },
        onError: () => {
          setIsUploadingFileToS3(false)
          showErrorToast(page.contact.sections.imageUpload.failureCopy)
          closeModal()
        },
        s3BucketUrl: responseData.getContactImageUploadForm.url,
      })
    },
    onError: () => {
      showErrorToast(page.contact.sections.imageUpload.failureCopy)
      setIsUploadingFileToS3(false)
      closeModal()
    },
  })

  const uploadFileToS3Bucket = (contactUuid: string) => {
    setIsUploadingFileToS3(true)
    upload({
      variables: {
        contactUuid,
      },
    })
  }

  return {
    isUploadingFileToS3,
    uploadFileToS3Bucket,
  }
}
