import { useMutation } from '@apollo/client'
import { CreateInvoiceInput } from '@npco/mp-gql-types'

import {
  CreateInvoice as CreateInvoiceResponse,
  CreateInvoiceVariables,
} from 'types/gql-types/CreateInvoice'

import { CreateInvoice } from '../graphql/createInvoice'

export const useCreateInvoice = () => {
  const [
    createInvoice,
    { data, loading: isCreatingInvoice, reset: resetCreatedInvoice },
  ] = useMutation<CreateInvoiceResponse, CreateInvoiceVariables>(CreateInvoice)

  return {
    createInvoice: (invoice: CreateInvoiceInput) =>
      createInvoice({
        variables: {
          invoice,
        },
      }),
    data: data?.createInvoice,
    isCreatingInvoice,
    resetCreatedInvoice,
  }
}
