import { ApolloCache } from '@apollo/client'

import {
  GetSetupFlowDebitCards,
  GetSetupFlowDebitCardsQueryResponse,
} from '../useSetupFlowCardsQuery/graphql/getCards.generated'
import { NewDebitCardType } from './graphql/requestNewCard.types'

interface CacheNewCardProps {
  cache: ApolloCache<any>
  newCard: NewDebitCardType
}
export const cacheNewCard = ({ cache, newCard }: CacheNewCardProps) => {
  const cachedCards =
    cache.readQuery<GetSetupFlowDebitCardsQueryResponse>({
      query: GetSetupFlowDebitCards,
    })?.getDebitCardsV2.cards || []

  cache.writeQuery<GetSetupFlowDebitCardsQueryResponse>({
    query: GetSetupFlowDebitCards,
    data: {
      getDebitCardsV2: {
        cards: [newCard, ...cachedCards],
      },
    },
  })
}
