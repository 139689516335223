import { AnchorBasic, Flex } from '@npco/zeller-design-system'
import { Box } from 'reflexbox'

import { useGetDocumentTypeOptionsByEntity } from 'hooks/documentType/useGetDocumentTypeOptionsByEntity'
import { translate } from 'utils/translations'
import { useFilesQueue } from 'components/File/hooks/useFileQueue'
import { useGetUploadUrls } from 'components/UploadDocuments/hooks/useGetUploadUrls'
import { useUploadDocument } from 'components/UploadDocuments/hooks/useUploadDocument'
import { UploadDocumentLoader } from 'components/UploadDocuments/Loader/Loader'
import { UploadDocuments } from 'components/UploadDocuments/UploadDocuments'

import { StyledDescription, StyledTitle } from './ProfileDocuments.styled'

export const ProfileDocuments = () => {
  const { documentTypeItemOptions } = useGetDocumentTypeOptionsByEntity()
  const {
    addFilesToQueue,
    addSuccededFileQueueItem,
    fileQueueItems,
    removeFileFromQueue,
    resetFileQueueItems,
    resetSuccededFileQueueItems,
    succeededFileQueueItems,
  } = useFilesQueue()

  const {
    failedFileNames,
    isLoading,
    persitedSubject,
    setFailedFailedNames,
    setPersistedSubject,
    setIsLoading,
  } = useUploadDocument({
    fileQueueItems,
    resetFileQueueItems,
    resetSuccededFileQueueItems,
    succeededFileQueueItems,
  })

  const { onSubmit } = useGetUploadUrls({
    addSuccededFileQueueItem,
    fileQueueItems,
    setFailedFailedNames,
    setIsLoading,
    setPersistedSubject,
    succeededFileQueueItems,
  })

  if (isLoading) {
    return (
      <Flex height="100%" justifyContent="center">
        <UploadDocumentLoader
          currentFileIndex={succeededFileQueueItems.length + 1}
          totalFilesAmount={fileQueueItems.length}
        />
      </Flex>
    )
  }

  return (
    <>
      <Box>
        <StyledTitle>
          {translate('page.settings.profileSettings.documentsTitle')}
        </StyledTitle>
        <StyledDescription>
          {translate('page.settings.profileSettings.documentsDescription', {
            instructionLink: (
              <AnchorBasic
                href={translate('form.shared.uploadDocuments.instructionLink')}
              >
                {translate('form.shared.uploadDocuments.instructionLinkLabel')}
              </AnchorBasic>
            ),
          })}
        </StyledDescription>
      </Box>
      <UploadDocuments
        succeededFileQueueItems={succeededFileQueueItems}
        addFilesToQueue={addFilesToQueue}
        removeFileFromQueue={removeFileFromQueue}
        failedFileNames={failedFileNames}
        onSubmit={onSubmit}
        fileQueueItems={fileQueueItems}
        persitedSubject={persitedSubject}
        optionItems={documentTypeItemOptions}
      />
    </>
  )
}
