import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetEntityForCardListLayoutQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type GetEntityForCardListLayoutQueryResponse = {
  __typename?: 'Query'
  getEntity: {
    __typename?: 'Entity'
    id: string
    canCreateCard: boolean | null
  }
}

export const GetEntityForCardListLayout = gql`
  query GetEntityForCardListLayout {
    getEntity {
      id
      canCreateCard
    }
  }
` as unknown as TypedDocumentNode<
  GetEntityForCardListLayoutQueryResponse,
  GetEntityForCardListLayoutQueryVariables
>

/**
 * __useGetEntityForCardListLayoutQuery__
 *
 * To run a query within a React component, call `useGetEntityForCardListLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityForCardListLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityForCardListLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEntityForCardListLayoutQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEntityForCardListLayoutQueryResponse,
    GetEntityForCardListLayoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetEntityForCardListLayoutQueryResponse,
    GetEntityForCardListLayoutQueryVariables
  >(GetEntityForCardListLayout, options)
}
export function useGetEntityForCardListLayoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEntityForCardListLayoutQueryResponse,
    GetEntityForCardListLayoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEntityForCardListLayoutQueryResponse,
    GetEntityForCardListLayoutQueryVariables
  >(GetEntityForCardListLayout, options)
}
export type GetEntityForCardListLayoutQueryHookResult = ReturnType<
  typeof useGetEntityForCardListLayoutQuery
>
export type GetEntityForCardListLayoutLazyQueryHookResult = ReturnType<
  typeof useGetEntityForCardListLayoutLazyQuery
>
export type GetEntityForCardListLayoutQueryResult = Apollo.QueryResult<
  GetEntityForCardListLayoutQueryResponse,
  GetEntityForCardListLayoutQueryVariables
>
