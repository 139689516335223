import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { TabItemProps } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'

import { translations } from '../Contacts.i18n'

const TabValues = {
  BUSINESSES: ROOT.PORTAL.CONTACTS.BUSINESSES.relative,
  PEOPLE: ROOT.PORTAL.CONTACTS.PEOPLE.relative,
}

export const useContactsLayout = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const t = useTranslations(translations)

  const tabs: TabItemProps[] = useMemo(
    () => [
      {
        id: 'contacts-tabs-businesses',
        children: t('businesses'),
        value: TabValues.BUSINESSES,
        onClick: () => navigate(TabValues.BUSINESSES),
      },
      {
        id: 'contacts-tabs-people',
        children: t('people'),
        value: TabValues.PEOPLE,
        onClick: () => navigate(TabValues.PEOPLE),
      },
    ],
    [navigate, t]
  )

  return {
    tabs,
    currentTab: (value: string) => pathname.includes(value),
  }
}
