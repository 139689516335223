import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { GetItemsTableCoreFieldsDoc } from './getItemsTableCoreFields.generated'

const defaultOptions = {} as const
export type GetItemsTableSubscriptionVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type GetItemsTableSubscriptionResponse = {
  __typename?: 'Subscription'
  onItemUpdate: {
    __typename?: 'ItemBatchUpdate'
    items: Array<{
      __typename: 'Item'
      id: string
      referenceNumber: string | null
      name: string
      sku: string | null
      price: number
      status: Types.ItemStatus | null
      categories: Array<{
        __typename?: 'ItemCategory'
        color: string
        id: string
        name: string
      }> | null
      taxes: Array<{
        __typename?: 'ItemTax'
        enabled: boolean
        name: string
        percent: number | null
      }> | null
    }> | null
  } | null
}

export const GetItemsTableSubscription = gql`
  subscription GetItemsTableSubscription($entityUuid: ID!) {
    onItemUpdate(entityUuid: $entityUuid) {
      items {
        ...GetItemsTableCoreFields
      }
    }
  }
  ${GetItemsTableCoreFieldsDoc}
` as unknown as TypedDocumentNode<
  GetItemsTableSubscriptionResponse,
  GetItemsTableSubscriptionVariables
>

/**
 * __useGetItemsTableSubscription__
 *
 * To run a query within a React component, call `useGetItemsTableSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsTableSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsTableSubscription({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetItemsTableSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetItemsTableSubscriptionResponse,
    GetItemsTableSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    GetItemsTableSubscriptionResponse,
    GetItemsTableSubscriptionVariables
  >(GetItemsTableSubscription, options)
}
export type GetItemsTableSubscriptionHookResult = ReturnType<
  typeof useGetItemsTableSubscription
>
export type GetItemsTableSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<GetItemsTableSubscriptionResponse>
