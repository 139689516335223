import { useCallback } from 'react'
import { GetCategoriesSortColumnName } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Flex, TableHeaderCellIcon } from '@npco/zeller-design-system'
import { HeaderContext } from '@tanstack/react-table'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { SortAmountList, SortIndicator } from 'components/Sort'

import { useItemManagementContext } from '../../ItemManagementContext/ItemManagementContext'
import { translations } from '../CategoriesTable.i18n'
import { CategoryTableData } from '../CategoriesTable.types'

export const CategoriesHeaderRowItemsCell = ({
  header,
}: HeaderContext<CategoryTableData, number>) => {
  const isMobile = useIsMobileResolution()

  const t = useTranslations(translations)

  const {
    categories: { sortInput, setSortInput },
  } = useItemManagementContext()

  const handleClick = useCallback(
    (setIsOpen: (state: boolean) => void) => (ascending: boolean) => {
      setIsOpen(false)

      if (
        sortInput?.ascending === ascending &&
        sortInput?.columnName === GetCategoriesSortColumnName.ItemsNumber
      ) {
        setSortInput(null)
      } else {
        setSortInput({
          ascending,
          columnName: GetCategoriesSortColumnName.ItemsNumber,
        })
      }
    },
    [sortInput, setSortInput]
  )

  if (isMobile) {
    return null
  }

  const isApplied =
    sortInput?.columnName === GetCategoriesSortColumnName.ItemsNumber
  const isAscending = sortInput?.ascending === true

  return (
    <TableHeaderCellIcon
      dataTestId={header.id}
      forwardedProps={{ style: { padding: 0 } }}
      icon={() => <SortIndicator $ascending={isAscending} />}
      isFiltering={isApplied}
      isSorting={isApplied}
      justifyContent="flex-end"
      key={header.id}
      label={t('items')}
      popperWidth="208px"
    >
      {({ setIsOpen }) => (
        <Flex
          data-testid="header-row-items-popper"
          flexDirection="column"
          padding="8px"
        >
          <SortAmountList
            onClick={handleClick(setIsOpen)}
            sort={isApplied ? isAscending : null}
          />
        </Flex>
      )}
    </TableHeaderCellIcon>
  )
}
