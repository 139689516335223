import { useCallback, useState } from 'react'
import { gql } from '@apollo/client'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { Box, COLOR } from '@npco/zeller-design-system'

import { KEY_CODE } from 'const/keyboard'

import { getIsCardRestricted, getTextColour } from '../CardDisplay.utils'
import { ViewDetailsDebitCardV2Fragment } from './ViewDetails.generated'
import { MaskedPanText, ViewDetailsButton } from './ViewDetails.styled'
import { getBackgroundColor, getWidth } from './ViewDetails.utils'
import { ViewDetailsContent } from './ViewDetailsContent'

interface ViewDetailsProps {
  top?: string
  left?: string
  onClick: () => void
  areDetailsDisplayed: boolean
  isLoading: boolean
  card: ViewDetailsDebitCardV2Fragment
}

export const ViewDetails = ({
  top,
  left,
  onClick,
  areDetailsDisplayed,
  isLoading,
  card,
}: ViewDetailsProps) => {
  const { userData } = useLoggedInUser()
  const [isHoveredOrFocused, setIsHoveredOrFocused] = useState(false)

  const isCardOwner = userData?.id === card.customer?.id
  const canRevealDetails = isCardOwner && !getIsCardRestricted(card.status)

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.key === KEY_CODE.ENTER) {
        onClick()
      }
    },
    [onClick]
  )

  if (!canRevealDetails) {
    return (
      <Box
        position="absolute"
        top={top}
        left={left}
        color={getTextColour({
          cardColour: card.colour,
          isCardRevealed: false,
        })}
      >
        <Box>
          <MaskedPanText>••• {card.maskedPan}</MaskedPanText>
        </Box>
      </Box>
    )
  }

  return (
    <ViewDetailsButton
      width={getWidth(isLoading, areDetailsDisplayed)}
      position="absolute"
      top={top}
      left={left}
      backgroundColor={getBackgroundColor(
        card.colour,
        isHoveredOrFocused,
        areDetailsDisplayed && !isLoading
      )}
      color={COLOR.WHITE}
      borderRadius="1000px"
      p="4px 16px 4px 16px"
      height="32px"
      justifyContent="space-between"
      onMouseEnter={() => setIsHoveredOrFocused(true)}
      onMouseLeave={() => setIsHoveredOrFocused(false)}
      onFocus={() => setIsHoveredOrFocused(true)}
      onBlur={() => setIsHoveredOrFocused(false)}
      role="button"
      tabIndex={0}
      onClick={isLoading ? undefined : onClick}
      onKeyDown={isLoading ? undefined : onKeyDown}
      isLoading={isLoading}
    >
      <ViewDetailsContent
        isLoading={isLoading}
        maskedPan={card.maskedPan}
        areDetailsDisplayed={areDetailsDisplayed}
        isHoveredOrFocused={isHoveredOrFocused}
      />
    </ViewDetailsButton>
  )
}

ViewDetails.fragments = {
  DebitCardV2: gql`
    fragment ViewDetailsDebitCardV2Fragment on DebitCardV2 {
      maskedPan
      colour
      status
      customer {
        id
      }
    }
  `,
}
