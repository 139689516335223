import { ApolloError } from '@apollo/client'
import currency from 'currency.js'
import { prop, sortBy } from 'ramda'

import { ErrorType } from 'types/errors'

import { ItemDetailsFragment } from './components/ItemsTable/ItemDrawer/ItemDrawerDetails/ItemDrawerDetails.generated'
import { ItemServerError } from './ItemManagement.types'

export const DEFAULT_GET_LIMIT = 50
export const MAX_CATEGORIES_TO_DISPLAY = 3
export const TAX_EXCLUSIVE_RATE = 1
export const TAX_INCLUSIVE_RATE = 1.1

const sortByName = sortBy(prop('name'))

export const getItemCategoryNames = (
  categories: ItemDetailsFragment['categories'],
  showAll = false
) => {
  if (!categories?.length) {
    return ''
  }

  const sortedCategories = sortByName(categories)

  if (sortedCategories.length <= MAX_CATEGORIES_TO_DISPLAY || showAll) {
    return sortedCategories.map(({ name }) => name).join(', ')
  }

  const [category1, category2, category3, ...otherCategories] = sortedCategories

  return `${category1.name}, ${category2.name}, ${category3.name}... +${otherCategories.length}`
}

export const getItemCategoryColors = (
  categories: ItemDetailsFragment['categories']
) =>
  (categories ?? [])
    ?.map((value) => value.color || null)
    .filter((x): x is string => x !== null) || []

export const getTaxRate = ({
  isTaxApplicable,
  isTaxInclusive,
}: {
  isTaxApplicable: boolean
  isTaxInclusive: boolean
}) => {
  return isTaxApplicable && isTaxInclusive
    ? TAX_INCLUSIVE_RATE
    : TAX_EXCLUSIVE_RATE
}

export const getPriceWithTax = ({
  isTaxInclusive,
  price,
  taxes,
}: {
  isTaxInclusive: boolean
  price: number
  taxes: ItemDetailsFragment['taxes'] | undefined
}) => {
  const isTaxApplicable = taxes?.some((tax) => tax.enabled) ?? false

  const taxRate = getTaxRate({
    isTaxApplicable,
    isTaxInclusive,
  })

  // NOTE: item total has centi cents precision so wrap in new currency object
  // to get nearest cent precision (formatted/displayed total)
  return currency(
    currency(price, { fromCents: true, precision: 4 }).multiply(taxRate)
  )
}

export const getDuplicateSkuErrorItemName = (error: ApolloError) => {
  const {
    graphQLErrors: [graphqlError],
  } = error
  const serverError = graphqlError as ItemServerError

  if (serverError?.errorType === ErrorType.DUPLICATE_ITEM_SKU) {
    return serverError.errorInfo?.itemName ?? ''
  }

  return ''
}
