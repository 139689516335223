import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  PillList,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'

import { useAddEntityTag } from 'hooks/entityTags/useAddEntityTag'
import { useDeleteEntityTag } from 'hooks/entityTags/useDeleteEntityTag'
import { useEntityTags } from 'hooks/entityTags/useEntityTags'
import { hasArrayContentChanged } from 'utils/arrayHelper'
import { translate } from 'utils/translations'
import { EditTags, validateAddNewTag } from 'components/EditTags'
import { MAXIMUM_TAGS_PER_TRANSACTION } from 'components/EditTags/EditTags.utils'

import { useTagContact } from '../../../hooks/useTagContact/useTagContact'
import {
  updateRvContactsOnContactUpdate,
  updateRvSelectedContactOnContactUpdate,
} from '../../../rv-deprecated/contacts.utils'
import { NoDataAddedContent } from '../../ContactGeneral/ContactGeneral.styled'
import { ContactSection } from '../ContactSection'

export const TAGS_DATA_TESTID = 'contact-tags-section'

interface ActionElementProps {
  contactId: string
  currentTags: string[]
  setCurrentTags: Dispatch<SetStateAction<string[]>>
}

const ActionElement = ({
  contactId,
  currentTags,
  setCurrentTags,
}: ActionElementProps) => {
  const { entityTags } = useEntityTags()

  const { addEntityTag, isAddingEntityTag } = useAddEntityTag()

  const { deleteEntityTag, isDeletingEntityTag } = useDeleteEntityTag()

  const { tagContact, isSavingContactTags } = useTagContact()

  const tagOptions = useMemo(
    () => entityTags?.map((tag) => ({ label: tag, value: tag })) || [],
    [entityTags]
  )

  const handleAddNewTag = useCallback(
    async (tag: string) => {
      try {
        const response = await addEntityTag(tag)

        return Boolean(response.data?.addEntityTag)
      } catch (error) {
        return false
      }
    },
    [addEntityTag]
  )

  const handleSaveContactTags = useCallback(
    async (tags: string[]) => {
      if (!hasArrayContentChanged(currentTags, tags)) {
        return true
      }

      try {
        const response = await tagContact(contactId, tags)

        if (!response.data?.tagContact) {
          showErrorToast(translate('component.tags.tagEditFailMessage'))
          return false
        }

        showSuccessToast(translate('component.tags.tagEditSuccessMessage'))

        setCurrentTags(tags)

        updateRvContactsOnContactUpdate(contactId, { tags })
        updateRvSelectedContactOnContactUpdate({ tags })
        return true
      } catch (error) {
        showErrorToast(translate('component.tags.tagEditFailMessage'))
        return false
      }
    },
    [contactId, setCurrentTags, tagContact, currentTags]
  )

  const handleDeleteTag = async (tag: string) => {
    try {
      const response = await deleteEntityTag(tag)

      if (!response.data?.removeEntityTag) {
        showErrorToast(translate('component.tags.tagDeleteFailMessage'))
        return false
      }

      setCurrentTags((prevTags) => {
        const newTags = prevTags.filter((prevTag) => prevTag !== tag)

        updateRvContactsOnContactUpdate(contactId, { tags: newTags })
        updateRvSelectedContactOnContactUpdate({ tags: newTags })

        return newTags
      })

      showSuccessToast(translate('component.tags.tagDeleteSuccessMessage'))

      return true
    } catch (error) {
      showErrorToast(translate('component.tags.tagDeleteFailMessage'))
      return false
    }
  }

  return (
    <EditTags
      buttonDataTestId="contact-edit-tags"
      buttonLabel={translate('shared.edit')}
      initialTags={currentTags}
      inputLabel={translate('component.tags.label')}
      isAddingEntityTag={isAddingEntityTag}
      isDeletingEntityTag={isDeletingEntityTag}
      isSavingTags={isSavingContactTags}
      itemsInSearch={tagOptions}
      onAddNewTag={handleAddNewTag}
      onDeleteEntityTag={handleDeleteTag}
      onSaveEdit={handleSaveContactTags}
      placeholder={translate('component.tags.placeholder')}
      title={translate('component.tags.editTags')}
      validate={validateAddNewTag(MAXIMUM_TAGS_PER_TRANSACTION)}
    />
  )
}

interface TagsProps {
  contactTags: (string | null)[] | null
  contactId: string
}

export const Tags = ({ contactTags, contactId }: TagsProps) => {
  const [currentTags, setCurrentTags] = useState<string[]>(
    (contactTags?.filter(Boolean) || []) as string[]
  )

  useEffect(() => {
    setCurrentTags((contactTags?.filter(Boolean) || []) as string[])
  }, [contactTags])

  return (
    <ContactSection
      heading={translate('component.tags.title')}
      actionElement={
        <ActionElement
          contactId={contactId}
          currentTags={currentTags}
          setCurrentTags={setCurrentTags}
        />
      }
      dataTestId={TAGS_DATA_TESTID}
    >
      {currentTags.length === 0 ? (
        <NoDataAddedContent>
          {translate('component.tags.noData')}
        </NoDataAddedContent>
      ) : (
        <PillList
          maxWidth="100%"
          pills={currentTags}
          editButtonText={translate('shared.edit')}
          closeIconAlt={translate('shared.close')}
        />
      )}
    </ContactSection>
  )
}
