import { useCallback, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  BodyDefault,
  Box,
  DecisionModal,
  showApiErrorToast,
  showSuccessToast,
  useModalState,
} from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { rvSelectedContact } from 'features/Contacts/rv-deprecated/contacts'
import { updateRvContactsOnContactUpdate } from 'features/Contacts/rv-deprecated/contacts.utils'

import { UNEXPECTED_ERROR } from 'types/errors'

import {
  ContactDetailSection,
  NoDataAddedContent,
} from '../../ContactGeneral/ContactGeneral.styled'
import { ContactSection } from '../ContactSection'
import { bpayListTranslations } from './Bpay.i18n'
import { BpayPaymentInstrument } from './Bpay.types'
import { getPaymentInstrumentType } from './BpayList.utils'
import { BPayListItem } from './BpayListItem'
import { EditBPayModalDetails } from './EditBPayModalDetails/EditBPayModalDetails.form'
import { useUnlinkBpayPaymentInstrument } from './hooks/useUnlinkBpayPaymentInstrument'

interface BPayListProps {
  bpayPaymentInstruments: BpayPaymentInstrument[]
  contactId: string
  contactBusinessName: string | null
}

export const BPayList = ({
  bpayPaymentInstruments,
  contactBusinessName,
  contactId,
}: BPayListProps) => {
  const t = useTranslations(bpayListTranslations)
  const [bpayEdit, setBpayEdit] = useState<BpayPaymentInstrument | undefined>()
  const { handleUnlinkBpayPaymentInstrument, loading } =
    useUnlinkBpayPaymentInstrument()
  const heading = t('label')

  const {
    isModalOpen: isModalOpenBPay,
    openModal: openModalBPay,
    closeModal: closeModalBPay,
  } = useModalState()

  const {
    isModalOpen: isModalOpenUnlink,
    openModal: openModalUnlink,
    closeModal: closeModalUnlink,
  } = useModalState()

  const handleItemClick = useCallback(
    (id: string) => {
      openModalBPay()
      const selectedItem = bpayPaymentInstruments?.find(
        (
          acc: NonNullable<ContactCoreFieldsFragment['paymentInstruments']>[0]
        ) => acc.id === id
      )
      setBpayEdit(selectedItem)
    },
    [bpayPaymentInstruments, openModalBPay, setBpayEdit]
  )

  return (
    <ContactSection heading={heading}>
      <>
        {bpayPaymentInstruments.length > 0 && (
          <ContactDetailSection>
            {bpayPaymentInstruments.map((bpayItem) => (
              <BPayListItem
                key={bpayItem.id}
                accountId={bpayItem.id}
                bpayDetails={bpayItem.details}
                onItemClick={handleItemClick}
              />
            ))}
          </ContactDetailSection>
        )}
        {bpayPaymentInstruments.length === 0 && (
          <NoDataAddedContent>{t('noData')}</NoDataAddedContent>
        )}
        {bpayEdit && (
          <EditBPayModalDetails
            isOpen={isModalOpenBPay}
            closeModal={closeModalBPay}
            paymentInstrument={bpayEdit}
            handleUnLink={() => {
              closeModalBPay()
              openModalUnlink()
            }}
          />
        )}

        {bpayEdit && (
          <DecisionModal
            isOpen={isModalOpenUnlink && !!bpayEdit}
            title={t('confirmModalTitle', {
              contactName: `${contactBusinessName}`,
            })}
            onCancel={() => {
              closeModalUnlink()
              openModalBPay()
            }}
            onClickPrimary={async () => {
              const response = await handleUnlinkBpayPaymentInstrument({
                id: bpayEdit.id,
                type: getPaymentInstrumentType(bpayEdit),
                contactUuid: contactId,
              })

              if (response === UNEXPECTED_ERROR) {
                showApiErrorToast()
                return
              }

              const contact = rvSelectedContact() as ContactCoreFieldsFragment

              const newPaymentInstruments =
                contact.paymentInstruments?.filter(
                  (paymentInstrument) => paymentInstrument.id !== bpayEdit?.id
                ) ?? []

              rvSelectedContact({
                ...contact,
                paymentInstruments: newPaymentInstruments,
              })

              updateRvContactsOnContactUpdate(contact.id, {
                paymentInstruments: newPaymentInstruments,
              })

              showSuccessToast(t('successUnlinkLabel'))
              closeModalUnlink()
            }}
            primaryButtonLabel={t('confirmModalPrimary')}
            secondaryButtonLabel={t('cancelLabel')}
            isLoadingPrimary={loading}
            onClickSecondary={() => {
              closeModalUnlink()
              openModalBPay()
            }}
          >
            <Box>
              <BodyDefault>{t('confirmModalDescription')}</BodyDefault>
            </Box>
          </DecisionModal>
        )}
      </>
    </ContactSection>
  )
}
