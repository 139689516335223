import { Box } from '@npco/zeller-design-system'

import { ReactComponent as ItemManagementSvg } from 'assets/svg/item-management.svg'

export const ItemIllustration = () => {
  return (
    <Box width={160} height={160}>
      <ItemManagementSvg />
    </Box>
  )
}
