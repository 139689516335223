import { gql } from '@apollo/client'

export const PinSelectInitiateDebitCard = gql`
  mutation PinSelectInitiateDebitCard(
    $input: PinSelectInitiateDebitCardInput!
  ) {
    pinSelectInitiateDebitCard(input: $input) {
      result
      sessionId
      publicKeyCertificate
    }
  }
`

export const PinSelectSetupDebitCard = gql`
  mutation PinSelectSetupDebitCard($input: PinSelectDebitCardInput!) {
    pinSelectSetupDebitCard(input: $input)
  }
`

export const PinSelectExecuteDebitCard = gql`
  mutation PinSelectExecuteDebitCard($input: PinSelectDebitCardInput!) {
    pinSelectExecuteDebitCard(input: $input)
  }
`

export const LockDebitCard = gql`
  mutation LockDebitCard($cardId: DebitCardIdInput!) {
    lockDebitCard(cardId: $cardId)
  }
`

export const UnlockDebitCard = gql`
  mutation UnlockDebitCard($cardId: DebitCardIdInput!) {
    unlockDebitCard(cardId: $cardId)
  }
`

export const CancelDebitCard = gql`
  mutation CancelDebitCard($cardId: DebitCardIdInput!) {
    cancelDebitCard(cardId: $cardId)
  }
`

export const UpdateCardNameMutation = gql`
  mutation UpdateCardName($input: UpdateDebitCardInput!) {
    updateDebitCard(input: $input)
  }
`

export const ReportLostCardMutation = gql`
  mutation ReportLostDebitCard($input: ReportLostDebitCardInput!) {
    reportLostDebitCard(input: $input) {
      reference
      result
    }
  }
`
