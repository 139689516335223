import { useMemo } from 'react'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'

import { useIsInvoicingEnabled } from 'hooks/invoices/useIsInvoicingEnabled'
import { ROOT } from 'const/routes'
import { page } from 'translations'

import { SubItem } from '../Sidebar.types'

export const usePaymentsSubItems = (): SubItem[] => {
  const isInvoicingEnabled = useIsInvoicingEnabled()
  const flags = useFeatureFlags()

  const isItemManagementEnabled = useMemo(
    () => !flags.POSItems && isInvoicingEnabled,
    [flags.POSItems, isInvoicingEnabled]
  )

  const itemManagementTitle = page.title.items

  return [
    {
      subitemTitle: page.title.transactions,
      subitemPath: ROOT.PORTAL.TRANSACTIONS.path,
    },
    {
      subitemTitle: page.title.deposits,
      subitemPath: ROOT.PORTAL.PAYMENTS.DEPOSITS.path,
    },
    {
      subitemTitle: page.title.reports,
      subitemPath: ROOT.PORTAL.PAYMENTS.REPORTS.path,
      isVisible: !flags.CashFlowReporting,
    },
    {
      subitemTitle: page.title.devices,
      subitemPath: ROOT.PORTAL.PAYMENTS.DEVICES.path,
    },
    {
      subitemTitle: itemManagementTitle,
      subitemPath: ROOT.PORTAL.ITEM_MANAGEMENT.path,
      isVisible: isItemManagementEnabled,
    },
    {
      subitemTitle: page.title.sim,
      subitemPath: ROOT.PORTAL.PAYMENTS.SIM.path,
    },
  ]
}
