import { translate } from 'utils/translations'

import { NoResultPlaceholderMessageProps } from '../TagPicker.types'
import {
  StyledBoldText,
  StyledContainer,
  StyledInnerContainer,
  StyledQuotesWrapper,
  StyledSearchWrapper,
  StyledText,
} from './NoResultsPlaceholder.styled'

interface Props {
  searchInput: string
  placeholderMessage: NoResultPlaceholderMessageProps
}

export const NoResultsPlaceholder = ({
  searchInput,
  placeholderMessage,
}: Props) => {
  return (
    <StyledContainer>
      <StyledInnerContainer>
        <StyledBoldText as="div">
          {translate(placeholderMessage.title, {
            searchInput: (
              <StyledQuotesWrapper>
                &apos;
                <StyledSearchWrapper>{searchInput}</StyledSearchWrapper>
                &apos;
              </StyledQuotesWrapper>
            ),
          })}
        </StyledBoldText>
        <StyledText>{translate(placeholderMessage.description)}</StyledText>
      </StyledInnerContainer>
    </StyledContainer>
  )
}
