import { useCallback, useMemo, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'

import { FilterBySearch } from 'components/FilterBySearch/FilterBySearch'
import { MobileFilters } from 'components/MobileFilters/MobileFilters'

import { useItemManagementContext } from '../../ItemManagementContext/ItemManagementContext'
import { ItemManagementContextType } from '../../ItemManagementContext/ItemManagementContext.types'
import { FilterByCategories } from './FilterByCategories/FilterByCategories'
import { FilterByPrice } from './FilterByPrice/FilterByPrice'
import { FilterList } from './FilterList/FilterList'
import { translations } from './ItemsMobileFilters.i18n'
import { ItemMobileFiltersVisibilityStates } from './ItemsMobileFilters.types'
import { SortByFilters } from './SortByFilters/SortByFilters'

type ItemsFilterInputType = ItemManagementContextType['items']['filterInput']
type ItemsSortInputType = ItemManagementContextType['items']['sortInput']

interface ItemsMobileFiltersProps {
  initialVisibilityState?: ItemMobileFiltersVisibilityStates
}

export const ItemsMobileFilters = ({
  initialVisibilityState = ItemMobileFiltersVisibilityStates.List,
}: ItemsMobileFiltersProps) => {
  const {
    items: {
      closeMobileFilters,
      filterInput,
      isMobileFiltersOpen,
      setFilterInput,
      setSortInput: handleNextSortInput,
      sortInput,
    },
    setTextSearchFilter,
  } = useItemManagementContext()

  const t = useTranslations(translations)

  const [visibilityState, setVisibilityState] =
    useState<ItemMobileFiltersVisibilityStates>(initialVisibilityState)

  const handleNextFilterInput = useCallback(
    (nextFilterInput: ItemsFilterInputType) => {
      if (nextFilterInput === null) {
        setFilterInput(null)
        setTextSearchFilter('')
      } else {
        const { textSearchFilter, ...otherFilters } = nextFilterInput

        setFilterInput(otherFilters)
        setTextSearchFilter(textSearchFilter ?? '')
      }
    },
    [setFilterInput, setTextSearchFilter]
  )

  const title = useMemo(() => {
    if (visibilityState === ItemMobileFiltersVisibilityStates.List) {
      return t('filterAndSortItems')
    }

    if (visibilityState === ItemMobileFiltersVisibilityStates.SortBy) {
      return t('sortBy')
    }

    if (
      visibilityState === ItemMobileFiltersVisibilityStates.FilterByCategory
    ) {
      return t('filterByCategory')
    }

    if (visibilityState === ItemMobileFiltersVisibilityStates.FilterByPrice) {
      return t('filterByPrice')
    }

    if (visibilityState === ItemMobileFiltersVisibilityStates.FilterByKeyword) {
      return t('filterByKeyword')
    }

    return t('filters')
  }, [t, visibilityState])

  const isDefaultVisibilityState =
    visibilityState === ItemMobileFiltersVisibilityStates.List

  const setDefaultVisibilityState = () =>
    setVisibilityState(ItemMobileFiltersVisibilityStates.List)

  return (
    <MobileFilters<ItemsFilterInputType, ItemsSortInputType>
      closeMobileFilters={closeMobileFilters}
      filterInput={filterInput}
      isDefaultVisibilityState={isDefaultVisibilityState}
      isMobileFiltersOpen={isMobileFiltersOpen}
      onNextFilterInput={handleNextFilterInput}
      onNextSortInput={handleNextSortInput}
      setDefaultVisibilityState={setDefaultVisibilityState}
      sortInput={sortInput}
      title={title}
    >
      {({
        nextFilterInput,
        nextSortInput,
        setNextFilterInput,
        setNextSortInput,
      }) => (
        <>
          {visibilityState === ItemMobileFiltersVisibilityStates.List && (
            <FilterList
              nextFilterInput={nextFilterInput}
              nextSortInput={nextSortInput}
              setVisibilityState={setVisibilityState}
            />
          )}
          {visibilityState === ItemMobileFiltersVisibilityStates.SortBy && (
            <SortByFilters
              nextSortInput={nextSortInput}
              setDefaultVisibilityState={setDefaultVisibilityState}
              setNextSortInput={setNextSortInput}
            />
          )}
          {visibilityState ===
            ItemMobileFiltersVisibilityStates.FilterByCategory && (
            <FilterByCategories
              nextFilterInput={nextFilterInput}
              setDefaultVisibilityState={setDefaultVisibilityState}
              setNextFilterInput={setNextFilterInput}
            />
          )}
          {visibilityState ===
            ItemMobileFiltersVisibilityStates.FilterByPrice && (
            <FilterByPrice
              nextFilterInput={nextFilterInput}
              setDefaultVisibilityState={setDefaultVisibilityState}
              setNextFilterInput={setNextFilterInput}
            />
          )}
          {visibilityState ===
            ItemMobileFiltersVisibilityStates.FilterByKeyword && (
            <FilterBySearch<ItemsFilterInputType>
              defaultValue={nextFilterInput?.textSearchFilter ?? ''}
              nextFilterInput={nextFilterInput}
              searchPlaceholder={t('searchItemOrCategory')}
              setDefaultVisibilityState={setDefaultVisibilityState}
              setNextFilterInput={setNextFilterInput}
            />
          )}
        </>
      )}
    </MobileFilters>
  )
}
