import { useState } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { ButtonLink, Flex } from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { translate } from 'utils/translations'

import { getSortedSubContacts } from '../../../Contacts.utils'
import { NoDataAddedContent } from '../../ContactGeneral/ContactGeneral.styled'
import { ContactSection } from '../ContactSection'
import { AddSubContact } from './AddSubContact/AddSubContact'
import { SubContactActionsMobile } from './SubContactActionsMobile/SubContactActionsMobile'
import { SubContactListItem } from './SubContactListItem/SubContactListItem'
import { UnlinkSubContactModal } from './UnlinkSubContactModal/UnlinkSubContactModal'

type Contact = ContactCoreFieldsFragment
type SubContact = NonNullable<ContactCoreFieldsFragment['contacts']>[0]

interface SubContactsProps {
  contact: Contact
}

export const SubContacts = ({ contact }: SubContactsProps) => {
  const { isModalOpen, openModal, closeModal } = useModalState()
  const {
    isModalOpen: isUnlinkModalOpen,
    openModal: openUnlinkModal,
    closeModal: closeUnlinkModal,
  } = useModalState()
  const {
    isModalOpen: isMobileModalOpen,
    openModal: openMobileModal,
    closeModal: closeMobileModal,
  } = useModalState()

  const [subContactToUnlink, setSubContactToUnlink] = useState<{
    person: Contact | SubContact | null
    business: Contact | SubContact | null
  } | null>(null)

  const isContactTypeBusiness = contact.contactType === ContactType.BUSINESS

  const heading = isContactTypeBusiness
    ? translate('page.contact.sections.subContacts.people')
    : translate('page.contact.sections.subContacts.businesses')

  const noSubContactMessage = isContactTypeBusiness
    ? translate('page.contact.sections.subContacts.noPeopleData')
    : translate('page.contact.sections.subContacts.noBusinessesData')

  const actionElement = (
    <ButtonLink
      data-testid="contact-add-subcontact"
      onClick={() => openModal()}
    >
      {translate('shared.add')}
    </ButtonLink>
  )

  const subContacts = getSortedSubContacts(contact)

  return (
    <ContactSection heading={heading} actionElement={actionElement}>
      {!subContacts.length ? (
        <NoDataAddedContent>{noSubContactMessage}</NoDataAddedContent>
      ) : (
        <Flex flexDirection="column">
          {subContacts.map((subContact) => {
            return (
              <SubContactListItem
                key={subContact?.id}
                subContact={subContact}
                setSubContact={() => {
                  setSubContactToUnlink({
                    business: isContactTypeBusiness ? contact : subContact,
                    person: isContactTypeBusiness ? subContact : contact,
                  })
                }}
                openUnlinkModal={openUnlinkModal}
                onOpenMobileModal={openMobileModal}
              />
            )
          })}
        </Flex>
      )}
      {subContactToUnlink && (
        <UnlinkSubContactModal
          business={subContactToUnlink.business}
          closeModal={closeUnlinkModal}
          isModalOpen={isUnlinkModalOpen}
          isContactTypeBusiness={isContactTypeBusiness}
          person={subContactToUnlink.person}
        />
      )}
      <AddSubContact
        isSubContactTypeBusiness={!isContactTypeBusiness}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
      <SubContactActionsMobile
        isOpen={isMobileModalOpen}
        closeModal={closeMobileModal}
        openUnlinkModal={openUnlinkModal}
      />
    </ContactSection>
  )
}
