import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import {
  DecisionModal,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { translationsShared } from 'translations'

import { useItemsAnalytics } from '../../../../../hooks/useItemsAnalytics'
import { useDeleteItem } from '../../hooks/useDeleteItem'
import { translations } from './ItemDeleteModal.i18n'

export const ItemDeleteModal = ({
  name,
  referenceNumber,
  isOpen,
  onClose,
}: {
  name: string
  referenceNumber: string
  isOpen: boolean
  onClose: () => void
}) => {
  const { trackDeleteItem } = useItemsAnalytics()

  const { deleteItem, isDeletingItem } = useDeleteItem()
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const navigate = useNavigate()

  const handleDeleteItem = useCallback(() => {
    async function sendRequest() {
      const deletedItem = await deleteItem(referenceNumber)

      if (!deletedItem?.data) {
        showErrorToast(t('error', { name }))
        return
      }

      trackDeleteItem()

      showSuccessToast(t('success', { name }))
      navigate(ROOT.PORTAL.ITEM_MANAGEMENT.ITEMS.path)
    }

    sendRequest().catch(() => showErrorToast(t('error', { name })))
  }, [deleteItem, navigate, name, t, trackDeleteItem, referenceNumber])

  return (
    <DecisionModal
      isOpen={isOpen}
      isLoadingPrimary={isDeletingItem}
      onCancel={onClose}
      onClickPrimary={handleDeleteItem}
      onClickSecondary={onClose}
      primaryButtonLabel={tShared('confirm')}
      secondaryButtonLabel={tShared('cancel')}
      title={t('title')}
    >
      {t('body', { name })}
    </DecisionModal>
  )
}
