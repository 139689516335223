import { useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

export const useEztaTermsOfService = () => {
  const entityDetails = useReactiveVar(rvEntityDetails)

  const hasRejectedTermsOfService = useMemo(() => {
    return entityDetails.termsOfService?.ezta?.accepted === false
  }, [entityDetails.termsOfService?.ezta?.accepted])

  return { hasRejectedTermsOfService }
}
