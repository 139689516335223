import { useMemo } from 'react'
import { NetworkStatus, QueryHookOptions, useQuery } from '@apollo/client'

import { isNotNull } from 'utils/common'

import {
  GetDebitCardTransactions,
  GetDebitCardTransactionsQueryResponse,
  GetDebitCardTransactionsQueryVariables,
} from './graphql/GetDebitCardTransactions.generated'

type HookOptions = QueryHookOptions<
  GetDebitCardTransactionsQueryResponse,
  GetDebitCardTransactionsQueryVariables
>

interface Arguments extends HookOptions {
  limit?: number
  filter: GetDebitCardTransactionsQueryVariables['filter']
}

export const DEFAULT_TRANSACTIONS_PER_PAGE = 20
export const useQueryCardTransactions = ({
  limit = DEFAULT_TRANSACTIONS_PER_PAGE,
  filter,
  variables,
  onCompleted,
  onError,
  ...queryOptions
}: Arguments) => {
  const {
    data,
    fetchMore,
    refetch: refetchTransactions,
    error,
    networkStatus,
  } = useQuery<
    GetDebitCardTransactionsQueryResponse,
    GetDebitCardTransactionsQueryVariables
  >(GetDebitCardTransactions, {
    onCompleted,
    onError,
    variables: {
      filter,
      limit,
      nextToken: undefined,
      ...variables,
    },
    ...queryOptions,
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'cache-first',
  })

  const transactions = useMemo(() => {
    const transactionsWithNulls =
      data?.getDebitCardTransactionsV2?.transactions || []

    return transactionsWithNulls.filter(isNotNull)
  }, [data?.getDebitCardTransactionsV2?.transactions])

  const fetchMoreTransactions = async () => {
    const nextToken = data?.getDebitCardTransactionsV2.nextToken

    if (nextToken) {
      await fetchMore({
        variables: {
          limit,
          nextToken,
          filter,
        },
      })
    }
  }

  const hasMore = Boolean(data?.getDebitCardTransactionsV2?.nextToken?.id)

  return {
    transactions,
    fetchMoreTransactions,
    loading:
      networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.setVariables ||
      networkStatus === NetworkStatus.refetch,
    hasMore,
    refetchTransactions,
    error,
  }
}
