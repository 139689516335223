import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetFilterCategoriesQueryVariables = Types.Exact<{
  filter: Types.InputMaybe<Types.GetCategoriesFilterInput>
  limit: Types.Scalars['Int']['input']
  nextToken: Types.InputMaybe<Types.Scalars['String']['input']>
  sort: Types.InputMaybe<Types.GetCategoriesSortInput>
}>

export type GetFilterCategoriesQueryResponse = {
  __typename?: 'Query'
  getItemCategories: {
    __typename?: 'ItemCategoryConnection'
    nextToken: string | null
    categories: Array<{
      __typename: 'ItemCategory'
      id: string
      name: string
    }> | null
  }
}

export const GetFilterCategories = gql`
  query GetFilterCategories(
    $filter: GetCategoriesFilterInput
    $limit: Int!
    $nextToken: String
    $sort: GetCategoriesSortInput
  ) {
    getItemCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) @connection(key: "categoriesFilter") {
      categories {
        __typename
        id
        name
      }
      nextToken
    }
  }
` as unknown as TypedDocumentNode<
  GetFilterCategoriesQueryResponse,
  GetFilterCategoriesQueryVariables
>

/**
 * __useGetFilterCategoriesQuery__
 *
 * To run a query within a React component, call `useGetFilterCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetFilterCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFilterCategoriesQueryResponse,
    GetFilterCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetFilterCategoriesQueryResponse,
    GetFilterCategoriesQueryVariables
  >(GetFilterCategories, options)
}
export function useGetFilterCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFilterCategoriesQueryResponse,
    GetFilterCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetFilterCategoriesQueryResponse,
    GetFilterCategoriesQueryVariables
  >(GetFilterCategories, options)
}
export type GetFilterCategoriesQueryHookResult = ReturnType<
  typeof useGetFilterCategoriesQuery
>
export type GetFilterCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetFilterCategoriesLazyQuery
>
export type GetFilterCategoriesQueryResult = Apollo.QueryResult<
  GetFilterCategoriesQueryResponse,
  GetFilterCategoriesQueryVariables
>
