import { ONBOARDING_SHOP, PORTAL_SHOP } from '@npco/mp-feature-onboarding-shop'

export enum ROUTE_NAME {
  PORTAL_ACCOUNTS_TRANSFER_CREATE = 'PORTAL_ACCOUNTS_TRANSFER_CREATE',
  JOIN_WAIT_LIST = 'JOIN_WAIT_LIST',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SIGNUP = 'SIGNUP',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  REGISTER_PHONE = 'REGISTER_PHONE',
  VALIDATE_CODE = 'VALIDATE_CODE',
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  CHANGE_EMAIL = 'CHANGE_EMAIL',
  CUSTOMER_PREFERENCES = 'CUSTOMER_PREFERENCES',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
  LOGIN_ERROR_ONBOARDING_STATUS = 'LOGIN_ERROR_ONBOARDING_STATUS',
  LOGIN_ERROR_ENTITY_DISABLED = 'LOGIN_ERROR_ENTITY_DISABLED',
  LOGIN_ERROR_ENTITY_ABANDONED = 'LOGIN_ERROR_ENTITY_ABANDONED',
  PIN_SUCCESS = 'PIN_SUCCESS',

  PORTAL_KYC_EXISTING_USER = 'PORTAL_KYC_EXISTING_USER',
  PORTAL_KYC_NEW_USER = 'PORTAL_KYC_NEW_USER',

  PORTAL = 'PORTAL',
  PORTAL_DEMOS = 'PORTAL_DEMOS',
  PORTAL_OVERVIEW = 'PORTAL_OVERVIEW',
  PORTAL_HELP = 'PORTAL_HELP',
  PORTAL_REFERRAL = 'PORTAL_REFERRAL',
  SHOP = 'SHOP',
  PORTAL_NOTIFICATIONS = 'PORTAL_NOTIFICATIONS',

  PORTAL_KYC = 'PORTAL_KYC',
  PORTAL_KYC_MEDICARE = 'PORTAL_KYC_MEDICARE',
  PORTAL_KYC_PASSPORT = 'PORTAL_KYC_PASSPORT',
  PORTAL_KYC_DRIVING_LICENCE = 'PORTAL_KYC_DRIVING_LICENCE',
  PORTAL_KYC_NO_IDENTIFICATION = 'PORTAL_KYC_NO_IDENTIFICATION',
  PORTAL_KYC_FINALISE = 'PORTAL_KYC_FINALISE',
  PORTAL_KYC_YOUR_INFORMATION = 'PORTAL_KYC_YOUR_INFORMATION',
  PORTAL_KYC_YOUR_IDENTITY = 'PORTAL_KYC_YOUR_IDENTITY',
  PORTAL_KYC_TIME_FOR_A_SELFIE = 'PORTAL_KYC_TIME_FOR_A_SELFIE',
  PORTAL_KYC_SELFIE_VERIFICATION = 'PORTAL_KYC_SELFIE_VERIFICATION',
  PORTAL_KYC_VERIFICATION_FAILED = 'PORTAL_KYC_VERIFICATION_FAILED',
  PORTAL_KYC_RESIDENTAL_ADDRESS = 'PORTAL_KYC_RESIDENTAL_ADDRESS',
  PORTAL_KYC_REJECTED = 'PORTAL_KYC_REJECTED',
  PORTAL_KYC_SUCCESS = 'PORTAL_KYC_SUCCESS',
  PORTAL_KYC_IN_REVIEW = 'PORTAL_KYC_IN_REVIEW',
  PORTAL_KYC_UPLOAD_SUCCESS = 'PORTAL_KYC_UPLOAD_SUCCESS',
  PORTAL_KYC_REVIEW_DOCUMENT_UPLOAD = 'PORTAL_KYC_REVIEW_DOCUMENT_UPLOAD',
  PORTAL_KYC_DOCUMENT_UPLOAD = 'PORTAL_KYC_DOCUMENT_UPLOAD',

  PORTAL_PAYMENTS = 'PORTAL_PAYMENTS',
  PORTAL_PAYMENTS_REPORTS = 'PORTAL_PAYMENTS_REPORTS',
  PORTAL_PAYMENTS_TRANSACTIONS = 'PORTAL_PAYMENTS_TRANSACTIONS',
  PORTAL_PAYMENTS_DEPOSITS = 'PORTAL_PAYMENTS_DEPOSITS',
  PORTAL_PAYMENTS_DEVICES = 'PORTAL_PAYMENTS_DEVICES',

  PORTAL_PAYMENTS_DEPOSITS_STATEMENTS = 'PORTAL_PAYMENTS_DEPOSITS_STATEMENTS',
  PORTAL_PAYMENTS_DEPOSITS_ACCOUNT = 'PORTAL_PAYMENTS_DEPOSITS_SETTINGS',
  PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_DETAILS = 'PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_DETAILS',
  PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT = 'PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT',
  PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT_SUCCESS = 'PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT_SUCCESS',
  PORTAL_PAYMENTS_DEPOSITS_SETTINGS_EDIT_ACCOUNT = 'PORTAL_PAYMENTS_DEPOSITS_SETTINGS_EDIT_ACCOUNT',
  PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_EDIT_ACCOUNT_DETAIL = 'PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_EDIT_ACCOUNT_DETAIL',

  PORTAL_PAYMENTS_SITES = 'PORTAL_PAYMENTS_SITES',
  PORTAL_PAYMENTS_SITES_CREATE_SITE = 'PORTAL_PAYMENTS_SITES_CREATE_SITE',
  PORTAL_PAYMENTS_SITES_SITE = 'PORTAL_PAYMENTS_SITES_SITE',
  PORTAL_PAYMENTS_SITES_SITE_USERS = 'PORTAL_PAYMENTS_SITES_SITE_USERS',
  PORTAL_PAYMENTS_SITES_SITE_USERS_EDIT = 'PORTAL_PAYMENTS_SITES_SITE_USERS_EDIT',
  PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_TYPE = 'PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_TYPE',
  PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_DETAILS = 'PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_DETAILS',
  PORTAL_PAYMENTS_SITES_SITE_GENERAL = 'PORTAL_PAYMENTS_SITES_SITE_GENERAL',
  PORTAL_PAYMENTS_SITES_SITE_GENERAL_EDIT = 'PORTAL_PAYMENTS_SITES_SITE_GENERAL_EDIT',
  PORTAL_PAYMENTS_SITES_SITE_DEVICES = 'PORTAL_PAYMENTS_SITES_SITE_DEVICES',
  PORTAL_PAYMENTS_SITES_SITE_PAYMENTS = 'PORTAL_PAYMENTS_SITES_SITE_PAYMENTS',
  PORTAL_PAYMENTS_SITES_SITE_RECEIPT = 'PORTAL_PAYMENTS_SITES_SITE_RECEIPT',
  PORTAL_PAYMENTS_SITES_SITE_APPEARANCE = 'PORTAL_PAYMENTS_SITES_SITE_APPEARANCE',

  PORTAL_SETTINGS = 'PORTAL_SETTINGS',
  PORTAL_SETTINGS_PROFILE = 'PORTAL_SETTINGS_PROFILE',
  PORTAL_SETTINGS_PROFILE_PERSONAL = 'PORTAL_SETTINGS_PROFILE_PERSONAL',
  PORTAL_SETTINGS_PROFILE_PERSONAL_EDIT = 'PORTAL_SETTINGS_PROFILE_PERSONAL_EDIT',
  PORTAL_SETTINGS_PROFILE_SECURITY = 'PORTAL_SETTINGS_PROFILE_SECURITY',
  PORTAL_SETTINGS_PROFILE_SECURITY_CHANGE_NUMBER = 'PORTAL_SETTINGS_PROFILE_SECURITY_CHANGE_NUMBER',
  PORTAL_SETTINGS_PROFILE_SECURITY_VALIDATE_CODE = 'PORTAL_SETTINGS_PROFILE_SECURITY_VALIDATE_CODE',
  PORTAL_SETTINGS_PROFILE_DOCUMENTS = 'PORTAL_SETTINGS_PROFILE_DOCUMENTS',

  PORTAL_SETTINGS_FEES_AND_PRICING = 'PORTAL_SETTINGS_FEES_AND_PRICING',

  PORTAL_SETTINGS_USERS = 'PORTAL_SETTINGS_USERS',
  PORTAL_SETTINGS_USERS_USER_EDIT = 'PORTAL_SETTINGS_USERS_USER_EDIT',
  PORTAL_SETTINGS_USERS_USER_CREATE_TYPE = 'PORTAL_SETTINGS_USERS_USER_CREATE_TYPE',
  PORTAL_SETTINGS_USERS_USER_CREATE_DETAILS = 'PORTAL_SETTINGS_USERS_USER_CREATE_DETAILS',

  PORTAL_ACCOUNTS = 'PORTAL_ACCOUNTS',
  PORTAL_ACCOUNTS_TRANSACTIONS = 'PORTAL_ACCOUNTS_TRANSACTIONS',
  PORTAL_ACCOUNTS_ACCOUNT = 'PORTAL_ACCOUNTS_ACCOUNT',
  PORTAL_ACCOUNTS_ACCOUNT_ID = 'PORTAL_ACCOUNTS_ACCOUNT_ID',
  PORTAL_ACCOUNTS_ACCOUNT_TRANSACTIONS = 'PORTAL_ACCOUNTS_ACCOUNT_TRANSACTIONS',
  PORTAL_ACCOUNTS_ACCOUNT_CARDS = 'PORTAL_ACCOUNTS_ACCOUNT_CARDS',
  PORTAL_ACCOUNTS_ACCOUNT_TRANSACTIONS_TRANSACTION = 'PORTAL_ACCOUNTS_ACCOUNT_TRANSACTIONS_TRANSACTION',
  PORTAL_ACCOUNTS_ACCOUNT_ID_TRANSACTIONS = 'PORTAL_ACCOUNTS_ACCOUNT_ID_TRANSACTIONS',
  PORTAL_ACCOUNTS_ACCOUNT_ID_DETAILS = 'PORTAL_ACCOUNTS_ACCOUNT_ID_DETAILS',
  PORTAL_ACCOUNTS_ACCOUNT_ID_EDIT = 'PORTAL_ACCOUNTS_ACCOUNT_ID_EDIT',
  PORTAL_ACCOUNTS_ACCOUNT_ID_CLOSE = 'PORTAL_ACCOUNTS_ACCOUNT_ID_CLOSE',

  PORTAL_CARDS = 'PORTAL_CARDS',
  PORTAL_CARDS_DEBIT = 'PORTAL_CARDS_DEBIT',
  PORTAL_CARDS_CORPORATE = 'PORTAL_CARDS_CORPORATE',
  PORTAL_CARDS_ACTIVATE = 'PORTAL_CARDS_ACTIVATE',
  PORTAL_CARDS_ID = 'PORTAL_CARDS_ID',
  PORTAL_INVOICES = 'PORTAL_INVOICES',
  PORTAL_INVOICES_OVERVIEW = 'PORTAL_INVOICES_OVERVIEW',
  PORTAL_INVOICES_INVOICE_LIST = 'PORTAL_INVOICES_INVOICE_LIST',
  PORTAL_INVOICES_INVOICE_DETAILS = 'PORTAL_INVOICES_INVOICE_DETAILS',
  PORTAL_INVOICES_INVOICE_CREATE = 'PORTAL_INVOICES_INVOICE_CREATE',
  PORTAL_INVOICES_INVOICE_EDIT = 'PORTAL_INVOICES_INVOICE_EDIT',
  PORTAL_INVOICES_QUOTES = 'PORTAL_INVOICES_QUOTES',
  PORTAL_INVOICES_RECURRING = 'PORTAL_INVOICES_RECURRING',
  PORTAL_INVOICES_SETTINGS = 'PORTAL_INVOICES_SETTINGS',
  PORTAL_INVOICES_SETTINGS_GENERAL = 'PORTAL_INVOICES_SETTINGS_GENERAL',
  PORTAL_INVOICES_SETTINGS_RECEIPTS = 'PORTAL_INVOICES_SETTINGS_RECEIPTS',
  PORTAL_INVOICES_SETTINGS_REMINDERS = 'PORTAL_INVOICES_SETTINGS_REMINDERS',
  PORTAL_INVOICES_SETTINGS_USERS = 'PORTAL_INVOICES_SETTINGS_USERS',
  PORTAL_INVOICES_SETTINGS_USERS_DETAILS = 'PORTAL_INVOICES_SETTINGS_USERS_DETAILS',
  PORTAL_INVOICES_SETTINGS_CUSTOMISATION = 'PORTAL_INVOICES_SETTINGS_CUSTOMISATION',
  PORTAL_INVOICES_QUOTE_CREATE = 'PORTAL_INVOICES_QUOTE_CREATE',

  PORTAL_CONTACTS = 'PORTAL_CONTACTS',
  PORTAL_CONTACTS_BUSINESSES = 'PORTAL_CONTACTS_BUSINESSES',
  PORTAL_CONTACTS_PEOPLE = 'PORTAL_CONTACTS_PEOPLE',
  PORTAL_CONTACTS_PEOPLE_GENERAL = 'PORTAL_CONTACTS_PEOPLE_GENERAL',
  PORTAL_CONTACTS_PEOPLE_PAYMENTS = 'PORTAL_CONTACTS_PEOPLE_PAYMENTS',
  PORTAL_CONTACTS_PEOPLE_ACCOUNTS = 'PORTAL_CONTACTS_PEOPLE_ACCOUNTS',
  PORTAL_CONTACTS_PEOPLE_INVOICES = 'PORTAL_CONTACTS_PEOPLE_INVOICES',
  PORTAL_CONTACTS_PEOPLE_INVOICES_DETAILS = 'PORTAL_CONTACTS_PEOPLE_INVOICES_DETAILS',
  PORTAL_CONTACTS_BUSINESSES_GENERAL = 'PORTAL_CONTACTS_BUSINESSES_GENERAL',
  PORTAL_CONTACTS_BUSINESSES_PAYMENTS = 'PORTAL_CONTACTS_BUSINESSES_PAYMENTS',
  PORTAL_CONTACTS_BUSINESSES_ACCOUNTS = 'PORTAL_CONTACTS_BUSINESSES_ACCOUNTS',
  PORTAL_CONTACTS_BUSINESSES_INVOICES = 'PORTAL_CONTACTS_BUSINESSES_INVOICES',
  PORTAL_CONTACTS_BUSINESSES_INVOICES_DETAILS = 'PORTAL_CONTACTS_BUSINESSES_INVOICES_DETAILS',

  ONBOARDING = 'ONBOARDING',
  ONBOARDING_WELCOME_BACK = 'ONBOARDING_WELCOME_BACK',
  ONBOARDING_YOUR_BUSINESS_INFORMATION = 'ONBOARDING_YOUR_BUSINESS_INFORMATION',
  ONBOARDING_TRY_AGAIN = 'ONBOARDING_TRY_AGAIN',
  ONBOARDING_GOVERNMENT = 'ONBOARDING_GOVERNMENT',
  ONBOARDING_GOVERNMENT_PRINCIPAL_PLACE_OF_BUSINESS = 'ONBOARDING_GOVERNMENT_PRINCIPAL_PLACE_OF_BUSINESS',
  ONBOARDING_GOVERNMENT_REVENUE = 'ONBOARDING_GOVERNMENT_REVENUE',
  ONBOARDING_GOVERNMENT_BUSINESS_TYPE = 'ONBOARDING_GOVERNMENT_BUSINESS_TYPE',
  ONBOARDING_GOVERNMENT_YOUR_ROLE = 'ONBOARDING_GOVERNMENT_YOUR_ROLE',
  ONBOARDING_GOVERNMENT_ABOUT_BUSINESS = 'ONBOARDING_GOVERNMENT_ABOUT_BUSINESS',
  ONBOARDING_GOVERNMENT_BUSINESS_REGULATIONS = 'ONBOARDING_GOVERNMENT_BUSINESS_REGULATIONS',
  ONBOARDING_INDIVIDUAL_NO_ABN = 'ONBOARDING_INDIVIDUAL_NO_ABN',
  ONBOARDING_INDIVIDUAL_NO_ABN_YOUR_BUSINESS_INFORMATION = 'ONBOARDING_INDIVIDUAL_NO_ABN_YOUR_BUSINESS_INFORMATION',
  ONBOARDING_INDIVIDUAL_NO_ABN_PRINCIPAL_PLACE_OF_BUSINESS = 'ONBOARDING_INDIVIDUAL_NO_ABN_PRINCIPAL_PLACE_OF_BUSINESS',
  ONBOARDING_INDIVIDUAL_NO_ABN_REVENUE = 'ONBOARDING_INDIVIDUAL_NO_ABN_REVENUE',
  ONBOARDING_INDIVIDUAL_NO_ABN_BUSINESS_TYPE = 'ONBOARDING_INDIVIDUAL_NO_ABN_BUSINESS_TYPE',
  ONBOARDING_INDIVIDUAL_NO_ABN_ABOUT_BUSINESS = 'ONBOARDING_INDIVIDUAL_NO_ABN_ABOUT_BUSINESS',
  ONBOARDING_INDIVIDUAL_NO_ABN_TRADING_NAME = 'ONBOARDING_INDIVIDUAL_NO_ABN_TRADING_NAME',
  ONBOARDING_INDIVIDUAL_NO_ABN_BUSINESS_REGULATIONS = 'ONBOARDING_INDIVIDUAL_NO_ABN_BUSINESS_REGULATIONS',
  ONBOARDING_INDIVIDUAL_NO_ABN_UPLOAD_BANK_STATEMENTS = 'ONBOARDING_INDIVIDUAL_NO_ABN_UPLOAD_BANK_STATEMENTS',
  ONBOARDING_INDIVIDUAL = 'ONBOARDING_INDIVIDUAL',
  ONBOARDING_INDIVIDUAL_REVENUE = 'ONBOARDING_INDIVIDUAL_REVENUE',
  ONBOARDING_INDIVIDUAL_TRADING_NAME = 'ONBOARDING_INDIVIDUAL_TRADING_NAME',
  ONBOARDING_INDIVIDUAL_REGISTERED_OFFICE_ADDRESS = 'ONBOARDING_INDIVIDUAL_REGISTERED_OFFICE_ADDRESS',
  ONBOARDING_INDIVIDUAL_BUSINESS_TYPE = 'ONBOARDING_INDIVIDUAL_BUSINESS_TYPE',
  ONBOARDING_INDIVIDUAL_ABOUT_BUSINESS = 'ONBOARDING_INDIVIDUAL_ABOUT_BUSINESS',
  ONBOARDING_INDIVIDUAL_BUSINESS_REGULATIONS = 'ONBOARDING_INDIVIDUAL_BUSINESS_REGULATIONS',
  ONBOARDING_INDIVIDUAL_UPLOAD_BANK_STATEMENTS = 'ONBOARDING_INDIVIDUAL_UPLOAD_BANK_STATEMENTS',
  ONBOARDING_COMPANY = 'ONBOARDING_COMPANY',
  ONBOARDING_COMPANY_SEARCH = 'ONBOARDING_COMPANY_SEARCH',
  ONBOARDING_COMPANY_INFORMATION = 'ONBOARDING_COMPANY_INFORMATION',
  ONBOARDING_BUSINESS_INFORMATION = 'ONBOARDING_BUSINESS_INFORMATION',
  ONBOARDING_COMPANY_TRADING_NAME = 'ONBOARDING_COMPANY_TRADING_NAME',
  ONBOARDING_COMPANY_REGISTERED_OFFICE_ADDRESS = 'ONBOARDING_COMPANY_REGISTERED_OFFICE_ADDRESS',
  ONBOARDING_COMPANY_PLACE_OF_BUSINESS = 'ONBOARDING_COMPANY_PLACE_OF_BUSINESS',
  ONBOARDING_COMPANY_REVENUE = 'ONBOARDING_COMPANY_REVENUE',
  ONBOARDING_COMPANY_BUSINESS_TYPE = 'ONBOARDING_COMPANY_BUSINESS_TYPE',
  ONBOARDING_COMPANY_POSITION = 'ONBOARDING_COMPANY_POSITION',
  ONBOARDING_COMPANY_NAME = 'ONBOARDING_COMPANY_NAME',
  ONBOARDING_COMPANY_CUSTOMERS = 'ONBOARDING_COMPANY_CUSTOMERS',
  ONBOARDING_COMPANY_CUSTOMERS_DIRECTORS = 'ONBOARDING_COMPANY_CUSTOMERS_DIRECTORS',
  ONBOARDING_COMPANY_CUSTOMERS_ADD_DIRECTOR = 'ONBOARDING_COMPANY_CUSTOMERS_ADD_DIRECTOR',
  ONBOARDING_COMPANY_CUSTOMERS_EDIT_DIRECTOR = 'ONBOARDING_COMPANY_CUSTOMERS_EDIT_DIRECTOR',
  ONBOARDING_COMPANY_CUSTOMERS_BENEFICIAL_OWNERS = 'ONBOARDING_COMPANY_CUSTOMERS_BENEFICIAL_OWNERS',
  ONBOARDING_COMPANY_CUSTOMERS_ADD_BENEFICIAL_OWNER = 'ONBOARDING_COMPANY_CUSTOMERS_ADD_BENEFICIAL_OWNER',
  ONBOARDING_COMPANY_CUSTOMERS_EDIT_BENEFICIAL_OWNER = 'ONBOARDING_COMPANY_CUSTOMERS_EDIT_BENEFICIAL_OWNER',
  ONBOARDING_COMPANY_CUSTOMERS_ALTERNATE_BENEFICIAL_OWNERS = 'ONBOARDING_COMPANY_CUSTOMERS_ALTERNATE_BENEFICIAL_OWNERS',
  ONBOARDING_COMPANY_CUSTOMERS_ADD_ALTERNATE_BENEFICIAL_OWNER = 'ONBOARDING_COMPANY_CUSTOMERS_ADD_ALTERNATE_BENEFICIAL_OWNER',
  ONBOARDING_COMPANY_CUSTOMERS_EDIT_ALTERNATE_BENEFICIAL_OWNER = 'ONBOARDING_COMPANY_CUSTOMERS_EDIT_ALTERNATE_BENEFICIAL_OWNER',
  ONBOARDING_COMPANY_ABOUT_BUSINESS = 'ONBOARDING_COMPANY_ABOUT_BUSINESS',
  ONBOARDING_COMPANY_BUSINESS_REGULATIONS = 'ONBOARDING_COMPANY_BUSINESS_REGULATIONS',
  ONBOARDING_COMPANY_UPLOAD_BANK_STATEMENTS = 'ONBOARDING_COMPANY_UPLOAD_BANK_STATEMENTS',
  ONBOARDING_TRUST = 'ONBOARDING_TRUST',
  ONBOARDING_TRUST_TRADING_NAME = 'ONBOARDING_TRUST_TRADING_NAME',
  ONBOARDING_TRUST_REGISTERED_OFFICE_ADDRESS = 'ONBOARDING_TRUST_REGISTERED_OFFICE_ADDRESS',
  ONBOARDING_TRUST_REVENUE = 'ONBOARDING_TRUST_REVENUE',
  ONBOARDING_TRUST_BUSINESS_TYPE = 'ONBOARDING_TRUST_BUSINESS_TYPE',
  ONBOARDING_TRUST_POSITION = 'ONBOARDING_TRUST_POSITION',
  ONBOARDING_TRUST_CUSTOMERS = 'ONBOARDING_TRUST_CUSTOMERS',
  ONBOARDING_TRUST_CUSTOMERS_SETTLED_SUM = 'ONBOARDING_TRUST_CUSTOMERS_SETTLED_SUM',
  ONBOARDING_TRUST_CUSTOMERS_SETTLOR_INFORMATION = 'ONBOARDING_TRUST_CUSTOMERS_SETTLOR_INFORMATION',
  ONBOARDING_TRUST_CUSTOMERS_BENEFICIARIES = 'ONBOARDING_TRUST_CUSTOMERS_BENEFICIARIES',
  ONBOARDING_TRUST_CUSTOMERS_ADD_BENEFICIARIES = 'ONBOARDING_TRUST_CUSTOMERS_ADD_BENEFICIARIES',
  ONBOARDING_TRUST_CUSTOMERS_EDIT_BENEFICIARIES = 'ONBOARDING_TRUST_CUSTOMERS_EDIT_BENEFICIARIES',
  ONBOARDING_TRUST_CUSTOMERS_TRUSTEES = 'ONBOARDING_TRUST_CUSTOMERS_TRUSTEES',
  ONBOARDING_TRUST_CUSTOMERS_ADD_TRUSTEES = 'ONBOARDING_TRUST_CUSTOMERS_ADD_TRUSTEES',
  ONBOARDING_TRUST_CUSTOMERS_EDIT_TRUSTEES = 'ONBOARDING_TRUST_CUSTOMERS_EDIT_TRUSTEES',
  ONBOARDING_TRUST_CUSTOMERS_BENEFICIAL_OWNERS = 'ONBOARDING_TRUST_CUSTOMERS_BENEFICIAL_OWNERS',
  ONBOARDING_TRUST_CUSTOMERS_ADD_BENEFICIAL_OWNERS = 'ONBOARDING_TRUST_CUSTOMERS_ADD_BENEFICIAL_OWNERS',
  ONBOARDING_TRUST_CUSTOMERS_EDIT_BENEFICIAL_OWNERS = 'ONBOARDING_TRUST_CUSTOMERS_EDIT_BENEFICIAL_OWNERS',
  ONBOARDING_TRUST_UPLOAD_DOCUMENTS = 'ONBOARDING_TRUST_UPLOAD_DOCUMENTS',
  ONBOARDING_TRUST_ABOUT_BUSINESS = 'ONBOARDING_TRUST_ABOUT_BUSINESS',
  ONBOARDING_TRUST_BUSINESS_REGULATIONS = 'ONBOARDING_TRUST_BUSINESS_REGULATIONS',
  ONBOARDING_TRUST_UPLOAD_BANK_STATEMENTS = 'ONBOARDING_TRUST_UPLOAD_BANK_STATEMENTS',
  ONBOARDING_PARTNERSHIP = 'ONBOARDING_PARTNERSHIP',
  ONBOARDING_PARTNERSHIP_TRADING_NAME = 'ONBOARDING_PARTNERSHIP_TRADING_NAME',
  ONBOARDING_PARTNERSHIP_REGISTERED_OFFICE_ADDRESS = 'ONBOARDING_PARTNERSHIP_REGISTERED_OFFICE_ADDRESS',
  ONBOARDING_PARTNERSHIP_REVENUE = 'ONBOARDING_PARTNERSHIP_REVENUE',
  ONBOARDING_PARTNERSHIP_BUSINESS_TYPE = 'ONBOARDING_PARTNERSHIP_BUSINESS_TYPE',
  ONBOARDING_PARTNERSHIP_POSITION = 'ONBOARDING_PARTNERSHIP_POSITION',
  ONBOARDING_PARTNERSHIP_CUSTOMERS = 'ONBOARDING_PARTNERSHIP_CUSTOMERS',
  ONBOARDING_PARTNERSHIP_CUSTOMERS_PARTNERS = 'ONBOARDING_PARTNERSHIP_CUSTOMERS_PARTNERS',
  ONBOARDING_PARTNERSHIP_CUSTOMERS_ADD_PARTNER = 'ONBOARDING_PARTNERSHIP_CUSTOMERS_ADD_PARTNER',
  ONBOARDING_PARTNERSHIP_CUSTOMERS_EDIT_PARTNER = 'ONBOARDING_PARTNERSHIP_CUSTOMERS_EDIT_PARTNER',
  ONBOARDING_PARTNERSHIP_ABOUT_BUSINESS = 'ONBOARDING_PARTNERSHIP_ABOUT_BUSINESS',
  ONBOARDING_PARTNERSHIP_BUSINESS_REGULATIONS = 'ONBOARDING_PARTNERSHIP_BUSINESS_REGULATIONS',
  ONBOARDING_PARTNERSHIP_UPLOAD_DOCUMENTS = 'ONBOARDING_PARTNERSHIP_UPLOAD_DOCUMENTS',
  ONBOARDING_PARTNERSHIP_UPLOAD_BANK_STATEMENTS = 'ONBOARDING_PARTNERSHIP_UPLOAD_BANK_STATEMENTS',
  ONBOARDING_ASSOCIATION = 'ONBOARDING_ASSOCIATION',
  ONBOARDING_ASSOCIATION_TRADING_NAME = 'ONBOARDING_ASSOCIATION_TRADING_NAME',
  ONBOARDING_ASSOCIATION_REGISTERED_OFFICE_ADDRESS = 'ONBOARDING_ASSOCIATION_REGISTERED_OFFICE_ADDRESS',
  ONBOARDING_ASSOCIATION_PLACE_OF_BUSINESS = 'ONBOARDING_ASSOCIATION_PLACE_OF_BUSINESS',
  ONBOARDING_ASSOCIATION_REVENUE = 'ONBOARDING_ASSOCIATION_REVENUE',
  ONBOARDING_ASSOCIATION_BUSINESS_TYPE = 'ONBOARDING_ASSOCIATION_BUSINESS_TYPE',
  ONBOARDING_ASSOCIATION_POSITION = 'ONBOARDING_ASSOCIATION_POSITION',
  ONBOARDING_ASSOCIATION_CUSTOMERS = 'ONBOARDING_ASSOCIATION_CUSTOMERS',
  ONBOARDING_ASSOCIATION_CUSTOMERS_CHAIR = 'ONBOARDING_ASSOCIATION_CUSTOMERS_CHAIR',
  ONBOARDING_ASSOCIATION_CUSTOMERS_SECRETARY = 'ONBOARDING_ASSOCIATION_CUSTOMERS_SECRETARY',
  ONBOARDING_ASSOCIATION_CUSTOMERS_TREASURER = 'ONBOARDING_ASSOCIATION_CUSTOMERS_TREASURER',
  ONBOARDING_ASSOCIATION_ABOUT_BUSINESS = 'ONBOARDING_ASSOCIATION_ABOUT_BUSINESS',
  ONBOARDING_ASSOCIATION_BUSINESS_REGULATIONS = 'ONBOARDING_ASSOCIATION_BUSINESS_REGULATIONS',
  ONBOARDING_ASSOCIATION_UPLOAD_DOCUMENTS = 'ONBOARDING_ASSOCIATION_UPLOAD_DOCUMENTS',
  ONBOARDING_ASSOCIATION_UPLOAD_BANK_STATEMENTS = 'ONBOARDING_ASSOCIATION_UPLOAD_BANK_STATEMENTS',
  ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE',
  ONBOARDING_KYC = 'ONBOARDING_KYC',
  ONBOARDING_KYC_MEDICARE = 'ONBOARDING_KYC_MEDICARE',
  ONBOARDING_KYC_PASSPORT = 'ONBOARDING_KYC_PASSPORT',
  ONBOARDING_KYC_DRIVING_LICENCE = 'ONBOARDING_KYC_DRIVING_LICENCE',
  ONBOARDING_KYC_YOUR_INFORMATION = 'ONBOARDING_KYC_YOUR_INFORMATION',
  ONBOARDING_KYC_VERIFICATION_FAILED = 'ONBOARDING_KYC_VERIFICATION_FAILED',
  ONBOARDING_KYC_RESIDENTAL_ADDRESS = 'ONBOARDING_KYC_RESIDENTAL_ADDRESS',
  ONBOARDING_KYC_NO_IDENTIFICATION = 'ONBOARDING_KYC_NO_IDENTIFICATION',
  ONBOARDING_KYC_TIME_FOR_A_SELFIE = 'ONBOARDING_KYC_TIME_FOR_A_SELFIE',
  ONBOARDING_KYC_SELFIE_VERIFICATION = 'ONBOARDING_KYC_SELFIE_VERIFICATION',
  ONBOARDING_BUSINESS_REVIEW = 'ONBOARDING_BUSINESS_REVIEW',
  ONBOARDING_BUSINESS_REVIEW_UPLOAD_DOCUMENTS = 'ONBOARDING_BUSINESS_REVIEW_UPLOAD_DOCUMENTS',
  ONBOARDING_BUSINESS_REVIEW_BIOMETRICS = 'ONBOARDING_BUSINESS_REVIEW_BIOMETRICS',
  ONBOARDING_BUSINESS_REVIEW_BIOMETRICS_ONFIDO = 'ONBOARDING_BUSINESS_REVIEW_BIOMETRICS_ONFIDO',
  ONBOARDING_UPLOAD_DOCUMENTS_LATER = 'ONBOARDING_UPLOAD_DOCUMENTS_LATER',
  ONBOARDING_PRE_ONBOARDED_QUESTIONNAIRE = 'ONBOARDING_PRE_ONBOARDED_QUESTIONNAIRE',
  ONBOARDING_PRE_ONBOARDED_QUESTIONNAIRE_UPLOAD_LATER = 'ONBOARDING_PRE_ONBOARDED_QUESTIONNAIRE_UPLOAD_LATER',

  MY_ZELLER_COM = 'MY_ZELLER_COM',
}

export enum ROUTE_PARAM_NAME {
  PORTAL_ACCOUNT_ID = 'accountId',
  PORTAL_ATTRIBUTE_SET_ID = 'attributeSetId',
  PORTAL_CARDS_ID = 'cardId',
  PORTAL_CONTACT_ID = 'contactId',
  PORTAL_DISCOUNT_ID = 'discountId',
  PORTAL_INVOICE_REF_NUMBER = 'invoiceRefNumber',
  PORTAL_INVOICE_SETTINGS_USER_ID = 'invoiceSettingsUserId',
  PORTAL_ITEM_CATEGORY_ID = 'categoryId',
  PORTAL_ITEM_ID = 'itemRefNumber',
  PORTAL_MODIFIER_SET_ID = 'modifierSetId',
  PORTAL_SETTINGS_XERO_USER_ID = 'settingsXeroUserId',
  PORTAL_SPLIT_PAYMENT_ID = 'splitPaymentId',
}

export const ONBOARDING_ROUTES = {
  [ROUTE_NAME.ONBOARDING]: '/onboarding',
  [ROUTE_NAME.ONBOARDING_WELCOME_BACK]: '/onboarding/welcome-back',
  [ROUTE_NAME.ONBOARDING_TRY_AGAIN]: `/onboarding/try-again`,
  [ROUTE_NAME.ONBOARDING_GOVERNMENT]: '/onboarding/government',
  [ROUTE_NAME.ONBOARDING_YOUR_BUSINESS_INFORMATION]:
    '/onboarding/your-business-information',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_PRINCIPAL_PLACE_OF_BUSINESS]:
    '/onboarding/government/place-of-business',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_REVENUE]: '/onboarding/government/revenue',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_BUSINESS_TYPE]:
    '/onboarding/government/business-type',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_YOUR_ROLE]:
    '/onboarding/government/your-role',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_ABOUT_BUSINESS]:
    '/onboarding/government/about-business',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_BUSINESS_REGULATIONS]:
    '/onboarding/government/business-regulations',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN]: '/onboarding/individual-no-abn',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_YOUR_BUSINESS_INFORMATION]:
    '/onboarding/individual-no-abn/your-business-information',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_PRINCIPAL_PLACE_OF_BUSINESS]:
    '/onboarding/individual-no-abn/principal-place',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_REVENUE]:
    '/onboarding/individual-no-abn/revenue',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_BUSINESS_TYPE]:
    '/onboarding/individual-no-abn/business-type',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_ABOUT_BUSINESS]:
    '/onboarding/individual-no-abn/about-business',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_TRADING_NAME]:
    '/onboarding/individual-no-abn/trading-name',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_BUSINESS_REGULATIONS]:
    '/onboarding/individual-no-abn/business-regulations',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_UPLOAD_BANK_STATEMENTS]:
    '/onboarding/individual-no-abn/upload-bank-statements',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL]: '/onboarding/individual',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_TRADING_NAME]:
    '/onboarding/individual/trading-name',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_REGISTERED_OFFICE_ADDRESS]:
    '/onboarding/individual/registered-office-address',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_REVENUE]: '/onboarding/individual/revenue',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_BUSINESS_TYPE]:
    '/onboarding/individual/business-type',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_ABOUT_BUSINESS]:
    '/onboarding/individual/about-business',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_BUSINESS_REGULATIONS]:
    '/onboarding/individual/business-regulations',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_UPLOAD_BANK_STATEMENTS]:
    '/onboarding/individual/upload-bank-statements',
  [ROUTE_NAME.ONBOARDING_COMPANY]: '/onboarding/company',
  [ROUTE_NAME.ONBOARDING_COMPANY_SEARCH]: '/onboarding/company-search',
  [ROUTE_NAME.ONBOARDING_COMPANY_INFORMATION]:
    '/onboarding/company-information',
  [ROUTE_NAME.ONBOARDING_BUSINESS_INFORMATION]:
    '/onboarding/business-information',
  [ROUTE_NAME.ONBOARDING_COMPANY_TRADING_NAME]:
    '/onboarding/company/trading-name',
  [ROUTE_NAME.ONBOARDING_COMPANY_REGISTERED_OFFICE_ADDRESS]:
    '/onboarding/company/registered-office-address',
  [ROUTE_NAME.ONBOARDING_COMPANY_PLACE_OF_BUSINESS]:
    '/onboarding/company/place-of-business',
  [ROUTE_NAME.ONBOARDING_COMPANY_REVENUE]: '/onboarding/company/revenue',
  [ROUTE_NAME.ONBOARDING_COMPANY_BUSINESS_TYPE]:
    '/onboarding/company/business-type',
  [ROUTE_NAME.ONBOARDING_COMPANY_POSITION]: '/onboarding/company/position',
  [ROUTE_NAME.ONBOARDING_COMPANY_NAME]: '/onboarding/company/name',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS]: '/onboarding/company/customers',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_DIRECTORS]:
    '/onboarding/company/customers/directors',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_ADD_DIRECTOR]:
    '/onboarding/company/customers/add-director',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_EDIT_DIRECTOR]:
    '/onboarding/company/customers/edit-director',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_BENEFICIAL_OWNERS]:
    '/onboarding/company/customers/beneficial-owners',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_ADD_BENEFICIAL_OWNER]:
    '/onboarding/company/customers/add-beneficial-owner',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_EDIT_BENEFICIAL_OWNER]:
    '/onboarding/company/customers/edit-beneficial-owner',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_ALTERNATE_BENEFICIAL_OWNERS]:
    '/onboarding/company/customers/alternate-beneficial-owners',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_ADD_ALTERNATE_BENEFICIAL_OWNER]:
    '/onboarding/company/customers/add-alternate-beneficial-owner',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_EDIT_ALTERNATE_BENEFICIAL_OWNER]:
    '/onboarding/company/customers/edit-alternate-beneficial-owner',
  [ROUTE_NAME.ONBOARDING_COMPANY_ABOUT_BUSINESS]:
    '/onboarding/company/about-business',
  [ROUTE_NAME.ONBOARDING_COMPANY_BUSINESS_REGULATIONS]:
    '/onboarding/company/business-regulations',
  [ROUTE_NAME.ONBOARDING_COMPANY_UPLOAD_BANK_STATEMENTS]:
    '/onboarding/company/upload-bank-statements',
  [ROUTE_NAME.ONBOARDING_TRUST]: '/onboarding/trust',
  [ROUTE_NAME.ONBOARDING_TRUST_TRADING_NAME]: '/onboarding/trust/trading-name',
  [ROUTE_NAME.ONBOARDING_TRUST_REGISTERED_OFFICE_ADDRESS]:
    '/onboarding/trust/registered-office-address',
  [ROUTE_NAME.ONBOARDING_TRUST_REVENUE]: '/onboarding/trust/revenue',
  [ROUTE_NAME.ONBOARDING_TRUST_BUSINESS_TYPE]:
    '/onboarding/trust/business-type',
  [ROUTE_NAME.ONBOARDING_TRUST_POSITION]: '/onboarding/trust/position',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS]: '/onboarding/trust/customers',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_SETTLED_SUM]:
    '/onboarding/trust/customers/settled-sum',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_SETTLOR_INFORMATION]:
    '/onboarding/trust/customers/settlor-information',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_BENEFICIARIES]:
    '/onboarding/trust/customers/beneficiaries',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_ADD_BENEFICIARIES]:
    '/onboarding/trust/customers/add-beneficiaries',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_EDIT_BENEFICIARIES]:
    '/onboarding/trust/customers/edit-beneficiaries',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_TRUSTEES]:
    '/onboarding/trust/customers/trustees',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_ADD_TRUSTEES]:
    '/onboarding/trust/customers/add-trustees',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_EDIT_TRUSTEES]:
    '/onboarding/trust/customers/edit-trustees',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_BENEFICIAL_OWNERS]:
    '/onboarding/trust/customers/beneficial-owners',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_ADD_BENEFICIAL_OWNERS]:
    '/onboarding/trust/customers/add-beneficial-owners',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_EDIT_BENEFICIAL_OWNERS]:
    '/onboarding/trust/customers/edit-beneficial-owners',
  [ROUTE_NAME.ONBOARDING_TRUST_UPLOAD_DOCUMENTS]:
    '/onboarding/trust/upload-documents',
  [ROUTE_NAME.ONBOARDING_TRUST_ABOUT_BUSINESS]:
    '/onboarding/trust/about-business',
  [ROUTE_NAME.ONBOARDING_TRUST_BUSINESS_REGULATIONS]:
    '/onboarding/trust/business-regulations',
  [ROUTE_NAME.ONBOARDING_TRUST_UPLOAD_BANK_STATEMENTS]:
    '/onboarding/trust/upload-bank-statements',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP]: '/onboarding/partnership',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_TRADING_NAME]:
    '/onboarding/partnership/trading-name',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_REGISTERED_OFFICE_ADDRESS]:
    '/onboarding/partnership/registered-office-address',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_REVENUE]:
    '/onboarding/partnership/revenue',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_BUSINESS_TYPE]:
    '/onboarding/partnership/business-type',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_POSITION]:
    '/onboarding/partnership/position',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_CUSTOMERS]:
    '/onboarding/partnership/customers',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_CUSTOMERS_PARTNERS]:
    '/onboarding/partnership/customers/partners',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_CUSTOMERS_ADD_PARTNER]:
    '/onboarding/partnership/customers/add-partner',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_CUSTOMERS_EDIT_PARTNER]:
    '/onboarding/partnership/customers/edit-partner',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_ABOUT_BUSINESS]:
    '/onboarding/partnership/about-business',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_BUSINESS_REGULATIONS]:
    '/onboarding/partnership/business-regulations',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_UPLOAD_DOCUMENTS]:
    '/onboarding/partnership/upload-documents',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_UPLOAD_BANK_STATEMENTS]:
    '/onboarding/partnership/upload-bank-statements',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION]: '/onboarding/association',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_TRADING_NAME]:
    '/onboarding/association/trading-name',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_REGISTERED_OFFICE_ADDRESS]:
    '/onboarding/association/registered-office-address',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_PLACE_OF_BUSINESS]:
    '/onboarding/association/place-of-business',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_REVENUE]:
    '/onboarding/association/revenue',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_BUSINESS_TYPE]:
    '/onboarding/association/business-type',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_POSITION]:
    '/onboarding/association/position',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_CUSTOMERS]:
    '/onboarding/association/customers',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_CUSTOMERS_CHAIR]:
    '/onboarding/association/customers/chair',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_CUSTOMERS_SECRETARY]:
    '/onboarding/association/customers/secretary',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_CUSTOMERS_TREASURER]:
    '/onboarding/association/customers/treasurer',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_ABOUT_BUSINESS]:
    '/onboarding/association/about-business',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_BUSINESS_REGULATIONS]:
    '/onboarding/association/business-regulations',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_UPLOAD_DOCUMENTS]:
    '/onboarding/association/upload-documents',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_UPLOAD_BANK_STATEMENTS]:
    '/onboarding/association/upload-bank-statements',
  [ROUTE_NAME.ONBOARDING_COMPLETE]: '/onboarding/complete',
  [ROUTE_NAME.ONBOARDING_KYC]: '/onboarding/kyc',
  [ROUTE_NAME.ONBOARDING_KYC_MEDICARE]: '/onboarding/kyc/medicare',
  [ROUTE_NAME.ONBOARDING_KYC_PASSPORT]: '/onboarding/kyc/passport',
  [ROUTE_NAME.ONBOARDING_KYC_TIME_FOR_A_SELFIE]:
    '/onboarding/kyc/time-for-a-selfie',
  [ROUTE_NAME.ONBOARDING_KYC_SELFIE_VERIFICATION]:
    '/onboarding/kyc/selfie-verification',
  [ROUTE_NAME.ONBOARDING_KYC_DRIVING_LICENCE]:
    '/onboarding/kyc/driving-licence',
  [ROUTE_NAME.ONBOARDING_KYC_RESIDENTAL_ADDRESS]:
    '/onboarding/kyc/residental-address',
  [ROUTE_NAME.ONBOARDING_KYC_YOUR_INFORMATION]:
    '/onboarding/kyc/your-information',
  [ROUTE_NAME.ONBOARDING_KYC_VERIFICATION_FAILED]:
    '/onboarding/kyc/verification-failed',
  [ROUTE_NAME.ONBOARDING_KYC_NO_IDENTIFICATION]:
    '/onboarding/kyc/no-identification',
  [ROUTE_NAME.ONBOARDING_UPLOAD_DOCUMENTS_LATER]:
    '/onboarding/upload-documents-later',
  [ROUTE_NAME.ONBOARDING_BUSINESS_REVIEW]: '/onboarding/business-review',
  [ROUTE_NAME.ONBOARDING_BUSINESS_REVIEW_UPLOAD_DOCUMENTS]:
    '/onboarding/business-review-upload-documents',
  [ROUTE_NAME.ONBOARDING_BUSINESS_REVIEW_BIOMETRICS]:
    '/onboarding/business-review/biometrics',
  [ROUTE_NAME.ONBOARDING_BUSINESS_REVIEW_BIOMETRICS_ONFIDO]:
    '/onboarding/business-review/biometrics/onfido',
  [ROUTE_NAME.ONBOARDING_PRE_ONBOARDED_QUESTIONNAIRE]:
    '/onboarding/pre-onboarding-questionnaire',
  [ROUTE_NAME.ONBOARDING_PRE_ONBOARDED_QUESTIONNAIRE_UPLOAD_LATER]:
    '/onboarding/upload-later/pre-onboarding-questionnaire',
}

export const ADMIN_KYC_ROUTES = {
  [ROUTE_NAME.PORTAL_KYC]: '/portal/kyc',

  [ROUTE_NAME.PORTAL_KYC_EXISTING_USER]: '/portal/kyc/existing-user',
  [ROUTE_NAME.PORTAL_KYC_NEW_USER]: '/portal/kyc/new-user',

  [ROUTE_NAME.PORTAL_KYC_MEDICARE]: '/portal/kyc/medicare',
  [ROUTE_NAME.PORTAL_KYC_PASSPORT]: '/portal/kyc/passport',
  [ROUTE_NAME.PORTAL_KYC_DRIVING_LICENCE]: '/portal/kyc/driving-licence',
  [ROUTE_NAME.PORTAL_KYC_NO_IDENTIFICATION]: '/portal/kyc/no-identification',
  [ROUTE_NAME.PORTAL_KYC_FINALISE]: '/portal/kyc/finalise',
  [ROUTE_NAME.PORTAL_KYC_RESIDENTAL_ADDRESS]: '/portal/kyc/residental-address',
  [ROUTE_NAME.PORTAL_KYC_REJECTED]: '/portal/kyc/rejected',
  [ROUTE_NAME.PORTAL_KYC_YOUR_INFORMATION]: '/portal/kyc/your-information',
  [ROUTE_NAME.PORTAL_KYC_YOUR_IDENTITY]: '/portal/kyc/your-identity',
  [ROUTE_NAME.PORTAL_KYC_TIME_FOR_A_SELFIE]: '/portal/kyc/time-for-a-selfie',
  [ROUTE_NAME.PORTAL_KYC_SELFIE_VERIFICATION]:
    '/portal/kyc/selfie-verification',
  [ROUTE_NAME.PORTAL_KYC_VERIFICATION_FAILED]:
    '/portal/kyc/verification-failed',
  [ROUTE_NAME.PORTAL_KYC_SUCCESS]: '/portal/kyc/success',
  [ROUTE_NAME.PORTAL_KYC_IN_REVIEW]: '/portal/kyc/in-review',
  [ROUTE_NAME.PORTAL_KYC_UPLOAD_SUCCESS]: '/portal/kyc/upload-success',
  [ROUTE_NAME.PORTAL_KYC_REVIEW_DOCUMENT_UPLOAD]:
    '/portal/kyc/review-document-upload',
  [ROUTE_NAME.PORTAL_KYC_DOCUMENT_UPLOAD]: '/portal/kyc/document-upload',
}

type RouteProps = {
  [key in ROUTE_NAME]: string
}

export const sitesSubpath = '/sites'
export const siteSubpath = '/site'
export const settingsSubpath = '/settings'

export const INVOICES_ROUTES = {
  [ROUTE_NAME.PORTAL_INVOICES]: '/portal/invoicing',
  [ROUTE_NAME.PORTAL_INVOICES_OVERVIEW]: '/portal/invoicing/overview',

  // Invoice
  [ROUTE_NAME.PORTAL_INVOICES_INVOICE_LIST]: '/portal/invoicing/invoices',
  [ROUTE_NAME.PORTAL_INVOICES_INVOICE_CREATE]: '/portal/invoicing/invoices/new',
  [ROUTE_NAME.PORTAL_INVOICES_INVOICE_EDIT]: `/portal/invoicing/invoices/:${ROUTE_PARAM_NAME.PORTAL_INVOICE_REF_NUMBER}/edit`,
  [ROUTE_NAME.PORTAL_INVOICES_INVOICE_DETAILS]: `/portal/invoicing/invoices/:${ROUTE_PARAM_NAME.PORTAL_INVOICE_REF_NUMBER}`,

  // Quotes
  [ROUTE_NAME.PORTAL_INVOICES_QUOTES]: '/portal/invoicing/quotes',
  [ROUTE_NAME.PORTAL_INVOICES_QUOTE_CREATE]: '/portal/invoicing/quote/new',

  // Recurring
  [ROUTE_NAME.PORTAL_INVOICES_RECURRING]: '/portal/invoicing/recurring',

  // Settings
  [ROUTE_NAME.PORTAL_INVOICES_SETTINGS]: '/portal/invoicing/settings',
  [ROUTE_NAME.PORTAL_INVOICES_SETTINGS_GENERAL]:
    '/portal/invoicing/settings/general',
  [ROUTE_NAME.PORTAL_INVOICES_SETTINGS_REMINDERS]:
    '/portal/invoicing/settings/reminders',
  [ROUTE_NAME.PORTAL_INVOICES_SETTINGS_RECEIPTS]:
    '/portal/invoicing/settings/receipts',
  [ROUTE_NAME.PORTAL_INVOICES_SETTINGS_CUSTOMISATION]:
    '/portal/invoicing/settings/customisation',
  [ROUTE_NAME.PORTAL_INVOICES_SETTINGS_USERS]:
    '/portal/invoicing/settings/users',
  [ROUTE_NAME.PORTAL_INVOICES_SETTINGS_USERS_DETAILS]: `/portal/invoicing/settings/users/:${ROUTE_PARAM_NAME.PORTAL_INVOICE_SETTINGS_USER_ID}`,
}

/**
 * @deprecated use ROOT property instead.
 */
export const ROUTE: RouteProps = {
  [ROUTE_NAME.LOGIN]: '/',
  [ROUTE_NAME.JOIN_WAIT_LIST]: '/join-waitlist',
  [ROUTE_NAME.LOGOUT]: '/logout',
  [ROUTE_NAME.SIGNUP]: '/signup',
  [ROUTE_NAME.FORGOT_PASSWORD]: '/forgot-password',
  [ROUTE_NAME.REGISTER_PHONE]: '/register-phone',
  [ROUTE_NAME.VALIDATE_CODE]: '/validate-code',
  [ROUTE_NAME.ACCOUNT_CREATED]: '/account-created',
  [ROUTE_NAME.EMAIL_VERIFIED]: '/email-verified',
  [ROUTE_NAME.CHANGE_EMAIL]: '/change-email',
  [ROUTE_NAME.CUSTOMER_PREFERENCES]: '/customer-preferences',
  [ROUTE_NAME.UNEXPECTED_ERROR]: '/unexpected-error',
  [ROUTE_NAME.LOGIN_ERROR_ONBOARDING_STATUS]: '/login-error-onboarding-status',
  [ROUTE_NAME.LOGIN_ERROR_ENTITY_DISABLED]: '/login-error-entity-disabled',
  [ROUTE_NAME.LOGIN_ERROR_ENTITY_ABANDONED]: '/login-error-entity-abandoned',
  [ROUTE_NAME.PIN_SUCCESS]: '/pin-success',
  [ROUTE_NAME.PORTAL]: '/portal',
  [ROUTE_NAME.PORTAL_DEMOS]: '/portal/demos',

  [ROUTE_NAME.PORTAL_OVERVIEW]: '/portal/overview',
  [ROUTE_NAME.PORTAL_HELP]: '/portal/help',
  [ROUTE_NAME.PORTAL_REFERRAL]: '/portal/referral',
  [ROUTE_NAME.SHOP]: 'https://shop.myzeller.com/ ',
  [ROUTE_NAME.PORTAL_NOTIFICATIONS]: '/portal/notifications',
  [ROUTE_NAME.PORTAL_PAYMENTS_REPORTS]: '/portal/payments/reports',

  [ROUTE_NAME.PORTAL_PAYMENTS]: '/portal/payments',
  [ROUTE_NAME.PORTAL_PAYMENTS_TRANSACTIONS]: '/portal/payments/transactions',

  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS]: '/portal/payments/settlements',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS_STATEMENTS]:
    '/portal/payments/settlements/statements',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS_ACCOUNT]:
    '/portal/payments/settlements/settlement-account',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_DETAILS]:
    '/portal/payments/settlements/settlement-account/detail',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT]:
    '/portal/payments/settlements/settlement-account/add-account',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT_SUCCESS]:
    '/portal/payments/settlements/settlement-account/add-account-success',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS_SETTINGS_EDIT_ACCOUNT]:
    '/portal/payments/settlements/settlement-account/edit-account',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_EDIT_ACCOUNT_DETAIL]:
    '/portal/payments/settlements/settlement-account/edit-account-detail',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES]: '/portal/payments/sites',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_CREATE_SITE]:
    '/portal/payments/sites/create-site',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE]: '/portal/payments/sites/site',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_GENERAL]:
    '/portal/payments/sites/site/general',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_GENERAL_EDIT]:
    '/portal/payments/sites/site/general/edit',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_DEVICES]:
    '/portal/payments/sites/site/devices',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_PAYMENTS]:
    '/portal/payments/sites/site/payments',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_RECEIPT]:
    '/portal/payments/sites/site/receipt',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_USERS]:
    '/portal/payments/sites/site/users',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_USERS_EDIT]:
    '/portal/payments/sites/site/users/edit',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_TYPE]:
    '/portal/payments/sites/site/users/create-type',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_DETAILS]:
    '/portal/payments/sites/site/users/create-details',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_APPEARANCE]:
    '/portal/payments/sites/site/appearance',

  [ROUTE_NAME.PORTAL_PAYMENTS_DEVICES]: '/portal/payments/devices',

  [ROUTE_NAME.PORTAL_SETTINGS]: '/portal/settings',
  [ROUTE_NAME.PORTAL_SETTINGS_PROFILE]: '/portal/settings/profile',
  [ROUTE_NAME.PORTAL_SETTINGS_PROFILE_PERSONAL]:
    '/portal/settings/profile/personal',
  [ROUTE_NAME.PORTAL_SETTINGS_PROFILE_PERSONAL_EDIT]:
    '/portal/settings/profile/personal/edit',
  [ROUTE_NAME.PORTAL_SETTINGS_PROFILE_SECURITY]:
    '/portal/settings/profile/security',
  [ROUTE_NAME.PORTAL_SETTINGS_PROFILE_SECURITY_CHANGE_NUMBER]:
    '/portal/settings/profile/security/change-number',
  [ROUTE_NAME.PORTAL_SETTINGS_PROFILE_SECURITY_VALIDATE_CODE]:
    '/portal/settings/profile/security/validate-code',
  [ROUTE_NAME.PORTAL_SETTINGS_PROFILE_DOCUMENTS]:
    '/portal/settings/profile/documents',
  [ROUTE_NAME.PORTAL_SETTINGS_USERS]: '/portal/settings/users',
  [ROUTE_NAME.PORTAL_SETTINGS_USERS_USER_EDIT]:
    '/portal/settings/users/user-edit',
  [ROUTE_NAME.PORTAL_SETTINGS_USERS_USER_CREATE_TYPE]:
    '/portal/settings/users/user-create-type',
  [ROUTE_NAME.PORTAL_SETTINGS_USERS_USER_CREATE_DETAILS]:
    '/portal/settings/users/user-create-details',
  [ROUTE_NAME.PORTAL_SETTINGS_FEES_AND_PRICING]:
    '/portal/settings/fees-and-pricing',

  [ROUTE_NAME.PORTAL_ACCOUNTS]: '/portal/accounts',
  [ROUTE_NAME.PORTAL_ACCOUNTS_TRANSACTIONS]: '/portal/accounts/transactions',

  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT]: '/portal/accounts/account',
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_ID]: `/portal/accounts/:${ROUTE_PARAM_NAME.PORTAL_ACCOUNT_ID}`,
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_TRANSACTIONS]:
    '/portal/accounts/account/transactions',
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_CARDS]: `/portal/accounts/:${ROUTE_PARAM_NAME.PORTAL_ACCOUNT_ID}/cards`,
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_TRANSACTIONS_TRANSACTION]:
    '/portal/accounts/account/transactions/transaction',
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_ID_TRANSACTIONS]: `/portal/accounts/:${ROUTE_PARAM_NAME.PORTAL_ACCOUNT_ID}/transactions`,
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_ID_DETAILS]: `/portal/accounts/:${ROUTE_PARAM_NAME.PORTAL_ACCOUNT_ID}/details`,
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_ID_EDIT]: `/portal/accounts/:${ROUTE_PARAM_NAME.PORTAL_ACCOUNT_ID}/edit`,
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_ID_CLOSE]: `/portal/accounts/:${ROUTE_PARAM_NAME.PORTAL_ACCOUNT_ID}/close`,

  [ROUTE_NAME.PORTAL_CARDS]: '/portal/cards',
  [ROUTE_NAME.PORTAL_CARDS_ACTIVATE]: '/portal/cards/activate',
  [ROUTE_NAME.PORTAL_CARDS_DEBIT]: '/portal/cards/debit',
  [ROUTE_NAME.PORTAL_CARDS_CORPORATE]: '/portal/cards/corporate',
  [ROUTE_NAME.PORTAL_CARDS_ID]: `/portal/cards/:${ROUTE_PARAM_NAME.PORTAL_CARDS_ID}`,

  [ROUTE_NAME.PORTAL_CONTACTS]: '/portal/contacts',
  [ROUTE_NAME.PORTAL_CONTACTS_BUSINESSES]: '/portal/contacts/businesses',
  [ROUTE_NAME.PORTAL_CONTACTS_PEOPLE]: '/portal/contacts/people',

  [ROUTE_NAME.PORTAL_CONTACTS_PEOPLE_GENERAL]:
    '/portal/contacts/people/general',
  [ROUTE_NAME.PORTAL_CONTACTS_PEOPLE_PAYMENTS]:
    '/portal/contacts/people/payments',
  [ROUTE_NAME.PORTAL_CONTACTS_PEOPLE_ACCOUNTS]:
    '/portal/contacts/people/accounts',
  [ROUTE_NAME.PORTAL_CONTACTS_PEOPLE_INVOICES]:
    '/portal/contacts/people/invoices',
  [ROUTE_NAME.PORTAL_CONTACTS_PEOPLE_INVOICES_DETAILS]: `/portal/contacts/people/invoices/:${ROUTE_PARAM_NAME.PORTAL_INVOICE_REF_NUMBER}`,
  [ROUTE_NAME.PORTAL_CONTACTS_BUSINESSES_GENERAL]:
    '/portal/contacts/businesses/general',
  [ROUTE_NAME.PORTAL_CONTACTS_BUSINESSES_PAYMENTS]:
    '/portal/contacts/businesses/payments',
  [ROUTE_NAME.PORTAL_CONTACTS_BUSINESSES_ACCOUNTS]:
    '/portal/contacts/businesses/accounts',
  [ROUTE_NAME.PORTAL_CONTACTS_BUSINESSES_INVOICES]:
    '/portal/contacts/businesses/invoices',
  [ROUTE_NAME.PORTAL_CONTACTS_BUSINESSES_INVOICES_DETAILS]: `/portal/contacts/businesses/invoices/:${ROUTE_PARAM_NAME.PORTAL_INVOICE_REF_NUMBER}`,

  [ROUTE_NAME.MY_ZELLER_COM]: 'https://myzeller.com',
  [ROUTE_NAME.PORTAL_ACCOUNTS_TRANSFER_CREATE]: '/portal/accounts/transfer',
  ...ONBOARDING_ROUTES,
  ...ADMIN_KYC_ROUTES,
  ...INVOICES_ROUTES,
}

export const getContactPaths = (contactId: string, path: string) => ({
  path: `/portal/contacts/${path}/${contactId}`,
  relative: contactId,
  GENERAL: {
    path: `/portal/contacts/${path}/${contactId}/general`,
    relative: 'general',
  },
  ACCOUNTS: {
    path: `/portal/contacts/${path}/${contactId}/accounts`,
    relative: 'accounts',
  },
  PAYMENTS: {
    path: `/portal/contacts/${path}/${contactId}/payments`,
    relative: 'payments',
  },
  INVOICES: {
    path: `/portal/contacts/${path}/${contactId}/invoices`,
    relative: 'invoices',
    INVOICE: (
      referenceNumber = `:${ROUTE_PARAM_NAME.PORTAL_INVOICE_REF_NUMBER}`
    ) => ({
      path: `/portal/contacts/${path}/${contactId}/invoices/${referenceNumber}`,
      relative: referenceNumber,
    }),
  },
})

// TICKET: https://npco-dev.atlassian.net/browse/BANK-561
// Need to find a better, extensible, non redundant structure for routes.
export const ROOT = {
  path: '/',
  relative: '',
  JOIN_WAITLIST: {
    path: '/join-waitlist',
    relative: 'join-waitlist',
  },
  CUSTOMER_PREFERENCES: {
    path: `/customer-preferences`,
    relative: 'customer-preferences',
  },
  EMAIL_VERIFIED: {
    path: '/email-verified',
    relative: 'email-verified',
  },
  CHANGE_EMAIL: {
    path: '/change-email',
    relative: 'change-email',
  },
  UNEXPECTED_ERROR: {
    path: '/unexpected-error',
    relative: 'unexpected-error',
  },
  ACCOUNT_CREATED: {
    path: '/account-created',
    relative: 'account-created',
  },
  FORGOT_PASSWORD: {
    path: '/forgot-password',
    relative: 'forgot-password',
  },
  PIN_SUCCESS: {
    path: '/pin-success',
    relative: 'pin-success',
  },
  REGISTER_PHONE: {
    path: '/register-phone',
    relative: 'register-phone',
  },
  VALIDATE_CODE: { path: '/validate-code' },
  ONBOARDING: {
    path: '/onboarding',
    relative: 'onboarding',
    ...ONBOARDING_SHOP,

    WELCOME_BACK: {
      path: '/onboarding/welcome-back',
      relative: 'welcome-back',
    },
    TRY_AGAIN: {
      path: '/onboarding/try-again',
      relative: 'try-again',
    },
    YOUR_BUSINESS_INFORMATION: {
      path: '/onboarding/your-business-information',
      relative: 'your-business-information',
    },
    COMPANY_SEARCH: {
      path: '/onboarding/company-search',
      relative: 'company-search',
    },
    COMPANY_INFORMATION: {
      path: '/onboarding/company-information',
      relative: 'company-information',
    },
    BUSINESS_INFORMATION: {
      path: '/onboarding/business-information',
      relative: 'business-information',
    },
    UPLOAD_DOCUMENTS_LATER: {
      path: '/onboarding/upload-documents-later',
      relative: 'upload-documents-later',
    },
    BUSINESS_REVIEW: {
      path: '/onboarding/business-review',
      relative: 'business-review',
      BIOMETRICS: {
        path: '/onboarding/business-review/biometrics',
        relative: 'biometrics',
        ONFIDO: {
          path: '/onboarding/business-review/biometrics/onfido',
          relative: 'onfido',
        },
      },
    },
    BUSINESS_REVIEW_UPLOAD_DOCUMENTS: {
      path: '/onboarding/business-review-upload-documents',
      relative: 'business-review-upload-documents',
    },
    PRE_ONBOARDING_QUESTIONNAIRE: {
      path: '/onboarding/pre-onboarding-questionnaire',
      relative: 'pre-onboarding-questionnaire',
    },
    UPLOAD_LATER: {
      path: '/onboarding/upload-later',
      relative: 'upload-later',
      PRE_ONBOARDING_QUESTIONNAIRE: {
        path: '/onboarding/upload-later/pre-onboarding-questionnaire',
        relative: 'pre-onboarding-questionnaire',
      },
    },
    ASSOCIATION: {
      path: '/onboarding/association',
      relative: 'association',
      TRADING_NAME: {
        path: '/onboarding/association/trading-name',
        relative: 'trading-name',
      },
      REGISTERED_OFFICE_ADDRESS: {
        path: '/onboarding/association/registered-office-address',
        relative: 'registered-office-address',
      },
      PLACE_OF_BUSINESS: {
        path: '/onboarding/association/place-of-business',
        relative: 'place-of-business',
      },
      REVENUE: {
        path: '/onboarding/association/revenue',
        relative: 'revenue',
      },
      BUSINESS_TYPE: {
        path: '/onboarding/association/business-type',
        relative: 'business-type',
      },
      POSITION: {
        path: '/onboarding/association/position',
        relative: 'position',
      },
      CUSTOMERS: {
        path: '/onboarding/association/customers',
        relative: 'customers',
        CHAIR: {
          path: '/onboarding/association/customers/chair',
          relative: 'chair',
        },
        SECRETARY: {
          path: '/onboarding/association/customers/secretary',
          relative: 'secretary',
        },
        TREASURER: {
          path: '/onboarding/association/customers/treasurer',
          relative: 'treasurer',
        },
      },
      ABOUT_BUSINESS: {
        path: '/onboarding/association/about-business',
        relative: 'about-business',
      },
      BUSINESS_REGULATIONS: {
        path: '/onboarding/association/business-regulations',
        relative: 'business-regulations',
      },
      UPLOAD_DOCUMENTS: {
        path: '/onboarding/association/upload-documents',
        relative: 'upload-documents',
      },
      UPLOAD_BANK_STATEMENTS: {
        path: '/onboarding/association/upload-bank-statements',
        relative: 'upload-bank-statements',
      },
    },
    COMPANY: {
      path: '/onboarding/company',
      relative: 'company',
      ABOUT_BUSINESS: {
        path: '/onboarding/company/about-business',
        relative: 'about-business',
      },
      BUSINESS_REGULATIONS: {
        path: '/onboarding/company/business-regulations',
        relative: 'business-regulations',
      },
      BUSINESS_TYPE: {
        path: '/onboarding/company/business-type',
        relative: 'business-type',
      },
      CUSTOMERS: {
        path: '/onboarding/company/customers',
        relative: 'customers',
        DIRECTORS: {
          path: '/onboarding/company/customers/directors',
          relative: 'directors',
        },
        ADD_DIRECTOR: {
          path: '/onboarding/company/customers/add-director',
          relative: 'add-director',
        },
        EDIT_DIRECTOR: {
          path: '/onboarding/company/customers/edit-director',
          relative: 'edit-director',
        },
        BENEFICIAL_OWNERS: {
          path: '/onboarding/company/customers/beneficial-owners',
          relative: 'beneficial-owners',
        },
        ADD_BENEFICIAL_OWNER: {
          path: '/onboarding/company/customers/add-beneficial-owner',
          relative: 'add-beneficial-owner',
        },
        EDIT_BENEFICIAL_OWNER: {
          path: '/onboarding/company/customers/edit-beneficial-owner',
          relative: 'edit-beneficial-owner',
        },
        ALTERNATE_BENEFICIAL_OWNERS: {
          path: '/onboarding/company/customers/alternate-beneficial-owners',
          relative: 'alternate-beneficial-owners',
        },
        ADD_ALTERNATE_BENEFICIAL_OWNER: {
          path: '/onboarding/company/customers/add-alternate-beneficial-owner',
          relative: 'add-alternate-beneficial-owner',
        },
        EDIT_ALTERNATE_BENEFICIAL_OWNER: {
          path: '/onboarding/company/customers/edit-alternate-beneficial-owner',
          relative: 'edit-alternate-beneficial-owner',
        },
      },
      NAME: {
        path: '/onboarding/company/name',
        relative: 'name',
      },
      PLACE_OF_BUSINESS: {
        path: '/onboarding/company/place-of-business',
        relative: 'place-of-business',
      },
      POSITION: {
        path: '/onboarding/company/position',
        relative: 'position',
      },
      REGISTERED_OFFICE_ADDRESS: {
        path: '/onboarding/company/registered-office-address',
        relative: 'registered-office-address',
      },
      REVENUE: {
        path: '/onboarding/company/revenue',
        relative: 'revenue',
      },
      TRADING_NAME: {
        path: '/onboarding/company/trading-name',
        relative: 'trading-name',
      },
      UPLOAD_BANK_STATEMENTS: {
        path: '/onboarding/company/upload-bank-statements',
        relative: 'upload-bank-statements',
      },
    },
    INDIVIDUAL: {
      path: '/onboarding/individual',
      relative: 'individual',
      TRADING_NAME: {
        path: '/onboarding/individual/trading-name',
        relative: 'trading-name',
      },
      REVENUE: {
        path: '/onboarding/individual/revenue',
        relative: 'revenue',
      },
      BUSINESS_TYPE: {
        path: '/onboarding/individual/business-type',
        relative: 'business-type',
      },
      ABOUT_BUSINESS: {
        path: '/onboarding/individual/about-business',
        relative: 'about-business',
      },
      BUSINESS_REGULATIONS: {
        path: '/onboarding/individual/business-regulations',
        relative: 'business-regulations',
      },
      UPLOAD_BANK_STATEMENTS: {
        path: '/onboarding/individual/upload-bank-statements',
        relative: 'upload-bank-statements',
      },
    },
    INDIVIDUAL_NO_ABN: {
      path: '/onboarding/individual-no-abn',
      relative: 'individual-no-abn',
      PRINCIPAL_PLACE_OF_BUSINESS: {
        path: '/onboarding/individual-no-abn/principal-place',
        relative: 'principal-place',
      },
      YOUR_BUSINESS_INFORMATION: {
        path: '/onboarding/individual-no-abn/your-business-information',
        relative: 'your-business-information',
      },
      REVENUE: {
        path: '/onboarding/individual-no-abn/revenue',
        relative: 'revenue',
      },
      BUSINESS_TYPE: {
        path: '/onboarding/individual-no-abn/business-type',
        relative: 'business-type',
      },
      ABOUT_BUSINESS: {
        path: '/onboarding/individual-no-abn/about-business',
        relative: 'about-business',
      },
      TRADING_NAME: {
        path: '/onboarding/individual-no-abn/trading-name',
        relative: 'trading-name',
      },
      BUSINESS_REGULATIONS: {
        path: '/onboarding/individual-no-abn/business-regulations',
        relative: 'business-regulations',
      },
      UPLOAD_BANK_STATEMENTS: {
        path: '/onboarding/individual-no-abn/upload-bank-statements',
        relative: 'upload-bank-statements',
      },
    },
    GOVERNMENT: {
      path: '/onboarding/government',
      relative: 'government',
      PLACE_OF_BUSINESS: {
        path: '/onboarding/government/place-of-business',
        relative: 'place-of-business',
      },
      REVENUE: {
        path: '/onboarding/government/revenue',
        relative: 'revenue',
      },
      BUSINESS_TYPE: {
        path: '/onboarding/government/business-type',
        relative: 'business-type',
      },
      YOUR_ROLE: {
        path: '/onboarding/government/your-role',
        relative: 'your-role',
      },
      ABOUT_BUSINESS: {
        path: '/onboarding/government/about-business',
        relative: 'about-business',
      },
      BUSINESS_REGULATIONS: {
        path: '/onboarding/government/business-regulations',
        relative: 'business-regulations',
      },
    },
    KYC: {
      path: '/onboarding/kyc',
      relative: 'kyc',
      YOUR_INFORMATION: {
        path: '/onboarding/kyc/your-information',
        relative: 'your-information',
      },
      MEDICARE: {
        path: '/onboarding/kyc/medicare',
        relative: 'medicare',
      },
      PASSPORT: {
        path: '/onboarding/kyc/passport',
        relative: 'passport',
      },
      RESIDENTIAL_ADDRESS: {
        // Residential is misspelled in the route name. Will fix it later.
        path: '/onboarding/kyc/residental-address',
      },
      DRIVING_LICENCE: {
        path: '/onboarding/kyc/driving-licence',
        relative: 'driving-licence',
      },
      NO_IDENTIFICATION: {
        path: '/onboarding/kyc/no-identification',
        relative: 'no-identification',
      },
      VERIFICATION_FAILED: {
        path: '/onboarding/kyc/verification-failed',
        relative: 'verification-failed',
      },
      TIME_FOR_A_SELFIE: {
        path: '/onboarding/kyc/time-for-a-selfie',
        relative: 'time-for-a-selfie',
      },
      SELFIE_VERIFICATION: {
        path: '/onboarding/kyc/selfie-verification',
        relative: 'selfie-verification',
      },
    },
    PARTNERSHIP: {
      path: '/onboarding/partnership',
      relative: 'partnership',
      TRADING_NAME: {
        path: '/onboarding/partnership/trading-name',
        relative: 'trading-name',
      },
      REGISTERED_OFFICE_ADDRESS: {
        path: '/onboarding/partnership/registered-office-address',
        relative: 'registered-office-address',
      },
      REVENUE: {
        path: '/onboarding/partnership/revenue',
        relative: 'revenue',
      },
      BUSINESS_TYPE: {
        path: '/onboarding/partnership/business-type',
        relative: 'business-type',
      },
      POSITION: {
        path: '/onboarding/partnership/position',
        relative: 'position',
      },
      CUSTOMERS: {
        path: '/onboarding/partnership/customers',
        relative: 'customers',
        PARTNERS: {
          path: '/onboarding/partnership/customers/partners',
          relative: 'partners',
        },
        ADD_PARTNER: {
          path: '/onboarding/partnership/customers/add-partner',
          relative: 'add-partner',
        },
        EDIT_PARTNER: {
          path: '/onboarding/partnership/customers/edit-partner',
          relative: 'edit-partner',
        },
      },
      ABOUT_BUSINESS: {
        path: '/onboarding/partnership/about-business',
        relative: 'about-business',
      },
      BUSINESS_REGULATIONS: {
        path: '/onboarding/partnership/business-regulations',
        relative: 'business-regulations',
      },
      UPLOAD_DOCUMENTS: {
        path: '/onboarding/partnership/upload-documents',
        relative: 'upload-documents',
      },
      UPLOAD_BANK_STATEMENTS: {
        path: '/onboarding/partnership/upload-bank-statements',
        relative: 'upload-bank-statements',
      },
    },
    TRUST: {
      path: '/onboarding/trust',
      relative: 'trust',
      TRADING_NAME: {
        path: '/onboarding/trust/trading-name',
        relative: 'trading-name',
      },
      REGISTERED_OFFICE_ADDRESS: {
        path: '/onboarding/trust/registered-office-address',
        relative: 'registered-office-address',
      },
      REVENUE: {
        path: '/onboarding/trust/revenue',
        relative: 'revenue',
      },
      BUSINESS_TYPE: {
        path: '/onboarding/trust/business-type',
        relative: 'business-type',
      },
      POSITION: {
        path: '/onboarding/trust/position',
        relative: 'position',
      },
      CUSTOMERS: {
        path: '/onboarding/trust/customers',
        relative: 'customers',
        SETTLED_SUM: {
          path: '/onboarding/trust/customers/settled-sum',
          relative: 'settled-sum',
        },
        SETTLOR_INFORMATION: {
          path: '/onboarding/trust/customers/settlor-information',
          relative: 'settlor-information',
        },
        BENEFICIAL_OWNERS: {
          path: '/onboarding/trust/customers/beneficial-owners',
          relative: 'beneficial-owners',
        },
        ADD_BENEFICIAL_OWNERS: {
          path: '/onboarding/trust/customers/add-beneficial-owners',
          relative: 'add-beneficial-owners',
        },
        EDIT_BENEFICIAL_OWNERS: {
          path: '/onboarding/trust/customers/edit-beneficial-owners',
          relative: 'edit-beneficial-owners',
        },
        BENEFICIARIES: {
          path: '/onboarding/trust/customers/beneficiaries',
          relative: 'beneficiaries',
        },
        ADD_BENEFICIARIES: {
          path: '/onboarding/trust/customers/add-beneficiaries',
          relative: 'add-beneficiaries',
        },
        EDIT_BENEFICIARIES: {
          path: '/onboarding/trust/customers/edit-beneficiaries',
          relative: 'edit-beneficiaries',
        },
        TRUSTEES: {
          path: '/onboarding/trust/customers/trustees',
          relative: 'trustees',
        },
        ADD_TRUSTEES: {
          path: '/onboarding/trust/customers/add-trustees',
          relative: 'add-trustees',
        },
        EDIT_TRUSTEES: {
          path: '/onboarding/trust/customers/edit-trustees',
          relative: 'edit-trustees',
        },
      },
      ABOUT_BUSINESS: {
        path: '/onboarding/trust/about-business',
        relative: 'about-business',
      },
      BUSINESS_REGULATIONS: {
        path: '/onboarding/trust/business-regulations',
        relative: 'business-regulations',
      },
      UPLOAD_DOCUMENTS: {
        path: '/onboarding/trust/upload-documents',
        relative: 'upload-documents',
      },
      UPLOAD_BANK_STATEMENTS: {
        path: '/onboarding/trust/upload-bank-statements',
        relative: 'upload-bank-statements',
      },
    },
    PRE_ONBOARDED_QUESTIONNAIRE: {
      path: '/onboarding/pre-onboarding-questionnaire',
      relative: 'pre-onboarding-questionnaire',
    },
    COMPLETE: {
      path: '/onboarding/complete',
      relative: 'complete',
    },
  },
  PORTAL: {
    path: '/portal',
    ...PORTAL_SHOP,
    PAYMENTS: {
      path: '/portal/payments',
      relative: 'payments',
      SIM: {
        customPageName: 'Sim',
        path: '/portal/payments/sim',
        relative: 'sim',
        BILLING_HISTORY: {
          customPageName: 'SimBillingHistory',
          path: '/portal/payments/sim/billing-history',
          relative: 'billing-history',
          STATEMENTS: {
            customPageName: 'SimBillingHistoryStatements',
            path: '/portal/payments/sim/billing-history/statements',
            relative: 'statements',
          },
        },
        BILLING_ACCOUNT: {
          customPageName: 'SimBillingAccount',
          path: '/portal/payments/sim/billing-account',
          relative: 'billing-account',
        },
      },
      REPORTS: {
        customPageName: 'PaymentsReports',
        path: '/portal/payments/reports',
        relative: 'reports',
      },
      DEVICES: {
        customPageName: 'Devices',
        path: '/portal/payments/devices',
        relative: 'devices',
      },
      SETTLEMENTS: {
        customPageName: 'Settlements',
        path: '/portal/payments/settlements',
        relative: 'settlements',
      },
      DEPOSITS: {
        path: '/portal/payments/settlements',
        relative: 'settlements',
        STATEMENTS: {
          path: '/portal/payments/settlements/statements',
          relative: 'statements',
        },
        SETTINGS: {
          path: '/portal/payments/settlements/settlement-account',
          relative: 'settlement-account',
        },
      },
      SITES: {
        path: '/portal/payments/sites',
        relative: 'sites',
        CREATE_SITE: {
          path: '/portal/payments/sites/create-site',
          relative: 'create-site',
        },
        SITE: {
          customPageName: 'Sites',
          path: '/portal/payments/sites/site',
          relative: 'site',
          GENERAL: {
            customPageName: 'SiteGeneral',
            path: '/portal/payments/sites/site/general',
            relative: 'general',
            EDIT: {
              path: '/portal/payments/sites/site/general/edit',
              relative: 'edit',
            },
          },
          USERS: {
            customPageName: 'SiteUsers',
            path: '/portal/payments/sites/site/users',
            relative: 'users',
            EDIT: {
              customPageName: 'SiteUsersEdit',
              path: '/portal/payments/sites/site/users/edit',
              relative: 'edit',
            },
            CREATE_TYPE: {
              customPageName: 'SiteUsersCreateType',
              path: '/portal/payments/sites/site/users/create-type',
              relative: 'create-type',
            },
            CREATE_DETAILS: {
              customPageName: 'SiteUsersCreateDetails',
              path: '/portal/payments/sites/site/users/create-details',
              relative: 'create-details',
            },
          },
          APPEARANCE: {
            customPageName: 'SiteAppearance',
            path: '/portal/payments/sites/site/appearance',
            relative: 'appearance',
          },
          DEVICES: {
            path: '/portal/payments/sites/site/devices',
            relative: 'devices',
          },
          PAYMENTS: {
            path: '/portal/payments/sites/site/payments',
            relative: 'payments',
          },
          RECEIPT: {
            path: '/portal/payments/sites/site/receipt',
            relative: 'receipt',
          },
        },
      },
    },
    ONBOARDING: {
      customPageName: 'Onboarding',
      path: '/onboarding',
      relative: 'onboarding',
      INDIVIDUAL_NO_ABN: {
        path: '/onboarding/individual-no-abn',
        relative: 'individual-no-abn',
      },
    },
    OVERVIEW: {
      path: '/portal/overview',
      relative: 'overview',
      PAYMENTS: {
        path: '/portal/overview/payments',
        relative: 'payments',
      },
      FINANCE: {
        path: '/portal/overview/finance',
        relative: 'finance',
      },
    },
    NOTIFICATIONS: {
      customPageName: 'Notifications',
      path: '/portal/notifications',
      relative: 'notifications',
      PREFERENCES: {
        path: '/portal/notifications/preferences',
        relative: 'preferences',
        PAYMENTS: {
          path: '/portal/notifications/preferences/payments',
          relative: 'payments',
        },
        ACCOUNTS: {
          path: '/portal/notifications/preferences/accounts',
          relative: 'accounts',
        },
        CARDS: {
          path: '/portal/notifications/preferences/cards',
          relative: 'cards',
        },
        USERS: {
          path: '/portal/notifications/preferences/users',
          relative: 'users',
        },
      },
    },
    TRANSACTIONS: {
      path: '/portal/payments/transactions',
      relative: 'transactions',
      SPLITPAYMENT: {
        path: '/portal/payments/transactions/splitpayment',
        relative: 'splitpayment',
        SPLIT_PAYMENT_ID: (
          splitPaymentId = `:${ROUTE_PARAM_NAME.PORTAL_SPLIT_PAYMENT_ID}`
        ) => ({
          path: `/portal/payments/transactions/splitpayment/${splitPaymentId}`,
          relative: splitPaymentId,
        }),
      },
    },
    SITES: {
      path: '/portal/payments/sites',
      relative: 'sites',
      SITE: {
        path: '/portal/payments/sites/site',
        relative: 'site',
        GENERAL: {
          path: '/portal/payments/sites/site/general',
          relative: 'general',
          EDIT: {
            path: '/portal/payments/sites/site/general/edit',
            relative: 'edit',
          },
        },
        DEVICES: {
          path: '/portal/payments/sites/site/devices',
          relative: 'devices',
        },
        PAYMENTS: {
          path: '/portal/payments/sites/site/payments',
          relative: 'payments',
        },
        RECEIPT: {
          path: '/portal/payments/sites/site/receipt',
          relative: 'receipt',
        },
        USERS: {
          path: '/portal/payments/sites/site/users',
          relative: 'users',
          EDIT: {
            path: '/portal/payments/sites/site/users/edit',
            relative: 'edit',
          },
        },
        APPEARANCE: {
          path: '/portal/payments/sites/site/appearance',
          relative: 'appearance',
        },
      },
    },
    ACCOUNTS: {
      path: '/portal/accounts',
      relative: 'accounts',
      ACTIVATE_SAVINGS: {
        path: '/portal/accounts/activate-savings',
        relative: 'activate-savings',
      },
      TRANSACTIONS: {
        path: '/portal/accounts/transactions',
        relative: 'transactions',
      },
      TRANSFER: {
        path: '/portal/accounts/transfer',
        relative: 'transfer',
        SAVE: {
          path: '/portal/accounts/transfer/save',
          relative: 'save',
        },
      },
    },
    CARDS: {
      path: '/portal/cards',
      relative: 'cards',
      DEBIT: {
        path: '/portal/cards/debit',
        relative: 'debit',
      },
      CORPORATE: {
        path: '/portal/cards/corporate',
        relative: 'corporate',
        NEW: {
          path: '/portal/cards/corporate/new',
          relative: 'new',
        },
      },
      ACTIVATE: {
        path: '/portal/cards/activate',
        relative: 'activate',
      },
      SETTINGS: {
        path: '/portal/cards/settings',
        relative: 'settings',
        OUTSTANDING_EXPENSES: {
          path: '/portal/cards/settings/outstanding-expenses',
          relative: 'outstanding-expenses',
        },
      },
      CARD: (cardId = `:${ROUTE_PARAM_NAME.PORTAL_CARDS_ID}`) => ({
        path: `/portal/cards/${cardId}`,
        relative: cardId,
      }),
    },
    INVOICING: {
      path: '/portal/invoicing',
      relative: 'invoicing',
      INVOICES: {
        path: '/portal/invoicing/invoices',
        relative: 'invoices',
        INVOICE: (
          referenceNumber = `:${ROUTE_PARAM_NAME.PORTAL_INVOICE_REF_NUMBER}`
        ) => ({
          path: `/portal/invoicing/invoices/${referenceNumber}`,
          relative: referenceNumber,
          EDIT: {
            path: `/portal/invoicing/invoices/${referenceNumber}/edit`,
            relative: `${referenceNumber}/edit`,
          },
        }),
        NEW: {
          path: '/portal/invoicing/invoices/new',
          relative: 'new',
        },
        SETTINGS: {
          path: '/portal/invoicing/settings',
          relative: 'settings',
          GENERAL: {
            path: '/portal/invoicing/settings/general',
            relative: 'general',
          },
          REMINDERS: {
            path: '/portal/invoicing/settings/reminders',
            relative: 'reminders',
          },
          RECEIPTS: {
            path: '/portal/invoicing/settings/receipts',
            relative: 'receipts',
          },
          USERS: {
            path: '/portal/invoicing/settings/users',
            relative: 'users',
            USER: (
              userId = `:${ROUTE_PARAM_NAME.PORTAL_INVOICE_SETTINGS_USER_ID}`
            ) => ({
              path: `/portal/invoicing/settings/users/${userId}`,
              relative: userId,
            }),
          },
          CUSTOMISATION: {
            customPageName: 'InvoicingSettingsCustomisation',
            path: '/portal/invoicing/settings/customisation',
            relative: 'customisation',
          },
        },
      },
    },
    ITEM_MANAGEMENT: {
      path: '/portal/payments/item-management',
      relative: 'item-management',
      ITEMS: {
        customPageName: 'ItemManagementItems',
        path: '/portal/payments/item-management/items',
        relative: 'items',
        NEW: {
          path: '/portal/payments/item-management/items/new',
          relative: 'new',
        },
        ITEM: (itemId = `:${ROUTE_PARAM_NAME.PORTAL_ITEM_ID}`) => ({
          customPageName: 'ItemManagementItemsDetails',
          path: `/portal/payments/item-management/items/${itemId}`,
          relative: itemId,
          EDIT_NOTES: {
            path: `/portal/payments/item-management/items/${itemId}/notes`,
            relative: `notes`,
          },
          EDIT_DETAILS: {
            path: `/portal/payments/item-management/items/${itemId}/edit`,
            relative: `edit`,
          },
        }),
        IMPORT: {
          path: '/portal/payments/item-management/items/import',
          relative: 'import',
        },
        DELETE_ALL: {
          path: '/portal/payments/item-management/items/delete-all',
          relative: 'delete-all',
        },
      },
      CATEGORIES: {
        customPageName: 'ItemManagementCategories',
        path: '/portal/payments/item-management/categories',
        relative: 'categories',
        CATEGORY: (
          categoryId = `:${ROUTE_PARAM_NAME.PORTAL_ITEM_CATEGORY_ID}`
        ) => ({
          customPageName: 'ItemManagementCategoriesDetails',
          path: `/portal/payments/item-management/categories/${categoryId}`,
          relative: categoryId,
          EDIT: {
            path: `/portal/payments/item-management/categories/${categoryId}/edit`,
            relative: 'edit',
          },
          ITEMS: {
            path: `/portal/payments/item-management/categories/${categoryId}/items`,
            relative: 'items',
          },
        }),
        NEW: {
          path: '/portal/payments/item-management/categories/new',
          relative: 'new',
        },
      },

      SETTINGS: {
        customPageName: 'ItemManagementSettings',
        path: '/portal/payments/item-management/settings',
        relative: 'settings',
      },
    },
    ITEMS: {
      path: '/portal/items',
      relative: 'items',
      customPageName: 'All Items',
      CREATE: {
        path: '/portal/items/create',
        relative: 'create',
        customPageName: 'ItemsCreate',
      },
      IMPORT: {
        path: '/portal/items/import',
        relative: 'import',
        customPageName: 'Import Items',
      },
      EDIT: (itemId = `:${ROUTE_PARAM_NAME.PORTAL_ITEM_ID}`) => ({
        customPageName: 'ItemsEdit',
        path: `/portal/items/${itemId}`,
        relative: itemId,
      }),
      CATEGORIES: {
        path: '/portal/items/categories',
        relative: 'categories',
        customPageName: 'ItemsCategories',
        CREATE: {
          path: '/portal/items/categories/create',
          relative: 'create',
          customPageName: 'ItemsCategoryCreate',
        },
        EDIT: (
          categoryId = `:${ROUTE_PARAM_NAME.PORTAL_ITEM_CATEGORY_ID}`
        ) => ({
          customPageName: 'ItemsCategoryEdit',
          path: `/portal/items/categories/${categoryId}`,
          relative: categoryId,
        }),
      },
      ATTRIBUTE_SETS: {
        path: '/portal/items/attribute-sets',
        relative: 'attribute-sets',
        customPageName: 'ItemsAttributeSets',
        CREATE: {
          path: '/portal/items/attribute-sets/create',
          relative: 'create',
          customPageName: 'ItemsAttributeSetCreate',
        },
        EDIT: (
          attributeSetId = `:${ROUTE_PARAM_NAME.PORTAL_ATTRIBUTE_SET_ID}`
        ) => ({
          customPageName: 'ItemsAttributeSetEdit',
          path: `/portal/items/attribute-sets/${attributeSetId}`,
          relative: attributeSetId,
        }),
      },
      DISCOUNTS: {
        path: '/portal/items/discounts',
        relative: 'discounts',
        customPageName: 'ItemsDiscounts',
        CREATE: {
          path: '/portal/items/discounts/create',
          relative: 'create',
          customPageName: 'ItemsDiscountsCreate',
        },
        EDIT: (discountId = `:${ROUTE_PARAM_NAME.PORTAL_DISCOUNT_ID}`) => ({
          customPageName: 'ItemsDiscountsEdit',
          path: `/portal/items/discounts/${discountId}`,
          relative: discountId,
        }),
      },
      MODIFIER_SETS: {
        path: '/portal/items/modifier-sets',
        relative: 'modifier-sets',
        customPageName: 'ItemsModifierSets',
        CREATE: {
          path: '/portal/items/modifier-sets/create',
          relative: 'create',
          customPageName: 'ItemsModifierSetCreate',
        },
        EDIT: (
          modifierSetId = `:${ROUTE_PARAM_NAME.PORTAL_MODIFIER_SET_ID}`
        ) => ({
          path: `/portal/items/modifier-sets/${modifierSetId}`,
          relative: modifierSetId,
          customPageName: 'ItemsModifierSetEdit',
        }),
      },
      SETTINGS: {
        path: '/portal/items/settings',
        relative: 'settings',
        customPageName: 'ItemsSettings',
      },
    },
    CONTACTS: {
      path: '/portal/contacts',
      relative: 'contacts',
      BUSINESSES: {
        path: '/portal/contacts/businesses',
        relative: 'businesses',
        CONTACT: (contactId = `:${ROUTE_PARAM_NAME.PORTAL_CONTACT_ID}`) =>
          getContactPaths(contactId, 'businesses'),
      },
      PEOPLE: {
        path: '/portal/contacts/people',
        relative: 'people',
        CONTACT: (contactId = `:${ROUTE_PARAM_NAME.PORTAL_CONTACT_ID}`) =>
          getContactPaths(contactId, 'people'),
      },
    },
    KYC: {
      path: '/portal/kyc',
      relative: 'kyc',
      EXISTING_USER: {
        path: '/portal/kyc/existing-user',
        relative: 'existing-user',
      },
      NEW_USER: {
        path: '/portal/kyc/new-user',
        relative: 'new-user',
      },
      YOUR_INFORMATION: {
        path: '/portal/kyc/your-information',
        relative: 'your-information',
      },
      YOUR_IDENTITY: {
        path: '/portal/kyc/your-identity',
        relative: 'your-identity',
      },
      DRIVING_LICENCE: {
        path: '/portal/kyc/driving-licence',
        relative: 'driving-licence',
      },
      PASSPORT: {
        path: '/portal/kyc/passport',
        relative: 'passport',
      },
      MEDICARE: {
        path: '/portal/kyc/medicare',
        relative: 'medicare',
      },
      NO_IDENTIFICATION: {
        path: '/portal/kyc/no-identification',
        relative: 'no-identification',
      },
      TIME_FOR_A_SELFIE: {
        path: '/portal/kyc/time-for-a-selfie',
        relative: 'time-for-a-selfie',
      },
      SELFIE_VERIFICATION: {
        path: '/portal/kyc/selfie-verification',
        relative: 'selfie-verification',
      },
      VERIFICATION_FAILED: {
        path: '/portal/kyc/verification-failed',
        relative: 'verification-failed',
      },
      FINALISE: {
        path: '/portal/kyc/finalise',
        relative: 'finalise',
      },
      DOCUMENT_UPLOAD: {
        path: '/portal/kyc/document-upload',
        relative: 'document-upload',
      },
      IN_REVIEW: {
        path: '/portal/kyc/in-review',
        relative: 'in-review',
      },
      SUCCESS: {
        path: '/portal/kyc/success',
        relative: 'success',
      },
      REJECTED: {
        path: '/portal/kyc/rejected',
        relative: 'rejected',
      },
      UPLOAD_SUCCESS: {
        path: '/portal/kyc/upload-success',
        relative: 'upload-success',
      },
      REVIEW_DOCUMENT_UPLOAD: {
        path: '/portal/kyc/review-document-upload',
        relative: 'review-document-upload',
      },
    },
    SETTINGS: {
      path: '/portal/settings',
      relative: 'settings',
      customPageName: 'Settings',
      FEES_AND_PRICING: {
        path: '/portal/settings/fees-and-pricing',
        relative: 'fees-and-pricing',
        customPageName: 'SettingsFeesAndPricing',
      },
      CONNECTIONS: {
        path: '/portal/settings/connections',
        relative: 'connections',
        customPageName: 'SettingsConnections',
        ORACLE_POS: {
          path: '/portal/settings/connections/oracle-pos',
          relative: 'oracle-pos',
          customPageName: 'OraclePos',
          MANAGE: {
            path: '/portal/settings/connections/oracle-pos/manage',
            relative: 'manage',
            customPageName: 'OraclePosManage',
            GENERAL: {
              path: '/portal/settings/connections/oracle-pos/manage/general',
              customPageName: 'OraclePosManageGeneral',
              relative: 'general',
            },
            PAIRING: {
              path: '/portal/settings/connections/oracle-pos/manage/pairing',
              customPageName: 'OraclePosManagePairing',
              relative: 'pairing',
            },
          },
        },
        XERO_PAYMENT_SERVICES: {
          path: '/portal/settings/connections/xero-payment-services',
          relative: 'xero-payment-services',
          customPageName: 'SettingsConnectionsXeroPaymentServices',
        },
        XERO_PAYMENT_SERVICES_MANAGE: {
          path: '/portal/settings/connections/xero-payment-services-manage',
          relative: 'xero-payment-services-manage',
          customPageName: 'SettingsConnectionsXeroPaymentServicesManage',
          GENERAL: {
            path: '/portal/settings/connections/xero-payment-services-manage/general',
            customPageName:
              'SettingsConnectionsXeroPaymentServicesManageGeneral',
            relative: 'general',
          },
          RECEIPT: {
            path: '/portal/settings/connections/xero-payment-services-manage/receipt',
            customPageName:
              'SettingsConnectionsXeroPaymentServicesManageReceipt',
            relative: 'receipt',
          },
          USERS: {
            path: '/portal/settings/connections/xero-payment-services-manage/users',
            customPageName: 'SettingsConnectionsXeroPaymentServicesManageUsers',
            relative: 'users',
            USER: (
              userId = `:${ROUTE_PARAM_NAME.PORTAL_SETTINGS_XERO_USER_ID}`
            ) => ({
              path: `/portal/settings/connections/xero-payment-services-manage/users/${userId}`,
              customPageName:
                'SettingsConnectionsXeroPaymentServicesManageUsersDetails',
              relative: userId,
            }),
          },
        },
        XERO_PAYMENT_SERVICES_SETUP: {
          path: '/portal/settings/connections/xero-payment-services-setup',
          relative: 'xero-payment-services-setup',
          customPageName: 'SettingsConnectionsXeroPaymentServicesSetup',
        },
        XERO_BANK_FEEDS: {
          path: '/portal/settings/connections/xero-bank-feeds',
          relative: 'xero-bank-feeds',
          customPageName: 'SettingsConnectionsXeroBankFeeds',
        },
        XERO_BANK_FEEDS_SETUP: {
          path: '/portal/settings/connections/xero-bank-feeds-setup',
          relative: 'xero-bank-feeds-setup',
          customPageName: 'SettingsConnectionsXeroBankFeedsSetup',
        },
        XERO_BANK_FEEDS_MANAGE: {
          path: '/portal/settings/connections/xero-bank-feeds-manage',
          relative: 'xero-bank-feeds-manage',
          customPageName: 'SettingsConnectionsXeroBankFeedsManage',
        },
        HL_POS: {
          path: '/portal/settings/connections/hl-pos',
          relative: 'hl-pos',
          MANAGE: {
            path: '/portal/settings/connections/hl-pos/manage',
            relative: 'manage',
            customPageName: 'SettingsConnectionsHlPosManage',
          },
        },
      },
      PROFILE: {
        path: '/portal/settings/profile',
        relative: 'profile',
        PERSONAL: {
          path: '/portal/settings/profile/personal',
          relative: 'personal',
          EDIT: {
            path: '/portal/settings/profile/personal/edit',
            relative: 'edit',
          },
        },
        SECURITY: {
          path: '/portal/settings/profile/security',
          relative: 'security',
          CHANGE_NUMBER: {
            path: '/portal/settings/profile/security/change-number',
            relative: 'change-number',
          },
          VALIDATE_CODE: {
            path: '/portal/settings/profile/security/validate-code',
            relative: 'validate-code',
          },
        },
        DOCUMENTS: {
          path: '/portal/settings/profile/documents',
          relative: 'documents',
        },
      },
      USERS: {
        path: '/portal/settings/users',
        relative: 'users',
        USER_EDIT: {
          path: '/portal/settings/users/user-edit',
          relative: 'user-edit',
        },
        USER_CREATE_TYPE: {
          path: '/portal/settings/users/user-create-type',
          relative: 'user-create-type',
        },
        USER_CREATE_DETAILS: {
          path: '/portal/settings/users/user-create-details',
          relative: 'user-create-details',
        },
      },
    },
    BIOMETRICS: {
      path: '/portal/biometrics',
      relative: 'biometrics',
      ONFIDO: {
        path: '/portal/biometrics/onfido',
        relative: 'onfido',
      },
      PUBLIC_ONFIDO: {
        path: '/biometrics',
        relative: 'biometrics',
      },
    },
    VIRTUAL_TERMINAL: {
      customPageName: 'VirtualTerminal',
      path: '/portal/payments/virtual-terminal',
      relative: 'virtual-terminal',
      HISTORY: {
        customPageName: 'VirtualTerminalHistory',
        path: '/portal/payments/virtual-terminal/history',
        relative: 'history',
      },
    },
    REPORTS: {
      customPageName: 'Reports',
      path: '/portal/reports',
      relative: 'reports',
      CASHFLOW: {
        path: '/portal/reports/cashflow',
        relative: 'cashflow',
      },
      SALES: {
        path: '/portal/reports/sales',
        relative: 'sales',
      },
    },
    REFERRAL: {
      customPageName: 'Referral',
      path: '/portal/referral',
      relative: 'referral',
    },
    HELP: {
      customPageName: 'Help',
      path: '/portal/help',
      relative: 'help',
    },
    DEMOS: {
      path: '/portal/demos',
      relative: 'demos',
    },
    POS_LITE: {
      path: '/portal/pos-lite',
      relative: 'pos-lite',
      customPageName: 'PosLite',
    },
  },
  SIGNUP: {
    path: '/signup',
    relative: 'signup',
  },
  LOGOUT: {
    path: '/logout',
    relative: 'logout',
    INACTIVITY_TIMEOUT: {
      path: '/logout/inactivity-timeout',
      relative: 'inactivity-timeout',
    },
  },
  LOGIN_ERROR_ONBOARDING_STATUS: {
    path: '/login-error-onboarding-status',
    relative: 'login-error-onboarding-status',
  },
  LOGIN_ERROR_ENTITY_DISABLED: {
    path: '/login-error-entity-disabled',
    relative: 'login-error-entity-disabled',
  },
  LOGIN_ERROR_ENTITY_ABANDONED: {
    path: '/login-error-entity-abandoned',
    relative: 'login-error-entity-abandoned',
  },
} satisfies RootRoute

export type RootRoute = {
  /**
   * Full URL path
   */
  path: string
  /**
   * Relative path is the new convention when defining paths
   * in router v6
   */
  relative?: string
  /**
   * Define customPageName if you want this path to be
   * tracked in segment analytics
   */
  customPageName?: string
  [key: string]: RootRoute | string | undefined | (() => RootRoute)
}

export const DEFAULT_AFTER_LOGIN_ROUTE = ROOT.PORTAL.OVERVIEW.PAYMENTS.path
