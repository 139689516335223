import { matchPath } from 'react-router-dom'
import { memoize } from 'lodash-es'

import { ROOT, type RootRoute, ROUTE, ROUTE_NAME } from 'const/routes'

const customPageNames: Record<ROUTE_NAME, string> = {
  [ROUTE_NAME.ACCOUNT_CREATED]: 'AccountCreated',
  [ROUTE_NAME.CHANGE_EMAIL]: 'ChangeEmail',
  [ROUTE_NAME.CUSTOMER_PREFERENCES]: 'CustomerPreferences',
  [ROUTE_NAME.EMAIL_VERIFIED]: 'EmailVerified',
  [ROUTE_NAME.FORGOT_PASSWORD]: 'ForgotPassword',
  [ROUTE_NAME.JOIN_WAIT_LIST]: 'RegionWaitlist',
  [ROUTE_NAME.LOGIN]: 'Login',
  [ROUTE_NAME.LOGIN_ERROR_ENTITY_ABANDONED]: 'LoginErrorEntityAbandoned',
  [ROUTE_NAME.LOGIN_ERROR_ENTITY_DISABLED]: 'LoginErrorEntityDisabled',
  [ROUTE_NAME.LOGIN_ERROR_ONBOARDING_STATUS]: 'LoginErrorOnboardingStatus',
  [ROUTE_NAME.LOGOUT]: 'Logout',
  [ROUTE_NAME.MY_ZELLER_COM]: 'MyZellerCom',
  [ROUTE_NAME.ONBOARDING]: 'Onboarding_',
  [ROUTE_NAME.ONBOARDING_YOUR_BUSINESS_INFORMATION]:
    'Onboarding_YourBusinessInformation',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION]: 'Onboarding_Association',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_ABOUT_BUSINESS]:
    'Onboarding_AssociationAboutBusiness',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_BUSINESS_REGULATIONS]:
    'Onboarding_AssociationBusinessRegulations',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_UPLOAD_BANK_STATEMENTS]:
    'Onboarding_AssociationUploadBankStatements',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_BUSINESS_TYPE]:
    'Onboarding_AssociationBusinessType',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_CUSTOMERS]:
    'Onboarding_AssociationCustomers',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_CUSTOMERS_CHAIR]:
    'Onboarding_AssociationCustomersChair',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_CUSTOMERS_SECRETARY]:
    'Onboarding_AssociationCustomersSecretary',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_CUSTOMERS_TREASURER]:
    'Onboarding_AssociationCustomersTreasurer',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_PLACE_OF_BUSINESS]:
    'Onboarding_AssociationPlaceOfBusiness',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_POSITION]:
    'Onboarding_AssociationPosition',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_REGISTERED_OFFICE_ADDRESS]:
    'Onboarding_AssociationRegisteredOfficeAddress',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_REVENUE]: 'Onboarding_AssociationRevenue',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_TRADING_NAME]:
    'Onboarding_AssociationTradingName',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_UPLOAD_DOCUMENTS]:
    'Onboarding_AssociationUploadDocuments',
  [ROUTE_NAME.ONBOARDING_BUSINESS_INFORMATION]:
    'Onboarding_BusinessInformation',
  [ROUTE_NAME.ONBOARDING_BUSINESS_REVIEW]: 'Onboarding_BusinessReview',
  [ROUTE_NAME.ONBOARDING_BUSINESS_REVIEW_UPLOAD_DOCUMENTS]:
    'Onboarding_BusinessReviewUploadDocuments',
  [ROUTE_NAME.ONBOARDING_BUSINESS_REVIEW_BIOMETRICS]:
    'Onboarding_BusinessReviewBiometrics',
  [ROUTE_NAME.ONBOARDING_BUSINESS_REVIEW_BIOMETRICS_ONFIDO]:
    'Onboarding_BusinessReviewBiometricsOnfido',
  [ROUTE_NAME.ONBOARDING_COMPANY]: 'Onboarding_Company',
  [ROUTE_NAME.ONBOARDING_COMPANY_ABOUT_BUSINESS]:
    'Onboarding_CompanyAboutBusiness',
  [ROUTE_NAME.ONBOARDING_COMPANY_BUSINESS_REGULATIONS]:
    'Onboarding_CompanyBusinessRegulations',
  [ROUTE_NAME.ONBOARDING_COMPANY_BUSINESS_TYPE]:
    'Onboarding_CompanyBusinessType',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS]: 'Onboarding_CompanyCustomers',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_ADD_ALTERNATE_BENEFICIAL_OWNER]:
    'Onboarding_CompanyCustomersAddAlternateBeneficialOwner',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_ADD_BENEFICIAL_OWNER]:
    'Onboarding_CompanyCustomersAddBeneficialOwner',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_ADD_DIRECTOR]:
    'Onboarding_CompanyCustomersAddDirector',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_ALTERNATE_BENEFICIAL_OWNERS]:
    'Onboarding_CompanyCustomersAlternateBeneficialOwners',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_BENEFICIAL_OWNERS]:
    'Onboarding_CompanyCustomersBeneficialOwners',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_DIRECTORS]:
    'Onboarding_CompanyCustomersDirectors',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_EDIT_ALTERNATE_BENEFICIAL_OWNER]:
    'Onboarding_CompanyCustomersEditAlternateBeneficialOwner',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_EDIT_BENEFICIAL_OWNER]:
    'Onboarding_CompanyCustomersEditBeneficialOwner',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_EDIT_DIRECTOR]:
    'Onboarding_CompanyCustomersEditDirector',
  [ROUTE_NAME.ONBOARDING_COMPANY_INFORMATION]: 'Onboarding_CompanyInformation',
  [ROUTE_NAME.ONBOARDING_COMPANY_NAME]: 'Onboarding_CompanyName',
  [ROUTE_NAME.ONBOARDING_COMPANY_PLACE_OF_BUSINESS]:
    'Onboarding_CompanyPlaceOfBusiness',
  [ROUTE_NAME.ONBOARDING_COMPANY_POSITION]: 'Onboarding_CompanyPosition',
  [ROUTE_NAME.ONBOARDING_COMPANY_REGISTERED_OFFICE_ADDRESS]:
    'Onboarding_CompanyRegisteredOfficeAddress',
  [ROUTE_NAME.ONBOARDING_COMPANY_REVENUE]: 'Onboarding_CompanyRevenue',
  [ROUTE_NAME.ONBOARDING_COMPANY_SEARCH]: 'Onboarding_CompanySearch',
  [ROUTE_NAME.ONBOARDING_COMPANY_TRADING_NAME]: 'Onboarding_CompanyTradingName',
  [ROUTE_NAME.ONBOARDING_COMPANY_UPLOAD_BANK_STATEMENTS]:
    'Onboarding_CompanyUploadBankStatements',
  [ROUTE_NAME.ONBOARDING_COMPLETE]: 'Onboarding_Complete',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT]: 'Onboarding_Government',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_ABOUT_BUSINESS]:
    'Onboarding_GovernmentAboutBusiness',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_BUSINESS_REGULATIONS]:
    'Onboarding_GovernmentBusinessRegulations',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_BUSINESS_TYPE]:
    'Onboarding_GovernmentBusinessType',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_PRINCIPAL_PLACE_OF_BUSINESS]:
    'Onboarding_GovernmentPrincipalPlaceOfBusiness',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_REVENUE]: 'Onboarding_GovernmentRevenue',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_YOUR_ROLE]: 'Onboarding_GovernmentYourRole',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL]: 'Onboarding_Individual',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_ABOUT_BUSINESS]:
    'Onboarding_IndividualAboutBusiness',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_BUSINESS_REGULATIONS]:
    'Onboarding_IndividualBusinessRegulations',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_UPLOAD_BANK_STATEMENTS]:
    'Onboarding_IndividualUploadBankStatements',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_BUSINESS_TYPE]:
    'Onboarding_IndividualBusinessType',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN]: 'Onboarding_IndividualNoAbn',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_YOUR_BUSINESS_INFORMATION]:
    'Onboarding_IndividualNoAbnYourBusinessInformation',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_ABOUT_BUSINESS]:
    'Onboarding_IndividualNoAbnAboutBusiness',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_BUSINESS_REGULATIONS]:
    'Onboarding_IndividualNoAbnBusinessRegulations',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_UPLOAD_BANK_STATEMENTS]:
    'Onboarding_IndividualNoAbnUploadBankStatements',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_BUSINESS_TYPE]:
    'Onboarding_IndividualNoAbnBusinessType',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_PRINCIPAL_PLACE_OF_BUSINESS]:
    'Onboarding_IndividualNoAbnPrincipalPlaceOfBusiness',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_REVENUE]:
    'Onboarding_IndividualNoAbnRevenue',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_TRADING_NAME]:
    'Onboarding_IndividualNoAbnTradingName',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_REGISTERED_OFFICE_ADDRESS]:
    'Onboarding_IndividualRegisteredOfficeAddress',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_REVENUE]: 'Onboarding_IndividualRevenue',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_TRADING_NAME]:
    'Onboarding_IndividualTradingName',
  [ROUTE_NAME.ONBOARDING_KYC]: 'Onboarding_Kyc',
  [ROUTE_NAME.ONBOARDING_KYC_DRIVING_LICENCE]: 'Onboarding_KycDrivingLicence',
  [ROUTE_NAME.ONBOARDING_KYC_MEDICARE]: 'Onboarding_KycMedicare',
  [ROUTE_NAME.ONBOARDING_KYC_PASSPORT]: 'Onboarding_KycPassport',
  [ROUTE_NAME.ONBOARDING_KYC_RESIDENTAL_ADDRESS]:
    'Onboarding_KycResidentalAddress',
  [ROUTE_NAME.ONBOARDING_KYC_VERIFICATION_FAILED]:
    'Onboarding_KycVerificationFailed',
  [ROUTE_NAME.ONBOARDING_KYC_YOUR_INFORMATION]: 'Onboarding_KycYourInformation',
  [ROUTE_NAME.ONBOARDING_KYC_NO_IDENTIFICATION]:
    'Onboarding_KycNoIdentification',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP]: 'Onboarding_Partnership',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_ABOUT_BUSINESS]:
    'Onboarding_PartnershipAboutBusiness',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_BUSINESS_REGULATIONS]:
    'Onboarding_PartnershipBusinessRegulations',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_UPLOAD_BANK_STATEMENTS]:
    'Onboarding_PartnershipUploadBankStatements',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_BUSINESS_TYPE]:
    'Onboarding_PartnershipBusinessType',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_CUSTOMERS]:
    'Onboarding_PartnershipCustomers',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_CUSTOMERS_ADD_PARTNER]:
    'Onboarding_PartnershipCustomersAddPartner',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_CUSTOMERS_EDIT_PARTNER]:
    'Onboarding_PartnershipCustomersEditPartner',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_CUSTOMERS_PARTNERS]:
    'Onboarding_PartnershipCustomersPartners',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_POSITION]:
    'Onboarding_PartnershipPosition',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_REGISTERED_OFFICE_ADDRESS]:
    'Onboarding_PartnershipRegisteredOfficeAddress',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_REVENUE]: 'Onboarding_PartnershipRevenue',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_TRADING_NAME]:
    'Onboarding_PartnershipTradingName',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_UPLOAD_DOCUMENTS]:
    'Onboarding_PartnershipUploadDocuments',
  [ROUTE_NAME.ONBOARDING_TRUST]: 'Onboarding_Trust',
  [ROUTE_NAME.ONBOARDING_TRUST_ABOUT_BUSINESS]: 'Onboarding_TrustAboutBusiness',
  [ROUTE_NAME.ONBOARDING_TRUST_BUSINESS_REGULATIONS]:
    'Onboarding_TrustBusinessRegulations',
  [ROUTE_NAME.ONBOARDING_TRUST_UPLOAD_BANK_STATEMENTS]:
    'Onboarding_TrustUploadBankStatements',
  [ROUTE_NAME.ONBOARDING_TRUST_BUSINESS_TYPE]: 'Onboarding_TrustBusinessType',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS]: 'Onboarding_TrustCustomers',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_ADD_BENEFICIAL_OWNERS]:
    'Onboarding_TrustCustomersAddBeneficialOwners',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_ADD_BENEFICIARIES]:
    'Onboarding_TrustCustomersAddBeneficiaries',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_ADD_TRUSTEES]:
    'Onboarding_TrustCustomersAddTrustees',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_BENEFICIAL_OWNERS]:
    'Onboarding_TrustCustomersBeneficialOwners',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_BENEFICIARIES]:
    'Onboarding_TrustCustomersBeneficiaries',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_EDIT_BENEFICIAL_OWNERS]:
    'Onboarding_TrustCustomersEditBeneficialOwners',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_EDIT_BENEFICIARIES]:
    'Onboarding_TrustCustomersEditBeneficiaries',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_EDIT_TRUSTEES]:
    'Onboarding_TrustCustomersEditTrustees',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_SETTLED_SUM]:
    'Onboarding_TrustCustomersSettledSum',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_SETTLOR_INFORMATION]:
    'Onboarding_TrustCustomersSettlorInformation',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_TRUSTEES]:
    'Onboarding_TrustCustomersTrustees',
  [ROUTE_NAME.ONBOARDING_TRUST_POSITION]: 'Onboarding_TrustPosition',
  [ROUTE_NAME.ONBOARDING_TRUST_REGISTERED_OFFICE_ADDRESS]:
    'Onboarding_TrustRegisteredOfficeAddress',
  [ROUTE_NAME.ONBOARDING_TRUST_REVENUE]: 'Onboarding_TrustRevenue',
  [ROUTE_NAME.ONBOARDING_TRUST_TRADING_NAME]: 'Onboarding_TrustTradingName',
  [ROUTE_NAME.ONBOARDING_TRUST_UPLOAD_DOCUMENTS]:
    'Onboarding_TrustUploadDocuments',
  [ROUTE_NAME.ONBOARDING_TRY_AGAIN]: 'Onboarding_TryAgain',
  [ROUTE_NAME.ONBOARDING_UPLOAD_DOCUMENTS_LATER]:
    'Onboarding_UploadDocumentsLater',
  [ROUTE_NAME.ONBOARDING_WELCOME_BACK]: 'Onboarding_WelcomeBack',
  [ROUTE_NAME.ONBOARDING_PRE_ONBOARDED_QUESTIONNAIRE]:
    'Onboarding_PreOnboardingQuestionnaire',
  [ROUTE_NAME.ONBOARDING_PRE_ONBOARDED_QUESTIONNAIRE_UPLOAD_LATER]:
    'Onboarding_UploadDocumentsLater_PreOnboardingQuestionnaire',
  [ROUTE_NAME.PIN_SUCCESS]: 'PinSuccess',

  [ROUTE_NAME.PORTAL]: '',
  [ROUTE_NAME.PORTAL_ACCOUNTS]: 'Accounts',
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT]: 'Account',
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_ID]: 'Account',
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_ID_CLOSE]: 'AccountClose',
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_ID_DETAILS]: 'AccountDetails',
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_ID_EDIT]: 'AccountEdit',
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_TRANSACTIONS]: 'AccountTransactions',
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_CARDS]: 'AccountCards',
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_TRANSACTIONS_TRANSACTION]:
    'AccountTransactionsTransaction',
  [ROUTE_NAME.PORTAL_ACCOUNTS_ACCOUNT_ID_TRANSACTIONS]: 'AccountTransactions',
  [ROUTE_NAME.PORTAL_ACCOUNTS_TRANSACTIONS]: 'AccountsTransactions',
  [ROUTE_NAME.PORTAL_ACCOUNTS_TRANSFER_CREATE]: 'Transfer',
  [ROUTE_NAME.PORTAL_CARDS]: 'Cards',
  [ROUTE_NAME.PORTAL_CARDS_ACTIVATE]: 'CardsActivate',
  [ROUTE_NAME.PORTAL_CARDS_CORPORATE]: 'CardsCorporate',
  [ROUTE_NAME.PORTAL_CARDS_DEBIT]: 'CardsDebit',
  [ROUTE_NAME.PORTAL_CARDS_ID]: 'CardsId',
  [ROUTE_NAME.PORTAL_CONTACTS]: 'Contacts',
  [ROUTE_NAME.PORTAL_CONTACTS_BUSINESSES]: 'ContactsBusinesses',
  [ROUTE_NAME.PORTAL_CONTACTS_BUSINESSES_ACCOUNTS]: 'ContactAccounts',
  [ROUTE_NAME.PORTAL_CONTACTS_BUSINESSES_GENERAL]: 'Contact',
  [ROUTE_NAME.PORTAL_CONTACTS_BUSINESSES_PAYMENTS]: 'ContactPayments',
  [ROUTE_NAME.PORTAL_CONTACTS_BUSINESSES_INVOICES]: 'ContactInvoices',
  [ROUTE_NAME.PORTAL_CONTACTS_BUSINESSES_INVOICES_DETAILS]:
    'ContactInvoiceDetails',
  [ROUTE_NAME.PORTAL_CONTACTS_PEOPLE]: 'ContactsPeople',
  [ROUTE_NAME.PORTAL_CONTACTS_PEOPLE_ACCOUNTS]: 'ContactAccounts',
  [ROUTE_NAME.PORTAL_CONTACTS_PEOPLE_GENERAL]: 'Contact',
  [ROUTE_NAME.PORTAL_CONTACTS_PEOPLE_PAYMENTS]: 'ContactPayments',
  [ROUTE_NAME.PORTAL_CONTACTS_PEOPLE_INVOICES]: 'ContactInvoices',
  [ROUTE_NAME.PORTAL_CONTACTS_PEOPLE_INVOICES_DETAILS]: 'ContactInvoiceDetails',
  [ROUTE_NAME.PORTAL_DEMOS]: 'Demos',
  [ROUTE_NAME.PORTAL_HELP]: 'Help',
  [ROUTE_NAME.PORTAL_KYC]: 'Kyc',
  [ROUTE_NAME.PORTAL_KYC_DRIVING_LICENCE]: 'KycDrivingLicence',
  [ROUTE_NAME.PORTAL_KYC_NO_IDENTIFICATION]: 'KycNoIdentification',
  [ROUTE_NAME.PORTAL_KYC_FINALISE]: 'KycFinalise',
  [ROUTE_NAME.PORTAL_KYC_EXISTING_USER]: 'KycExistingUser',
  [ROUTE_NAME.PORTAL_KYC_IN_REVIEW]: 'KycInReview',
  [ROUTE_NAME.PORTAL_KYC_MEDICARE]: 'KycMedicare',
  [ROUTE_NAME.PORTAL_KYC_NEW_USER]: 'KycNewUser',
  [ROUTE_NAME.PORTAL_KYC_PASSPORT]: 'KycPassport',
  [ROUTE_NAME.PORTAL_KYC_RESIDENTAL_ADDRESS]: 'KycResidentalAddress',
  [ROUTE_NAME.PORTAL_KYC_REJECTED]: 'KycRejected',
  [ROUTE_NAME.PORTAL_KYC_SUCCESS]: 'KycSuccess',
  [ROUTE_NAME.PORTAL_KYC_UPLOAD_SUCCESS]: 'KycUploadSuccess',
  [ROUTE_NAME.PORTAL_KYC_VERIFICATION_FAILED]: 'KycVerificationFailed',
  [ROUTE_NAME.PORTAL_KYC_YOUR_IDENTITY]: 'KycYourIdentity',
  [ROUTE_NAME.PORTAL_KYC_YOUR_INFORMATION]: 'KycYourInformation',
  [ROUTE_NAME.PORTAL_KYC_TIME_FOR_A_SELFIE]: 'KycTimeForASelfie',
  [ROUTE_NAME.PORTAL_KYC_SELFIE_VERIFICATION]: 'KycSelfieVerification',
  [ROUTE_NAME.PORTAL_KYC_REVIEW_DOCUMENT_UPLOAD]: 'KycReviewDocumentUpload',
  [ROUTE_NAME.PORTAL_KYC_DOCUMENT_UPLOAD]: 'KycUploadDocuments',
  [ROUTE_NAME.PORTAL_NOTIFICATIONS]: 'Notifications',
  [ROUTE_NAME.PORTAL_OVERVIEW]: 'Overview',
  [ROUTE_NAME.PORTAL_PAYMENTS]: 'Payments',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS]: 'Deposits',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS_ACCOUNT]: 'DepositsSettings',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_DETAILS]:
    'DepositsAccountDetails',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_EDIT_ACCOUNT_DETAIL]:
    'DepositsAccountEditAccountDetail',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT]:
    'DepositsSettingsAddAccount',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT_SUCCESS]:
    'DepositsSettingsAddAccountSuccess',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS_SETTINGS_EDIT_ACCOUNT]:
    'DepositsSettingsEditAccount',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEPOSITS_STATEMENTS]: 'DepositsStatements',
  [ROUTE_NAME.PORTAL_INVOICES]: 'Invoicing',
  [ROUTE_NAME.PORTAL_INVOICES_INVOICE_LIST]: 'InvoicingInvoices',
  [ROUTE_NAME.PORTAL_INVOICES_INVOICE_EDIT]: 'InvoicingInvoicesEdit',
  [ROUTE_NAME.PORTAL_INVOICES_INVOICE_DETAILS]: 'InvoicingInvoicesDetails',
  [ROUTE_NAME.PORTAL_INVOICES_INVOICE_CREATE]: 'InvoicingInvoiceCreate',
  [ROUTE_NAME.PORTAL_INVOICES_OVERVIEW]: 'InvoicingOverview',
  [ROUTE_NAME.PORTAL_INVOICES_QUOTES]: 'InvoicingQuotes',
  [ROUTE_NAME.PORTAL_INVOICES_QUOTE_CREATE]: 'InvoicingQuoteCreate',
  [ROUTE_NAME.PORTAL_INVOICES_RECURRING]: 'InvoicingRecurring',
  [ROUTE_NAME.PORTAL_INVOICES_SETTINGS]: 'InvoicingSettings',
  [ROUTE_NAME.PORTAL_INVOICES_SETTINGS_CUSTOMISATION]:
    'InvoicingSettingsCustomisation',
  [ROUTE_NAME.PORTAL_INVOICES_SETTINGS_GENERAL]: 'InvoicingSettingsGeneral',
  [ROUTE_NAME.PORTAL_INVOICES_SETTINGS_RECEIPTS]: 'InvoicingSettingsReceipts',
  [ROUTE_NAME.PORTAL_INVOICES_SETTINGS_REMINDERS]: 'InvoicingSettingsReminders',
  [ROUTE_NAME.PORTAL_INVOICES_SETTINGS_USERS]: 'InvoicingSettingsUsers',
  [ROUTE_NAME.PORTAL_INVOICES_SETTINGS_USERS_DETAILS]:
    'InvoicingSettingsUsersDetails',

  [ROUTE_NAME.PORTAL_PAYMENTS_REPORTS]: 'Reports',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES]: 'Sites',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_CREATE_SITE]: 'SitesCreateSite',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE]: 'Site',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_GENERAL]: 'SiteGeneral',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_GENERAL_EDIT]: 'SiteGeneralEdit',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_PAYMENTS]: 'SitePayments',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_RECEIPT]: 'SiteReceipt',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_DEVICES]: 'SiteDevices',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_USERS]: 'SiteUsers',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_DETAILS]:
    'SiteUsersCreateDetails',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_TYPE]:
    'SiteUsersCreateType',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_USERS_EDIT]: 'SiteUsersEdit',
  [ROUTE_NAME.PORTAL_PAYMENTS_SITES_SITE_APPEARANCE]: 'SiteAppearance',
  [ROUTE_NAME.PORTAL_PAYMENTS_DEVICES]: 'Devices',
  [ROUTE_NAME.PORTAL_PAYMENTS_TRANSACTIONS]: 'Transactions',
  [ROUTE_NAME.PORTAL_REFERRAL]: 'Referral',
  [ROUTE_NAME.SHOP]: 'Shop',
  [ROUTE_NAME.PORTAL_SETTINGS]: 'Settings',
  [ROUTE_NAME.PORTAL_SETTINGS_FEES_AND_PRICING]: 'SettingsFeesAndPricing',
  [ROUTE_NAME.PORTAL_SETTINGS_PROFILE]: 'SettingsProfile',
  [ROUTE_NAME.PORTAL_SETTINGS_PROFILE_DOCUMENTS]: 'SettingsProfileDocuments',
  [ROUTE_NAME.PORTAL_SETTINGS_PROFILE_PERSONAL]: 'SettingsProfilePersonal',
  [ROUTE_NAME.PORTAL_SETTINGS_PROFILE_PERSONAL_EDIT]:
    'SettingsProfilePersonalEdit',
  [ROUTE_NAME.PORTAL_SETTINGS_PROFILE_SECURITY]: 'SettingsProfileSecurity',
  [ROUTE_NAME.PORTAL_SETTINGS_PROFILE_SECURITY_CHANGE_NUMBER]:
    'SettingsProfileSecurityChangeNumber',
  [ROUTE_NAME.PORTAL_SETTINGS_PROFILE_SECURITY_VALIDATE_CODE]:
    'SettingsProfileSecurityValidateCode',
  [ROUTE_NAME.PORTAL_SETTINGS_USERS]: 'SettingsUsers',
  [ROUTE_NAME.PORTAL_SETTINGS_USERS_USER_CREATE_DETAILS]:
    'SettingsUsersUserCreateDetails',
  [ROUTE_NAME.PORTAL_SETTINGS_USERS_USER_CREATE_TYPE]:
    'SettingsUsersUserCreateType',
  [ROUTE_NAME.PORTAL_SETTINGS_USERS_USER_EDIT]: 'SettingsUsersUserEdit',
  [ROUTE_NAME.REGISTER_PHONE]: 'RegisterPhone',
  [ROUTE_NAME.SIGNUP]: 'Signup',
  [ROUTE_NAME.UNEXPECTED_ERROR]: 'UnexpectedError',
  [ROUTE_NAME.VALIDATE_CODE]: 'ValidateCode',
  [ROUTE_NAME.ONBOARDING_KYC_TIME_FOR_A_SELFIE]: 'Onboarding_TimeForASelfie',
  [ROUTE_NAME.ONBOARDING_KYC_SELFIE_VERIFICATION]:
    'Onboarding_SelfieVerification',
}

export const pages = Object.keys(ROUTE).reduce<{
  [urlPath: string]: string
}>((acc, curr) => {
  const routeName = curr as ROUTE_NAME

  acc[ROUTE[routeName]] = customPageNames[routeName]

  return acc
}, {})

/**
 * Flattened ROOT paths with custom page names
 * for tracking page analytics
 */
export const getRootPathsFlatMap = (
  rootPaths: RootRoute
): [Record<string, string>, string[]] => {
  const pathsWithCustomPageNameMap: Record<string, string> = {}
  const pathsWithoutCustomPageName: string[] = []

  const callback = (rootRoute: RootRoute) => {
    if (rootRoute?.path) {
      if (rootRoute?.customPageName) {
        pathsWithCustomPageNameMap[rootRoute.path] = rootRoute.customPageName
      } else {
        pathsWithoutCustomPageName.push(rootRoute.path)
      }
    }

    const routeKeys = Object.keys(rootRoute).filter(
      (prop) =>
        typeof rootRoute[prop] === 'object' ||
        typeof rootRoute[prop] === 'function'
    )

    routeKeys.forEach((callableKey) => {
      const rootRouteObj = rootRoute[callableKey]

      if (typeof rootRouteObj === 'function') {
        callback(rootRouteObj())
      } else {
        callback(rootRouteObj as RootRoute)
      }
    })
  }
  callback(rootPaths)

  return [pathsWithCustomPageNameMap, pathsWithoutCustomPageName]
}

const [rootPathsWithCustomPageNameMap, rootPathsWithoutCustomPageName] =
  getRootPathsFlatMap(ROOT)

const ignoreUrlChangePaths = [
  ROUTE.PORTAL_CONTACTS,
  ROUTE.CUSTOMER_PREFERENCES,
  ROUTE.JOIN_WAIT_LIST,
]

/**
 * This function looks at the existing ROUTES enum paths if
 * the current location has a defined custom page name. If the
 * path is not defined in ROUTES, check the flattened ROOT object
 * map which contains all paths that has customPageName property
 * @param locationPathName location path name from useLocation
 * @returns custom page name for analytics
 */
export const getCustomPageName = memoize((locationPathName: string) => {
  if (ignoreUrlChangePaths.includes(locationPathName)) {
    return null
  }

  // This is looking at the ROUTES enum which is the old pattern
  const routesCustomPageName = pages[locationPathName]

  if (routesCustomPageName) {
    return routesCustomPageName
  }

  if (rootPathsWithoutCustomPageName.includes(locationPathName)) {
    return null
  }

  // Get all matched ROOT paths in the cases of dynamic segments path/:id
  const matchingRootPaths = Object.keys(rootPathsWithCustomPageNameMap).filter(
    (rootRoute) => {
      return Boolean(matchPath(locationPathName, rootRoute)?.isExact)
    }
  )

  if (!matchingRootPaths.length) {
    return null
  }

  // This already handles dynamic segments
  if (matchingRootPaths.length === 1) {
    return rootPathsWithCustomPageNameMap[matchingRootPaths[0]]
  }

  // Grab the map key of EXACT path
  return matchingRootPaths.find((rootPath) => rootPath === locationPathName)
})
