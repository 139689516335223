import { gql } from '@apollo/client'

export const GetTransactionTotals = gql`
  query GetTransactionTotals($filter: TransactionFilterInput!) {
    getTransactionTotalsBigInt(filter: $filter, totalsType: HOURLY) {
      period
      totalAmount
      countPurchases
    }
  }
`

export const GetOverviewTransactionTotals = gql`
  query GetOverviewTransactionTotals($filter: TransactionFilterInput!) {
    getTransactionTotalsBigInt(filter: $filter, totalsType: HOURLY) {
      period
      refundAmount
      surchargeAmount
      purchaseAmount
      totalAmount
      tipAmount
      taxAmounts {
        name
        amount
      }
      feeAmount
    }
  }
`
