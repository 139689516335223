import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetSpendControlLimitAccountBalanceQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type GetSpendControlLimitAccountBalanceQueryResponse = {
  __typename?: 'Query'
  account: {
    __typename?: 'DebitCardAccountV2'
    id: string
    balance: { __typename?: 'Money'; currency: string; value: string } | null
  } | null
}

export const GetSpendControlLimitAccountBalance = gql`
  query GetSpendControlLimitAccountBalance($id: ID!) {
    account: getDebitCardAccountV2(debitCardAccountUuid: $id) {
      id
      balance {
        currency
        value
      }
    }
  }
` as unknown as TypedDocumentNode<
  GetSpendControlLimitAccountBalanceQueryResponse,
  GetSpendControlLimitAccountBalanceQueryVariables
>

/**
 * __useGetSpendControlLimitAccountBalanceQuery__
 *
 * To run a query within a React component, call `useGetSpendControlLimitAccountBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpendControlLimitAccountBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpendControlLimitAccountBalanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSpendControlLimitAccountBalanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSpendControlLimitAccountBalanceQueryResponse,
    GetSpendControlLimitAccountBalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSpendControlLimitAccountBalanceQueryResponse,
    GetSpendControlLimitAccountBalanceQueryVariables
  >(GetSpendControlLimitAccountBalance, options)
}
export function useGetSpendControlLimitAccountBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSpendControlLimitAccountBalanceQueryResponse,
    GetSpendControlLimitAccountBalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSpendControlLimitAccountBalanceQueryResponse,
    GetSpendControlLimitAccountBalanceQueryVariables
  >(GetSpendControlLimitAccountBalance, options)
}
export type GetSpendControlLimitAccountBalanceQueryHookResult = ReturnType<
  typeof useGetSpendControlLimitAccountBalanceQuery
>
export type GetSpendControlLimitAccountBalanceLazyQueryHookResult = ReturnType<
  typeof useGetSpendControlLimitAccountBalanceLazyQuery
>
export type GetSpendControlLimitAccountBalanceQueryResult = Apollo.QueryResult<
  GetSpendControlLimitAccountBalanceQueryResponse,
  GetSpendControlLimitAccountBalanceQueryVariables
>
