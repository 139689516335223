import { useQuery } from '@apollo/client'

import { GetUpdatedOutstandingTransactionsLocalState } from './graphql/getUpdatedOutstandingTransactionsLocalState'
import { CacheLocalStateOutstandingTransactions } from './useUpdatedOutstandingTransactions.types'

export const useReadUpdatedOutstandingTransactionsLocalCache = () => {
  const { data } = useQuery<CacheLocalStateOutstandingTransactions>(
    GetUpdatedOutstandingTransactionsLocalState
  )

  return {
    updatedOutstandingTransactions:
      data?.local.updatedOutstandingTransactions ?? [],
  }
}
