import { useCallback, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { isEqual } from 'lodash-es'

import {
  GetZellerInvoiceSettings,
  GetZellerInvoiceSettingsQueryResponse,
  useGetZellerInvoiceSettingsQuery,
} from '../graphql/getZellerInvoiceSettings.generated'

type UseGetZellerInvoiceSettingsProps = {
  onError?: () => void
}

export const useGetZellerInvoiceSettings = ({
  onError,
}: UseGetZellerInvoiceSettingsProps = {}) => {
  const [refetching, setRefetching] = useState(false)
  const [previous, setPrevious] = useState<typeof invoiceSettings | undefined>()

  const { data, loading, refetch, previousData } =
    useGetZellerInvoiceSettingsQuery({
      onCompleted: () => {
        setRefetching(false)
      },
      onError,
    })

  const { cache } = useApolloClient()

  const updateInvoiceSettingsCache = (
    updatedValues: GetZellerInvoiceSettingsQueryResponse['getZellerInvoiceSettings']
  ) => {
    cache.writeQuery<GetZellerInvoiceSettingsQueryResponse>({
      query: GetZellerInvoiceSettings,
      data: {
        getZellerInvoiceSettings: updatedValues,
      },
    })
  }

  const updateReceiptNameCache = (
    updatedValues: GetZellerInvoiceSettingsQueryResponse['getZellerInvoiceSettings']['receipt']
  ) => {
    cache.modify({
      id: cache.identify({
        id: data?.getZellerInvoiceSettings?.id,
        __typename: 'ZellerInvoiceSite',
      }),
      fields: {
        receipt() {
          return updatedValues
        },
      },
    })
  }

  const invoiceSettings = data?.getZellerInvoiceSettings

  const isLoading = loading || refetching

  // NOTE: previousData is set immediately when refetch is called,
  // so will initially equal invoiceSettings
  useEffect(() => {
    if (!isEqual(previousData?.getZellerInvoiceSettings, invoiceSettings)) {
      setPrevious(previousData?.getZellerInvoiceSettings)
    }
  }, [invoiceSettings, previousData])

  useEffect(() => {
    if (previous !== undefined) {
      setRefetching(false)
      setPrevious(undefined)
    }
  }, [previous])

  const refetchAndLoad = useCallback(() => {
    setRefetching(true)
    refetch()
  }, [refetch])

  return {
    invoiceSettings,
    receiptName: data?.getZellerInvoiceSettings?.receipt?.name,
    updateInvoiceSettingsCache,
    updateReceiptNameCache,
    isLoading,
    refetch: refetchAndLoad,
  }
}
