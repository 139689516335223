import { useMutation } from '@apollo/client'
import { PaymentInstrumentType } from '@npco/mp-gql-types'
import { UnlinkPaymentInstrument } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/paymentInstruments'

import {
  UnlinkPaymentInstrument as UnlinkPaymentInstrumentResponse,
  UnlinkPaymentInstrumentVariables,
} from 'types/gql-types/UnlinkPaymentInstrument'

export const useUnlinkBsbPaymentInstrument = () => {
  const [unlinkPaymentInstrument, { loading: isUnlinkingPaymentInstrument }] =
    useMutation<
      UnlinkPaymentInstrumentResponse,
      UnlinkPaymentInstrumentVariables
    >(UnlinkPaymentInstrument)

  return {
    isUnlinkingPaymentInstrument,
    unlinkPaymentInstrument: (
      contactId: string,
      paymentInstrumentId: string
    ) => {
      return unlinkPaymentInstrument({
        variables: {
          contactUuid: contactId,
          id: paymentInstrumentId,
          type: PaymentInstrumentType.BSB,
        },
      })
    },
  }
}
