import { useDeleteAllItemsMutation } from '../graphql/deleteAllItems.generated'

export const useDeleteAllItems = () => {
  const [
    deleteAllItems,
    {
      loading: isDeletingAllItems,
      error: deleteAllItemsError,
      data: deleteAllItemsData,
    },
  ] = useDeleteAllItemsMutation({
    update: (cache) => {
      cache.modify({
        broadcast: false,
        fields: {
          getItems: (existingItemRef) => ({
            ...existingItemRef,
            items: [],
            nextToken: null,
          }),
        },
      })
      cache.gc()
    },
  })

  return {
    deleteAllItems,
    isDeletingAllItems,
    deleteAllItemsError,
    deleteAllItemsData,
  }
}
