import { type ReactNode } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { Flex, PageTemplate } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'

import { AddUserButton } from './components/AddUserButton'

interface Props {
  children: ReactNode
}

export const UsersLayout = ({ children }: Props) => {
  const { pathname } = useLocation()

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            width={1}
            height="48px"
          >
            <Flex>
              <Breadcrumbs />
            </Flex>
            {pathname === ROOT.PORTAL.SETTINGS.USERS.path && <AddUserButton />}
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      {pathname !== ROOT.PORTAL.SETTINGS.USERS.path ? (
        <PageTemplate.Section>{children}</PageTemplate.Section>
      ) : (
        children
      )}
    </PageTemplate>
  )
}
