import { useGetCustomerMarketingQuery } from './graphql/getCustomerMarketing.generated'

export const useGetCustomerMarketing = () => {
  const { loading, data } = useGetCustomerMarketingQuery({
    fetchPolicy: 'cache-and-network',
  })

  return {
    isLoading: loading,
    bannerSlug: data?.getCustomerMarketing?.banner,
    modalSlug: data?.getCustomerMarketing?.modal,
  }
}
