import { useMutation } from '@apollo/client'
import { TransferRemittanceNotificationInput } from '@npco/mp-gql-types'

import {
  TransferRemittanceNotification,
  TransferRemittanceNotificationMutationResponse,
  TransferRemittanceNotificationMutationVariables,
} from '../../graphql/transferRemittanceNotification.generated'

export const useTransferRemittanceNotification = () => {
  const [transferRemittanceNotificationMutation, { error, loading }] =
    useMutation<
      TransferRemittanceNotificationMutationResponse,
      TransferRemittanceNotificationMutationVariables
    >(TransferRemittanceNotification)

  const sendTransferRemittance = async (
    input: TransferRemittanceNotificationInput
  ) => {
    const result = await transferRemittanceNotificationMutation({
      variables: { input },
    })

    return result
  }

  return { sendTransferRemittance, isSending: loading, error }
}
