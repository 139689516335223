import { useNavigate } from 'react-router-dom-v5-compat'

import { PERMISSIONS } from 'const/permissions'
import { ROOT } from 'const/routes'
import { withPermissionGuard } from 'services/withPermissionGuard/withPermissionGuard'
import { CardAdd } from 'components/Cards/Card'
import { page } from 'translations'

export const AddSiteButton = withPermissionGuard(PERMISSIONS.SITE.ADD_SITE)(
  () => {
    const navigate = useNavigate()
    return (
      <CardAdd
        label={page.settings.sites.createNewSite}
        onClick={() => navigate(ROOT.PORTAL.PAYMENTS.SITES.CREATE_SITE.path)}
      />
    )
  }
)
