import { useCallback } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { ContactType } from '@npco/mp-gql-types'
import { DebitCardTransactionsV2Fragment } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'

import { useUpdateDebitCardTransaction } from 'hooks/banking'
import { UNEXPECTED_ERROR } from 'types/errors'

import { LinkContact } from './graphql/LinkContact'
import {
  LinkContactMutationResponse,
  LinkContactMutationVariables,
} from './graphql/LinkContact.generated'

interface LinkContactArguments {
  contactUuid: string
  senderUuid: string
  contact: DebitCardTransactionsV2Fragment['contact'] | null | undefined
  transactionUuid: string
}

export const useLinkContact = () => {
  const [linkContactMutation, { data, loading, error }] = useMutation<
    LinkContactMutationResponse,
    LinkContactMutationVariables
  >(LinkContact)
  const { updateDebitCardTransaction } = useUpdateDebitCardTransaction()

  const linkContact = useCallback(
    async ({
      contactUuid,
      senderUuid,
      contact,
      transactionUuid,
    }: LinkContactArguments) => {
      try {
        const result = await linkContactMutation({
          variables: { contactUuid, senderUuid },
          update: () => {
            if (contact) {
              updateDebitCardTransaction(transactionUuid, { contact })
            } else {
              updateDebitCardTransaction(transactionUuid, {
                contact: {
                  id: contactUuid,
                  businessName: null,
                  contactType: ContactType.BUSINESS,
                  firstName: null,
                  lastName: null,
                  icon: null,
                  category: null,
                  isSelf: null,
                },
              })
            }
          },
        })

        if (!result.data?.linkContactWithDebitCardAccountTransactionSender) {
          return UNEXPECTED_ERROR
        }

        return {
          result: result.data.linkContactWithDebitCardAccountTransactionSender,
        }
      } catch (err) {
        return UNEXPECTED_ERROR
      }
    },
    [linkContactMutation, updateDebitCardTransaction]
  )

  const errorOrFalseFlag: ApolloError | typeof UNEXPECTED_ERROR | undefined =
    error ||
    (data?.linkContactWithDebitCardAccountTransactionSender === false
      ? UNEXPECTED_ERROR
      : undefined)

  return { linkContact, isLinkingContact: loading, error: errorOrFalseFlag }
}
