import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type ActivateSimMutationVariables = Types.Exact<{
  simId: Types.Scalars['ID']['input']
}>

export type ActivateSimMutationResponse = {
  __typename?: 'Mutation'
  activateSim: boolean
}

export const ActivateSimMutation = gql`
  mutation ActivateSimMutation($simId: ID!) {
    activateSim(simId: $simId)
  }
` as unknown as TypedDocumentNode<
  ActivateSimMutationResponse,
  ActivateSimMutationVariables
>
export type ActivateSimMutationMutationFn = Apollo.MutationFunction<
  ActivateSimMutationResponse,
  ActivateSimMutationVariables
>

/**
 * __useActivateSimMutation__
 *
 * To run a mutation, you first call `useActivateSimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSimMutation, { data, loading, error }] = useActivateSimMutation({
 *   variables: {
 *      simId: // value for 'simId'
 *   },
 * });
 */
export function useActivateSimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateSimMutationResponse,
    ActivateSimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ActivateSimMutationResponse,
    ActivateSimMutationVariables
  >(ActivateSimMutation, options)
}
export type ActivateSimMutationHookResult = ReturnType<
  typeof useActivateSimMutation
>
export type ActivateSimMutationMutationResult =
  Apollo.MutationResult<ActivateSimMutationResponse>
export type ActivateSimMutationMutationOptions = Apollo.BaseMutationOptions<
  ActivateSimMutationResponse,
  ActivateSimMutationVariables
>
