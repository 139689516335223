import { useCallback, useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  ButtonGhost,
  Divider,
  Flex,
  TableHeaderCellIcon,
} from '@npco/zeller-design-system'
import { HeaderContext } from '@tanstack/react-table'

import { translationsShared } from 'translations'

import { CategoriesFilter } from '../../CategoriesFilter/CategoriesFilter'
import { useItemManagementContext } from '../../ItemManagementContext/ItemManagementContext'
import { translations } from '../ItemsTable.i18n'
import { ItemsTableData } from '../ItemsTable.types'

export const ItemsHeaderRowCategoryCell = ({
  header,
}: HeaderContext<ItemsTableData, string>) => {
  const {
    items: { filterInput, setFilterInput },
  } = useItemManagementContext()

  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const selectedCategories = useMemo(
    () => filterInput?.categoryUuidFilter?.categoryUuids ?? [],
    [filterInput?.categoryUuidFilter?.categoryUuids]
  )

  const setCategories = useCallback(
    (uuids: string[]) => {
      setFilterInput({
        categoryUuidFilter: {
          categoryUuids: uuids,
        },
      })
    },
    [setFilterInput]
  )

  const handleClearClick = useCallback(() => {
    setCategories([])
  }, [setCategories])

  return (
    <TableHeaderCellIcon
      dataTestId={header.id}
      forwardedProps={{ style: { padding: 0 } }}
      isFiltering={!!selectedCategories.length}
      key={header.id}
      label={t('categoryHeader')}
      popperWidth="235px"
    >
      <Flex
        data-testid="header-row-category-popper"
        padding="8px"
        flexDirection="column"
      >
        <CategoriesFilter
          selectedCategories={selectedCategories}
          setCategories={setCategories}
        />
        <Divider margin="20px 0px" />
        <Flex
          alignItems="flex-end"
          data-testid="categories-filter-clear"
          flexDirection="column"
        >
          <ButtonGhost onClick={handleClearClick}>
            {tShared('clear')}
          </ButtonGhost>
        </Flex>
      </Flex>
    </TableHeaderCellIcon>
  )
}
