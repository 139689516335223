import { Box, Flex } from '@npco/zeller-design-system'

import { AVATAR_BADGE_SIZE } from 'types/common'
import { SkeletonLoaderAvatar } from 'components/SkeletonLoader/SkeletonLoaderAvatar'
import { SkeletonBodySmall } from 'components/SkeletonLoader/SkeletonLoaderTypography'

export const SubContactListItemLoaderSkeleton = () => {
  return (
    <Flex alignItems="center" justifyContent="space-between" my="0.5rem">
      <Flex alignItems="center">
        <Box mr="0.75rem">
          <SkeletonLoaderAvatar size={AVATAR_BADGE_SIZE.DEFAULT} />
        </Box>
        <Box mr="0.75rem">
          <SkeletonBodySmall width={140} margin="0" />
        </Box>
      </Flex>
    </Flex>
  )
}
