import { OnboardingFlowType } from '@npco/mp-gql-types'
import { type SelectItemBasic } from 'design-system/Components/Select'

import {
  type OnboardingFlowConfigItem,
  type OnboardingFlowItem,
} from './onboarding/onboarding-config.types'
import {
  ONBOARDING_ASSOCIATION_CONFIG_ITEMS,
  ONBOARDING_ASSOCIATION_CONFIG_ITEMS_PHASE_2,
} from './onboarding/onboarding-config-association'
import {
  ONBOARDING_COMPANY_FOUND_FLOW_CONFIG_ITEMS,
  ONBOARDING_COMPANY_FOUND_FLOW_CONFIG_ITEMS_PHASE_2,
} from './onboarding/onboarding-config-company-found'
import {
  ONBOARDING_GOVERNMENT_FLOW_CONFIG_ITEMS,
  ONBOARDING_GOVERNMENT_FLOW_CONFIG_ITEMS_PHASE_2,
} from './onboarding/onboarding-config-government'
import {
  ONBOARDING_INDIVIDUAL_NO_ABN_FLOW_CONFIG_ITEMS,
  ONBOARDING_INDIVIDUAL_NO_ABN_FLOW_CONFIG_ITEMS_PHASE_1,
  ONBOARDING_INDIVIDUAL_NO_ABN_FLOW_CONFIG_ITEMS_PHASE_2,
} from './onboarding/onboarding-config-individual-no-abn'
import {
  ONBOARDING_INDIVIDUAL_WITH_ABN_FLOW_CONFIG_ITEMS,
  ONBOARDING_INDIVIDUAL_WITH_ABN_FLOW_CONFIG_ITEMS_PHASE_2,
} from './onboarding/onboarding-config-individual-with-abn'
import {
  ONBOARDING_PARTNERSHIP_CONFIG_ITEMS,
  ONBOARDING_PARTNERSHIP_CONFIG_ITEMS_PHASE_2,
} from './onboarding/onboarding-config-partnership'
import {
  ONBOARDING_TRUST_FLOW_CONFIG_ITEMS,
  ONBOARDING_TRUST_FLOW_CONFIG_ITEMS_PHASE_2,
} from './onboarding/onboarding-config-trust'
import { ROOT } from './routes'

export const SAVE_AND_CLOSE_DISALLOWED_ROUTES = [
  ROOT.ONBOARDING.WELCOME_BACK.path,
  ROOT.ONBOARDING.COMPLETE.path,
  ROOT.ONBOARDING.TRY_AGAIN.path,
  ROOT.ONBOARDING.BUSINESS_REVIEW.path,
  ROOT.ONBOARDING.UPLOAD_DOCUMENTS_LATER.path,
  ROOT.LOGIN_ERROR_ENTITY_ABANDONED.path,
  ROOT.LOGIN_ERROR_ONBOARDING_STATUS.path,
  ROOT.PORTAL.KYC.FINALISE.path,
  ROOT.PORTAL.KYC.DOCUMENT_UPLOAD.path,
  ROOT.PORTAL.BIOMETRICS.PUBLIC_ONFIDO.path,
]

export const SAVE_AND_CLOSE_ALWAYS_ALLOWED_ROUTES = [
  ROOT.REGISTER_PHONE.path,
  ROOT.VALIDATE_CODE.path,
  ROOT.ONBOARDING.path,
  ROOT.ONBOARDING.COMPANY_SEARCH.path,
  ROOT.ONBOARDING.COMPANY_INFORMATION.path,
  ROOT.ONBOARDING.INDIVIDUAL_NO_ABN.path,
]

export const REVENUE: SelectItemBasic[] = [
  { value: '20000', label: '$0 - $20,000' },
  { value: '75000', label: '$20,000 - $75,000' },
  { value: '200000', label: '$75,000 - $200,000' },
  { value: '500000', label: '$200,000 - $500,000' },
  { value: '1000000', label: '$500,000 - $1,000,000' },
  { value: '5000000', label: '$1,000,000 - $5,000,000' },
  { value: '5000001', label: '$5,000,000+' },
]

const generateFlow = (flowConfigItems: OnboardingFlowConfigItem[]) =>
  new Map<string | undefined, OnboardingFlowItem>(
    flowConfigItems.map((item, index) => [
      item.path,
      { isCheckpoint: item.isCheckpoint, position: index },
    ])
  )

type OnboardingFlowConfig = [
  OnboardingFlowType,
  ReturnType<typeof generateFlow>
]

const ONBOARDING_GOVERNMENT_FLOW_CONFIG: OnboardingFlowConfig = [
  OnboardingFlowType.GOVERNMENT,
  generateFlow(ONBOARDING_GOVERNMENT_FLOW_CONFIG_ITEMS),
]

const ONBOARDING_GOVERNMENT_FLOW_CONFIG_PHASE_2: OnboardingFlowConfig = [
  OnboardingFlowType.GOVERNMENT,
  generateFlow(ONBOARDING_GOVERNMENT_FLOW_CONFIG_ITEMS_PHASE_2),
]

const ONBOARDING_COMPANY_FOUND_FLOW_CONFIG: OnboardingFlowConfig = [
  OnboardingFlowType.COMPANY,
  generateFlow(ONBOARDING_COMPANY_FOUND_FLOW_CONFIG_ITEMS),
]

const ONBOARDING_COMPANY_FOUND_FLOW_CONFIG_PHASE_2: OnboardingFlowConfig = [
  OnboardingFlowType.COMPANY,
  generateFlow(ONBOARDING_COMPANY_FOUND_FLOW_CONFIG_ITEMS_PHASE_2),
]

const ONBOARDING_TRUST_FLOW_CONFIG: OnboardingFlowConfig = [
  OnboardingFlowType.TRUST,
  generateFlow(ONBOARDING_TRUST_FLOW_CONFIG_ITEMS),
]

const ONBOARDING_TRUST_FLOW_CONFIG_PHASE_2: OnboardingFlowConfig = [
  OnboardingFlowType.TRUST,
  generateFlow(ONBOARDING_TRUST_FLOW_CONFIG_ITEMS_PHASE_2),
]

const ONBOARDING_ASSOCIATION_FLOW_CONFIG: OnboardingFlowConfig = [
  OnboardingFlowType.ASSOCIATION,
  generateFlow(ONBOARDING_ASSOCIATION_CONFIG_ITEMS),
]

const ONBOARDING_ASSOCIATION_FLOW_CONFIG_PHASE_2: OnboardingFlowConfig = [
  OnboardingFlowType.ASSOCIATION,
  generateFlow(ONBOARDING_ASSOCIATION_CONFIG_ITEMS_PHASE_2),
]

const ONBOARDING_INDIVIDUAL_NO_ABN_FLOW_CONFIG: OnboardingFlowConfig = [
  OnboardingFlowType.INDIVIDUAL_NO_ABN,
  generateFlow(ONBOARDING_INDIVIDUAL_NO_ABN_FLOW_CONFIG_ITEMS),
]

const ONBOARDING_INDIVIDUAL_NO_ABN_FLOW_CONFIG_PHASE_1: OnboardingFlowConfig = [
  OnboardingFlowType.INDIVIDUAL_NO_ABN,
  generateFlow(ONBOARDING_INDIVIDUAL_NO_ABN_FLOW_CONFIG_ITEMS_PHASE_1),
]

const ONBOARDING_INDIVIDUAL_NO_ABN_FLOW_CONFIG_PHASE_2: OnboardingFlowConfig = [
  OnboardingFlowType.INDIVIDUAL_NO_ABN,
  generateFlow(ONBOARDING_INDIVIDUAL_NO_ABN_FLOW_CONFIG_ITEMS_PHASE_2),
]

const ONBOARDING_INDIVIDUAL_WITH_ABN_FLOW_CONFIG: OnboardingFlowConfig = [
  OnboardingFlowType.INDIVIDUAL,
  generateFlow(ONBOARDING_INDIVIDUAL_WITH_ABN_FLOW_CONFIG_ITEMS),
]

const ONBOARDING_INDIVIDUAL_WITH_ABN_FLOW_CONFIG_PHASE_2: OnboardingFlowConfig =
  [
    OnboardingFlowType.INDIVIDUAL,
    generateFlow(ONBOARDING_INDIVIDUAL_WITH_ABN_FLOW_CONFIG_ITEMS_PHASE_2),
  ]

const ONBOARDING_PARTNERSHIP_FLOW_CONFIG: OnboardingFlowConfig = [
  OnboardingFlowType.PARTNERSHIP,
  generateFlow(ONBOARDING_PARTNERSHIP_CONFIG_ITEMS),
]

const ONBOARDING_PARTNERSHIP_FLOW_CONFIG_PHASE_2: OnboardingFlowConfig = [
  OnboardingFlowType.PARTNERSHIP,
  generateFlow(ONBOARDING_PARTNERSHIP_CONFIG_ITEMS_PHASE_2),
]

export const ONBOARDING_FLOW = new Map<
  OnboardingFlowType | undefined,
  ReturnType<typeof generateFlow>
>([
  ONBOARDING_GOVERNMENT_FLOW_CONFIG,
  ONBOARDING_COMPANY_FOUND_FLOW_CONFIG,
  ONBOARDING_INDIVIDUAL_NO_ABN_FLOW_CONFIG,
  ONBOARDING_INDIVIDUAL_WITH_ABN_FLOW_CONFIG,
  ONBOARDING_TRUST_FLOW_CONFIG,
  ONBOARDING_ASSOCIATION_FLOW_CONFIG,
  ONBOARDING_PARTNERSHIP_FLOW_CONFIG,
])

export const ONBOARDING_FLOW_COMBINED_FORM_PHASE_1 = new Map<
  OnboardingFlowType | undefined,
  ReturnType<typeof generateFlow>
>([
  ONBOARDING_GOVERNMENT_FLOW_CONFIG,
  ONBOARDING_COMPANY_FOUND_FLOW_CONFIG,
  ONBOARDING_INDIVIDUAL_NO_ABN_FLOW_CONFIG_PHASE_1,
  ONBOARDING_INDIVIDUAL_WITH_ABN_FLOW_CONFIG,
  ONBOARDING_TRUST_FLOW_CONFIG,
  ONBOARDING_ASSOCIATION_FLOW_CONFIG,
  ONBOARDING_PARTNERSHIP_FLOW_CONFIG,
])

export const ONBOARDING_FLOW_COMBINED_FORM_PHASE_2 = new Map<
  OnboardingFlowType | undefined,
  ReturnType<typeof generateFlow>
>([
  ONBOARDING_GOVERNMENT_FLOW_CONFIG_PHASE_2,
  ONBOARDING_COMPANY_FOUND_FLOW_CONFIG_PHASE_2,
  ONBOARDING_INDIVIDUAL_NO_ABN_FLOW_CONFIG_PHASE_2,
  ONBOARDING_INDIVIDUAL_WITH_ABN_FLOW_CONFIG_PHASE_2,
  ONBOARDING_TRUST_FLOW_CONFIG_PHASE_2,
  ONBOARDING_ASSOCIATION_FLOW_CONFIG_PHASE_2,
  ONBOARDING_PARTNERSHIP_FLOW_CONFIG_PHASE_2,
])
