import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Divider,
  ErrorMessageForm,
  Flex,
  SelectAdaptive,
  SelectSize,
} from '@npco/zeller-design-system'
import { useField } from 'formik'

import { translationsShared } from 'translations'

interface ItemsImportModalMatchFieldProps {
  isLastField?: boolean
  isLoading: boolean
  items: string[]
  label: string
  name: string
}

export const ItemsImportModalMatchField = ({
  isLastField,
  isLoading,
  items,
  label,
  name,
}: ItemsImportModalMatchFieldProps) => {
  const tShared = useTranslations(translationsShared)
  const [field, meta, helpers] = useField(name)

  const value = items.find((item) => item === field.value)

  const selectedItem = value ? { label: value, value } : null

  return (
    <>
      <Flex alignItems="center">
        <Flex flex={1}>{label}</Flex>
        <Box flex={1}>
          <SelectAdaptive
            isDisabled={isLoading}
            hasError={!!meta.error}
            placeholder={!selectedItem ? tShared('select') : undefined}
            onChange={(selected) => helpers.setValue(selected?.value)}
            items={items.map((item) => ({ label: item, value: item }))}
            selectedItem={selectedItem}
            size={SelectSize.Small}
          />
          {!!meta.error && (
            <ErrorMessageForm hasError errorMessage={meta.error} />
          )}
        </Box>
      </Flex>
      <Divider margin={!isLastField ? '8px 0' : '8px 0 0'} />
    </>
  )
}
