import { UseTranslationsFn } from '@npco/utils-translations'

import { convertLocaleStringToNumber } from 'utils/localeString'

import { NAME_MAX_CHARACTERS } from '../../../CategoryCreateModal/CategoryCreateModal.constants'
import { ItemsImportData } from '../../ItemsImportModal.types'
import { translations } from '../ItemsImportModalMatch.i18n'

const MAX_ERROR_ROW_NUMBERS = 5

export const getRowsData = ({
  headers,
  mappedHeaders,
  rows,
}: {
  headers: (string | null)[]
  mappedHeaders: ItemsImportData
  rows: string[][]
}) => {
  const nameIndex = headers.indexOf(mappedHeaders.name)
  const descriptionIndex = headers.indexOf(mappedHeaders.description)
  const skuIndex = headers.indexOf(mappedHeaders.sku)
  const categoryIndex = headers.indexOf(mappedHeaders.category)
  const priceIndex = headers.indexOf(mappedHeaders.price)
  const gstIndex = headers.indexOf(mappedHeaders.gst)

  return rows.map((data) => ({
    name: data[nameIndex],
    description: data[descriptionIndex],
    sku: data[skuIndex],
    category: data[categoryIndex],
    price: data[priceIndex],
    gst: data[gstIndex],
  }))
}

type AttributeKey = 'category' | 'gst' | 'name' | 'price'

type InvalidGenericTranslationKey = `${AttributeKey}ValueInvalidGeneric`
type InvalidMultipleRowTranslationKey =
  `${AttributeKey}ValueInvalidMultipleRows`
type InvalidSingleRowTranslationKey = `${AttributeKey}ValueInvalidSingleRow`

const invalidTranslationsKeyMap: Record<
  AttributeKey,
  {
    generic: InvalidGenericTranslationKey
    multiple: InvalidMultipleRowTranslationKey
    single: InvalidSingleRowTranslationKey
  }
> = {
  category: {
    generic: 'categoryValueInvalidGeneric',
    multiple: 'categoryValueInvalidMultipleRows',
    single: 'categoryValueInvalidSingleRow',
  },
  gst: {
    generic: 'gstValueInvalidGeneric',
    multiple: 'gstValueInvalidMultipleRows',
    single: 'gstValueInvalidSingleRow',
  },
  name: {
    generic: 'nameValueInvalidGeneric',
    multiple: 'nameValueInvalidMultipleRows',
    single: 'nameValueInvalidSingleRow',
  },
  price: {
    generic: 'priceValueInvalidGeneric',
    multiple: 'priceValueInvalidMultipleRows',
    single: 'priceValueInvalidSingleRow',
  },
}

type MapValidationCallback = (
  row: Nullable<ItemsImportData>,
  index: number
) => number | null

interface DefaultValidateFunctionProps {
  attributeKey: AttributeKey
  mapValidationCallback: MapValidationCallback
  maxLength?: string
}

// NOTE: +2 to set the correct row
// number as Row 1 is the header
const getRowNumber = (index: number) => index + 2

export const getAttributeValidations = ({
  rowsData,
  t,
  values: { category, gst, name, price },
}: {
  rowsData: Nullable<ItemsImportData>[]
  t: UseTranslationsFn<typeof translations>
  values: ItemsImportData
}) => {
  const defaultValidateFunction = ({
    attributeKey,
    mapValidationCallback,
    maxLength,
  }: DefaultValidateFunctionProps) => {
    const allInvalidValue = rowsData
      .map(mapValidationCallback)
      .filter((value): value is number => value !== null)

    if (!allInvalidValue.length) {
      return undefined
    }

    if (allInvalidValue.length === 1) {
      return t(invalidTranslationsKeyMap[attributeKey].single, {
        rowNumber: `${allInvalidValue[0]}`,
        length: maxLength,
      })?.toString()
    }

    if (allInvalidValue.length <= MAX_ERROR_ROW_NUMBERS) {
      return t(invalidTranslationsKeyMap[attributeKey].multiple, {
        rowNumbers: allInvalidValue
          .slice(0, allInvalidValue.length - 1)
          .join(', '),
        endRowNumber: `${allInvalidValue[allInvalidValue.length - 1]}`,
        length: maxLength,
      })?.toString()
    }

    return t(invalidTranslationsKeyMap[attributeKey].generic, {
      length: maxLength,
    })?.toString()
  }

  const validateItemName = () => {
    if (!name) {
      return t('nameAttributeRequired')
    }

    return defaultValidateFunction({
      attributeKey: 'name',
      mapValidationCallback: (row, index) =>
        !row.name?.trim() ? getRowNumber(index) : null,
    })
  }

  const validateCategory = () => {
    if (!category) {
      return t('categoryAttributeRequired')
    }

    return defaultValidateFunction({
      attributeKey: 'category',
      mapValidationCallback: (row, index) => {
        const isCategoryRowEmpty = !row.category?.trim()
        const hasMaxLengthExceededName = row.category
          ?.split('|')
          .some(
            (categoryName) => categoryName?.trim().length > NAME_MAX_CHARACTERS
          )
        return isCategoryRowEmpty || hasMaxLengthExceededName
          ? getRowNumber(index)
          : null
      },
      maxLength: `${NAME_MAX_CHARACTERS}`,
    })
  }

  const validateSku = () => {
    const allSku = rowsData.map((row) => row.sku ?? ''.trim())

    const duplicateSku = [
      ...new Set(
        allSku
          .flatMap((sku, index) => {
            return sku && allSku.indexOf(sku) !== index
              ? [getRowNumber(allSku.indexOf(sku)), getRowNumber(index)]
              : []
          })
          .filter(Boolean)
      ),
    ]

    if (!duplicateSku.length) {
      return undefined
    }

    if (duplicateSku.length > MAX_ERROR_ROW_NUMBERS) {
      return t('skuValueDuplicateGeneric')
    }

    const rowNumbers = duplicateSku.slice(0, duplicateSku.length - 1).join(', ')

    return t('skuValueDuplicateRows', {
      rowNumbers,
      endRowNumber: `${duplicateSku[duplicateSku.length - 1]}`,
    })
  }

  const validatePrice = () => {
    if (!price) {
      return t('priceAttributeRequired')
    }

    return defaultValidateFunction({
      attributeKey: 'price',
      mapValidationCallback: (row, index) => {
        if (!row?.price?.trim()) {
          return getRowNumber(index)
        }
        const numberFromString = convertLocaleStringToNumber(
          row.price.replace('$', '').trim(),
          NaN
        )
        return !Number.isFinite(numberFromString) ? getRowNumber(index) : null
      },
    })
  }

  const validateDefaultGst = () => {
    if (!gst) {
      return t('gstAttributeRequired')
    }

    return defaultValidateFunction({
      attributeKey: 'gst',
      mapValidationCallback: (row, index) =>
        // NOTE: Only accept N and Y values
        row.gst !== 'N' && row.gst !== 'Y' ? getRowNumber(index) : null,
    })
  }

  return {
    validateCategory,
    validateDefaultGst,
    validateItemName,
    validatePrice,
    validateSku,
  }
}
