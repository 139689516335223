import { NotesDisplay } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import {
  ContactDetailSection,
  NoDataAddedContent,
} from '../../ContactGeneral/ContactGeneral.styled'
import { ContactSection } from '../ContactSection'
import { EditNotes } from './EditNotes'

export const NOTES_DATA_TESTID = 'contact-notes-section'

interface NotesProps {
  contactId: string
  contactNotes?: string | null
}

export const Notes = ({ contactId, contactNotes }: NotesProps) => {
  const heading = translate('page.contact.sections.notes.title')

  return (
    <ContactSection
      actionElement={
        <EditNotes contactId={contactId} initialNote={contactNotes || ''} />
      }
      dataTestId={NOTES_DATA_TESTID}
      heading={heading}
    >
      {!contactNotes ? (
        <NoDataAddedContent>
          {translate('page.contact.sections.notes.noData')}
        </NoDataAddedContent>
      ) : (
        <ContactDetailSection>
          <NotesDisplay note={contactNotes} placeholder={heading} />
        </ContactDetailSection>
      )}
    </ContactSection>
  )
}
