import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  errorNotification: 'Settings could not be saved. Please try again.',
  heading: 'GST',
  successNotification: 'Settings saved successfully.',
  taxApplicableLabel: 'New items are GST Applicable',
  taxPriceLabel: 'Prices includes GST',
  taxInclusive: 'GST Included',
  taxExclusive: 'GST Excluded',
  taxInfoMessage:
    'The price for {itemsCount} items will be updated to be {taxStatus}.',
  taxInfoMessageNoItems:
    'Item prices will be displayed to customers with {taxStatus}.',
  itemManagement: 'Item Management',
  autoGenerateSkuLabel: 'Automatically Generate SKUs',
  autoGenerateSkuDescription:
    'Automatically Generate SKUs for any new items. Existing items will not be affected by these settings',
  description: ' These settings can be overridden for individual items.',
})
