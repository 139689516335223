import { useMutation } from '@apollo/client'

import {
  SendInvoice as SendInvoiceResponse,
  SendInvoiceVariables,
} from 'types/gql-types/SendInvoice'

import { SendInvoice } from '../graphql/sendInvoice'

export const useSendInvoice = () => {
  const [sendInvoice, { loading: isSendingInvoice }] = useMutation<
    SendInvoiceResponse,
    SendInvoiceVariables
  >(SendInvoice)

  return {
    sendInvoice: (referenceNumber: string) =>
      sendInvoice({
        variables: {
          referenceNumber,
        },
      }),

    isSendingInvoice,
  }
}
