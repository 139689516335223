import { useCallback, useEffect, useState } from 'react'
import { GetCategoriesSortColumnName } from '@npco/mp-gql-types'

import { useItemManagementContext } from '../components/ItemManagementContext/ItemManagementContext'
import { DEFAULT_GET_LIMIT } from '../ItemManagement.utils'
import { useFilterCategoriesInfiniteLoader } from './useFilterCatagoriesInfiniteLoader'

interface UseFilterCategoriesProps<T> {
  nextFilterInput: T
  setDefaultVisibilityState: () => void
  setNextFilterInput: (nextFilterInput: T) => void
}

export const useFilterCategories = <T>({
  nextFilterInput,
  setDefaultVisibilityState,
  setNextFilterInput,
}: UseFilterCategoriesProps<T>) => {
  const {
    // NOTE: filter categories are preloaded in item management layout
    filterCategories,
    getFilterCategories,
  } = useItemManagementContext()

  const { isLoadingMore, observerContainer } =
    useFilterCategoriesInfiniteLoader()

  useEffect(() => {
    // NOTE: filter categories are preloaded in item management layout. However,
    // sync filter categories to add any newly created or removed categories
    getFilterCategories({
      variables: {
        sort: { ascending: true, columnName: GetCategoriesSortColumnName.Name },
        limit: DEFAULT_GET_LIMIT,
        filter: undefined,
        nextToken: undefined,
      },
    })
  }, [getFilterCategories])

  const [nextLocalFilterInput, setNextLocalFilterInput] =
    useState<T>(nextFilterInput)

  const handleApply = useCallback(() => {
    setNextFilterInput(nextLocalFilterInput)
    setDefaultVisibilityState()
  }, [nextLocalFilterInput, setNextFilterInput, setDefaultVisibilityState])

  return {
    filterCategories,
    handleApply,
    isLoadingMore,
    nextLocalFilterInput,
    observerContainer,
    setNextLocalFilterInput,
  }
}
