import { useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { ButtonLink } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'

import { viewAllTransactionsLinkTranslations } from './ViewAllTransactionsLink.i18n'

export const ViewAllTransactionsLink = () => {
  const navigate = useNavigate()
  const t = useTranslations(viewAllTransactionsLinkTranslations)

  return (
    <ButtonLink
      onClick={() => navigate(ROOT.PORTAL.ACCOUNTS.TRANSACTIONS.path)}
    >
      {t('viewAllTransactions')}
    </ButtonLink>
  )
}
