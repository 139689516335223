import { gql } from '@apollo/client'
import { Box, COLOR, Flex } from '@npco/zeller-design-system'

import { getCustomerDisplayName } from 'utils/customers'
import { translate } from 'utils/translations'

import { CardholderInformationCustomerFragment } from './CardholderInformation.generated'

interface CardholderInformationProps {
  cardOwner: CardholderInformationCustomerFragment
}

export const CardholderInformation = ({
  cardOwner,
}: CardholderInformationProps) => {
  return (
    <Flex
      position="absolute"
      justifyContent="space-between"
      width="298px"
      left="16px"
      top="126px"
    >
      <Box color={COLOR.GREY_250}>
        {translate(
          'component.cardSummary.sensitiveCardInformation.cardholderLabel'
        )}
      </Box>
      <Box color={COLOR.WHITE} fontFamily="Arial" opacity={0.75}>
        {getCustomerDisplayName(cardOwner)}
      </Box>
    </Flex>
  )
}

CardholderInformation.fragments = {
  Customer: gql`
    fragment CardholderInformationCustomerFragment on Customer {
      firstname
      lastname
    }
  `,
}
