import { Flex } from '@npco/zeller-design-system'

import { useGetDeviceInformationQuery } from '../graphql/getDeviceInformation.generated'
import { ActiveNetworkDetails } from './components/ActiveNetworkDetails'
import { InactiveNetworkDetails } from './components/InactiveNetworkDetails'

type NetworkDetailsProps = {
  deviceUuid: string
}

export const NetworkDetails = ({ deviceUuid }: NetworkDetailsProps) => {
  const { data, loading } = useGetDeviceInformationQuery({
    variables: {
      deviceUuid,
    },
    fetchPolicy: 'cache-and-network',
  })
  const networkDetails = data?.getDeviceInformation?.network

  return (
    <Flex flexDirection="column" gap="16px">
      <ActiveNetworkDetails
        isLoading={loading}
        networkDetails={networkDetails}
      />
      <InactiveNetworkDetails
        isLoading={loading}
        networkDetails={networkDetails}
      />
    </Flex>
  )
}
