import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import {
  GetXeroBrandingThemes as GetXeroBrandingThemesResponse,
  GetXeroBrandingThemesVariables,
} from 'types/gql-types/GetXeroBrandingThemes'

import { GetXeroBrandingThemes } from '../graphql/GetXeroBrandingThemes'

export const useGetXeroBrandingThemes = (xeroOrganisationUuid: string) => {
  const { loading, data, error, refetch } = useQuery<
    GetXeroBrandingThemesResponse,
    GetXeroBrandingThemesVariables
  >(GetXeroBrandingThemes, {
    variables: {
      xeroOrganisationUuid,
    },
  })

  const xeroBrandingThemes = useMemo(() => {
    if (!data?.getXeroBrandingThemes) {
      return []
    }
    return data?.getXeroBrandingThemes
  }, [data?.getXeroBrandingThemes])

  return {
    isLoading: loading,
    xeroBrandingThemes,
    error,
    refetch,
  }
}
