import { useTranslations } from '@npco/utils-translations'

import { ROOT } from 'const/routes'

import { translations } from './BiometricVerificationSection.i18n'
import {
  Section,
  SectionDescription,
  SectionLink,
  SectionTitle,
} from './components'

export const BiometricVerificationSection = () => {
  const t = useTranslations(translations)

  return (
    <Section>
      <SectionTitle>{t('title')}</SectionTitle>
      <SectionDescription>{t('description')}</SectionDescription>
      <SectionLink to={ROOT.PORTAL.BIOMETRICS.path}>
        {t('linkLabel')}
      </SectionLink>
    </Section>
  )
}
