import { useGetEntityAddressTimezoneQuery } from './graphql/getEntityAddressTimezone.generated'

interface UseGetEntityAddressTimezoneProps {
  skip?: boolean
}

export const useGetEntityAddressTimezone = ({
  skip,
}: UseGetEntityAddressTimezoneProps = {}) => {
  const { data, loading } = useGetEntityAddressTimezoneQuery({
    skip,
  })

  return { timezone: data?.getEntityAddressTimeZone, isLoading: loading }
}
