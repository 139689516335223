import { useHistory } from 'react-router-dom'
import { useTranslations } from '@npco/utils-translations'
import { AnchorBasic, BUTTON_SIZE } from '@npco/zeller-design-system'

import { JOIN_WAIT_LIST_EXTERNAL_LINK } from 'const/externalLinks'
import { ROUTE } from 'const/routes'
import { AuthLayout } from 'layouts'
import { ErrorPage } from 'components/Placeholders/ErrorPage/ErrorPage'
import {
  PlaceholderAlignment,
  PlaceholderSize,
} from 'components/Placeholders/Placeholders.types'

import { translations } from './JoinWaitList.i18n'
import { StyledPrimaryAction } from './JoinWaitList.styled'

export const JoinWaitList = () => {
  const history = useHistory()
  const t = useTranslations(translations)
  return (
    <AuthLayout isLogInPage>
      <ErrorPage
        title={t('title')}
        description={
          <>
            {t('description', {
              link: (
                <AnchorBasic
                  href={JOIN_WAIT_LIST_EXTERNAL_LINK}
                  data-testid="waitlist-link"
                >
                  {t('linkWaitListLabel')}
                </AnchorBasic>
              ),
            })}
          </>
        }
        alignment={PlaceholderAlignment.Left}
        variant={PlaceholderSize.ExtraLarge}
      >
        <StyledPrimaryAction
          size={BUTTON_SIZE.LARGE}
          data-testid="back-to-login"
          onClick={() => {
            history.push(ROUTE.LOGIN)
          }}
        >
          {t('primaryButtonLabel')}
        </StyledPrimaryAction>
      </ErrorPage>
    </AuthLayout>
  )
}
