import { Navigate, Outlet, Route, Routes } from 'react-router-dom-v5-compat'
import { CustomerRole, KycStatus } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { AccountsTransactionsMetadataProvider } from 'features/AccountsTransactions'
import { OutstandingFilterContextProvider } from 'features/Cards/CardSingle/context/OutstandingFilterContextProvider'

import {
  AddCardStage,
  CardTypeOption,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { NotFound } from 'pages/NotFound'
import { AccountFilterValuesWrapper } from 'layouts/AccountLayout/AccountFilterValuesWrapper/AccountFilterValuesWrapper'
import { FilterableTransactionType } from 'layouts/AccountLayout/filters/AccountFilterTransactionType/AccountFilterTransactionType.constants'
import { FilterConfig } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues.types'
import { IDVRequirementScreen } from 'components/IDVRequirementScreen'

import { AllCards } from '../../AllCards'
import { AllCardsCorporate } from '../../AllCardsCorporate'
import { AllCardsDebit } from '../../AllCardsDebit'
import { CardSingle } from '../../CardSingle'
import { CardsSettingsOutstandingExpenses } from '../../CardsSettings/CardsSettingsOutstandingExpenses'
import { CardsListLayout, CardsSettingsLayout } from '../../components'
import { CardsActivateRoutes } from './CardsActivateRoutes'

export const cardTransactionFiltersConfig: Partial<FilterConfig> = {
  transactionType: {
    items: [
      {
        label: translate('component.transactionTypeFilter.labels.moneyIn'),
        value: FilterableTransactionType.MoneyIn,
      },
      {
        label: translate('component.transactionTypeFilter.labels.moneyOut'),
        value: FilterableTransactionType.MoneyOut,
      },
    ],
  },
}

// "UNVERIFIED" is undefined
const IDV_MISSING = [KycStatus.REQUIRED]
const IDV_PENDING = [KycStatus.IN_REVIEW]
const IDV_ABANDONED = [KycStatus.RC_ABANDONED]

export const CardsRoutes = () => {
  const user = useLoggedInUser()

  const { userRole, userData } = user
  const userKycStatus = userData?.kycStatus ?? undefined

  if (userRole === CustomerRole.MANAGER) {
    if (!userKycStatus || IDV_MISSING.includes(userKycStatus)) {
      return (
        <IDVRequirementScreen
          description={translate('page.cards.idvMissing')}
        />
      )
    }
    if (IDV_PENDING.includes(userKycStatus)) {
      return (
        <IDVRequirementScreen
          description={translate('page.cards.idvPending')}
        />
      )
    }
    if (IDV_ABANDONED.includes(userKycStatus)) {
      return (
        <IDVRequirementScreen
          description={translate('page.cards.idvAbandoned')}
        />
      )
    }
    if (userKycStatus !== KycStatus.VERIFIED) {
      return <IDVRequirementScreen />
    }
  }

  return (
    <AccountsTransactionsMetadataProvider>
      <AccountFilterValuesWrapper initialConfig={cardTransactionFiltersConfig}>
        <Routes>
          <Route
            element={
              <CardsListLayout>
                <Outlet />
              </CardsListLayout>
            }
          >
            <Route index element={<AllCards />} />
            <Route
              path={ROOT.PORTAL.CARDS.DEBIT.relative}
              element={<AllCardsDebit />}
            />
            <Route path={`${ROOT.PORTAL.CARDS.CORPORATE.relative}/*`}>
              <Route index element={<AllCardsCorporate />} />
              <Route
                path={ROOT.PORTAL.CARDS.CORPORATE.NEW.relative}
                element={
                  <Navigate
                    to={ROOT.PORTAL.CARDS.CORPORATE.path}
                    state={{
                      AddCardModal: {
                        stage: AddCardStage.CreateSelectCardholderStage,
                        cardType: CardTypeOption.ExpenseCard,
                      },
                    }}
                    replace
                  />
                }
              />
            </Route>
          </Route>
          <Route
            path={ROOT.PORTAL.CARDS.ACTIVATE.relative}
            element={<CardsActivateRoutes />}
          />

          <Route
            path={ROOT.PORTAL.CARDS.SETTINGS.relative}
            element={
              <CardsSettingsLayout>
                <Outlet />
              </CardsSettingsLayout>
            }
          >
            <Route
              index
              element={
                <Navigate
                  to={ROOT.PORTAL.CARDS.SETTINGS.OUTSTANDING_EXPENSES.path}
                  replace
                />
              }
            />
            <Route
              path={ROOT.PORTAL.CARDS.SETTINGS.OUTSTANDING_EXPENSES.relative}
              element={<CardsSettingsOutstandingExpenses />}
            />
          </Route>
          <Route
            path={ROOT.PORTAL.CARDS.CARD().relative}
            element={
              <OutstandingFilterContextProvider>
                <CardSingle />
              </OutstandingFilterContextProvider>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AccountFilterValuesWrapper>
    </AccountsTransactionsMetadataProvider>
  )
}

export default CardsRoutes
