import { COLOR, Flex, SvgIcon, useModalState } from '@npco/zeller-design-system'

import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg'
import { translate } from 'utils/translations'

import { StyledButton } from '../ContactsLayout.styled'
import {
  StyledButtonGhost,
  StyledDivider,
  StyledModalBasic,
} from './AddContactModalMobile.styled'

export const ADD_CONTACT_TESTID = 'contacts-add-button'
export const ADD_BUSINESS_TESTID = 'contacts-add-business-button'
export const ADD_PERSON_TESTID = 'contacts-add-person-button'

export const AddContactModalMobile = ({
  openAddBusinessModal,
  openAddPersonModal,
}: {
  openAddBusinessModal: () => void
  openAddPersonModal: () => void
}) => {
  const {
    isModalOpen: isMobileAddContactOpen,
    openModal: openMobileAddContact,
    closeModal: closeMobileAddContact,
  } = useModalState()

  return (
    <>
      <Flex>
        <StyledButton
          dataTestId={ADD_CONTACT_TESTID}
          onClick={openMobileAddContact}
        >
          <SvgIcon color={COLOR.WHITE} width="14" height="14">
            <PlusIcon />
          </SvgIcon>
        </StyledButton>
      </Flex>
      <StyledModalBasic
        onCancel={closeMobileAddContact}
        isOpen={isMobileAddContactOpen}
      >
        <>
          <StyledButtonGhost
            dataTestId={ADD_BUSINESS_TESTID}
            onClick={() => {
              openAddBusinessModal()
              closeMobileAddContact()
            }}
          >
            {translate('page.contacts.buttons.addBusiness')}
          </StyledButtonGhost>
          <StyledDivider />
          <StyledButtonGhost
            dataTestId={ADD_PERSON_TESTID}
            onClick={() => {
              openAddPersonModal()
              closeMobileAddContact()
            }}
          >
            {translate('page.contacts.buttons.addPerson')}
          </StyledButtonGhost>
        </>
      </StyledModalBasic>
    </>
  )
}
