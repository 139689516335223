import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type DeleteItemCategoryMutationVariables = Types.Exact<{
  categoryUuid: Types.Scalars['ID']['input']
  newCategoryUuid: Types.InputMaybe<Types.Scalars['ID']['input']>
}>

export type DeleteItemCategoryMutationResponse = {
  __typename?: 'Mutation'
  deleteItemCategory: {
    __typename: 'ItemCategory'
    id: string
    status: Types.ItemCategoryStatus | null
  }
}

export const DeleteItemCategory = gql`
  mutation DeleteItemCategory($categoryUuid: ID!, $newCategoryUuid: ID) {
    deleteItemCategory(
      categoryUuid: $categoryUuid
      newCategoryUuid: $newCategoryUuid
    ) {
      __typename
      id
      status
    }
  }
` as unknown as TypedDocumentNode<
  DeleteItemCategoryMutationResponse,
  DeleteItemCategoryMutationVariables
>
export type DeleteItemCategoryMutationFn = Apollo.MutationFunction<
  DeleteItemCategoryMutationResponse,
  DeleteItemCategoryMutationVariables
>

/**
 * __useDeleteItemCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemCategoryMutation, { data, loading, error }] = useDeleteItemCategoryMutation({
 *   variables: {
 *      categoryUuid: // value for 'categoryUuid'
 *      newCategoryUuid: // value for 'newCategoryUuid'
 *   },
 * });
 */
export function useDeleteItemCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteItemCategoryMutationResponse,
    DeleteItemCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteItemCategoryMutationResponse,
    DeleteItemCategoryMutationVariables
  >(DeleteItemCategory, options)
}
export type DeleteItemCategoryMutationHookResult = ReturnType<
  typeof useDeleteItemCategoryMutation
>
export type DeleteItemCategoryMutationResult =
  Apollo.MutationResult<DeleteItemCategoryMutationResponse>
export type DeleteItemCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteItemCategoryMutationResponse,
  DeleteItemCategoryMutationVariables
>
