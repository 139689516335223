import { useMutation } from '@apollo/client'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { RemoveContactImage } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/contacts'

import { translate } from 'utils/translations'
import {
  RemoveContactImage as RemoveContactImagePayload,
  RemoveContactImageVariables,
} from 'types/gql-types/RemoveContactImage'

export const useRemoveContactImage = (
  onImageRemoved: () => void,
  closeModal: () => void
) => {
  const [removeContactImage, { loading: isRemovingContactImage }] = useMutation<
    RemoveContactImagePayload,
    RemoveContactImageVariables
  >(RemoveContactImage, {
    onCompleted: (responseData: RemoveContactImagePayload) => {
      const isImageRemoved = responseData.removeContactImage

      if (!isImageRemoved) {
        showErrorToast(
          translate('page.contact.sections.imageUpload.failureCopy')
        )
        closeModal()
        return
      }

      showSuccessToast(
        translate('page.contact.sections.imageUpload.successCopy')
      )
      onImageRemoved()
      closeModal()
    },
    onError: () => {
      showErrorToast(translate('page.contact.sections.imageUpload.failureCopy'))
      closeModal()
    },
  })

  return {
    removeContactImage: (contactUuid: string) =>
      removeContactImage({
        variables: {
          contactUuid,
        },
      }),
    isRemovingContactImage,
  }
}
