import { createTranslations } from '@npco/utils-translations'

export const setupFlowDownloadStageTranslations = createTranslations({
  title: 'Manage everything with Zeller App.',
  description:
    'Accept payments, create accounts, issue cards, and track sales with the free Zeller App.',
  point1: 'Accept contactless payments.',
  point2: 'Add accounts and issue cards.',
  point3: 'Real-time financial reporting.',
  point4: 'Create new or remove existing users.',
})
