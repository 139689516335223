import { currencyFormatter } from 'utils/common'
import dayjs from 'utils/dayjs'

import { ChartData } from '../Charts.types'

export const formatDayTicks = (tick: number) => {
  return dayjs(tick).format('D')
}

export const formatMonthTicks = (tick: number) =>
  dayjs(tick).format('MMM').toUpperCase()

export const formatDollarValueTicks = (value: number) => {
  if (value < 100000) {
    return currencyFormatter(value).split('.')[0]
  }

  if (value < 100000000) {
    const roundedStartAmount = value / 100000

    const stringifiedAmount = roundedStartAmount.toFixed(2)

    if (stringifiedAmount.endsWith('00')) {
      return `$${roundedStartAmount.toFixed(0)}K`
    }

    return `$${stringifiedAmount}K`
  }

  const roundedStartAmount = value / 100000000

  const stringifiedAmount = roundedStartAmount.toFixed(2)

  if (stringifiedAmount.endsWith('00')) {
    return `$${roundedStartAmount.toFixed(0)}M`
  }

  return `$${stringifiedAmount}M`
}

export const getYAxisWidth = (chartData: ChartData) => {
  if (chartData.length === 0) {
    return undefined
  }

  const highestValue = chartData
    .map((data) => data.value ?? 0)
    .reduce((acc, cur) => (cur > acc ? cur : acc))

  const longestTickLength = formatDollarValueTicks(highestValue).length

  const yAxisWidth = longestTickLength * 11

  return yAxisWidth < 60 ? 60 : yAxisWidth
}
