import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetSites } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/sites'

import {
  GetSites as GetSitesType,
  GetSites_getSites_sites as SiteData,
} from 'types/gql-types/GetSites'

export const useGetEntitySites = () => {
  const [entitySites, setEntitySites] = useState<SiteData[]>([])

  const { loading } = useQuery<GetSitesType>(GetSites, {
    variables: {
      limit: 30,
    },
    onCompleted: (data) => {
      const sitesData = data?.getSites.sites
      setEntitySites(sitesData as SiteData[])
    },
    onError: (error) => {
      showApiErrorToast(error)
    },
  })

  return {
    isSitesLoading: loading,
    entitySites,
  }
}
