import { FeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { keys } from 'ramda'

import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { GetConnections as GetConnectionsResponse } from 'types/gql-types/GetConnections'

import { HLIcon } from './components/HLIcon'
import { OracleIcon } from './components/OracleIcon'
import { XeroIcon } from './components/XeroIcon'
import { Connection } from './Connections.types'

type ConnectionConfig = Omit<Connection, 'id' | 'status' | 'errorType'>
type ConnectionsConfig = {
  [key: string]: ConnectionConfig
}

const xeroBankFeedsConfig: ConnectionConfig = {
  title: translate('page.settings.connections.xeroBankFeeds.card.title'),
  subTitle: translate('page.settings.connections.xeroBankFeeds.card.subTitle'),
  routeInitalise: ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_BANK_FEEDS.path,
  routeManage: ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_BANK_FEEDS_MANAGE.path,
  icon: XeroIcon,
}

export const xeroPaymentServicesConfig: ConnectionConfig = {
  title: translate('page.settings.connections.xeroPaymentServices.card.title'),
  subTitle: translate(
    'page.settings.connections.xeroPaymentServices.card.subTitle'
  ),
  routeInitalise: ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES.path,
  routeManage:
    ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.path,
  icon: XeroIcon,
}

export const payAtTableConfig: ConnectionConfig = {
  title: translate('page.settings.connections.payAtTable.title'),
  subTitle: translate('page.settings.connections.payAtTable.description'),
  routeInitalise: ROOT.PORTAL.SETTINGS.CONNECTIONS.HL_POS.MANAGE.path,
  routeManage: ROOT.PORTAL.SETTINGS.CONNECTIONS.HL_POS.MANAGE.path,
  icon: HLIcon,
}

export const oraclePosConfig: ConnectionConfig = {
  title: translate('page.settings.connections.oraclePost.title'),
  subTitle: translate('page.settings.connections.oraclePost.subTitle'),
  routeInitalise: ROOT.PORTAL.SETTINGS.CONNECTIONS.ORACLE_POS.path,
  routeManage: ROOT.PORTAL.SETTINGS.CONNECTIONS.ORACLE_POS.MANAGE.path,
  icon: OracleIcon,
}

export const getConnectionsConfigs = (
  flags?: FeatureFlags
): ConnectionsConfig => ({
  xeroBankFeeds: xeroBankFeedsConfig,
  xeroPaymentServices: xeroPaymentServicesConfig,
  payAtTable: payAtTableConfig,
  oraclePos: oraclePosConfig,
})

export const buildConnections = (
  payload?: GetConnectionsResponse,
  flags?: FeatureFlags
): Connection[] => {
  const hasConnectionConfig = (type: string) =>
    !!getConnectionsConfigs(flags)[type]

  if (!payload) {
    return []
  }

  return keys(payload).reduce<Connection[]>((result, key) => {
    if (!hasConnectionConfig(key)) {
      return result
    }

    const connection: Connection = {
      ...payload[key],
      ...getConnectionsConfigs(flags)[key],
    }
    return [...result, connection]
  }, [])
}
