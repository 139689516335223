import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type CreateItemMutationVariables = Types.Exact<{
  item: Types.CreateItemInput
}>

export type CreateItemMutationResponse = {
  __typename?: 'Mutation'
  createItem: {
    __typename?: 'Item'
    id: string
    name: string
    description: string | null
    price: number
    productId: string | null
    categories: Array<{
      __typename?: 'ItemCategory'
      color: string
      id: string
      name: string
    }> | null
  }
}

export const CreateItem = gql`
  mutation CreateItem($item: CreateItemInput!) {
    createItem(input: $item) {
      id
      categories {
        color
        id
        name
      }
      name
      description
      price
      productId
    }
  }
` as unknown as TypedDocumentNode<
  CreateItemMutationResponse,
  CreateItemMutationVariables
>
export type CreateItemMutationFn = Apollo.MutationFunction<
  CreateItemMutationResponse,
  CreateItemMutationVariables
>

/**
 * __useCreateItemMutation__
 *
 * To run a mutation, you first call `useCreateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemMutation, { data, loading, error }] = useCreateItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useCreateItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateItemMutationResponse,
    CreateItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateItemMutationResponse,
    CreateItemMutationVariables
  >(CreateItem, options)
}
export type CreateItemMutationHookResult = ReturnType<
  typeof useCreateItemMutation
>
export type CreateItemMutationResult =
  Apollo.MutationResult<CreateItemMutationResponse>
export type CreateItemMutationOptions = Apollo.BaseMutationOptions<
  CreateItemMutationResponse,
  CreateItemMutationVariables
>
