import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { ConnectionType } from '@npco/mp-gql-types'
import { Box, DrawerWithIcon, Flex } from '@npco/zeller-design-system'

import { ReactComponent as XeroLogo } from 'assets/svg/logos/xero.svg'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'

import { XeroConnectionAgreement } from '../components/XeroConnectionAgreement'
import { generateXeroOauthScopeAuthorizeURI } from '../Connections.utils'
import { Description } from './BankFeedsConnection.styled'

export const BankFeedsConnection = () => {
  const navigate = useNavigate()
  const handleXeroRedirection = useCallback(() => {
    window.location.replace(
      generateXeroOauthScopeAuthorizeURI(ConnectionType.XERO_BANKFEEDS)
    )
  }, [])

  return (
    <DrawerWithIcon
      isOpen
      icon={
        <Flex justifyContent="left">
          <XeroLogo width={48} height={48} />
        </Flex>
      }
      drawerTitle={translate('page.settings.connections.xeroBankFeeds.title')}
      onClose={() => navigate(ROOT.PORTAL.SETTINGS.CONNECTIONS.path)}
      overlayClassName="animated-drawer-overlay"
    >
      <Flex flexDirection="column">
        <Box mb="24px">
          <Description>
            {translate('page.settings.connections.xeroBankFeeds.description')}
          </Description>
        </Box>

        <XeroConnectionAgreement onSubmit={handleXeroRedirection} />
      </Flex>
    </DrawerWithIcon>
  )
}
