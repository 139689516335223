import { useCallback, useMemo } from 'react'
import { NetworkStatus, useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetSites } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/sites'
import { rvNewSites } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { getStorageDeduplicatedSites } from 'utils/newSitesHelper'
import {
  GetSites as GetSitesType,
  GetSites_getSites_sites as SiteData,
  GetSitesVariables,
} from 'types/gql-types/GetSites'
import { SiteCache } from 'types/site'

interface UseGetSitesArguments {
  pageSize?: number
  fetchPolicy?: WatchQueryFetchPolicy
  nextFetchPolicy?: WatchQueryFetchPolicy
  skip?: boolean
}

const SITES_QUERY_DEFAULT_PAGE_SIZE = 30

export const useGetSites = ({
  pageSize = SITES_QUERY_DEFAULT_PAGE_SIZE,
  fetchPolicy = 'network-only',
  nextFetchPolicy = 'cache-first',
  skip,
}: UseGetSitesArguments = {}) => {
  const { data, fetchMore, networkStatus, error, refetch } = useQuery<
    GetSitesType,
    GetSitesVariables
  >(GetSites, {
    fetchPolicy,
    errorPolicy: 'all',
    nextFetchPolicy,
    skip,
    variables: { limit: pageSize },
    onError: (err) => {
      showApiErrorToast(err)
    },
    notifyOnNetworkStatusChange: true,
  })

  const onLoadMore = useCallback(async () => {
    const nextToken = data?.getSites?.nextToken

    if (!nextToken?.type) {
      return null
    }

    return fetchMore?.({
      variables: {
        limit: pageSize,
        nextToken,
      },
    })
  }, [data, fetchMore, pageSize])

  const sites = useMemo<SiteData[]>(() => {
    if (!data) {
      return []
    }

    const newSitesData: SiteCache[] | undefined = rvNewSites()
    // INFO: ZD-1208 improve implementation to achieve TS requirements
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return getStorageDeduplicatedSites(newSitesData, data.getSites.sites)
  }, [data])

  const hasMore = !!data?.getSites?.nextToken

  const isLoading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.refetch

  const isLoadingMore = networkStatus === NetworkStatus.fetchMore

  return {
    data,
    sites,
    isLoading,
    isLoadingMore,
    onLoadMore,
    hasMore,
    error,
    refetch,
  }
}
