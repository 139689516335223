import { useCallback, useEffect, useRef } from 'react'
import { InvoiceItemUnit } from '@npco/mp-gql-types'
import {
  ErrorMessageForm,
  INPUT_SIZE,
  InputAdaptive,
} from '@npco/zeller-design-system'
import currency from 'currency.js'
import {
  INVOICE_DEFAULT_PRICE,
  INVOICE_DEFAULT_UNIT_QUANTITY,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceItem } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useField } from 'formik'

import { useInputHandlers } from 'hooks/useInputHandlers/useInputHandlers'
import { usePrevious } from 'hooks/usePrevious'
import {
  convertLocaleStringToNumber,
  convertNumberToLocaleString,
} from 'utils/localeString'
import { translate } from 'utils/translations'
import { CurrencySymbolWrapper } from 'components/InputAdaptiveCurrencyField/InputAdaptiveCurrencyField.styled'

import {
  getTaxRate,
  TAX_INCLUSIVE_RATE,
} from '../../InvoiceItemsAccordion.utils'
import { InvoiceItemPriceClear } from './InvoiceItemPriceClear/InvoiceItemPriceClear'

const renderLeftControls = () => (
  <CurrencySymbolWrapper>$</CurrencySymbolWrapper>
)

export interface InvoiceItemPriceProps {
  isTaxApplicable: boolean
  isTaxInclusive: boolean
  lineIndex: number
  name: string
}

export const InvoiceItemPrice = ({
  isTaxApplicable,
  isTaxInclusive,
  lineIndex,
  name,
}: InvoiceItemPriceProps) => {
  const [itemField, , itemHelpers] = useField<InvoiceItem>(name)
  const [, priceMeta, priceHelpers] = useField<currency>(`${name}.price`)

  const inputRef = useRef<HTMLInputElement>(null)
  const itemRef = useRef<InvoiceItem>(itemField.value)

  const { handleCurrencyMax6DigitInputKeydown } = useInputHandlers()

  const dataTestId = `invoicing-invoice-line-item-price-${lineIndex}`

  const taxRate = getTaxRate(isTaxApplicable, isTaxInclusive)

  // NOTE: set default value for initial render of editable input
  const defaultValue = currency(
    itemRef.current.price
      .multiply(taxRate)
      .multiply(convertLocaleStringToNumber(itemRef.current.quantity))
  ).toString()

  const hasError = Boolean(priceMeta.error)
  const previousIsTaxInclusive = usePrevious(isTaxInclusive)
  const previousItem = usePrevious({ ...itemRef.current })

  useEffect(() => {
    itemRef.current = itemField.value
  }, [itemField.value])

  // NOTE: update price if invoice tax type changes
  useEffect(() => {
    if (
      previousIsTaxInclusive !== undefined &&
      previousIsTaxInclusive !== isTaxInclusive &&
      inputRef.current
    ) {
      const rate = getTaxRate(isTaxApplicable, isTaxInclusive)
      const nextPrice = itemRef.current.price.multiply(rate)

      inputRef.current.value = convertNumberToLocaleString(nextPrice.value)
    }
  }, [isTaxApplicable, isTaxInclusive, previousIsTaxInclusive])

  // NOTE: update price when catalog item uuid or quantity changes
  useEffect(() => {
    if (inputRef.current && itemRef.current && previousItem) {
      // NOTE: if initial price changes we've set a catalog item or a recently
      // used line item
      const hasInitialPriceChanged =
        previousItem.initialPrice.intValue !==
        itemRef.current.initialPrice.intValue

      const hasQuantityChanged =
        previousItem.quantity !== itemRef.current.quantity

      if (hasInitialPriceChanged || hasQuantityChanged) {
        const rate = getTaxRate(isTaxApplicable, isTaxInclusive)
        const nextPrice = itemRef.current.price.multiply(rate)

        inputRef.current.value = convertNumberToLocaleString(nextPrice.value)
      }
    }
  }, [isTaxApplicable, isTaxInclusive, previousItem])

  const ariaLabel = translate(
    'page.invoice.formSections.items.lineItemAriaLabel',
    {
      index: lineIndex,
      field: 'price',
    }
  )

  const handleOnClearCalculation = useCallback(() => {
    itemHelpers.setValue({
      ...itemRef.current,
      hasCalculation: false,
      initialQuantity: itemRef.current.initialQuantity,
      initialPrice: itemRef.current.initialPrice,
      price: itemRef.current.initialPrice,
      quantity: INVOICE_DEFAULT_UNIT_QUANTITY,
      unit: InvoiceItemUnit.QUANTITY,
    })
  }, [itemHelpers])

  const handleOnBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      priceHelpers.setTouched(true)

      if (inputRef?.current) {
        const { value } = event.target
        const number = Number(value)

        if (!value || Number.isNaN(number)) {
          inputRef.current.value = INVOICE_DEFAULT_PRICE
          return
        }

        inputRef.current.value = convertNumberToLocaleString(number)
      }
    },
    [priceHelpers]
  )

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      const number = Number(value)

      if (!value || Number.isNaN(number)) {
        itemHelpers.setValue({
          ...itemRef.current,
          price: currency(INVOICE_DEFAULT_PRICE, { precision: 4 }),
        })

        return
      }

      const nextPrice =
        isTaxApplicable && isTaxInclusive
          ? currency(number, { precision: 4 }).divide(TAX_INCLUSIVE_RATE)
          : currency(number, { precision: 4 })

      itemHelpers.setValue({
        ...itemRef.current,
        price: nextPrice,
      })
    },
    [isTaxApplicable, isTaxInclusive, itemHelpers]
  )

  const handleOnFocus = useCallback((event) => {
    const isZeroPrice = event.target.value === INVOICE_DEFAULT_PRICE

    if (inputRef?.current) {
      // NOTE: if zero price we clear the value so the user can more easily type
      // in their next value
      if (isZeroPrice) {
        inputRef.current.value = ''
      } else {
        inputRef.current.value = inputRef.current.value.replace(/,/g, '')
      }
    }
  }, [])

  const renderRightControls = useCallback(() => {
    const rate = getTaxRate(isTaxApplicable, isTaxInclusive)
    const calculatedInitialUnitPrice = currency(
      itemRef.current.initialPrice.multiply(rate)
    ).format()

    return (
      <InvoiceItemPriceClear
        onClearPrice={handleOnClearCalculation}
        unitPrice={calculatedInitialUnitPrice}
      />
    )
  }, [handleOnClearCalculation, isTaxApplicable, isTaxInclusive])

  if (itemRef.current.hasCalculation) {
    const rate = getTaxRate(isTaxApplicable, isTaxInclusive)

    const calculatedPrice = currency(
      itemRef.current.price
        .multiply(rate)
        .multiply(convertLocaleStringToNumber(itemRef.current.quantity))
    ).value

    const value = convertNumberToLocaleString(calculatedPrice)

    return (
      <div key="invoice-price-calculation">
        <InputAdaptive
          aria-label={ariaLabel}
          data-testid={dataTestId}
          disabled
          hasError={Boolean(hasError)}
          name={name}
          renderLeftControls={renderLeftControls}
          renderRightControls={renderRightControls}
          size={INPUT_SIZE.SMALL}
          style={{ textAlign: 'right' }}
          value={value}
        />
        <ErrorMessageForm
          hasError={Boolean(hasError)}
          errorMessage={priceMeta.error}
        />
      </div>
    )
  }

  return (
    <div key="invoice-price-editable">
      <InputAdaptive
        aria-label={ariaLabel}
        data-testid={dataTestId}
        defaultValue={defaultValue}
        hasError={Boolean(hasError)}
        inputMode="numeric"
        name={name}
        onKeyDown={handleCurrencyMax6DigitInputKeydown}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        ref={inputRef}
        renderLeftControls={renderLeftControls}
        size={INPUT_SIZE.SMALL}
        style={{ textAlign: 'right' }}
      />
      <ErrorMessageForm
        hasError={Boolean(hasError)}
        errorMessage={priceMeta.error}
      />
    </div>
  )
}
