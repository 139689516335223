import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { Box, Heading, ModalBasic } from '@npco/zeller-design-system'
import { rvProfileSettings } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/users'
import { useAppStateMapper } from 'auth/useAppStateMapper'
import { useModalState } from 'design-system/Components/Modal'
import { ModalVerificationRequired } from 'features/MFA'
import { Formik } from 'formik'

import { ROOT } from 'const/routes'
import { getInitialProfilePersonalValues } from 'utils/profile'
import { ProfileSettingsApiCallTypeEnum } from 'types/auth'
import { ProfileSettingFormValuesWithEmail } from 'types/profile'
import { useRequestEmailChange } from 'pages/Settings/Profile/Personal/Edit/hooks/useRequestEmailChange'
import { useUpdateCustomer } from 'pages/Settings/Profile/Personal/Edit/hooks/useUpdateCustomer'
import { ProfilePersonalAddress } from 'pages/Settings/Profile/Personal/Edit/ProfilePersonalAddress/ProfilePersonalAddress'
import {
  updateCustomerAndRequestEmailChange,
  updateEmail,
} from 'pages/Settings/Profile/Personal/Edit/ProfilePersonalEdit.utils'
import { ProfilePersonalEmail } from 'pages/Settings/Profile/Personal/Edit/ProfilePersonalEmail/ProfilePersonalEmail'
import { ProfilePersonalInformation } from 'pages/Settings/Profile/Personal/Edit/ProfilePersonalInformation/ProfilePersonalInformation'
import { useHasElevatedScope } from 'pages/Settings/Profile/Personal/useHasElevatedScope'
import { SettingsFormLayout } from 'layouts/SettingsFormLayout'
import { SpinnerWrapped } from 'components/Spinner'
import { page } from 'translations'

import { getHasCompletedIDV } from '../ProfilePersonal.utils'
import { useMFAProfilePersonal } from '../useMFAProfilePersonal'

const fieldNames = ['firstname', 'middlename', 'lastname']

export const ProfilePersonalEdit = () => {
  const navigate = useNavigate()
  const { isModalOpen, closeModal, openModal } = useModalState({
    isOpenByDefault: false,
  })
  const [newEmail, setNewEmail] = useState<string>('')
  const customerDetails = useReactiveVar(rvProfileSettings)
  const { mapProfileSettingsStateToAuthObject } = useAppStateMapper()
  const { userData, setUserData } = useLoggedInUser()
  const customerUuid = useMemo(() => userData?.id as string, [userData])
  const { requestEmailChange, isRequestingEmailChange } =
    useRequestEmailChange()
  const { updateCustomer, isUpdatingCustomer } = useUpdateCustomer()

  const returnToSettingsPage = () =>
    navigate(ROOT.PORTAL.SETTINGS.PROFILE.PERSONAL.path)

  const hasCompletedIDV = getHasCompletedIDV(customerDetails)

  const isRegisteringIndividual = Boolean(customerDetails.registeringIndividual)

  const { hasElevatedScope, stepUpAuth, loading, refetch } =
    useHasElevatedScope()

  useMFAProfilePersonal({
    refetch,
    requestEmailChange,
    openModal,
    redirect: returnToSettingsPage,
  })

  const onRedirect = () => {
    const hasEmailChanged = newEmail && newEmail !== customerDetails.email
    if (hasEmailChanged) {
      const appState = mapProfileSettingsStateToAuthObject(
        ProfileSettingsApiCallTypeEnum.RESEND_EMAIL,
        { input: { newEmail } },
        'resendEmail'
      )
      stepUpAuth(appState)
      return
    }

    stepUpAuth()
  }

  const handleSubmit = (values: ProfileSettingFormValuesWithEmail) => {
    setNewEmail(values.email || '')

    const initialEmailValue = customerDetails.email || ''

    if (isRegisteringIndividual || hasCompletedIDV) {
      updateEmail({
        initialEmailValue,
        values,
        requestEmailChange,
        redirect: returnToSettingsPage,
        openModal,
      })

      return
    }

    updateCustomerAndRequestEmailChange({
      initialEmailValue,
      values,
      customerUuid,
      customerDetails,
      updateCustomer,
      requestEmailChange,
      setUserData,
      redirect: returnToSettingsPage,
      openModal,
    })
  }

  const isLoading = isUpdatingCustomer || isRequestingEmailChange

  useEffect(() => {
    if (!hasElevatedScope && !loading) {
      openModal()
    }

    return () => closeModal()
  }, [loading, closeModal, hasElevatedScope, openModal])

  useEffect(() => {
    refetch()
  }, [refetch])

  const onModalClose = () => {
    closeModal()
    returnToSettingsPage()
  }

  return (
    <>
      {isLoading || !customerDetails.firstname || loading ? (
        <SpinnerWrapped variant="top" />
      ) : (
        <Formik
          initialValues={getInitialProfilePersonalValues(customerDetails)}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, submitForm }) => {
            return (
              <SettingsFormLayout
                dataTestId="profile-personal-edit"
                isSubmitButtonDisabled={isSubmitting || !hasElevatedScope}
                onBackButtonClick={() =>
                  navigate(ROOT.PORTAL.SETTINGS.PROFILE.PERSONAL.path)
                }
                onSubmitButtonClick={submitForm}
              >
                <Box mb="1rem">
                  <Heading.H3>
                    {page.settings.users.personalInformation}
                  </Heading.H3>
                </Box>
                <ProfilePersonalInformation
                  isRequired
                  areNameFieldsDisabled={
                    isRegisteringIndividual ||
                    hasCompletedIDV ||
                    !hasElevatedScope
                  }
                  fieldNames={fieldNames}
                />

                <ProfilePersonalEmail />

                <ProfilePersonalAddress
                  isDisabled={
                    isRegisteringIndividual ||
                    hasCompletedIDV ||
                    !hasElevatedScope
                  }
                  values={values}
                />
              </SettingsFormLayout>
            )
          }}
        </Formik>
      )}
      <ModalBasic isOpen={isModalOpen} onCancel={onModalClose}>
        <ModalVerificationRequired
          onCancel={onModalClose}
          onRedirect={onRedirect}
        />
      </ModalBasic>
    </>
  )
}
