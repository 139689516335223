import { useHistory } from 'react-router'
import { rvSelectedSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ROUTE } from 'const/routes'
import { getDevicesText } from 'utils/devices/devices.utils'
import { GetSites_getSites_sites as SiteData } from 'types/gql-types/GetSites'
import { Card } from 'components/Cards/Card'

export const SiteListItem = ({ site }: { site: SiteData }) => {
  const history = useHistory()
  const { id, name, devices } = site
  return (
    <Card
      key={id}
      text={name}
      textSmall={getDevicesText(devices?.length)}
      isClickable
      onCardClick={() => {
        history.push(ROUTE.PORTAL_PAYMENTS_SITES_SITE_GENERAL, {
          // NOTE: temporary solution to pass site name while reactive variables are loading
          name,
        })
        rvSelectedSite(id)
      }}
    />
  )
}
