import { gql } from '@apollo/client'

export const GetXeroBrandingThemes = gql`
  query GetXeroBrandingThemes($xeroOrganisationUuid: String!) {
    getXeroBrandingThemes(
      connectionType: XERO_PAYMENT_SERVICES
      xeroOrganisationUuid: $xeroOrganisationUuid
    ) {
      brandingThemeId
      createdDateUTC
      logoUrl
      name
      themeType
      sortOrder
    }
  }
`
