import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { DebitCardsTableDebitCardV2FragmentDoc } from '../../../../../../../../../../components/CardsView/components/CardsTable/DebitCardsTable/DebitCardsTable.generated'

const defaultOptions = {} as const
export type RequestNewDebitCardMutationVariables = Types.Exact<{
  input: Types.RequestNewDebitCardInput
}>

export type RequestNewDebitCardMutationResponse = {
  __typename?: 'Mutation'
  requestNewDebitCard: {
    __typename?: 'RequestNewDebitCardResult'
    card: {
      __typename: 'DebitCardV2'
      productType: Types.DebitCardProductType | null
      id: string
      name: string
      owner: string | null
      status: Types.DebitCardStatus
      colour: Types.DebitCardColour
      maskedPan: string
      debitCardAccount: {
        __typename: 'DebitCardAccountV2'
        id: string
        name: string
        icon: { __typename?: 'Icon'; colour: string | null } | null
        balance: { __typename?: 'Money'; value: string } | null
      }
      customer: { __typename?: 'Customer'; id: string } | null
    } | null
  }
}

export const RequestNewDebitCardMutation = gql`
  mutation RequestNewDebitCardMutation($input: RequestNewDebitCardInput!) {
    requestNewDebitCard(input: $input) {
      card {
        productType
        ...DebitCardsTableDebitCardV2Fragment
      }
    }
  }
  ${DebitCardsTableDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  RequestNewDebitCardMutationResponse,
  RequestNewDebitCardMutationVariables
>
export type RequestNewDebitCardMutationMutationFn = Apollo.MutationFunction<
  RequestNewDebitCardMutationResponse,
  RequestNewDebitCardMutationVariables
>

/**
 * __useRequestNewDebitCardMutation__
 *
 * To run a mutation, you first call `useRequestNewDebitCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestNewDebitCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestNewDebitCardMutation, { data, loading, error }] = useRequestNewDebitCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestNewDebitCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestNewDebitCardMutationResponse,
    RequestNewDebitCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestNewDebitCardMutationResponse,
    RequestNewDebitCardMutationVariables
  >(RequestNewDebitCardMutation, options)
}
export type RequestNewDebitCardMutationHookResult = ReturnType<
  typeof useRequestNewDebitCardMutation
>
export type RequestNewDebitCardMutationMutationResult =
  Apollo.MutationResult<RequestNewDebitCardMutationResponse>
export type RequestNewDebitCardMutationMutationOptions =
  Apollo.BaseMutationOptions<
    RequestNewDebitCardMutationResponse,
    RequestNewDebitCardMutationVariables
  >
