import { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  InvoiceFormFields,
  InvoiceItem,
  InvoiceItemCalculateFormFields,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useField, useFormikContext } from 'formik'
import { isEqual } from 'lodash-es'

import { getInitialValues } from '../../InvoiceItemCalculateModal.utils'

export interface UseInvoiceItemCalculateModalHandlersProps {
  closeModal: () => void
  name: string
}

export const useInvoiceItemCalculateModalHandlers = ({
  closeModal,
  name,
}: UseInvoiceItemCalculateModalHandlersProps) => {
  const { values: invoiceValues } = useFormikContext<InvoiceFormFields>()
  const [itemField, , itemHelpers] = useField<InvoiceItem>(name)

  const itemRef = useRef<InvoiceItem>(itemField.value)

  useEffect(() => {
    itemRef.current = itemField.value
  }, [itemField.value])

  const initialValues = useMemo(() => getInitialValues(itemRef.current), [])

  const handleSubmit = useCallback(
    (values: InvoiceItemCalculateFormFields) => {
      const isDirty = !isEqual(initialValues, values)

      if (isDirty) {
        // NOTE: we only define the calculator as being enabled when the
        // quantity, price or unit fields have changed.
        const hasUsedModifiedCalculateFields = !isEqual(
          {
            quantity: initialValues.quantity,
            unit: initialValues.unit,
            price: initialValues.price,
          },
          {
            quantity: values.quantity,
            unit: values.unit,
            price: values.price,
          }
        )

        // NOTE: if user has previously set a calculation we don't disable
        // until they've cleared the calculation from the price input otherwise
        // ensure they have modified the fields
        const hasCalculation =
          initialValues.hasCalculation || hasUsedModifiedCalculateFields

        itemHelpers.setValue({
          ...itemRef.current,
          hasCalculation,
          price: values.price,
          quantity: values.quantity,
          unit: values.unit,
        })
      }

      closeModal()
    },
    [closeModal, initialValues, itemHelpers]
  )

  return { initialValues, invoiceValues, handleSubmit }
}
