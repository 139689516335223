import { gql } from '@apollo/client'

export const RunConfigureXeroBankfeeds = gql`
  mutation RunConfigureXeroBankfeeds($config: XeroBankfeedsConfigInput!) {
    configureXeroBankfeeds(config: $config) {
      organisationName
      lastDataSyncDateTime
      connection {
        id
        status
        errorType
      }
    }
  }
`
