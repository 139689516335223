import { TabItemProps, Tabs } from '@npco/zeller-design-system'

import { SwitchAnimationContainer } from 'components/DrawerWithNavigation/DrawerNavigation/SwitchAnimationContainer/SwitchAnimationContainer'

import { InvoiceDrawerTabs } from './useInvoiceDrawerTabs'

interface InvoiceDrawerHeaderProps {
  animationKey: string
  tabs: TabItemProps[]
  selectedTab: InvoiceDrawerTabs
}

export const InvoiceDrawerHeader = ({
  animationKey,
  tabs,
  selectedTab,
}: InvoiceDrawerHeaderProps) => (
  <SwitchAnimationContainer animationKey={animationKey}>
    <Tabs currentTab={selectedTab}>
      {tabs.map((tab) => (
        <Tabs.Item key={tab.value} {...tab} />
      ))}
    </Tabs>
  </SwitchAnimationContainer>
)
