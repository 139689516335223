import { useNavigate, useParams } from 'react-router-dom-v5-compat'

import { useGetCustomerBasicInfo } from 'hooks/useGetCustomerBasicInfo/useGetCustomerBasicInfo'
import { ROOT, ROUTE_PARAM_NAME } from 'const/routes'
import { UserDrawer } from 'components/UserDetails/UserDrawer'

export const XeroUserDrawer = () => {
  const { settingsXeroUserId: customerUuid } = useParams<{
    [ROUTE_PARAM_NAME.PORTAL_SETTINGS_XERO_USER_ID]: string
  }>()

  const { customer, isLoading: isLoadingCustomer } = useGetCustomerBasicInfo(
    customerUuid || ''
  )

  const navigate = useNavigate()

  return (
    <UserDrawer
      isLoading={isLoadingCustomer}
      canEditUser={false}
      selectedUser={customer}
      isOpen={Boolean(customerUuid)}
      onClose={() =>
        navigate(
          ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.USERS
            .path
        )
      }
    />
  )
}
