import { useCallback } from 'react'
import { PageTemplate } from '@npco/zeller-design-system'

import { useGetItemSettings } from 'hooks/useGetItemSettings/useGetItemSettings'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { useUpdateItemSettingsMutation } from 'components/ItemsSettingsForm/graphql/updateItemSettings.generated'
import { ItemsSettingsForm } from 'components/ItemsSettingsForm/ItemsSettingsForm'
import { ItemsSettingsFormFields } from 'components/ItemsSettingsForm/ItemsSettingsForm.types'
import { SpinnerWrapped } from 'components/Spinner'

export const ItemsSettings = () => {
  const {
    isLoading: isLoadingSettings,
    settings,
    hasError,
  } = useGetItemSettings()
  const [updateItemSettings, { loading: isUpdatingSettings }] =
    useUpdateItemSettingsMutation()

  const updateSettings = useCallback(
    async (values: ItemsSettingsFormFields) => {
      await updateItemSettings({
        variables: {
          input: {
            itemsApplyTax: values.isTaxApplicable,
            itemsTaxInclusive: values.isTaxInclusive,
          },
        },
      })
    },
    [updateItemSettings]
  )

  if (hasError && !settings) {
    return <ErrorPage />
  }

  if (isLoadingSettings || isUpdatingSettings) {
    return <SpinnerWrapped variant="top" />
  }

  return (
    <PageTemplate.Section>
      <ItemsSettingsForm
        settings={settings}
        updateSettings={updateSettings}
        isUpdatingSettings={isUpdatingSettings}
      />
    </PageTemplate.Section>
  )
}
