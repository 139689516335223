import { useCallback, useMemo } from 'react'
import { gql } from '@apollo/client'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Flex,
  NumberBadge,
  NumberBadgeVariant,
  Toggle,
  TooltipBasic,
} from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { useCardOutstandingQueryLazyQuery } from './graphql/CardOutstandingQuery.generated'
import { OutstandingExpensesToggleDebitCardV2Fragment } from './OutstandingExpensesToggle.generated'
import { outstandingExpensesToggleTranslations } from './OutstandingExpensesToggle.i18n'

interface OutstandingExpensesToggleProps {
  card: OutstandingExpensesToggleDebitCardV2Fragment | null
  isFilterOn: boolean
  toggleFilter: () => void
  isOutstandingFilterSettingsAllInactive: boolean
  isLoadingOutstandingFilterSettings: boolean
}

export const OutstandingExpensesToggle = ({
  card,
  isFilterOn,
  toggleFilter,
  isOutstandingFilterSettingsAllInactive,
  isLoadingOutstandingFilterSettings,
}: OutstandingExpensesToggleProps) => {
  const isMobile = useIsMobileResolution()
  const t = useTranslations(outstandingExpensesToggleTranslations)
  const [cardOutstandingQuery] = useCardOutstandingQueryLazyQuery()

  const { userRole } = useLoggedInUser()

  const isAdmin = userRole === CustomerRole.ADMIN
  const outstandingTransactionsCount = card?.outstandingTransactions ?? 0

  const isFilterToggleDisabled = useMemo(() => {
    if (
      isOutstandingFilterSettingsAllInactive ||
      isLoadingOutstandingFilterSettings
    ) {
      return true
    }

    return false
  }, [
    isOutstandingFilterSettingsAllInactive,
    isLoadingOutstandingFilterSettings,
  ])

  const toggleText = useMemo(() => {
    const text = isMobile ? t('badgeTextMobile') : t('badgeText')

    if (isFilterToggleDisabled) {
      return text
    }

    if (!isFilterOn) {
      return `${outstandingTransactionsCount} ${text}`
    }

    return text
  }, [
    t,
    isMobile,
    isFilterToggleDisabled,
    isFilterOn,
    outstandingTransactionsCount,
  ])

  const toggleTextVariant = useMemo(() => {
    if (
      !isOutstandingFilterSettingsAllInactive &&
      outstandingTransactionsCount > 0
    ) {
      return NumberBadgeVariant.SoftAlert
    }

    return NumberBadgeVariant.Default
  }, [outstandingTransactionsCount, isOutstandingFilterSettingsAllInactive])

  const onToggleChange = useCallback(() => {
    if (isFilterOn && card?.id) {
      cardOutstandingQuery({
        variables: { cardId: card.id },
        fetchPolicy: 'network-only',
      })
    }
    toggleFilter()
  }, [card?.id, cardOutstandingQuery, toggleFilter, isFilterOn])

  return (
    <TooltipBasic
      placement={isMobile ? 'bottom' : 'top'}
      isDisabled={!isOutstandingFilterSettingsAllInactive}
      renderTrigger={({ handlers }) => (
        <Flex
          gap="10px"
          position="absolute"
          zIndex="10"
          right="0"
          top="0"
          alignItems="center"
          {...handlers}
        >
          <NumberBadge variant={toggleTextVariant} text={toggleText} />
          <Toggle
            checked={isFilterOn}
            disabled={isFilterToggleDisabled}
            onChange={onToggleChange}
          />
        </Flex>
      )}
      showArrow={false}
    >
      <Box maxWidth="184px" textAlign="center">
        {isAdmin ? t('tooltipContentAdmin') : t('tooltipContentManager')}
      </Box>
    </TooltipBasic>
  )
}

OutstandingExpensesToggle.fragments = {
  DebitCardV2: gql`
    fragment OutstandingExpensesToggleDebitCardV2Fragment on DebitCardV2 {
      id
      outstandingTransactions
    }
  `,
}
