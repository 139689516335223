import { useCallback } from 'react'
import { DebitCardColour } from '@npco/mp-gql-types'
import { ErrorLogger } from '@npco/utils-error-logger'

import {
  Address,
  CardFormatOption,
  CardTypeOption,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { UNEXPECTED_ERROR } from 'types/errors'

import { useRequestCorporateCard } from './useRequestCorporateCard'
import { useRequestDebitCard } from './useRequestDebitCard'
import { VelocityControlInput } from './useRequestNewCard.types'
import {
  getCardFormatVariable,
  getCardTypeVariable,
  getVelocityControlVariable,
} from './useRequestNewCard.utils'

interface RequestNewCardVariables {
  accountUuid: string
  address: Address | undefined
  cardholderUuid: string
  colour: DebitCardColour
  format: CardFormatOption
  nickname: string
  type: CardTypeOption
  usePin: boolean
  velocityControl?: VelocityControlInput
  entityAddressTimezone?: string
}

export const useRequestNewCard = () => {
  const { requestDebitCardMutation, isLoading: isRequestDebitCardLoading } =
    useRequestDebitCard()
  const { requestCorporateCard, isLoading: isRequestCorporateCardLoading } =
    useRequestCorporateCard()

  const requestNewCard = useCallback(
    async ({
      accountUuid,
      address,
      cardholderUuid,
      colour,
      format,
      nickname,
      type,
      usePin,
      velocityControl,
      entityAddressTimezone,
    }: RequestNewCardVariables) => {
      const requestNewCardMutation =
        type === CardTypeOption.DebitCard
          ? requestDebitCardMutation
          : requestCorporateCard

      try {
        const result = await requestNewCardMutation({
          variables: {
            input: {
              accountUuid,
              address,
              cardholderUuid,
              colour,
              format: getCardFormatVariable(format),
              nickname,
              productType: getCardTypeVariable(type),
              accessibleProfile: !usePin,
              velocityControl: getVelocityControlVariable({
                type,
                velocityControl,
              }),
            },
          },
          entityAddressTimezone,
        })

        if (!result.data?.requestNewDebitCard.card) {
          return UNEXPECTED_ERROR
        }

        return result.data.requestNewDebitCard.card.id
      } catch (err) {
        ErrorLogger.report('Requesting new card error:', err)
        return UNEXPECTED_ERROR
      }
    },
    [requestDebitCardMutation, requestCorporateCard]
  )

  return {
    requestNewCard,
    isLoading: isRequestDebitCardLoading || isRequestCorporateCardLoading,
  }
}
