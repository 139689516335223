import { useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { SimBillingStatementExportFormat } from '@npco/mp-gql-types'
import { Box, Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'
import { rvIsDateRangeAppropriate } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'
import { AnimatePresence, motion } from 'framer-motion'

import { ROOT } from 'const/routes'
import { useSimBillsFetch } from 'pages/Settings/Sim/BillingHistory/BillingHistory.hooks'
import { EmptyBillingHistory } from 'pages/Settings/Sim/BillingHistory/EmptyBillingHistory/EmptyBillingHistory'
import { ErrorState } from 'pages/Settings/Sim/BillingHistory/ErrorState/ErrorState'
import { ExportSimBillsModal } from 'pages/Settings/Sim/BillingHistory/ExportSimBillsModal/ExportSimBillsModal'
import { SimBillList } from 'pages/Settings/Sim/BillingHistory/SimBillsList/SimBillList'
import { StatementButton } from 'pages/Settings/Sim/BillingHistory/StatementButton/StatementButton'
import { DashboardFiltersButton } from 'layouts/DashboardLayout'
import { ButtonExportTransactions } from 'components/Buttons/ButtonExportTransactions/ButtonExportTransactions'
import { ContentCollapser } from 'components/ContentCollapser/ContentCollapser'
import { NoResults } from 'components/Placeholders/NoResults'
import { SpinnerWrapped } from 'components/Spinner'
import { StatementsModal } from 'components/StatementsModal/StatementsModal'
import { component, page } from 'translations'

import { SimBillFilters } from './SimBillFilters/SimBillFilters'
import { StyledWarningNotification } from './SimBillFilters/SimBillFilters.styled'

export const BillingHistory = () => {
  const [areFiltersOpen, setFiltersOpen] = useState(false)
  const [isWarningVisible, setIsWarningVisible] = useState(false)
  const { isModalOpen, openModal, closeModal } = useModalState()
  const isDateRangeAppropriate = useReactiveVar(rvIsDateRangeAppropriate)

  const {
    areFiltersInDefaultState,
    isInitiallyLoading,
    simBills,
    fetchMoreSimBills,
    hasReachedEnd,
    hasLoaded,
    hasErrorOccurred,
  } = useSimBillsFetch()

  const hasNoInitialSimBills = Boolean(
    areFiltersInDefaultState &&
      hasLoaded &&
      !hasErrorOccurred &&
      simBills.length < 1
  )

  const isEmpty = simBills.length === 0

  useEffect(() => {
    if (isDateRangeAppropriate) {
      setIsWarningVisible(false)
    }
  }, [isDateRangeAppropriate])

  const handleClick = () => {
    if (!isDateRangeAppropriate) {
      setIsWarningVisible(true)
    } else {
      openModal()
    }
  }

  const getContent = () => {
    if (isInitiallyLoading) {
      return <SpinnerWrapped variant="centre" />
    }

    if (isEmpty) {
      if (hasNoInitialSimBills) {
        return <EmptyBillingHistory />
      }
      if (hasErrorOccurred) {
        return <ErrorState />
      }
      return (
        <NoResults
          title={component.noResults.titleTransactions}
          description={component.noResults.descTransactions}
        />
      )
    }

    return (
      <SimBillList
        simBills={simBills}
        fetchMoreSimBills={fetchMoreSimBills}
        hasReachedEnd={hasReachedEnd}
      />
    )
  }
  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex height="48px" width="100%" justifyContent="space-between">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Breadcrumb.Link to={ROOT.PORTAL.PAYMENTS.SIM.path}>
                  {page.title.sim}
                </Breadcrumb.Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Breadcrumb.Text>
                  {page.settings.sim.billingHistory.title}
                </Breadcrumb.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
            <Flex gap="10px">
              <DashboardFiltersButton
                onClick={() => setFiltersOpen(!areFiltersOpen)}
                isActive={areFiltersOpen}
                disabled={hasNoInitialSimBills || !hasLoaded}
              />
              <StatementButton />
            </Flex>
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
      HeaderSecondaryRow={
        <Box pt="24px">
          <ContentCollapser isActive={areFiltersOpen}>
            {isWarningVisible && (
              <Box mb="1rem">
                <StyledWarningNotification>
                  {page.exportFile.simBillWarning}
                </StyledWarningNotification>
              </Box>
            )}
            <SimBillFilters />
          </ContentCollapser>

          <AnimatePresence>
            {areFiltersOpen && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
              >
                <Box mt="-0.5rem">
                  <ButtonExportTransactions
                    label={page.exportFile.exportSimBill}
                    onClick={handleClick}
                  />
                </Box>
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
      }
    >
      {isModalOpen && (
        <StatementsModal
          closeModal={closeModal}
          title={page.exportFile.exportDeposits}
          type={SimBillingStatementExportFormat}
          modalContent={(fileFormat: SimBillingStatementExportFormat) => (
            <ExportSimBillsModal
              closeModal={closeModal}
              filename={page.exportFile.exportSimBillFilename}
              format={fileFormat}
            />
          )}
        />
      )}

      {getContent()}
    </PageTemplate>
  )
}
