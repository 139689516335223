import { useTranslations } from '@npco/utils-translations'
import {
  AnchorBasic,
  Box,
  InfoBox,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'
import { FormikErrors } from 'formik'

import { ItemsImportData } from '../ItemsImportModal.types'
import { translations } from './ItemsImportModalMatch.i18n'

interface ItemsImportModalMatchInfoBoxProps {
  errors: FormikErrors<ItemsImportData>
}

export const ItemsImportModalMatchInfoBox = ({
  errors,
}: ItemsImportModalMatchInfoBoxProps) => {
  const t = useTranslations(translations)
  if (Object.values(errors).filter(Boolean).length < 2) {
    return null
  }

  return (
    <Box mt="12px" pr="30px">
      <InfoBox variant={INFOBOX_VARIANT.NEGATIVE}>
        <InfoBox.Message>
          {t('severalErrorInfobox', {
            link: (
              <AnchorBasic href="https://support.myzeller.com/how-do-i-import-items">
                {t('infoboxLink')}
              </AnchorBasic>
            ),
          })}
        </InfoBox.Message>
      </InfoBox>
    </Box>
  )
}
