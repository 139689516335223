import { Link } from 'react-router-dom-v5-compat'
import {
  AnnouncementModal,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_VARIANT,
  ProgressIndicator,
} from '@npco/zeller-design-system'

import PaperPlane from 'assets/svg/accounts-upgrade-flow/paper-plane-in-hand.svg'
import { ROOT } from 'const/routes'
import { SharedProgressIndicatorWrapper } from 'pages/GlobalModals/UpgradeAccountsCompleteModal/UpgradeAccountCompleteModal.styled'

import { useUpgradeAccountsCompleteModal } from '../../../hooks/useUpgradeAccountsCompleteModal'
import { ImageContainer } from '../../../shared/ImageContainer/ImageContainer'
import { ModalContent } from '../../../shared/ModalContent/ModalContent'
import {
  backButtonLabel,
  nextButtonLabel,
  TOTAL_STEP_COUNT,
} from '../../../UpgradeAccountsCompleteModal.utils'
import {
  description,
  getMessage,
  messageLinkText,
  title,
} from './UpgradeAccountsCompleteXeroIntegrationStageContent.utils'

export const UpgradeAccountsXeroIntegrationStageContent = () => {
  const {
    closeModal,
    goCompleteReadyToGoStage,
    goCompleteDebitCardsRewardsStage,
  } = useUpgradeAccountsCompleteModal()

  return (
    <AnnouncementModal
      isOpen
      onClickPrimary={goCompleteReadyToGoStage}
      onClickSecondary={goCompleteDebitCardsRewardsStage}
      primaryButtonLabel={nextButtonLabel}
      secondaryButtonLabel={backButtonLabel}
      onCancel={closeModal}
    >
      <ModalContent
        Illustration={<ImageContainer imagePath={PaperPlane} />}
        description={description}
        heading={title}
      >
        <InfoBox
          dataTestId="info-box"
          priority={INFOBOX_PRIORITY.MEDIUM}
          variant={INFOBOX_VARIANT.NEGATIVE}
        >
          <InfoBox.Message>
            {getMessage(
              <Link
                to={
                  ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_BANK_FEEDS_MANAGE.path
                }
                target="_blank"
              >
                {messageLinkText}
              </Link>
            )}
          </InfoBox.Message>
        </InfoBox>
        <SharedProgressIndicatorWrapper mt={{ _: 15, XS: 20 }}>
          <ProgressIndicator
            currentStep={4}
            totalStepCount={TOTAL_STEP_COUNT}
          />
        </SharedProgressIndicatorWrapper>
      </ModalContent>
    </AnnouncementModal>
  )
}
