import gql from 'graphql-tag'

export const GetOverview = gql`
  query GetOverview($filter: TransactionFilterInput!) {
    getTransactionTotalsBigInt(filter: $filter, totalsType: HOURLY) {
      period
      totalAmount
      countPurchases
      countRefunds
      purchaseAmount
      surchargeAmount
      tipAmount
      taxAmounts {
        name
        amount
      }
      feeAmount
      refundAmount
      declinedAmount
    }
  }
`
