import { useReactiveVar } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import {
  Flex,
  Heading,
  HeadingStyles,
  InfoBox,
  INFOBOX_VARIANT,
  ToggleForm,
} from '@npco/zeller-design-system'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { Field, useFormikContext } from 'formik'

import { SettingsPayments } from 'types/settings'
import { SiteCache } from 'types/site'

import { getDisplaySurchargeFeePercentage } from '../../SitePayments.utils'
import { IconsBar } from '../IconsBar/IconsBar'
import { Warning } from '../Warning/Warning'
import { SurchargeOptions } from './SurchargeOptions.fields'
import { translations } from './VirtualTerminal.i18n'
import * as styled from './VirtualTerminal.styled'

type VirtualTerminalProps = {
  siteData: SiteCache
}
export const VirtualTerminal = ({ siteData }: VirtualTerminalProps) => {
  const { initialValues, values, errors } = useFormikContext<SettingsPayments>()
  const entityDetails = useReactiveVar(rvEntityDetails)
  const t = useTranslations(translations)
  const { feeFixed, feePercent } =
    siteData?.surchargesTaxes?.feesSurchargeVt ?? {}
  const shouldShowSurchargeInfo =
    !errors.vtSurchargePercent &&
    (initialValues.vtSurchargeEnabled !== values.vtSurchargeEnabled ||
      initialValues.vtSurchargeType !== values.vtSurchargeType ||
      initialValues.vtSurchargePercent !== values.vtSurchargePercent)

  if (!entityDetails.canAcquire || !entityDetails.canAcquireVt) {
    return (
      <>
        <Heading.H3>{t('virtualTerminal')}</Heading.H3>
        <Warning />
      </>
    )
  }

  return (
    <Flex flexDirection="column" gap="24px">
      <Flex flexDirection="column" gap="16px">
        <styled.Row>
          <Heading.H3>{t('virtualTerminal')}</Heading.H3>
          <div data-testid="VTEnabled-switch">
            <Field
              component={ToggleForm}
              name="vtEnabled"
              value={values.vtEnabled}
            />
          </div>
        </styled.Row>
        <styled.Description>{t('description')}</styled.Description>
      </Flex>
      {values.vtEnabled && (
        <>
          {Boolean(values.schemesMoto?.length) && (
            <IconsBar values={values.schemesMoto} />
          )}
          <styled.Row>
            <styled.Subtitle withStyles={HeadingStyles.H5}>
              {t('processingFee')}
            </styled.Subtitle>
            <styled.Subtitle withStyles={HeadingStyles.H5}>
              {getDisplaySurchargeFeePercentage(feePercent, feeFixed)}
            </styled.Subtitle>
          </styled.Row>
          <styled.Row>
            <styled.Subtitle withStyles={HeadingStyles.H5}>
              {t('virtualTerminalSurcharge')}
            </styled.Subtitle>
            <div data-testid="VTSurcharge">
              <Field
                component={ToggleForm}
                name="vtSurchargeEnabled"
                value={values.vtSurchargeEnabled}
              />
            </div>
          </styled.Row>
          {values.vtSurchargeEnabled && (
            <SurchargeOptions
              feeFixed={feeFixed ?? 0}
              feePercent={feePercent ?? 0}
            />
          )}
          {shouldShowSurchargeInfo && (
            <InfoBox variant={INFOBOX_VARIANT.NEGATIVE}>
              {t('surchargeInfo')}
            </InfoBox>
          )}
        </>
      )}
    </Flex>
  )
}
