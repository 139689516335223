import { useMutation } from '@apollo/client'
import { UpdatedInvoiceNotesInput } from '@npco/mp-gql-types'

import {
  UpdateInvoiceNotes as UpdateInvoiceNotesResponse,
  UpdateInvoiceNotesVariables,
} from 'types/gql-types/UpdateInvoiceNotes'

import { UpdateInvoiceNotes } from './gql/updateInvoiceNotes'

export const useUpdateInvoiceNotes = () => {
  const [updateInvoiceNotes, { loading: isLoading }] = useMutation<
    UpdateInvoiceNotesResponse,
    UpdateInvoiceNotesVariables
  >(UpdateInvoiceNotes)

  return {
    updateInvoiceNotes: (input: UpdatedInvoiceNotesInput) =>
      updateInvoiceNotes({ variables: { input } }),
    isLoading,
  }
}
