import { useCallback, useMemo, useState } from 'react'
import {
  BankAccountDetailsInput,
  PaymentInstrumentStatus,
  PaymentInstrumentType,
} from '@npco/mp-gql-types'
import {
  ButtonLink,
  NEW_ITEM_VALUE,
  showErrorToast,
} from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { useCreatePaymentInstrumentMFAState } from 'features/MFA'

import { BsbPaymentInstrument } from 'hooks/paymentInstruments/useBsbPaymentInstruments'
import { translate } from 'utils/translations'
import { RESOURCE_ALREADY_EXISTS_ERROR } from 'types/errors'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { PaymentInstrumentSource } from 'services/Analytics/events/contact'
import { useAnalyticsContext } from 'services/Analytics/useAnalyticsContext'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import { useCreatePaymentInstrumentOnMFARedirect } from 'pages/Transfer/hooks/useCreatePaymentInstrumentOnMFARedirect/useCreatePaymentInstrumentOnMFARedirect'

import {
  updateRvContactsOnContactUpdate,
  updateRvSelectedContactOnContactUpdate,
} from '../../../rv-deprecated/contacts.utils'
import { ContactSection } from '../ContactSection'
import { createBankAccountItem } from './BankAccounts.utils'
import { BankAccountsList } from './BankAccountsList'
import { BankAccountsModal } from './BankAccountsModal'
import { useBankAccountHandlers } from './hooks/useBankAccountHandlers'

export const BANK_ACCOUNTS_DATA_TESTID = 'contact-bank-accounts-section'

interface BankAccountsProps {
  contactBankAccounts: ContactCoreFieldsFragment['paymentInstruments']
  contactId: string
  contactName: string | null
}

export const BankAccounts = ({
  contactBankAccounts,
  contactId,
  contactName,
}: BankAccountsProps) => {
  const { hasRedirectedBackToApp, createPaymentInstrumentState } =
    useCreatePaymentInstrumentMFAState()

  const { locationName } = useAnalyticsContext()
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  const [isExistingPaymentInstrument, setIsExistingPaymentInstrument] =
    useState(false)

  const handleSuccessfulPaymentInstrumentCreation = useCallback(
    (selectedPaymentInstrument: BsbPaymentInstrument | null) => {
      const getPaymentInstrumentSource = () => {
        if (!selectedPaymentInstrument?.id) {
          return PaymentInstrumentSource.New
        }
        if (selectedPaymentInstrument.contactUuid) {
          return PaymentInstrumentSource.Linked
        }

        return PaymentInstrumentSource.Unlinked
      }

      trackAnalyticsEvent(
        AnalyticsEventNames.CONTACT_ATTACHED_PAYMENT_INSTRUMENT,
        {
          Type: PaymentInstrumentType.BSB,
          InstrumentSource: getPaymentInstrumentSource(),
          Location: locationName.current,
        }
      )
    },
    [locationName, trackAnalyticsEvent]
  )

  const { isModalOpen, openModal, closeModal } = useModalState({
    isOpenByDefault: Boolean(
      hasRedirectedBackToApp &&
        createPaymentInstrumentState?.variables.contactId === contactId
    ),
  })

  const [itemToUpdate, setItemToUpdate] = useState('')

  const handleCloseModal = () => {
    setItemToUpdate('')
    setIsExistingPaymentInstrument(false)
    closeModal()
  }

  const {
    isLoading,
    onLinkBankAccount,
    onUpdateBankAccount,
    savedBankAccounts,
    setSavedBankAccounts,
    recentUpdatedBankAccount,
    handleCreateBankAccountSuccess,
  } = useBankAccountHandlers(
    handleCloseModal,
    contactBankAccounts,
    contactId,
    setIsExistingPaymentInstrument
  )

  const {
    isCreatingPaymentInstrument,
    bankAccountDetails: initialBankAccountDetails,
  } = useCreatePaymentInstrumentOnMFARedirect({
    onSuccess: (id, bankDetails) =>
      handleCreateBankAccountSuccess(id, bankDetails, () =>
        handleSuccessfulPaymentInstrumentCreation(null)
      ),
    onError: (err, bankDetails) => {
      if (err === RESOURCE_ALREADY_EXISTS_ERROR) {
        setIsExistingPaymentInstrument(true)
      }

      showErrorToast(
        translate('page.contact.sections.bankAccounts.linkBankAccountFailure', {
          accountName: bankDetails.name,
        })
      )
    },
  })

  const initialAccountValues = useMemo(() => {
    if (initialBankAccountDetails === undefined || !hasRedirectedBackToApp) {
      return undefined
    }

    return {
      accountName: initialBankAccountDetails.name,
      bsb: initialBankAccountDetails.bsb,
      accountNumber: initialBankAccountDetails.account,
      status: PaymentInstrumentStatus.ACTIVE,
    }
  }, [initialBankAccountDetails, hasRedirectedBackToApp])

  const initialSelectedItem = useMemo(() => {
    if (initialBankAccountDetails === undefined || !hasRedirectedBackToApp) {
      return undefined
    }

    const { account, bsb, name } = initialBankAccountDetails

    return createBankAccountItem({ account, bsb, id: NEW_ITEM_VALUE, name })
  }, [initialBankAccountDetails, hasRedirectedBackToApp])

  const heading = translate('page.contact.sections.bankAccounts.title')

  const handleUpdateBankAccount = async (
    bankAccountDetails: BankAccountDetailsInput,
    paymentInstrumentStatus: PaymentInstrumentStatus
  ) => {
    onUpdateBankAccount(
      itemToUpdate,
      bankAccountDetails,
      paymentInstrumentStatus,
      () => {
        if (paymentInstrumentStatus !== PaymentInstrumentStatus.ARCHIVED) {
          return
        }

        trackAnalyticsEvent(
          AnalyticsEventNames.CONTACT_ARCHIVED_PAYMENT_INSTRUMENT,
          { Type: PaymentInstrumentType.BSB }
        )
      }
    )
  }

  const handleLinkBankAccount = async (
    bankAccountDetails: BankAccountDetailsInput,
    paymentInstrumentStatus: PaymentInstrumentStatus,
    selectedPaymentInstrument: BsbPaymentInstrument | null
  ) => {
    onLinkBankAccount(
      bankAccountDetails,
      paymentInstrumentStatus,
      selectedPaymentInstrument,
      () => {
        handleSuccessfulPaymentInstrumentCreation(selectedPaymentInstrument)
      }
    )
  }

  const handleItemClick = (id: string) => {
    setItemToUpdate(id)
    openModal()
  }

  const handleUnlinkSuccess = () => {
    setSavedBankAccounts((prev) => {
      const newBankAccountsList = prev.filter(
        (bankAccount) => bankAccount.id !== itemToUpdate
      )

      updateRvContactsOnContactUpdate(contactId, {
        paymentInstruments: newBankAccountsList,
      })
      updateRvSelectedContactOnContactUpdate({
        paymentInstruments: newBankAccountsList,
      })

      return newBankAccountsList
    })

    trackAnalyticsEvent(
      AnalyticsEventNames.CONTACT_UNLINKED_PAYMENT_INSTRUMENT,
      {
        Type: PaymentInstrumentType.BSB,
      }
    )

    handleCloseModal()
  }

  const bankAccountToUpdate = itemToUpdate
    ? savedBankAccounts.find(({ id }) => itemToUpdate === id)
    : null

  return (
    <ContactSection
      heading={heading}
      actionElement={
        <ButtonLink data-testid="contact-add-bank-account" onClick={openModal}>
          {translate('shared.add')}
        </ButtonLink>
      }
      dataTestId={BANK_ACCOUNTS_DATA_TESTID}
    >
      <BankAccountsList
        bankAccounts={savedBankAccounts}
        onItemClick={handleItemClick}
        recentUpdatedBankAccount={recentUpdatedBankAccount}
      />
      {isModalOpen && (
        <BankAccountsModal
          bankAccountToUpdate={bankAccountToUpdate}
          contactId={contactId}
          contactName={contactName ?? ''}
          isLoading={isLoading || isCreatingPaymentInstrument}
          isExistingPaymentInstrument={isExistingPaymentInstrument}
          setIsExistingPaymentInstrument={setIsExistingPaymentInstrument}
          onCancel={handleCloseModal}
          onSave={
            !bankAccountToUpdate
              ? handleLinkBankAccount
              : handleUpdateBankAccount
          }
          onUnlinkSuccess={handleUnlinkSuccess}
          initialAccountValues={initialAccountValues}
          initialSelectedItem={initialSelectedItem}
        />
      )}
    </ContactSection>
  )
}
