import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { useSubscription } from 'hooks/useSubscription'
import {
  DebitCardUpdate as DebitCardUpdateResponse,
  DebitCardUpdateVariables,
} from 'types/gql-types/DebitCardUpdate'

import { DebitCardUpdate } from './graphql/DebitCardUpdate'
import { useCacheCard } from './useCacheCard'

export const useSubscribeToCards = () => {
  const { cacheCardUpdate } = useCacheCard()

  useSubscription<DebitCardUpdateResponse, DebitCardUpdateVariables>(
    DebitCardUpdate,
    {
      onSubscriptionData: ({ subscriptionData }) => {
        const updateData = subscriptionData.data?.onDebitCardUpdate

        if (updateData) {
          cacheCardUpdate(updateData)
        }
      },
      variables: {
        entityUuid: rvEntityDetails()?.id,
      },
    }
  )
}
