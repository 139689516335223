import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { Box, ButtonLink } from '@npco/zeller-design-system'

import { PERMISSIONS } from 'const/permissions'
import { ROUTE } from 'const/routes'
import { translate } from 'utils/translations'
import { withPermissionGuard } from 'services/withPermissionGuard/withPermissionGuard'

interface UserEditButtonProps {
  isUsers?: boolean
}

export const UserEditButton = withPermissionGuard<UserEditButtonProps>(
  PERMISSIONS.SITE.USERS.EDIT
)(({ isUsers = false }) => {
  const navigate = useNavigate()

  const nextRoute = useMemo(() => {
    if (isUsers) {
      return ROUTE.PORTAL_SETTINGS_USERS_USER_EDIT
    }

    return ROUTE.PORTAL_PAYMENTS_SITES_SITE_USERS_EDIT
  }, [isUsers])
  return (
    <Box marginLeft="auto">
      <ButtonLink onClick={() => navigate(nextRoute)}>
        {translate('page.settings.users.edit')}
      </ButtonLink>
    </Box>
  )
})
