import { PageTemplate, Tabs } from '@npco/zeller-design-system'

import { useContactsLayout } from '../ContactsLayout.hooks'

export const ContactsTertiaryHeaderRow = () => {
  const { tabs, currentTab } = useContactsLayout()

  return (
    <PageTemplate.HeaderTertiaryRow>
      <Tabs currentTab={currentTab}>
        {tabs.map((tab) => (
          <Tabs.Item key={tab.value} {...tab} />
        ))}
      </Tabs>
    </PageTemplate.HeaderTertiaryRow>
  )
}
