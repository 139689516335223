import { useMutation } from '@apollo/client'

import {
  CancelInvoice as CancelInvoiceResponse,
  CancelInvoiceVariables,
} from 'types/gql-types/CancelInvoice'

import { CancelInvoice } from '../../graphql/cancelInvoice'

export const useCancelInvoice = () => {
  const [cancelInvoice, { loading: isCancellingInvoice }] = useMutation<
    CancelInvoiceResponse,
    CancelInvoiceVariables
  >(CancelInvoice)

  return {
    cancelInvoice: (referenceNumber: string) =>
      cancelInvoice({
        variables: {
          referenceNumber,
        },
      }),
    isCancellingInvoice,
  }
}
