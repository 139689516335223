import { useApolloClient } from '@apollo/client'

import { Subset } from 'types/utils'

import { GetContactTransactionsLocalState } from './getContactTransactionsLocalState'
import { GetTransactionsLocalState } from './getTransactionsLocalState'
import { TransactionsDefaultLocalState } from './TransactionsFilters.constants'
import {
  CacheLocalStateTransactions,
  LocalStateTransactions,
} from './TransactionsFilters.types'

export type TransactionsCacheKey = 'contactTransactions' | 'transactions'

export const useTransactionsLocalCache = (
  key: TransactionsCacheKey = 'transactions'
) => {
  const { cache } = useApolloClient()

  const updateCache = (next: Subset<CacheLocalStateTransactions<typeof key>>) =>
    cache.updateQuery<Subset<CacheLocalStateTransactions<typeof key>>>(
      {
        query:
          key === 'transactions'
            ? GetTransactionsLocalState
            : GetContactTransactionsLocalState,
      },
      (previous) => ({
        local: {
          [key]: {
            ...previous?.local?.[key],
            ...next.local?.[key],
            filters: {
              ...previous?.local?.[key]?.filters,
              ...next.local?.[key]?.filters,
              dates: {
                ...previous?.local?.[key]?.filters?.dates,
                ...next.local?.[key]?.filters?.dates,
              },
            },
          },
        },
      })
    )

  const resetFilters = () => {
    updateCache({
      local: {
        [key]: TransactionsDefaultLocalState,
      },
    })
  }

  const setSelectedAmount = (
    amount?: LocalStateTransactions['filters']['amount']
  ) => {
    updateCache({
      local: {
        [key]: {
          filters: {
            amount,
          },
        },
      },
    })
  }

  const setSiteUuid = (
    siteUuid?: LocalStateTransactions['filters']['siteUuid']
  ) => {
    updateCache({
      local: {
        [key]: {
          filters: {
            siteUuid,
          },
        },
      },
    })
  }

  const setSelectedDates = (
    dates: Partial<LocalStateTransactions['filters']['dates']> | undefined
  ) => {
    updateCache({
      local: {
        [key]: {
          filters: {
            dates: dates ?? TransactionsDefaultLocalState.filters.dates,
          },
        },
      },
    })
  }

  const setSelectedStatuses = (
    statuses: LocalStateTransactions['filters']['statuses'] | undefined
  ) => {
    updateCache({
      local: {
        [key]: {
          filters: {
            statuses:
              statuses ?? TransactionsDefaultLocalState.filters.statuses,
          },
        },
      },
    })
  }

  const setSelectedTypes = (
    types: LocalStateTransactions['filters']['types'] | undefined
  ) => {
    updateCache({
      local: {
        [key]: {
          filters: {
            types: types ?? TransactionsDefaultLocalState.filters.types,
          },
        },
      },
    })
  }

  const setSelectedSources = (
    sources: LocalStateTransactions['filters']['sources'] | undefined
  ) => {
    updateCache({
      local: {
        [key]: {
          filters: {
            sources: sources ?? TransactionsDefaultLocalState.filters.sources,
          },
        },
      },
    })
  }

  const setSelectedTerminals = (
    terminals: LocalStateTransactions['filters']['terminals'] | undefined
  ) => {
    updateCache({
      local: {
        [key]: {
          filters: {
            terminals:
              terminals ?? TransactionsDefaultLocalState.filters.terminals,
          },
        },
      },
    })
  }

  const setSearch = (search: string) => {
    updateCache({
      local: {
        [key]: {
          filters: {
            search: search ?? TransactionsDefaultLocalState.filters.search,
          },
        },
      },
    })
  }

  const setIsFiltersVisible = (
    isFiltersVisible: LocalStateTransactions['isFiltersVisible']
  ) => {
    updateCache({
      local: {
        [key]: {
          isFiltersVisible,
        },
      },
    })
  }

  return {
    resetFilters,
    setIsFiltersVisible,
    setSelectedAmount,
    setSelectedDates,
    setSelectedSources,
    setSelectedStatuses,
    setSelectedTerminals,
    setSelectedTypes,
    setSearch,
    setSiteUuid,
  }
}
