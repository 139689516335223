import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { GetCategoriesTableCoreFieldsDoc } from './getCategoriesTableCoreFields.generated'

const defaultOptions = {} as const
export type GetCategoriesTableQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input']
  filter: Types.InputMaybe<Types.GetCategoriesFilterInput>
  sort: Types.InputMaybe<Types.GetCategoriesSortInput>
  nextToken: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type GetCategoriesTableQueryResponse = {
  __typename?: 'Query'
  getItemCategories: {
    __typename?: 'ItemCategoryConnection'
    nextToken: string | null
    categories: Array<{
      __typename: 'ItemCategory'
      color: string
      id: string
      itemsNumber: number | null
      name: string
      status: Types.ItemCategoryStatus | null
    }> | null
  }
}

export const GetCategoriesTable = gql`
  query GetCategoriesTable(
    $limit: Int!
    $filter: GetCategoriesFilterInput
    $sort: GetCategoriesSortInput
    $nextToken: String
  ) {
    getItemCategories(
      limit: $limit
      filter: $filter
      sort: $sort
      nextToken: $nextToken
    ) {
      categories {
        ...GetCategoriesTableCoreFields
      }
      nextToken
    }
  }
  ${GetCategoriesTableCoreFieldsDoc}
` as unknown as TypedDocumentNode<
  GetCategoriesTableQueryResponse,
  GetCategoriesTableQueryVariables
>

/**
 * __useGetCategoriesTableQuery__
 *
 * To run a query within a React component, call `useGetCategoriesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesTableQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetCategoriesTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCategoriesTableQueryResponse,
    GetCategoriesTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCategoriesTableQueryResponse,
    GetCategoriesTableQueryVariables
  >(GetCategoriesTable, options)
}
export function useGetCategoriesTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCategoriesTableQueryResponse,
    GetCategoriesTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCategoriesTableQueryResponse,
    GetCategoriesTableQueryVariables
  >(GetCategoriesTable, options)
}
export type GetCategoriesTableQueryHookResult = ReturnType<
  typeof useGetCategoriesTableQuery
>
export type GetCategoriesTableLazyQueryHookResult = ReturnType<
  typeof useGetCategoriesTableLazyQuery
>
export type GetCategoriesTableQueryResult = Apollo.QueryResult<
  GetCategoriesTableQueryResponse,
  GetCategoriesTableQueryVariables
>
