import { gql } from '@apollo/client'
import { CloseAccountView } from 'features/CloseAccount/CloseAccountModal/CloseAccount/CloseAccountView/CloseAccountView'

export const CloseAccountQuery = gql`
  query CloseAccountQuery($debitCardAccountUuid: ID!) {
    getDebitCardAccountV2(debitCardAccountUuid: $debitCardAccountUuid) {
      __typename
      ...CloseAccountViewDebitCardAccountV2Fragment
    }
    getBillingAccount {
      __typename
      ...CloseAccountViewBillingAccountFragment
    }
    getEntity {
      __typename
      ...CloseAccountViewEntityFragment
    }
  }

  ${CloseAccountView.fragments.DebitCardAccountV2}
  ${CloseAccountView.fragments.BillingAccount}
  ${CloseAccountView.fragments.Entity}
`
