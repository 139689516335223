import {
  BadgeSize,
  ComingSoonBadge,
  ZellerChatBubbleSiteNavIcon,
} from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'

import { SidebarItem } from '../components/Item/Item'
import { useSidebarLocalCache } from '../hooks/useSidebarLocalCache'
import * as styled from '../Sidebar.styled'

export const ReferralItem = () => {
  const { isFolded } = useSidebarLocalCache()

  return (
    <SidebarItem
      icon={<ZellerChatBubbleSiteNavIcon />}
      isFolded={isFolded}
      route={ROOT.PORTAL.REFERRAL.path}
      isNavLink
      badge={
        <styled.BadgeContainer aria-hidden data-testid="referral-badge">
          <ComingSoonBadge
            size={BadgeSize.Small}
            text={translate('component.sidebar.referralBadge')}
          />
        </styled.BadgeContainer>
      }
    >
      {translate('component.sidebar.items.referrals')}
    </SidebarItem>
  )
}
