import { useGetBillersByCodeQuery } from 'pages/Transfer/BPay/BPayTargetInput/useBPayTargetItems/GetBillersByCode.generated'

const QUERY_DEBOUNCE_DELAY_MS = 800

export const useBillerCodeSearch = (billerCode: string) => {
  const { data, error, loading } = useGetBillersByCodeQuery({
    variables: { code: billerCode },
    context: {
      debounceKey: 'getBillersByCodeQuery',
      debounceTimeout: QUERY_DEBOUNCE_DELAY_MS,
    },
    skip: billerCode?.length < 4,
  })

  const billerData = data?.searchBpayBillers?.bpayBillers

  return {
    billerData,
    isLoading: loading,
    error,
  }
}
