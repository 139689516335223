import { Route, Routes } from 'react-router-dom-v5-compat'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useIsMobileResolution } from '@npco/zeller-design-system'

import { TIME_FILTER_ENABLED_COMPONENTS } from 'types/picker'
import { Filters } from 'components/Filters'
import { TransactionsFilters } from 'components/Filters/PillFilters/TransactionsFilters/TransactionsFilters'
import { FiltersHeaderRow } from 'components/FiltersHeaderRow'

import { AccountsFilter } from '../../Contact/ContactSections/AccountsList/AccountsFilter'
import { useContactContext } from '../../ContactContext/ContactContext'

const deps = {
  useContactContext,
  useIsMobileResolution,
  TransactionsFilters,
}
export { deps as ContactTertiaryHeaderRowDeps }

export const ContactTertiaryHeaderRow = () => {
  const { isAccountsFiltersVisible, isPaymentsFiltersVisible } =
    deps.useContactContext()

  const flags = useFeatureFlags()

  const isMobile = deps.useIsMobileResolution()

  return (
    <Routes>
      {!isMobile && (
        <Route
          path="accounts"
          element={
            <FiltersHeaderRow isOpen={isAccountsFiltersVisible}>
              <AccountsFilter />
            </FiltersHeaderRow>
          }
        />
      )}
      <Route
        path="payments"
        element={
          <FiltersHeaderRow isOpen={isPaymentsFiltersVisible}>
            {flags.TransactionsFilterUplift ? (
              <deps.TransactionsFilters cacheKey="contactTransactions" />
            ) : (
              <Filters
                isDateTime
                widgetKey={TIME_FILTER_ENABLED_COMPONENTS.CONTACTS}
              />
            )}
          </FiltersHeaderRow>
        }
      />
    </Routes>
  )
}
