import { InfoBox, INFOBOX_PRIORITY } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

export const LinkCardIdInfoBox = () => {
  return (
    <InfoBox priority={INFOBOX_PRIORITY.DEFAULT}>
      <InfoBox.Message>
        {translate('page.addCardModal.linkCard.cardIdInfo')}
      </InfoBox.Message>
    </InfoBox>
  )
}
