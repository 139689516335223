import { Outlet, useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import {
  ButtonLink,
  Flex,
  Heading,
  TileIcon,
  Title,
  useModalState,
} from '@npco/zeller-design-system'

import { formatWeekdayDateTime, fromUnixTime } from 'utils/date'
import { translationsShared } from 'translations'

import { CategoryDeleteModal } from './CategoryDeleteModal/CategoryDeleteModal'
import { CategoryDrawerDescription } from './CategoryDrawerDescription/CategoryDrawerDescription'
import { CategoryDrawerDetailsFragment } from './CategoryDrawerDetails.generated'
import { translations } from './CategoryDrawerDetails.i18n'

export const CategoryDrawerDetails = ({
  color,
  createdTime,
  description,
  id,
  name,
}: CategoryDrawerDetailsFragment) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const {
    isModalOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModalState()
  const navigate = useNavigate()

  return (
    <>
      <Flex flexDirection="column" width="100%" gridGap="40px">
        <div>
          <Flex justifyContent="space-between" alignItems="center" mb="12px">
            <Heading.H3>{t('heading')}</Heading.H3>
            <ButtonLink onClick={() => navigate('edit')}>
              {tShared('edit')}
            </ButtonLink>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" mb="4px">
            <Title weight="500">{t('colour')}</Title>
            <TileIcon backgroundColor={color} />
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Title weight="500">{t('dateCreated')}</Title>
            <div>
              {createdTime
                ? formatWeekdayDateTime(fromUnixTime(createdTime))
                : '-'}
            </div>
          </Flex>
        </div>
        <CategoryDrawerDescription description={description} id={id} />
        <div>
          <ButtonLink onClick={openDeleteModal}>{t('deleteButton')}</ButtonLink>
        </div>
        {isDeleteModalOpen && (
          <CategoryDeleteModal id={id} name={name} onClose={closeDeleteModal} />
        )}
      </Flex>
      <Outlet />
    </>
  )
}
