import { gql } from '@apollo/client'

import { SearchableCardholderList } from '../SearchableCardholderList/SearchableCardholderList'

export const GetCardholderList = gql`
  query GetCardholderList {
    getCustomers {
      ...SearchableCardholderListCustomerFragment
    }
  }
  ${SearchableCardholderList.fragments.Customer}
`
