import { PageTemplate, PopperItem } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const HeaderSecondaryRowWrapper = styled(
  PageTemplate.HeaderSecondaryRow
)`
  flex-direction: column;
  padding-top: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding-top: 24px;
  }
`

export const DeleteAllPopperItem = styled(PopperItem)`
  ${({ theme, isDisabled }) =>
    !isDisabled && {
      color: theme.colors.RED_1000,
    }}
`
