import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type OnUnreadNotificationCountUpdateSubscriptionVariables = Types.Exact<{
  customerUuid: Types.Scalars['String']['input']
}>

export type OnUnreadNotificationCountUpdateSubscriptionResponse = {
  __typename?: 'Subscription'
  onUnreadNotificationCountUpdate: {
    __typename?: 'UnreadNotificationCountSubscription'
    count: number | null
  } | null
}

export const OnUnreadNotificationCountUpdate = gql`
  subscription OnUnreadNotificationCountUpdate($customerUuid: String!) {
    onUnreadNotificationCountUpdate(customerUuid: $customerUuid) {
      count
    }
  }
` as unknown as TypedDocumentNode<
  OnUnreadNotificationCountUpdateSubscriptionResponse,
  OnUnreadNotificationCountUpdateSubscriptionVariables
>

/**
 * __useOnUnreadNotificationCountUpdateSubscription__
 *
 * To run a query within a React component, call `useOnUnreadNotificationCountUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUnreadNotificationCountUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUnreadNotificationCountUpdateSubscription({
 *   variables: {
 *      customerUuid: // value for 'customerUuid'
 *   },
 * });
 */
export function useOnUnreadNotificationCountUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnUnreadNotificationCountUpdateSubscriptionResponse,
    OnUnreadNotificationCountUpdateSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    OnUnreadNotificationCountUpdateSubscriptionResponse,
    OnUnreadNotificationCountUpdateSubscriptionVariables
  >(OnUnreadNotificationCountUpdate, options)
}
export type OnUnreadNotificationCountUpdateSubscriptionHookResult = ReturnType<
  typeof useOnUnreadNotificationCountUpdateSubscription
>
export type OnUnreadNotificationCountUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnUnreadNotificationCountUpdateSubscriptionResponse>
