import { useQuery } from '@apollo/client'

import { GetXeroSiteCustomers as GetXeroSiteCustomersResponse } from 'types/gql-types/GetXeroSiteCustomers'

import { GetXeroSiteCustomers } from '../../graphql/GetXeroSiteCustomers'

export const useGetXeroSiteCustomers = () => {
  const { data, loading } =
    useQuery<GetXeroSiteCustomersResponse>(GetXeroSiteCustomers)

  return {
    siteUuid: data?.getXeroSiteSettings?.id,
    siteCustomers: data?.getXeroSiteSettings?.customers,
    isLoadingSiteCustomers: loading,
  }
}
