import { useTranslations } from '@npco/utils-translations'
import {
  BellIcon,
  NumberBadge,
  NumberBadgeVariant,
  SvgIcon,
} from '@npco/zeller-design-system'
import { BadgeSize } from '@npco/zeller-design-system/dist/Components/Badge/Badge.types'

import { ROOT } from 'const/routes'

import { SidebarItem } from '../components/Item/Item'
import { useNotificationsCountSidebar } from '../hooks/useNotificationsCountSidebar'
import { useSidebarLocalCache } from '../hooks/useSidebarLocalCache'
import { translations } from '../Sidebar.i18n'
import * as styled from './NotificationsItem.styled'

const deps = {
  useSidebarLocalCache,
  useTranslations,
  useNotificationsCountSidebar,
}

export { deps as NotificationsItemDeps }

export const NotificationsItem = () => {
  const t = deps.useTranslations(translations)
  const { isFolded } = deps.useSidebarLocalCache()

  const { count, shouldShowCount, skip } = deps.useNotificationsCountSidebar()

  if (skip) {
    return null
  }

  const countText = count > 9 ? '9+' : count.toString()

  return (
    <SidebarItem
      icon={
        <SvgIcon>
          <BellIcon />
          <styled.DotWrapper>
            <styled.NotificationsDot
              key={`${isFolded}-${shouldShowCount}`}
              $isFolded={isFolded}
              $newDot={!shouldShowCount}
            />
          </styled.DotWrapper>
        </SvgIcon>
      }
      isFolded={isFolded}
      isNavLink
      route={ROOT.PORTAL.NOTIFICATIONS.path}
      badge={
        shouldShowCount && (
          <NumberBadge
            variant={NumberBadgeVariant.Alert}
            size={BadgeSize.Small}
            text={countText}
          />
        )
      }
    >
      {t('notifications')}
    </SidebarItem>
  )
}
