import { RefundPinType } from '@npco/mp-gql-types'
import { rvSelectedSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvSiteDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/sites'

import { getHasTwoDecimalPlaces } from 'utils/formValidation'
import { getBasePoints, getPercentageValue } from 'utils/numbers'
import { translate } from 'utils/translations'
import { CustomValidator } from 'types/common'
import { CardSurcharge, MotoSurcharge, SettingsPayments } from 'types/settings'
import { SiteCache } from 'types/site'
import { errorMessages, page } from 'translations'

export const getPaymentsData = (siteData: SiteCache) => {
  return {
    surchargeEnabled: siteData?.surchargesTaxes?.surchargeEnabled,
    surchargePercent: getPercentageValue(
      siteData?.surchargesTaxes?.surchargePercent as number
    ),
    surchargeType: String(Number(siteData?.surchargesTaxes?.surchargeFullFees)),
    motoEnabled: siteData?.moto?.enabled,
    motoDefault: siteData?.moto?.defaultEntryMethod,
    motoRequirePIN: siteData?.moto?.requiresPin,
    motoPaymentsSurchargeEnabled:
      siteData?.surchargesTaxes?.surchargeEnabledMoto,
    motoPaymentsSurchargeType: String(
      Number(siteData?.surchargesTaxes?.surchargeFullFeesMoto)
    ),
    motoPaymentsSurchargePercentage: getPercentageValue(
      siteData?.surchargesTaxes?.surchargePercentMoto as number
    ),
    gstEnabled: siteData?.surchargesTaxes?.gstEnabled,
    tipEnabled: siteData?.tipping?.enabled,
    tipPercent1: getPercentageValue(siteData?.tipping?.tipPercent1),
    tipPercent2: getPercentageValue(siteData?.tipping?.tipPercent2),
    tipPercent3: getPercentageValue(siteData?.tipping?.tipPercent3),
    schemes: siteData?.schemes,
    schemesMoto: siteData?.schemesMoto,
    customTipAllowed: siteData?.tipping?.customTipAllowed,
    refundRequiresPin: siteData?.refundRequiresPin,
    refundPin: siteData?.refundPin,
    refundPinType: siteData?.refundPinType,
    cpocSurchargeType: String(
      Number(siteData?.surchargesTaxes?.feesSurchargeCpoc?.surchargeFullFees)
    ),
    cpocSurchargePercent: getPercentageValue(
      siteData?.surchargesTaxes?.feesSurchargeCpoc?.surchargePercent as number
    ),
    cpocSurchargeEnabled:
      siteData?.surchargesTaxes?.feesSurchargeCpoc?.surchargeEnabled,
    restrictReportAccessEnabled:
      siteData?.features?.restrictReportAccessEnabled,
    splitPaymentEnabled: siteData?.features?.splitPaymentEnabled,
    vtEnabled: siteData?.vtEnabled,
    vtSurchargeEnabled:
      siteData?.surchargesTaxes?.feesSurchargeVt?.surchargeEnabled,
    vtSurchargeType: String(
      Number(siteData?.surchargesTaxes?.feesSurchargeVt?.surchargeFullFees)
    ),
    vtSurchargePercent: getPercentageValue(
      siteData?.surchargesTaxes?.feesSurchargeVt?.surchargePercent as number
    ),
  }
}

export const getUpdatedData = (values: SettingsPayments) => {
  const {
    surchargeEnabled,
    surchargePercent,
    surchargeType,
    motoEnabled,
    motoDefault,
    motoRequirePIN,
    motoPaymentsSurchargeEnabled,
    motoPaymentsSurchargeType,
    motoPaymentsSurchargePercentage,
    gstEnabled,
    tipEnabled,
    tipPercent1,
    tipPercent2,
    tipPercent3,
    schemes,
    schemesMoto,
    customTipAllowed,
    refundRequiresPin,
    refundPinType,
    refundPin,
    cpocSurchargeEnabled,
    cpocSurchargeType,
    cpocSurchargePercent,
    restrictReportAccessEnabled,
    splitPaymentEnabled,
    vtEnabled,
    vtSurchargeEnabled,
    vtSurchargeType,
    vtSurchargePercent,
  } = values
  const siteUuid = rvSelectedSite()

  const shouldSetRefundPin =
    refundRequiresPin && refundPinType === RefundPinType.REFUND_PIN
  return {
    id: siteUuid,
    surchargesTaxes: {
      surchargeEnabled,
      surchargePercent: getBasePoints(surchargePercent),
      surchargeFullFees: surchargeType === CardSurcharge.TotalFees,
      gstEnabled,
      surchargeEnabledMoto: motoPaymentsSurchargeEnabled,
      surchargeFullFeesMoto:
        motoPaymentsSurchargeType === MotoSurcharge.TotalFees,
      surchargePercentMoto: getBasePoints(motoPaymentsSurchargePercentage),
      feesSurchargeCpoc: {
        surchargeEnabled: cpocSurchargeEnabled,
        surchargeFullFees: cpocSurchargeType === CardSurcharge.TotalFees,
        surchargePercent: getBasePoints(cpocSurchargePercent),
      },
      feesSurchargeVt: {
        surchargeEnabled: vtSurchargeEnabled,
        surchargeFullFees: vtSurchargeType === CardSurcharge.TotalFees,
        surchargePercent: getBasePoints(vtSurchargePercent),
      },
      feesSurchargePbl: {
        surchargeEnabled: vtSurchargeEnabled,
        surchargeFullFees: vtSurchargeType === CardSurcharge.TotalFees,
        surchargePercent: getBasePoints(vtSurchargePercent),
      },
    },
    moto: {
      enabled: motoEnabled,
      defaultEntryMethod: motoDefault,
      requiresPin: motoRequirePIN,
    },
    tipping: {
      enabled: tipEnabled,
      tipPercent1: getBasePoints(tipPercent1),
      tipPercent2: getBasePoints(tipPercent2),
      tipPercent3: getBasePoints(tipPercent3),
      customTipAllowed,
    },
    features: {
      restrictReportAccessEnabled,
      splitPaymentEnabled,
    },
    schemes,
    schemesMoto,
    refundRequiresPin,
    refundPin: shouldSetRefundPin ? refundPin : null,
    refundPinType: refundRequiresPin ? refundPinType : RefundPinType.SITE_PIN,
    vtEnabled,
  } as SiteCache
}

export const getVariables = (updatedData: SiteCache) => {
  return {
    input: updatedData,
  }
}

export const getUpdatedSiteDetails = (updatedData: SiteCache) => {
  const siteData = rvSiteDetails()

  return {
    ...siteData,
    ...updatedData,
  } as SiteCache
}

export const getMaxCardSurchargeValue = (
  feePercent: number | undefined | null
) => {
  if (feePercent === 0) {
    return 0
  }

  return (feePercent || 150) / 100
}

const getSurchargeFeePercentage = (feePercentage?: number | null) => {
  if (feePercentage === 0) {
    return 0
  }

  if (!feePercentage) {
    return -1
  }

  return Number((feePercentage / 100).toFixed(2))
}

export const getDisplaySurchargeFeePercentage = (
  feePercentage?: number | null,
  feeFixed?: number | null
) => {
  const surchargeFeePercentage = getSurchargeFeePercentage(feePercentage)

  if (surchargeFeePercentage === -1) {
    return page.settings.payments.surcharge.notAvailable
  }

  const feePercentFormatted = `${surchargeFeePercentage}%`
  const feeFixedFormatted = feeFixed && `${feeFixed}c`

  return [feePercentFormatted, feeFixedFormatted].filter(Boolean).join(' + ')
}

export const getMaxCardSurchargeText = (
  feePercent: number | undefined | null
) => {
  const surchargeFeePercentage = getSurchargeFeePercentage(feePercent)

  if (surchargeFeePercentage === -1) {
    return ''
  }

  return translate('page.settings.payments.surcharge.description2', {
    value: surchargeFeePercentage,
  })
}

export const validateSurchargePercent: (
  max: number
) => CustomValidator<string | undefined> =
  (max: number) => (value?: string | null) => {
    if (value === undefined || value === null || value === '') {
      return errorMessages.invalidSurcharge
    }

    if (Number(value) > max || Number(value) <= 0) {
      return errorMessages.invalidSurcharge
    }

    if (!getHasTwoDecimalPlaces(value.toString())) {
      return errorMessages.decimalPlaces
    }

    return undefined
  }

export const handleUpdateSiteResponse = (
  accepted: boolean,
  siteData: SiteCache,
  updatedSiteDetails: SiteCache
) => {
  if (accepted) {
    rvSiteDetails({
      ...siteData,
      ...updatedSiteDetails,
      surchargesTaxes: {
        ...(siteData.surchargesTaxes ?? {}),
        ...(updatedSiteDetails.surchargesTaxes ?? {}),
        feesSurchargeCpoc: {
          ...(siteData.surchargesTaxes?.feesSurchargeCpoc ?? {}),
          ...(updatedSiteDetails.surchargesTaxes?.feesSurchargeCpoc ?? {}),
        },
        feesSurchargeVt: {
          ...(siteData.surchargesTaxes?.feesSurchargeVt ?? {}),
          ...(updatedSiteDetails.surchargesTaxes?.feesSurchargeVt ?? {}),
        },
        feesSurchargePbl: {
          ...(siteData.surchargesTaxes?.feesSurchargePbl ?? {}),
          ...(updatedSiteDetails.surchargesTaxes?.feesSurchargePbl ?? {}),
        },
      } as SiteCache['surchargesTaxes'],
      moto: {
        ...(siteData.moto ?? {}),
        ...(updatedSiteDetails.moto ?? {}),
      } as SiteCache['moto'],
    })
  }
}

export const prepareUpdateSitePaymentsPayloads = (values: SettingsPayments) => {
  const updatedData = getUpdatedData(values)
  const variables = getVariables(updatedData)
  const updatedSiteDetails = getUpdatedSiteDetails(updatedData)

  return {
    variables,
    updatedSiteDetails,
  }
}
