import { useMemo, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { MiniTabItemProps } from '@npco/zeller-design-system'

import { cardStyleTabsTranslations } from './useCardStyleTabs.i18n'

export type CardStyleTabType = 'style1' | 'style2'

export const useCardStyleTabs = () => {
  const t = useTranslations(cardStyleTabsTranslations)
  const [currentTab, setCurrentTab] = useState('style1')

  const tabs: MiniTabItemProps<'button'>[] = useMemo(
    () => [
      {
        value: 'style1',
        children: t('style1'),
        onClick: () => setCurrentTab('style1'),
      },
      {
        value: 'style2',
        children: t('style2'),
        onClick: () => setCurrentTab('style2'),
      },
    ],
    [t]
  )

  return {
    tabs,
    currentTab,
  }
}
