import { validateMaxLengthAndCharsBecs } from '@npco/component-mp-common'
import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'

import {
  combineValidators,
  validateMaxLength,
  validateRequired,
} from 'utils/formValidation'
import { translate } from 'utils/translations'
import { externalTransferTranslations } from 'pages/Transfer/Transfer.i18n'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import { errorMessages } from 'translations'

const REFERENCE_MAX_LENGTH = 18

export const RecipientDescriptionField = () => {
  const t = useTranslations(externalTransferTranslations)

  return (
    <Box mb="24px">
      <InputAdaptiveFieldWrapper
        label={translate('page.transfer.recipientReferenceLabel')}
        placeholder={t('recipientDescriptionPlaceholder')}
        name="recipientReference"
        type="text"
        validate={combineValidators(
          validateRequired,
          validateMaxLengthAndCharsBecs({
            maxLength: REFERENCE_MAX_LENGTH,
            errorMsgSpecialChars: errorMessages.invalidCharsBecs,
          }),
          validateMaxLength(
            REFERENCE_MAX_LENGTH,
            translate('errorMessages.referenceLength', {
              referenceLength: REFERENCE_MAX_LENGTH,
            })
          )
        )}
      />
    </Box>
  )
}
