import { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Flex } from '@npco/zeller-design-system'

import { Amount } from 'components/Filters/PillFilters/Amount'
import { DateRange } from 'components/Filters/PillFilters/DateRange'
import { DevicesFilter } from 'components/Filters/PillFilters/DevicesFilter'
import { SaleType } from 'components/Filters/PillFilters/SaleType'
import { Search } from 'components/Filters/PillFilters/Search'
import { Source } from 'components/Filters/PillFilters/Source'
import { Status } from 'components/Filters/PillFilters/Status'

import { GetContactTransactionsLocalState } from './getContactTransactionsLocalState'
import { GetTransactionsLocalState } from './getTransactionsLocalState'
import {
  CacheLocalStateTransactions,
  TransactionsFiltersInput,
} from './TransactionsFilters.types'
import {
  TransactionsCacheKey,
  useTransactionsLocalCache,
} from './useTransactionsLocalCache'

type TransactionsFiltersProps = {
  cacheKey?: TransactionsCacheKey
}

export const TransactionsFilters = ({
  cacheKey = 'transactions',
}: TransactionsFiltersProps) => {
  const {
    setSearch,
    setSelectedAmount,
    setSelectedDates,
    setSelectedSources,
    setSelectedStatuses,
    setSelectedTerminals,
    setSelectedTypes,
  } = useTransactionsLocalCache(cacheKey)

  const { data: cacheData } = useQuery<
    CacheLocalStateTransactions<typeof cacheKey>
  >(
    cacheKey === 'transactions'
      ? GetTransactionsLocalState
      : GetContactTransactionsLocalState
  )

  const disabledStatuses = useMemo(
    () =>
      cacheData?.local[cacheKey].filters.types?.includes('REFUND')
        ? (['REFUNDED'] satisfies TransactionsFiltersInput['statuses'])
        : undefined,
    [cacheData?.local, cacheKey]
  )

  const handleSetSelectedTypes = useCallback(
    (types: TransactionsFiltersInput['types']) => {
      if (
        types?.includes('REFUND') &&
        cacheData?.local[cacheKey].filters.statuses?.includes('REFUNDED')
      ) {
        setSelectedStatuses(
          cacheData?.local[cacheKey].filters.statuses?.filter(
            (status) => status !== 'REFUNDED'
          )
        )
      }
      setSelectedTypes(types)
    },
    [cacheData?.local, cacheKey, setSelectedStatuses, setSelectedTypes]
  )

  return (
    <Flex flexWrap="wrap" gap="12px" marginRight="auto">
      <Search
        searchValue={cacheData?.local[cacheKey].filters.search ?? ''}
        setSearchValue={setSearch}
      />
      <Amount
        selectedAmount={cacheData?.local[cacheKey].filters.amount ?? undefined}
        setSelectedAmount={setSelectedAmount}
      />
      <DateRange
        format="datetime"
        range={cacheData?.local[cacheKey].filters.dates ?? undefined}
        onRangeChange={setSelectedDates}
      />
      <DevicesFilter
        value={cacheData?.local[cacheKey].filters.terminals ?? undefined}
        onValueChange={setSelectedTerminals}
      />
      <SaleType
        selectedTypes={cacheData?.local[cacheKey].filters.types ?? undefined}
        setSelectedTypes={handleSetSelectedTypes}
      />
      <Status
        selectedStatuses={
          cacheData?.local[cacheKey].filters.statuses ?? undefined
        }
        setSelectedStatuses={setSelectedStatuses}
        disabledStatuses={disabledStatuses}
      />
      <Source
        selectedSources={
          cacheData?.local[cacheKey].filters.sources ?? undefined
        }
        setSelectedSources={setSelectedSources}
      />
    </Flex>
  )
}
