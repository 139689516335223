import { useCallback, useState } from 'react'
import { PaymentInstrumentStatus } from '@npco/mp-gql-types'
import { GetContactsQueryResponse } from 'features/Contacts/graphql/GetContacts.generated'

import { rvSelectedContact } from '../../../../rv-deprecated/contacts'
import { updateRvContactsOnContactUpdate } from '../../../../rv-deprecated/contacts.utils'

type PaymentInstrument = NonNullable<
  NonNullable<
    NonNullable<GetContactsQueryResponse['getContacts']['contacts']>[0]
  >['paymentInstruments']
>[0]

interface UpdateSaveBankAccountsArguments {
  bankAccountIdToUpdate: string
  paymentInstrumentStatus: PaymentInstrumentStatus
  existingPrimaryBankAccountToUpdate: false | PaymentInstrument | undefined
  contactId: string
}

export const useSavedBankAccounts = () => {
  const [savedBankAccounts, setSavedBankAccounts] = useState<
    PaymentInstrument[]
  >([])

  const updateSavedBankAccounts = useCallback(
    ({
      bankAccountIdToUpdate,
      paymentInstrumentStatus,
      existingPrimaryBankAccountToUpdate,
      contactId,
    }: UpdateSaveBankAccountsArguments) =>
      setSavedBankAccounts((prev) => {
        const newBankAccountList = prev.map((props) => {
          if (props.id === bankAccountIdToUpdate) {
            return { ...props, status: paymentInstrumentStatus }
          }

          if (
            existingPrimaryBankAccountToUpdate &&
            props.id === existingPrimaryBankAccountToUpdate.id
          ) {
            return { ...props, status: PaymentInstrumentStatus.ACTIVE }
          }

          return props
        })

        updateRvContactsOnContactUpdate(contactId, {
          paymentInstruments: newBankAccountList,
        })

        const selectedContact = rvSelectedContact()
        if (selectedContact) {
          rvSelectedContact({
            ...selectedContact,
            paymentInstruments: newBankAccountList,
          })
        }

        return newBankAccountList
      }),
    []
  )

  return { savedBankAccounts, setSavedBankAccounts, updateSavedBankAccounts }
}
