import { ApolloCache, ApolloClient } from '@apollo/client'
import { GetAccountDebitCardsVerboseQueryResponse } from 'features/Accounts/AccountCards'
import { cacheAccountDebitCardList } from 'features/Accounts/feature-accounts'
import { GetDebitCardsListQueryResponse } from 'features/Cards/AllCardsDebit/hooks/useQueryDebitCards/graphql/getDebitCardsList.generated'
import { omit } from 'lodash-es'

import { cacheDebitCardList } from '../cacheDebitCardList/cacheDebitCardList'

type DebitCard = NonNullable<
  GetDebitCardsListQueryResponse['getDebitCardsV2']['cards'][number]
>
type AccountDebitCard = NonNullable<
  NonNullable<
    GetAccountDebitCardsVerboseQueryResponse['getDebitCardAccountV2']
  >['cards']
>[number]

interface CacheNewDebitCardProps {
  cache: ApolloCache<any>
  newCard: DebitCard
  client?: ApolloClient<any>
}

export const cacheNewDebitCard = ({
  cache,
  newCard,
  client,
}: CacheNewDebitCardProps) => {
  const updateCardsList = (oldCards: (DebitCard | null)[]) => {
    const isCardAlreadyCached = oldCards.some(
      (existingCard) => existingCard?.id === newCard.id
    )

    if (isCardAlreadyCached) {
      return oldCards.map((existingCard) => {
        if (existingCard?.id === newCard.id) {
          return newCard
        }

        return existingCard
      })
    }
    return [newCard, ...oldCards]
  }

  cacheDebitCardList({ cache, mapToNewList: updateCardsList, client })
  const cacheCardToAccount = cacheAccountDebitCardList(
    newCard.debitCardAccount.id
  )

  const updateAccountCardsList = (
    oldAccountCards: (AccountDebitCard | null)[]
  ) => {
    const newAccountDebitCard: AccountDebitCard = omit(
      newCard,
      'debitCardAccount'
    )
    const isCardAlreadyCached = oldAccountCards.some(
      (existingCard) => existingCard?.id === newCard.id
    )

    if (isCardAlreadyCached) {
      return oldAccountCards.map((existingCard) => {
        if (existingCard?.id === newAccountDebitCard.id) {
          return newAccountDebitCard
        }

        return existingCard
      })
    }
    return [newAccountDebitCard, ...oldAccountCards]
  }

  cacheCardToAccount({
    cache,
    mapToNewList: updateAccountCardsList,
  })
}
