import { gql } from '@apollo/client'

export const CreatePaymentInstrument = gql`
  mutation CreatePaymentInstrument($payload: CreatePaymentInstrumentInput!) {
    createPaymentInstrument(payload: $payload)
  }
`

export const UpdatePaymentInstrument = gql`
  mutation UpdatePaymentInstrument(
    $id: ID!
    $type: PaymentInstrumentType!
    $payload: UpdatePaymentInstrumentInput!
  ) {
    updatePaymentInstrument(id: $id, type: $type, payload: $payload)
  }
`

export const LinkPaymentInstrument = gql`
  mutation LinkPaymentInstrument(
    $id: ID!
    $type: PaymentInstrumentType!
    $contactUuid: ID!
  ) {
    linkPaymentInstrumentWithContact(
      id: $id
      type: $type
      contactUuid: $contactUuid
    )
  }
`

export const UnlinkPaymentInstrument = gql`
  mutation UnlinkPaymentInstrument(
    $id: ID!
    $type: PaymentInstrumentType!
    $contactUuid: ID!
  ) {
    unlinkPaymentInstrumentFromContact(
      id: $id
      type: $type
      contactUuid: $contactUuid
    )
  }
`
