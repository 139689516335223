import { UseTranslationsFn } from '@npco/utils-translations'
import { Flex, TileIcon, TileMultipleIcon } from '@npco/zeller-design-system'

import {
  getItemCategoryColors,
  getItemCategoryNames,
} from '../../../../ItemManagement.utils'
import { ItemDetailsFragment } from './ItemDrawerDetails.generated'
import { translations } from './ItemDrawerDetails.i18n'
import * as styled from './ItemDrawerDetails.styled'

export const getDisplayCategories = (
  categories: ItemDetailsFragment['categories']
) => {
  if (categories && categories.length > 0) {
    const colors = getItemCategoryColors(categories)
    const names = getItemCategoryNames(categories, true)

    const [color1, color2] = colors

    const icon = color2 ? (
      <TileMultipleIcon
        backgroundColorTwo={color1}
        backgroundColorOne={color2}
      />
    ) : (
      <TileIcon backgroundColor={color1} />
    )

    return (
      <Flex gridGap="12px" alignItems="baseline">
        <styled.CategoryIconContainer>{icon}</styled.CategoryIconContainer>
        {names}
      </Flex>
    )
  }

  return null
}

export const getDisplayTaxes = (
  t: UseTranslationsFn<typeof translations>,
  taxes: ItemDetailsFragment['taxes']
) => {
  if (taxes && taxes.length > 0) {
    // assumes taxes array won't have multiple values
    if (taxes[0].enabled && taxes[0].name === 'GST') return t('applicable')
  }
  return t('notApplicable')
}
