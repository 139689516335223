import { useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { Box, Flex } from '@npco/zeller-design-system'

import { USERS } from 'const/common'
import { ROOT } from 'const/routes'
import { NAME_FORMATS, nameFormatter } from 'utils/nameHelper'
import { translate } from 'utils/translations'
import { GetCustomerDetails_getCustomer as CustomerDetails } from 'types/gql-types/GetCustomerDetails'
import { GetSites_getSites_sites as SiteData } from 'types/gql-types/GetSites'
import { Card, CardAdd } from 'components/Cards/Card'
import { Checkbox as DefaultCheckbox } from 'components/Input/Checkbox'
import {
  CancelButton,
  ConfirmButton,
  ModalDescription,
  ModalHeader,
} from 'components/Modal/ModalElements/ModalElements'
import { page } from 'translations'

import { SpinnerWrapped } from '../Spinner'
import { useEntityModal } from './AddEntityModal.hooks'
import {
  StyledModalWrapper,
  StyledUsersListWrapper,
} from './AddEntityModal.styled'

export const Checkbox: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const [isChecked, setIsChecked] = useState(false)

  return (
    <Box pr="22px">
      <DefaultCheckbox
        checked={isChecked}
        name="user"
        type="checkbox"
        onClick={() => {
          setIsChecked(!isChecked)
          onClick()
        }}
      />
    </Box>
  )
}

interface Props {
  entities: CustomerDetails[] | SiteData[]
  type?: string
  closeModal: () => void
  onSaveCallback?: (sites: SiteData[]) => void
}

export const AddEntityModal = ({
  closeModal,
  entities,
  type = USERS,
  onSaveCallback,
}: Props) => {
  const navigate = useNavigate()

  const {
    isSavePending,
    isTypeUsers,
    toggleEntity,
    setSelectedEntities,
    isSaveButtonDisabled,
    onSave,
  } = useEntityModal({ type, onSaveCallback, closeModal })

  const noEntitiesMessage = isTypeUsers
    ? page.settings.users.noUsersToBeAdded
    : page.settings.users.noSitesToBeAdded

  if (isSavePending) {
    return (
      <StyledModalWrapper data-testid="save-entity-modal">
        <SpinnerWrapped
          pt="100px"
          alignItems="center"
          justifyContent="center"
        />
      </StyledModalWrapper>
    )
  }

  return (
    <StyledModalWrapper
      data-testid={isTypeUsers ? 'add-user-modal' : 'add-site-modal'}
    >
      <Flex flexDirection="column" height="inherit">
        <Flex flexDirection="column" flex="6">
          <ModalHeader>
            {isTypeUsers
              ? page.settings.users.addUserTitle
              : page.settings.users.addSite}
          </ModalHeader>
          <ModalDescription>
            {isTypeUsers
              ? page.settings.users.addUserDescription
              : page.settings.users.addSiteDescription}
          </ModalDescription>
          <StyledUsersListWrapper>
            {isTypeUsers && (
              <CardAdd
                label={page.settings.users.createNewUser}
                onClick={() =>
                  navigate(
                    ROOT.PORTAL.PAYMENTS.SITES.SITE.USERS.CREATE_TYPE.path
                  )
                }
              />
            )}
            {entities.length > 0 ? (
              (entities as (CustomerDetails | SiteData)[]).map(
                (entity: CustomerDetails | SiteData) => (
                  <Card
                    isClickable
                    key={entity.id}
                    text={
                      isTypeUsers
                        ? nameFormatter(
                            {
                              firstname:
                                (entity as CustomerDetails).firstname || '',
                              middlename:
                                (entity as CustomerDetails).middlename || '',
                              lastname:
                                (entity as CustomerDetails).lastname || '',
                            },
                            NAME_FORMATS.FIRST_LAST
                          )
                        : (entity as SiteData).name
                    }
                  >
                    <Checkbox onClick={() => toggleEntity(entity)} />
                  </Card>
                )
              )
            ) : (
              <Box m="1rem">{noEntitiesMessage}</Box>
            )}
          </StyledUsersListWrapper>
        </Flex>

        <Flex flexDirection="column" flex="1">
          <Flex justifyContent="space-between" pt="24px">
            <CancelButton
              onClick={() => {
                setSelectedEntities([])
                closeModal()
              }}
            >
              {translate('shared.cancel')}
            </CancelButton>
            <ConfirmButton disabled={isSaveButtonDisabled} onClick={onSave}>
              {translate('shared.add')}
            </ConfirmButton>
          </Flex>
        </Flex>
      </Flex>
    </StyledModalWrapper>
  )
}
