import { gql } from '@apollo/client'

import { CorporateCardsTable } from 'components/CardsView/components/CardsTable/CorporateCardsTable/CorporateCardsTable'

import { useOnAddCard } from '../../../../../../hooks/useOnAddCard/useOnAddCard'

export const GetCorporateCardsList = gql`
  query GetCorporateCardsList($filter: DebitCardFilterInput) {
    getDebitCardsV2(limit: 50, filter: $filter) {
      cards {
        productType
        ...CorporateCardsTableDebitCardV2Fragment
        velocityControl {
          __typename
        }
      }
    }
    getEntity {
      __typename
      ...UseOnAddCardEntityFragment
    }
  }

  ${CorporateCardsTable.fragments.DebitCardV2}
  ${useOnAddCard.fragments.Entity}
`
