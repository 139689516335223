import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type UpdateItemCategoryMutationVariables = Types.Exact<{
  input: Types.UpdateItemCategoryInput
}>

export type UpdateItemCategoryMutationResponse = {
  __typename?: 'Mutation'
  updateItemCategory: {
    __typename: 'ItemCategory'
    color: string
    description: string | null
    id: string
    name: string
  }
}

export const UpdateItemCategory = gql`
  mutation UpdateItemCategory($input: UpdateItemCategoryInput!) {
    updateItemCategory(input: $input) {
      __typename
      color
      description
      id
      name
    }
  }
` as unknown as TypedDocumentNode<
  UpdateItemCategoryMutationResponse,
  UpdateItemCategoryMutationVariables
>
export type UpdateItemCategoryMutationFn = Apollo.MutationFunction<
  UpdateItemCategoryMutationResponse,
  UpdateItemCategoryMutationVariables
>

/**
 * __useUpdateItemCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemCategoryMutation, { data, loading, error }] = useUpdateItemCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateItemCategoryMutationResponse,
    UpdateItemCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateItemCategoryMutationResponse,
    UpdateItemCategoryMutationVariables
  >(UpdateItemCategory, options)
}
export type UpdateItemCategoryMutationHookResult = ReturnType<
  typeof useUpdateItemCategoryMutation
>
export type UpdateItemCategoryMutationResult =
  Apollo.MutationResult<UpdateItemCategoryMutationResponse>
export type UpdateItemCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateItemCategoryMutationResponse,
  UpdateItemCategoryMutationVariables
>
