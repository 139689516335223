import { useInfiniteLoader } from 'hooks/useInfiniteLoader/useInfiniteLoader'

import { useItemManagementContext } from '../components/ItemManagementContext/ItemManagementContext'

export const useFilterCategoriesInfiniteLoader = () => {
  const {
    isLoadingFilterCategories,
    loadMoreFilterCategories,
    filterCategoriesNextToken,
  } = useItemManagementContext()

  return useInfiniteLoader({
    isLoading: isLoadingFilterCategories,
    onLoadMore: async () => {
      await loadMoreFilterCategories?.({
        variables: {
          nextToken: filterCategoriesNextToken,
        },
      })
    },
    shouldLoadMore: !!filterCategoriesNextToken,
  })
}
