import { useCallback, useEffect } from 'react'
import { GetCategoriesSortColumnName } from '@npco/mp-gql-types'
import { Box, Flex, SkeletonBasic } from '@npco/zeller-design-system'
import { MultiSelectItem } from 'design-system/Components/MultiSelect/MultiSelectItem/MultiSelectItem'

import { ListLoader } from 'components/Lists'

import { useFilterCategoriesInfiniteLoader } from '../../hooks/useFilterCatagoriesInfiniteLoader'
import { DEFAULT_GET_LIMIT } from '../../ItemManagement.utils'
import { useItemManagementContext } from '../ItemManagementContext/ItemManagementContext'
import * as styled from './CategoriesFilter.styled'

interface CategoriesFilterProps {
  selectedCategories: string[]
  setCategories: (uuids: string[]) => void
}

// NOTE: When time move this component to src/components and handle loading
// filter categories and loading via props and callback
export const CategoriesFilter = ({
  selectedCategories,
  setCategories,
}: CategoriesFilterProps) => {
  const { filterCategories, isLoadingFilterCategories, getFilterCategories } =
    useItemManagementContext()

  const { isLoadingMore, observerContainer } =
    useFilterCategoriesInfiniteLoader()

  useEffect(() => {
    // NOTE: filter categories are preloaded in item management layout. However,
    // sync filter categories to add any newly created or removed categories
    getFilterCategories({
      variables: {
        sort: { ascending: true, columnName: GetCategoriesSortColumnName.Name },
        limit: DEFAULT_GET_LIMIT,
        filter: undefined,
        nextToken: undefined,
      },
    })
  }, [getFilterCategories])

  const handleItemClick = useCallback(
    ({ id, isChecked }: { id: string; isChecked: boolean }) =>
      () => {
        const nextCategoryUuids = isChecked
          ? selectedCategories.filter((uuid) => uuid !== id)
          : [...selectedCategories, id]

        setCategories(nextCategoryUuids)
      },
    [setCategories, selectedCategories]
  )

  const hasNoFilterCategories = Boolean(filterCategories?.length === 0)

  return (
    <Flex flexDirection="column">
      <styled.List data-testid="categories-filter-items">
        {isLoadingFilterCategories && hasNoFilterCategories && (
          <Flex
            data-testid="categories-filter-items-skeletons"
            flexDirection="column"
          >
            <Box padding="16px 0px">
              <SkeletonBasic height="16px" isRounded />
            </Box>
            <Box padding="16px 0px">
              <SkeletonBasic height="16px" isRounded />
            </Box>
            <Box padding="16px 0px">
              <SkeletonBasic height="16px" isRounded />
            </Box>

            <Box padding="16px 0px">
              <SkeletonBasic height="16px" isRounded />
            </Box>
          </Flex>
        )}

        {filterCategories?.map((category) => {
          const isChecked = selectedCategories.includes(category.id)

          // NOTE: using multi select item until we have the multiselect
          // popper item in the design system
          return (
            <MultiSelectItem
              data-testid={category.id}
              id={category.id}
              isChecked={isChecked}
              item={{ label: category.name, value: category.id }}
              key={category.id}
              label={category.name}
              onClick={handleItemClick({
                id: category.id,
                isChecked,
              })}
              role="option"
            />
          )
        })}
        <div ref={observerContainer} />
        {isLoadingMore && <ListLoader />}
      </styled.List>
    </Flex>
  )
}
