import { ApolloClient } from '@apollo/client'
import { GetAccountDebitCardsVerboseQueryResponse } from 'features/Accounts/AccountCards'
import { GetCorporateCardsListQueryResponse } from 'features/Cards/AllCardsCorporate/hooks/useQueryCorporateCards/graphql/getCorporateCardsList.generated'
import { GetDebitCardsListQueryResponse } from 'features/Cards/AllCardsDebit/hooks/useQueryDebitCards/graphql/getDebitCardsList.generated'

import { cacheAccountCardList } from 'utils/banking/cacheAccountCardList/cacheAccountCardList'
import { cacheCorporateCardList } from 'utils/banking/cacheCorporateCardList/cacheCorporateCardList'
import { cacheDebitCardList } from 'utils/banking/cacheDebitCardList/cacheDebitCardList'
import { DebitCardAccount } from 'types/accounts'
import { SubscribeCardUpdate_onDebitCardUpdate as SubscribeCardUpdateData } from 'types/gql-types/SubscribeCardUpdate'

type CorporateCard = NonNullable<
  GetCorporateCardsListQueryResponse['getDebitCardsV2']['cards'][0]
>
type CorporateCardAccountType = NonNullable<CorporateCard['debitCardAccount']>

type DebitCard = NonNullable<
  GetDebitCardsListQueryResponse['getDebitCardsV2']['cards'][0]
>
type DebitCardAccountType = NonNullable<DebitCard['debitCardAccount']>
type AccountDebitCard = NonNullable<
  NonNullable<
    GetAccountDebitCardsVerboseQueryResponse['getDebitCardAccountV2']
  >['cards']
>[0]

export const handleDebitCardUpdate = ({
  client,
  updatedData,
  cachedAccount,
}: {
  client: ApolloClient<any>
  updatedData: SubscribeCardUpdateData
  cachedAccount: DebitCardAccount
}) => {
  const debitCardAccount: DebitCardAccountType = {
    __typename: 'DebitCardAccountV2',
    id: cachedAccount.id,
    name: cachedAccount.name,
    icon: {
      colour: cachedAccount.icon.colour,
    },
    balance: {
      value: cachedAccount.balance.value.toString(),
    },
  }

  const newDebitCard: DebitCard = {
    ...updatedData,
    __typename: 'DebitCardV2',
    customer: updatedData.customerUuid
      ? { id: updatedData.customerUuid }
      : null,
    debitCardAccount,
  }

  const updateCardsList = (oldCards: (DebitCard | null)[]) => {
    const isCardAlreadyCached = oldCards.some(
      (existingCard) => existingCard?.id === updatedData.id
    )

    if (isCardAlreadyCached) {
      return oldCards.map((existingCard) =>
        existingCard?.id === updatedData.id ? newDebitCard : existingCard
      )
    }

    return [newDebitCard, ...oldCards]
  }

  cacheDebitCardList({
    cache: client.cache,
    mapToNewList: updateCardsList,
  })
}

export const handleCorporateCardUpdate = ({
  client,
  updatedData,
  cachedAccount,
}: {
  client: ApolloClient<any>
  updatedData: SubscribeCardUpdateData
  cachedAccount: DebitCardAccount
}) => {
  const debitCardAccount: CorporateCardAccountType = {
    __typename: 'DebitCardAccountV2',
    id: cachedAccount.id,
    name: cachedAccount.name,
    icon: {
      colour: cachedAccount.icon.colour,
    },
  }

  const newCorporateCard: CorporateCard = {
    ...updatedData,
    __typename: 'DebitCardV2',
    customer: updatedData.customerUuid
      ? { id: updatedData.customerUuid }
      : null,
    debitCardAccount,
    outstandingTransactions: null,
  }

  const updateCardsList = (oldCards: (CorporateCard | null)[]) => {
    const isCardAlreadyCached = oldCards.some(
      (existingCard) => existingCard?.id === updatedData.id
    )

    if (isCardAlreadyCached) {
      return oldCards.map((existingCard) => {
        if (existingCard?.id === updatedData.id) {
          return {
            ...newCorporateCard,
            outstandingTransactions: existingCard.outstandingTransactions,
          }
        }

        return existingCard
      })
    }

    return [newCorporateCard, ...oldCards]
  }

  cacheCorporateCardList({
    cache: client.cache,
    mapToNewList: updateCardsList,
  })
}

export const handleAccountCardUpdate = ({
  client,
  updatedData,
  cachedAccount,
}: {
  client: ApolloClient<any>
  updatedData: SubscribeCardUpdateData
  cachedAccount: DebitCardAccount
}) => {
  const newAccountCard: AccountDebitCard = {
    ...updatedData,
    __typename: 'DebitCardV2',
    customer: updatedData.customerUuid
      ? { id: updatedData.customerUuid }
      : null,
  }

  const updateAccountCardsList = (oldAccountCards: AccountDebitCard[]) => {
    const isCardAlreadyCached = oldAccountCards.some(
      (existingCard) => existingCard?.id === updatedData.id
    )

    if (isCardAlreadyCached) {
      return oldAccountCards.map((existingCard) =>
        existingCard?.id === updatedData.id ? newAccountCard : existingCard
      )
    }

    return [newAccountCard, ...oldAccountCards]
  }

  cacheAccountCardList({
    cache: client.cache,
    mapToNewList: updateAccountCardsList,
    debitCardAccountUuid: cachedAccount.id,
  })
}
