import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CorporateCardsTableDebitCardV2FragmentDoc } from '../../../../../../../../../../components/CardsView/components/CardsTable/CorporateCardsTable/CorporateCardsTable.generated'

const defaultOptions = {} as const
export type RequestNewCorporateCardMutationVariables = Types.Exact<{
  input: Types.RequestNewDebitCardInput
}>

export type RequestNewCorporateCardMutationResponse = {
  __typename?: 'Mutation'
  requestNewDebitCard: {
    __typename?: 'RequestNewDebitCardResult'
    card: {
      __typename: 'DebitCardV2'
      productType: Types.DebitCardProductType | null
      name: string
      owner: string | null
      outstandingTransactions: number | null
      id: string
      status: Types.DebitCardStatus
      colour: Types.DebitCardColour
      maskedPan: string
      velocityControl: {
        __typename: 'VelocityControlType'
        resetsAt: any
        velocityWindow: Types.VelocityWindowEnum
        availableAmount: { __typename?: 'Money'; value: string }
        amountLimit: { __typename?: 'Money'; value: string }
        totalSpentAmount: { __typename?: 'Money'; value: string }
      } | null
      debitCardAccount: {
        __typename: 'DebitCardAccountV2'
        id: string
        name: string
        icon: { __typename?: 'Icon'; colour: string | null } | null
      }
      customer: { __typename?: 'Customer'; id: string } | null
    } | null
  }
}

export const RequestNewCorporateCardMutation = gql`
  mutation RequestNewCorporateCardMutation($input: RequestNewDebitCardInput!) {
    requestNewDebitCard(input: $input) {
      card {
        productType
        ...CorporateCardsTableDebitCardV2Fragment
        velocityControl {
          __typename
        }
      }
    }
  }
  ${CorporateCardsTableDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  RequestNewCorporateCardMutationResponse,
  RequestNewCorporateCardMutationVariables
>
export type RequestNewCorporateCardMutationMutationFn = Apollo.MutationFunction<
  RequestNewCorporateCardMutationResponse,
  RequestNewCorporateCardMutationVariables
>

/**
 * __useRequestNewCorporateCardMutation__
 *
 * To run a mutation, you first call `useRequestNewCorporateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestNewCorporateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestNewCorporateCardMutation, { data, loading, error }] = useRequestNewCorporateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestNewCorporateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestNewCorporateCardMutationResponse,
    RequestNewCorporateCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestNewCorporateCardMutationResponse,
    RequestNewCorporateCardMutationVariables
  >(RequestNewCorporateCardMutation, options)
}
export type RequestNewCorporateCardMutationHookResult = ReturnType<
  typeof useRequestNewCorporateCardMutation
>
export type RequestNewCorporateCardMutationMutationResult =
  Apollo.MutationResult<RequestNewCorporateCardMutationResponse>
export type RequestNewCorporateCardMutationMutationOptions =
  Apollo.BaseMutationOptions<
    RequestNewCorporateCardMutationResponse,
    RequestNewCorporateCardMutationVariables
  >
