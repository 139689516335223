import { COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as QRCode } from 'assets/svg/setup-flow/download-QR-code.svg'

import { DownloadMessage } from '../DownloadMessage/DownloadMessage'

export const DownloadLinkTablet = () => {
  return (
    <Flex
      alignItems="center"
      backgroundColor={COLOR.WHITE}
      borderRadius="8px"
      data-testid="download-link-tablet"
      gap="16px"
      padding="0 px 0 0"
      position="absolute"
      right="24px"
      style={{
        boxShadow:
          '0px 8px 16px 0px rgba(34, 34, 38, 0.08), 0px 1px 6px 0px rgba(34, 34, 38, 0.15)',
      }}
      top={{
        XS: 'calc((100vw - 48px) / 1.786 - 128px)',
        SM: 'calc(720px / 1.786 - 128px)',
      }}
      width="314px"
    >
      <SvgIcon>
        <QRCode />
      </SvgIcon>
      <DownloadMessage variant="heading-sm" />
    </Flex>
  )
}
