import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { IconAvatar } from '@npco/mp-ui-icon-avatar'
import {
  AvatarUserSize,
  Box,
  PillBasic,
  PillNegative,
  PillSize,
  TOOLTIP_ZINDEX,
  TooltipButton,
} from '@npco/zeller-design-system'

import { useWindowResolution } from 'hooks/useWindowResolution/useWindowResolution'
import { getCustomerDisplayName, getCustomerRoleString } from 'utils/customers'
import { getIsVerified } from 'utils/kyc/kycStatus'
import { translate } from 'utils/translations'
import { StyledRadioCard } from 'pages/GlobalModals/GlobalModals.styled'
import { UserIconAvatar } from 'components/IconAvatar/UserIconAvatar/UserIconAvatar'

import { getIsNewUser } from '../../utils/cardholders.utils'
import { CardholderRadioOptionCustomerFragment } from './CardholderRadioOption.generated'

interface CardholderRadioOptionProps {
  cardholder: CardholderRadioOptionCustomerFragment
  value: string
  name?: string
  checked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
  isCurrentUser?: boolean
}

const MAX_TOOLTIP_WIDTH = 400

export const CardholderRadioOption = ({
  cardholder,
  value,
  checked,
  name,
  onChange,
  onBlur,
  isCurrentUser,
}: CardholderRadioOptionProps) => {
  const { width } = useWindowResolution()

  const isVerified = cardholder.kycStatus
    ? getIsVerified(cardholder.kycStatus)
    : false

  const isNewUser = getIsNewUser(cardholder.createdAt)

  const pill = useMemo(() => {
    if (!isVerified) {
      return (
        <TooltipButton
          tooltipIcon={
            <PillNegative
              text={translate(
                'page.addCardModal.selectCardholder.unverifiedText'
              )}
              size={PillSize.Small}
            />
          }
          zIndex={Number(TOOLTIP_ZINDEX)}
          placement="top"
          showArrow={false}
        >
          <Box width={Math.min(MAX_TOOLTIP_WIDTH, width - 20)}>
            {translate('page.addCardModal.selectCardholder.unverifiedTooltip')}
          </Box>
        </TooltipButton>
      )
    }

    if (isNewUser) {
      return (
        <PillBasic
          text={translate('page.addCardModal.selectCardholder.newText')}
          size={PillSize.Small}
        />
      )
    }

    return undefined
  }, [isVerified, isNewUser, width])

  return (
    <StyledRadioCard
      id={cardholder.id}
      subtitle={
        isCurrentUser
          ? translate('page.addCardModal.selectCardholder.currentUserSubtitle')
          : undefined
      }
      title={getCustomerDisplayName(cardholder)}
      desc={getCustomerRoleString(cardholder.role)}
      value={value}
      icon={() => (
        <UserIconAvatar
          icon={cardholder.icon}
          firstName={cardholder.firstname || ''}
          size={AvatarUserSize.Small}
        />
      )}
      onChange={onChange}
      onBlur={onBlur}
      checked={checked}
      name={name}
      disabled={!isVerified}
      contextContent={pill}
    />
  )
}

export const fragments = {
  Customer: gql`
    fragment CardholderRadioOptionCustomerFragment on Customer {
      firstname
      lastname
      id
      icon {
        ...IconAvatarIconFragment
      }
      role
      kycStatus
      createdAt
    }
    ${IconAvatar.fragments.Icon}
  `,
}
