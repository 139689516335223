import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { contactFieldsDoc } from './ContactFragment.generated'

const defaultOptions = {} as const
export type GetContactsByNameQueryVariables = Types.Exact<{
  name: Types.Scalars['String']['input']
}>

export type GetContactsByNameQueryResponse = {
  __typename?: 'Query'
  getContacts: {
    __typename?: 'ContactConnection'
    contacts: Array<{
      __typename?: 'Contact'
      id: string
      businessName: string | null
      isSelf: boolean | null
      phone: string | null
      icon: {
        __typename?: 'Icon'
        colour: string | null
        image: string | null
        letter: string | null
        images: Array<{
          __typename?: 'Image'
          size: Types.ImageSize
          url: string
        }> | null
      } | null
      paymentInstruments: Array<{
        __typename?: 'PaymentInstrument'
        id: string
        details:
          | { __typename: 'BankAccountDetails' }
          | { __typename: 'BpayDetails' }
          | { __typename: 'NppDetails' }
          | {
              __typename: 'PaymentInstrumentBpayDynamicCrnDetails'
              nickname: string | null
              billerName: string
              billerCode: string
            }
          | {
              __typename: 'PaymentInstrumentBpayStaticCrnDetails'
              nickname: string | null
              billerName: string
              billerCode: string
              crn: string
            }
      }> | null
      email: { __typename?: 'ContactEmail'; email: string } | null
      phoneV2: { __typename?: 'ContactPhone'; phone: string } | null
    } | null>
  }
}

export const GetContactsByName = gql`
  query GetContactsByName($name: String!) {
    getContacts(
      limit: 50
      contactType: BUSINESS
      filter: { name: { beginsWith: $name } }
    ) {
      contacts {
        ...contactFields
      }
    }
  }
  ${contactFieldsDoc}
` as unknown as TypedDocumentNode<
  GetContactsByNameQueryResponse,
  GetContactsByNameQueryVariables
>

/**
 * __useGetContactsByNameQuery__
 *
 * To run a query within a React component, call `useGetContactsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetContactsByNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactsByNameQueryResponse,
    GetContactsByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContactsByNameQueryResponse,
    GetContactsByNameQueryVariables
  >(GetContactsByName, options)
}
export function useGetContactsByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactsByNameQueryResponse,
    GetContactsByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContactsByNameQueryResponse,
    GetContactsByNameQueryVariables
  >(GetContactsByName, options)
}
export type GetContactsByNameQueryHookResult = ReturnType<
  typeof useGetContactsByNameQuery
>
export type GetContactsByNameLazyQueryHookResult = ReturnType<
  typeof useGetContactsByNameLazyQuery
>
export type GetContactsByNameQueryResult = Apollo.QueryResult<
  GetContactsByNameQueryResponse,
  GetContactsByNameQueryVariables
>
