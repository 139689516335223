import { Route, Routes } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'

import { ROOT } from 'const/routes'
import { SettingsCreateUserType, SettingsSiteUsers, SettingsUser } from 'pages'
import { NotFound } from 'pages/NotFound'
import { RoleGuardedRoutes } from 'components/RoleGuardedRoutes'

export const SiteUsersRoutes = () => (
  <Routes>
    <Route index element={<SettingsSiteUsers />} />

    <Route element={<RoleGuardedRoutes allowedRoles={[CustomerRole.ADMIN]} />}>
      <Route
        path={ROOT.PORTAL.PAYMENTS.SITES.SITE.USERS.EDIT.relative}
        element={<SettingsUser isEditForm />}
      />
      <Route
        path={ROOT.PORTAL.PAYMENTS.SITES.SITE.USERS.CREATE_TYPE.relative}
        element={<SettingsCreateUserType />}
      />
      <Route
        path={ROOT.PORTAL.PAYMENTS.SITES.SITE.USERS.CREATE_DETAILS.relative}
        element={<SettingsUser isEditForm={false} />}
      />
    </Route>

    <Route path="*" element={<NotFound />} />
  </Routes>
)
