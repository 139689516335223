import { useLazyQuery } from '@apollo/client'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'

import { GetInvoiceItemSettings as GetInvoiceItemSettingsResponse } from 'types/gql-types/GetInvoiceItemSettings'

import { GetInvoiceItemSettings } from './graphql/getInvoiceItemSettings'

export const useGetInvoiceItemSettings = () => {
  const flags = useFeatureFlags()
  const user = useLoggedInUser()

  const [getInvoiceItemSettings, { data, loading: isLoading }] =
    useLazyQuery<GetInvoiceItemSettingsResponse>(GetInvoiceItemSettings, {
      fetchPolicy: 'network-only',
      variables: {
        entityUuid: user.userData?.entityUuid ?? '',
      },
    })

  const settings = flags.POSItems
    ? data?.getCatalogSettings
    : data?.getItemSettings

  return {
    getInvoiceItemSettings,
    isLoading,
    settings,
  }
}
