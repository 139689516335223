export const IS_FROM_APP_KEY = 'is-from-app'
export const IOS_APPSTORE_URL =
  'https://apps.apple.com/au/app/zeller/id6444711724'

const AppReferrer = {
  getIsFromApp: (): boolean => {
    try {
      const valueJson = sessionStorage.getItem(IS_FROM_APP_KEY)
      if (!valueJson) {
        return false
      }

      const value = JSON.parse(valueJson)
      if (typeof value !== 'boolean') {
        return false
      }

      return value
    } catch {
      return false
    }
  },
  setIsFromApp: (value: boolean): void => {
    sessionStorage.setItem(IS_FROM_APP_KEY, JSON.stringify(value))
  },
  gotoAppStoreUrl: (): void => {
    window.location.assign(IOS_APPSTORE_URL)
  },
}

export const useAppReferrer = () => AppReferrer
