import { useEffect } from 'react'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { DEFAULT_GET_LIMIT } from '../../../ItemManagement.utils'
import { useGetCategoriesTableSubscription } from '../../ItemManagementContext/hooks/useGetCategoriesTableSubscription'
import { useItemManagementContext } from '../../ItemManagementContext/ItemManagementContext'
import { CategoryTableData } from '../CategoriesTable.types'
import { useCategoriesTableColumns } from './useCategoriesTableColumns'

export const initialCategories = Array(DEFAULT_GET_LIMIT).fill({
  id: null,
  color: null,
  items: null,
  name: null,
})

export const useCategoriesTable = () => {
  useGetCategoriesTableSubscription()
  const {
    categories: {
      data: categories,
      filterInput,
      getCategories,
      hasError,
      hasNoInitialResults,
      hasNoResults,
      isDefaultFilters,
      isDefaultSort,
      isLoading,
      sortInput,
    },
    hasNoItemsAndCategories,
  } = useItemManagementContext()

  const columns = useCategoriesTableColumns()

  useEffect(() => {
    getCategories({
      variables: {
        limit: DEFAULT_GET_LIMIT,
        filter: filterInput,
        sort: sortInput,
        nextToken: undefined,
      },
    })
  }, [filterInput, getCategories, sortInput])

  const data: CategoryTableData[] =
    categories?.map((category) => ({
      color: category.color,
      id: category.id,
      items: category.itemsNumber,
      name: category.name,
    })) ?? initialCategories

  const table = useReactTable<CategoryTableData>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    meta: { isLoading: hasNoInitialResults || isLoading },
  })

  const hasHeaderBasedFilters = Boolean(
    filterInput?.idFilter?.categoryUuids?.length ??
      filterInput?.name ??
      !isDefaultSort
  )

  return {
    hasError,
    hasNoItemsAndCategories,
    hasNoResults,
    isDefaultFilters,
    hasHeaderBasedFilters,
    isLoading,
    table,
  }
}
