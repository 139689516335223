import { Outlet } from 'react-router-dom-v5-compat'
import { PageTemplate } from '@npco/zeller-design-system'

import { useContactsContext } from '../ContactsContext/ContactsContext'
import { ContactsPrimaryHeaderRow } from './ContactsPrimaryHeaderRow/ContactsPrimaryHeaderRow'
import { ContactsSecondaryHeaderRow } from './ContactsSecondaryHeaderRow/ContactsSecondaryHeaderRow'
import { ContactsTertiaryHeaderRow } from './ContactsTertiaryHeaderRow/ContactsTertiaryHeaderRow'

export const ContactsLayout = () => {
  const { hasContact } = useContactsContext()

  return (
    <PageTemplate
      HeaderPrimaryRow={<ContactsPrimaryHeaderRow />}
      HeaderSecondaryRow={hasContact && <ContactsSecondaryHeaderRow />}
      HeaderTertiaryRow={hasContact && <ContactsTertiaryHeaderRow />}
    >
      <Outlet />
    </PageTemplate>
  )
}
