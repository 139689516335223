import { useCallback, useState } from 'react'

import {
  Address,
  AddressPage,
} from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage.types'
import { AUSTRALIA_COUNTRY_CODE } from 'const/common'

export const useReportLostAndReplaceCardAddressPageToggle = () => {
  const [addressPage, setAddressPage] = useState(AddressPage.PredefinedAddress)
  const [initialAddress, setInitialAddress] = useState<Address>()

  const goToPredefinedAddressPage = useCallback(
    (address: Omit<Address, 'country'>) => {
      setAddressPage(AddressPage.PredefinedAddress)
      setInitialAddress({
        ...address,
        country: AUSTRALIA_COUNTRY_CODE,
      })
    },
    []
  )

  const goToCustomAddressPage = useCallback(() => {
    setAddressPage(AddressPage.CustomAddress)
  }, [])

  return {
    goToPredefinedAddressPage,
    goToCustomAddressPage,
    currentPage: addressPage,
    initialAddress,
  }
}
