import { useMutation } from '@apollo/client'
import { UpdateCustomerInput } from '@npco/mp-gql-types'
import { showErrorToast } from '@npco/zeller-design-system'
import { UpdateCustomer } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/customer'
import { useZellerAuthenticationContext } from 'auth/ZellerAuthenticationContext'

import { ErrorType, ServerError } from 'types/errors'
import {
  UpdateCustomer as UpdateCustomerPayload,
  UpdateCustomerVariables,
} from 'types/gql-types/UpdateCustomer'
import { errorMessages } from 'translations'

export const useUpdateCustomer = () => {
  const { stepUpAuth } = useZellerAuthenticationContext()
  const [updateCustomer, { loading: isUpdatingCustomer }] = useMutation<
    UpdateCustomerPayload,
    UpdateCustomerVariables
  >(UpdateCustomer, {
    onError: ({ graphQLErrors: [graphQLError] }) => {
      const error = graphQLError as ServerError

      if (
        error.errorType === ErrorType.MFA_REQUIRED ||
        error.errorType === ErrorType.MFA_SENSITIVE_ACCESS_EXPIRED
      ) {
        showErrorToast(errorMessages.expired)
        stepUpAuth()
      }
    },
  })

  return {
    updateCustomer: (customer: UpdateCustomerInput, update: () => void) =>
      updateCustomer({
        variables: { customer },
        update,
      }),
    isUpdatingCustomer,
  }
}
