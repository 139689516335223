import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CardBalanceCellDebitCardV2FragmentDoc } from './components/CardBalanceCell/CardBalanceCell.generated'

export type DebitCardBalanceCellDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  id: string
  status: Types.DebitCardStatus
  customer: { __typename?: 'Customer'; id: string } | null
  debitCardAccount: {
    __typename: 'DebitCardAccountV2'
    id: string
    balance: { __typename?: 'Money'; value: string } | null
  }
}

export const DebitCardBalanceCellDebitCardV2FragmentDoc = gql`
  fragment DebitCardBalanceCellDebitCardV2Fragment on DebitCardV2 {
    id
    customer {
      id
    }
    ...CardBalanceCellDebitCardV2Fragment
  }
  ${CardBalanceCellDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  DebitCardBalanceCellDebitCardV2Fragment,
  undefined
>
