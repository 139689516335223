import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useTranslations } from '@npco/utils-translations'
import { NewBadge, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as PosIcon } from 'assets/svg/Sidebar/pos.svg'
import { ROOT } from 'const/routes'
import { SidebarItem } from 'components/Sidebar/components/Item/Item'
import { useSidebarLocalCache } from 'components/Sidebar/hooks/useSidebarLocalCache'
import { translationsShared } from 'translations/index'

import { translations } from '../Sidebar.i18n'

export const PosLiteItem = () => {
  const { isFolded } = useSidebarLocalCache()
  const flags = useFeatureFlags()
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  if (!flags.ZellerPos) {
    return null
  }

  return (
    <SidebarItem
      badge={<NewBadge size="Small" text={tShared('new')} />}
      icon={
        <SvgIcon>
          <PosIcon />
        </SvgIcon>
      }
      isFolded={isFolded}
      isNavLink
      route={ROOT.PORTAL.POS_LITE.path}
      className="nav-item-pos-lite"
    >
      {t('posLite')}
    </SidebarItem>
  )
}
