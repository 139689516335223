import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'
import { sendToDataLayer } from 'utils/gtmHelper'
import { clearSessionStorage } from 'services/sessionStorage/utils'

export const useLogout = () => {
  const navigate = useNavigate()

  const cleanUpBeforeLoggingOut = useCallback(() => {
    sendToDataLayer({
      event: 'Logout',
      entity: undefined,
      user: undefined,
    })
    clearSessionStorage()
  }, [])

  const logout = () => {
    cleanUpBeforeLoggingOut()
    navigate(ROOT.LOGOUT.path)
  }

  return {
    logout,
  }
}
