import { useTranslations } from '@npco/utils-translations'
import {
  Flex,
  NotesBasic,
  showErrorToast,
  showSuccessToast,
  useModalState,
} from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { EditNotesModal } from 'components/EditNotesModal/EditNotesModal'
import { translationsShared } from 'translations'

import { useItemsAnalytics } from '../../../../hooks/useItemsAnalytics'
import { useUpdateItemMutation } from '../graphql/updateItem.generated'
import { ItemDescriptionFragment } from './ItemDrawerDescription.generated'
import { translations } from './ItemDrawerDescription.i18n'

export const ItemDrawerDescription = ({
  description: initialDescription,
  id,
}: ItemDescriptionFragment) => {
  const isMobileResolution = useIsMobileResolution()
  const { trackEditItem } = useItemsAnalytics()
  const { isModalOpen, openModal, closeModal } = useModalState()

  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const [updateItem, { loading: isUpdatingItem }] = useUpdateItemMutation()

  const handleSubmit = (description: string) => {
    async function sendRequest() {
      await updateItem({ variables: { item: { id, description } } })

      trackEditItem({ description: initialDescription ?? '' }, { description })
      showSuccessToast(t('successNotification'))
      closeModal()
    }

    sendRequest().catch(() => showErrorToast(t('errorNotification')))
  }
  return (
    <Flex flexDirection="column" gridGap="16px">
      <NotesBasic
        addLabel={tShared('edit')}
        editLabel={tShared('edit')}
        note={initialDescription === '' ? null : initialDescription}
        onEdit={openModal}
        placeholder={t('noData')}
        title={t('heading')}
        maxDisplayHeight={isMobileResolution ? 'unset' : 360}
      />

      <EditNotesModal
        handleSubmit={handleSubmit}
        isLoading={isUpdatingItem}
        isOpen={isModalOpen}
        notes={initialDescription}
        onCancel={closeModal}
        placeholder={t('placeholder')}
        title={t('heading')}
      />
    </Flex>
  )
}
