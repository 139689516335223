import { Navigate } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { Flex } from '@npco/zeller-design-system'
import { isEmpty } from 'lodash-es'

import { useAccounts } from 'hooks/banking'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import {
  NoInitialResults,
  NoSearchResults,
} from 'components/Placeholders/NoAccountTransactions'
import { SpinnerWrapped } from 'components/Spinner'

import { useContactAccountTransactions } from '../../hooks/useContactAccountTransactions/useContactAccountTransactions'
import { rvSelectedContact } from '../../rv-deprecated/contacts'
import { AccountsList } from '../ContactSections/AccountsList/AccountsList'

export const ContactAccounts = () => {
  const contact = useReactiveVar(rvSelectedContact)

  const {
    groupedTransactions,
    fetchMoreTransactions,
    isLoadingTransactions,
    areFiltersInDefaultState,
    hasMore,
    handleSelectedTransactionUpdate,
  } = useContactAccountTransactions()

  const { accountById, isLoadingAccounts } = useAccounts()

  if (!contact) {
    return <Navigate to={ROOT.PORTAL.CONTACTS.path} replace />
  }

  if (isLoadingTransactions || isLoadingAccounts) {
    return <SpinnerWrapped variant="centre" />
  }

  if (isEmpty(groupedTransactions) && areFiltersInDefaultState) {
    return (
      <Flex flex="1" justifyContent="center">
        <NoInitialResults
          description={translate(
            'page.contact.sections.accounts.emptyStateDescription'
          )}
        />
      </Flex>
    )
  }

  if (isEmpty(groupedTransactions)) {
    return (
      <Flex flex="1" height="100%" justifyContent="center">
        <NoSearchResults />
      </Flex>
    )
  }

  return (
    <AccountsList
      accountById={accountById}
      groupedTransactions={groupedTransactions}
      fetchMore={fetchMoreTransactions}
      hasMore={hasMore}
      handleSelectedTransactionUpdate={handleSelectedTransactionUpdate}
    />
  )
}
