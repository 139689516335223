import { useCallback, useState } from 'react'
import {
  BREAKPOINT,
  Ellipsize,
  ErrorMessageForm,
  Flex,
  InputSelectComboboxStandard,
  InputSelectComboboxStandardItemWrapper,
  Message,
  SelectInputBasicProps,
  Tabs,
} from '@npco/zeller-design-system'
import currency from 'currency.js'
import { Subject } from 'rxjs'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

import {
  InvoiceNameSelectedItem,
  InvoiceNameSelectedTab,
} from '../../../../InvoiceFormAccordions.types'
import { getTaxRate } from '../../InvoiceItemsAccordion.utils'
import { useInvoiceNameCombobox } from './hooks/useInvoiceNameCombobox'
import { useInvoiceNameItems } from './hooks/useInvoiceNameItems'
import { InvoiceNameItemPrice } from './InvoiceItemName.styled'

export const translations = {
  label: translate('page.invoice.formSections.items.nameLabel'),
  noRecentlyUsedItemsDescription: translate(
    'page.invoice.formSections.items.itemsNameNoRecentlyUsedItemsDescription'
  ),
  noRecentlyUsedItemsTitle: translate(
    'page.invoice.formSections.items.itemsNameNoRecentlyUsedItemsTitle'
  ),
  noSavedItemsDescription: translate(
    'page.invoice.formSections.items.itemsNameNoSavedItemsDescription'
  ),
  noSavedItemsTitle: translate(
    'page.invoice.formSections.items.itemsNameNoSavedItemsTitle'
  ),
  noResultsDescription: translate(
    'page.invoice.formSections.items.itemsNameNoResultsDescription'
  ),
  noResultsTitle: translate(
    'page.invoice.formSections.items.itemsNameNoResultsTitle'
  ),
}

export interface InvoiceItemNameProps {
  index: number
  lineIndex: number
  name: string
}

export const InvoiceItemName = ({
  index,
  // lineIndex,
  name,
}: InvoiceItemNameProps) => {
  const [name$] = useState(new Subject<string>())

  const {
    error,
    handleBlur,
    handleChange,
    handleInputChange,
    handleInputClear,
    inputValue,
    isTaxInclusive,
    selectedItem,
    selectedTab,
    tabs,
  } = useInvoiceNameCombobox({ index, name$ })

  const { hasItems, hasMore, isLoading, items, loadMore } = useInvoiceNameItems(
    {
      name$,
      selectedItem,
      selectedTab,
    }
  )

  const isMobile = useIsMobileResolution(BREAKPOINT.LG)

  const renderItem: SelectInputBasicProps<InvoiceNameSelectedItem>['renderItem'] =
    useCallback(
      (renderProps) => {
        const { label, price, taxApplicable } = renderProps.item

        // NOTE: price returned from item service is tax exclusive so must update
        // price if invoice is tax inclusive
        const calculatedPrice = price.multiply(
          getTaxRate(taxApplicable, isTaxInclusive)
        )
        const priceFormatted = currency(calculatedPrice).format()

        return (
          <InputSelectComboboxStandardItemWrapper {...renderProps}>
            <Flex
              flex={1}
              alignItems="center"
              justifyContent="space-between"
              maxWidth="100%"
            >
              <Ellipsize>{label}</Ellipsize>
              <InvoiceNameItemPrice>{priceFormatted}</InvoiceNameItemPrice>
            </Flex>
          </InputSelectComboboxStandardItemWrapper>
        )
      },
      [isTaxInclusive]
    )

  const renderNoItemsPlaceholder = useCallback(() => {
    if (inputValue && !isLoading) {
      return (
        <Message
          description={translations.noResultsDescription}
          title={translations.noResultsTitle}
        />
      )
    }

    if (isLoading) {
      return null
    }

    if (hasItems) {
      return null
    }

    const isSavedItemsTab = selectedTab === InvoiceNameSelectedTab.SavedItems

    const description = isSavedItemsTab
      ? translations.noSavedItemsDescription
      : translations.noRecentlyUsedItemsDescription

    const title = isSavedItemsTab
      ? translations.noSavedItemsTitle
      : translations.noRecentlyUsedItemsTitle

    return <Message description={description} title={title} />
  }, [isLoading, hasItems, inputValue, selectedTab])

  const renderHeader = useCallback(() => {
    return (
      <Flex paddingLeft="24px" paddingRight="24px" marginBottom="10px">
        <Tabs currentTab={selectedTab} size="small">
          {tabs.map((tab) => (
            <Tabs.Item key={tab.value} {...tab} />
          ))}
        </Tabs>
      </Flex>
    )
  }, [tabs, selectedTab])

  return (
    <>
      <InputSelectComboboxStandard<InvoiceNameSelectedItem>
        // NOTE: add prop support to design system
        // aria-label={ariaLabel}
        // data-testid={`invoicing-invoice-line-item-name-${lineIndex}`}
        hasError={Boolean(error)}
        hasMore={hasMore}
        inputValue={inputValue}
        isLoading={isLoading}
        items={items}
        label={translations.label}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onInputClear={handleInputClear}
        onInputChange={handleInputChange}
        onLoadMoreItems={loadMore}
        renderNoItemsPlaceholder={renderNoItemsPlaceholder}
        popperWidth={isMobile ? undefined : '680px'}
        renderHeader={renderHeader}
        renderItem={renderItem}
        selectedItem={selectedItem}
      />
      <ErrorMessageForm hasError={Boolean(error)} errorMessage={error} />
    </>
  )
}
