import { useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'

import {
  CloseAccountQuery as CloseAccountQueryResponse,
  CloseAccountQueryVariables,
} from 'types/gql-types/CloseAccountQuery'

import { CloseAccountQuery } from './graphql/CloseAccountQuery'

interface UseCloseAccountQueryProps {
  accountId: string
}

export const useCloseAccountQuery = ({
  accountId,
}: UseCloseAccountQueryProps) => {
  const { data, error, loading, refetch, networkStatus } = useQuery<
    CloseAccountQueryResponse,
    CloseAccountQueryVariables
  >(CloseAccountQuery, {
    variables: { debitCardAccountUuid: accountId },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  })

  const { getDebitCardAccountV2, getBillingAccount, getEntity } = data || {}

  const { account, simBillingAccountId, settlementAccountId } = useMemo(
    () => ({
      account: getDebitCardAccountV2 || null,
      simBillingAccountId: getBillingAccount?.id || null,
      settlementAccountId: getEntity?.remitToCard
        ? getEntity?.debitCardAccountUuid
        : null,
    }),
    [getDebitCardAccountV2, getBillingAccount, getEntity]
  )

  return {
    account,
    simBillingAccountId,
    settlementAccountId,
    hasSimBillingAccountQueryError: error && !getBillingAccount,
    hasSettlementAccountQueryError: error && !getEntity,
    isLoading: loading || networkStatus === NetworkStatus.refetch,
    refetch,
  }
}
