import { ApolloCache, ApolloClient } from '@apollo/client'
import { DebitCardProductType } from '@npco/mp-gql-types'
import { GetCorporateCardsList } from 'features/Cards/AllCardsCorporate/hooks/useQueryCorporateCards/graphql/getCorporateCardsList'
import {
  GetCorporateCardsListQueryResponse,
  GetCorporateCardsListQueryVariables,
} from 'features/Cards/AllCardsCorporate/hooks/useQueryCorporateCards/graphql/getCorporateCardsList.generated'

type Entity = GetCorporateCardsListQueryResponse['getEntity']
type CorporateCard =
  GetCorporateCardsListQueryResponse['getDebitCardsV2']['cards'][number]

interface CacheCorporateCardListProps {
  cache: ApolloCache<any>
  mapToNewList: (oldCards: (CorporateCard | null)[]) => (CorporateCard | null)[]
  client?: ApolloClient<any>
}

export const cacheCorporateCardList = ({
  cache,
  mapToNewList,
  client,
}: CacheCorporateCardListProps) => {
  const variables = {
    filter: {
      productType: {
        eq: DebitCardProductType.EXPENSE,
      },
    },
  }

  const writeCorporateCardsQuery = (
    oldCards: (CorporateCard | null)[],
    entity: Entity
  ) => {
    cache.writeQuery<GetCorporateCardsListQueryResponse>({
      query: GetCorporateCardsList,
      variables,
      data: {
        getDebitCardsV2: {
          cards: mapToNewList(oldCards),
        },
        getEntity: entity,
      },
    })
  }

  const cardList = cache.readQuery<GetCorporateCardsListQueryResponse>({
    query: GetCorporateCardsList,
    variables,
  })

  if (cardList) {
    writeCorporateCardsQuery(cardList.getDebitCardsV2.cards, cardList.getEntity)
    return
  }

  if (client) {
    client
      .query<
        GetCorporateCardsListQueryResponse,
        GetCorporateCardsListQueryVariables
      >({
        query: GetCorporateCardsList,
        variables,
        errorPolicy: 'all',
      })
      .then(({ data }) => {
        writeCorporateCardsQuery(data.getDebitCardsV2.cards, data.getEntity)
      })
  }
}
