import { gql } from '@apollo/client'

import { InvoiceCoreFields } from './invoiceCoreFieldsFragment'

export const UpdateInvoice = gql`
  ${InvoiceCoreFields}
  mutation UpdateInvoice($input: UpdateInvoiceInput!) {
    updateInvoice(input: $input) {
      ...InvoiceCoreFields
    }
  }
`
