import { lazy } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom-v5-compat'
import { PORTAL_SHOP } from '@npco/mp-feature-onboarding-shop'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { AccountsRoutes } from 'features/Accounts/feature-accounts'
import { ReportsRoutes } from 'features/Reports'
import { StartupModals } from 'features/StartupModals'

import { usePortalRoutesSetup } from 'hooks/usePortalRoutesSetup'
import { ROOT } from 'const/routes'
import { DashboardRoutes } from 'pages/Dashboard/DashboardRoutes'
import { Demos } from 'pages/Demos/Demos'
import { GlobalModals } from 'pages/GlobalModals/GlobalModals'
import { Help } from 'pages/Help/Help'
import { LoginError } from 'pages/Login/LoginError/LoginError'
import { NotFound } from 'pages/NotFound'
import { Referral } from 'pages/Referral'
import { BiometricsRoutes } from 'pages/Settings/Profile/Security/BiometricsRoutes'
import {
  DashboardLayout,
  DashboardLayoutContent,
} from 'layouts/DashboardLayout'
import { useGetCustomerMarketing } from 'components/App/components/PortalRoutes/CustomerMarketing/useGetCustomerMarketing'
import { LazyLoadComponent } from 'components/LazyLoadComponent/LazyLoadComponent'
import { SpinnerWrapped } from 'components/Spinner'

import { useRedirectAfterLogin } from './hooks/useRedirectAfterLogin'
import { PaymentsRoutes } from './PaymentsRoutes/PaymentsRoutes'
import { SettingsRoutes } from './SettingsRoutes/SettingsRoutes'
import { withIsOnboardedGuard } from './utils/IsOnboardedGuard'

const CardsRoutes = lazy(
  () => import('features/Cards/feature-cards/routes/CardsRoutes')
)

const ContactsRoutes = lazy(
  () => import('features/Contacts/routes/ContactsRoutes')
)

const InvoicingRoutes = lazy(
  () => import('features/Invoicing/routes/InvoicingRoutes')
)

const NotificationsRoutes = lazy(
  () => import('features/Notifications/routes/NotificationsRoutes')
)

const PortalKYCRoutes = lazy(
  () => import('pages/PortalKYC/routes/PortalKYCRoutes')
)

const ItemsRoutes = lazy(() => import('features/Items/routes/ItemsRoutes'))
const PosLiteRoutes = lazy(() => import('features/PosLite/PosLiteRoutes'))
const OnboardingShopApp = lazy(
  () => import('features/OnboardingApp/OnboardingShop/OnboardingShopRoutes')
)

export const PortalRoutes = withIsOnboardedGuard(() => {
  const { fetchSetupDataError, isFetchSetupDataLoading } =
    usePortalRoutesSetup()
  const { pathname } = useLocation()
  const {
    isLoading: isLoadingCMS,
    bannerSlug,
    modalSlug,
  } = useGetCustomerMarketing()

  const isInKycPath = pathname.includes(ROOT.PORTAL.KYC.path)
  const isFetchSetupDataFailed = Boolean(fetchSetupDataError)
  const shouldLoadStartupModals = !isInKycPath

  const flags = useFeatureFlags()

  useRedirectAfterLogin({
    skip: isFetchSetupDataLoading || isFetchSetupDataFailed,
  })

  if (isFetchSetupDataLoading) {
    return <SpinnerWrapped variant="centre" />
  }

  if (fetchSetupDataError) {
    return <LoginError error={fetchSetupDataError} />
  }

  return (
    <>
      <GlobalModals />
      {shouldLoadStartupModals && (
        <StartupModals isLoadingCMS={isLoadingCMS} modalSlug={modalSlug} />
      )}
      <Routes>
        <Route
          path={`${PORTAL_SHOP.DEALS.relative}/*`}
          element={
            <LazyLoadComponent>
              <OnboardingShopApp />
            </LazyLoadComponent>
          }
        />
        <Route
          path={`${ROOT.PORTAL.KYC.relative}/*`}
          element={
            <LazyLoadComponent>
              <PortalKYCRoutes />
            </LazyLoadComponent>
          }
        />
        <Route
          path={`${ROOT.PORTAL.BIOMETRICS.relative}/*`}
          element={<BiometricsRoutes />}
        />

        <Route
          index
          element={<Navigate to={ROOT.PORTAL.OVERVIEW.PAYMENTS.path} replace />}
        />
        <Route
          element={
            <DashboardLayout
              bannerSlug={bannerSlug}
              isLoadingCMS={isLoadingCMS}
            >
              <Outlet />
            </DashboardLayout>
          }
        >
          <Route
            path={`${ROOT.PORTAL.OVERVIEW.relative}/*`}
            element={<DashboardRoutes />}
          />
          <Route
            path={`${ROOT.PORTAL.NOTIFICATIONS.relative}/*`}
            element={
              <LazyLoadComponent>
                <NotificationsRoutes />
              </LazyLoadComponent>
            }
          />
          <Route
            path={ROOT.PORTAL.REFERRAL.relative}
            element={
              <DashboardLayoutContent>
                <Referral />
              </DashboardLayoutContent>
            }
          />
          <Route
            path={ROOT.PORTAL.HELP.relative}
            element={
              <DashboardLayoutContent>
                <Help />
              </DashboardLayoutContent>
            }
          />
          <Route
            path={`${ROOT.PORTAL.PAYMENTS.relative}/*`}
            element={<PaymentsRoutes />}
          />
          <Route
            path={`${ROOT.PORTAL.SETTINGS.relative}/*`}
            element={<SettingsRoutes />}
          />
          <Route
            path={`${ROOT.PORTAL.ACCOUNTS.relative}/*`}
            element={<AccountsRoutes />}
          />
          <Route
            path={`${ROOT.PORTAL.CARDS.relative}/*`}
            element={
              <LazyLoadComponent>
                <CardsRoutes />
              </LazyLoadComponent>
            }
          />
          <Route
            path={`${ROOT.PORTAL.INVOICING.relative}/*`}
            element={
              <LazyLoadComponent>
                <InvoicingRoutes />
              </LazyLoadComponent>
            }
          />
          <Route
            path={`${ROOT.PORTAL.CONTACTS.relative}/*`}
            element={
              <LazyLoadComponent>
                <ContactsRoutes />
              </LazyLoadComponent>
            }
          />
          <Route path={`${ROOT.PORTAL.DEMOS.relative}/*`} element={<Demos />} />
          {flags.CashFlowReporting && (
            <Route
              path={`${ROOT.PORTAL.REPORTS.relative}/*`}
              element={<ReportsRoutes />}
            />
          )}
          <Route
            path="*"
            element={
              <DashboardLayoutContent>
                <NotFound />
              </DashboardLayoutContent>
            }
          />
          <Route
            path={`${ROOT.PORTAL.ITEMS.relative}/*`}
            element={
              <LazyLoadComponent>
                <ItemsRoutes />
              </LazyLoadComponent>
            }
          />
          <Route
            path={`${ROOT.PORTAL.POS_LITE.relative}/*`}
            element={
              <LazyLoadComponent>
                <PosLiteRoutes />
              </LazyLoadComponent>
            }
          />
        </Route>
      </Routes>
    </>
  )
})
