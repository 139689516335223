import { EntityInput, EntityType, OnboardingStatus } from '@npco/mp-gql-types'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { setSessionStorageItem } from 'services/sessionStorage/utils'

export const getIsEntityOnboarded = (entityDetails?: EntityInput) => {
  setSessionStorageItem('redirect', false)
  const onboardingStatus =
    entityDetails?.onboardingStatus || rvEntityDetails().onboardingStatus
  return (
    onboardingStatus === OnboardingStatus.ONBOARDED ||
    onboardingStatus === OnboardingStatus.RC_ONBOARDED
  )
}

export const getIsCompany = () => {
  const { type } = rvEntityDetails()
  return Boolean(type && type === EntityType.COMPANY)
}

export const getIsIndividualWithAbn = () => {
  const { type, abn, acn } = rvEntityDetails()
  return Boolean(type && (abn || acn) && type === EntityType.INDIVIDUAL)
}
