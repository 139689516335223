import {
  ActionWrapper,
  Header,
  Section,
  SectionHeaderRow,
} from '../ContactGeneral/ContactGeneral.styled'

interface ContactSectionProps {
  heading: React.ReactNode
  actionElement?: React.ReactNode
  children: React.ReactNode
  dataTestId?: string
}
export const ContactSection = ({
  heading,
  actionElement,
  children,
  dataTestId = 'contact-section',
}: ContactSectionProps) => {
  return (
    <Section data-testid={dataTestId}>
      <SectionHeaderRow>
        <Header>{heading}</Header>
        <ActionWrapper>{actionElement}</ActionWrapper>
      </SectionHeaderRow>
      {children}
    </Section>
  )
}
