import { useCallback, useMemo, useState } from 'react'
import { useQuery, useReactiveVar, WatchQueryFetchPolicy } from '@apollo/client'
import { GetDebitCardTransactionAttachmentDownloadUrls } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/debitCardTransactions'
import { rvPersistedFiles } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/debitCardTransactions'

import { createFileFromUrl } from 'utils/file'
import {
  GetDebitCardTransactionAttachmentDownloadUrls as GetDebitCardTransactionAttachmentDownloadUrlsResponse,
  GetDebitCardTransactionAttachmentDownloadUrls_getDebitCardTxnAttachmentDownloadUrls as Image,
  GetDebitCardTransactionAttachmentDownloadUrlsVariables,
} from 'types/gql-types/GetDebitCardTransactionAttachmentDownloadUrls'
import { FileWithId } from 'components/File'

const RECEIPTS_DEBOUNCE_IN_MS = 500

export interface UseTransactionImagesProps {
  transactionUuid: string
  fetchPolicy?: WatchQueryFetchPolicy
}

export const useTransactionImages = ({
  transactionUuid,
  fetchPolicy = 'network-only',
}: UseTransactionImagesProps) => {
  const [isDownloadingFiles, setIsDownloadingFiles] = useState(false)
  const persistedFiles = useReactiveVar(rvPersistedFiles)

  const { loading: isLoadingImages } = useQuery<
    GetDebitCardTransactionAttachmentDownloadUrlsResponse,
    GetDebitCardTransactionAttachmentDownloadUrlsVariables
  >(GetDebitCardTransactionAttachmentDownloadUrls, {
    variables: {
      transactionUuid,
    },
    context: {
      debounceKey: 'debitCardTransactionReceipts',
      debounceTimeout: RECEIPTS_DEBOUNCE_IN_MS,
    },
    fetchPolicy,
    onCompleted: async (result) => {
      const cachedFiles = rvPersistedFiles()[transactionUuid]

      const hasMissedCache = result.getDebitCardTxnAttachmentDownloadUrls.some(
        (downloadUrl) =>
          !cachedFiles?.some((file) => file.id === downloadUrl?.documentUuid)
      )

      try {
        setIsDownloadingFiles(hasMissedCache)

        const fetchedFiles = (
          await Promise.all(
            (
              result.getDebitCardTxnAttachmentDownloadUrls.filter(
                Boolean
              ) as Image[]
            )
              .filter((image) => image.contentType)
              .map(async (downloadUrl) => {
                const cachedFile = cachedFiles?.find(
                  (file) => file.id === downloadUrl.documentUuid
                )

                if (cachedFile) {
                  return cachedFile
                }

                return {
                  file: await createFileFromUrl(
                    downloadUrl.downloadUrl,
                    downloadUrl.fileName,
                    downloadUrl.contentType as string
                  ),
                  id: downloadUrl.documentUuid,
                }
              })
          )
        ).filter((fileWithId) => Boolean(fileWithId.file)) as FileWithId[]

        rvPersistedFiles({
          ...persistedFiles,
          [transactionUuid]: fetchedFiles,
        })
      } finally {
        setIsDownloadingFiles(false)
      }
    },
  })

  const images = useMemo(
    () => persistedFiles[transactionUuid] || [],
    [persistedFiles, transactionUuid]
  )

  const removeImage = useCallback(
    (imageToRemove: FileWithId) => {
      rvPersistedFiles({
        ...persistedFiles,
        [transactionUuid]: images.filter(
          (image) => image.id !== imageToRemove.id
        ),
      })
    },
    [images, persistedFiles, transactionUuid]
  )

  return { images, isLoadingImages, isDownloadingFiles, removeImage }
}
