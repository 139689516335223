import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { GetCategoriesTableCoreFieldsDoc } from './getCategoriesTableCoreFields.generated'

const defaultOptions = {} as const
export type GetCategoriesTableSubscriptionVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type GetCategoriesTableSubscriptionResponse = {
  __typename?: 'Subscription'
  onItemCategoryUpdate: {
    __typename?: 'ItemCategoryBatchUpdate'
    categories: Array<{
      __typename: 'ItemCategory'
      color: string
      id: string
      itemsNumber: number | null
      name: string
      status: Types.ItemCategoryStatus | null
    }> | null
  } | null
}

export const GetCategoriesTableSubscription = gql`
  subscription GetCategoriesTableSubscription($entityUuid: ID!) {
    onItemCategoryUpdate(entityUuid: $entityUuid) {
      categories {
        ...GetCategoriesTableCoreFields
      }
    }
  }
  ${GetCategoriesTableCoreFieldsDoc}
` as unknown as TypedDocumentNode<
  GetCategoriesTableSubscriptionResponse,
  GetCategoriesTableSubscriptionVariables
>

/**
 * __useGetCategoriesTableSubscription__
 *
 * To run a query within a React component, call `useGetCategoriesTableSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesTableSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesTableSubscription({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetCategoriesTableSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetCategoriesTableSubscriptionResponse,
    GetCategoriesTableSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    GetCategoriesTableSubscriptionResponse,
    GetCategoriesTableSubscriptionVariables
  >(GetCategoriesTableSubscription, options)
}
export type GetCategoriesTableSubscriptionHookResult = ReturnType<
  typeof useGetCategoriesTableSubscription
>
export type GetCategoriesTableSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<GetCategoriesTableSubscriptionResponse>
