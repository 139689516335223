import { useTranslations } from '@npco/utils-translations'
import { TakePaymentIcon } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'

import { SidebarItem } from '../components/Item/Item'
import { useSidebarLocalCache } from '../hooks/useSidebarLocalCache'
import { translations } from '../Sidebar.i18n'

export const VirtualTerminalItem = () => {
  const { isFolded } = useSidebarLocalCache()

  const t = useTranslations(translations)

  return (
    <SidebarItem
      icon={<TakePaymentIcon />}
      isFolded={isFolded}
      isNavLink
      route={ROOT.PORTAL.VIRTUAL_TERMINAL.path}
      className="nav-item-virtual-terminal"
    >
      {t('virtualTerminal')}
    </SidebarItem>
  )
}
