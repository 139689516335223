import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { DebitCardsTableDebitCardV2FragmentDoc } from '../../../../../../components/CardsView/components/CardsTable/DebitCardsTable/DebitCardsTable.generated'

const defaultOptions = {} as const
export type ActivateDebitCardMutationVariables = Types.Exact<{
  input: Types.ActivateDebitCardInput
}>

export type ActivateDebitCardMutationResponse = {
  __typename?: 'Mutation'
  activateDebitCard:
    | {
        __typename: 'ActivateDebitCardError'
        error: Types.ActivateDebitCardErrorEnum | null
      }
    | { __typename?: 'DebitCard' }
    | {
        __typename: 'DebitCardV2'
        productType: Types.DebitCardProductType | null
        accessibleProfile: boolean | null
        id: string
        name: string
        owner: string | null
        status: Types.DebitCardStatus
        colour: Types.DebitCardColour
        maskedPan: string
        debitCardAccount: {
          __typename: 'DebitCardAccountV2'
          id: string
          name: string
          icon: { __typename?: 'Icon'; colour: string | null } | null
          balance: { __typename?: 'Money'; value: string } | null
        }
        customer: { __typename?: 'Customer'; id: string } | null
      }
    | null
}

export const ActivateDebitCard = gql`
  mutation ActivateDebitCard($input: ActivateDebitCardInput!) {
    activateDebitCard(input: $input) {
      ... on DebitCardV2 {
        productType
        accessibleProfile
        ...DebitCardsTableDebitCardV2Fragment
      }
      ... on ActivateDebitCardError {
        __typename
        error
      }
    }
  }
  ${DebitCardsTableDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  ActivateDebitCardMutationResponse,
  ActivateDebitCardMutationVariables
>
export type ActivateDebitCardMutationFn = Apollo.MutationFunction<
  ActivateDebitCardMutationResponse,
  ActivateDebitCardMutationVariables
>

/**
 * __useActivateDebitCardMutation__
 *
 * To run a mutation, you first call `useActivateDebitCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateDebitCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateDebitCardMutation, { data, loading, error }] = useActivateDebitCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateDebitCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateDebitCardMutationResponse,
    ActivateDebitCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ActivateDebitCardMutationResponse,
    ActivateDebitCardMutationVariables
  >(ActivateDebitCard, options)
}
export type ActivateDebitCardMutationHookResult = ReturnType<
  typeof useActivateDebitCardMutation
>
export type ActivateDebitCardMutationResult =
  Apollo.MutationResult<ActivateDebitCardMutationResponse>
export type ActivateDebitCardMutationOptions = Apollo.BaseMutationOptions<
  ActivateDebitCardMutationResponse,
  ActivateDebitCardMutationVariables
>
