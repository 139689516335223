import { Fragment } from 'react'
import {
  Box,
  Divider,
  Flex,
  SkeletonAvatar,
  SkeletonBasic,
} from '@npco/zeller-design-system'

const WIDTHS = {
  loader1: [286, 196],
  loader2: [326, 146],
  loader3: [296, 96],
  loader4: [286, 196],
  loader5: [326, 146],
  loader6: [296, 96],
  loader7: [286, 196],
}

export const LoaderList = () => {
  return (
    <Box data-testid="loader" as="ul" m={0} p={0} mt="12px" flexGrow={1}>
      {Object.entries(WIDTHS).map(([key, [width1, width2]], i) => (
        <Fragment key={key}>
          <Flex as="li" alignItems="center" gridGap="12px" px="6px" py="12px">
            <SkeletonAvatar />
            <Flex flexDirection="column" gridGap="12px">
              <SkeletonBasic borderRadius={8} height={12} width={width1} />
              <SkeletonBasic borderRadius={8} height={8} width={width2} />
            </Flex>
          </Flex>
          <Divider margin={0} />
        </Fragment>
      ))}
    </Box>
  )
}
