import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import {
  SessionTimeoutModal,
  useActivityTimer,
} from '@npco/feature-session-timeout'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useZellerAuthenticationContext } from 'auth/ZellerAuthenticationContext'

import { ROOT } from 'const/routes'

import { useTimeoutContext } from '../context/SessionTimeoutContext'

export const MerchantPortalSessionTimeoutModal = () => {
  const flags = useFeatureFlags()
  const defaultTimeout = flags.PortalInactivityTimer
  const onboardingTimeout = flags.OnboardingInactivityTimer
  const countdownTime = flags.CountDownModalTimer

  const { timeoutContext } = useTimeoutContext()
  const sessionTimeout =
    timeoutContext === 'onboarding' ? onboardingTimeout : defaultTimeout
  const { getNewToken } = useZellerAuthenticationContext()
  const { isUserInactive, clearActivityTimer, setIsUserInactive } =
    useActivityTimer({ inactivityTimeout: sessionTimeout })
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  const goToLogoutWithInactivityTimeout = useCallback(() => {
    navigate(
      `${ROOT.LOGOUT.INACTIVITY_TIMEOUT.path}?redirect=${pathname}${search}`
    )
  }, [navigate, pathname, search])

  return (
    <SessionTimeoutModal
      isOpen={isUserInactive}
      countdownTime={countdownTime}
      onTimeout={() => {
        goToLogoutWithInactivityTimeout()
        clearActivityTimer()
      }}
      onStayLoggedIn={() => {
        getNewToken(true)
        setIsUserInactive(false)
      }}
      onLogOut={() => {
        goToLogoutWithInactivityTimeout()
        clearActivityTimer()
      }}
    />
  )
}
