import { showApiErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvThirdPartyDepositBank } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/depositAccount'

import { ThirdPartyBankAccount } from 'types/accounts'
import { Account } from 'types/settings'
import { useDepositAccountSelection } from 'pages/Settings/DepositsSettings/hooks/DepositSettings.hooks'
import { page } from 'translations'

export const useSelectDepositExternalBankListItem = () => {
  const { isLoading, selectDepositAccount, isMfaRequiredOrExpired } =
    useDepositAccountSelection(Account.EXTERNAL)

  const changeToExternalAccount = async (
    account: ThirdPartyBankAccount,
    isNewAccount?: boolean
  ) => {
    try {
      const response = await selectDepositAccount(
        account.id,
        account.accountName as string,
        isNewAccount
      )

      if (isMfaRequiredOrExpired(response)) {
        return true
      }

      rvEntityDetails({
        ...rvEntityDetails(),
        depositAccountUuid: account.id,
        remitToCard: false,
      })

      showSuccessToast(
        isNewAccount
          ? page.settings.deposits.createAndChangingDepositAccountSuccessToast
          : page.settings.deposits.changingDepositAccountSuccessToast
      )

      const { accountBsb, accountName, accountNumber } = account

      if (accountBsb && accountName && accountNumber) {
        rvThirdPartyDepositBank({
          accountName,
          accountBsb,
          accountNumber,
        })
      }
      return true
    } catch (err) {
      showApiErrorToast()
      return false
    }
  }
  return { isLoading, changeToExternalAccount }
}
