import { gql } from '@apollo/client'

import { InvoiceCoreFields } from './invoiceCoreFieldsFragment'

export const GetInvoice = gql`
  ${InvoiceCoreFields}
  query GetInvoice($referenceNumber: String!) {
    getInvoice(referenceNumber: $referenceNumber) {
      ...InvoiceCoreFields
    }
  }
`
