import { useNavigate } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'

export const useRedirectToCardList = () => {
  const navigate = useNavigate()

  return {
    redirectToCardList: () =>
      navigate(ROOT.PORTAL.CARDS.path, { replace: true }),
  }
}
