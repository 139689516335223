import { useCallback, useMemo, useState } from 'react'
import {
  Box,
  BUTTON_SIZE as DESIGN_BUTTON_SIZE,
  COLOR,
  SvgIcon,
} from '@npco/zeller-design-system'
import { Auth0Error } from 'auth0-js'
import { Formik } from 'formik'
import { Flex } from 'reflexbox'

import { ReactComponent as Mail } from 'assets/svg/mail.svg'
import { ROUTE } from 'const/routes'
import { validateEmail } from 'utils/formValidation'
import { BUTTON_SIZE, EmailProps, SetFieldProps } from 'types/common'
import { getWebAuth } from 'services/auth0'
import { AuthLayout } from 'layouts'
import { StyledButtonGhostBack } from 'components/Buttons/ButtonBack/ButtonBack.styled'
import { InputFieldWithoutLabelWrapper } from 'components/InputAdaptiveManagers/InputFieldWithoutLabelWrapper'
import { page, shared } from 'translations'

import {
  StyledButton,
  StyledButtonWrapper,
  StyledConfirmIconWrapper,
  StyledConfirmWrapper,
  StyledCopy,
  StyledFormWrapper,
  StyledHeader,
  StyledLink,
  StyledSubmitButton,
  StyledWrapper,
} from './ForgotPassword.styled'

const { forgottenPassword } = page

const EmailSent: React.FC<EmailProps> = ({ email }: { email: string }) => {
  return (
    <StyledConfirmWrapper>
      <StyledWrapper>
        <StyledConfirmIconWrapper alignItems="center" justifyContent="center">
          <SvgIcon width="22px" height="22px" color={COLOR.BLUE_1000}>
            <Mail />
          </SvgIcon>
        </StyledConfirmIconWrapper>
        <StyledHeader emailSent>
          {forgottenPassword.emailSentHeadline}
        </StyledHeader>
        <StyledCopy isCenter data-testid="email-sent">
          {forgottenPassword.emailSentMessagePartOne}&nbsp;<span>{email}</span>
          &nbsp;{forgottenPassword.emailSentMessagePartTwo}
        </StyledCopy>
      </StyledWrapper>
      <StyledButtonWrapper>
        <StyledLink to={ROUTE.LOGIN}>
          <StyledButton size={BUTTON_SIZE.LARGE}>
            {forgottenPassword.back}
          </StyledButton>
        </StyledLink>
      </StyledButtonWrapper>
    </StyledConfirmWrapper>
  )
}

export const ForgotPassword: React.FC = () => {
  const webAuth = useMemo(() => getWebAuth(), [])

  const [emailSent, setEmailSent] = useState('')

  const handleResetPassword = useCallback(
    (
      { email }: EmailProps,
      { setFieldError, setSubmitting }: SetFieldProps
    ) => {
      webAuth.changePassword(
        {
          email,
          connection: 'Username-Password-Authentication',
        },
        (err: Auth0Error | null) => {
          if (err) {
            const message = err.description ?? ''

            setFieldError('email', message)
            setSubmitting(false)

            return
          }

          setEmailSent(email)
        }
      )
    },
    [webAuth]
  )

  return (
    <AuthLayout>
      {emailSent ? (
        <EmailSent email={emailSent} />
      ) : (
        <>
          <StyledHeader data-testid="forgotten-password">
            {forgottenPassword.headline}
          </StyledHeader>
          <StyledCopy>{forgottenPassword.copy}</StyledCopy>
          <Formik
            initialValues={{
              email: '',
            }}
            onSubmit={handleResetPassword}
            component={({
              handleChange,
              handleBlur,
              isValid,
              isSubmitting,
              values,
            }) => (
              <StyledFormWrapper data-testid="form">
                <Box mb={40}>
                  <InputFieldWithoutLabelWrapper
                    icon={Mail}
                    name="email"
                    type="text"
                    placeholder={forgottenPassword.emailPlaceholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    validate={validateEmail}
                    renderLeftControls={({ hasError }) => (
                      <SvgIcon
                        width="16"
                        height="16"
                        withMarginRight
                        color={hasError ? COLOR.RED_1000 : COLOR.BLUE_1000}
                      >
                        <Mail />
                      </SvgIcon>
                    )}
                  />
                </Box>
                <StyledButtonWrapper>
                  <Flex flexDirection="row">
                    <StyledSubmitButton
                      type="submit"
                      data-testid="reset-button"
                      size={BUTTON_SIZE.LARGE}
                      disabled={!isValid || isSubmitting || !values.email}
                    >
                      {forgottenPassword.reset}
                    </StyledSubmitButton>
                    <StyledLink to={ROUTE.LOGIN} data-testid="back-button">
                      <StyledButtonGhostBack size={DESIGN_BUTTON_SIZE.LARGE}>
                        {shared.cancel}
                      </StyledButtonGhostBack>
                    </StyledLink>
                  </Flex>
                </StyledButtonWrapper>
              </StyledFormWrapper>
            )}
          />
        </>
      )}
    </AuthLayout>
  )
}
