import { useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import {
  Message,
  SomethingWentWrongQuestionMark,
} from '@npco/zeller-design-system'

import { categoryDrawerTranslations } from '../CategoryDrawer.i18n'

export const TRANSACTION_PATH = '/portal/accounts/transactions'

export const UncategorisedMessage = () => {
  const t = useTranslations(categoryDrawerTranslations)

  const navigate = useNavigate()

  return (
    <Message
      title={t('uncategorisedTitle')}
      description={t('uncategorisedDescription')}
      image={<SomethingWentWrongQuestionMark size="large" />}
      secondaryButton={{
        label: t('uncategoriesButtonText'),
        onClick: () => navigate(TRANSACTION_PATH),
      }}
    />
  )
}
