import { useEffect } from 'react'
import { Accept } from 'react-dropzone'
import { useReactiveVar } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { COLOR, ModalBasic } from '@npco/zeller-design-system'
import { rvSelectedSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useFormikContext } from 'formik'

import { useFileDrop } from 'components/ModalUploadImage/hooks/useFileDrop'
import { ModalUploadImage } from 'components/ModalUploadImage/ModalUploadImage'
import { translationsShared } from 'translations'

import { useGetScreensaverLogoUploadDetails } from '../hooks/useGetScreensaverLogoUploadDetails'
import { MAX_COLOR_COUNT } from '../SiteAppearanceForm/SiteAppearanceForm.constants'
import {
  SCREEN_SAVER_FORM_FIELDS,
  SiteAppearanceFormValues,
} from '../SiteAppearanceForm/SiteAppearanceForm.types'
import { translations } from './LogoUploadModal.i18n'
import { getLogoUploadInfo, getMostUsedColour } from './LogoUploadModal.utils'

const ACCEPT_IMAGE_TYPES: Accept = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
}

interface LogoUploadModalProps {
  isOpen: boolean
  onClose: () => void
}

export const LogoUploadModal = ({ isOpen, onClose }: LogoUploadModalProps) => {
  const siteUuid = useReactiveVar(rvSelectedSite)
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const { values, setFieldValue } = useFormikContext<SiteAppearanceFormValues>()
  const {
    hasError,
    screensaverLogoUploadDetails,
    getScreensaverLogoUploadDetails,
    isLoading: isLoadingUploadDetails,
  } = useGetScreensaverLogoUploadDetails()
  const { file, previewUrl, isPreviewLoading, onDrop, reset } = useFileDrop()

  // improve UX, get upload info as soon as the img is dropped to the zone
  useEffect(() => {
    if (file?.name) {
      getScreensaverLogoUploadDetails({
        variables: {
          fileName: file?.name,
          siteUuid,
        },
      })
    }
  }, [file?.name, getScreensaverLogoUploadDetails, siteUuid])

  useEffect(() => {
    if (hasError && previewUrl) {
      reset()
    }
  }, [hasError, previewUrl, reset])

  const handleCancel = () => {
    onClose()
    reset()
  }

  const handleOnSave = async () => {
    if (screensaverLogoUploadDetails) {
      const logoUploadInfo = await getLogoUploadInfo({
        screensaverLogoUploadDetails,
        previewUrl,
      })
      const logoToAdd = {
        logoUuid: screensaverLogoUploadDetails.logoUuid,
        url: previewUrl,
      }
      setFieldValue(SCREEN_SAVER_FORM_FIELDS.logos, [
        ...values.logos,
        logoToAdd,
      ])
      setFieldValue(
        SCREEN_SAVER_FORM_FIELDS.selectedLogo,
        screensaverLogoUploadDetails.logoUuid
      )
      setFieldValue(SCREEN_SAVER_FORM_FIELDS.logosToUpload, [
        ...values.logosToUpload,
        logoUploadInfo,
      ])

      const numberOfCurrentColours = values.customColours.length

      if (numberOfCurrentColours < MAX_COLOR_COUNT) {
        const colours = await getMostUsedColour(previewUrl)
        const remainingSlots = MAX_COLOR_COUNT - numberOfCurrentColours
        const coloursToAdd = colours
          .filter(
            (c) =>
              c !== COLOR.BLACK &&
              c !== COLOR.WHITE &&
              c !== '#000000' &&
              !values.customColours.includes(c)
          )
          .slice(0, remainingSlots)

        if (coloursToAdd.length > 0) {
          setFieldValue(SCREEN_SAVER_FORM_FIELDS.customColours, [
            ...values.customColours,
            ...coloursToAdd.slice(0, 2),
          ])
          setFieldValue(
            SCREEN_SAVER_FORM_FIELDS.selectedColour,
            coloursToAdd[0]
          )
        }
      }
    }
    onClose()
    reset()
  }

  return (
    <ModalBasic
      title={t('title')}
      isOpen={isOpen}
      onCancel={handleCancel}
      hasCloseButton
    >
      <ModalBasic.Body>
        <ModalUploadImage
          description={t('description')}
          imageUrl={previewUrl}
          isPreviewLoading={isPreviewLoading || isLoadingUploadDetails}
          isSaveDisabled={
            isPreviewLoading || isLoadingUploadDetails || hasError
          }
          onDrop={onDrop}
          isProcessing={isLoadingUploadDetails}
          onSecondaryButtonClick={handleCancel}
          primaryButtonLabel={tShared('confirm')}
          secondaryButtonLabel={tShared('cancel')}
          uploadFile={handleOnSave}
          acceptImageTypes={ACCEPT_IMAGE_TYPES}
        />
      </ModalBasic.Body>
    </ModalBasic>
  )
}
