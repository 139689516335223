import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type UpdateContactIsSelfMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
  isSelf: Types.Scalars['Boolean']['input']
}>

export type UpdateContactIsSelfMutationResponse = {
  __typename?: 'Mutation'
  updateContact: boolean
}

export const UpdateContactIsSelf = gql`
  mutation UpdateContactIsSelf($id: ID!, $isSelf: Boolean!) {
    updateContact(id: $id, contact: { isSelf: $isSelf })
  }
` as unknown as TypedDocumentNode<
  UpdateContactIsSelfMutationResponse,
  UpdateContactIsSelfMutationVariables
>
export type UpdateContactIsSelfMutationFn = Apollo.MutationFunction<
  UpdateContactIsSelfMutationResponse,
  UpdateContactIsSelfMutationVariables
>

/**
 * __useUpdateContactIsSelfMutation__
 *
 * To run a mutation, you first call `useUpdateContactIsSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactIsSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactIsSelfMutation, { data, loading, error }] = useUpdateContactIsSelfMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isSelf: // value for 'isSelf'
 *   },
 * });
 */
export function useUpdateContactIsSelfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactIsSelfMutationResponse,
    UpdateContactIsSelfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateContactIsSelfMutationResponse,
    UpdateContactIsSelfMutationVariables
  >(UpdateContactIsSelf, options)
}
export type UpdateContactIsSelfMutationHookResult = ReturnType<
  typeof useUpdateContactIsSelfMutation
>
export type UpdateContactIsSelfMutationResult =
  Apollo.MutationResult<UpdateContactIsSelfMutationResponse>
export type UpdateContactIsSelfMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactIsSelfMutationResponse,
  UpdateContactIsSelfMutationVariables
>
