import { useMemo } from 'react'
import { Navigate } from 'react-router-dom-v5-compat'
import { DebitCardAccountStatus } from '@npco/mp-gql-types'

import { ROOT } from 'const/routes'
import { useGetSavingsAccounts } from 'pages/Dashboard/DashboardLayout/OverviewBanner/hooks/useGetSavingsAccounts/useGetSavingsAccounts'
import { useGoToInternalTransfer } from 'pages/Transfer/hooks/useGoToInternalTransfer/useGoToInternalTransfer'

export const TransferToSavings = () => {
  const { savingsAccounts, isLoadingSavingsAccounts } = useGetSavingsAccounts()

  const savingsAccount = useMemo(
    () =>
      savingsAccounts.find(
        (account) => account.status === DebitCardAccountStatus.ACTIVE
      ),
    [savingsAccounts]
  )

  const { createState: createInternalTransferState } = useGoToInternalTransfer()

  if (isLoadingSavingsAccounts) {
    return null
  }

  return (
    <Navigate
      to={ROOT.PORTAL.ACCOUNTS.TRANSFER.path}
      state={createInternalTransferState({
        from: '',
        transferType: 'internal',
        to: savingsAccount?.id ?? '',
      })}
      replace
    />
  )
}
