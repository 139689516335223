import { ApolloCache, ApolloClient } from '@apollo/client'
import {
  GetAccountDebitCardsVerbose,
  GetAccountDebitCardsVerboseQueryResponse,
  GetAccountDebitCardsVerboseQueryVariables,
} from 'features/Accounts/AccountCards'

type Entity = GetAccountDebitCardsVerboseQueryResponse['getEntity']
type AccountDebitCard = NonNullable<
  NonNullable<
    GetAccountDebitCardsVerboseQueryResponse['getDebitCardAccountV2']
  >['cards']
>[0]
type AccountWithCards = NonNullable<
  GetAccountDebitCardsVerboseQueryResponse['getDebitCardAccountV2']
>

interface CacheAccountCardListProps {
  cache: ApolloCache<any>
  mapToNewList: (oldCards: AccountDebitCard[]) => AccountDebitCard[]
  client?: ApolloClient<any>
  debitCardAccountUuid: string
}

export const cacheAccountCardList = ({
  cache,
  mapToNewList,
  client,
  debitCardAccountUuid,
}: CacheAccountCardListProps) => {
  const variables = {
    debitCardAccountUuid,
  }
  const writeAccountCardsQuery = (
    accountWithCards: AccountWithCards,
    entity: Entity
  ) => {
    cache.writeQuery<GetAccountDebitCardsVerboseQueryResponse>({
      query: GetAccountDebitCardsVerbose,
      variables,
      data: {
        getDebitCardAccountV2: {
          ...accountWithCards,
          cards: mapToNewList(accountWithCards.cards ?? []),
        },
        getEntity: entity,
      },
    })
  }

  const cachedQuery = cache.readQuery<GetAccountDebitCardsVerboseQueryResponse>(
    {
      query: GetAccountDebitCardsVerbose,
      variables,
    }
  )

  if (cachedQuery?.getDebitCardAccountV2) {
    writeAccountCardsQuery(
      cachedQuery.getDebitCardAccountV2,
      cachedQuery.getEntity
    )
    return
  }

  if (client) {
    client
      .query<
        GetAccountDebitCardsVerboseQueryResponse,
        GetAccountDebitCardsVerboseQueryVariables
      >({
        query: GetAccountDebitCardsVerbose,
        variables,
        errorPolicy: 'all',
      })
      .then(({ data }) => {
        if (!data?.getDebitCardAccountV2) {
          return
        }
        writeAccountCardsQuery(data.getDebitCardAccountV2, data.getEntity)
      })
  }
}
