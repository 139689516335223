import {
  GetCategoriesFilterInput,
  GetCategoriesSortInput,
  GetItemFilterInput,
  GetItemsSortInput,
} from '@npco/mp-gql-types'

import { ServerError } from 'types/errors'

export interface LocalStateItemManagement {
  categories: {
    filterInput: GetCategoriesFilterInput | null
    sortInput: GetCategoriesSortInput | null
  }
  items: {
    filterInput: GetItemFilterInput | null
    sortInput: GetItemsSortInput | null
  }
  hasSeenItemOnboarding: boolean
}

export type CacheLocalStateItemManagement = {
  local: {
    itemManagement: LocalStateItemManagement
  }
}

export enum TableTabStateEnum {
  ITEMS = 'ITEMS',
  CATEGORIES = 'CATEGORIES',
}

export type ItemServerError = Omit<ServerError, 'errorInfo'> & {
  errorInfo?: { itemName: string }
}
