import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  errorNotification: 'Item could not be updated. Please try again.',
  heading: 'Edit Item',
  nameLabel: 'Name',
  nameRequiredError: 'Name is required.',
  skuDuplicateError:
    'Please enter a unique SKU. This SKU is already in use for {itemName}',
  successNotification: 'Item updated.',
})
