import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type SavingsAccountsListQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type SavingsAccountsListQueryResponse = {
  __typename?: 'Query'
  getSavingsAccounts: Array<{
    __typename?: 'DebitCardAccountV2'
    id: string
  } | null>
}

export const SavingsAccountsListQuery = gql`
  query SavingsAccountsListQuery {
    getSavingsAccounts {
      id
    }
  }
` as unknown as TypedDocumentNode<
  SavingsAccountsListQueryResponse,
  SavingsAccountsListQueryVariables
>

/**
 * __useSavingsAccountsListQuery__
 *
 * To run a query within a React component, call `useSavingsAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavingsAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavingsAccountsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavingsAccountsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SavingsAccountsListQueryResponse,
    SavingsAccountsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SavingsAccountsListQueryResponse,
    SavingsAccountsListQueryVariables
  >(SavingsAccountsListQuery, options)
}
export function useSavingsAccountsListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SavingsAccountsListQueryResponse,
    SavingsAccountsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SavingsAccountsListQueryResponse,
    SavingsAccountsListQueryVariables
  >(SavingsAccountsListQuery, options)
}
export type SavingsAccountsListQueryHookResult = ReturnType<
  typeof useSavingsAccountsListQuery
>
export type SavingsAccountsListQueryLazyQueryHookResult = ReturnType<
  typeof useSavingsAccountsListQueryLazyQuery
>
export type SavingsAccountsListQueryQueryResult = Apollo.QueryResult<
  SavingsAccountsListQueryResponse,
  SavingsAccountsListQueryVariables
>
