import { useQuery } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Box, InfoBox } from '@npco/zeller-design-system'

import { TransactionFilterStatus } from 'types/transactions'
import { SpinnerWrapped } from 'components/Spinner'

import { translations } from '../Reports.i18n'
import { ReportsChart } from '../ReportsCharts/ReportsChart'
import { ReportsHighlights } from '../ReportsHighlights/ReportsHighlights'
import { ReportsTable } from '../ReportsTable/ReportsTable'
import { GetReportsLocalState } from './getReportsLocalState'
import { CacheLocalStateReports } from './ReportsNew.types'
import { useFetchReportsContent } from './useFetchReportsContent'

type ReportsContentNewProps = ReturnType<typeof useFetchReportsContent>

const isOnlyDeclinedSelected = (
  selectedStatus: `${TransactionFilterStatus}`[]
) => selectedStatus.length === 1 && selectedStatus.includes('DECLINED')

export const ReportsContentNew = ({
  dayOfWeekChartData,
  dayOfWeekDeclinedChartData,
  hasNoInitialResults,
  hasNoResults,
  isLoading,
  isSelectionMoreThenTwoMonths,
  timeOfDayChartData,
  timeOfDayDeclinedChartData,
  totalDeclinedChartData,
  totalSaleChartData,
  transactionsSum,
}: ReportsContentNewProps) => {
  const t = useTranslations(translations)
  const { data: cacheData } =
    useQuery<CacheLocalStateReports>(GetReportsLocalState)

  if (isLoading) {
    return <SpinnerWrapped variant="centre" />
  }

  const selectedStatus = cacheData?.local.reports.filters.statuses
  const shouldShowDeclinedData =
    selectedStatus && isOnlyDeclinedSelected(selectedStatus)

  const highlightsTotal = shouldShowDeclinedData
    ? transactionsSum.declinedTotal
    : transactionsSum.total
  const displayDayOfWeekChartData = shouldShowDeclinedData
    ? dayOfWeekDeclinedChartData
    : dayOfWeekChartData
  const displayTimeOfDayChartData = shouldShowDeclinedData
    ? timeOfDayDeclinedChartData
    : timeOfDayChartData
  const displayTotalChartData = shouldShowDeclinedData
    ? totalDeclinedChartData
    : totalSaleChartData

  return (
    <>
      {hasNoResults && (
        <Box paddingBottom="24px">
          <InfoBox>
            <InfoBox.Message>{t('noData')}</InfoBox.Message>
          </InfoBox>
        </Box>
      )}
      <ReportsHighlights
        refundsCount={transactionsSum.refundsCount}
        salesCount={transactionsSum.salesCount}
        shouldShowCounts={!shouldShowDeclinedData}
        total={highlightsTotal}
      />
      <ReportsChart
        dayOfWeekChartData={displayDayOfWeekChartData}
        hasNoInitialResults={hasNoInitialResults}
        isSelectionMoreThenTwoMonths={isSelectionMoreThenTwoMonths}
        timeOfDayChartData={displayTimeOfDayChartData}
        totalSaleChartData={displayTotalChartData}
      />
      {!shouldShowDeclinedData && (
        <ReportsTable
          collected={transactionsSum.collected}
          fees={transactionsSum.fees}
          refunds={transactionsSum.refunds}
          // totalAmount is now used for the subtotal on the reports page
          subtotal={transactionsSum.total}
          surcharge={transactionsSum.surcharge}
          tax={transactionsSum.tax}
          tip={transactionsSum.tip}
        />
      )}
    </>
  )
}
