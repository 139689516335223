import { gql } from '@apollo/client'

export const GetDocumentUploadUrls = gql`
  query GetDocumentUploadUrls(
    $documents: [DocumentUploadFiles]!
    $subject: String
  ) {
    getDocumentUploadUrls(documents: $documents, subject: $subject) {
      fileName
      uploadUrl
    }
  }
`
