import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { useGetCustomerItemPermissionsQuery } from './getCustomerItemPermissions.generated'

const deps = {
  useGetCustomerItemPermissionsQuery,
}
export { deps as useGetCustomerItemPermissionsDeps }

export const useCustomerItemPermissions = () => {
  const { userData } = useLoggedInUser()

  const userIsAdmin = userData?.role === CustomerRole.ADMIN

  const { data, loading } = deps.useGetCustomerItemPermissionsQuery({
    skip: userIsAdmin || !userData?.id,
    variables: {
      customerUuid: userData?.id ?? '',
    },
  })

  if (userIsAdmin) {
    return {
      loading: false,
      allowItemManagement: true,
      allowDiscountManagement: true,
    }
  }

  const allowItemManagement =
    !!data?.getCustomer.permissions?.allowItemManagement
  const allowDiscountManagement =
    !!data?.getCustomer.permissions?.allowDiscountManagement

  return {
    loading,
    allowItemManagement,
    allowDiscountManagement,
  }
}
