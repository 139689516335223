import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { ACCOUNT } from '../../routes/routes'

interface AccountNavigationProps {
  accountId: string
}

export const useAccountNavigation = () => {
  const navigate = useNavigate()
  const { userData } = useLoggedInUser()

  const isAdmin = userData?.role === CustomerRole.ADMIN

  const navigateToAccountTransactions = useCallback(
    ({ accountId }: AccountNavigationProps) => {
      navigate(ACCOUNT(accountId).TRANSACTIONS.path)
    },
    [navigate]
  )

  const navigateToAccountDetails = useCallback(
    ({ accountId }: AccountNavigationProps) => {
      navigate(ACCOUNT(accountId).DETAILS.path)
    },
    [navigate]
  )

  const navigateToAccountEdit = useCallback(
    ({ accountId }: AccountNavigationProps) => {
      navigate(ACCOUNT(accountId).EDIT.path)
    },
    [navigate]
  )

  const navigateToAccountClose = useCallback(
    ({ accountId }: AccountNavigationProps) => {
      navigate(ACCOUNT(accountId).CLOSE.path)
    },
    [navigate]
  )

  const navigateToAccountCards = useCallback(
    ({ accountId }: AccountNavigationProps) => {
      navigate(ACCOUNT(accountId).CARDS.path)
    },
    [navigate]
  )

  return {
    navigateToAccountTransactions,
    navigateToAccountDetails,
    navigateToAccountCards,
    navigateToAccountEdit,
    navigateToAccountClose,
    canNavigate: isAdmin,
  }
}
