import { useTranslations } from '@npco/utils-translations'

import { SortList, SortListProps } from '../SortList/SortList'
import { translations } from './SortAlphabeticallyList.i18n'

type SortAlphabeticallyListProps = Omit<
  SortListProps,
  'ascendingLabel' | 'descendingLabel'
>

export const SortAlphabeticallyList = ({
  dataTestId = 'sort-alphabetically-list',
  sort,
  onClick,
}: SortAlphabeticallyListProps) => {
  const t = useTranslations(translations)

  return (
    <SortList
      ascendingLabel={t('sortAlphabeticallyAscending')}
      dataTestId={dataTestId}
      descendingLabel={t('sortAlphabeticallyDescending')}
      onClick={onClick}
      sort={sort}
    />
  )
}
