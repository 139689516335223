import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { AccountCardsTableDebitCardV2FragmentDoc } from '../../../../../../components/CardsView/components/CardsTable/AccountCardsTable/AccountCardsTable.generated'

const defaultOptions = {} as const
export type GetAccountDebitCardsVerboseQueryVariables = Types.Exact<{
  debitCardAccountUuid: Types.Scalars['ID']['input']
}>

export type GetAccountDebitCardsVerboseQueryResponse = {
  __typename?: 'Query'
  getDebitCardAccountV2: {
    __typename: 'DebitCardAccountV2'
    id: string
    status: Types.DebitCardAccountStatus
    type: Types.DebitCardAccountType
    cards: Array<{
      __typename: 'DebitCardV2'
      id: string
      name: string
      owner: string | null
      status: Types.DebitCardStatus
      colour: Types.DebitCardColour
      maskedPan: string
      customer: { __typename?: 'Customer'; id: string } | null
    }> | null
  } | null
  getEntity: { __typename: 'Entity'; id: string; canCreateCard: boolean | null }
}

export const GetAccountDebitCardsVerbose = gql`
  query GetAccountDebitCardsVerbose($debitCardAccountUuid: ID!) {
    getDebitCardAccountV2(debitCardAccountUuid: $debitCardAccountUuid) {
      id
      status
      type
      __typename
      cards {
        ...AccountCardsTableDebitCardV2Fragment
      }
    }
    getEntity {
      __typename
      id
      canCreateCard
    }
  }
  ${AccountCardsTableDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  GetAccountDebitCardsVerboseQueryResponse,
  GetAccountDebitCardsVerboseQueryVariables
>

/**
 * __useGetAccountDebitCardsVerboseQuery__
 *
 * To run a query within a React component, call `useGetAccountDebitCardsVerboseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountDebitCardsVerboseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountDebitCardsVerboseQuery({
 *   variables: {
 *      debitCardAccountUuid: // value for 'debitCardAccountUuid'
 *   },
 * });
 */
export function useGetAccountDebitCardsVerboseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountDebitCardsVerboseQueryResponse,
    GetAccountDebitCardsVerboseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAccountDebitCardsVerboseQueryResponse,
    GetAccountDebitCardsVerboseQueryVariables
  >(GetAccountDebitCardsVerbose, options)
}
export function useGetAccountDebitCardsVerboseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountDebitCardsVerboseQueryResponse,
    GetAccountDebitCardsVerboseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAccountDebitCardsVerboseQueryResponse,
    GetAccountDebitCardsVerboseQueryVariables
  >(GetAccountDebitCardsVerbose, options)
}
export type GetAccountDebitCardsVerboseQueryHookResult = ReturnType<
  typeof useGetAccountDebitCardsVerboseQuery
>
export type GetAccountDebitCardsVerboseLazyQueryHookResult = ReturnType<
  typeof useGetAccountDebitCardsVerboseLazyQuery
>
export type GetAccountDebitCardsVerboseQueryResult = Apollo.QueryResult<
  GetAccountDebitCardsVerboseQueryResponse,
  GetAccountDebitCardsVerboseQueryVariables
>
