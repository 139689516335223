import { gql, type TypedDocumentNode } from '@apollo/client'

export type CategoryDrawerDescriptionFragment = {
  __typename?: 'ItemCategory'
  description: string | null
  id: string
}

export const CategoryDrawerDescriptionFragmentDoc = gql`
  fragment CategoryDrawerDescriptionFragment on ItemCategory {
    description
    id
  }
` as unknown as TypedDocumentNode<CategoryDrawerDescriptionFragment, undefined>
