import { ListCardButton } from '@npco/zeller-design-system'

import { BpayAvatar } from 'pages/Transfer/TransferFields/fields/BpayDetails/BpayAvatar'

import { BpayPaymentInstrumentsDetails } from './Bpay.types'
import { ButtonWrapper } from './BpayListItem.styled'

interface BPayListItemProps {
  accountId: string
  bpayDetails: BpayPaymentInstrumentsDetails
  onItemClick: (id: string) => void
}

export const BPayListItem = ({
  bpayDetails,
  onItemClick,
  accountId,
}: BPayListItemProps) => {
  const { billerCode, nickname, crn = '' } = bpayDetails
  const description = crn ? `BC ${billerCode} · CRN ${crn}` : `BC ${billerCode}`
  return (
    <ButtonWrapper>
      <ListCardButton
        title={nickname}
        onClick={() => onItemClick(accountId)}
        description={description}
        image={BpayAvatar}
      />
    </ButtonWrapper>
  )
}
