import { useMutation } from '@apollo/client'
import { UnlinkContacts } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/contacts'

import {
  UnlinkContacts as UnlinkContactsResponse,
  UnlinkContactsVariables,
} from 'types/gql-types/UnlinkContacts'

export const useUnlinkContacts = () => {
  const [unlinkContacts, { loading: isUnlinking }] = useMutation<
    UnlinkContactsResponse,
    UnlinkContactsVariables
  >(UnlinkContacts)

  return {
    unlinkContacts: (contact1Uuid: string, contact2Uuid: string) =>
      unlinkContacts({ variables: { contact1Uuid, contact2Uuid } }),
    isUnlinking,
  }
}
