import { ApolloClient } from '@apollo/client'
import { GetDebitCardTransactionAttachmentUploadUrls } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/debitCardTransactions'

import {
  GetDebitCardTransactionAttachmentUploadUrls as GetDebitCardTransactionAttachmentUploadUrlsResponse,
  GetDebitCardTransactionAttachmentUploadUrlsVariables,
} from 'types/gql-types/GetDebitCardTransactionAttachmentUploadUrls'
import { FileWithId } from 'components/File'
import { fetchUploadFileToS3Bucket } from 'components/File/File.utils'

interface FileWithUpload {
  file: File
  id: string
  uploadUrl: string
  fileName: string
}

export const fetchUploadUrls = async (
  // We're using <object> here as that's what useApolloClient returns to us
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>,
  filesToUpload: FileWithId[],
  transactionUuid: string
) => {
  const fileNames = filesToUpload.map(({ file }) => file.name)

  const { data } = await client.query<
    GetDebitCardTransactionAttachmentUploadUrlsResponse,
    GetDebitCardTransactionAttachmentUploadUrlsVariables
  >({
    query: GetDebitCardTransactionAttachmentUploadUrls,
    variables: {
      fileNames,
      transactionUuid,
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  })

  return filesToUpload
    .map(({ file }, i) => ({
      file,
      id: data.getDebitCardTxnAttachmentUploadUrls[i]?.documentUuid,
      fileName: data.getDebitCardTxnAttachmentUploadUrls[i]?.fileName,
      uploadUrl: data.getDebitCardTxnAttachmentUploadUrls[i]?.uploadUrl,
    }))
    .filter(
      (fileWithUpload): fileWithUpload is FileWithUpload =>
        fileWithUpload.fileName !== undefined &&
        fileWithUpload.uploadUrl !== undefined &&
        fileWithUpload.id !== undefined
    )
}

type FetchUploadFileToS3BucketParameters = Parameters<
  typeof fetchUploadFileToS3Bucket
>[0]

export const promisifiedFetchUploadFileToS3Bucket = (
  props: Omit<Omit<FetchUploadFileToS3BucketParameters, 'onSuccess'>, 'onError'>
) =>
  new Promise<void>((resolve, reject) => {
    fetchUploadFileToS3Bucket({
      ...props,
      onSuccess: resolve,
      onError: reject,
    })
  })
