import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetEcommerceTypeQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input']
}>

export type GetEcommerceTypeQueryResponse = {
  __typename?: 'Query'
  getVirtualTerminalRecord: {
    __typename?: 'Ecommerce'
    id: string
    contactType: Types.ContactMethod | null
  }
}

export const GetEcommerceType = gql`
  query GetEcommerceType($id: String!) {
    getVirtualTerminalRecord(id: $id) {
      id
      contactType
    }
  }
` as unknown as TypedDocumentNode<
  GetEcommerceTypeQueryResponse,
  GetEcommerceTypeQueryVariables
>

/**
 * __useGetEcommerceTypeQuery__
 *
 * To run a query within a React component, call `useGetEcommerceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEcommerceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEcommerceTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEcommerceTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEcommerceTypeQueryResponse,
    GetEcommerceTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetEcommerceTypeQueryResponse,
    GetEcommerceTypeQueryVariables
  >(GetEcommerceType, options)
}
export function useGetEcommerceTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEcommerceTypeQueryResponse,
    GetEcommerceTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEcommerceTypeQueryResponse,
    GetEcommerceTypeQueryVariables
  >(GetEcommerceType, options)
}
export type GetEcommerceTypeQueryHookResult = ReturnType<
  typeof useGetEcommerceTypeQuery
>
export type GetEcommerceTypeLazyQueryHookResult = ReturnType<
  typeof useGetEcommerceTypeLazyQuery
>
export type GetEcommerceTypeQueryResult = Apollo.QueryResult<
  GetEcommerceTypeQueryResponse,
  GetEcommerceTypeQueryVariables
>
