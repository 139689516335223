import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type AddMarketingModalSeenMutationVariables = Types.Exact<{
  marketingModalName: Types.Scalars['String']['input']
}>

export type AddMarketingModalSeenMutationResponse = {
  __typename?: 'Mutation'
  addMarketingModalSeen: boolean
}

export const AddMarketingModalSeen = gql`
  mutation AddMarketingModalSeen($marketingModalName: String!) {
    addMarketingModalSeen(marketingModalName: $marketingModalName)
  }
` as unknown as TypedDocumentNode<
  AddMarketingModalSeenMutationResponse,
  AddMarketingModalSeenMutationVariables
>
export type AddMarketingModalSeenMutationFn = Apollo.MutationFunction<
  AddMarketingModalSeenMutationResponse,
  AddMarketingModalSeenMutationVariables
>

/**
 * __useAddMarketingModalSeenMutation__
 *
 * To run a mutation, you first call `useAddMarketingModalSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMarketingModalSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMarketingModalSeenMutation, { data, loading, error }] = useAddMarketingModalSeenMutation({
 *   variables: {
 *      marketingModalName: // value for 'marketingModalName'
 *   },
 * });
 */
export function useAddMarketingModalSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMarketingModalSeenMutationResponse,
    AddMarketingModalSeenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddMarketingModalSeenMutationResponse,
    AddMarketingModalSeenMutationVariables
  >(AddMarketingModalSeen, options)
}
export type AddMarketingModalSeenMutationHookResult = ReturnType<
  typeof useAddMarketingModalSeenMutation
>
export type AddMarketingModalSeenMutationResult =
  Apollo.MutationResult<AddMarketingModalSeenMutationResponse>
export type AddMarketingModalSeenMutationOptions = Apollo.BaseMutationOptions<
  AddMarketingModalSeenMutationResponse,
  AddMarketingModalSeenMutationVariables
>
