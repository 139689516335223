import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom-v5-compat'
import { COLOR, TabItemProps } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { page } from 'translations'

export const useCardTabs = () => {
  const { pathname } = useLocation()

  const tabs: TabItemProps<typeof Link>[] = useMemo(
    () => [
      {
        children: page.cards.tabs.debit,
        value: ROOT.PORTAL.CARDS.DEBIT.path,
        to: ROOT.PORTAL.CARDS.DEBIT.path,
        component: Link,
      },
      {
        children: page.cards.tabs.corporate,
        value: ROOT.PORTAL.CARDS.CORPORATE.path,
        to: ROOT.PORTAL.CARDS.CORPORATE.path,
        indicator: {
          color: COLOR.BRIGHT_ORANGE,
          isActive: true,
        },
        component: Link,
      },
    ],
    []
  )

  return { tabs, currentTab: pathname }
}
