import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { rvFinishedLoading } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { isEmpty } from 'lodash-es'

import { useTransactionFilters } from 'hooks/useTransactionFilters'
import { useTransactionsList } from 'hooks/useTransactionsList/useTransactionsList'
import { ROOT } from 'const/routes'
import { mapFiltersInputToTransactionsApiFilter } from 'utils/mapFiltersInputToApiFilter'
import { useTransactionsFilters } from 'components/Filters/PillFilters/TransactionsFilters/useTransactionsFilters'

import { useContactContext } from '../../ContactContext/ContactContext'
import { rvSelectedContact } from '../../rv-deprecated/contacts'
import { PaymentsList } from '../ContactSections/PaymentsList/PaymentsList'

export const ContactPayments = () => {
  const navigate = useNavigate()
  const contact = useReactiveVar(rvSelectedContact)
  const { isPaymentsFilterButtonVisible, setIsPaymentsFilterButtonVisible } =
    useContactContext()
  const flags = useFeatureFlags()

  const isMappingDone = useReactiveVar(rvFinishedLoading)

  const { areFiltersInDefaultState: isDefaultOldFilters, filters: oldFilters } =
    useTransactionFilters()

  const { isDefaultFilters: isDefaultNewFilters, filters: newFilters } =
    useTransactionsFilters({
      key: 'contactTransactions',
    })

  const { isDefaultFilters, filters } = useMemo(() => {
    if (flags.TransactionsFilterUplift) {
      return {
        isDefaultFilters: isDefaultNewFilters,
        filters: newFilters,
      }
    }
    return {
      isDefaultFilters: isDefaultOldFilters,
      filters: mapFiltersInputToTransactionsApiFilter(oldFilters, false, true),
    }
  }, [
    flags.TransactionsFilterUplift,
    isDefaultNewFilters,
    isDefaultOldFilters,
    newFilters,
    oldFilters,
  ])

  const [unlinkedCardHolderUuid, setUnlinkedCardHolderUuid] = useState('')

  const filterObject = {
    contactUuid: { in: contact?.id ? [contact.id] : [] },
    not: {
      cardholderUuid: {
        eq: unlinkedCardHolderUuid || '',
      },
    },
    ...filters,
  }

  const {
    hasMore,
    isLoading,
    isLoadingMore,
    onLoadMore,
    refetch,
    transactionList,
  } = useTransactionsList({
    filterObject,
    areFiltersSelected: !isDefaultFilters,
  })

  useEffect(() => {
    if (!isEmpty(transactionList) && !isPaymentsFilterButtonVisible) {
      setIsPaymentsFilterButtonVisible(true)
    }
  }, [
    isPaymentsFilterButtonVisible,
    setIsPaymentsFilterButtonVisible,
    transactionList,
  ])

  useEffect(() => {
    if (!contact) {
      navigate(ROOT.PORTAL.CONTACTS.path)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PaymentsList
      areFiltersInDefaultState={isDefaultFilters}
      hasMore={hasMore}
      isLoading={isLoading}
      isLoadingMore={isLoadingMore}
      isMappingDone={isMappingDone}
      onLoadMore={onLoadMore}
      transactionList={transactionList}
      onLink={refetch}
      onUnlink={setUnlinkedCardHolderUuid}
    />
  )
}
