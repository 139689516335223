import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { contactFieldsDoc } from './ContactFragment.generated'

const defaultOptions = {} as const
export type GetContactsByBillerCodeQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input']
}>

export type GetContactsByBillerCodeQueryResponse = {
  __typename?: 'Query'
  getContactsByBpayBillerCode: Array<{
    __typename?: 'Contact'
    id: string
    businessName: string | null
    isSelf: boolean | null
    phone: string | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      image: string | null
      letter: string | null
      images: Array<{
        __typename?: 'Image'
        size: Types.ImageSize
        url: string
      }> | null
    } | null
    paymentInstruments: Array<{
      __typename?: 'PaymentInstrument'
      id: string
      details:
        | { __typename: 'BankAccountDetails' }
        | { __typename: 'BpayDetails' }
        | { __typename: 'NppDetails' }
        | {
            __typename: 'PaymentInstrumentBpayDynamicCrnDetails'
            nickname: string | null
            billerName: string
            billerCode: string
          }
        | {
            __typename: 'PaymentInstrumentBpayStaticCrnDetails'
            nickname: string | null
            billerName: string
            billerCode: string
            crn: string
          }
    }> | null
    email: { __typename?: 'ContactEmail'; email: string } | null
    phoneV2: { __typename?: 'ContactPhone'; phone: string } | null
  }>
}

export const GetContactsByBillerCode = gql`
  query GetContactsByBillerCode($code: String!) {
    getContactsByBpayBillerCode(billerCode: $code) {
      ...contactFields
    }
  }
  ${contactFieldsDoc}
` as unknown as TypedDocumentNode<
  GetContactsByBillerCodeQueryResponse,
  GetContactsByBillerCodeQueryVariables
>

/**
 * __useGetContactsByBillerCodeQuery__
 *
 * To run a query within a React component, call `useGetContactsByBillerCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsByBillerCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsByBillerCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetContactsByBillerCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactsByBillerCodeQueryResponse,
    GetContactsByBillerCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContactsByBillerCodeQueryResponse,
    GetContactsByBillerCodeQueryVariables
  >(GetContactsByBillerCode, options)
}
export function useGetContactsByBillerCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactsByBillerCodeQueryResponse,
    GetContactsByBillerCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContactsByBillerCodeQueryResponse,
    GetContactsByBillerCodeQueryVariables
  >(GetContactsByBillerCode, options)
}
export type GetContactsByBillerCodeQueryHookResult = ReturnType<
  typeof useGetContactsByBillerCodeQuery
>
export type GetContactsByBillerCodeLazyQueryHookResult = ReturnType<
  typeof useGetContactsByBillerCodeLazyQuery
>
export type GetContactsByBillerCodeQueryResult = Apollo.QueryResult<
  GetContactsByBillerCodeQueryResponse,
  GetContactsByBillerCodeQueryVariables
>
