import { useMutation } from '@apollo/client'
import { SaveAndSendInvoiceInput } from '@npco/mp-gql-types'

import {
  SaveAndSendInvoice as SaveAndSendInvoiceResponse,
  SaveAndSendInvoiceVariables,
} from 'types/gql-types/SaveAndSendInvoice'

import { SaveAndSendInvoice } from '../graphql/saveAndSendInvoice'

export const useSaveAndSendInvoice = () => {
  const [saveAndSendInvoice, { data, loading: isSavingAndSendingInvoice }] =
    useMutation<SaveAndSendInvoiceResponse, SaveAndSendInvoiceVariables>(
      SaveAndSendInvoice
    )

  return {
    saveAndSendInvoice: (invoice: SaveAndSendInvoiceInput) =>
      saveAndSendInvoice({
        variables: {
          invoice,
        },
      }),
    data: data?.saveAndSendInvoice,
    isSavingAndSendingInvoice,
  }
}
