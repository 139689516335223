import { useMutation } from '@apollo/client'
import {
  DeleteTransactionNotesMutation,
  UpdateTransactionNotesMutation,
} from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/transactions'

import {
  DeleteTransactionNotes as DeleteTransactionNotesResponse,
  DeleteTransactionNotesVariables,
} from 'types/gql-types/DeleteTransactionNotes'
import {
  UpdateTransactionNotes as UpdateTransactionNotesResponse,
  UpdateTransactionNotesVariables,
} from 'types/gql-types/UpdateTransactionNotes'

export const useUpdateTransactionNotes = () => {
  const [updateTransactionNotes, { loading: isUpdating }] = useMutation<
    UpdateTransactionNotesResponse,
    UpdateTransactionNotesVariables
  >(UpdateTransactionNotesMutation)

  return {
    updateTransactionNotes: (transactionUuid: string, notes: string) =>
      updateTransactionNotes({ variables: { transactionUuid, notes } }),
    isUpdating,
  }
}

export const useDeleteTransactionNotes = () => {
  const [deleteTransactionNotes, { loading: isDeleting }] = useMutation<
    DeleteTransactionNotesResponse,
    DeleteTransactionNotesVariables
  >(DeleteTransactionNotesMutation)

  return {
    deleteTransactionNotes: (transactionUuid: string) =>
      deleteTransactionNotes({ variables: { transactionUuid } }),
    isDeleting,
  }
}
