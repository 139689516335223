import { FiltersHeaderRow } from 'components/FiltersHeaderRow'

import { useContactsContext } from '../../ContactsContext/ContactsContext'
import { ContactsFilters } from '../ContactsFilters/ContactsFilters'

export const ContactsSecondaryHeaderRowDeps = {
  ContactsFilters,
  useContactsContext,
}

export const ContactsSecondaryHeaderRow = () => {
  const { ContactsFilters, useContactsContext } = ContactsSecondaryHeaderRowDeps

  const { isFiltersVisible } = useContactsContext()

  return (
    <FiltersHeaderRow isOpen={isFiltersVisible}>
      <ContactsFilters />
    </FiltersHeaderRow>
  )
}
