import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type CreateDebitCardAccountMutationVariables = Types.Exact<{
  input: Types.CreateDebitCardAccountInput
}>

export type CreateDebitCardAccountMutationResponse = {
  __typename?: 'Mutation'
  createDebitCardAccount:
    | { __typename: 'DebitCardAccount'; id: string; entityUuid: string }
    | {
        __typename: 'DebitCardAccountV2'
        name: string
        id: string
        type: Types.DebitCardAccountType
        status: Types.DebitCardAccountStatus
        icon: {
          __typename?: 'Icon'
          colour: string | null
          letter: string | null
          image: string | null
          animation: string | null
          images: Array<{
            __typename?: 'Image'
            size: Types.ImageSize
            url: string
          }> | null
        } | null
        balance: {
          __typename?: 'Money'
          currency: string
          value: string
        } | null
        cardsCount: {
          __typename?: 'DebitCardAccountV2CardsCount'
          total: number
          debit: number
          expense: number | null
        }
        savingsAccountDetails: {
          __typename?: 'SavingsAccountDetails'
          effectiveInterestRate: string | null
        } | null
        accountDetails: {
          __typename?: 'BankAccountDetails'
          account: string | null
        } | null
      }
}

export const CreateDebitCardAccount = gql`
  mutation CreateDebitCardAccount($input: CreateDebitCardAccountInput!) {
    createDebitCardAccount(input: $input) {
      __typename
      ... on DebitCardAccount {
        id
        entityUuid
      }
      ... on DebitCardAccountV2 {
        __typename
        name
        id
        type
        icon {
          colour
          letter
          images {
            size
            url
          }
          image
          animation
        }
        balance {
          currency
          value
        }
        status
        cardsCount {
          total
          debit
          expense
        }
        savingsAccountDetails {
          effectiveInterestRate
        }
        accountDetails {
          account
        }
      }
    }
  }
` as unknown as TypedDocumentNode<
  CreateDebitCardAccountMutationResponse,
  CreateDebitCardAccountMutationVariables
>
export type CreateDebitCardAccountMutationFn = Apollo.MutationFunction<
  CreateDebitCardAccountMutationResponse,
  CreateDebitCardAccountMutationVariables
>

/**
 * __useCreateDebitCardAccountMutation__
 *
 * To run a mutation, you first call `useCreateDebitCardAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDebitCardAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDebitCardAccountMutation, { data, loading, error }] = useCreateDebitCardAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDebitCardAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDebitCardAccountMutationResponse,
    CreateDebitCardAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDebitCardAccountMutationResponse,
    CreateDebitCardAccountMutationVariables
  >(CreateDebitCardAccount, options)
}
export type CreateDebitCardAccountMutationHookResult = ReturnType<
  typeof useCreateDebitCardAccountMutation
>
export type CreateDebitCardAccountMutationResult =
  Apollo.MutationResult<CreateDebitCardAccountMutationResponse>
export type CreateDebitCardAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateDebitCardAccountMutationResponse,
  CreateDebitCardAccountMutationVariables
>
