import { gql } from '@apollo/client'

export const TotalAccountBalanceQuery = gql`
  query TotalAccountBalanceQuery($range: DateRange!, $timeZone: String!) {
    getDebitCardAccountBalances(range: $range, timeZone: $timeZone) {
      balance {
        value
      }
    }
  }
`
