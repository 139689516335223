import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetItemCategoriesQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input']
}>

export type GetItemCategoriesQueryResponse = {
  __typename?: 'Query'
  getItemCategories: {
    __typename?: 'ItemCategoryConnection'
    nextToken: string | null
    categories: Array<{
      __typename: 'ItemCategory'
      id: string
      name: string
    }> | null
  }
}

export const GetItemCategories = gql`
  query GetItemCategories($limit: Int!) {
    getItemCategories(limit: $limit) {
      categories {
        __typename
        id
        name
      }
      nextToken
    }
  }
` as unknown as TypedDocumentNode<
  GetItemCategoriesQueryResponse,
  GetItemCategoriesQueryVariables
>

/**
 * __useGetItemCategoriesQuery__
 *
 * To run a query within a React component, call `useGetItemCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetItemCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetItemCategoriesQueryResponse,
    GetItemCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetItemCategoriesQueryResponse,
    GetItemCategoriesQueryVariables
  >(GetItemCategories, options)
}
export function useGetItemCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetItemCategoriesQueryResponse,
    GetItemCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetItemCategoriesQueryResponse,
    GetItemCategoriesQueryVariables
  >(GetItemCategories, options)
}
export type GetItemCategoriesQueryHookResult = ReturnType<
  typeof useGetItemCategoriesQuery
>
export type GetItemCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetItemCategoriesLazyQuery
>
export type GetItemCategoriesQueryResult = Apollo.QueryResult<
  GetItemCategoriesQueryResponse,
  GetItemCategoriesQueryVariables
>
