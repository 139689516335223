import { useState } from 'react'
import { Theme } from '@npco/mp-gql-types'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  BUTTON_SIZE,
  ButtonFill,
  ButtonGhost,
  Flex,
  SelectSize,
  SelectStandard,
  SelectStyle,
  showErrorToast,
  showSuccessToast,
  ToggleForm,
  useModalState,
} from '@npco/zeller-design-system'
import { Field, Formik, FormikHelpers } from 'formik'

import { GenericErrorPage } from 'components/Placeholders/GenericErrorPage/GenericErrorPage'
import { Slider } from 'components/Slider/Slider'
import { SpinnerWrapped } from 'components/Spinner'
import { translationsShared } from 'translations'

import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal'
import { useUpdateDevicesMutation } from '../graphql/UpdateDevices.generated'
import { useGetDeviceScreenSettings } from './hooks/useGetDeviceScreenSetting'
import { useUpdateDeviceScreenSettings } from './hooks/useUpdateDeviceScreenSettings'
import { translations } from './ScreenSettings.i18n'
import {
  ButtonContainer,
  Description,
  Form,
  Title,
} from './ScreenSettings.styled'
import {
  ScreenSettingsFormValues,
  ScreenSettingsProps,
} from './ScreenSettings.types'
import {
  getTheme,
  HIBERNATE_ITEMS,
  mapHibernateTimeToItem,
  mapSleepTimeToItem,
  SLEEP_TIME_ITEMS,
} from './ScreenSettings.utils'

export const ScreenSettings = ({ terminal }: ScreenSettingsProps) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const flags = useFeatureFlags()
  const { screenSettings, isLoading, error, refetchScreenSettings } =
    useGetDeviceScreenSettings(terminal.id)
  const { isModalOpen, openModal, closeModal } = useModalState()
  const { updateDeviceScreenSettings } = useUpdateDeviceScreenSettings()
  const [updateDevicesMutation] = useUpdateDevicesMutation()

  const [applyToAllDevices, setApplyToAllDevices] = useState(false)

  if (isLoading) {
    return <SpinnerWrapped variant="top" flex={1} />
  }

  if (error) {
    return (
      <GenericErrorPage
        data-testid="screen-settings-error"
        retry={() => {
          refetchScreenSettings()
        }}
      />
    )
  }

  const handleUpdateAllDevices = async (
    values: ScreenSettingsFormValues,
    formikHelpers: FormikHelpers<ScreenSettingsFormValues>
  ) => {
    try {
      const res = await updateDevicesMutation({
        variables: {
          siteUuid: terminal.siteId,
          input: {
            screen: {
              sleepEnabled: values.sleepEnabled,
              sleepTimer: values.sleepTimer,
              standbyEnabled: values.standbyEnabled,
              standbyTimer: values.standbyTimer,
              brightness: values.brightness,
              notHibernateWhenPluggedIn: values.notHibernateWhenPluggedIn,
              theme: getTheme(values.darkModeEnabled),
            },
          },
        },
      })

      if (!res?.data?.updateDevices) {
        formikHelpers.resetForm()
        showErrorToast(t('errorToast'))
        return
      }

      showSuccessToast(t('updateAllSuccessToast'))
      formikHelpers.resetForm({ values })
    } catch (error) {
      showErrorToast(t('errorToast'))
    }
  }

  const handleUpdateSingleDevice = async (
    values: ScreenSettingsFormValues,
    formikHelpers: FormikHelpers<ScreenSettingsFormValues>
  ) => {
    try {
      const res = await updateDeviceScreenSettings({
        variables: {
          deviceSettings: {
            id: terminal.id,
            screen: {
              sleepEnabled: values.sleepEnabled,
              sleepTimer: values.sleepTimer,
              standbyEnabled: values.standbyEnabled,
              standbyTimer: values.standbyTimer,
              brightness: values.brightness,
              notHibernateWhenPluggedIn: values.notHibernateWhenPluggedIn,
              theme: getTheme(values.darkModeEnabled),
            },
          },
        },
      })
      if (!res?.data?.updateDeviceSettings) {
        formikHelpers.resetForm()
        showErrorToast(t('errorToast'))
        return
      }
      showSuccessToast(t('updateSuccessToast'))
      formikHelpers.resetForm({ values })
    } catch (error) {
      showErrorToast(t('errorToast'))
    }
  }

  const handleSubmit = async (
    values: ScreenSettingsFormValues,
    formikHelpers: FormikHelpers<ScreenSettingsFormValues>
  ) => {
    if (applyToAllDevices) {
      await handleUpdateAllDevices(values, formikHelpers)
    } else {
      await handleUpdateSingleDevice(values, formikHelpers)
    }
  }

  return (
    <Flex width="100%">
      <Formik<ScreenSettingsFormValues>
        initialValues={{
          sleepEnabled: screenSettings?.sleepEnabled ?? false,
          sleepTimer: screenSettings?.sleepTimer ?? 60,
          standbyEnabled: screenSettings?.standbyEnabled ?? false,
          standbyTimer: screenSettings?.standbyTimer ?? 1800,
          brightness: screenSettings?.brightness ?? 60,
          notHibernateWhenPluggedIn:
            screenSettings?.notHibernateWhenPluggedIn ?? false,
          darkModeEnabled: screenSettings?.theme === Theme.DARK_THEME,
        }}
        onSubmit={async (values, formikHelpers) => {
          await handleSubmit(values, formikHelpers)
        }}
      >
        {({
          values,
          setFieldValue,
          setFieldTouched,
          submitForm,
          resetForm,
          isSubmitting,
        }) => (
          <Form>
            <Flex
              data-testid="sleep-section"
              alignItems="center"
              justifyContent="space-between"
              mb="12px"
            >
              <Title>{t('sleepMode')}</Title>
              <Field
                component={ToggleForm}
                name="sleepEnabled"
                value={values.sleepEnabled}
              />
            </Flex>
            {values.sleepEnabled && (
              <Flex flexDirection="column" width="100%">
                <Description>{t('sleepModeDescription')}</Description>
                <Box width="100%" mb="40px">
                  <SelectStandard
                    size={SelectSize.Medium}
                    menuStyle={SelectStyle.Standard}
                    selectedItem={mapSleepTimeToItem(values.sleepTimer)}
                    items={SLEEP_TIME_ITEMS}
                    onChange={(item) => {
                      setFieldValue('sleepTimer', item?.value)
                      setFieldTouched('sleepTimer', true)
                    }}
                    hasSelectedIndicator
                    maxHeight="100%"
                  />
                </Box>
              </Flex>
            )}
            <Flex
              data-testid="hibernate-section"
              alignItems="center"
              justifyContent="space-between"
              mb="12px"
            >
              <Title>{t('deivceHibernate')}</Title>
              <Field
                component={ToggleForm}
                name="standbyEnabled"
                value={values.standbyEnabled}
              />
            </Flex>
            {values.standbyEnabled && (
              <Flex flexDirection="column" width="100%">
                <Description>{t('deviceHibernateDescription')}</Description>
                <Box width="100%" mb="12px">
                  <SelectStandard
                    size={SelectSize.Medium}
                    menuStyle={SelectStyle.Standard}
                    selectedItem={mapHibernateTimeToItem(values.standbyTimer)}
                    items={HIBERNATE_ITEMS}
                    onChange={(item) => {
                      setFieldValue('standbyTimer', item?.value)
                      setFieldTouched('standbyTimer', true)
                    }}
                    hasSelectedIndicator
                    maxHeight="100%"
                  />
                </Box>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  mb="40px"
                >
                  <Title>{t('neverHibernate')}</Title>
                  <Field
                    component={ToggleForm}
                    name="notHibernateWhenPluggedIn"
                    value={values.notHibernateWhenPluggedIn}
                  />
                </Flex>
              </Flex>
            )}
            <Flex
              data-testid="brightness-section"
              flexDirection="column"
              width="100%"
              pb="40px"
            >
              <Box mb="12px">
                <Title>{t('screenBrightness')}</Title>
              </Box>
              <Description>{t('screenBrightnessDescription')}</Description>
              <Slider
                min={0}
                max={100}
                step={10}
                value={values.brightness}
                onChange={(value) => {
                  setFieldValue('brightness', value)
                  setFieldTouched('brightness', true)
                }}
              />
            </Flex>
            {flags.DarkMode && (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb="40px"
              >
                <Title>{t('darkMode')}</Title>
                <Field
                  component={ToggleForm}
                  name="darkModeEnabled"
                  value={values.darkModeEnabled}
                />
              </Flex>
            )}
            <ButtonContainer>
              <ButtonFill
                data-testid="submit-button"
                onClick={() => openModal()}
                isLoading={isSubmitting}
                fullWidth
                size={BUTTON_SIZE.LARGE}
              >
                {tShared('save')}
              </ButtonFill>
              <ButtonGhost
                data-testid="reset-button"
                onClick={() => resetForm()}
                disabled={isSubmitting}
                fullWidth
                size={BUTTON_SIZE.LARGE}
              >
                {tShared('cancel')}
              </ButtonGhost>
            </ButtonContainer>
            <ConfirmationModal
              isOpen={isModalOpen}
              onCancel={closeModal}
              onClickPrimary={() => {
                setApplyToAllDevices(true)
                submitForm()
                closeModal()
              }}
              onClickSecondary={() => {
                setApplyToAllDevices(false)
                submitForm()
                closeModal()
              }}
            />
          </Form>
        )}
      </Formik>
    </Flex>
  )
}
