import { useMemo, useState } from 'react'
import { isNil } from 'lodash-es'
import { pick } from 'ramda'

interface ChartNextStateType {
  chartX?: number
  activeTooltipIndex?: number
  isTooltipActive?: boolean
}

export const useTooltipOnMouseEvent = <T,>(items: T[]) => {
  const [activeState, setActiveState] = useState<ChartNextStateType>({})

  const onChartMouseEvent = useMemo(
    () => (nextState: ChartNextStateType) => {
      setActiveState(
        pick(['chartX', 'activeTooltipIndex', 'isTooltipActive'], nextState)
      )
    },
    [setActiveState]
  )

  const activeItem = useMemo(() => {
    return isNil(activeState.activeTooltipIndex)
      ? undefined
      : items?.[activeState.activeTooltipIndex]
  }, [activeState.activeTooltipIndex, items])

  return {
    onChartMouseEvent,
    activeItem,
    activeItemCoordinateX: activeState?.chartX,
    isTooltipActive: activeState?.isTooltipActive,
  }
}
