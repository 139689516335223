import { useQuery } from '@apollo/client'

import { XeroPaymentServices as XeroPaymentServicesResponse } from 'types/gql-types/XeroPaymentServices'

import { XeroPaymentServices } from './XeroPaymentServices.graphql'

export const useXeroPaymentServices = () => {
  const { loading, data, error } =
    useQuery<XeroPaymentServicesResponse>(XeroPaymentServices)

  return {
    isLoading: loading,
    xeroPaymentServices: data?.xeroPaymentServices,
    error,
  }
}
