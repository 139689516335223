import { useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { GetTransactionTotals } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/dashboard'

import { GetTransactionTotalsVariables } from 'types/gql-types/GetTransactionTotals'
import { TransactionTotalsResponse } from 'pages/Dashboard/Dashboard.types'
import { getHasNoSales } from 'pages/Dashboard/DashboardPayments/DashboardPerformance/DashboardPerformance.utils'
import { useUpdateTotals } from 'pages/Dashboard/hooks/useUpdateTotals'
import {
  getCurrentTime,
  getStartOfDayOneMonthAgoPlusDay,
} from 'pages/Dashboard/utils/timeUtils'

import {
  convertFromApiTotals,
  mapToSalesTransactionTotal,
  mergeSalesTransactionTotals,
  sumSalesTransactionTotals,
} from './useTransactionTotals.utils'

export const useTransactionTotals = () => {
  const oneMonthAgo = useMemo(
    () => getStartOfDayOneMonthAgoPlusDay().toISOString(),
    []
  )
  const now = useMemo(() => getCurrentTime().toISOString(), [])

  const { data, loading, error, refetch, networkStatus } = useQuery<
    TransactionTotalsResponse,
    GetTransactionTotalsVariables
  >(GetTransactionTotals, {
    variables: {
      filter: {
        timestamp: {
          between: [oneMonthAgo, now],
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const cachedSalesTransactionTotals = useMemo(
    () => convertFromApiTotals(data?.getTransactionTotalsBigInt || []),
    [data]
  )

  const hasNoSales = useMemo(
    () => getHasNoSales(cachedSalesTransactionTotals),
    [cachedSalesTransactionTotals]
  )

  const { updateTotals } = useUpdateTotals({
    mapToTotal: mapToSalesTransactionTotal,
    sumTotals: sumSalesTransactionTotals,
  })

  const transactionTotals = useMemo(
    () =>
      mergeSalesTransactionTotals(cachedSalesTransactionTotals, updateTotals),
    [cachedSalesTransactionTotals, updateTotals]
  )

  return {
    transactionTotals,
    hasNoSales,
    isLoadingTransactionTotals:
      loading || networkStatus === NetworkStatus.refetch,
    error,
    refetchTransactionTotals: refetch,
  }
}
