import { useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'
import {
  rvOptimisticDebitCardTransactions,
  rvUpdatedDebitCardTransactions,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { mapAccountTransactionFiltersToApiFilters } from 'utils/banking/mapAccountTransactionsFiltersToApiFilters/mapAccountTransactionsFiltersToApiFilters'
import { groupDebitCardsTransactionsByYear } from 'utils/groupDebitCardsTransactions'
import { useDebitCardAccountTransactions } from 'pages/DebitCardTransactions/hooks/useDebitCardAccountTransactions/useDebitCardAccountTransactions'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'

import { rvSelectedContact } from '../../rv-deprecated/contacts'

export const TRANSACTIONS_PER_PAGE = 20

export const useContactAccountTransactions = () => {
  const { areFiltersInDefaultState, ...accountFilters } = useAccountFilters()
  const contact = useReactiveVar(rvSelectedContact)

  const contactFilterId = contact?.id
    ? { contactUuid: { eq: contact?.id } }
    : {}

  const filterObject = useMemo(
    () =>
      mapAccountTransactionFiltersToApiFilters({
        selectedAccountID: undefined,
        ...accountFilters,
      }),
    [accountFilters]
  )
  const filter = { ...filterObject, ...contactFilterId }

  const {
    groupedTransactions,
    loading,
    fetchMoreTransactions,
    hasMore,
    handleSelectedTransactionUpdate,
  } = useDebitCardAccountTransactions({
    groupBy: groupDebitCardsTransactionsByYear,
    filter,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (!areFiltersInDefaultState) {
        rvOptimisticDebitCardTransactions([])
        rvUpdatedDebitCardTransactions([])
      }
    },
    onError: (error) => {
      showApiErrorToast(error)
    },
    areFiltersInDefaultState,
  })

  return {
    groupedTransactions,
    fetchMoreTransactions,
    areFiltersInDefaultState,
    isLoadingTransactions: loading,
    hasMore,
    handleSelectedTransactionUpdate,
  }
}
