import { useGetCategoriesTableLazyQuery } from './graphql/getCategoriesTableQuery.generated'

export const useGetCategoriesTable = (
  hookOptions?: Parameters<typeof useGetCategoriesTableLazyQuery>[0]
) => {
  const [getQuery, { data, fetchMore, loading: isLoading, error }] =
    useGetCategoriesTableLazyQuery(hookOptions)

  const categories = data?.getItemCategories.categories
  const nextToken = data?.getItemCategories.nextToken

  const hasError = Boolean(error)
  const hasNoInitialResults = categories === undefined
  const hasNoResults = Boolean(categories && categories.length === 0)

  return {
    categories,
    getCategories: getQuery,
    hasError,
    hasMore: Boolean(nextToken),
    hasNoInitialResults,
    hasNoResults,
    isLoading,
    loadMore: fetchMore,
    nextToken,
  }
}
