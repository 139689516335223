import { useQuery } from '@apollo/client'
import { GetInvoiceDraftContactUsage } from 'features/Contacts/contacts'

import { GetInvoiceDraftContactUsage as GetInvoiceDraftContactUsageResponse } from 'types/gql-types/GetInvoiceDraftContactUsage'

export const useGetInvoiceDraftContactUsage = ({
  contactUuid,
  skip,
}: {
  contactUuid: string
  skip: boolean
}) => {
  const { data, loading } = useQuery<GetInvoiceDraftContactUsageResponse>(
    GetInvoiceDraftContactUsage,
    { variables: { contactUuid }, fetchPolicy: 'network-only', skip }
  )

  return {
    invoiceDraftContactUsage: data?.getInvoiceDraftContactUsage || 0,
    isLoadingInvoiceDraftContactUsage: loading,
  }
}
