import { gql, type TypedDocumentNode } from '@apollo/client'

import { ItemDetailsFragmentDoc } from './ItemDrawerDetails/ItemDrawerDetails.generated'

export type ItemDrawerFragment = {
  __typename?: 'Item'
  name: string
  sku: string | null
  price: number
  createdTime: number | null
  id: string
  referenceNumber: string | null
  description: string | null
  categories: Array<{
    __typename?: 'ItemCategory'
    name: string
    color: string
    id: string
  }> | null
  taxes: Array<{
    __typename?: 'ItemTax'
    enabled: boolean
    name: string
    percent: number | null
  }> | null
}

export const ItemDrawerFragmentDoc = gql`
  fragment ItemDrawerFragment on Item {
    name
    ...ItemDetailsFragment
  }
  ${ItemDetailsFragmentDoc}
` as unknown as TypedDocumentNode<ItemDrawerFragment, undefined>
