import { useEffect } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  AnnouncementModal,
  HandAndKey,
  NewBadge,
  zIndexMap,
} from '@npco/zeller-design-system'

import { useCountdownTimer } from './hooks'
import { sessionTimeoutModalTranslations } from './SessionTimeoutModal.i18n'
import { formatCountdownTime } from './utils/formatCountdownTime'

interface SessionTimeoutModalProps {
  countdownTime: number
  isOpen: boolean
  onTimeout: () => void
  onStayLoggedIn: () => void
  onLogOut: () => void
}

export const SessionTimeoutModal = ({
  countdownTime,
  isOpen,
  onTimeout,
  onStayLoggedIn,
  onLogOut,
}: SessionTimeoutModalProps) => {
  const t = useTranslations(sessionTimeoutModalTranslations)

  const { isTimeout, countdown, startCountdown, clearCountdownTimer } =
    useCountdownTimer({
      countdownTime,
    })

  const handleStayLoggedIn = () => {
    onStayLoggedIn()
    clearCountdownTimer()
  }

  const handleLoggedOut = () => {
    onLogOut()
    clearCountdownTimer()
  }

  useEffect(() => {
    if (isTimeout) {
      onTimeout()
    }
  }, [isTimeout, onTimeout])

  useEffect(() => {
    if (isOpen) {
      startCountdown()
    }
  }, [isOpen, startCountdown])

  return (
    <AnnouncementModal
      Illustration={<HandAndKey size="large" />}
      badgeContent={<NewBadge text={formatCountdownTime(countdown)} />}
      description={t('description')}
      heading={t('title')}
      onClickPrimary={handleStayLoggedIn}
      onClickSecondary={handleLoggedOut}
      primaryButtonLabel={t('stayLoggedInButton')}
      secondaryButtonLabel={t('logOutButton')}
      isOpen={isOpen}
      onCancel={handleStayLoggedIn}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      overlayBlur
      zIndex={zIndexMap.announcement}
    />
  )
}
