import { gql } from '@apollo/client'

export const DeleteInvoice = gql`
  mutation DeleteInvoice($referenceNumber: String!) {
    deleteInvoice(referenceNumber: $referenceNumber) {
      __typename
      id
      referenceNumber
      status
    }
  }
`
