import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { NewSavingsAccountFragmentDoc } from './NewSavingsAccountFragment.generated'

const defaultOptions = {} as const
export type ActivateSavingsAccountMutationVariables = Types.Exact<{
  tfn: Types.Scalars['String']['input']
}>

export type ActivateSavingsAccountMutationResponse = {
  __typename?: 'Mutation'
  createSavingsAccount:
    | { __typename?: 'DebitCardAccount' }
    | {
        __typename?: 'DebitCardAccountV2'
        id: string
        type: Types.DebitCardAccountType
        name: string
        status: Types.DebitCardAccountStatus
        balance: {
          __typename?: 'Money'
          currency: string
          value: string
        } | null
        cardsCount: {
          __typename?: 'DebitCardAccountV2CardsCount'
          total: number
          debit: number
          expense: number | null
        }
        icon: {
          __typename?: 'Icon'
          colour: string | null
          letter: string | null
          image: string | null
          animation: string | null
          images: Array<{
            __typename?: 'Image'
            size: Types.ImageSize
            url: string
          }> | null
        } | null
        savingsAccountDetails: {
          __typename?: 'SavingsAccountDetails'
          effectiveInterestRate: string | null
        } | null
        accountDetails: {
          __typename?: 'BankAccountDetails'
          account: string | null
        } | null
      }
}

export const ActivateSavingsAccount = gql`
  mutation ActivateSavingsAccount($tfn: String!) {
    createSavingsAccount(input: { tfn: $tfn }) {
      ... on DebitCardAccountV2 {
        ...NewSavingsAccountFragment
      }
    }
  }
  ${NewSavingsAccountFragmentDoc}
` as unknown as TypedDocumentNode<
  ActivateSavingsAccountMutationResponse,
  ActivateSavingsAccountMutationVariables
>
export type ActivateSavingsAccountMutationFn = Apollo.MutationFunction<
  ActivateSavingsAccountMutationResponse,
  ActivateSavingsAccountMutationVariables
>

/**
 * __useActivateSavingsAccountMutation__
 *
 * To run a mutation, you first call `useActivateSavingsAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSavingsAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSavingsAccountMutation, { data, loading, error }] = useActivateSavingsAccountMutation({
 *   variables: {
 *      tfn: // value for 'tfn'
 *   },
 * });
 */
export function useActivateSavingsAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateSavingsAccountMutationResponse,
    ActivateSavingsAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ActivateSavingsAccountMutationResponse,
    ActivateSavingsAccountMutationVariables
  >(ActivateSavingsAccount, options)
}
export type ActivateSavingsAccountMutationHookResult = ReturnType<
  typeof useActivateSavingsAccountMutation
>
export type ActivateSavingsAccountMutationResult =
  Apollo.MutationResult<ActivateSavingsAccountMutationResponse>
export type ActivateSavingsAccountMutationOptions = Apollo.BaseMutationOptions<
  ActivateSavingsAccountMutationResponse,
  ActivateSavingsAccountMutationVariables
>
