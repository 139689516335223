import { Navigate, useLocation } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'

export const AllCards = () => {
  const location = useLocation()

  return (
    <Navigate
      to={ROOT.PORTAL.CARDS.DEBIT.path}
      state={location.state}
      replace
    />
  )
}
