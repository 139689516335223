import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { ROOT } from '../../../../const/routes'
import { InternalTransferState } from '../../Transfer.types'

export const useGoToInternalTransfer = () => {
  const history = useHistory()

  const createState = useCallback(
    (state: InternalTransferState | null) => ({
      Transfer: state,
    }),
    []
  )

  const goToInternalTransfer = useCallback(
    (state: InternalTransferState | null) => {
      history.push(ROOT.PORTAL.ACCOUNTS.TRANSFER.path, createState(state))
    },
    [history, createState]
  )

  return { goToInternalTransfer, createState }
}
