import { useMemo } from 'react'
import { Step } from 'react-joyride'
import { useTranslations } from '@npco/utils-translations'

import { translations } from '../ItemOnboarding.i18n'

export const STEPS_LAST_INDEX = 1

export const useGetSteps = (): Step[] => {
  const t = useTranslations(translations)

  return useMemo(
    () => [
      {
        content: t('settingsBody'),
        disableBeacon: true,
        placement: 'bottom-end',
        target: '#items-settings',
        title: t('settingsTitle'),
      },
      {
        content: t('manageBody'),
        disableBeacon: true,
        placement: 'bottom-end',
        target: '#manage-button',
        title: t('manageTitle'),
      },
    ],
    [t]
  )
}
