import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type ExportItemsMutationVariables = Types.Exact<{
  format: Types.ItemExportFormat
}>

export type ExportItemsMutationResponse = {
  __typename?: 'Mutation'
  exportItems: { __typename?: 'ItemExportDownload'; downloadLink: string }
}

export const ExportItems = gql`
  mutation ExportItems($format: ItemExportFormat!) {
    exportItems(format: $format) {
      downloadLink
    }
  }
` as unknown as TypedDocumentNode<
  ExportItemsMutationResponse,
  ExportItemsMutationVariables
>
export type ExportItemsMutationFn = Apollo.MutationFunction<
  ExportItemsMutationResponse,
  ExportItemsMutationVariables
>

/**
 * __useExportItemsMutation__
 *
 * To run a mutation, you first call `useExportItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportItemsMutation, { data, loading, error }] = useExportItemsMutation({
 *   variables: {
 *      format: // value for 'format'
 *   },
 * });
 */
export function useExportItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportItemsMutationResponse,
    ExportItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ExportItemsMutationResponse,
    ExportItemsMutationVariables
  >(ExportItems, options)
}
export type ExportItemsMutationHookResult = ReturnType<
  typeof useExportItemsMutation
>
export type ExportItemsMutationResult =
  Apollo.MutationResult<ExportItemsMutationResponse>
export type ExportItemsMutationOptions = Apollo.BaseMutationOptions<
  ExportItemsMutationResponse,
  ExportItemsMutationVariables
>
