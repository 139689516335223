import { useQuery } from '@apollo/client'
import { Flex } from '@npco/zeller-design-system'

import { DateRange } from 'components/Filters/PillFilters/DateRange'
import { DateRangeValue } from 'components/Filters/PillFilters/DateRange.types'
import { DevicesFilter } from 'components/Filters/PillFilters/DevicesFilter'
import { SaleType } from 'components/Filters/PillFilters/SaleType'
import { Source } from 'components/Filters/PillFilters/Source'
import { Status } from 'components/Filters/PillFilters/Status'

import { GetReportsLocalState } from './getReportsLocalState'
import { CacheLocalStateReports } from './ReportsNew.types'
import { useReportsLocalCache } from './useReportsLocalCache'

type ReportsFiltersProps = {
  defaultRange: Required<DateRangeValue>
}

export const ReportsFiltersNew = ({ defaultRange }: ReportsFiltersProps) => {
  const { data } = useQuery<CacheLocalStateReports>(GetReportsLocalState)
  const { filters } = data?.local.reports ?? {}
  const {
    setSelectedDates,
    setSelectedTypes,
    setSelectedStatuses,
    setSelectedSources,
    setSelectedTerminals,
  } = useReportsLocalCache()

  return (
    <Flex flexWrap="wrap" gap="8px">
      <DateRange
        range={filters?.dates ?? undefined}
        onRangeChange={setSelectedDates}
        defaultRange={defaultRange}
        format="datetime"
      />
      <SaleType
        selectedTypes={filters?.types ?? undefined}
        setSelectedTypes={setSelectedTypes}
      />
      <Status
        selectedStatuses={filters?.statuses ?? undefined}
        setSelectedStatuses={setSelectedStatuses}
        hiddenStatuses={['REFUNDED']}
      />
      <Source
        selectedSources={filters?.sources ?? undefined}
        setSelectedSources={setSelectedSources}
      />
      <DevicesFilter
        value={filters?.terminals ?? undefined}
        onValueChange={setSelectedTerminals}
      />
    </Flex>
  )
}
