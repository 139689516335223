import TagManager from 'react-gtm-module'
import { rvCurrentUserData } from '@npco/mp-utils-logged-in-user'
import { ErrorLogger } from '@npco/utils-error-logger'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/misc'
import { find } from 'lodash-es'

import countryCodes from 'const/country-codes.json'

async function hash(string: string | null | undefined = '') {
  const utf8 = new TextEncoder().encode((string ?? '').trim())

  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('')

  return hashHex
}

async function getDefaultProperties() {
  const entityDetails = rvEntityDetails()
  const userData = rvCurrentUserData()

  const countryData = find(
    countryCodes,
    ({ name }) => name === entityDetails?.businessAddress?.country
  )

  const sha256City = await hash(entityDetails?.businessAddress?.suburb ?? '')
  const sha256Country = await hash(countryData?.alpha2Code ?? '')
  const sha256FirstName = await hash(userData?.firstname ?? '')
  const sha256LastName = await hash(userData?.lastname ?? '')
  const sha256PhoneNumber = await hash(userData?.phone ?? '')
  const sha256PostalCode = await hash(
    entityDetails?.businessAddress?.postcode ?? ''
  )
  const sha256Region = await hash(entityDetails?.businessAddress?.state ?? '')
  const sha256Street = await hash(entityDetails?.businessAddress?.street1 ?? '')
  const sha256emailAddress = await hash(userData?.email ?? '')

  const defaultProperties = {
    'entity.category': entityDetails?.category,
    'entity.categoryGroup': entityDetails?.categoryGroup,
    'entity.sha256City': sha256City,
    'entity.sha256Country': sha256Country,
    'entity.sha256PostalCode': sha256PostalCode,
    'entity.sha256Region': sha256Region,
    'entity.sha256Street': sha256Street,
    'entity.type': entityDetails?.type,
    'entity.uuid': entityDetails?.id,
    'user.role': userData?.role,
    'user.sha256EmailAddress': sha256emailAddress,
    'user.sha256FirstName': sha256FirstName,
    'user.sha256LastName': sha256LastName,
    'user.sha256PhoneNumber': sha256PhoneNumber,
    'user.uuid': userData?.id,
  }

  return defaultProperties
}

type DataLayerProperties = {
  event?: string | null
} & Partial<DataLayerPropertiesDefaults> &
  Record<string, unknown>

type DataLayerPropertiesDefaults = Awaited<
  ReturnType<typeof getDefaultProperties>
>

export const sendToDataLayer = (properties: DataLayerProperties): void => {
  getDefaultProperties()
    .then((defaultProperties) =>
      TagManager.dataLayer({
        dataLayer: {
          ...defaultProperties,
          ...properties,
        },
      })
    )
    .catch((error) =>
      ErrorLogger.reportError('[GTM] - error setting data layer', error)
    )
}
