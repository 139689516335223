import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetOrderReferenceNumberQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type GetOrderReferenceNumberQueryResponse = {
  __typename?: 'Query'
  getOrder: { __typename?: 'Order'; referenceNumber: string }
}

export const GetOrderReferenceNumber = gql`
  query GetOrderReferenceNumber($id: ID!) {
    getOrder(id: $id) {
      referenceNumber
    }
  }
` as unknown as TypedDocumentNode<
  GetOrderReferenceNumberQueryResponse,
  GetOrderReferenceNumberQueryVariables
>

/**
 * __useGetOrderReferenceNumberQuery__
 *
 * To run a query within a React component, call `useGetOrderReferenceNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderReferenceNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderReferenceNumberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderReferenceNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderReferenceNumberQueryResponse,
    GetOrderReferenceNumberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOrderReferenceNumberQueryResponse,
    GetOrderReferenceNumberQueryVariables
  >(GetOrderReferenceNumber, options)
}
export function useGetOrderReferenceNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderReferenceNumberQueryResponse,
    GetOrderReferenceNumberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOrderReferenceNumberQueryResponse,
    GetOrderReferenceNumberQueryVariables
  >(GetOrderReferenceNumber, options)
}
export type GetOrderReferenceNumberQueryHookResult = ReturnType<
  typeof useGetOrderReferenceNumberQuery
>
export type GetOrderReferenceNumberLazyQueryHookResult = ReturnType<
  typeof useGetOrderReferenceNumberLazyQuery
>
export type GetOrderReferenceNumberQueryResult = Apollo.QueryResult<
  GetOrderReferenceNumberQueryResponse,
  GetOrderReferenceNumberQueryVariables
>
