import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  categoryAttributeRequired: 'Category must have a matching attribute.',
  categoryLabel: 'Category',
  categoryValueInvalidGeneric:
    'All Items must have a category less than {length} characters. Please review the file.',
  categoryValueInvalidMultipleRows:
    'Category is required and must be less than {length} characters on row {rowNumbers} and {endRowNumber}.',
  categoryValueInvalidSingleRow:
    'Category is required and must be less than {length} characters on row {rowNumber}.',
  column1: 'Attributes in Zeller',
  column2: 'Attributes in Imported File',
  description:
    'Match the columns in your imported file to those in Zeller Items.',
  descriptionLabel: 'Description (Optional)',
  gstAttributeRequired: 'Default GST must have a matching attribute.',
  gstLabel: 'Default GST',
  gstValueInvalidGeneric: 'Default GST must be Y or N. Please review the file.',
  gstValueInvalidMultipleRows:
    'Default GST must be Y or N on row {rowNumbers} and {endRowNumber}.',
  gstValueInvalidSingleRow: 'Default GST must be Y or N on row {rowNumber}.',
  infoboxLink: 'this support article',
  nameAttributeRequired: 'Name must have a matching attribute.',
  nameLabel: 'Name',
  nameValueInvalidGeneric:
    'All items must have a name. Please review the file.',
  nameValueInvalidMultipleRows:
    'Item name is required on row {rowNumbers} and {endRowNumber}.',
  nameValueInvalidSingleRow: 'Item name is required on row {rowNumber}.',
  priceAttributeRequired: 'Price must have a matching attribute.',
  priceLabel: 'Price',
  priceSubLabel: '0.00',
  priceValueInvalidGeneric:
    'Price format must be 0.00. Please review the file.',
  priceValueInvalidMultipleRows:
    'Price format must be 0.00 on row {rowNumbers} and {endRowNumber}',
  priceValueInvalidSingleRow: 'Price format must be 0.00 on row {rowNumber}.',
  severalErrorInfobox:
    'Several errors. You can find more information on uploading items at {link}.',
  skuLabel: 'SKU (Optional)',
  skuValueDuplicateGeneric:
    'Duplicate SKU values exist. Please review the file.',
  skuValueDuplicateRows:
    'Duplicate SKU values on row {rowNumbers} and {endRowNumber}.',
  uploadFileError: 'Upload failed. Please try again.',
  uploadFileSuccess:
    'Item import in progress. You will receive an email when the upload is complete.',
})
