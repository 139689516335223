import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { CompleteEmailChangeStatus } from '@npco/mp-gql-types'
import { Box } from '@npco/zeller-design-system'
import { rvEmailChangeStatus } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/users'

import { ROUTE } from 'const/routes'
import { translate } from 'utils/translations'
import { StyledText } from 'pages/CompleteEmailChange/CompleteEmailChange.styled'
import { useCompleteEmailChange } from 'pages/CompleteEmailChange/hooks/useCompleteEmailChange'
import { CouldNotUpdateEmailMessage } from 'pages/CompleteEmailChange/Messages/CouldNotUpdateEmailMessage'
import { EmailChangedMessage } from 'pages/CompleteEmailChange/Messages/EmailChangedMessage'
import { SomethingWentWrongMessage } from 'pages/CompleteEmailChange/Messages/SomethingWentWrongMessage'
import { AuthLayout } from 'layouts'
import { SpinnerWrapped } from 'components/Spinner'

export const CompleteEmailChange = () => {
  const status = useReactiveVar(rvEmailChangeStatus)
  const history = useHistory()
  const { completeEmailChange, isCompletingEmailChange } =
    useCompleteEmailChange()

  useEffect(() => {
    completeEmailChange()

    // componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const displayLoader = () => (
    <Box>
      <SpinnerWrapped justifyContent="center" />
      <StyledText>{translate('page.changeEmail.updatingSettings')}</StyledText>
    </Box>
  )

  const displayMessage = () => {
    switch (status) {
      case CompleteEmailChangeStatus.COMPLETED:
        return <EmailChangedMessage />
      case CompleteEmailChangeStatus.CODE_EXPIRED_OR_INVALID:
        return <SomethingWentWrongMessage />
      case CompleteEmailChangeStatus.FAILED:
      case CompleteEmailChangeStatus.INVALID_EMAIL:
      default:
        return <CouldNotUpdateEmailMessage />
    }
  }

  useEffect(() => {
    if (
      status === CompleteEmailChangeStatus.COMPLETED &&
      history.location.pathname === ROUTE.CHANGE_EMAIL
    ) {
      setTimeout(() => history.push(ROUTE.PORTAL_OVERVIEW), 3000)
    }
  }, [history, status])

  return (
    <AuthLayout hasNavBar={false} isLogInPage>
      {isCompletingEmailChange && displayLoader()}
      {!isCompletingEmailChange && displayMessage()}
    </AuthLayout>
  )
}
