import { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { rvCurrentUserData } from '@npco/mp-utils-logged-in-user'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetDeposits } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/deposits'
import { DepositUpdate } from 'apps/component-merchant-portal/src/graphql/merchant-portal/subscriptions/deposits'
import {
  rvSelectedDates,
  rvSelectedRates,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvDeferredFilterPayload } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/filters'
import { debounce } from 'debounce'
import { whereEq } from 'ramda'
import { Observable, Subject } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { useSubscription } from 'hooks/useSubscription'
import { rangePickerInitialValue } from 'const/common'
import { resetFilters } from 'utils/common'
import { mapFiltersInputToDepositApiFilter } from 'utils/mapFiltersInputToApiFilter'
import { Deposit, FetchMoreDeposits } from 'types/deposits'
import {
  DepositUpdate as DepositUpdateResponse,
  DepositUpdateVariables,
} from 'types/gql-types/DepositUpdate'
import { GetDeposits as GetDepositsType } from 'types/gql-types/GetDeposits'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { DepositsFilteredPayload } from 'services/Analytics/events/deposit'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import {
  filterNonNullDeposits,
  filterUniqueDeposits,
  sortDescendingDepositsTimestamp,
} from 'pages/Deposits/Deposits.utils'

import { groupDepositsByYear } from './useDepositFetch.utils'

const defaultFiltersState = {
  depositAmount: {
    between: [0, 10000000],
  },
  timestamp: {
    from: undefined,
    to: undefined,
    enteredTo: undefined,
  },
}

export const useDepositFilters = (isLocalDate = false) => {
  const dates = useReactiveVar(rvSelectedDates)
  const rates = useReactiveVar(rvSelectedRates)

  const filters = useMemo(
    () =>
      mapFiltersInputToDepositApiFilter(
        {
          dates,
          rates,
        },
        isLocalDate
      ),
    [dates, rates, isLocalDate]
  )

  return {
    filters,
    defaultFiltersState,
    areFiltersInDefaultState: whereEq(defaultFiltersState)({
      ...defaultFiltersState,
      ...filters,
    }),
  }
}

const allDepositList$ = new Subject<Deposit[]>()

const DEPOSITS_PER_PAGE = 50
const FILTER_CHANGE_EVENT_DEBOUNCE = 10000

export const useFetchDeposits = () => {
  const { filters, areFiltersInDefaultState } = useDepositFilters()
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  const entityUuid = rvCurrentUserData()?.entityUuid || ''

  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)

  const [, setAllDeposits] = useState<Deposit[]>([])

  const debounceAnalyticsEvent = useMemo(
    () =>
      debounce((payload: DepositsFilteredPayload) => {
        if (Object.keys(payload).length) {
          trackAnalyticsEvent(AnalyticsEventNames.DEPOSITS_FILTERED, payload)
          rvDeferredFilterPayload({})
        }
      }, FILTER_CHANGE_EVENT_DEBOUNCE),
    [trackAnalyticsEvent]
  )

  const groupedDepositList$: Observable<Deposit[][]> = allDepositList$.pipe(
    map(filterNonNullDeposits),
    map(filterUniqueDeposits),
    map(sortDescendingDepositsTimestamp),
    map(groupDepositsByYear),
    tap(() => setHasLoaded(true))
  )

  const manageDeposits = (deposits: (Deposit | null)[]) => {
    setAllDeposits(() => {
      allDepositList$.next([...(deposits as unknown as Deposit[])])
      return [...(deposits as unknown as Deposit[])]
    })
  }

  const manageSubscription = (deposit: Deposit) => {
    setAllDeposits((prev) => {
      allDepositList$.next([
        { ...deposit, subscriptionIsUpdated: true },
        ...prev,
      ])
      return [{ ...deposit, subscriptionIsUpdated: true }, ...prev]
    })
  }

  const sentDepositsFilteredEvent = () => {
    debounceAnalyticsEvent.clear()
    debounceAnalyticsEvent({ ...rvDeferredFilterPayload() })
  }

  const [getDeposits, { data, loading, fetchMore }] =
    useLazyQuery<GetDepositsType>(GetDeposits, {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        limit: DEPOSITS_PER_PAGE,
        filter: filters,
        nextToken: undefined,
      },
      onError: (err) => {
        showApiErrorToast(err)
        sentDepositsFilteredEvent()
      },
      onCompleted: (response) => {
        const deposits: (Deposit | null)[] = response?.getDeposits.deposits
        manageDeposits(deposits)
        sentDepositsFilteredEvent()
      },
    })

  useSubscription<DepositUpdateResponse, DepositUpdateVariables>(
    DepositUpdate,
    {
      onSubscriptionData: (res) => {
        if (res.subscriptionData.data?.onDepositUpdate) {
          manageSubscription(res.subscriptionData.data?.onDepositUpdate)
        }
      },
      skip: !areFiltersInDefaultState,
      variables: {
        entityUuid,
      },
    }
  )

  useEffect(() => {
    resetFilters()
    rvSelectedRates(rangePickerInitialValue)
    rvDeferredFilterPayload({})
    getDeposits()

    return () => {
      resetFilters()
      debounceAnalyticsEvent.flush()
    }
  }, [debounceAnalyticsEvent, getDeposits])

  const fetchMoreDeposits = async () => {
    const nextToken = data?.getDeposits?.nextToken

    if (!nextToken?.type) {
      return
    }

    setIsLoadingMore(true)

    const response: FetchMoreDeposits = await fetchMore?.({
      variables: {
        nextToken,
      },
    })

    const fetchedDeposits: (Deposit | null)[] | undefined =
      response?.data.getDeposits.deposits

    if (fetchedDeposits) {
      setAllDeposits((prev) => {
        allDepositList$.next([...prev, ...(fetchedDeposits as Deposit[])])
        return [...prev, ...(fetchedDeposits as Deposit[])]
      })
    }
    setIsLoadingMore(false)
  }

  const hasReachedEnd = data?.getDeposits.nextToken === null

  return {
    groupedDepositList$,
    isInitiallyLoading: loading && !isLoadingMore,
    areFiltersInDefaultState,
    hasLoaded,
    fetchMoreDeposits,
    hasReachedEnd,
  }
}
