import { useCallback } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { useApolloClient, useQuery } from '@apollo/client'

import { Subset } from 'types/utils'

import { GetSidebarLocalState } from '../graphql/getSidebarLocalState'
import { SidebarDefaultLocalState } from '../Sidebar.constants'
import {
  CacheLocalStateSidebar,
  ExpandableSidebarItems,
} from '../Sidebar.types'
import { getExpandableSidebarItemFromPath } from '../Sidebar.utils'

export const useSidebarLocalCache = () => {
  const { cache } = useApolloClient()
  const { data } = useQuery<CacheLocalStateSidebar>(GetSidebarLocalState)
  const { pathname } = useLocation()
  const expandedItemFromPath = getExpandableSidebarItemFromPath(pathname)

  const isFolded =
    data?.local?.application?.sidebar?.isFolded ??
    SidebarDefaultLocalState.isFolded

  const expandedItem =
    data?.local?.application?.sidebar?.itemExpanded ??
    SidebarDefaultLocalState.itemExpanded

  const updateCache = useCallback(
    (next: Subset<CacheLocalStateSidebar>) =>
      cache.updateQuery<Subset<CacheLocalStateSidebar> | null>(
        {
          query: GetSidebarLocalState,
        },
        (previous) => {
          const isFolded =
            next.local?.application?.sidebar?.isFolded ??
            previous?.local?.application?.sidebar?.isFolded ??
            SidebarDefaultLocalState.isFolded

          const expandedItem =
            next.local?.application?.sidebar?.itemExpanded ??
            previous?.local?.application?.sidebar?.itemExpanded ??
            SidebarDefaultLocalState.itemExpanded

          return {
            local: {
              application: {
                sidebar: {
                  isFolded,
                  itemExpanded: expandedItem,
                },
              },
            },
          }
        }
      ),
    [cache]
  )
  const setIsFolded = useCallback(
    (isFolded: boolean) => {
      updateCache({
        local: {
          application: {
            sidebar: {
              isFolded,
              itemExpanded: isFolded ? 'NONE' : expandedItemFromPath,
            },
          },
        },
      })
    },
    [expandedItemFromPath, updateCache]
  )

  const toggleFold = useCallback(() => {
    setIsFolded(!isFolded)
  }, [isFolded, setIsFolded])

  const setExpandedItem = useCallback(
    (expandedItem: ExpandableSidebarItems) => {
      updateCache({
        local: {
          application: {
            sidebar: {
              itemExpanded: expandedItem,
            },
          },
        },
      })
    },
    [updateCache]
  )

  const resetState = useCallback(() => {
    updateCache({
      local: {
        application: {
          sidebar: SidebarDefaultLocalState,
        },
      },
    })
  }, [updateCache])

  const handleClickOutside = useCallback(() => {
    if (!isFolded) {
      setExpandedItem(expandedItemFromPath)
    }
  }, [expandedItemFromPath, isFolded, setExpandedItem])

  return {
    expandedItemFromPath,
    expandedItem,
    handleClickOutside,
    isFolded,
    resetState,
    setExpandedItem,
    setIsFolded,
    toggleFold,
  }
}
