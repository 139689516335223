import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import {
  GetSetupFlowDebitCards,
  GetSetupFlowDebitCardsQueryResponse,
} from './graphql/getCards.generated'

export const useSetupFlowCardsQuery = () => {
  const { loading, data } = useQuery<
    GetSetupFlowDebitCardsQueryResponse,
    never
  >(GetSetupFlowDebitCards)

  const cards = useMemo(() => {
    return data?.getDebitCardsV2.cards
  }, [data?.getDebitCardsV2?.cards])

  return {
    isLoadingCards: loading,
    cards,
  }
}
