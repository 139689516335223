import { useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { TransactionFilterInput } from '@npco/mp-gql-types'
import { GetOverviewTransactionTotals } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/dashboard'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import dayjs from 'utils/dayjs'
import {
  GetOverviewTransactionTotals as GetOverviewTransactionTotalsResponse,
  GetOverviewTransactionTotalsVariables,
} from 'types/gql-types/GetOverviewTransactionTotals'
import { useUpdateTotals } from 'pages/Dashboard/hooks/useUpdateTotals'
import {
  getCurrentTime,
  getEndOfCurrentDay,
  getStartOfDayOneWeekAgo,
  getStartOfPreviousMonth,
} from 'pages/Dashboard/utils/timeUtils'
import { getTotalsBetween } from 'pages/Dashboard/utils/transactionTotalsUtils'

import { getHasNoSales } from '../DashboardPerformance.utils'
import {
  convertFromApiTotals,
  mapToTransactionTotal,
  mergePerformanceData,
  sumTransactionTotals,
} from './utils/usePerformanceData.utils'

interface PerformanceOptions {
  filter: TransactionFilterInput | undefined
}

export const usePerformanceData = ({ filter }: PerformanceOptions) => {
  const startOfPreviousMonth = useMemo(
    () => dayjs(getStartOfPreviousMonth()),
    []
  )

  const now = useMemo(() => dayjs(getCurrentTime()), [])

  const variables = useMemo(() => {
    const timestamp = {
      between: [startOfPreviousMonth.toISOString(), now.toISOString()],
    }

    return {
      filter: {
        timestamp,
        ...filter,
      },
    }
  }, [filter, now, startOfPreviousMonth])

  const isMobile = useIsMobileResolution()

  const context = useMemo(
    () =>
      isMobile
        ? {
            debounceKey: 'dashboardSitePerformanceData',
            debounceTimeout: 500,
          }
        : undefined,
    [isMobile]
  )

  const { data, loading, error, refetch, networkStatus } = useQuery<
    GetOverviewTransactionTotalsResponse,
    GetOverviewTransactionTotalsVariables
  >(GetOverviewTransactionTotals, {
    skip: filter === undefined,
    variables,
    context,
    notifyOnNetworkStatusChange: true,
  })

  const cachedPerformanceData = useMemo(
    () => convertFromApiTotals(data?.getTransactionTotalsBigInt || []),
    [data]
  )

  const { updateTotals } = useUpdateTotals({
    mapToTotal: mapToTransactionTotal,
    sumTotals: sumTransactionTotals,
  })

  const performanceData = useMemo(
    () => mergePerformanceData(cachedPerformanceData, updateTotals),
    [cachedPerformanceData, updateTotals]
  )

  const hasNoPerformanceData = useMemo(
    () => performanceData.length === 0 || getHasNoSales(performanceData),
    [performanceData]
  )

  const hasNoPerformanceDataToday = useMemo(
    () =>
      hasNoPerformanceData ||
      getHasNoSales(
        getTotalsBetween(
          performanceData,
          getStartOfDayOneWeekAgo(),
          getEndOfCurrentDay()
        )
      ),
    [hasNoPerformanceData, performanceData]
  )

  return {
    performanceData,
    isLoadingPerformanceData:
      loading || networkStatus === NetworkStatus.refetch,
    hasNoPerformanceData,
    hasNoPerformanceDataToday,
    hasError: Boolean(error),
    refetch,
  }
}
