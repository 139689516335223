import { useQuery } from '@apollo/client'

import { parseApiMoney } from 'utils/parseMoney'

import {
  GetSpendControlLimitAccountBalance,
  GetSpendControlLimitAccountBalanceQueryResponse,
  GetSpendControlLimitAccountBalanceQueryVariables,
} from './graphql/getSpendControlLimitAccountBalance.generated'

interface UseAccountBalanceProps {
  id?: string
}

export const useAccountBalance = ({ id }: UseAccountBalanceProps) => {
  const { data } = useQuery<
    GetSpendControlLimitAccountBalanceQueryResponse,
    GetSpendControlLimitAccountBalanceQueryVariables
  >(GetSpendControlLimitAccountBalance, {
    skip: !id,
    variables: { id: id || '' },
  })

  return {
    balance: data?.account?.balance?.value
      ? parseApiMoney(data.account.balance.value)
      : null,
  }
}
