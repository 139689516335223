import { Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import { ACCOUNT } from '@npco/mp-utils-accounts-routing'

import { useAccountId } from 'hooks/banking'
import { NotFound } from 'pages/NotFound'

import { AccountCards } from '../../AccountCards/AccountCards'
import { AccountDetails } from '../../AccountDetails'
import { AccountTransactions } from '../../AccountTransactions'

export const AccountRoutes = () => {
  const { accountId } = useAccountId()

  return (
    <Routes>
      <Route
        index
        element={<Navigate to={ACCOUNT(accountId).TRANSACTIONS.path} replace />}
      />
      <Route
        path={ACCOUNT().TRANSACTIONS.relative}
        element={<AccountTransactions />}
      />

      <Route path={ACCOUNT().CARDS.relative} element={<AccountCards />} />

      <Route path={ACCOUNT().DETAILS.relative} element={<AccountDetails />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
