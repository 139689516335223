import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { translate } from 'utils/translations'

import { StyledHeading } from './DashboardHeader.styled'
import { getTranslationString } from './DashboardHeader.utils'

export const DashboardHeader = () => {
  const { userData } = useLoggedInUser()
  return (
    <StyledHeading>
      {translate(getTranslationString(), {
        firstName: userData?.firstname || '',
      })}
    </StyledHeading>
  )
}
