import { Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import { CASHFLOW_REPORTING } from '@npco/mp-feature-cashflow-reporting'
import { CustomerRole } from '@npco/mp-gql-types'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'

import { ROOT } from 'const/routes'
import { Reports } from 'pages/Reports/Reports'
import { ReportsNew } from 'pages/Reports/ReportsNew/ReportsNew'
import { RoleGuardedRoutes } from 'components/RoleGuardedRoutes'

import { CashflowReporting } from '../CashflowReporting/CashflowReporting'
import { ReportsAccessDenied } from '../ReportsAccessDenied/ReportsAccessDenied'

export const ReportsRoutes = () => {
  const flags = useFeatureFlags()

  return (
    <Routes>
      <Route
        index
        element={<Navigate to={ROOT.PORTAL.REPORTS.SALES.path} replace />}
      />
      <Route
        path={ROOT.PORTAL.REPORTS.SALES.relative}
        element={flags.ReportsFilterUplift ? <ReportsNew /> : <Reports />}
      />
      <Route
        element={
          <RoleGuardedRoutes
            allowedRoles={[CustomerRole.ADMIN]}
            accessDeniedElement={<ReportsAccessDenied />}
          />
        }
      >
        <Route
          path={CASHFLOW_REPORTING.relative}
          element={<CashflowReporting />}
        />
      </Route>
    </Routes>
  )
}
