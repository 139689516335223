import { ErrorLogger } from '@npco/utils-error-logger'

import { SESSION_STORAGE_KEYS } from './keys'

export function getSessionStorageItem<T>(key: string): T | undefined {
  const item = sessionStorage.getItem(key)

  try {
    return item && item !== 'undefined'
      ? (JSON.parse(item.replace(/undefined/g, 'null')) as T)
      : undefined
  } catch (error) {
    ErrorLogger.report('[Core] parse session storage items', { key, error })
    return undefined
  }
}

export function setSessionStorageItem<T>(key: string, payload: T): void {
  const parsedItem = JSON.stringify(payload)

  sessionStorage.setItem(key, parsedItem)
}

export function removeSessionStorageItem<T>(
  key: string
): T | undefined | Error {
  const item = sessionStorage.getItem(key)
  try {
    sessionStorage.removeItem(key)
  } catch (error) {
    ErrorLogger.report('[Core] parse session storage items', { key, error })
    return undefined
  }
  return item ? (JSON.parse(item) as T) : undefined
}

export const clearSessionStorage = (
  ...excludedItemsFull: SESSION_STORAGE_KEYS[]
) => {
  const excludedItems = excludedItemsFull.filter(Boolean)

  if (excludedItems.length === 0) {
    sessionStorage.clear()
    return
  }

  const excludedSessionStorageData: Partial<Record<SESSION_STORAGE_KEYS, any>> =
    {}

  excludedItems.forEach((item) => {
    excludedSessionStorageData[item] = getSessionStorageItem(item)
  })

  sessionStorage.clear()

  excludedItems.forEach((item) => {
    setSessionStorageItem(item, excludedSessionStorageData[item])
  })
}
