import { useCallback, useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { DateRange } from '@npco/mp-gql-types'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import dayjs from 'utils/dayjs'
import {
  DebitCardTransactionAmountUpdate as DebitCardTransactionAmountUpdateResponse,
  DebitCardTransactionAmountUpdateVariables,
} from 'types/gql-types/DebitCardTransactionAmountUpdate'
import { getTimezone } from 'pages/Dashboard/DashboardFinance/DashboardFinance.utils'
import { DebitCardTransactionAmountUpdate } from 'pages/Dashboard/hooks/useTotalAccountBalance/graphql/DebitCardTransactionAmountUpdate'
import { useTrackNewTransactions } from 'pages/Dashboard/hooks/useTotalAccountBalance/useTrackNewTransactions'
import {
  getIsApproved,
  getIsIncoming,
  getIsOutgoing,
} from 'pages/Dashboard/utils/debitCardTransactionUpdate'

import {
  AccountBalanceChartQuery,
  AccountBalanceChartQueryResponse,
  AccountBalanceChartQueryVariables,
} from './graphql/AccountBalanceChartQuery.generated'
import { buildBalances } from './useAccountBalanceChartQuery.utils'

export const useAccountBalanceChartQuery = (range: DateRange) => {
  const now = useMemo(() => dayjs(), [])

  const { timeZone, isTimeZoneUndefined } = getTimezone()

  const variables = useMemo(
    () => ({
      range,
      timeZone,
    }),
    [range, timeZone]
  )
  const { data, loading, error, refetch, client, subscribeToMore } = useQuery<
    AccountBalanceChartQueryResponse,
    AccountBalanceChartQueryVariables
  >(AccountBalanceChartQuery, {
    notifyOnNetworkStatusChange: true,
    variables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const { getIsNew, registerTransaction } = useTrackNewTransactions({
    after: now,
  })

  const refetchWithoutArgs = useCallback(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    subscribeToMore<
      DebitCardTransactionAmountUpdateResponse,
      DebitCardTransactionAmountUpdateVariables
    >({
      document: DebitCardTransactionAmountUpdate,
      variables: {
        entityUuid: rvEntityDetails().id,
      },
      updateQuery: (prev, { subscriptionData }) => {
        const subscriptionUpdateData =
          subscriptionData.data.onDebitCardTransactionUpdate

        if (!subscriptionUpdateData) {
          return prev
        }

        const isOutgoing = getIsOutgoing(subscriptionUpdateData)
        const isIncoming = getIsIncoming(subscriptionUpdateData)
        const isNew = getIsNew(subscriptionUpdateData)
        const isApproved = getIsApproved(subscriptionUpdateData)
        const isExternal = isOutgoing || isIncoming

        registerTransaction(subscriptionUpdateData)

        if (!isExternal || !isNew || !isApproved) {
          return prev
        }

        return buildBalances(
          prev.getDebitCardAccountBalances || [],
          subscriptionUpdateData
        )
      },
    })
    // Adding dependencies causes double processing of subscription updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const accountBalances = useMemo(() => {
    return data?.getDebitCardAccountBalances || []
  }, [data])

  const isLoadingWithoutCache = useMemo(
    () =>
      loading &&
      !client.readQuery({
        query: AccountBalanceChartQuery,
        variables,
      }),
    [loading, client, variables]
  )

  return {
    isLoading: loading,
    isLoadingWithoutCache,
    accountBalances,
    error,
    refetch: refetchWithoutArgs,
    isTimeZoneUndefined,
  }
}
