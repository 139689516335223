import { useEffect, useMemo } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetOverview } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/overview'
import { isEmpty, isEqual } from 'lodash-es'

import dayjs from 'utils/dayjs'
import {
  GetOverview as GetOverviewType,
  GetOverviewVariables,
} from 'types/gql-types/GetOverview'
import { DateRangeValue } from 'components/Filters/PillFilters/DateRange.types'

import {
  convertFromApiTotals,
  fillMissingData,
  getDayOfWeekChartData,
  getPlaceholderTransactions,
  getTimeOfDayChartData,
  getTotalSaleChartData,
  sumTransactionTotals,
} from '../Reports.utils'
import { GetReportsLocalState } from './getReportsLocalState'
import { ReportsDefaultLocalState } from './ReportsNew.constants'
import { CacheLocalStateReports } from './ReportsNew.types'
import { mapFiltersInputToOverviewApiFilter } from './ReportsNew.utils'

export const useFetchReportsContent = ({
  defaultRange,
}: {
  defaultRange: Required<DateRangeValue>
}) => {
  const { data: localData } =
    useQuery<CacheLocalStateReports>(GetReportsLocalState)
  const filters =
    localData?.local?.reports?.filters ?? ReportsDefaultLocalState.filters

  const filterObject = mapFiltersInputToOverviewApiFilter(filters)
  const areFiltersSelected = !isEqual(filters, ReportsDefaultLocalState.filters)

  const [fetchOverviewData, { data, loading }] = useLazyQuery<
    GetOverviewType,
    GetOverviewVariables
  >(GetOverview, {
    variables: {
      filter: filterObject,
    },
    onError: (error) => {
      showApiErrorToast(error)
    },
  })

  const hasNoResults = isEmpty(data?.getTransactionTotalsBigInt)
  const hasNoInitialResults = !areFiltersSelected && hasNoResults

  useEffect(() => {
    if (filters.dates?.end) fetchOverviewData()
  }, [fetchOverviewData, filters])

  const transactionTotals = useMemo(
    () => convertFromApiTotals(data?.getTransactionTotalsBigInt || []),
    [data]
  )

  const chartsDataTransactionTotals = useMemo(() => {
    if (!hasNoInitialResults) {
      return transactionTotals
    }

    return getPlaceholderTransactions()
  }, [hasNoInitialResults, transactionTotals])

  const {
    dayOfWeekChartData,
    dayOfWeekDeclinedChartData,
    timeOfDayChartData,
    timeOfDayDeclinedChartData,
  } = useMemo(
    () => ({
      dayOfWeekChartData: getDayOfWeekChartData(chartsDataTransactionTotals),
      dayOfWeekDeclinedChartData: getDayOfWeekChartData(
        chartsDataTransactionTotals,
        true
      ),
      timeOfDayChartData: getTimeOfDayChartData(chartsDataTransactionTotals),
      timeOfDayDeclinedChartData: getTimeOfDayChartData(
        chartsDataTransactionTotals,
        true
      ),
    }),
    [chartsDataTransactionTotals]
  )
  const transactionsSum = useMemo(
    () => sumTransactionTotals(transactionTotals),
    [transactionTotals]
  )

  const isSelectionMoreThenTwoMonths = useMemo(
    () =>
      dayjs(filters.dates?.end ?? new Date()).diff(
        filters.dates?.start ?? new Date(),
        'month',
        true
      ) > 2,
    [filters.dates?.end, filters.dates?.start]
  )

  const totalSaleChartData = useMemo(() => {
    const chartData = getTotalSaleChartData(
      chartsDataTransactionTotals,
      isSelectionMoreThenTwoMonths
    )

    if (hasNoResults && hasNoInitialResults) {
      return chartData
    }

    return fillMissingData(
      chartData,
      filters.dates?.start ?? defaultRange.start,
      filters.dates?.end ?? defaultRange.end,
      isSelectionMoreThenTwoMonths ? 'month' : 'day'
    )
  }, [
    chartsDataTransactionTotals,
    defaultRange.end,
    defaultRange.start,
    filters.dates?.end,
    filters.dates?.start,
    hasNoInitialResults,
    hasNoResults,
    isSelectionMoreThenTwoMonths,
  ])

  const totalDeclinedChartData = useMemo(() => {
    const chartData = getTotalSaleChartData(
      chartsDataTransactionTotals,
      isSelectionMoreThenTwoMonths,
      true
    )

    if (hasNoResults && hasNoInitialResults) {
      return chartData
    }

    return fillMissingData(
      chartData,
      filters.dates?.start ?? defaultRange.start,
      filters.dates?.end ?? defaultRange.end,
      isSelectionMoreThenTwoMonths ? 'month' : 'day'
    )
  }, [
    chartsDataTransactionTotals,
    defaultRange.end,
    defaultRange.start,
    filters.dates?.end,
    filters.dates?.start,
    hasNoInitialResults,
    hasNoResults,
    isSelectionMoreThenTwoMonths,
  ])

  return {
    dayOfWeekChartData,
    dayOfWeekDeclinedChartData,
    hasNoInitialResults,
    hasNoResults,
    isLoading: loading,
    isSelectionMoreThenTwoMonths,
    timeOfDayChartData,
    timeOfDayDeclinedChartData,
    totalDeclinedChartData,
    totalSaleChartData,
    transactionsSum,
  }
}
