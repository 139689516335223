import { useCallback } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { CreatePaymentInstrumentInput } from '@npco/mp-gql-types'
import { ErrorLogger } from '@npco/utils-error-logger'
import { CreatePaymentInstrument } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/paymentInstruments'
import { getIsMFAError } from 'features/MFA'

import { getIsResourceAlreadyExistsError } from 'utils/errors'
import {
  MFA_ERROR,
  RESOURCE_ALREADY_EXISTS_ERROR,
  UNEXPECTED_ERROR,
} from 'types/errors'
import {
  CreatePaymentInstrument as CreatePaymentInstrumentResponse,
  CreatePaymentInstrumentVariables,
} from 'types/gql-types/CreatePaymentInstrument'

export const useCreatePaymentInstrument = () => {
  const [createPaymentInstrumentMutation, { loading }] = useMutation<
    CreatePaymentInstrumentResponse,
    CreatePaymentInstrumentVariables
  >(CreatePaymentInstrument)

  const createPaymentInstrument = useCallback(
    async (payload: CreatePaymentInstrumentInput) => {
      try {
        const result = await createPaymentInstrumentMutation({
          variables: {
            payload,
          },
        })

        if (!result.data?.createPaymentInstrument) {
          throw new Error(
            'createPaymentInstrument failed with undefined id return'
          )
        }

        return { id: result.data.createPaymentInstrument }
      } catch (err) {
        const isGraphQLError =
          typeof err === 'object' && (err as ApolloError).graphQLErrors

        if (
          isGraphQLError &&
          getIsMFAError((err as ApolloError).graphQLErrors)
        ) {
          return MFA_ERROR
        }

        if (
          isGraphQLError &&
          getIsResourceAlreadyExistsError((err as ApolloError).graphQLErrors)
        ) {
          return RESOURCE_ALREADY_EXISTS_ERROR
        }

        ErrorLogger.report('[Payments] Error transferring to contact:', err)
        return UNEXPECTED_ERROR
      }
    },
    [createPaymentInstrumentMutation]
  )

  return {
    loading,
    createPaymentInstrument,
  }
}
