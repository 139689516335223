import { Box } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { SCROLLBAR } from 'const/scrollbars'

export const Wrapper = styled(Box).attrs({
  overflowY: { _: 'unset', MD: 'scroll' },
  pr: '8px',
  flexGrow: 1,
})`
  &::-webkit-scrollbar {
    width: ${SCROLLBAR.MD.width};
  }
`
