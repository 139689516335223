import { MockedResponse } from '@apollo/client/testing'
import {
  ContactType,
  DebitCardAccountStatus,
  DebitCardAccountType,
  DebitCardTransactionStatusV2,
  DebitCardTransactionTypeV2,
  EntityCategories,
  ImageSize,
} from '@npco/mp-gql-types'
import { DebitCardTransactionsV2Fragment as DebitCardTransaction } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import {
  GetContact,
  GetContactQueryResponse,
} from 'features/Contacts/graphql/GetContact.generated'

import { GraphQLError } from 'graphql'

import { Contact } from '../TransactionDrawer.types'

const ACCOUNT_ID = 'cc66ffeb-1163-41e1-9405-099eb003f926'
const ACCOUNT_NAME = 'A better name than 1234'

export const mockAccount = {
  balance: {
    currency: 'AUD',
    value: 7947126,
  },
  icon: {
    colour: 'BLUE_700',
    letter: 'A',
    images: [],
  },
  id: ACCOUNT_ID,
  name: 'A Testing Account',
  status: DebitCardAccountStatus.ACTIVE,
  cardsCount: {
    total: 1,
    debit: 1,
    expense: 0,
  },
  type: DebitCardAccountType.ZLR_DEBIT,
}

export const PAYER_NAME = 'A better name than 1234'
export const PAYEE_NAME = 'test Anna'
export const REFERENCE = 'reference'

export const externalTransaction: DebitCardTransaction = {
  __typename: 'DebitCardTransactionV2',
  attachments: [],
  amount: { value: '300' },
  debitCardName: null,
  cardAcceptorName: 'cardAcceptorName',
  id: '8b2c1a93-4721-4105-a74e-9a6466041d96',
  debitCardAccountUuid: ACCOUNT_ID,
  debitCardId: null,
  debitCardMaskedPan: null,
  description: null,
  contact: null,
  merchant: null,
  payerDetails: {
    accountDetails: {
      bsb: null,
      account: null,
      name: ACCOUNT_NAME,
    },
    debitCardAccount: {
      id: ACCOUNT_ID,
      name: PAYER_NAME,
      icon: {
        colour: 'GREEN_1000',
        letter: 'A',
        image: '',
        images: null,
        animation: null,
      },
      type: DebitCardAccountType.ZLR_DEBIT,
    },
    debitCardAccountUuid: ACCOUNT_ID,
    paymentInstrumentUuid: null,
    senderUuid: null,
  },
  payeeDetails: {
    accountDetails: { bsb: '123456', account: '123456789', name: PAYEE_NAME },
    bpayDetails: null,
    debitCardAccount: null,
    debitCardAccountUuid: null,
    paymentInstrumentUuid: '6de1606e-6ac0-5bad-b48f-ae62c0b627e4',
  },
  category: null,
  subcategory: null,
  status: DebitCardTransactionStatusV2.APPROVED,
  timestamp: '2022-02-21T14:02:02.246Z',
  reference: REFERENCE,
  referencePayee: 'reference payee',
  type: DebitCardTransactionTypeV2.DE_OUT,
  note: null,
  tags: [],
  subcategoryDetails: null,
  deposit: null,
}

export const internalTransaction: DebitCardTransaction = {
  __typename: 'DebitCardTransactionV2',
  attachments: [],
  amount: {
    value: '1200',
  },
  cardAcceptorName: 'cardAcceptorName',
  debitCardName: null,
  id: '6b3eb94e-b1ad-4a8e-9147-675b498456ce',
  debitCardAccountUuid: ACCOUNT_ID,
  debitCardId: null,
  debitCardMaskedPan: null,
  description: null,
  merchant: null,
  contact: null,
  payerDetails: {
    accountDetails: {
      bsb: null,
      account: null,
      name: ACCOUNT_NAME,
    },
    debitCardAccount: {
      id: '71f42b28-28d1-4d8c-b280-0ac681ecdcc4',
      name: 'some Random account',
      icon: {
        colour: 'GREEN_1000',
        letter: 'S',
        image: '',
        images: null,
        animation: null,
      },
      type: DebitCardAccountType.ZLR_DEBIT,
    },
    debitCardAccountUuid: '71f42b28-28d1-4d8c-b280-0ac681ecdcc4',
    paymentInstrumentUuid: null,
    senderUuid: null,
  },
  payeeDetails: {
    accountDetails: {
      bsb: null,
      account: null,
      name: 'some Random account',
    },
    bpayDetails: null,
    debitCardAccount: {
      id: ACCOUNT_ID,
      name: ACCOUNT_NAME,
      icon: {
        colour: 'GREEN_1000',
        letter: 'A',
        image: '',
        images: null,
        animation: null,
      },
      type: DebitCardAccountType.ZLR_DEBIT,
    },
    debitCardAccountUuid: ACCOUNT_ID,
    paymentInstrumentUuid: null,
  },
  category: null,
  subcategory: null,
  status: DebitCardTransactionStatusV2.APPROVED,
  timestamp: '2022-02-15T14:50:25.865Z',
  reference: 'image test',
  referencePayee: 'image test',
  type: DebitCardTransactionTypeV2.TRANSFER_OUT,
  note: 'note',
  tags: [
    '10_feb',
    'another',
    'another label',
    'A test tag',
    'Lorem ipsum dolor ',
    'LOOOOOOOOOOOOOOONG',
    'LIIOOOOOOOOOOOOOOONG',
    'VERYLOOOOOOOOOOOOONG',
    'SUPERLOOOOOOOOOONG',
    'VEEERYLOOOOOOOOOONG',
  ],
  subcategoryDetails: null,
  deposit: null,
}

export const enrichedTransaction: DebitCardTransaction = {
  __typename: 'DebitCardTransactionV2',
  attachments: [],
  amount: {
    value: '12345',
  },
  cardAcceptorName: 'cardAcceptorName',
  debitCardName: 'cardName',
  id: 'id-1',
  contact: null,
  description: null,
  merchant: {
    __typename: 'MerchantDetails',
    id: '1',
    name: 'Merchant Name',
    icon: {
      colour: 'RED_1000',
      letter: 'A',
      images: [{ url: 'https://image.com/random.png', size: ImageSize.Native }],
    },
    address: {
      country: 'AU',
      postcode: '3083',
      state: 'VIC',
      street1: 'Plenty Road',
      street2: '',
      suburb: 'Bundoora',
    },
    hours: null,
    phone: '1300 123 123',
    email: 'test@bunnings.com.au',
    url: 'www.bunningsport.com.au',
    abn: '51 123 123 123',
    location: '123.123, 234.234',
    locationAccuracy: null,
  },
  payerDetails: {
    accountDetails: {
      bsb: '123456',
      account: '12345 56789',
      name: 'Name',
    },
    debitCardAccountUuid: null,
    debitCardAccount: null,
    paymentInstrumentUuid: '12345-12345',
    senderUuid: null,
  },
  payeeDetails: {
    accountDetails: {
      bsb: '123456',
      account: '12345 56789',
      name: 'Name1',
    },
    bpayDetails: {
      nickname: 'Name1 - nick',
      billerName: 'Electric Company',
      billerCode: '123456',
      crn: '123456',
    },
    debitCardAccountUuid: null,
    debitCardAccount: null,
    paymentInstrumentUuid: '12345-12345',
  },
  status: DebitCardTransactionStatusV2.APPROVED,
  reference: 'This is reference',
  referencePayee: 'Payee Reference',
  timestamp: '2021-03-10T19:16:41.000Z',
  type: DebitCardTransactionTypeV2.PURCHASE,
  debitCardAccountUuid: mockAccount.id,
  debitCardId: null,
  debitCardMaskedPan: '9999',
  category: EntityCategories.BANK_FEES,
  subcategory: '1',
  note: null,
  tags: ['staff', 'christmas party', 'non-refundable', 'norwegian', 'no name'],
  subcategoryDetails: null,
  deposit: {
    id: '1',
    siteName: 'Site-1',
  },
}

export const contact: Contact = {
  __typename: 'Contact',
  abn: '38167106176',
  acn: null,
  address: {
    country: 'Australia',
    postcode: '2000',
    state: 'NSW',
    street: '',
    suburb: 'Sydney',
  },
  additionalEmails: null,
  additionalPhones: null,
  businessName: 'Square Up',
  category: EntityCategories.ADVERTISING,
  contacts: [],
  contactType: ContactType.BUSINESS,
  email: {
    email: 'help@squareup.com',
    label: null,
  },
  firstName: null,
  id: 'f4614fc5-53f0-4bdd-b752-83929966b074',
  jobTitle: null,
  lastName: null,
  notes: null,
  isSelf: null,
  paymentInstruments: [],
  phone: '',
  phoneV2: {
    phone: '1800 760 137',
    label: null,
  },
  subcategory: null,
  subcategoryUuid: '1',
  tags: [],
  website: 'https://squareup.com/au',
  location: '-33.870846, 151.20733',
  locationAccuracy: null,
  icon: {
    images: [
      {
        size: ImageSize.Thumbnail,
        url: 'https://dashboard.myzeller.dev/contact/assets/b9b733e4-4a81-4076-a4d5-26fc3dfc41e5-thumbnail.png',
      },
      {
        size: ImageSize.Small,
        url: 'https://dashboard.myzeller.dev/contact/assets/b9b733e4-4a81-4076-a4d5-26fc3dfc41e5-small.png',
      },
      {
        size: ImageSize.Medium,
        url: 'https://dashboard.myzeller.dev/contact/assets/b9b733e4-4a81-4076-a4d5-26fc3dfc41e5-medium.png',
      },
      {
        size: ImageSize.Large,
        url: 'https://dashboard.myzeller.dev/contact/assets/b9b733e4-4a81-4076-a4d5-26fc3dfc41e5-large.png',
      },
      {
        size: ImageSize.Native,
        url: 'https://dashboard.myzeller.dev/contact/assets/b9b733e4-4a81-4076-a4d5-26fc3dfc41e5-native.png',
      },
    ],
    letter: 'S',
    colour: null,
  },
}

export const enrichedContactTransaction: DebitCardTransaction = {
  ...enrichedTransaction,
  contact,
}

export const unenrichedTransaction: DebitCardTransaction = {
  __typename: 'DebitCardTransactionV2',
  attachments: [],
  amount: {
    value: '-42000',
  },
  cardAcceptorName: 'cardAcceptorName',
  debitCardName: 'MasterCard 2',
  id: '1',
  contact: null,
  description: null,
  merchant: {
    __typename: 'MerchantDetails',
    id: '1',
    name: 'Name',
    icon: null,
    address: null,
    hours: null,
    phone: null,
    email: null,
    url: null,
    abn: null,
    location: null,
    locationAccuracy: null,
  },
  payerDetails: null,
  payeeDetails: null,
  status: DebitCardTransactionStatusV2.APPROVED,
  reference: '',
  referencePayee: null,
  timestamp: '2021-04-15T16:55:30.213Z',
  type: DebitCardTransactionTypeV2.PURCHASE,
  debitCardAccountUuid: '1',
  debitCardId: null,
  debitCardMaskedPan: null,
  category: null,
  subcategory: null,
  note: null,
  tags: null,
  subcategoryDetails: null,
  deposit: null,
}

export const enrichedCardTransaction: DebitCardTransaction = {
  ...enrichedTransaction,
  debitCardName: 'Test Card Name',
  type: DebitCardTransactionTypeV2.PURCHASE,
  debitCardMaskedPan: '9999',
}

export const getContactMock: MockedResponse<GetContactQueryResponse> = {
  request: {
    query: GetContact,
    variables: {
      id: contact.id,
    },
  },
  result: {
    data: {
      getContact: contact,
    },
  },
}

export const getContactGraphQLErrorMock: MockedResponse<GetContactQueryResponse> =
  {
    request: {
      query: GetContact,
      variables: {
        id: contact.id,
      },
    },
    result: {
      errors: [new GraphQLError('test')],
    },
  }

export const getContactNetworkErrorMock = {
  request: {
    query: GetContact,
    variables: {
      id: contact.id,
    },
  },
  error: new Error('test'),
}
