import { useTranslations } from '@npco/utils-translations'
import {
  Breadcrumb,
  ButtonFillIconLeft,
  COLOR,
  Flex,
  PageTemplate,
  useIsMobileResolution,
  useModalState,
} from '@npco/zeller-design-system'

import { ReactComponent as Plus } from 'assets/svg/plus.svg'
import { BREAKPOINT } from 'styles/breakpoints'

import { SearchIconButtonWithIndicator } from '../../../../components/SearchIconButtonWithIndicator/SearchIconButtonWithIndicator'
import { BusinessForm } from '../../Businesses/Business.form'
import { translations } from '../../Contacts.i18n'
import { useContactsContext } from '../../ContactsContext/ContactsContext'
import { PersonForm } from '../../People/Person.form'
import { AddContactModalMobile } from '../AddContactModalMobile/AddContactModalMobile'

export const ContactsPrimaryHeaderRowDeps = {
  AddContactModalMobile,
  BusinessForm,
  PersonForm,
  useContactsContext,
  useIsMobileResolution,
  useModalState,
  useTranslations,
}

export const ContactsPrimaryHeaderRow = () => {
  const {
    AddContactModalMobile,
    BusinessForm,
    PersonForm,
    useContactsContext,
    useIsMobileResolution,
    useModalState,
    useTranslations,
  } = ContactsPrimaryHeaderRowDeps

  const { isDefaultFilters, setIsFiltersVisible, hasContact } =
    useContactsContext()

  const isTabletResolution = useIsMobileResolution(BREAKPOINT.MD)

  const t = useTranslations(translations)

  const {
    isModalOpen: isAddPersonModalOpen,
    openModal: openAddPersonModal,
    closeModal: closeAddPersonModal,
  } = useModalState()

  const {
    isModalOpen: isAddBusinessModalOpen,
    openModal: openAddBusinessModal,
    closeModal: closeAddBusinessModal,
  } = useModalState()

  return (
    <PageTemplate.HeaderPrimaryRow>
      <Flex
        flexDirection="column"
        justifyContent="center"
        minHeight="48px"
        width="100%"
      >
        <Flex
          alignItems="center"
          gap="8px"
          justifyContent="space-between"
          width="100%"
        >
          <Flex flex={1} overflow="hidden">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Breadcrumb.Text>{t('contacts')}</Breadcrumb.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Flex>
          {hasContact && (
            <SearchIconButtonWithIndicator
              isActive={!isDefaultFilters}
              onClick={() => setIsFiltersVisible((prev) => !prev)}
            />
          )}
          {isTabletResolution ? (
            <AddContactModalMobile
              openAddBusinessModal={openAddBusinessModal}
              openAddPersonModal={openAddPersonModal}
            />
          ) : (
            <Flex gap="8px">
              <ButtonFillIconLeft
                dataTestId="contacts-add-business-button"
                onClick={() => openAddBusinessModal()}
                icon={Plus}
                iconEnabledColor={COLOR.WHITE}
              >
                {t('business')}
              </ButtonFillIconLeft>
              <ButtonFillIconLeft
                dataTestId="contacts-add-person-button"
                onClick={() => openAddPersonModal()}
                icon={Plus}
                iconEnabledColor={COLOR.WHITE}
              >
                {t('person')}
              </ButtonFillIconLeft>
            </Flex>
          )}
        </Flex>
        <BusinessForm
          isModalOpen={isAddBusinessModalOpen}
          closeModal={closeAddBusinessModal}
        />
        <PersonForm
          isModalOpen={isAddPersonModalOpen}
          closeModal={closeAddPersonModal}
        />
      </Flex>
    </PageTemplate.HeaderPrimaryRow>
  )
}
