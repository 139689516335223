import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type UnlinkPaymentInstrumentFromContactMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
  type: Types.PaymentInstrumentType
  contactUuid: Types.Scalars['ID']['input']
}>

export type UnlinkPaymentInstrumentFromContactMutationResponse = {
  __typename?: 'Mutation'
  unlinkPaymentInstrumentFromContact: boolean
}

export const UnlinkPaymentInstrumentFromContact = gql`
  mutation UnlinkPaymentInstrumentFromContact(
    $id: ID!
    $type: PaymentInstrumentType!
    $contactUuid: ID!
  ) {
    unlinkPaymentInstrumentFromContact(
      id: $id
      type: $type
      contactUuid: $contactUuid
    )
  }
` as unknown as TypedDocumentNode<
  UnlinkPaymentInstrumentFromContactMutationResponse,
  UnlinkPaymentInstrumentFromContactMutationVariables
>
export type UnlinkPaymentInstrumentFromContactMutationFn =
  Apollo.MutationFunction<
    UnlinkPaymentInstrumentFromContactMutationResponse,
    UnlinkPaymentInstrumentFromContactMutationVariables
  >

/**
 * __useUnlinkPaymentInstrumentFromContactMutation__
 *
 * To run a mutation, you first call `useUnlinkPaymentInstrumentFromContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkPaymentInstrumentFromContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkPaymentInstrumentFromContactMutation, { data, loading, error }] = useUnlinkPaymentInstrumentFromContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      contactUuid: // value for 'contactUuid'
 *   },
 * });
 */
export function useUnlinkPaymentInstrumentFromContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkPaymentInstrumentFromContactMutationResponse,
    UnlinkPaymentInstrumentFromContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnlinkPaymentInstrumentFromContactMutationResponse,
    UnlinkPaymentInstrumentFromContactMutationVariables
  >(UnlinkPaymentInstrumentFromContact, options)
}
export type UnlinkPaymentInstrumentFromContactMutationHookResult = ReturnType<
  typeof useUnlinkPaymentInstrumentFromContactMutation
>
export type UnlinkPaymentInstrumentFromContactMutationResult =
  Apollo.MutationResult<UnlinkPaymentInstrumentFromContactMutationResponse>
export type UnlinkPaymentInstrumentFromContactMutationOptions =
  Apollo.BaseMutationOptions<
    UnlinkPaymentInstrumentFromContactMutationResponse,
    UnlinkPaymentInstrumentFromContactMutationVariables
  >
