import { gql } from '@apollo/client'

export const GetItemManagementLocalState = gql`
  query GetItemManagementLocalState {
    local @client {
      itemManagement {
        categories {
          filterInput
          sortInput
        }
        items {
          filterInput
          sortInput
        }
        hasSeenItemOnboarding
      }
    }
  }
`
