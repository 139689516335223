import { useLocation } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'

import { ROOT } from 'const/routes'

import { translations } from './useItemsBreadcrumbs.i18n'

export const useItemsBreadcrumbs = () => {
  const location = useLocation()
  const t = useTranslations(translations)

  const itemManagementListPart = {
    name: t('items'),
    path: ROOT.PORTAL.ITEM_MANAGEMENT.path,
  }

  if (
    location.pathname.includes(ROOT.PORTAL.ITEM_MANAGEMENT.SETTINGS.relative)
  ) {
    return [
      itemManagementListPart,
      { name: t('settings'), path: ROOT.PORTAL.ITEM_MANAGEMENT.SETTINGS.path },
    ]
  }

  return [itemManagementListPart]
}
