import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CardAccountCellDebitCardV2FragmentDoc } from '../components/CardAccountCell/CardAccountCell.generated'
import { DebitCardBalanceCellDebitCardV2FragmentDoc } from '../components/CardBalanceCell/DebitCardBalanceCell.generated'
import { CardNameIconCellDebitCardV2FragmentDoc } from '../components/CardNameIconCell/CardNameIconCell.generated'
import { CardOwnerCellDebitCardV2FragmentDoc } from '../components/CardOwnerCell/CardOwnerCell.generated'

export type DebitCardsTableDebitCardV2Fragment = {
  __typename: 'DebitCardV2'
  id: string
  name: string
  owner: string | null
  status: Types.DebitCardStatus
  colour: Types.DebitCardColour
  maskedPan: string
  debitCardAccount: {
    __typename: 'DebitCardAccountV2'
    id: string
    name: string
    icon: { __typename?: 'Icon'; colour: string | null } | null
    balance: { __typename?: 'Money'; value: string } | null
  }
  customer: { __typename?: 'Customer'; id: string } | null
}

export const DebitCardsTableDebitCardV2FragmentDoc = gql`
  fragment DebitCardsTableDebitCardV2Fragment on DebitCardV2 {
    __typename
    ...CardAccountCellDebitCardV2Fragment
    ...DebitCardBalanceCellDebitCardV2Fragment
    ...CardNameIconCellDebitCardV2Fragment
    ...CardOwnerCellDebitCardV2Fragment
  }
  ${CardAccountCellDebitCardV2FragmentDoc}
  ${DebitCardBalanceCellDebitCardV2FragmentDoc}
  ${CardNameIconCellDebitCardV2FragmentDoc}
  ${CardOwnerCellDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<DebitCardsTableDebitCardV2Fragment, undefined>
