import { gql } from '@apollo/client'

export const XeroPaymentServices = gql`
  query XeroPaymentServices(
    $connection: ConnectionType = XERO_PAYMENT_SERVICES
  ) {
    xeroPaymentServices: getConnectionState(connectionType: $connection) {
      id
      status
      errorType
    }
  }
`
