import { useMutation } from '@apollo/client'

import {
  DeleteInvoice as DeleteInvoiceResponse,
  DeleteInvoiceVariables,
} from 'types/gql-types/DeleteInvoice'
import { GetInvoices as GetInvoicesResponse } from 'types/gql-types/GetInvoices'

import { GetInvoices } from '../../../../graphql/getInvoices'
import { DeleteInvoice } from '../../graphql/deleteInvoice'

export const useDeleteInvoice = () => {
  const [deleteInvoiceMutation, { loading: isDeletingInvoice }] = useMutation<
    DeleteInvoiceResponse,
    DeleteInvoiceVariables
  >(DeleteInvoice)

  const deleteInvoice = (referenceNumber: string, onUpdate: () => void) =>
    deleteInvoiceMutation({
      variables: {
        referenceNumber,
      },

      update: (cache) => {
        // call onUpdate here otherwise error for invoice not found will occur
        onUpdate()

        const cacheInvoices = cache.readQuery<GetInvoicesResponse>({
          query: GetInvoices,
        })

        if (cacheInvoices?.getInvoices?.nextToken !== undefined) {
          cache.writeQuery<GetInvoicesResponse>({
            query: GetInvoices,
            data: {
              getInvoices: {
                invoices:
                  cacheInvoices?.getInvoices?.invoices?.filter(
                    (invoice) => invoice.referenceNumber !== referenceNumber
                  ) || [],
                nextToken: cacheInvoices?.getInvoices?.nextToken,
              },
            },
          })
        }
      },
    })

  return {
    deleteInvoice,
    isDeletingInvoice,
  }
}
