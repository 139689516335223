import { ReactComponent as NoContactsIcon } from 'assets/svg/no-contacts.svg'

import { NoInitialResults } from '../NoInitialResults'
import { PlaceholderSize } from '../Placeholders.types'

interface NoContactsProps {
  description: string[]
  title: string
  variant?: PlaceholderSize
}

export const NoContacts = ({
  description,
  title,
  variant = PlaceholderSize.Large,
}: NoContactsProps) => {
  return (
    <NoInitialResults
      description={description}
      icon={<NoContactsIcon />}
      title={title}
      variant={variant}
    />
  )
}
