import { useLazyQuery, useMutation } from '@apollo/client'
import { CreateCustomerInput } from '@npco/mp-gql-types'
import { showApiErrorToast } from '@npco/zeller-design-system'
import {
  CreateCustomer,
  UpdateCustomer,
} from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/customer'
import { CheckForAdditionalEntityInfo } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/onboarding'

import {
  UpdateCustomer as UpdateCustomerType,
  UpdateCustomerVariables,
} from 'types/gql-types/UpdateCustomer'

interface UseCustomersApiArgs {
  onError?: () => void
  shouldFetchForAdditionalInfo?: boolean
}

export const useCustomersApi = ({
  onError,
  shouldFetchForAdditionalInfo,
}: UseCustomersApiArgs = {}) => {
  const [
    getAdditionalInfoNeeded,
    {
      data: additionalInfoNeeded,
      called: isAdditionalInfoCalled,
      loading: isAdditionalInfoNeededLoading,
    },
  ] = useLazyQuery<{
    checkForAdditionalEntityInfo: boolean
  }>(CheckForAdditionalEntityInfo, {
    onError: (err) => {
      showApiErrorToast(err)
      onError?.()
    },
  })

  const [createCustomers] = useMutation<
    { createCustomer: CreateCustomerInput[] },
    { input: CreateCustomerInput[] }
  >(CreateCustomer, {
    onCompleted: (responseData) => {
      if (responseData.createCustomer && shouldFetchForAdditionalInfo) {
        getAdditionalInfoNeeded()
      }
    },
    onError: (err) => {
      showApiErrorToast(err)
      onError?.()
    },
  })

  const [updateCustomer, { loading: isUpdatingCustomer }] = useMutation<
    UpdateCustomerType,
    UpdateCustomerVariables
  >(UpdateCustomer, {
    onCompleted: (result) => {
      if (!result.updateCustomer) {
        showApiErrorToast()
      }
    },
    onError: (err) => {
      showApiErrorToast(err)
    },
  })

  return {
    updateCustomer,
    createCustomers,
    getAdditionalInfoNeeded,
    additionalInfoNeeded,
    isAdditionalInfoCalled,
    isAdditionalInfoNeededLoading,
    isUpdatingCustomer,
  }
}
