import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type UpdateLabelMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
  labelText: Types.Scalars['String']['input']
}>

export type UpdateLabelMutationResponse = {
  __typename?: 'Mutation'
  updateLabel: boolean
}

export const UpdateLabel = gql`
  mutation UpdateLabel($id: ID!, $labelText: String!) {
    updateLabel(id: $id, labelText: $labelText)
  }
` as unknown as TypedDocumentNode<
  UpdateLabelMutationResponse,
  UpdateLabelMutationVariables
>
export type UpdateLabelMutationFn = Apollo.MutationFunction<
  UpdateLabelMutationResponse,
  UpdateLabelMutationVariables
>

/**
 * __useUpdateLabelMutation__
 *
 * To run a mutation, you first call `useUpdateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabelMutation, { data, loading, error }] = useUpdateLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      labelText: // value for 'labelText'
 *   },
 * });
 */
export function useUpdateLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLabelMutationResponse,
    UpdateLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateLabelMutationResponse,
    UpdateLabelMutationVariables
  >(UpdateLabel, options)
}
export type UpdateLabelMutationHookResult = ReturnType<
  typeof useUpdateLabelMutation
>
export type UpdateLabelMutationResult =
  Apollo.MutationResult<UpdateLabelMutationResponse>
export type UpdateLabelMutationOptions = Apollo.BaseMutationOptions<
  UpdateLabelMutationResponse,
  UpdateLabelMutationVariables
>
