import { gql } from '@apollo/client'

export const GetXeroSiteCustomers = gql`
  query GetXeroSiteCustomers {
    getXeroSiteSettings {
      __typename
      id
      name
      customers {
        id
        role
        firstname
        lastname
        __typename
      }
    }
  }
`
