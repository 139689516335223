import { createTranslations } from '@npco/utils-translations'

export const setupFlowTapToPayStageTranslations = createTranslations({
  title: 'Tap to Pay made simple with Zeller App.',
  description:
    'Accept contactless payments on your smartphone — no additional sign-up, app, or hardware needed.',
  point1: 'Accept contactless payments instantly.',
  point2: 'Email and SMS receipts, tipping and surcharging.',
  point3: 'One low rate for all cards, including American Express.',
  point4: 'Available on selected iPhone and Android smartphones.',
})
