import { useEffect } from 'react'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { useGetItemSettings } from 'hooks/useGetItemSettings/useGetItemSettings'

import {
  getItemCategoryColors,
  getItemCategoryNames,
  getPriceWithTax,
} from '../../../ItemManagement.utils'
import { useItemManagementContext } from '../../ItemManagementContext/ItemManagementContext'
import { ItemsTableData } from '../ItemsTable.types'
import { useItemsTableColumns } from './useItemsTableColumns'

export const initialItems = Array(50).fill({
  category: null,
  categoryColors: null,
  name: null,
  sku: null,
})

export const useItemsTable = () => {
  const {
    items: {
      data: items,
      filterInput,
      getItems,
      hasError: hasItemsError,
      hasNoInitialResults,
      hasNoResults,
      isDefaultFilters,
      isLoading: isLoadingItems,
      sortInput,
      isDefaultSort,
    },
    hasNoItemsAndCategories,
  } = useItemManagementContext()

  useEffect(() => {
    getItems({
      variables: {
        filter: filterInput,
        limit: 50,
        sort: sortInput,
        nextToken: undefined,
      },
    })
  }, [getItems, filterInput, sortInput])

  const {
    hasError: hasSettingsError,
    isLoading: isLoadingItemSettings,
    settings,
  } = useGetItemSettings()

  const { columns, columnVisibility } = useItemsTableColumns()

  const isLoading =
    hasNoInitialResults || isLoadingItems || isLoadingItemSettings

  const hasItemsAndSettings = items && settings

  const data: ItemsTableData[] =
    !isLoading && hasItemsAndSettings
      ? items.map((item) => {
          const categoryNames = getItemCategoryNames(item.categories)
          const categoryColors = getItemCategoryColors(item.categories)
          const price = getPriceWithTax({
            isTaxInclusive: settings.itemsTaxInclusive,
            price: item.price,
            taxes: item.taxes,
          }).format()

          return {
            categoryNames,
            categoryColors,
            id: item.id,
            name: item.name,
            price,
            sku: item.sku,
            referenceNumber: item.referenceNumber,
          }
        })
      : initialItems

  const table = useReactTable<ItemsTableData>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    meta: { isLoading },
    state: { columnVisibility },
  })

  const hasHeaderBasedFilters = Boolean(
    filterInput?.categoryUuidFilter?.categoryUuids?.length ??
      filterInput?.priceFilter?.columnName ??
      !isDefaultSort
  )
  return {
    hasError: hasItemsError || hasSettingsError,
    hasNoItemsAndCategories,
    hasNoResults,
    isDefaultFilters,
    hasHeaderBasedFilters,
    isLoading,
    table,
  }
}
