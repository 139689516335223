import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetItemSettingsQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type GetItemSettingsQueryResponse = {
  __typename?: 'Query'
  getItemSettings: {
    __typename: 'ItemSettings'
    entityUuid: string
    itemsTaxInclusive: boolean
    itemsApplyTax: boolean
  }
}

export const GetItemSettings = gql`
  query GetItemSettings {
    getItemSettings {
      __typename
      entityUuid
      itemsTaxInclusive
      itemsApplyTax
    }
  }
` as unknown as TypedDocumentNode<
  GetItemSettingsQueryResponse,
  GetItemSettingsQueryVariables
>

/**
 * __useGetItemSettingsQuery__
 *
 * To run a query within a React component, call `useGetItemSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetItemSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetItemSettingsQueryResponse,
    GetItemSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetItemSettingsQueryResponse,
    GetItemSettingsQueryVariables
  >(GetItemSettings, options)
}
export function useGetItemSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetItemSettingsQueryResponse,
    GetItemSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetItemSettingsQueryResponse,
    GetItemSettingsQueryVariables
  >(GetItemSettings, options)
}
export type GetItemSettingsQueryHookResult = ReturnType<
  typeof useGetItemSettingsQuery
>
export type GetItemSettingsLazyQueryHookResult = ReturnType<
  typeof useGetItemSettingsLazyQuery
>
export type GetItemSettingsQueryResult = Apollo.QueryResult<
  GetItemSettingsQueryResponse,
  GetItemSettingsQueryVariables
>
