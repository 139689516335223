import { useReactiveVar } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Flex, HeadingStyles, ToggleForm } from '@npco/zeller-design-system'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { Field, useFormikContext } from 'formik'

import { SettingsPayments } from 'types/settings'
import { SiteCache } from 'types/site'
import { IconsBar } from 'pages/Settings/SitePayments/SitePaymentForm/IconsBar/IconsBar'

import { getDisplaySurchargeFeePercentage } from '../../SitePayments.utils'
import { Warning } from '../Warning/Warning'
import { translations } from './Surcharge.i18n'
import * as styled from './Surcharge.styled'
import { SurchargeOptions } from './SurchargeOptions.fields'

interface Props {
  siteData: SiteCache
}

export const Surcharge = ({ siteData }: Props) => {
  const { values } = useFormikContext<SettingsPayments>()
  const t = useTranslations(translations)
  const entityDetails = useReactiveVar(rvEntityDetails)

  if (!entityDetails.canAcquire) {
    return (
      <>
        <styled.Title>{t('cardPaymentsTitle')}</styled.Title>
        <Warning />
      </>
    )
  }

  return (
    <>
      <styled.Title>{t('cardPaymentsTitle')}</styled.Title>
      <styled.Description>{t('cardPaymentsDescription')}</styled.Description>
      {Boolean(values.schemes?.length) && <IconsBar values={values.schemes} />}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        data-testid="surchargeFee"
        margin="24px 0"
      >
        <styled.Subtitle withStyles={HeadingStyles.H5}>
          {t('processingFee')}
        </styled.Subtitle>
        <styled.Subtitle withStyles={HeadingStyles.H5}>
          {getDisplaySurchargeFeePercentage(
            siteData?.surchargesTaxes?.feePercent
          )}
        </styled.Subtitle>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        data-testid="surchargeEnabled"
        margin="24px 0"
      >
        <styled.Subtitle withStyles={HeadingStyles.H5}>
          {t('cardPaymentsSurcharge')}
        </styled.Subtitle>
        <div>
          <Field
            component={ToggleForm}
            name="surchargeEnabled"
            value={values.surchargeEnabled}
          />
        </div>
      </Flex>
      {values.surchargeEnabled && (
        <SurchargeOptions surchargesTaxes={siteData?.surchargesTaxes} />
      )}
      <Flex alignItems="center" justifyContent="space-between" margin="16px 0">
        <styled.Subtitle withStyles={HeadingStyles.H5}>
          {t('restrictReportAcess')}
        </styled.Subtitle>
        <div>
          <Field
            component={ToggleForm}
            name="restrictReportAccessEnabled"
            value={values.restrictReportAccessEnabled}
          />
        </div>
      </Flex>
      <styled.Description>{t('restrictReportAcessDesc')}</styled.Description>
    </>
  )
}
