import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetCategoriesForItemsReassignQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input']
  filter: Types.InputMaybe<Types.GetCategoriesFilterInput>
  nextToken: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type GetCategoriesForItemsReassignQueryResponse = {
  __typename?: 'Query'
  getItemCategories: {
    __typename?: 'ItemCategoryConnection'
    nextToken: string | null
    categories: Array<{
      __typename: 'ItemCategory'
      id: string
      name: string
    }> | null
  }
}

export const GetCategoriesForItemsReassign = gql`
  query GetCategoriesForItemsReassign(
    $limit: Int!
    $filter: GetCategoriesFilterInput
    $nextToken: String
  ) {
    getItemCategories(limit: $limit, filter: $filter, nextToken: $nextToken) {
      categories {
        __typename
        id
        name
      }
      nextToken
    }
  }
` as unknown as TypedDocumentNode<
  GetCategoriesForItemsReassignQueryResponse,
  GetCategoriesForItemsReassignQueryVariables
>

/**
 * __useGetCategoriesForItemsReassignQuery__
 *
 * To run a query within a React component, call `useGetCategoriesForItemsReassignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesForItemsReassignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesForItemsReassignQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetCategoriesForItemsReassignQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCategoriesForItemsReassignQueryResponse,
    GetCategoriesForItemsReassignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCategoriesForItemsReassignQueryResponse,
    GetCategoriesForItemsReassignQueryVariables
  >(GetCategoriesForItemsReassign, options)
}
export function useGetCategoriesForItemsReassignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCategoriesForItemsReassignQueryResponse,
    GetCategoriesForItemsReassignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCategoriesForItemsReassignQueryResponse,
    GetCategoriesForItemsReassignQueryVariables
  >(GetCategoriesForItemsReassign, options)
}
export type GetCategoriesForItemsReassignQueryHookResult = ReturnType<
  typeof useGetCategoriesForItemsReassignQuery
>
export type GetCategoriesForItemsReassignLazyQueryHookResult = ReturnType<
  typeof useGetCategoriesForItemsReassignLazyQuery
>
export type GetCategoriesForItemsReassignQueryResult = Apollo.QueryResult<
  GetCategoriesForItemsReassignQueryResponse,
  GetCategoriesForItemsReassignQueryVariables
>
