import { ConfigType } from 'dayjs'

import {
  DATE_FULL_AND_TIME_FORMAT,
  DATE_FULL_DISPLAY_AND_TIME_FORMAT,
  DATE_FULL_DISPLAY_FORMAT,
  DATE_FULL_SAVE_FORMAT,
  DATE_MMM_FORMAT,
  DATE_YEAR_MONTH_DISPLAY_FORMAT,
  DATE_YEAR_MONTH_SAVE_FORMAT,
} from 'const/date'
import dayjs from 'utils/dayjs'

import { translate } from './translations'

const fullDateFormat = 'D MMMM YYYY'

export const getDaySuffix = (day: number) => {
  if ([11, 12, 13].includes(day)) {
    return 'th'
  }

  const secondNumber = day % 10
  switch (secondNumber) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export const formatDate = (date: ConfigType): string => {
  const formattedDate = dayjs(date).utc().local().format(fullDateFormat)
  const dateToArray = formattedDate.split(' ')

  const day = `${dateToArray[0]}${getDaySuffix(Number(dateToArray[0]))}`
  const month = dateToArray[1]
  const year = dateToArray[2]
  return `${day} ${month} ${year}`
}

export const formatWeekday = (date: ConfigType) =>
  `${dayjs(date).utc().local().format('dddd')}`

export const formatWeekdayShort = (date: ConfigType) =>
  `${dayjs(date).utc().local().format('ddd')}`

export const formatDayWithSuffix = (day: string) =>
  `${day}${getDaySuffix(Number(day))}`

export const formatTime = (date: ConfigType) =>
  dayjs(date).utc().local().format('h:mma')

export const formatWeekdayDate = (date: ConfigType) =>
  dayjs(date).utc().local().format('D/MM/YYYY')

export const formatWeekdayDateTime = (date: ConfigType) =>
  `${formatWeekdayDate(date)} ${formatTime(date)}`

export const formatTimeDate = (date: ConfigType) =>
  dayjs(date).utc().local().format('h:mma, D MMMM YYYY')

export const formatToday = (
  date: string,
  formatFunction: (value: string) => string
): string => {
  const isToday =
    dayjs(date).utc().local().format(fullDateFormat) ===
    dayjs().utc().local().format(fullDateFormat)

  if (isToday) {
    return translate('shared.today')
  }

  return formatFunction(date)
}

export const formatDayMonth = (date: ConfigType) =>
  dayjs(date).utc().local().format('D MMM')

export const formatWeekdayHour = (date: ConfigType): string =>
  dayjs(date).utc().local().format('dddd h:mma')

export const parseToDisplayFullDate = (date?: ConfigType | null) =>
  date ? dayjs(date).format(DATE_FULL_DISPLAY_FORMAT) : ''

export const parseToSaveFullDate = (date?: ConfigType | null) =>
  date
    ? dayjs(date, DATE_FULL_DISPLAY_FORMAT).format(DATE_FULL_SAVE_FORMAT)
    : undefined

export const parseToDisplayYearMonthDate = (date?: ConfigType | null) =>
  date ? dayjs(date).format(DATE_YEAR_MONTH_DISPLAY_FORMAT) : ''

export const parseToSaveYearMonthDate = (date?: ConfigType | null) =>
  date
    ? dayjs(date, DATE_YEAR_MONTH_DISPLAY_FORMAT).format(
        DATE_YEAR_MONTH_SAVE_FORMAT
      )
    : undefined

export const formatDateTime = (date?: ConfigType | null) =>
  date ? dayjs(date).format(DATE_FULL_DISPLAY_AND_TIME_FORMAT) : ''

export const formatDateFullAndTime = (date: ConfigType) =>
  dayjs(date).utc().local().format(DATE_FULL_AND_TIME_FORMAT)

export const formatDateMonthYear = (date: ConfigType) =>
  dayjs(date).utc().local().format(DATE_MMM_FORMAT)

export const formatDateFullSaveFormat = (date: ConfigType) =>
  dayjs(date).utc().local().format(DATE_FULL_SAVE_FORMAT)

export const fromUnixTime = (unixTime: number): dayjs.Dayjs =>
  dayjs.unix(unixTime)
