import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type UpdateItemSettingsMutationVariables = Types.Exact<{
  input: Types.ItemSettingsUpdateInput
}>

export type UpdateItemSettingsMutationResponse = {
  __typename?: 'Mutation'
  updateItemSettings: {
    __typename: 'ItemSettings'
    entityUuid: string
    itemsApplyTax: boolean
    itemsTaxInclusive: boolean
  }
}

export const UpdateItemSettings = gql`
  mutation UpdateItemSettings($input: ItemSettingsUpdateInput!) {
    updateItemSettings(input: $input) {
      __typename
      entityUuid
      itemsApplyTax
      itemsTaxInclusive
    }
  }
` as unknown as TypedDocumentNode<
  UpdateItemSettingsMutationResponse,
  UpdateItemSettingsMutationVariables
>
export type UpdateItemSettingsMutationFn = Apollo.MutationFunction<
  UpdateItemSettingsMutationResponse,
  UpdateItemSettingsMutationVariables
>

/**
 * __useUpdateItemSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateItemSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemSettingsMutation, { data, loading, error }] = useUpdateItemSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateItemSettingsMutationResponse,
    UpdateItemSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateItemSettingsMutationResponse,
    UpdateItemSettingsMutationVariables
  >(UpdateItemSettings, options)
}
export type UpdateItemSettingsMutationHookResult = ReturnType<
  typeof useUpdateItemSettingsMutation
>
export type UpdateItemSettingsMutationResult =
  Apollo.MutationResult<UpdateItemSettingsMutationResponse>
export type UpdateItemSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateItemSettingsMutationResponse,
  UpdateItemSettingsMutationVariables
>
