import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import {
  ModalForm,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { Form, Formik } from 'formik'

import { ROOT } from 'const/routes'
import { getRandomItemColour } from 'components/ItemCategories'
import { translationsShared } from 'translations'

import { useItemsAnalytics } from '../../hooks/useItemsAnalytics'
import { CategoryModalFormFields } from '../CategoryModalFormFields/CategoryModalFormFields'
import { initialValues as defaultInitialValues } from './CategoryCreateModal.constants'
import { translations } from './CategoryCreateModal.i18n'
import { CategoryFormFields } from './CategoryCreateModal.types'
import { useAddItemCategoryMutation } from './graphql/addItemCategory.generated'
import { useValidation } from './hooks/useValidation'

interface CategoryCreateModalProps {
  initialValues?: CategoryFormFields
  onClose?: () => void
  onCreate?: (category: { id: string; name: string }) => void
}

export const CategoryCreateModal = ({
  initialValues = { ...defaultInitialValues, color: getRandomItemColour() },
  onClose: handleCloseModal,
  onCreate: handleCreateCategory,
}: CategoryCreateModalProps) => {
  const { trackAddCategory } = useItemsAnalytics()

  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const validationSchema = useValidation()

  const navigate = useNavigate()
  const location = useLocation()
  const [addItemCategory, { loading: isAddingItemCategory }] =
    useAddItemCategoryMutation()

  const handleClose = useCallback(() => {
    if (handleCloseModal) {
      handleCloseModal()
      return
    }
    navigate(
      location.pathname.includes('categories')
        ? ROOT.PORTAL.ITEM_MANAGEMENT.CATEGORIES.path
        : ROOT.PORTAL.ITEM_MANAGEMENT.ITEMS.path
    )
  }, [handleCloseModal, location.pathname, navigate])

  const handleOnSubmit = useCallback(
    (formValues: CategoryFormFields) => {
      async function sendRequest() {
        const result = await addItemCategory({
          variables: {
            category: {
              ...formValues,
              name: formValues.name.trim(),
            },
          },
        })

        if (result.data?.createItemCategory) {
          handleCreateCategory?.(result.data.createItemCategory)
          trackAddCategory(formValues)

          showSuccessToast(t('successNotification'))
          handleClose()
        } else {
          showErrorToast(t('errorNotification'))
        }
      }

      sendRequest().catch(() => showErrorToast(t('errorNotification')))
    },
    [addItemCategory, handleClose, handleCreateCategory, t, trackAddCategory]
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit: handleFormikSubmit,
        submitForm: handleFormikSubmitForm,
      }) => (
        <Form data-testid="category-create-form" onSubmit={handleFormikSubmit}>
          <ModalForm
            isOpen
            isLoading={isAddingItemCategory}
            isPrimaryButtonDisabled={isAddingItemCategory}
            onCancel={handleClose}
            onClickPrimary={() => {
              handleFormikSubmitForm().catch(() => undefined)
            }}
            primaryButtonLabel={tShared('save')}
            secondaryButtonLabel={tShared('cancel')}
            testId="item-add-modal"
            title={t('heading')}
          >
            <CategoryModalFormFields />
          </ModalForm>
        </Form>
      )}
    </Formik>
  )
}
