import { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetThirdPartyBankAccounts } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/deposits'
import { rvThirdPartyAccounts } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { THIRD_PARTY_ACCOUNTS_PAGE_SIZE } from 'const/common'
import { ThirdPartyBankAccount } from 'types/accounts'
import {
  GetThirdPartyBankAccounts as GetThirdPartyBankAccountsType,
  GetThirdPartyBankAccountsVariables,
} from 'types/gql-types/GetThirdPartyBankAccounts'

export const useGetThirdPartyAccounts = () => {
  const pageSize = THIRD_PARTY_ACCOUNTS_PAGE_SIZE
  const variables: GetThirdPartyBankAccountsVariables = { limit: pageSize }

  const hasCachedThirdPartyAccounts = rvThirdPartyAccounts().length > 0

  const { data, loading } = useQuery<
    GetThirdPartyBankAccountsType,
    GetThirdPartyBankAccountsVariables
  >(GetThirdPartyBankAccounts, {
    fetchPolicy: 'cache-first',
    skip: hasCachedThirdPartyAccounts,
    variables,
    onError: (error) => {
      showApiErrorToast(error)
    },
    notifyOnNetworkStatusChange: true,
  })

  const thirdPartyAccounts = useMemo(() => {
    if (!data && !hasCachedThirdPartyAccounts) {
      return null
    }
    if (hasCachedThirdPartyAccounts) {
      return rvThirdPartyAccounts()
    }

    const accountsFromApi = data?.getThirdPartyBankAccounts
      .accounts as Array<ThirdPartyBankAccount>

    return [...accountsFromApi]
  }, [data, hasCachedThirdPartyAccounts])

  useEffect(() => {
    if (thirdPartyAccounts && !hasCachedThirdPartyAccounts) {
      rvThirdPartyAccounts(thirdPartyAccounts)
    }
  }, [thirdPartyAccounts, hasCachedThirdPartyAccounts])

  return {
    thirdPartyAccounts,
    isLoading: loading,
  }
}
