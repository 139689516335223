import { Navigate, Outlet } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { ROOT } from 'const/routes'

interface PrivateRoutesProps {
  allowedRoles: CustomerRole[]
  accessDeniedElement?: JSX.Element
}

const defaultRedirectRoute = ROOT.PORTAL.OVERVIEW.PAYMENTS.path

export const RoleGuardedRoutes = ({
  allowedRoles,
  accessDeniedElement = <Navigate to={defaultRedirectRoute} replace />,
}: PrivateRoutesProps) => {
  const { userRole } = useLoggedInUser()

  if (!allowedRoles.includes(userRole)) {
    return accessDeniedElement
  }

  return <Outlet />
}
