import { useCallback, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { showErrorToast } from '@npco/zeller-design-system'
import { GetReceiptSettingsLogoUploadUrl } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/sites'

import {
  GetReceiptSettingsLogoUploadUrl as GetReceiptSettingsLogoUploadUrlPayload,
  GetReceiptSettingsLogoUploadUrlVariables,
} from 'types/gql-types/GetReceiptSettingsLogoUploadUrl'
import { fetchUploadLogoToS3Bucket } from 'components/ModalUploadLogo/ModalUploadLogo.utils'
import { errorMessages } from 'translations'

export interface UseLogoS3BucketProps {
  file?: File
  id: string
  onSuccess: () => void
  onError: () => void
}

export const useLogoS3Bucket = ({
  file,
  id,
  onSuccess: handleOnSuccess,
  onError: handleOnError,
}: UseLogoS3BucketProps) => {
  const [isUploadingFileToS3, setIsUploadingFileToS3] = useState(false)

  const [upload] = useLazyQuery<
    GetReceiptSettingsLogoUploadUrlPayload,
    GetReceiptSettingsLogoUploadUrlVariables
  >(GetReceiptSettingsLogoUploadUrl, {
    fetchPolicy: 'network-only',
    onCompleted: (responseData) => {
      fetchUploadLogoToS3Bucket({
        s3BucketUrl: responseData.getReceiptSettingsLogoUploadUrl,
        file,
        onSuccess: () => {
          setIsUploadingFileToS3(false)
          handleOnSuccess()
        },
        onError: () => {
          setIsUploadingFileToS3(false)
          handleOnError()
        },
      })
    },
    onError: () => {
      showErrorToast(errorMessages.somethingWentWrong)
      setIsUploadingFileToS3(false)
      handleOnError()
    },
  })

  const uploadFileToS3Bucket = useCallback(() => {
    setIsUploadingFileToS3(true)

    upload({
      variables: {
        fileName: file?.name,
        siteUuid: id,
      },
    })
  }, [file, id, upload])

  return {
    isUploadingFileToS3,
    uploadFileToS3Bucket,
  }
}
