export const AUTH0_DOMAIN = import.meta.env.REACT_APP_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = import.meta.env.REACT_APP_AUTH0_CLIENT_ID
export const AUTH0_REDIRECT_URI = import.meta.env.REACT_APP_AUTH0_REDIRECT_URI
export const AUTH0_AUDIENCE = import.meta.env.REACT_APP_AUTH0_AUDIENCE
export const API_URI = import.meta.env.REACT_APP_API_URI
export const GOOGLE_API_KEY = import.meta.env.REACT_APP_GOOGLE_API_KEY
export const APP_VERSION = import.meta.env.REACT_APP_VERSION
export const REVIEW_TIME = import.meta.env.REACT_APP_REVIEW_TIME

export const SENTRY_DSN = import.meta.env.REACT_APP_SENTRY_DSN
export const STORYBLOK_TOKEN = import.meta.env.REACT_APP_STORYBLOK_TOKEN

export const ANALYTICS_SEGMENT_ACCESS_KEY = import.meta.env
  .REACT_APP_SEGMENT_ANALYTICS_KEY

export const GTM_AUTH = import.meta.env.REACT_APP_GTM_AUTH
export const GTM_ID = import.meta.env.REACT_APP_GTM_ID
export const GTM_PREVIEW = import.meta.env.REACT_APP_GTM_PREVIEW

export const CONNECTION_XERO_BANKFEEDS_CLIENTID = import.meta.env
  .REACT_APP_CONNECTION_XERO_BANKFEEDS_CLIENTID

export const CONNECTION_XERO_PAYMENT_SERVICES_CLIENTID = import.meta.env
  .REACT_APP_CONNECTION_XERO_PAYMENT_SERVICES_CLIENTID

export const IS_DEVELOPMENT = import.meta.env.DEV === true
export const IS_TEST = import.meta.env.NODE_ENV === 'test'

export const IS_PRODUCTION = import.meta.env.PROD === true
export const IS_SENTRY_ENABLED =
  import.meta.env.REACT_APP_SENTRY_ENABLED === 'true'
export const IS_SEGMENT_ENABLED =
  import.meta.env.REACT_APP_SEGMENT_ENABLED === 'true'

export const SENTRY_ENVIRONMENT = import.meta.env.REACT_APP_SENTRY_ENVIRONMENT
export const SET_PIN_URL = import.meta.env.REACT_APP_SET_PIN_URL
export const SET_PIN_APPLICATION_ID = import.meta.env
  .REACT_APP_SET_PIN_APPLICATION_ID

export const ZELLER_SESSION_CLIENT_ID = import.meta.env
  .REACT_APP_ZELLER_SESSION_CLIENT_ID

export const ONBOARDING_QUESTIONNAIRE_FORM_ID = import.meta.env
  .REACT_APP_ONBOARDING_QUESTIONNAIRE_FORM_ID
export const ONBOARDING_QUESTIONNAIRE_PORTAL_ID = import.meta.env
  .REACT_APP_ONBOARDING_QUESTIONNAIRE_PORTAL_ID

// --- FEATURE FLAGS DEPRECATED ---

// Default all feature flags to be off i.e. ignore environment variables values.
if (import.meta.env.NODE_ENV === 'test') {
  Object.keys(import.meta.env).forEach((key) => {
    if (key.startsWith('REACT_APP_FF_')) {
      delete import.meta.env[key]
    }
  })
}

// Testing
export const FEATURE_FLAG__PLACEHOLDER = import.meta.env
  .REACT_APP_FF_PLACEHOLDER
export const FEATURE_FLAG__PLACEHOLDER_ALT = import.meta.env
  .REACT_APP_FF_PLACEHOLDER_ALT
export const FEATURE_FLAG__PLACEHOLDER_ARRAY = import.meta.env
  .REACT_APP_FF_PLACEHOLDER_ARRAY
