import { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import {
  ButtonLink,
  Ellipsize,
  Flex,
  Heading,
  InfoFilledIcon,
  SvgIcon,
  TooltipBasic,
  zIndexMap,
} from '@npco/zeller-design-system'

import { formatWeekdayDateTime, fromUnixTime } from 'utils/date'
import { translationsShared } from 'translations'

import { getPriceWithTax } from '../../../../ItemManagement.utils'
import { ItemDrawerDescription } from '../ItemDrawerDescription/ItemDrawerDescription'
import { ItemDeleteModal } from './ItemDeleteModal/ItemDeleteModal'
import { ItemDetailsFragment } from './ItemDrawerDetails.generated'
import { translations } from './ItemDrawerDetails.i18n'
import * as styled from './ItemDrawerDetails.styled'
import {
  getDisplayCategories,
  getDisplayTaxes,
} from './ItemDrawerDetails.utils'

interface ItemDrawerDetailsProps {
  details: ItemDetailsFragment
  isTaxInclusive: boolean
}

export const ItemDrawerDetails = ({
  details: item,
  isTaxInclusive,
}: ItemDrawerDetailsProps) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const navigate = useNavigate()

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const {
    name,
    description,
    sku,
    categories,
    price,
    taxes,
    createdTime,
    id,
    referenceNumber,
  } = item

  const getPriceTooltipText = () => {
    if (isTaxInclusive) return t('priceTooltipGstIncluded')
    return t('priceTooltipGstExcluded')
  }

  const isTaxEnabled = taxes?.length && taxes[0].enabled

  return (
    <Flex gridGap="40px" flexGrow={1} flexDirection="column" width="100%">
      <Flex flexDirection="column" gridGap="8px">
        <Flex justifyContent="space-between" alignItems="center" mb="12px">
          <Heading.H3>{t('heading')}</Heading.H3>
          <ButtonLink
            dataTestId="edt-details-button"
            onClick={() => navigate('edit')}
          >
            {tShared('edit')}
          </ButtonLink>
        </Flex>

        {/* SKU */}
        <Flex justifyContent="space-between" alignItems="center">
          <styled.FlexItemLabel>{t('sku')}</styled.FlexItemLabel>
          <Ellipsize>{sku ?? '-'}</Ellipsize>
        </Flex>

        {/* Category */}
        <Flex justifyContent="space-between">
          <styled.FlexItemLabel>
            {categories && categories.length > 1
              ? t('categoryLabel', { count: categories.length })
              : t('category')}
          </styled.FlexItemLabel>
          <styled.divItemValue>
            {getDisplayCategories(categories)}
          </styled.divItemValue>
        </Flex>

        {/* Price */}
        <Flex justifyContent="space-between" gridGap="4px">
          <styled.FlexItemLabel>
            <Flex alignItems="center" gridGap="4px">
              {t('price')}
              {isTaxEnabled && (
                <TooltipBasic
                  showArrow={false}
                  placement="top"
                  zIndex={Number(zIndexMap.tooltip)}
                  renderTrigger={({ ref, handlers }) => {
                    return (
                      <span {...handlers} ref={ref}>
                        <SvgIcon
                          dataTestId="price-tooltip-icon"
                          width="12"
                          height="12"
                        >
                          <InfoFilledIcon />
                        </SvgIcon>
                      </span>
                    )
                  }}
                >
                  <styled.divTooltipContent>
                    {getPriceTooltipText()}
                  </styled.divTooltipContent>
                </TooltipBasic>
              )}
            </Flex>
          </styled.FlexItemLabel>
          <styled.divItemValue>
            {getPriceWithTax({ price, isTaxInclusive, taxes }).format()}
          </styled.divItemValue>
        </Flex>

        {/* Default GST */}
        <Flex justifyContent="space-between">
          <styled.FlexItemLabel>{t('defaultGST')}</styled.FlexItemLabel>
          <styled.divItemValue>
            {getDisplayTaxes(t, taxes).toString()}
          </styled.divItemValue>
        </Flex>

        {/* Date Created */}
        <Flex justifyContent="space-between">
          <styled.FlexItemLabel>{t('dateCreated')}</styled.FlexItemLabel>
          <styled.divItemValue>
            {createdTime
              ? formatWeekdayDateTime(fromUnixTime(createdTime))
              : null}
          </styled.divItemValue>
        </Flex>

        {/* Item ID */}
        <Flex justifyContent="space-between">
          <styled.FlexItemLabel>{t('itemId')}</styled.FlexItemLabel>
          <styled.divItemValue>{referenceNumber}</styled.divItemValue>
        </Flex>
      </Flex>

      <ItemDrawerDescription description={description} id={id} />
      <styled.ButtonLink onClick={() => setDeleteModalOpen(true)}>
        {t('delete')}
      </styled.ButtonLink>

      <Outlet />
      {referenceNumber && isDeleteModalOpen && (
        <ItemDeleteModal
          name={name}
          isOpen={isDeleteModalOpen}
          referenceNumber={referenceNumber}
          onClose={() => setDeleteModalOpen(false)}
        />
      )}
    </Flex>
  )
}
