import { useCallback, useEffect } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Box, COLOR, CopyIcon, SvgIcon } from '@npco/zeller-design-system'
import { delay } from 'lodash-es'

import { copyToClipboard } from 'utils/common'

import { copyIconButtonTranslations } from './CopyIconButton.i18n'
import { ButtonWrapper } from './CopyIconButton.styled'

const TOOLTIP_DURATION = 1000

interface CopyIconButtonProps {
  id?: string
  valueToCopy?: string
  copySuccessMap?: Record<string, boolean>
  isTooltipVisible: boolean
  setIsTooltipVisible: (isVisible: boolean) => void
}

export const CopyIconButton = ({
  id,
  valueToCopy,
  copySuccessMap,
  isTooltipVisible,
  setIsTooltipVisible,
}: CopyIconButtonProps) => {
  const t = useTranslations(copyIconButtonTranslations)
  const showTooltip = useCallback(() => {
    if (isTooltipVisible) {
      return
    }
    setIsTooltipVisible(true)
    delay(() => setIsTooltipVisible(false), TOOLTIP_DURATION)
  }, [setIsTooltipVisible, isTooltipVisible])

  useEffect(() => {
    if (id && copySuccessMap?.[id]) {
      showTooltip()
    }
    // showTooltip change should not trigger tooltip re-appearing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, copySuccessMap])

  const handleCopy = useCallback(() => {
    if (valueToCopy) {
      copyToClipboard(valueToCopy)
      showTooltip()
    }
  }, [showTooltip, valueToCopy])

  return (
    <ButtonWrapper aria-label={t('ariaLabel')} onClick={handleCopy}>
      <SvgIcon
        width="16"
        height="16"
        color={COLOR.BLUE_1000}
        withCursor
        hoverColor={COLOR.BLUE_1000}
        alt={t('altText')}
      >
        <Box id={id}>
          <CopyIcon />
        </Box>
      </SvgIcon>
    </ButtonWrapper>
  )
}
