import { GraphQLError } from 'graphql'
import {
  ErrorInfoType,
  ErrorType,
  OVERDRAWN_LIMIT_EXCEEDED_ERROR,
  ServerError,
} from 'types/errors'

export const getIsResourceAlreadyExistsError = (
  graphQLErrors: readonly GraphQLError[]
) => {
  return graphQLErrors.some(
    ({ errorType }: ServerError) =>
      errorType === ErrorType.RESOURCE_ALREADY_EXISTS
  )
}

export const getIsOverdrawnLimitExceededError = (
  graphqlErrors?: readonly GraphQLError[]
) => {
  return graphqlErrors?.some(
    ({ errorType, errorInfo }: ServerError) =>
      errorType === ErrorType.SERVER_ERROR &&
      errorInfo?.type === ErrorInfoType.ZELLER_DECLINE &&
      errorInfo?.errorDescription === OVERDRAWN_LIMIT_EXCEEDED_ERROR
  )
}

export const getIsZellerDeclinedError = (
  graphqlErrors?: readonly GraphQLError[]
) => {
  return graphqlErrors?.some(
    ({ errorType, errorInfo }: ServerError) =>
      errorType === ErrorType.SERVER_ERROR &&
      errorInfo?.type === ErrorInfoType.ZELLER_DECLINE
  )
}

export const getIsTimedOutError = (graphqlErrors?: readonly GraphQLError[]) => {
  const TIMEOUT_TEXT = 'timed out'
  return graphqlErrors?.some(({ message }: ServerError) =>
    message.includes(TIMEOUT_TEXT)
  )
}
