const PORTAL_ACCOUNT_ID = 'accountId'

export const ACCOUNT = (accountId = `:${PORTAL_ACCOUNT_ID}`) => ({
  path: `/portal/accounts/${accountId}`,
  relative: accountId,
  TRANSACTIONS: {
    path: `/portal/accounts/${accountId}/transactions`,
    relative: `transactions`,
  },
  DETAILS: {
    path: `/portal/accounts/${accountId}/details`,
    relative: 'details',
  },
  CARDS: {
    path: `/portal/accounts/${accountId}/cards`,
    relative: 'cards',
  },
  EDIT: {
    path: `/portal/accounts/${accountId}/edit`,
    relative: 'edit',
  },
  CLOSE: {
    path: `/portal/accounts/${accountId}/close`,
    relative: 'close',
  },
})
