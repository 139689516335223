import { GraphQLError } from 'graphql'
import { ErrorType, ServerError } from 'types/errors'

import {
  ApiCallType,
  AppState,
  BpayTransferState,
  ContactTransferAppState,
  CreateAdminAppState,
  CreateBpayPaymentInstrumentState,
  CreateContactlessPaymentInstrument,
  CreatePaymentInstrumentAppState,
  TransferAppState,
  UpdateAdminAppState,
} from '../MFA.types'

export const getIsValidTransferAppState = (
  maybeAppState: object
): maybeAppState is TransferAppState => {
  return (maybeAppState as any).apiCallType === ApiCallType.Transfer
}

export const getIsValidContactTransferAppState = (
  maybeAppState: object
): maybeAppState is ContactTransferAppState => {
  return (maybeAppState as any).apiCallType === ApiCallType.ContactTransfer
}

export const getIsValidCreatePaymentInstrumentAppState = (
  maybeAppState: object
): maybeAppState is CreatePaymentInstrumentAppState => {
  return (
    (maybeAppState as any).apiCallType === ApiCallType.CreatePaymentInstrument
  )
}

export const getIsValidCreateAdminAppState = (
  maybeAppState: object
): maybeAppState is CreateAdminAppState => {
  return (maybeAppState as any).apiCallType === ApiCallType.CreateAdmin
}

export const getIsValidUpdateAdminAppState = (
  maybeAppState: object
): maybeAppState is UpdateAdminAppState => {
  return (maybeAppState as any).apiCallType === ApiCallType.UpdateAdmin
}

export const getIsValidCreateContactlessPaymentInstrumentAppState = (
  maybeAppState: object
): maybeAppState is CreateContactlessPaymentInstrument => {
  return (
    (maybeAppState as any).apiCallType ===
    ApiCallType.CreateContactlessPaymentInstrument
  )
}

export const getIsValidCreateBpayPaymentInstrumentState = (
  maybeAppState: object
): maybeAppState is CreateBpayPaymentInstrumentState => {
  return (
    (maybeAppState as any).apiCallType ===
    ApiCallType.CreateBpayPaymentInstrument
  )
}

export const getIsValidBPayTransferAppState = (
  maybeAppState: object
): maybeAppState is BpayTransferState => {
  return (maybeAppState as any).apiCallType === ApiCallType.BpayTransfer
}

const APP_STATE_VALIDATORS = [
  getIsValidTransferAppState,
  getIsValidContactTransferAppState,
  getIsValidCreatePaymentInstrumentAppState,
  getIsValidCreateBpayPaymentInstrumentState,
  getIsValidCreateContactlessPaymentInstrumentAppState,
  getIsValidCreateAdminAppState,
  getIsValidUpdateAdminAppState,
  getIsValidBPayTransferAppState,
]

export const getIsValidAppState = (
  maybeAppState: unknown
): maybeAppState is AppState => {
  if (typeof maybeAppState !== 'object' || maybeAppState === null) {
    return false
  }

  return APP_STATE_VALIDATORS.some((validate) => validate(maybeAppState))
}

export const getIsMFAError = (graphQLErrors: readonly GraphQLError[]) => {
  return Boolean(
    graphQLErrors.find((graphQLError) => {
      const serverError = graphQLError as ServerError

      return (
        serverError.errorType === ErrorType.MFA_REQUIRED ||
        serverError.errorType === ErrorType.MFA_SENSITIVE_ACCESS_EXPIRED
      )
    })
  )
}
