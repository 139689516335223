import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { TransactionStatus, TransactionType } from '@npco/mp-gql-types'
import { SvgIcon } from '@npco/zeller-design-system'

import { useSelectedTransaction } from 'hooks/useSelectedTransaction/useSelectedTransaction'
import { cardIcons } from 'const/payment-providers'
import { ROUTE } from 'const/routes'
import { getFormattedTransactionAmount } from 'utils/common'
import { TransactionInList } from 'types/transactions'
import { useSourcePicker } from 'pages/Dashboard/SourcePicker/useSourcePicker'
import { getTypeAndMaskedPan } from 'components/ListGroupTransactionItem/ListGroupTransactionItem.utils'

import {
  StyledWrapper,
  TransactionAmount,
} from './PaymentTransactionsListElement.styled'

interface Props {
  transaction: TransactionInList
}

export const PaymentTransactionsListElement = ({ transaction }: Props) => {
  const history = useHistory()
  const { selectedSources } = useSourcePicker()

  const { type, status, scheme: cardScheme } = transaction
  const typeAndMaskedPan = getTypeAndMaskedPan(transaction)
  const isUpdated = useMemo(
    () => 'subscriptionUpdatedAt' in transaction,
    [transaction]
  )

  const { onOpen } = useSelectedTransaction()

  const handleOnClick = () => {
    onOpen(transaction)

    history.push(ROUTE.PORTAL_PAYMENTS_TRANSACTIONS, {
      transaction,
      sources: selectedSources,
    })
  }

  return (
    <StyledWrapper
      isUpdated={isUpdated}
      onClick={handleOnClick}
      dataTestId="payment-transaction-item"
    >
      <SvgIcon width="40" height="40">
        {cardIcons[cardScheme]}
      </SvgIcon>
      <div>{typeAndMaskedPan}</div>
      <TransactionAmount
        isDeclined={status === TransactionStatus.DECLINED}
        isIncoming={type === TransactionType.PURCHASE}
      >
        {getFormattedTransactionAmount(transaction)}
      </TransactionAmount>
    </StyledWrapper>
  )
}
