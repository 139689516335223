import { CustomerRole } from '@npco/mp-gql-types'

import { ROUTE_PERMISSIONS } from 'components/PrivateRoute/PrivateAdminRoute/routesPermissions'

const routeHasPermissionAssigned = (route: string) =>
  Object.keys(ROUTE_PERMISSIONS).includes(route)

const routeHasPermission = (route: string, role: CustomerRole) =>
  ROUTE_PERMISSIONS[route].includes(role)

export const routeCanBeAccessed = (
  route: string | undefined,
  role: CustomerRole
) => {
  if (!route) {
    return true
  }
  if (routeHasPermissionAssigned(route)) {
    return routeHasPermission(route, role)
  }
  return true
}
