import { MutableRefObject, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { DATE_FULL_DISPLAY_FORMAT } from '@npco/component-mp-common'
import { InvoiceStatus } from '@npco/mp-gql-types'
import { showErrorToast } from '@npco/zeller-design-system'
import currency from 'currency.js'
import { useGetContact } from 'features/Contacts/hooks/useGetContact/useGetContact'
import { setNestedObjectValues, useFormikContext } from 'formik'
import { omit } from 'ramda'

import { ROOT } from 'const/routes'
import dayjs from 'utils/dayjs'
import { translate } from 'utils/translations'

import { GetZellerInvoiceSettingsQueryResponse } from '../../../../../Settings/graphql/getZellerInvoiceSettings.generated'
import { useGetInvoice } from '../../../../hooks/useGetInvoice'
import { useSubmitFormValidation } from '../../../hooks/useSubmitFormValidation'
import {
  INVOICE_MAX_AMOUNT_IN_CENTS,
  INVOICE_MIN_AMOUNT_IN_CENTS,
} from '../../../Invoice.constants'
import { InvoiceFormFields, PaymentTerms } from '../../../Invoice.types'
import { convertGetInvoiceResponseToFormFields } from '../../InvoiceEditForm/InvoiceEditForm.utils'
import { useGetInvoiceItemSettings } from './useGetInvoiceItemSettings'

type UseInvoiceCreateFormContentProps = {
  defaultInitialValuesRef: MutableRefObject<InvoiceFormFields>
  invoiceSettings?: GetZellerInvoiceSettingsQueryResponse['getZellerInvoiceSettings']
  isSendingInvoiceRef: MutableRefObject<boolean>
  isInvoiceScheduled?: boolean
}

export const useInvoiceCreateFormContent = ({
  defaultInitialValuesRef,
  invoiceSettings,
  isInvoiceScheduled,
  isSendingInvoiceRef,
}: UseInvoiceCreateFormContentProps) => {
  const { refetch } = useGetContact()

  const history = useHistory()

  const { state } = useLocation<{
    duplicateReferenceNumber: string | undefined
  }>()

  const duplicateReferenceNumber = state?.duplicateReferenceNumber

  const handleInvoiceNotRetrieved = () => {
    showErrorToast(
      translate('page.invoice.duplicateInvoiceError', {
        referenceNumber: duplicateReferenceNumber,
      })
    )

    history.push(
      ROOT.PORTAL.INVOICING.INVOICES.INVOICE(duplicateReferenceNumber).path
    )
  }

  // NOTE: Although we are in the create new invoice form, we allow users to
  // duplicate existing invoices meaning we must first fetch the values and
  // then reset the forms default state
  const { invoiceData, isLoading: isLoadingInvoice } = useGetInvoice({
    referenceNumber: duplicateReferenceNumber ?? '',
    onCompleted: (data) => {
      if (!data.getInvoice) {
        handleInvoiceNotRetrieved()
      }
    },
    onError: handleInvoiceNotRetrieved,
  })

  const { resetForm, status, validateForm } =
    useFormikContext<InvoiceFormFields>()

  const {
    getInvoiceItemSettings,
    settings: itemSettings,
    isLoading,
  } = useGetInvoiceItemSettings()

  useSubmitFormValidation({
    isSendingInvoice: isSendingInvoiceRef.current,
    isInvoiceScheduled,
  })

  useEffect(() => {
    if (!duplicateReferenceNumber) {
      // NOTE: if request fails we fallback to the default initial values
      getInvoiceItemSettings()
    }
  }, [getInvoiceItemSettings, duplicateReferenceNumber])

  // NOTE: this useEffect handles resetting form values from invoice and item
  // settings once the latest values are returned
  useEffect(() => {
    if (!invoiceSettings || !itemSettings || status.hasAppliedSettings) {
      return
    }

    const isEmailEnabledByDefault = !!invoiceSettings.invoice.sendEmailByDefault

    const isSMSEnabledByDefault = !!invoiceSettings.invoice.sendSmsByDefault

    const maximumPaymentLimit =
      invoiceSettings?.paymentLimits?.maximum ?? INVOICE_MAX_AMOUNT_IN_CENTS

    const minimumPaymentLimit =
      invoiceSettings?.paymentLimits?.minimum ?? INVOICE_MIN_AMOUNT_IN_CENTS

    const nextInitialValues: InvoiceFormFields = {
      ...defaultInitialValuesRef.current,
      discountsEnabled: invoiceSettings.invoice.discountsEnabled ?? false,
      itemsTaxInclusive: itemSettings.itemsTaxInclusive,
      itemsApplyTax: itemSettings.itemsApplyTax,
      items: defaultInitialValuesRef.current.items.map((item) => ({
        ...item,
        taxApplicable: itemSettings.itemsApplyTax,
      })),
      title: defaultInitialValuesRef.current.title,
      delivery: {
        ...defaultInitialValuesRef.current.delivery,
        email: {
          ...defaultInitialValuesRef.current.delivery.email,
          isEnabled: isEmailEnabledByDefault,
        },
        sms: {
          ...defaultInitialValuesRef.current.delivery.sms,
          isEnabled: isSMSEnabledByDefault,
        },
      },
      // NOTE: maximum and minimum values returned as cents
      maximumLimit: currency(maximumPaymentLimit, {
        fromCents: true,
        precision: 2,
      }),
      minimumLimit: currency(minimumPaymentLimit, {
        fromCents: true,
        precision: 2,
      }),
    }

    // NOTE: update status property so we only apply invoice and item settings once
    const nextInitialStatus = {
      ...status,
      hasAppliedSettings: true,
    }

    // NOTE: once we have both the item and invoice settings we must update the
    // default initial values so that when creating a new invoice, resetting or
    // validating form values we have the latest values
    // eslint-disable-next-line no-param-reassign
    defaultInitialValuesRef.current = nextInitialValues

    resetForm({
      status: nextInitialStatus,
      values: nextInitialValues,
    })
  }, [
    defaultInitialValuesRef,
    invoiceSettings,
    itemSettings,
    resetForm,
    status,
  ])

  // NOTE: this useEffect handles resetting form values from an existing (duplicated)
  // invoice once the values are returned
  useEffect(() => {
    const invoice = invoiceData?.getInvoice ?? null

    if (!invoice || !invoiceSettings || status.hasAppliedInvoice) {
      return
    }

    if (invoice.customer?.payerContact?.contactUuid) {
      refetch(invoice.customer.payerContact.contactUuid)
    }

    const editInitialValues = convertGetInvoiceResponseToFormFields(
      invoice,
      invoiceSettings
    )

    // NOTE: update status property so we only apply a duplicated invoice once
    const nextInitialStatus = {
      ...status,
      hasAppliedInvoice: true,
    }

    const nextInitialValues = {
      ...editInitialValues,
      items: editInitialValues.items.map((item) => omit(['id'])(item)),
      schedule: {
        invoiceDate: dayjs().format(DATE_FULL_DISPLAY_FORMAT),
        dueDate: dayjs().format(DATE_FULL_DISPLAY_FORMAT),
        paymentTerms: PaymentTerms.Immediate,
        sendEnabled: false,
        sendDate: '',
      },
      referenceNumber: '',
      status: InvoiceStatus.DRAFT,
    }

    // NOTE: once we have the invoice settings we must update the default
    // initial values so that when creating a new invoice, resetting or
    // validating form values we have the latest values
    // eslint-disable-next-line no-param-reassign
    defaultInitialValuesRef.current = nextInitialValues

    resetForm({
      errors: {},
      values: nextInitialValues,
      status: nextInitialStatus,
      touched: setNestedObjectValues(editInitialValues, true),
    })

    // NOTE: resetting the form does not revalidate the form so call directly
    // to ensure any validation errors will be reset with the latest values
    validateForm(nextInitialValues)
  }, [
    defaultInitialValuesRef,
    invoiceData?.getInvoice,
    invoiceSettings,
    refetch,
    resetForm,
    status,
    validateForm,
  ])

  return {
    isLoading: isLoadingInvoice || isLoading,
    invoiceData,
  }
}
