import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  buttonLabel: 'Delete Category',
  categoriesLabel: 'Category',
  categoriesRequiredError:
    'Select an existing category from the dropdown or create new.',
  categoriesSelectionRequiredError:
    'Select an existing category from the dropdown or create new.',
  error: '{name} could not be deleted. Please try again.',
  itemLabelPlural: 'these items',
  itemLabelSingular: 'this item',
  modalDescription:
    'If you delete the "{name}" category, {items} will not have a category. To continue, please assign a new category for {itemLabel}:',
  modalDescriptionConfirm:
    'Are you sure you want to delete this category? This action cannot be undone.',
  modalTitle: 'Delete Category',
  itemsCountPlural: '{count} items',
  itemsCountSingular: '1 item',
  success: '{name} has been deleted.',
  viewMoreItems: 'View {itemsCount} more items',
})
