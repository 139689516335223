import { useDeleteAllItemsCategoriesMutation } from '../graphql/deleteAllItemsCategories.generated'

export const useDeleteAllItemsCategories = () => {
  const [deleteAllItemsCategories, { loading: isDeletingAllItemsCategories }] =
    useDeleteAllItemsCategoriesMutation({
      update: (cache) => {
        cache.modify({
          broadcast: false,
          fields: {
            getItems: (existingItemRef) => ({
              ...existingItemRef,
              items: [],
              nextToken: null,
            }),
            getItemCategories: (existingItemRef) => ({
              ...existingItemRef,
              categories: [],
              nextToken: null,
            }),
          },
        })
        cache.gc()
      },
    })

  return {
    deleteAllItemsCategories,
    isDeletingAllItemsCategories,
  }
}
