import { useQuery } from '@apollo/client'
import { currencyFormatter } from '@npco/component-mp-common'

import * as GetEntityDailyLimit from './GetEntityDailyLimit'

export const useGetEntityDailyLimit = () => {
  const { data, loading, error } = useQuery<GetEntityDailyLimit.QueryData>(
    GetEntityDailyLimit.query
  )

  // money value comes back with a string of number, including cent. e.g. '10000' = $100.00
  // use currencyFormatter
  const dailyMaximumLimit = parseInt(
    data?.getEntityDailyLimit.maximumLimit.value || '0',
    10
  )

  const dailyMaximumLimitFormatted = currencyFormatter(dailyMaximumLimit)

  return { dailyMaximumLimit, dailyMaximumLimitFormatted, loading, error }
}
