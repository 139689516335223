import { gql } from '@apollo/client'

export const GetContactImageUploadForm = gql`
  query GetContactImageUploadForm($contactUuid: ID!) {
    getContactImageUploadForm(contactUuid: $contactUuid) {
      url
      formFields
    }
  }
`

export const GetContactTransactionTotals = gql`
  query GetContactTransactionTotals($contactUuid: ID!, $cardholderUuid: ID) {
    getContactTransactionTotals(
      contactUuid: $contactUuid
      cardholderUuid: $cardholderUuid
    ) {
      count
      latestTimestamp
      totalAmount
    }
  }
`

export const GetInvoiceDraftContactUsage = gql`
  query GetInvoiceDraftContactUsage($contactUuid: ID!) {
    getInvoiceDraftContactUsage(contactUuid: $contactUuid)
  }
`
