import { useCallback, useMemo, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'

import { FilterBySearch } from 'components/FilterBySearch/FilterBySearch'
import { MobileFilters } from 'components/MobileFilters/MobileFilters'

import { useItemManagementContext } from '../../ItemManagementContext/ItemManagementContext'
import { ItemManagementContextType } from '../../ItemManagementContext/ItemManagementContext.types'
import { translations } from './CategoriesMobileFilters.i18n'
import { CategoriesMobileFiltersVisibilityStates } from './CategoriesMobileFilters.types'
import { FilterByName } from './FilterByName/FilterByName'
import { FilterList } from './FilterList/FilterList'
import { SortByFilters } from './SortByFilters/SortByFilters'

type CategoriesFilterInputType =
  ItemManagementContextType['categories']['filterInput']

type CategoriesSortInputType =
  ItemManagementContextType['categories']['sortInput']

interface CategoriesMobileFiltersProps {
  initialVisibilityState?: CategoriesMobileFiltersVisibilityStates
}

export const CategoriesMobileFilters = ({
  initialVisibilityState = CategoriesMobileFiltersVisibilityStates.List,
}: CategoriesMobileFiltersProps) => {
  const {
    categories: {
      closeMobileFilters,
      filterInput,
      isMobileFiltersOpen,
      setFilterInput,
      setSortInput: handleNextSortInput,
      sortInput,
    },
    setTextSearchFilter,
  } = useItemManagementContext()

  const t = useTranslations(translations)

  const [visibilityState, setVisibilityState] =
    useState<CategoriesMobileFiltersVisibilityStates>(initialVisibilityState)

  const handleNextFilterInput = useCallback(
    (nextFilterInput: CategoriesFilterInputType) => {
      if (nextFilterInput === null) {
        setFilterInput(null)
        setTextSearchFilter('')
      } else {
        const { textSearchFilter, ...otherFilters } = nextFilterInput

        setFilterInput(otherFilters)
        setTextSearchFilter(textSearchFilter ?? '')
      }
    },
    [setFilterInput, setTextSearchFilter]
  )

  const title = useMemo(() => {
    if (visibilityState === CategoriesMobileFiltersVisibilityStates.List) {
      return t('filterAndSortCategories')
    }

    if (visibilityState === CategoriesMobileFiltersVisibilityStates.SortBy) {
      return t('sortBy')
    }

    if (
      visibilityState === CategoriesMobileFiltersVisibilityStates.FilterByName
    ) {
      return t('filterByName')
    }

    if (
      visibilityState ===
      CategoriesMobileFiltersVisibilityStates.FilterByKeyword
    ) {
      return t('filterByKeyword')
    }

    return t('filters')
  }, [t, visibilityState])

  const isDefaultVisibilityState =
    visibilityState === CategoriesMobileFiltersVisibilityStates.List

  const setDefaultVisibilityState = () =>
    setVisibilityState(CategoriesMobileFiltersVisibilityStates.List)

  return (
    <MobileFilters<CategoriesFilterInputType, CategoriesSortInputType>
      closeMobileFilters={closeMobileFilters}
      filterInput={filterInput}
      isDefaultVisibilityState={isDefaultVisibilityState}
      isMobileFiltersOpen={isMobileFiltersOpen}
      onNextFilterInput={handleNextFilterInput}
      onNextSortInput={handleNextSortInput}
      setDefaultVisibilityState={setDefaultVisibilityState}
      sortInput={sortInput}
      title={title}
    >
      {({
        nextFilterInput,
        nextSortInput,
        setNextFilterInput,
        setNextSortInput,
      }) => (
        <>
          {visibilityState === CategoriesMobileFiltersVisibilityStates.List && (
            <FilterList
              nextFilterInput={nextFilterInput}
              nextSortInput={nextSortInput}
              setVisibilityState={setVisibilityState}
            />
          )}
          {visibilityState ===
            CategoriesMobileFiltersVisibilityStates.SortBy && (
            <SortByFilters
              nextSortInput={nextSortInput}
              setDefaultVisibilityState={setDefaultVisibilityState}
              setNextSortInput={setNextSortInput}
            />
          )}
          {visibilityState ===
            CategoriesMobileFiltersVisibilityStates.FilterByName && (
            <FilterByName
              nextFilterInput={nextFilterInput}
              setDefaultVisibilityState={setDefaultVisibilityState}
              setNextFilterInput={setNextFilterInput}
            />
          )}
          {visibilityState ===
            CategoriesMobileFiltersVisibilityStates.FilterByKeyword && (
            <FilterBySearch<CategoriesFilterInputType>
              defaultValue={nextFilterInput?.textSearchFilter ?? ''}
              nextFilterInput={nextFilterInput}
              searchPlaceholder={t('searchItemOrCategory')}
              setDefaultVisibilityState={setDefaultVisibilityState}
              setNextFilterInput={setNextFilterInput}
            />
          )}
        </>
      )}
    </MobileFilters>
  )
}
