import { gql, TypedDocumentNode } from '@apollo/client'

import {
  GetDevicePosSettings as GetDevicePosSettingsResponse,
  GetDevicePosSettingsVariables,
} from 'types/gql-types/GetDevicePosSettings'

export const GetDevicePosSettings = gql`
  query GetDevicePosSettings($deviceUuid: ID!) {
    getDeviceSettings(deviceUuid: $deviceUuid) {
      id
      posSettings {
        active
        oracleSettings {
          workstationId
        }
        posSoftwareName
        posRegisterName
        exitRequiresPin
        posReceipt
        customIdleText
        customIdleTextUsed
        connectionMethod
        posVenue {
          name
          locations {
            name
            id
          }
        }
        hlSettings {
          billingSummary
        }
      }
    }
  }
` as TypedDocumentNode<
  GetDevicePosSettingsResponse,
  GetDevicePosSettingsVariables
>

export const GetDeviceScreenSettings = gql`
  query GetDeviceScreenSettings($deviceUuid: ID!) {
    getDeviceSettings(deviceUuid: $deviceUuid) {
      screen {
        brightness
        communicationsTimer
        inactivityTimer
        pinEntryTimer
        notHibernateWhenPluggedIn
        sleepEnabled
        sleepTimer
        standbyEnabled
        standbyTimer
        theme
      }
    }
  }
`

export const GetDeviceDetails = gql`
  query GetDeviceDetails($deviceUuid: ID!) {
    getDeviceSettings(deviceUuid: $deviceUuid) {
      id
      name
      deviceUser
      serial
      appVersion
      terminalConfig
      firmwareVersion
      posSettings {
        active
        oracleSettings {
          workstationId
        }
      }
      site {
        siteUuid
        name
      }
    }
  }
`
