import { gql } from '@apollo/client'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useExtendedColumnConfig } from '@npco/ui-table'
import { CellContext } from '@tanstack/react-table'

import { CardBalanceCell } from 'components/CardsView/components/CardsTable/components/CardBalanceCell/components/CardBalanceCell/CardBalanceCell'

import { DebitCardBalanceCellDebitCardV2Fragment } from './DebitCardBalanceCell.generated'

export const DebitCardBalanceCell = <T,>({
  cell,
  row,
  getValue,
  table,
  column,
}: CellContext<T, DebitCardBalanceCellDebitCardV2Fragment>) => {
  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isLoading = table.options.meta?.isLoading
  const data = isLoading
    ? undefined
    : getValue<DebitCardBalanceCellDebitCardV2Fragment>()

  const cardUserId = data?.customer?.id
  const currentUserId = useLoggedInUser().userData?.id
  const isOwner = !!(
    cardUserId &&
    currentUserId &&
    cardUserId === currentUserId
  )

  return (
    <CardBalanceCell
      card={data}
      cellId={cell.id}
      isLoading={isLoading}
      isActivateButtonEnabled={isOwner}
      skeletonPropWidth={skeletonLoaderWidth}
      cellSize={cellSize}
    />
  )
}

DebitCardBalanceCell.fragments = {
  DebitCardV2: gql`
    fragment DebitCardBalanceCellDebitCardV2Fragment on DebitCardV2 {
      id
      customer {
        id
      }
      ...CardBalanceCellDebitCardV2Fragment
    }
    ${CardBalanceCell.fragments.DebitCardV2}
  `,
}
