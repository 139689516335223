import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { ROUTE } from 'const/routes'

import { useInvoiceAnalytics } from '../../../components/Invoices/hooks/useInvoiceAnalytics'
import { useInvoicesContext } from '../../../components/Invoices/InvoicesContext/InvoicesContext'
import { AllStatusesSelected } from '../../../components/Invoices/InvoiceTable/InvoiceTable.utils'

export const useInvoicingListHandlers = () => {
  const { trackInvoiceInitiated } = useInvoiceAnalytics()

  const history = useHistory()

  const {
    filterInput,
    setIsMobileFiltersOpen,
    setSearchFilter,
    sortingObject,
  } = useInvoicesContext()

  const handleClickFilters = () => {
    setIsMobileFiltersOpen(true)
  }

  const handleClickInvoice = () => {
    trackInvoiceInitiated()
    history.push(ROUTE.PORTAL_INVOICES_INVOICE_CREATE)
  }

  const handleClickQuote = () =>
    history.push(ROUTE.PORTAL_INVOICES_QUOTE_CREATE)

  const handleClickSettings = () => history.push(ROUTE.PORTAL_INVOICES_SETTINGS)

  const handleClickRetry = () =>
    history.push(ROUTE.PORTAL_INVOICES_INVOICE_LIST)

  const handleSearch = (nextSearch: string) => {
    setSearchFilter(nextSearch)
  }

  const isMobileFiltersActive = useMemo(() => {
    if (
      filterInput?.amountFilter ||
      filterInput?.dateFilter ||
      filterInput?.statusFilter ||
      filterInput?.textSearchFilter
    ) {
      return (
        filterInput?.statusFilter?.values?.length !== AllStatusesSelected.length
      )
    }
    return !!sortingObject?.columnName
  }, [
    filterInput?.amountFilter,
    filterInput?.dateFilter,
    filterInput?.statusFilter,
    filterInput?.textSearchFilter,
    sortingObject,
  ])

  const searchDefaultValue = filterInput?.textSearchFilter || ''

  return {
    handleClickFilters,
    handleClickInvoice,
    handleClickQuote,
    handleClickRetry,
    handleClickSettings,
    handleSearch,
    isMobileFiltersActive,
    searchDefaultValue,
  }
}
