import { useCallback, useState } from 'react'
import * as Types from '@npco/mp-gql-types'
import { useIsMobileResolution } from '@npco/zeller-design-system'

import { useCashflowReportingSubcategoriesNetAmounts } from '../../hooks/useCashflowReportingSubcategoriesNetAmounts/useCashflowReportingSubcategoriesNetAmounts'
import { useSelectedAccountId } from '../../hooks/useSelectedAccount/useSelectedAccountId'
import { useSelectedDateRange } from '../../hooks/useSelectedDateRange'
import { convertToAWSDate } from '../../utils/convertToAWSDate/convertToAWSDate'
import { HoveredSubcategoryNetAmount } from './CategoryDrawerContent.type'
import { CategoryDrawerError } from './CategoryDrawerError/CategoryDrawerError'
import { CategoryDrawerPieChart } from './CategoryDrawerPieChart/CategoryDrawerPieChart'
import { CategoryDrawerTable } from './CategoryDrawerTable/CategoryDrawerTable'
import { CategoryDrawerTableCashFlowSubcategoryNetAmountFragment as SubcategoryNetAmount } from './CategoryDrawerTable/CategoryDrawerTable.generated'

export interface CategoryDrawerContentProps {
  selectedCategory: Types.EntityCategories
  timeZone: string | undefined
  reportType: Types.CashFlowReportType
}

export const CategoryDrawerContent = ({
  selectedCategory,
  timeZone,
  reportType,
}: CategoryDrawerContentProps) => {
  const isMobile = useIsMobileResolution()
  const selectedAccountId = useSelectedAccountId()
  const { selectedDateRange } = useSelectedDateRange()

  const { subcategories, isLoading, error } =
    useCashflowReportingSubcategoriesNetAmounts({
      category: selectedCategory,
      date: convertToAWSDate(selectedDateRange, timeZone),
      reportType,
      accountUuid:
        selectedAccountId === 'all accounts'
          ? undefined
          : selectedAccountId.accountId,
      timeZone,
    })

  const [hoveredSubcategoryNetAmount, setHoveredSubcategoryNetAmount] =
    useState<HoveredSubcategoryNetAmount>()

  const toggleHoveredSubcategoryNetAmount = useCallback(
    (netAmount: SubcategoryNetAmount) => {
      setHoveredSubcategoryNetAmount((prevState) => {
        if (
          prevState?.subcategoryDetails?.id === netAmount.subcategoryDetails?.id
        ) {
          return undefined
        }
        return netAmount
      })
    },
    []
  )

  if (!isLoading && (error || !subcategories?.length)) {
    return <CategoryDrawerError />
  }

  return (
    <>
      <CategoryDrawerPieChart
        subCategoriesNetAmounts={subcategories}
        size="large"
        isLoading={isLoading}
        transactionDirection="expense"
        hoveredSubcategoryNetAmount={hoveredSubcategoryNetAmount}
        selectedCategory={selectedCategory}
      />
      <CategoryDrawerTable
        subcategoryNetAmount={subcategories}
        isLoading={isLoading}
        transactionDirection="expense"
        onSubcategoryClicked={
          isMobile ? toggleHoveredSubcategoryNetAmount : undefined
        }
        onSubcategoryHovered={
          isMobile ? undefined : setHoveredSubcategoryNetAmount
        }
        onSubcategoryUnhovered={() => setHoveredSubcategoryNetAmount(undefined)}
      />
    </>
  )
}
