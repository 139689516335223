import { useEffect, useRef } from 'react'
import { ReactiveVar, useReactiveVar } from '@apollo/client'

import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'services/sessionStorage/utils'

export interface Props<T> {
  reactiveVarHandler: ReactiveVar<T>
  storageKey: string
  defaultValue: T
}

export function SessionStorageHandlerSlice<T>({
  reactiveVarHandler,
  storageKey,
  defaultValue,
}: Props<T>) {
  const reactiveVar = useReactiveVar<T>(reactiveVarHandler)

  // We have to use refs to not rerender on reactiveVarHandler or defaultValue change and not set unwanted values,
  // but keep the newest data acc to react docs.
  // reactiveVarHandler is function and default value can be an empty array or empty object.
  // As {} !== {} we could have not wanted rerenders
  // as deafult key is a prymitive it will never cause an unwanted rerender and can stay in deps' array
  const defaultValueRef = useRef<T>(defaultValue)
  const reactiveVarHandlerRef =
    useRef<Props<T>['reactiveVarHandler']>(reactiveVarHandler)

  useEffect(() => {
    defaultValueRef.current = defaultValue
  }, [defaultValue])

  useEffect(() => {
    reactiveVarHandlerRef.current = reactiveVarHandler
  }, [reactiveVarHandler])

  useEffect(() => {
    const setCustomersTempData = () => {
      const storageData = getSessionStorageItem<T>(storageKey)
      reactiveVarHandlerRef.current(storageData ?? defaultValueRef.current)
    }

    setCustomersTempData()
  }, [storageKey])

  useEffect(() => {
    setSessionStorageItem(storageKey, reactiveVar)
  }, [reactiveVar, storageKey])

  return null
}
