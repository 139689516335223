import { gql } from '@apollo/client'

import { PredefinedAddressPage } from '../PredefinedAddressPage/PredefinedAddressPage'

export const GetEntityAddress = gql`
  query GetEntityAddress {
    getEntity {
      ...PredefinedAddressPageFragment
    }
  }
  ${PredefinedAddressPage.fragments.Entity}
`
