import { useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { ApolloError } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { FormikHelpers } from 'formik'

import { useGetItemSettings } from 'hooks/useGetItemSettings/useGetItemSettings'
import { ROOT } from 'const/routes'
import {
  convertItemFormFieldsToCreateItemInput,
  ItemCreateModalForm,
  itemCreateModalFormInitialValues,
  ItemFormFields,
  ItemTaxApplicableItems,
} from 'components/ItemBaseModalForm'

import { useItemsAnalytics } from '../../hooks/useItemsAnalytics'
import { getDuplicateSkuErrorItemName } from '../../ItemManagement.utils'
import { useItemOnboarding } from '../ItemOnboarding/hooks/useItemOnboarding'
import { useCreateItemMutation } from './graphql/createItem.generated'
import { translations } from './ItemCreateModal.i18n'

interface ItemCreateModalProps {
  initialValues?: ItemFormFields
}

export const ItemCreateModal = ({
  initialValues = itemCreateModalFormInitialValues,
}: ItemCreateModalProps) => {
  const { trackAddItem } = useItemsAnalytics()

  const t = useTranslations(translations)
  const { settings } = useGetItemSettings()
  const navigate = useNavigate()
  const location = useLocation()
  const [createItem, { loading: isCreatingItem }] = useCreateItemMutation()
  const { turnOffOnboardingModal } = useItemOnboarding()
  const [skuDuplicateError, setSkuDuplicateError] = useState('')

  const handleClose = useCallback(
    () =>
      navigate(
        location.pathname.includes('categories')
          ? ROOT.PORTAL.ITEM_MANAGEMENT.CATEGORIES.path
          : ROOT.PORTAL.ITEM_MANAGEMENT.ITEMS.path
      ),
    [location.pathname, navigate]
  )

  const handleOnSubmit = useCallback(
    async (
      formValues: ItemFormFields,
      helpers: FormikHelpers<ItemFormFields>
    ) => {
      setSkuDuplicateError('')

      async function sendRequest() {
        const item = convertItemFormFieldsToCreateItemInput(formValues)

        const result = await createItem({ variables: { item } })

        if (result.data?.createItem) {
          trackAddItem(formValues)

          showSuccessToast(t('successNotification'))
          if (formValues.addNewOnSave) {
            helpers.resetForm()
            return
          }

          await turnOffOnboardingModal()

          handleClose()
        } else {
          showErrorToast(t('errorNotification'))
        }
      }

      sendRequest().catch((error: ApolloError) => {
        showErrorToast(t('errorNotification'))
        const dupeSkuItemName = getDuplicateSkuErrorItemName(error)

        if (dupeSkuItemName) {
          setSkuDuplicateError(
            t('skuDuplicateError', {
              itemName: dupeSkuItemName,
            })
          )
        }
      })
    },
    [createItem, handleClose, t, trackAddItem, turnOffOnboardingModal]
  )

  // NOTE: This works since we are using cached item settings value
  // here. This component doesn't render until items table and items
  // settings has been loaded.
  const initialValuesUpdated = useMemo(
    () => ({
      ...initialValues,
      isTaxInclusive: !!settings?.itemsTaxInclusive,
      taxApplicable: settings?.itemsApplyTax
        ? ItemTaxApplicableItems.TaxApplicable
        : ItemTaxApplicableItems.NoTax,
    }),
    [initialValues, settings?.itemsApplyTax, settings?.itemsTaxInclusive]
  )

  return (
    <ItemCreateModalForm
      data-testid="item-create-modal"
      initialValues={initialValuesUpdated}
      title={t('title')}
      onSubmit={handleOnSubmit}
      onClose={handleClose}
      isCreatingItem={isCreatingItem}
      skuDuplicateError={skuDuplicateError}
    />
  )
}
