import { useCallback } from 'react'
import { useAccountNavigation } from '@npco/mp-utils-accounts-routing'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { UNEXPECTED_ERROR } from '../../../../const/errors'
import { createSavingsAccountFormTranslations } from '../../CreateSavingsAccountForm.i18n'
import { CreateSavingsAccountValues } from '../../CreateSavingsAccountForm.types'
import { useActivateSavingsAccount } from '../useActivateSavingsAccount/useActivateSavingsAccount'
import { useActivateSavingsAccountEncrypted } from '../useActivateSavingsAccountEncrypted/useActivateSavingsAccountEncrypted'
import { useEncryptTfn } from '../useEncryptTfn/useEncryptTfn'

export const useCreateSavingsAccountForm = () => {
  const {
    activateSavingsAccount: activateSavingsAccountMutation,
    isActivatingSavingsAccount,
  } = useActivateSavingsAccount()

  const { navigateToAccountTransactions } = useAccountNavigation()
  const t = useTranslations(createSavingsAccountFormTranslations)

  const flags = useFeatureFlags()

  const activateSavingsAccount = useCallback(
    async ({ tfn }: CreateSavingsAccountValues) => {
      const result = await activateSavingsAccountMutation({
        tfn: tfn.replace(/\s/g, ''),
      })

      if (result === UNEXPECTED_ERROR) {
        showErrorToast(t('errorActivating'))
        return
      }

      navigateToAccountTransactions({ accountId: result.id })
      showSuccessToast(t('activationSuccess'))
    },
    [activateSavingsAccountMutation, navigateToAccountTransactions, t]
  )

  const { encryptTfn, isInitiatingSession } = useEncryptTfn()
  const {
    activateSavingsAccountEncrypted,
    isActivatingSavingsAccountEncrypted,
  } = useActivateSavingsAccountEncrypted()

  const encryptTfnAndActivateSavingsAccount = useCallback(
    async ({ tfn }: CreateSavingsAccountValues) => {
      const encryptionResult = await encryptTfn({ tfn: tfn.replace(/\s/g, '') })

      if (encryptionResult === UNEXPECTED_ERROR) {
        showErrorToast(t('errorActivating'))
        return
      }

      const { secureSessionId, encryptedData, encryptedDataMac } =
        encryptionResult

      const result = await activateSavingsAccountEncrypted({
        encryptedTfn: encryptedData,
        encryptedTfnMac: encryptedDataMac,
        secureSessionId,
      })

      if (result === UNEXPECTED_ERROR) {
        showErrorToast(t('errorActivating'))
        return
      }

      navigateToAccountTransactions({ accountId: result.id })
      showSuccessToast(t('activationSuccess'))
    },
    [
      encryptTfn,
      activateSavingsAccountEncrypted,
      navigateToAccountTransactions,
      t,
    ]
  )

  return {
    activateSavingsAccount: flags.TfnEncryption
      ? encryptTfnAndActivateSavingsAccount
      : activateSavingsAccount,
    isActivatingSavingsAccount:
      isActivatingSavingsAccount ||
      isActivatingSavingsAccountEncrypted ||
      isInitiatingSession,
  }
}
