import {
  CheckboxIndeterminateIcon,
  SelectStyle,
} from '@npco/zeller-design-system'

import { ReactComponent as CheckboxTickIcon } from 'assets/svg/tick.svg'
import { conditionalClassName as cn } from 'utils/conditionalClassName'

import {
  MultiSelectItemBasic,
  MultiSelectItemRenderProps,
} from '../MultiSelect.types'
import * as styled from './MultiSelectItem.styled'

export type MultiSelectItemProps<ItemType extends MultiSelectItemBasic> =
  MultiSelectItemRenderProps<ItemType> & {
    sublabel?: React.ReactNode
    isDisabled?: boolean
    isIndeterminate?: boolean
    tabIndex?: number
    onKeyDown?: (event: React.KeyboardEvent<HTMLLIElement>) => void
  }

export const MultiSelectItem = <ItemType extends MultiSelectItemBasic>({
  label,
  sublabel,
  isDisabled = false,
  isChecked,
  isIndeterminate = false,
  className,
  selectStyle = SelectStyle.Standard,
  ...rest
}: MultiSelectItemProps<ItemType>) => {
  return (
    <styled.Item
      data-disabled={isDisabled}
      aria-disabled={isDisabled}
      {...rest}
      className={cn(
        {
          isChecked,
          isCompact: selectStyle === SelectStyle.Compact,
        },
        className
      )}
    >
      <styled.CheckIndicator
        className={cn({
          checked: isChecked,
          disabled: isDisabled,
        })}
      >
        {isIndeterminate ? (
          <CheckboxIndeterminateIcon width="10" height="10" />
        ) : (
          <CheckboxTickIcon width="11" height="11" />
        )}
      </styled.CheckIndicator>

      <styled.LabelContainer>
        <styled.Label>{label}</styled.Label>
        {!!sublabel && <styled.Sublabel>{sublabel}</styled.Sublabel>}
      </styled.LabelContainer>
    </styled.Item>
  )
}
