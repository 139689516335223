import { useMutation } from '@apollo/client'
import { InvoiceRecordPaymentInput } from '@npco/mp-gql-types'

import {
  RecordPayment as RecordPaymentResponse,
  RecordPaymentVariables,
} from 'types/gql-types/RecordPayment'

import { RecordPayment } from '../../graphql/recordPayment'

export const useRecordPayment = () => {
  const [recordPayment, { loading: isRecordingPayment }] = useMutation<
    RecordPaymentResponse,
    RecordPaymentVariables
  >(RecordPayment)

  return {
    recordPayment: (input: InvoiceRecordPaymentInput) =>
      recordPayment({
        variables: {
          input,
        },
      }),
    isRecordingPayment,
  }
}
