import { useReactiveVar } from '@apollo/client'
import { ContactType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'

import { AccountsFilterButton } from '../../Contact/ContactSections/AccountsList/AccountsFilterButton'
import { translations } from '../../Contacts.i18n'
import { getContactName } from '../../Contacts.utils'
import { rvSelectedContact } from '../../rv-deprecated/contacts'
import { useContactLayout } from '../ContactLayout.hooks'
import { ContactCreateInvoiceButton } from './ContactCreateInvoiceButton/ContactCreateInvoiceButton'
import { ContactFiltersButton } from './ContactsFilterButton/ContactFiltersButton'

export const ContactPrimaryHeaderRowDeps = {
  AccountsFilterButton,
  ContactCreateInvoiceButton,
  ContactFiltersButton,
  getContactName,
  useContactLayout,
  useReactiveVar,
}

export const ContactPrimaryHeaderRow = () => {
  const {
    AccountsFilterButton,
    ContactCreateInvoiceButton,
    ContactFiltersButton,
    getContactName,
    useContactLayout,
    useReactiveVar,
  } = ContactPrimaryHeaderRowDeps

  const {
    showAccountsFilterButton,
    showInvoiceCreateButton,
    showPaymentsFilterButton,
  } = useContactLayout()

  const selectedContact = useReactiveVar(rvSelectedContact)
  const contactName = getContactName(selectedContact)
  const t = useTranslations(translations)

  const redirectTo =
    selectedContact?.contactType === ContactType.BUSINESS
      ? ROOT.PORTAL.CONTACTS.BUSINESSES.path
      : ROOT.PORTAL.CONTACTS.PEOPLE.path

  return (
    <PageTemplate.HeaderPrimaryRow>
      <Flex
        flexDirection="column"
        justifyContent="center"
        minHeight="48px"
        width="100%"
      >
        <Flex
          alignItems="center"
          gap="8px"
          justifyContent="space-between"
          width="100%"
        >
          <Flex flex={1} overflow="hidden">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Breadcrumb.Link to={redirectTo}>
                  {t('contacts')}
                </Breadcrumb.Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Breadcrumb.Text>{contactName}</Breadcrumb.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Flex>
          {showAccountsFilterButton && <AccountsFilterButton />}
          {showPaymentsFilterButton && <ContactFiltersButton />}
          {showInvoiceCreateButton && <ContactCreateInvoiceButton />}
        </Flex>
      </Flex>
    </PageTemplate.HeaderPrimaryRow>
  )
}
