import { ROUTE } from 'const/routes'
import { getIsOnPage } from 'utils/common'

export const getAreTabsVisible = (pathname: string) => {
  const isUserOnUsersPage = [
    ROUTE.PORTAL_PAYMENTS_SITES_SITE_USERS_EDIT,
    ROUTE.PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_TYPE,
    ROUTE.PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_DETAILS,
  ].some((route) => getIsOnPage(pathname, route))

  return (
    getIsOnPage(pathname, ROUTE.PORTAL_PAYMENTS_SITES_SITE) &&
    !isUserOnUsersPage
  )
}
