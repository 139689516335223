import { ROOT, ROUTE } from 'const/routes'
import { page } from 'translations'

import {
  BreadcrumbParts,
  BreadcrumbPath,
  BreadcrumbsVariable,
} from './Breadcrumbs.types'

export const BREADCRUMB: { [part in BreadcrumbParts]: BreadcrumbPath } = {
  [BreadcrumbParts.OVERVIEW]: {
    name: page.title.overview,
    path: ROUTE.PORTAL_OVERVIEW,
  },
  [BreadcrumbParts.DEPOSITS]: {
    name: page.title.deposits,
    path: ROUTE.PORTAL_PAYMENTS_DEPOSITS,
  },
  [BreadcrumbParts.DEPOSITS_STATEMENTS]: {
    name: page.title.statements,
    path: ROUTE.PORTAL_PAYMENTS_DEPOSITS_STATEMENTS,
    parent: BreadcrumbParts.DEPOSITS,
  },
  [BreadcrumbParts.DEPOSIT_SETTINGS]: {
    name: page.title.settings,
    path: ROUTE.PORTAL_PAYMENTS_DEPOSITS_SETTINGS,
    parent: BreadcrumbParts.DEPOSITS,
  },
  [BreadcrumbParts.DEPOSTI_ACCOUNT_DETAIL]: {
    name: BreadcrumbsVariable.depositAccountName,
    path: ROUTE.PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_DETAILS,
    parent: BreadcrumbParts.DEPOSIT_SETTINGS,
  },
  [BreadcrumbParts.DEPOSTI_ACCOUNT_EDIT_ACCOUNT_DETAIL]: {
    name: page.settings.editDepositAccountDetail.name,
    path: ROUTE.PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_EDIT_ACCOUNT_DETAIL,
    parent: BreadcrumbParts.DEPOSTI_ACCOUNT_DETAIL,
  },
  [BreadcrumbParts.DEPOSIT_SETTINGS_CHANGE_ACCOUNT]: {
    name: page.title.changeAccount,
    path: ROUTE.PORTAL_PAYMENTS_DEPOSITS_SETTINGS_EDIT_ACCOUNT,
    parent: BreadcrumbParts.DEPOSIT_SETTINGS,
  },
  [BreadcrumbParts.DEPOSIT_SETTINGS_ADD_ACCOUNT]: {
    name: page.title.addBankAccount,
    path: ROUTE.PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT,
    parent: BreadcrumbParts.DEPOSIT_SETTINGS,
  },
  [BreadcrumbParts.PROFILE_PERSONAL]: {
    name: page.settings.profileSettings.title,
    path: ROUTE.PORTAL_SETTINGS_PROFILE_PERSONAL,
    applyToPaths: [
      ROUTE.PORTAL_SETTINGS_PROFILE_PERSONAL,
      ROUTE.PORTAL_SETTINGS_PROFILE_DOCUMENTS,
      ROUTE.PORTAL_SETTINGS_PROFILE_SECURITY,
    ],
  },
  [BreadcrumbParts.PROFILE_PERSONAL_EDIT]: {
    name: page.settings.general.edit,
    path: ROUTE.PORTAL_SETTINGS_PROFILE_PERSONAL_EDIT,
    parent: BreadcrumbParts.PROFILE_PERSONAL,
  },
  [BreadcrumbParts.SETTINGS_CONNECTIONS]: {
    name: page.settings.connections.title,
    path: ROOT.PORTAL.SETTINGS.CONNECTIONS.path,
    applyToPaths: [
      ROOT.PORTAL.SETTINGS.CONNECTIONS.path,
      ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_BANK_FEEDS.path,
      ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_BANK_FEEDS_SETUP.path,
      ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_BANK_FEEDS_MANAGE.path,
      ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES.path,
      ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_SETUP.path,
      ROOT.PORTAL.SETTINGS.CONNECTIONS.ORACLE_POS.path,
      ROOT.PORTAL.SETTINGS.CONNECTIONS.HL_POS.MANAGE.path,
    ],
  },
  [BreadcrumbParts.XERO_PAYMENT_SERVICES_MANAGE]: {
    name: page.settings.connections.xeroPaymentServices.breadcrumb,
    path: ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.path,
    applyToPaths: [
      ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.path,
      ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.GENERAL
        .path,
      ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.RECEIPT
        .path,
      ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.USERS.path,
      ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.USERS.USER()
        .path,
    ],
    parent: BreadcrumbParts.SETTINGS_CONNECTIONS,
  },
  [BreadcrumbParts.PROFILE_SETTINGS_CHANGE_NUMBER]: {
    name: page.settings.profileSettings.changePhoneNumber,
    path: ROUTE.PORTAL_SETTINGS_PROFILE_SECURITY_CHANGE_NUMBER,
    parent: BreadcrumbParts.PROFILE_PERSONAL,
  },
  [BreadcrumbParts.PROFILE_SETTINGS_VALIDATE_CODE]: {
    name: page.settings.profileSettings.changePhoneNumber,
    path: ROUTE.PORTAL_SETTINGS_PROFILE_SECURITY_VALIDATE_CODE,
    parent: BreadcrumbParts.PROFILE_PERSONAL,
  },
  [BreadcrumbParts.FEES_AND_PRICING]: {
    name: page.settings.feesAndPricing.title,
    path: ROUTE.PORTAL_SETTINGS_FEES_AND_PRICING,
  },

  [BreadcrumbParts.SITES]: {
    name: page.title.sites,
    path: ROUTE.PORTAL_PAYMENTS_SITES,
  },
  [BreadcrumbParts.CREATE_NEW_SITE]: {
    name: page.title.createSite,
    path: ROUTE.PORTAL_PAYMENTS_SITES_CREATE_SITE,
    parent: BreadcrumbParts.SITES,
  },
  [BreadcrumbParts.SITE]: {
    name: BreadcrumbsVariable.siteName,
    path: ROUTE.PORTAL_PAYMENTS_SITES_SITE_GENERAL,
    applyToPaths: [
      ROUTE.PORTAL_PAYMENTS_SITES_SITE_GENERAL,
      ROUTE.PORTAL_PAYMENTS_SITES_SITE_GENERAL_EDIT,
      ROUTE.PORTAL_PAYMENTS_SITES_SITE_DEVICES,
      ROUTE.PORTAL_PAYMENTS_SITES_SITE_PAYMENTS,
      ROUTE.PORTAL_PAYMENTS_SITES_SITE_RECEIPT,
      ROUTE.PORTAL_PAYMENTS_SITES_SITE_USERS,
      ROUTE.PORTAL_PAYMENTS_SITES_SITE_APPEARANCE,
    ],
    parent: BreadcrumbParts.SITES,
  },
  [BreadcrumbParts.SITE_EDIT]: {
    name: page.title.editSite,
    path: ROUTE.PORTAL_PAYMENTS_SITES_SITE_GENERAL_EDIT,
    parent: BreadcrumbParts.SITE,
  },
  [BreadcrumbParts.SITE_USERS_ADD]: {
    name: page.title.createNewUser,
    path: ROUTE.PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_TYPE,
    applyToPaths: [
      ROUTE.PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_TYPE,
      ROUTE.PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_DETAILS,
    ],
    parent: BreadcrumbParts.SITE,
  },
  [BreadcrumbParts.SITE_USERS_EDIT]: {
    name: page.title.editUser,
    path: ROUTE.PORTAL_PAYMENTS_SITES_SITE_USERS_EDIT,
    parent: BreadcrumbParts.SITE,
  },

  [BreadcrumbParts.PAYMENT_DEVICES]: {
    name: page.title.devices,
    path: ROUTE.PORTAL_PAYMENTS_DEVICES,
  },
  [BreadcrumbParts.TRANSACTIONS]: {
    name: page.title.transactions,
    path: ROUTE.PORTAL_PAYMENTS_TRANSACTIONS,
  },
  [BreadcrumbParts.SIM]: {
    name: page.title.sim,
    path: ROOT.PORTAL.PAYMENTS.SIM.path,
  },
  [BreadcrumbParts.SIM_BILLING_HISTORY]: {
    name: page.settings.sim.billingHistory.title,
    path: ROOT.PORTAL.PAYMENTS.SIM.BILLING_HISTORY.path,
    parent: BreadcrumbParts.SIM,
  },
  [BreadcrumbParts.SIM_BILLING_ACCOUNT]: {
    name: page.settings.sim.billingAccount.title,
    path: ROOT.PORTAL.PAYMENTS.SIM.BILLING_ACCOUNT.path,
    parent: BreadcrumbParts.SIM,
  },
  [BreadcrumbParts.SIM_BILLING_HISTORY_STATEMENTS]: {
    name: page.settings.sim.statements.title,
    path: ROOT.PORTAL.PAYMENTS.SIM.BILLING_HISTORY.STATEMENTS.path,
    parent: BreadcrumbParts.SIM_BILLING_HISTORY,
  },
  [BreadcrumbParts.CARDS]: {
    name: page.cards.breadcrumb,
    path: ROUTE.PORTAL_CARDS,
    applyToPaths: [
      ROUTE.PORTAL_CARDS,
      ROUTE.PORTAL_CARDS_ACTIVATE,
      ROUTE.PORTAL_CARDS_CORPORATE,
      ROUTE.PORTAL_CARDS_DEBIT,
    ],
  },
  [BreadcrumbParts.CONTACTS_PEOPLE]: {
    name: page.contacts.breadcrumb,
    path: ROUTE.PORTAL_CONTACTS_PEOPLE,
  },
  [BreadcrumbParts.CONTACTS_BUSINESSES]: {
    name: page.contacts.breadcrumb,
    path: ROUTE.PORTAL_CONTACTS_BUSINESSES,
  },

  [BreadcrumbParts.CONTACT_PERSON]: {
    name: BreadcrumbsVariable.contactName,
    path: ROUTE.PORTAL_CONTACTS_PEOPLE_GENERAL,
    applyToPaths: [
      ROUTE.PORTAL_CONTACTS_PEOPLE_GENERAL,
      ROUTE.PORTAL_CONTACTS_PEOPLE_PAYMENTS,
      ROUTE.PORTAL_CONTACTS_PEOPLE_ACCOUNTS,
      ROUTE.PORTAL_CONTACTS_PEOPLE_INVOICES,
      ROUTE.PORTAL_CONTACTS_PEOPLE_INVOICES_DETAILS,
    ],
    parent: BreadcrumbParts.CONTACTS_PEOPLE,
  },
  [BreadcrumbParts.CONTACT_BUSINESS]: {
    name: BreadcrumbsVariable.contactName,
    path: ROUTE.PORTAL_CONTACTS_BUSINESSES_GENERAL,
    applyToPaths: [
      ROUTE.PORTAL_CONTACTS_BUSINESSES_GENERAL,
      ROUTE.PORTAL_CONTACTS_BUSINESSES_PAYMENTS,
      ROUTE.PORTAL_CONTACTS_BUSINESSES_ACCOUNTS,
      ROUTE.PORTAL_CONTACTS_BUSINESSES_INVOICES,
      ROUTE.PORTAL_CONTACTS_BUSINESSES_INVOICES_DETAILS,
    ],
    parent: BreadcrumbParts.CONTACTS_BUSINESSES,
  },
  [BreadcrumbParts.TRANSFER_CREATE]: {
    name: page.title.transfer,
    path: ROUTE.PORTAL_ACCOUNTS_TRANSFER_CREATE,
  },
  [BreadcrumbParts.REPORTS]: {
    name: page.title.reports,
    path: ROUTE.PORTAL_PAYMENTS_REPORTS,
  },
  [BreadcrumbParts.USERS]: {
    name: page.title.users,
    path: ROUTE.PORTAL_SETTINGS_USERS,
  },
  [BreadcrumbParts.USERS_EDIT]: {
    name: page.title.editUser,
    path: ROUTE.PORTAL_SETTINGS_USERS_USER_EDIT,
    parent: BreadcrumbParts.USERS,
  },
  [BreadcrumbParts.USERS_CREATE]: {
    name: page.title.createNewUser,
    path: ROUTE.PORTAL_SETTINGS_USERS_USER_CREATE_TYPE,
    parent: BreadcrumbParts.USERS,
    applyToPaths: [
      ROUTE.PORTAL_SETTINGS_USERS_USER_CREATE_TYPE,
      ROUTE.PORTAL_SETTINGS_USERS_USER_CREATE_DETAILS,
    ],
  },
  [BreadcrumbParts.INVOICING]: {
    name: page.invoices.title,
    path: ROUTE.PORTAL_INVOICES,
  },
  [BreadcrumbParts.INVOICING_OVERVIEW]: {
    name: page.invoices.title,
    path: ROUTE.PORTAL_INVOICES_OVERVIEW,
  },
  [BreadcrumbParts.INVOICING_INVOICES_CREATE]: {
    name: page.invoice.draft,
    path: ROUTE.PORTAL_INVOICES_INVOICE_CREATE,
    parent: BreadcrumbParts.INVOICING_INVOICES,
  },
  [BreadcrumbParts.INVOICING_INVOICES_EDIT]: {
    name: BreadcrumbsVariable.invoiceRefNumber,
    path: ROUTE.PORTAL_INVOICES_INVOICE_EDIT,
    parent: BreadcrumbParts.INVOICING_INVOICES,
  },
  [BreadcrumbParts.INVOICING_INVOICES]: {
    name: page.invoices.title,
    path: ROUTE.PORTAL_INVOICES_INVOICE_LIST,
    applyToPaths: [
      ROUTE.PORTAL_INVOICES_INVOICE_LIST,
      ROUTE.PORTAL_INVOICES_INVOICE_DETAILS,
    ],
  },
  [BreadcrumbParts.INVOICING_QUOTES]: {
    name: page.invoices.title,
    path: ROUTE.PORTAL_INVOICES_QUOTES,
  },
  [BreadcrumbParts.INVOICING_RECURRING]: {
    name: page.invoices.title,
    path: ROUTE.PORTAL_INVOICES_RECURRING,
  },
  [BreadcrumbParts.INVOICING_SETTINGS]: {
    name: page.invoicesSettings.title,
    path: ROUTE.PORTAL_INVOICES_SETTINGS,
    applyToPaths: [
      ROUTE.PORTAL_INVOICES_SETTINGS_GENERAL,
      ROUTE.PORTAL_INVOICES_SETTINGS_REMINDERS,
      ROUTE.PORTAL_INVOICES_SETTINGS_CUSTOMISATION,
      ROUTE.PORTAL_INVOICES_SETTINGS_RECEIPTS,
      ROUTE.PORTAL_INVOICES_SETTINGS_USERS,
      ROUTE.PORTAL_INVOICES_SETTINGS_USERS_DETAILS,
    ],
    parent: BreadcrumbParts.INVOICING,
  },
}
