import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useGoToCompleteCardStage } from 'hooks/useGoToCompleteCardStage/useGoToCompleteCardStage'
import { ROOT } from 'const/routes'

export const useCompleteCardModal = () => {
  const navigate = useNavigate()
  const { goToAddCardStage } = useGoToAddCardStage()
  const { goToCompleteCardStage } = useGoToCompleteCardStage()

  const openAddCardModal = useCallback(() => {
    goToAddCardStage({
      stage: AddCardStage.LandingPageStage,
    })
  }, [goToAddCardStage])

  const viewCard = useCallback(
    (cardId: string) => {
      navigate(ROOT.PORTAL.CARDS.CARD(cardId).path)
    },
    [navigate]
  )

  const closeCompleteCardModal = useCallback(() => {
    goToCompleteCardStage(undefined)
  }, [goToCompleteCardStage])

  return { openAddCardModal, closeCompleteCardModal, viewCard }
}
