import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import { InvoiceStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast } from '@npco/zeller-design-system'
import { useGetInvoice } from 'features/Invoicing/components/Invoices/hooks/useGetInvoice'
import { StatusLabel } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.utils'

import { ROUTE_PARAM_NAME } from 'const/routes'
import { GetInvoiceDrawer as GetInvoiceDrawerResponse } from 'types/gql-types/GetInvoiceDrawer'
import { SwitchAnimationContainer } from 'components/DrawerWithNavigation/DrawerNavigation/SwitchAnimationContainer/SwitchAnimationContainer'
import { SpinnerWrapped } from 'components/Spinner'

import { GetInvoiceDrawer } from './graphql/getInvoiceDrawer'
import { translations } from './InvoiceDrawer.i18n'
import * as styled from './InvoiceDrawer.styled'
import { InvoiceDrawerActivity } from './InvoiceDrawerActivity/InvoiceDrawerActivity'
import { InvoiceDrawerContact } from './InvoiceDrawerContact/InvoiceDrawerContact'
import { InvoiceDrawerDetails } from './InvoiceDrawerDetails/InvoiceDrawerDetails'
import { InvoiceDrawerHeader } from './InvoiceDrawerHeader'
import { InvoiceDrawerTabs, useInvoiceDrawerTabs } from './useInvoiceDrawerTabs'

export const InvoiceDrawerDeps = {
  useGetInvoice,
  useInvoiceDrawerTabs,
  useParams,
  useTranslations,
}

type InvoiceDrawerProps = {
  handleRedirectDelay?: number
  handleRedirect: () => void
}

export const InvoiceDrawer = ({
  handleRedirectDelay = 200,
  handleRedirect,
}: InvoiceDrawerProps) => {
  const { useGetInvoice, useInvoiceDrawerTabs, useParams, useTranslations } =
    InvoiceDrawerDeps

  const t = useTranslations(translations)

  const { invoiceRefNumber = '' } =
    useParams<ROUTE_PARAM_NAME.PORTAL_INVOICE_REF_NUMBER>()

  const [isOpen, setIsOpen] = useState(Boolean(invoiceRefNumber))

  const { invoiceData, isLoading, error } =
    useGetInvoice<GetInvoiceDrawerResponse>({
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only',
      referenceNumber: invoiceRefNumber,
      query: GetInvoiceDrawer,
    })

  const invoiceStatus = invoiceData?.getInvoice?.status

  const { tabs, resetTabs, selectedTab } = useInvoiceDrawerTabs({
    status: invoiceStatus,
  })

  const handleOnClose = useCallback(() => {
    setIsOpen(false)

    // NOTE: await 200ms for drawer close animation to finish and then redirect
    // as this invoice drawer is route based rendered
    setTimeout(() => {
      handleRedirect()
      resetTabs()
    }, handleRedirectDelay)
  }, [handleRedirect, handleRedirectDelay, resetTabs])

  const invoiceNotFound = invoiceStatus === InvoiceStatus.DELETED || error

  useEffect(() => {
    if (invoiceNotFound) {
      handleOnClose()
      showErrorToast(t('invoiceError', { referenceNumber: invoiceRefNumber }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceNotFound])

  const invoice = invoiceData?.getInvoice
  const hasInvoice = !isLoading && Boolean(invoice)

  return (
    <styled.DrawerBasic
      overlayClassName="animated-drawer-overlay"
      isOpen={isOpen}
      title={invoiceRefNumber}
      onClose={handleOnClose}
      beforeCloseContent={
        <styled.InvoiceStatusLabel $status={invoice?.status}>
          {invoice?.status && StatusLabel[invoice.status]}
        </styled.InvoiceStatusLabel>
      }
      headerContent={
        <InvoiceDrawerHeader
          animationKey={invoiceRefNumber}
          tabs={tabs}
          selectedTab={selectedTab}
        />
      }
    >
      {hasInvoice ? (
        <>
          {invoice && selectedTab === InvoiceDrawerTabs.Details && (
            <SwitchAnimationContainer animationKey={invoiceRefNumber}>
              <InvoiceDrawerDetails details={invoice} />
            </SwitchAnimationContainer>
          )}
          {invoice && selectedTab === InvoiceDrawerTabs.Activity && (
            <SwitchAnimationContainer animationKey={invoiceRefNumber}>
              <InvoiceDrawerActivity
                activities={invoice.activities ?? []}
                referenceNumber={invoiceRefNumber}
                status={invoice.status}
              />
            </SwitchAnimationContainer>
          )}
          {invoice && selectedTab === InvoiceDrawerTabs.Contact && (
            <SwitchAnimationContainer animationKey={invoiceRefNumber}>
              <InvoiceDrawerContact
                customer={invoice.customer}
                referenceNumber={invoice.referenceNumber}
              />
            </SwitchAnimationContainer>
          )}
        </>
      ) : (
        <SpinnerWrapped variant="top" />
      )}
    </styled.DrawerBasic>
  )
}
