import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type EditAccountMutationVariables = Types.Exact<{
  values: Types.PersonaliseDebitCardAccountInput
}>

export type EditAccountMutationResponse = {
  __typename?: 'Mutation'
  account:
    | { __typename?: 'DebitCardAccount' }
    | {
        __typename: 'DebitCardAccountV2'
        id: string
        name: string
        icon: {
          __typename?: 'Icon'
          colour: string | null
          letter: string | null
          images: Array<{
            __typename?: 'Image'
            size: Types.ImageSize
            url: string
          }> | null
        } | null
      }
}

export const EditAccount = gql`
  mutation EditAccount($values: PersonaliseDebitCardAccountInput!) {
    account: personaliseDebitCardAccount(input: $values) {
      ... on DebitCardAccountV2 {
        id
        name
        icon {
          colour
          letter
          images {
            size
            url
          }
        }
        __typename
      }
    }
  }
` as unknown as TypedDocumentNode<
  EditAccountMutationResponse,
  EditAccountMutationVariables
>
export type EditAccountMutationFn = Apollo.MutationFunction<
  EditAccountMutationResponse,
  EditAccountMutationVariables
>

/**
 * __useEditAccountMutation__
 *
 * To run a mutation, you first call `useEditAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccountMutation, { data, loading, error }] = useEditAccountMutation({
 *   variables: {
 *      values: // value for 'values'
 *   },
 * });
 */
export function useEditAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditAccountMutationResponse,
    EditAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EditAccountMutationResponse,
    EditAccountMutationVariables
  >(EditAccount, options)
}
export type EditAccountMutationHookResult = ReturnType<
  typeof useEditAccountMutation
>
export type EditAccountMutationResult =
  Apollo.MutationResult<EditAccountMutationResponse>
export type EditAccountMutationOptions = Apollo.BaseMutationOptions<
  EditAccountMutationResponse,
  EditAccountMutationVariables
>
