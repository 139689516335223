import { gql } from '@apollo/client'

export const XeroBankfeed = gql`
  query XeroBankfeed($connection: ConnectionType = XERO_BANKFEEDS) {
    xeroBankFeed: getConnectionState(connectionType: $connection) {
      id
      status
      errorType
    }
  }
`
