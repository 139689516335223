/* eslint-disable camelcase */
import {
  Category,
  CategoryGroup,
  CreateCustomerInput,
  CustomerRole,
  KycStatus,
} from '@npco/mp-gql-types'

import { GetCustomerDetails_getCustomer } from 'types/gql-types/GetCustomerDetails'

import { SiteCache } from './site'

export interface CompanyInformaiton {
  name: string
}

export interface BusinessTypeAndCategory {
  categoryGroup: CategoryGroup | null
  category: Category | null
}

export type CustomerDetailsForm = {
  email: string
  firstname: string
  middlename: string
  lastname: string
  phone?: string
  role: CustomerRole | null
  isInvitationPending?: boolean | null
  registeringIndividual: boolean | null
  sites?: SiteCache[]
  kycStatus: KycStatus | null
  allowZellerInvoices: boolean
  allowDiscountManagement: boolean
  allowItemManagement: boolean
}

export interface CustomerDetailsWithSites
  extends GetCustomerDetails_getCustomer {
  sites: SiteCache[]
}

export type Customer = CreateCustomerInput & { id: string }
export type InitialMemberData = Partial<Customer> & {
  isCurrentUser?: boolean
  temporaryId?: string
}
export type TemporaryCustomer = InitialMemberData & { temporaryId: string }

export enum ROLE {
  CEO = 'CEO',
  SECREATRY = 'SECRETARY',
  OTHER = 'OTHER',
}

export enum Roles {
  beneficialOwner = 'beneficialOwner',
  beneficialOwnerAlt = 'beneficialOwnerAlt',
  partner = 'partner',
  director = 'director',
  beneficiary = 'beneficiary',
  trustee = 'trustee',
  chair = 'chair',
  secretary = 'secretary',
  treasurer = 'treasurer',
  ceo = 'ceo',
}

/* eslin-enable */
