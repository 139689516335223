import {
  PopperItemOneLine,
  PopperItemOneLineSize,
} from '@npco/zeller-design-system'

import { ReactComponent as Tick } from 'assets/svg/tick.svg'

import * as styled from './SortList.styled'

export interface SortListProps {
  dataTestId?: string
  sort?: boolean | null
  onClick: (ascending: boolean) => void
  ascendingLabel: string
  descendingLabel: string
}

export const SortList = ({
  dataTestId,
  sort,
  onClick: handleClick,
  ascendingLabel,
  descendingLabel,
}: SortListProps) => {
  return (
    <styled.Wrapper
      as="ul"
      data-testid={dataTestId}
      flexDirection="column"
      padding="0"
    >
      <PopperItemOneLine
        data-testid="sort-ascending"
        icon={() => (sort === true ? <Tick title="Tick" /> : null)}
        label={ascendingLabel}
        onClick={() => handleClick(true)}
        shouldDisplayIcon
        size={PopperItemOneLineSize.Small}
      />
      <PopperItemOneLine
        data-testid="sort-descending"
        icon={() => (sort === false ? <Tick title="Tick" /> : null)}
        label={descendingLabel}
        onClick={() => handleClick(false)}
        shouldDisplayIcon
        size={PopperItemOneLineSize.Small}
      />
    </styled.Wrapper>
  )
}
