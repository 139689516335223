import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { ConnectionType } from '@npco/mp-gql-types'
import {
  DrawerWithIcon,
  Flex,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { Formik, FormikErrors } from 'formik'

import { ReactComponent as XeroLogo } from 'assets/svg/logos/xero.svg'
import { useAbsolutePath } from 'hooks/useAbsolutePath'
import { useQueryParams } from 'hooks/useQueryParams'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'

import { Syncing } from '../components/Syncing'
import { useCompleteConnectionOAuthFlow } from '../hooks/useCompleteConnectionOAuthFlow'
import { useConfigureXeroBankfeeds } from './hooks/useConfigureXeroBankfeeds'
import { Values } from './OrganisationAccountForm.types'
import { StepOne } from './StepOne'
import { StepTwo } from './StepTwo'
import { sleep, updateOnSuccess } from './xeroBankFeeds.utils'

const initialValues: Values = {
  step: 1,
  accounts: [],
  organisation: '',
}

export const OrganisationAccountForm = () => {
  const navigate = useNavigate()
  const params = useQueryParams()
  const absolutePath = useAbsolutePath()
  const { completeConnectionOAuthFlow } = useCompleteConnectionOAuthFlow()
  const { configureXeroBankfeeds } = useConfigureXeroBankfeeds()
  const [isFinalisingConnection, setIsFinalisingConnection] = useState(true)

  const handleConfigure = useCallback(
    async (values: Required<Values>) => {
      const bankAccountsEnabled = values?.accounts.map((account) => ({
        debitCardAccountUuid: account,
      }))
      const configResult = configureXeroBankfeeds({
        variables: {
          config: {
            xeroOrganisationUuid: values.organisation,
            bankAccountsEnabled,
          },
        },
        update: updateOnSuccess,
      })
      // wait at least 2 seconds to finish connecting, giving time to the user to read the message
      await Promise.all([configResult, sleep(2000)])
      showSuccessToast(
        translate(
          'page.settings.connections.xeroBankFeeds.toast.connectionSuccess'
        )
      )
      navigate(ROOT.PORTAL.SETTINGS.CONNECTIONS.path)
    },
    [navigate, configureXeroBankfeeds]
  )

  const handleXeroOauthConnection = useCallback(async () => {
    const authCode = params.get('code')

    if (!authCode) {
      navigate(ROOT.PORTAL.SETTINGS.CONNECTIONS.path)
      return
    }
    await completeConnectionOAuthFlow({
      variables: {
        connectionType: ConnectionType.XERO_BANKFEEDS,
        url: absolutePath,
      },
    })

    setIsFinalisingConnection(false)
  }, [
    params,
    absolutePath,
    navigate,
    completeConnectionOAuthFlow,
    setIsFinalisingConnection,
  ])

  useEffect(() => {
    handleXeroOauthConnection()
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DrawerWithIcon
      isOpen
      icon={
        <Flex justifyContent="left">
          <XeroLogo width={48} height={48} />
        </Flex>
      }
      drawerTitle={translate('page.settings.connections.xeroBankFeeds.title')}
      onClose={() => navigate(ROOT.PORTAL.SETTINGS.CONNECTIONS.path)}
      overlayClassName="animated-drawer-overlay"
    >
      <Flex flexDirection="column" width="100%" alignItems="center">
        {isFinalisingConnection && (
          <Syncing connectionType={ConnectionType.XERO_BANKFEEDS} />
        )}
        {!isFinalisingConnection && (
          <Formik<Values>
            onSubmit={handleConfigure}
            initialValues={initialValues}
            validate={(values) => {
              const errors: FormikErrors<Values> = {}
              if (values.accounts?.length === 0) {
                errors.accounts = translate(
                  'page.settings.connections.xeroBankFeeds.errors.noAccountSelected'
                )
              }
              return errors
            }}
          >
            {({ values, handleSubmit, isSubmitting }) => (
              <>
                {isSubmitting && (
                  <Syncing connectionType={ConnectionType.XERO_BANKFEEDS} />
                )}
                {!isSubmitting && (
                  <form onSubmit={handleSubmit}>
                    {values.step === 1 && <StepOne />}
                    {values.step === 2 && <StepTwo />}
                  </form>
                )}
              </>
            )}
          </Formik>
        )}
      </Flex>
    </DrawerWithIcon>
  )
}
