import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useTranslations } from '@npco/utils-translations'
import {
  Breadcrumb,
  BUTTON_SIZE,
  ButtonFill,
  Flex,
  PageTemplate,
} from '@npco/zeller-design-system'

import { useGetSites } from 'hooks/useGetSites'
import { ROOT } from 'const/routes'
import { SitesList } from 'pages/Settings/Sites/SitesList/SitesList'
import { page } from 'translations'

import { translations } from './Sites.i18n'
import { SitesContext } from './SitesContext/SitesContext'

export const Sites = () => {
  const t = useTranslations(translations)
  const navigate = useNavigate()
  const { userRole } = useLoggedInUser()
  const { sites, isLoading, isLoadingMore, onLoadMore, hasMore } = useGetSites()
  const hasAddSiteButton =
    !isLoading && !sites.length && userRole === CustomerRole.ADMIN

  const value = useMemo(
    () => ({ sites, isLoading, isLoadingMore, onLoadMore, hasMore }),
    [sites, isLoading, isLoadingMore, onLoadMore, hasMore]
  )

  const onAddSite = useCallback(() => {
    navigate(ROOT.PORTAL.PAYMENTS.SITES.CREATE_SITE.path)
  }, [navigate])

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex height="48px" width="100%" justifyContent="space-between">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Breadcrumb.Text>{page.title.sites}</Breadcrumb.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
            {hasAddSiteButton && (
              <ButtonFill size={BUTTON_SIZE.MEDIUM} onClick={onAddSite}>
                {t('buttonLabel')}
              </ButtonFill>
            )}
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      <SitesContext.Provider value={value}>
        <SitesList />
      </SitesContext.Provider>
    </PageTemplate>
  )
}
