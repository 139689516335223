import { gql } from '@apollo/client'
import { DebitCardStatus } from '@npco/mp-gql-types'

import { TestCardQuery_getDebitCardV2 as DebitCardV2 } from 'types/gql-types/TestCardQuery'

export const TestCardQuery = gql`
  query TestCardQuery($cardId: ID!) {
    getDebitCardV2(cardId: $cardId) {
      __typename
      id
      status
      name
    }
  }
`

export const CARD: DebitCardV2 = {
  __typename: 'DebitCardV2',
  name: 'Card Name',
  status: DebitCardStatus.ACTIVE,
  id: 'card-id',
}
