import { useQuery } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetSitesWithTypes } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/sites'
import { rvSiteTypeList } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import {
  GetSitesWithTypes as GetSitesWithTypesResponse,
  GetSitesWithTypesVariables,
} from 'types/gql-types/GetSitesWithTypes'
import { SiteWithType } from 'types/site'

export const limit = 100

interface UseQuerySitesWithTypesProps {
  shouldSkipQuery?: boolean
}

export const useQuerySitesWithTypes = ({
  shouldSkipQuery = false,
}: UseQuerySitesWithTypesProps) => {
  const { loading: isLoading } = useQuery<
    GetSitesWithTypesResponse,
    GetSitesWithTypesVariables
  >(GetSitesWithTypes, {
    variables: {
      limit,
    },
    fetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
    skip: shouldSkipQuery,
    onError: (error) => {
      showApiErrorToast(error)
    },
    onCompleted: (data) => {
      if (!data?.getSites) {
        showApiErrorToast()
        return
      }
      if (data.getSites.sites.length) {
        rvSiteTypeList(data.getSites.sites as SiteWithType[])
      }
    },
  })

  return { isLoading }
}
