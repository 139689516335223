import { useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'

import { isNotNull } from 'utils/common'
import { HlPosConfiguration as HlPosPairConfigResponse } from 'types/gql-types/HlPosConfiguration'

export const HlPosPairConfiguration = gql`
  query HlPosConfiguration {
    getHlPosPairConfiguration {
      venueId
      locationId
      siteUuid
      stationId
    }
    getHlPosConfiguration {
      venues {
        id
        name
        locations {
          id
          name
        }
      }
    }
  }
`

export const useHlPosConfiguration = () => {
  const { loading, data, error, refetch } = useQuery<HlPosPairConfigResponse>(
    HlPosPairConfiguration,
    {
      onError: (err) => {
        showApiErrorToast(err)
      },
    }
  )

  const { venues, posPairState } = useMemo(() => {
    const venuesArray = data?.getHlPosConfiguration?.venues || []
    const transformedVenues = venuesArray.filter(isNotNull).map((venue) => ({
      id: venue.id,
      name: venue.name,
      locations: venue.locations ?? [],
    }))
    const localPosPairState = data?.getHlPosPairConfiguration ?? []
    return { venues: transformedVenues, posPairState: localPosPairState }
  }, [data])

  return {
    loading,
    error,
    posPairState,
    venues,
    refetch,
  }
}
