import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'
import { PortalKYCLayout } from 'pages/PortalKYC/components/PortalKYCLayout'

import { BiometricsPageTemplate } from './BiometricsPageTemplate'

export const BiometricsPageDeps = {
  BiometricsPageTemplate,
}

export const BiometricsPage = () => {
  const { BiometricsPageTemplate } = BiometricsPageDeps

  const navigate = useNavigate()
  const ctaSaveAndClose = useMemo(
    () => ({
      onClick: () => navigate(ROOT.PORTAL.SETTINGS.PROFILE.SECURITY.path),
    }),
    [navigate]
  )

  return (
    <PortalKYCLayout ctaSaveAndClose={ctaSaveAndClose}>
      <BiometricsPageTemplate
        onSubmitRoute={ROOT.PORTAL.BIOMETRICS.ONFIDO.path}
      />
    </PortalKYCLayout>
  )
}
