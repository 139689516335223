import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'

import { GetRecentlyUsedLineItems as GetRecentlyUsedLineItemsResponse } from 'types/gql-types/GetRecentlyUsedLineItems'

import { GetRecentlyUsedLineItems } from '../graphql/getRecentlyUsedLineItems'

export const useGetRecentlyUsedItems = (
  hookOptions?: LazyQueryHookOptions<GetRecentlyUsedLineItemsResponse>
) => {
  const [getRecentlyUsedLineItems, { data, loading: isLoading }] =
    useLazyQuery<GetRecentlyUsedLineItemsResponse>(
      GetRecentlyUsedLineItems,
      hookOptions
    )

  const items = data?.getRecentlyUsedLineItems

  return {
    getRecentlyUsedLineItems,
    isLoading,
    items,
  }
}
