import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  body: 'Are you sure you want to delete all items? This action cannot be undone.',
  deleteAllCheckboxLabel: 'Delete all categories and items',
  deleteAllError:
    'Items and categories could not be deleted. Please try again.',
  deleteAllSuccess: 'Items and categories deleted successfully.',
  deleteItemsError: 'Items could not be deleted. Please try again.',
  deleteItemsSuccess: 'Items deleted successfully.',
  infoBoxMessage: 'Your categories will not be deleted.',
  title: 'Delete All Items',
})
