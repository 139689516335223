import { createContext, ReactNode, useContext, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useModalState } from '@npco/zeller-design-system'

import { GetItemManagementLocalState } from '../../graphql/getItemManagementLocalState'
import { useItemManagementLocalCache } from '../../hooks/useItemManagementLocalCache'
import { ItemManagementDefaultLocalState } from '../../ItemManagement.constants'
import { CacheLocalStateItemManagement } from '../../ItemManagement.types'
import { DEFAULT_GET_LIMIT } from '../../ItemManagement.utils'
import { useGetCategoriesTable } from './hooks/useGetCategoriesTable'
import { useGetFilterCategories } from './hooks/useGetFilterCategories'
import { useGetItemsTable } from './hooks/useGetItemsTable'
import { ItemManagementContextType } from './ItemManagementContext.types'

export const ItemManagementContext = createContext<
  ItemManagementContextType | undefined
>(undefined)

ItemManagementContext.displayName = 'Item Management Context'

interface ItemManagementProviderProps {
  children: ReactNode | ReactNode[]
}

export const ItemManagementProvider = ({
  children,
}: ItemManagementProviderProps) => {
  const { data } = useQuery<CacheLocalStateItemManagement>(
    GetItemManagementLocalState
  )

  const {
    setCategoriesFilterInput,
    setCategoriesSortInput,
    setHasSeenItemOnboarding,
    setItemsFilterInput,
    setItemsSortInput,
    setTextSearchFilter,
  } = useItemManagementLocalCache()

  const {
    isModalOpen: isCategoriesMobileFiltersOpen,
    openModal: openCategoriesMobileFilters,
    closeModal: closeCategoriesMobileFilters,
  } = useModalState()

  const {
    isModalOpen: isItemsMobileFiltersOpen,
    openModal: openItemsMobileFilters,
    closeModal: closeItemsMobileFilters,
  } = useModalState()

  const {
    categories: {
      filterInput: defaultCategoriesFilterInput,
      sortInput: defaultCategoriesSortInput,
    },
    items: {
      filterInput: defaultItemsFilterInput,
      sortInput: defaultItemsSortInput,
    },
    hasSeenItemOnboarding: defaultHasSeenItemOnboarding,
  } = ItemManagementDefaultLocalState

  const categoriesFilterInput =
    data?.local.itemManagement.categories?.filterInput ??
    defaultCategoriesFilterInput

  const categoriesSortInput =
    data?.local?.itemManagement?.categories?.sortInput ??
    defaultCategoriesSortInput

  const itemsFilterInput =
    data?.local?.itemManagement?.items?.filterInput ?? defaultItemsFilterInput

  const itemsSortInput =
    data?.local?.itemManagement?.items?.sortInput ?? defaultItemsSortInput

  const isDefaultCategoriesFilters =
    categoriesFilterInput === defaultCategoriesFilterInput ||
    !categoriesFilterInput?.textSearchFilter

  const isDefaultCategoriesSort =
    categoriesSortInput === defaultCategoriesSortInput

  const hasItemsFiltersApplied =
    !!itemsFilterInput?.categoryUuidFilter?.categoryUuids?.length ||
    !!itemsFilterInput?.priceFilter ||
    !!itemsFilterInput?.textSearchFilter?.length

  const isDefaultItemsFilters =
    itemsFilterInput === defaultItemsFilterInput || !hasItemsFiltersApplied

  const isDefaultItemsSort = itemsSortInput === defaultItemsSortInput

  const hasSeenItemOnboarding =
    data?.local?.itemManagement?.hasSeenItemOnboarding ??
    defaultHasSeenItemOnboarding

  const {
    categories,
    getCategories,
    hasError: hasCategoriesError,
    hasMore: hasMoreCategories,
    hasNoInitialResults: hasNoInitialCategories,
    hasNoResults: hasNoCategories,
    isLoading: isLoadingCategories,
    loadMore: loadMoreCategories,
    nextToken: categoriesNextToken,
  } = useGetCategoriesTable({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      limit: DEFAULT_GET_LIMIT,
      filter: undefined,
      nextToken: undefined,
      sort: undefined,
    },
  })

  const {
    getItems,
    hasError: hasItemsError,
    hasMore: hasMoreItems,
    hasNoInitialResults: hasNoInitialItems,
    hasNoResults: hasNoItems,
    isLoading: isLoadingItems,
    items,
    loadMore: loadMoreItems,
    nextToken: itemsNextToken,
  } = useGetItemsTable({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      limit: DEFAULT_GET_LIMIT,
      filter: undefined,
      nextToken: undefined,
      sort: undefined,
    },
  })

  const {
    categories: filterCategories,
    getFilterCategories,
    hasMore: hasMoreFilterCategories,
    isLoading: isLoadingFilterCategories,
    loadMore: loadMoreFilterCategories,
    nextToken: filterCategoriesNextToken,
  } = useGetFilterCategories({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      limit: DEFAULT_GET_LIMIT,
      filter: undefined,
      nextToken: undefined,
      sort: undefined,
    },
  })

  const hasNoItemsAndCategories = hasNoItems && hasNoCategories

  const value = useMemo(
    () =>
      ({
        categories: {
          closeMobileFilters: closeCategoriesMobileFilters,
          data: categories,
          filterInput: categoriesFilterInput,
          getCategories,
          hasError: hasCategoriesError,
          hasMore: hasMoreCategories,
          hasNoInitialResults: hasNoInitialCategories,
          hasNoResults: hasNoCategories,
          isDefaultFilters: isDefaultCategoriesFilters,
          isDefaultSort: isDefaultCategoriesSort,
          isLoading: isLoadingCategories,
          isMobileFiltersOpen: isCategoriesMobileFiltersOpen,
          loadMore: loadMoreCategories,
          nextToken: categoriesNextToken,
          openMobileFilters: openCategoriesMobileFilters,
          setFilterInput: setCategoriesFilterInput,
          setSortInput: setCategoriesSortInput,
          sortInput: categoriesSortInput,
        },
        filterCategories,
        getFilterCategories,
        items: {
          closeMobileFilters: closeItemsMobileFilters,
          data: items,
          getItems,
          filterInput: itemsFilterInput,
          hasError: hasItemsError,
          hasMore: hasMoreItems,
          hasNoInitialResults: hasNoInitialItems,
          hasNoResults: hasNoItems,
          isDefaultFilters: isDefaultItemsFilters,
          isDefaultSort: isDefaultItemsSort,
          isLoading: isLoadingItems,
          isMobileFiltersOpen: isItemsMobileFiltersOpen,
          loadMore: loadMoreItems,
          nextToken: itemsNextToken,
          openMobileFilters: openItemsMobileFilters,
          setFilterInput: setItemsFilterInput,
          setSortInput: setItemsSortInput,
          sortInput: itemsSortInput,
        },
        filterCategoriesNextToken,
        hasMoreFilterCategories,
        hasNoItemsAndCategories,
        hasSeenItemOnboarding,
        isLoadingFilterCategories,
        loadMoreFilterCategories,
        setHasSeenItemOnboarding,
        setTextSearchFilter,
      } satisfies ItemManagementContextType),
    [
      categories,
      categoriesFilterInput,
      categoriesNextToken,
      categoriesSortInput,
      closeCategoriesMobileFilters,
      closeItemsMobileFilters,
      filterCategories,
      filterCategoriesNextToken,
      getCategories,
      getFilterCategories,
      getItems,
      hasCategoriesError,
      hasItemsError,
      hasMoreCategories,
      hasMoreFilterCategories,
      hasMoreItems,
      hasNoCategories,
      hasNoInitialCategories,
      hasNoInitialItems,
      hasNoItems,
      hasNoItemsAndCategories,
      hasSeenItemOnboarding,
      isCategoriesMobileFiltersOpen,
      isDefaultCategoriesFilters,
      isDefaultCategoriesSort,
      isDefaultItemsFilters,
      isDefaultItemsSort,
      isItemsMobileFiltersOpen,
      isLoadingCategories,
      isLoadingFilterCategories,
      isLoadingItems,
      items,
      itemsFilterInput,
      itemsNextToken,
      itemsSortInput,
      loadMoreCategories,
      loadMoreFilterCategories,
      loadMoreItems,
      openCategoriesMobileFilters,
      openItemsMobileFilters,
      setCategoriesFilterInput,
      setCategoriesSortInput,
      setHasSeenItemOnboarding,
      setItemsFilterInput,
      setItemsSortInput,
      setTextSearchFilter,
    ]
  )

  return (
    <ItemManagementContext.Provider value={value}>
      {children}
    </ItemManagementContext.Provider>
  )
}

export const useItemManagementContext = () => {
  const context = useContext(ItemManagementContext)

  if (!context) {
    throw new Error(
      'useItemManagementContext must be used within ItemManagementContext'
    )
  }

  return context
}
