import { useCallback, useEffect } from 'react'
import {
  rvCurrentUserData,
  useCustomerQuery,
} from '@npco/mp-utils-logged-in-user'
import { useOnboardingEntityStatus } from 'features/OnboardingApp/shared/useOnboardingEntityStatus'

import { useEntityQuery } from 'hooks/useEntityQuery'
import { sendToDataLayer } from 'utils/gtmHelper'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import { setSessionStorageItem } from 'services/sessionStorage/utils'

import {
  useTokenUserMetadata,
  useZellerAuthenticationContext,
} from './ZellerAuthenticationContext'

export const useFetchSetupData = () => {
  const tokenCustomerUuid = useTokenUserMetadata()?.customerUuid
  const { fetchTokenClaims } = useZellerAuthenticationContext()
  const { logAnalyticsGroup } = useAnalyticsLogger()
  const { identifyAnalyticsUser } = useAnalyticsLogger()
  const { navigateUserAccordingToOnboardingStatus } =
    useOnboardingEntityStatus()

  const {
    getCustomerData,
    loading: isFetchCustomerLoading,
    called: isFetchCustomerCalled,
    error: fetchCustomerError,
    customer,
  } = useCustomerQuery()
  const {
    getEntityData,
    loading: isFetchEntityLoading,
    called: isFetchEntityCalled,
    error: fetchEntityError,
    entity,
  } = useEntityQuery()

  useEffect(() => {
    if (!entity || !customer) {
      return
    }

    const { id, category, categoryGroup, type } = entity
    const entityTraits = {
      entityId: id,
      entityCategory: category,
      entityCategoryGroup: categoryGroup,
      entityType: type,
    }

    identifyAnalyticsUser(customer.id, {
      ...entityTraits,
      role: customer.role,
    })
    logAnalyticsGroup(id, entityTraits)
    sendToDataLayer({ event: 'Login' })
    navigateUserAccordingToOnboardingStatus(entity, customer)
    setSessionStorageItem('redirect', false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, customer, identifyAnalyticsUser, logAnalyticsGroup])

  const getEntityAndCustomer = useCallback(() => {
    const customerUuid = tokenCustomerUuid ?? rvCurrentUserData()?.id ?? ''
    getEntityData()
    getCustomerData({ variables: { customerUuid } })
  }, [tokenCustomerUuid, getCustomerData, getEntityData])

  const fetchSetupData = useCallback(async () => {
    const customerUuid = tokenCustomerUuid
    if (!customerUuid) {
      await fetchTokenClaims()
    }
    getEntityAndCustomer()
  }, [fetchTokenClaims, tokenCustomerUuid, getEntityAndCustomer])

  return {
    fetchSetupData,
    isFetchSetupDataCalled: isFetchEntityCalled && isFetchCustomerCalled,
    isFetchSetupDataLoading: isFetchEntityLoading || isFetchCustomerLoading,
    fetchSetupDataError: fetchEntityError || fetchCustomerError,
  }
}
