import { useQuery } from '@apollo/client'
import { showErrorToast } from '@npco/zeller-design-system'
import { GetReceiptSettingsLogoUploadUrl } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/sites'

import { translate } from 'utils/translations'
import {
  GetReceiptSettingsLogoUploadUrl as GetReceiptSettingsLogoUploadUrlPayload,
  GetReceiptSettingsLogoUploadUrlVariables,
} from 'types/gql-types/GetReceiptSettingsLogoUploadUrl'
import { fetchUploadLogoToS3Bucket } from 'components/ModalUploadLogo/ModalUploadLogo.utils'

export const useCompanyLogoS3Bucket = ({ siteUuid }: { siteUuid?: string }) => {
  const { refetch } = useQuery<
    GetReceiptSettingsLogoUploadUrlPayload,
    GetReceiptSettingsLogoUploadUrlVariables
  >(GetReceiptSettingsLogoUploadUrl, {
    fetchPolicy: 'network-only',
    // always fetch fresh in uploadCompanyLogoToS3Bucket
    skip: true,
  })

  const uploadCompanyLogoToS3Bucket = async (file: File) => {
    try {
      // NOTE: file name must be sent so backend can determine that it needs to
      // handle special file types such as heic/heif files
      const res = await refetch({
        siteUuid,
        fileName: file.name,
      })

      if (res?.data.getReceiptSettingsLogoUploadUrl) {
        await fetchUploadLogoToS3Bucket({
          s3BucketUrl: res?.data.getReceiptSettingsLogoUploadUrl,
          file,
        })
      }
    } catch (err) {
      showErrorToast(translate('page.invoicesSettings.errorToast'))
    }
  }

  return {
    uploadCompanyLogoToS3Bucket,
  }
}
