import { gql, type TypedDocumentNode } from '@apollo/client'

import { CategoryDeleteModalItemsReassignFragmentDoc } from './CategoryDeleteModalItemsReassign.generated'

export type CategoryDeleteModalFragment = {
  __typename?: 'ItemCategory'
  id: string
  name: string
}

export const CategoryDeleteModalFragmentDoc = gql`
  fragment CategoryDeleteModalFragment on ItemCategory {
    id
    name
    ...CategoryDeleteModalItemsReassignFragment
  }
  ${CategoryDeleteModalItemsReassignFragmentDoc}
` as unknown as TypedDocumentNode<CategoryDeleteModalFragment, undefined>
