import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  InvoiceAction,
  InvoiceActivityStatus,
  InvoiceStatus,
} from '@npco/mp-gql-types'
import { renameAndDownloadFile } from '@npco/utils-file'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { useInvoiceAnalytics } from 'features/Invoicing/components/Invoices/hooks/useInvoiceAnalytics'

import { ROOT } from 'const/routes'
import { copyToClipboard } from 'utils/common'
import { translate } from 'utils/translations'
import {
  InvoiceDetailsFragment,
  InvoiceDetailsFragment_activities as Activity,
  InvoiceDetailsFragment_payments as Payment,
} from 'types/gql-types/InvoiceDetailsFragment'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { FileWithId } from 'components/File'

import {
  getInvoiceActionOnPartFailedSend,
  getLatestSendInvoiceActivity,
  InvoiceDecisionModalTypes,
} from '../InvoiceDrawerDetails.utils'
import {
  formatDate,
  getDifferenceInDays,
  getEarliestScheduledReminder,
  getLastPayment,
} from './useInvoiceActionPrompt.utils'
import { useInvoiceDecisionModal } from './useInvoiceDecisionModal'
import { useSendReminder } from './useSendReminder'
import { useSkipReminder } from './useSkipReminder'

export const translations = {
  draftHeading: translate('component.invoiceDrawer.actionPrompt.draftHeading'),
  draftDescription: translate(
    'component.invoiceDrawer.actionPrompt.draftDescription'
  ),
  sentNoRemindersHeading: (date: string) =>
    translate('component.invoiceDrawer.actionPrompt.sentNoRemindersHeading', {
      date,
    }),
  sentEmailAndSMSNoRemindersDescription: translate(
    'component.invoiceDrawer.actionPrompt.sentEmailAndSMSNoRemindersDescription'
  ),
  sentEmailNoRemindersDescription: translate(
    'component.invoiceDrawer.actionPrompt.sentEmailNoRemindersDescription'
  ),
  sentSMSNoRemindersDescription: translate(
    'component.invoiceDrawer.actionPrompt.sentSMSNoRemindersDescription'
  ),
  nextReminderHeading: translate(
    'component.invoiceDrawer.actionPrompt.nextReminderHeading'
  ),
  nextReminderDescription: (days: number) => {
    if (days === 0) {
      return translate(
        'component.invoiceDrawer.actionPrompt.nextReminderDescriptionToday'
      )
    }

    if (days === 1) {
      return translate(
        'component.invoiceDrawer.actionPrompt.nextReminderDescriptionTomorrow'
      )
    }

    return translate(
      'component.invoiceDrawer.actionPrompt.nextReminderDescription',
      {
        days: days.toString(),
      }
    )
  },
  overdueHeading: translate(
    'component.invoiceDrawer.actionPrompt.overdueHeading'
  ),
  overdueEmailAndSmsDescription: translate(
    'component.invoiceDrawer.actionPrompt.overdueEmailAndSmsDescription'
  ),
  overdueEmailDescription: translate(
    'component.invoiceDrawer.actionPrompt.overdueEmailDescription'
  ),
  overdueSMSDescription: translate(
    'component.invoiceDrawer.actionPrompt.overdueSMSDescription'
  ),
  paidHeading: translate('component.invoiceDrawer.actionPrompt.paidHeading'),
  paidDescription: (date: string) =>
    translate('component.invoiceDrawer.actionPrompt.paidDescription', { date }),
  cancelledHeading: translate(
    'component.invoiceDrawer.actionPrompt.cancelledHeading'
  ),
  cancelledDescription: (date: string) =>
    translate('component.invoiceDrawer.actionPrompt.cancelledDescription', {
      date,
    }),
  failedToSendDescription: translate(
    'component.invoiceDrawer.actionPrompt.failedToSendDescription'
  ),
  failedToSendEmailDescription: translate(
    'component.invoiceDrawer.actionPrompt.failedToSendEmailDescription'
  ),
  failedToSendSmsDescription: translate(
    'component.invoiceDrawer.actionPrompt.failedToSendSmsDescription'
  ),
  failedToSendHeading: translate(
    'component.invoiceDrawer.actionPrompt.failedToSendHeading'
  ),
  failedToSendEmailHeading: translate(
    'component.invoiceDrawer.actionPrompt.failedToSendEmailHeading'
  ),
  failedToSendSmsHeading: translate(
    'component.invoiceDrawer.actionPrompt.failedToSendSmsHeading'
  ),
  scheduleDescription: ({ days, date }: { days: number; date: string }) => {
    if (days === 0) {
      return translate(
        'component.invoiceDrawer.actionPrompt.scheduleDescriptionToday'
      )
    }

    if (days === 1) {
      return translate(
        'component.invoiceDrawer.actionPrompt.scheduleDescriptionTomorrow'
      )
    }

    return translate(
      'component.invoiceDrawer.actionPrompt.scheduleDescription',
      { date }
    )
  },
  scheduleHeading: (days: number) => {
    if (days === 0) {
      return translate(
        'component.invoiceDrawer.actionPrompt.scheduleHeadingToday'
      )
    }

    if (days === 1) {
      return translate(
        'component.invoiceDrawer.actionPrompt.scheduleHeadingTomorrow'
      )
    }

    return translate('component.invoiceDrawer.actionPrompt.scheduleHeading', {
      days,
    })
  },
  actions: {
    cancel: translate('component.invoiceDrawer.actionPrompt.actions.cancel'),
    copyLink: translate(
      'component.invoiceDrawer.actionPrompt.actions.copyLink'
    ),
    copyLinkSuccess: translate(
      'component.invoiceDrawer.actionPrompt.actions.copyLinkSuccess'
    ),
    copyLinkError: translate(
      'component.invoiceDrawer.actionPrompt.actions.copyLinkError'
    ),
    delete: translate('component.invoiceDrawer.actionPrompt.actions.delete'),
    download: translate(
      'component.invoiceDrawer.actionPrompt.actions.download'
    ),
    downloadPdf: translate(
      'component.invoiceDrawer.actionPrompt.actions.downloadPdf'
    ),
    downloadPdfError: (referenceNumber: string) =>
      translate(
        'component.invoiceDrawer.actionPrompt.actions.downloadPdfError',
        { referenceNumber }
      ),
    duplicate: translate(
      'component.invoiceDrawer.actionPrompt.actions.duplicate'
    ),
    edit: translate('component.invoiceDrawer.actionPrompt.actions.edit'),
    editInvoice: translate(
      'component.invoiceDrawer.actionPrompt.actions.editInvoice'
    ),
    recordPayment: translate(
      'component.invoiceDrawer.actionPrompt.actions.recordPayment'
    ),
    resend: translate('component.invoiceDrawer.actionPrompt.actions.resend'),
    remind: translate('component.invoiceDrawer.actionPrompt.actions.remind'),
    remindNow: translate(
      'component.invoiceDrawer.actionPrompt.actions.remindNow'
    ),
    sendNow: translate('component.invoiceDrawer.actionPrompt.actions.sendNow'),
    sendReminder: translate(
      'component.invoiceDrawer.actionPrompt.actions.sendReminder'
    ),
    sendSuccess: translate(
      'component.invoiceDrawer.actionPrompt.actions.sendSuccess'
    ),
    sendError: translate(
      'component.invoiceDrawer.actionPrompt.actions.sendError'
    ),
    skip: translate('component.invoiceDrawer.actionPrompt.actions.skip'),
    skipSuccess: translate(
      'component.invoiceDrawer.actionPrompt.actions.skipSuccess'
    ),
    skipError: translate(
      'component.invoiceDrawer.actionPrompt.actions.skipError'
    ),
    markAsPaid: translate(
      'component.invoiceDrawer.actionPrompt.actions.markAsPaid'
    ),
  },
  getFileName: (referenceNumber: string) =>
    translate('page.invoice.previewModal.downloadFileName', {
      referenceNumber,
    }),
}

const getActivityDeliveryResults = (activity: Activity | null) => {
  const isEmailSuccess =
    activity?.invoiceEmailDeliveryResult?.failed === false || false

  const isSMSSuccess =
    activity?.invoiceSmsDeliveryResult?.failed === false || false

  return { isEmailSuccess, isSMSSuccess }
}

const getFailedSendDescription = (activity: Activity) => {
  const { isEmailSuccess, isSMSSuccess } = getActivityDeliveryResults(activity)

  if (!isEmailSuccess && !isSMSSuccess) {
    return translations.failedToSendDescription
  }

  return !isEmailSuccess
    ? translations.failedToSendEmailDescription
    : translations.failedToSendSmsDescription
}

const getSentDescription = (activity: Activity) => {
  // NOTE: Safety check when cache is not yet updated with the latest invoice
  // details
  if (!activity) {
    return ''
  }

  const { isEmailSuccess, isSMSSuccess } = getActivityDeliveryResults(activity)

  if (isEmailSuccess && isSMSSuccess) {
    return translations.sentEmailAndSMSNoRemindersDescription
  }

  return isEmailSuccess
    ? translations.sentEmailNoRemindersDescription
    : translations.sentSMSNoRemindersDescription
}

export const getFailedSendContent = (
  status: InvoiceStatus,
  activities: Activity[] | null
) => {
  const partFailedSendAction = getInvoiceActionOnPartFailedSend(
    status,
    activities
  )

  if (partFailedSendAction) {
    if (partFailedSendAction.type === InvoiceAction.SEND_EMAIL_INVOICE) {
      return {
        heading: translations.failedToSendEmailHeading,
        description: translations.failedToSendEmailDescription,
        type: partFailedSendAction,
      }
    }

    if (partFailedSendAction.type === InvoiceAction.SEND_SMS_INVOICE) {
      return {
        heading: translations.failedToSendSmsHeading,
        description: translations.failedToSendSmsDescription,
        type: partFailedSendAction,
      }
    }

    if (partFailedSendAction.type === InvoiceAction.SEND_INVOICE) {
      return {
        heading: translations.failedToSendHeading,
        description: getFailedSendDescription(partFailedSendAction),
        type: partFailedSendAction,
      }
    }
  }

  return null
}

const getOverdueDescription = (details: InvoiceDetailsFragment) => {
  const activity = getLatestSendInvoiceActivity(details.activities)

  // NOTE: Safety check when cache is not yet updated with the latest invoice
  // details
  if (!activity) {
    return ''
  }

  const { isEmailSuccess, isSMSSuccess } = getActivityDeliveryResults(activity)

  if (isEmailSuccess && isSMSSuccess) {
    return translations.overdueEmailAndSmsDescription
  }

  return isEmailSuccess
    ? translations.overdueEmailDescription
    : translations.overdueSMSDescription
}

interface ActionType {
  hasBottomDivider?: boolean
  label: string
  onClick: () => void
}
interface ActionsProp {
  [key: string]: ActionType
}

const getDefaultQuickAndDropdownActions = (
  details: InvoiceDetailsFragment,
  actions: ActionsProp
) => {
  const activity = getLatestSendInvoiceActivity(details.activities)

  const { isEmailSuccess, isSMSSuccess } = getActivityDeliveryResults(activity)

  const isEmailEnabled = Boolean(details.email?.enabled)
  const isSMSEnabled = Boolean(details.sms?.enabled)

  // NOTE: resend option will force user to update email when previously unsuccessful
  const quickActions =
    (isEmailEnabled && isEmailSuccess) || (isSMSEnabled && isSMSSuccess)
      ? [actions.resend, actions.remind]
      : [actions.resend]

  const dropdownActions =
    (isEmailEnabled && isEmailSuccess) || (isSMSEnabled && isSMSSuccess)
      ? [
          actions.editInvoice,
          actions.sendReminder,
          actions.cancel,
          actions.downloadPdf,
          actions.duplicate,
          actions.recordPayment,
          actions.markAsPaid,
          actions.resend,
          actions.copyLink,
        ]
      : [
          actions.editInvoice,
          actions.cancel,
          actions.downloadPdf,
          actions.duplicate,
          actions.recordPayment,
          actions.markAsPaid,
          actions.resend,
          actions.copyLink,
        ]

  return {
    dropdownActions,
    quickActions,
  }
}

const getDraftActionDetails = (actions: ActionsProp) => {
  return {
    heading: translations.draftHeading,
    description: translations.draftDescription,
    quickActions: [actions.edit],
    dropdownActions: [
      { ...actions.editInvoice, hasBottomDivider: true },
      actions.delete,
      actions.downloadPdf,
      actions.duplicate,
    ],
  }
}

const getFailedSendActionDetails = (
  heading: string,
  description: string,
  actions: ActionsProp
) => {
  const dropdownActions = [
    { ...actions.resend, hasBottomDivider: true },
    actions.editInvoice,
    actions.cancel,
    actions.downloadPdf,
    actions.duplicate,
    actions.recordPayment,
    actions.markAsPaid,
    actions.copyLink,
  ]

  return {
    heading,
    description,
    quickActions: [actions.resend],
    dropdownActions,
  }
}

const getScheduledActionDetails = (
  details: InvoiceDetailsFragment,
  actions: ActionsProp
) => {
  const scheduledActivity = details.activities?.find(
    ({ type }) => type === 'SEND_SCHEDULED_INVOICE'
  )

  const scheduledInDays = getDifferenceInDays({
    date: scheduledActivity?.dueDate,
  })

  return {
    heading: translations.scheduleHeading(scheduledInDays),
    description: translations.scheduleDescription({
      days: scheduledInDays,
      date: formatDate(scheduledActivity?.dueDate),
    }),
    quickActions: [actions.sendNow],
    dropdownActions: [
      actions.editInvoice,
      actions.sendNow,
      actions.delete,
      actions.duplicate,
    ],
  }
}

const getScheduledReminderActionDetails = (
  details: InvoiceDetailsFragment,
  actions: ActionsProp,
  nextScheduledReminder: Activity
) => {
  const { dropdownActions } = getDefaultQuickAndDropdownActions(
    details,
    actions
  )

  const nextReminderInDays = getDifferenceInDays({
    date: nextScheduledReminder.dueDate,
  })

  return {
    heading: translations.nextReminderHeading,
    description: translations.nextReminderDescription(nextReminderInDays),
    quickActions: [actions.remindNow, actions.skip],
    dropdownActions,
  }
}

const getSentOrPartPaidActionDetails = (
  details: InvoiceDetailsFragment,
  actions: ActionsProp
) => {
  const { dropdownActions, quickActions } = getDefaultQuickAndDropdownActions(
    details,
    actions
  )

  const activity = getLatestSendInvoiceActivity(details.activities)
  const sentDate = activity?.completedTime

  return {
    heading: translations.sentNoRemindersHeading(formatDate(sentDate)),
    description: getSentDescription(activity),
    quickActions,
    dropdownActions,
  }
}

const getOverdueActionDetails = (
  details: InvoiceDetailsFragment,
  actions: ActionsProp
) => {
  const { dropdownActions, quickActions } = getDefaultQuickAndDropdownActions(
    details,
    actions
  )

  const description = getOverdueDescription(details)

  return {
    heading: translations.overdueHeading,
    description,
    quickActions,
    dropdownActions,
  }
}

const getCancelledActionDetails = (
  details: InvoiceDetailsFragment,
  actions: ActionsProp
) => {
  const cancelledDate = details.activities?.find(
    ({ status }) => status === InvoiceActivityStatus.COMPLETED
  )?.completedTime

  return {
    heading: translations.cancelledHeading,
    description: translations.cancelledDescription(formatDate(cancelledDate)),
    quickActions: [actions.duplicate],
  }
}

const getPaidAndPaymentsActionDetails = (
  payments: Payment[],
  actions: ActionsProp
) => {
  const lastPayment = getLastPayment(payments).paymentTimeISO

  return {
    heading: translations.paidHeading,
    description: translations.paidDescription(formatDate(lastPayment)),
    quickActions: [actions.download, actions.duplicate],
    dropdownActions: [actions.downloadPdf, actions.duplicate],
  }
}

const mapInvoiceDetailsToActionPrompt = (
  details: InvoiceDetailsFragment,
  actions: ActionsProp,
  nextScheduledReminder: Activity | null
) => {
  const { activities, status } = details

  const isCancelled = status === InvoiceStatus.CANCELLED
  const isDraft = status === InvoiceStatus.DRAFT
  const isOverdue = status === InvoiceStatus.OVERDUE
  const isPaid = status === InvoiceStatus.PAID
  const isPartPaid = status === InvoiceStatus.PART_PAID
  const isScheduled = status === InvoiceStatus.SCHEDULED
  const isSent = status === InvoiceStatus.SENT

  const hasBeenSent = isSent || isPartPaid || isOverdue
  const hasScheduledReminder = hasBeenSent && !!nextScheduledReminder

  const failedSendContent = getFailedSendContent(status, activities)

  if (isDraft) {
    const draftActionDetails = getDraftActionDetails(actions)

    return draftActionDetails
  }

  if (failedSendContent) {
    const { heading, description } = failedSendContent

    const failedSendActionDetails = getFailedSendActionDetails(
      heading,
      description,
      actions
    )

    return failedSendActionDetails
  }

  if (isScheduled) {
    const scheduledActionDetails = getScheduledActionDetails(details, actions)

    return scheduledActionDetails
  }

  if (hasScheduledReminder) {
    const scheduledReminderActionDetails = getScheduledReminderActionDetails(
      details,
      actions,
      nextScheduledReminder
    )

    return scheduledReminderActionDetails
  }

  if (isSent || isPartPaid) {
    const sentOrPartPaidActionDetails = getSentOrPartPaidActionDetails(
      details,
      actions
    )

    return sentOrPartPaidActionDetails
  }

  if (isOverdue) {
    const overdueActionDetails = getOverdueActionDetails(details, actions)

    return overdueActionDetails
  }

  if (isPaid && details.payments) {
    const { payments } = details

    const paidAndPaymentsActionDetails = getPaidAndPaymentsActionDetails(
      payments,
      actions
    )

    return paidAndPaymentsActionDetails
  }

  if (isCancelled) {
    const cancelledActionDetails = getCancelledActionDetails(details, actions)

    return cancelledActionDetails
  }

  return null
}

export const useInvoiceActionPrompt = (invoicePdf?: FileWithId | null) => {
  const { push } = useHistory()
  const { details, setActiveModal } = useInvoiceDecisionModal()
  const { skipReminder } = useSkipReminder()
  const { sendReminder } = useSendReminder()

  const { id, referenceNumber, paymentLink } = details

  const { trackInvoiceAction } = useInvoiceAnalytics(referenceNumber, id)

  const nextScheduledReminder = getEarliestScheduledReminder(
    details?.activities || []
  )
  const handleDownloadPdf = useCallback(() => {
    if (invoicePdf?.id) {
      renameAndDownloadFile({
        link: invoicePdf.id,
        filename: translations.getFileName(referenceNumber),
        format: 'PDF',
      })
    } else {
      showErrorToast(translations.actions.downloadPdfError(referenceNumber))
    }
  }, [invoicePdf?.id, referenceNumber])

  const handleSkipReminder = useCallback(async () => {
    try {
      // technically this should never occur as we shouldn't show the skip
      // action unless there is a scheduled reminder
      if (!nextScheduledReminder?.id) {
        showErrorToast(translations.actions.skipError)
        return
      }

      const response = await skipReminder({
        referenceNumber,
        reminderUuid: nextScheduledReminder.id,
      })

      if (!response.data?.skipReminder) {
        showErrorToast(translations.actions.skipError)
        return
      }
      showSuccessToast(translations.actions.skipSuccess)
    } catch {
      showErrorToast(translations.actions.skipError)
    }
  }, [referenceNumber, skipReminder, nextScheduledReminder?.id])

  const handleSendScheduledReminder = useCallback(async () => {
    if (!nextScheduledReminder?.id) {
      showErrorToast(translations.actions.skipError)
      return
    }

    try {
      const response = await sendReminder(
        referenceNumber,
        nextScheduledReminder.id
      )

      if (!response.data?.sendReminder) {
        showErrorToast(translations.actions.sendError)
      }

      showSuccessToast(translations.actions.sendSuccess)
    } catch {
      showErrorToast(translations.actions.sendError)
    }
  }, [referenceNumber, sendReminder, nextScheduledReminder?.id])

  const actions = useMemo(() => {
    return {
      cancel: {
        label: translations.actions.cancel,
        onClick: () => setActiveModal(InvoiceDecisionModalTypes.CancelInvoice),
      },
      copyLink: {
        label: translations.actions.copyLink,
        onClick: () => {
          trackInvoiceAction(AnalyticsEventNames.INVOICE_SHARE_LINK)

          if (paymentLink) {
            copyToClipboard(paymentLink)
            showSuccessToast(translations.actions.copyLinkSuccess)
          } else {
            showErrorToast(translations.actions.copyLinkError)
          }
        },
      },
      delete: {
        label: translations.actions.delete,
        onClick: () => setActiveModal(InvoiceDecisionModalTypes.DeleteInvoice),
      },
      download: {
        label: translations.actions.download,
        onClick: () => {
          trackInvoiceAction(AnalyticsEventNames.INVOICE_DOWNLOAD_PDF)
          handleDownloadPdf()
        },
      },
      downloadPdf: {
        label: translations.actions.downloadPdf,
        onClick: () => {
          trackInvoiceAction(AnalyticsEventNames.INVOICE_DOWNLOAD_PDF)
          handleDownloadPdf()
        },
      },
      duplicate: {
        label: translations.actions.duplicate,
        onClick: () => {
          trackInvoiceAction(AnalyticsEventNames.INVOICE_DUPLICATED)
          push(ROOT.PORTAL.INVOICING.INVOICES.NEW.path, {
            duplicateReferenceNumber: referenceNumber,
          })
        },
      },
      edit: {
        label: translations.actions.edit,
        onClick: () => {
          trackInvoiceAction(AnalyticsEventNames.INVOICE_EDIT)
          push(
            ROOT.PORTAL.INVOICING.INVOICES.INVOICE(referenceNumber).EDIT.path
          )
        },
      },
      editInvoice: {
        label: translations.actions.editInvoice,
        onClick: () => {
          trackInvoiceAction(AnalyticsEventNames.INVOICE_EDIT)
          push(
            ROOT.PORTAL.INVOICING.INVOICES.INVOICE(referenceNumber).EDIT.path
          )
        },
      },
      markAsPaid: {
        label: translations.actions.markAsPaid,
        onClick: () => setActiveModal(InvoiceDecisionModalTypes.MarkAsPaid),
      },
      recordPayment: {
        label: translations.actions.recordPayment,
        onClick: () => setActiveModal(InvoiceDecisionModalTypes.RecordPayment),
      },
      remind: {
        label: translations.actions.remind,
        onClick: () => setActiveModal(InvoiceDecisionModalTypes.SendReminder),
      },
      remindNow: {
        label: translations.actions.remindNow,
        onClick: () => {
          trackInvoiceAction(AnalyticsEventNames.INVOICE_SEND_REMINDER)
          handleSendScheduledReminder()
        },
      },
      resend: {
        label: translations.actions.resend,
        onClick: () => setActiveModal(InvoiceDecisionModalTypes.ResendInvoice),
      },
      sendReminder: {
        hasBottomDivider: true,
        label: translations.actions.sendReminder,
        onClick: () => setActiveModal(InvoiceDecisionModalTypes.SendReminder),
      },
      sendNow: {
        hasBottomDivider: true,
        label: translations.actions.sendNow,
        onClick: () => setActiveModal(InvoiceDecisionModalTypes.SendNow),
      },
      skip: {
        label: translations.actions.skip,
        onClick: () => {
          trackInvoiceAction(AnalyticsEventNames.INVOICE_SKIP_REMINDER)
          handleSkipReminder()
        },
      },
    }
  }, [
    handleDownloadPdf,
    handleSendScheduledReminder,
    handleSkipReminder,
    paymentLink,
    push,
    referenceNumber,
    setActiveModal,
    trackInvoiceAction,
  ])

  const actionPromptProps = mapInvoiceDetailsToActionPrompt(
    details,
    actions,
    nextScheduledReminder
  )

  return { actionPromptProps }
}
