import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type DeleteAllItemsMutationVariables = Types.Exact<{
  [key: string]: never
}>

export type DeleteAllItemsMutationResponse = {
  __typename?: 'Mutation'
  deleteAllItems: boolean
}

export const DeleteAllItems = gql`
  mutation DeleteAllItems {
    deleteAllItems
  }
` as unknown as TypedDocumentNode<
  DeleteAllItemsMutationResponse,
  DeleteAllItemsMutationVariables
>
export type DeleteAllItemsMutationFn = Apollo.MutationFunction<
  DeleteAllItemsMutationResponse,
  DeleteAllItemsMutationVariables
>

/**
 * __useDeleteAllItemsMutation__
 *
 * To run a mutation, you first call `useDeleteAllItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllItemsMutation, { data, loading, error }] = useDeleteAllItemsMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAllItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAllItemsMutationResponse,
    DeleteAllItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAllItemsMutationResponse,
    DeleteAllItemsMutationVariables
  >(DeleteAllItems, options)
}
export type DeleteAllItemsMutationHookResult = ReturnType<
  typeof useDeleteAllItemsMutation
>
export type DeleteAllItemsMutationResult =
  Apollo.MutationResult<DeleteAllItemsMutationResponse>
export type DeleteAllItemsMutationOptions = Apollo.BaseMutationOptions<
  DeleteAllItemsMutationResponse,
  DeleteAllItemsMutationVariables
>
