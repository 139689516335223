import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom-v5-compat'
import { ContactType } from '@npco/mp-gql-types'
import { InvoiceDrawer } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceDrawer/InvoiceDrawer'

import { ROOT, ROUTE_PARAM_NAME } from 'const/routes'

export const ContactInvoiceDrawerDeps = {
  useNavigate,
  useParams,
}

type ContactInvoiceDrawerProps = {
  contactType: ContactType
  handleRedirectDelay?: number
}

export const ContactInvoiceDrawer = ({
  contactType,
  handleRedirectDelay,
}: ContactInvoiceDrawerProps) => {
  const { useNavigate, useParams } = ContactInvoiceDrawerDeps

  const { contactId = '' } = useParams<ROUTE_PARAM_NAME.PORTAL_CONTACT_ID>()
  const navigate = useNavigate()

  const handleRedirectBackToInvoices = useCallback(() => {
    if (contactType === ContactType.BUSINESS) {
      navigate(ROOT.PORTAL.CONTACTS.BUSINESSES.CONTACT(contactId).INVOICES.path)

      return
    }

    navigate(ROOT.PORTAL.CONTACTS.PEOPLE.CONTACT(contactId).INVOICES.path)
  }, [contactId, contactType, navigate])

  return (
    <InvoiceDrawer
      handleRedirect={handleRedirectBackToInvoices}
      handleRedirectDelay={handleRedirectDelay}
    />
  )
}
