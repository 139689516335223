import { useCallback } from 'react'
import { ApolloError } from '@apollo/client'

import { DebitCardIcon } from 'types/accounts'

import {
  CreateDebitCardAccountMutationResponse,
  useCreateDebitCardAccountMutation,
} from './graphql/CreateAccount.generated'
import { cacheNewAccount } from './useCreateAccount.utils'

interface UseCreateAccountProps {
  onSuccess?: (result: CreateDebitCardAccountMutationResponse) => void
  onError?: (error?: ApolloError) => void
}

interface CreateAccountVariables {
  name: string
  icon: Omit<DebitCardIcon, 'images'>
}

export const useCreateAccount = ({
  onSuccess,
  onError,
}: UseCreateAccountProps = {}) => {
  const [createAccountMutation, { loading }] =
    useCreateDebitCardAccountMutation({
      onCompleted: (result) => {
        onSuccess?.(result)
      },
      onError,
      update: (cache, { data }) => {
        if (data?.createDebitCardAccount) {
          cacheNewAccount(cache, data.createDebitCardAccount)
        }
      },
    })

  const createAccount = useCallback(
    async ({ name, icon }: CreateAccountVariables) => {
      const result = await createAccountMutation({
        variables: {
          input: {
            name,
            icon: { colour: icon.colour, letter: icon.letter },
          },
        },
      })

      return result
    },
    [createAccountMutation]
  )

  return { createAccount, isLoading: loading }
}
