import { isEqual } from 'lodash-es'

import { GetItemProps, PickerItemProps } from 'types/picker'
import { Checkbox } from 'components/Input/Checkbox'

import { StyledItem, StyledRow } from './Elements.styled'

interface TagRowProps {
  tag: PickerItemProps
  getItemProps: GetItemProps
  selectedItems: PickerItemProps[]
}

export const TagRow: React.FC<TagRowProps> = ({
  tag,
  getItemProps,
  selectedItems,
}) => (
  <StyledRow as="li" noPadding withTopBorder>
    <StyledItem>
      <Checkbox
        type="checkbox"
        name={tag.name}
        label={tag.name}
        onChange={() => null}
        checked={selectedItems.some((selectedTag) => isEqual(selectedTag, tag))}
        {...getItemProps({ item: tag })}
      />
    </StyledItem>
  </StyledRow>
)
