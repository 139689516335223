import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  BUTTON_SIZE,
  ButtonFill,
  Flex,
  MessageSize,
  MessageWithIcon,
} from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import { useItemManagementContext } from '../../ItemManagementContext/ItemManagementContext'
import { translations } from './CategoriesErrorState.i18n'

export const CategoriesErrorState = () => {
  const {
    categories: { isLoading, getCategories },
  } = useItemManagementContext()

  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  return (
    <Flex
      alignItems="center"
      data-testid="categories-error-state"
      justifyContent="center"
      flexGrow={1}
    >
      <MessageWithIcon
        align="center"
        description={
          <Box textAlign="center">
            <div>{t('descriptionOne')}</div>
            <div>{t('descriptionTwo')}</div>
          </Box>
        }
        primaryButton={
          <ButtonFill
            disabled={isLoading}
            size={BUTTON_SIZE.LARGE}
            onClick={() => getCategories()}
          >
            {tShared('tryAgain')}
          </ButtonFill>
        }
        size={MessageSize.LARGE}
        title={<Box textAlign="center">{t('title')}</Box>}
      />
    </Flex>
  )
}
