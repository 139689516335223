import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetUsersForSiteQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type GetUsersForSiteQueryResponse = {
  __typename?: 'Query'
  getCustomers: Array<{
    __typename?: 'Customer'
    id: string
    firstname: string | null
    middlename: string | null
    lastname: string | null
    role: Types.CustomerRole | null
  } | null>
}

export const GetUsersForSite = gql`
  query GetUsersForSite {
    getCustomers {
      id
      firstname
      middlename
      lastname
      role
    }
  }
` as unknown as TypedDocumentNode<
  GetUsersForSiteQueryResponse,
  GetUsersForSiteQueryVariables
>

/**
 * __useGetUsersForSiteQuery__
 *
 * To run a query within a React component, call `useGetUsersForSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForSiteQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersForSiteQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersForSiteQueryResponse,
    GetUsersForSiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUsersForSiteQueryResponse,
    GetUsersForSiteQueryVariables
  >(GetUsersForSite, options)
}
export function useGetUsersForSiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersForSiteQueryResponse,
    GetUsersForSiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUsersForSiteQueryResponse,
    GetUsersForSiteQueryVariables
  >(GetUsersForSite, options)
}
export type GetUsersForSiteQueryHookResult = ReturnType<
  typeof useGetUsersForSiteQuery
>
export type GetUsersForSiteLazyQueryHookResult = ReturnType<
  typeof useGetUsersForSiteLazyQuery
>
export type GetUsersForSiteQueryResult = Apollo.QueryResult<
  GetUsersForSiteQueryResponse,
  GetUsersForSiteQueryVariables
>
