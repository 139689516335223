import { ROUTE } from 'const/routes'
import { translate } from 'utils/translations'

import { Section, SectionLink, SectionTitle } from './components'

type PhoneSectionProps = {
  phone: string | null
}

export const PhoneSection = ({ phone }: PhoneSectionProps): JSX.Element => {
  return (
    <Section>
      <SectionTitle>
        {translate('page.settings.profileSettings.phoneNumber')}
      </SectionTitle>
      <div>{phone}</div>
      <div>
        <SectionLink to={ROUTE.PORTAL_SETTINGS_PROFILE_SECURITY_CHANGE_NUMBER}>
          {translate('page.settings.profileSettings.changeNumber')}
        </SectionLink>
      </div>
    </Section>
  )
}
