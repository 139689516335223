import { useCallback } from 'react'
import { rvCurrentUserData } from '@npco/mp-utils-logged-in-user'
import {
  rvEntityDetails,
  rvOnboardingMetadata,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { useSaveOnboarding } from '../useSaveOnboarding'

export const useSaveFlowFlagsCombinedForm = () => {
  const { saveOnboarding } = useSaveOnboarding()
  const currentEntityUuid = rvCurrentUserData()?.entityUuid
  const saveFlowFlagsCombinedForm = useCallback(
    async (showCombinedForm: boolean) => {
      if (currentEntityUuid) {
        rvOnboardingMetadata({
          ...rvOnboardingMetadata(),
          flowFlags: JSON.stringify({ combinedForm: showCombinedForm }),
        })

        // We'll keep the user in the current flow if this call fails and will not notify the user.
        const results = await saveOnboarding({ entityUuid: currentEntityUuid })

        if (results.data?.saveEntityOnboardingDetails) {
          // This is required when the user clicks save and logout button.
          rvEntityDetails({
            ...rvEntityDetails(),
            id: currentEntityUuid,
          })
        }
      }
    },
    [currentEntityUuid, saveOnboarding]
  )

  return {
    saveFlowFlagsCombinedForm,
  }
}
