import { useCallback, useRef, useState } from 'react'

import { ONE_SECOND_IN_MS } from '../../SessionTimeoutModal.mocks'

type useCountdownTimerProps = {
  countdownTime: number
}

export const useCountdownTimer = ({
  countdownTime,
}: useCountdownTimerProps) => {
  const [countdown, setCountdown] = useState(countdownTime)
  const [isTimeout, setIsTimeout] = useState(false)
  const countdownTimerRef = useRef<ReturnType<typeof setInterval> | null>(null)

  const clearCountdownTimer = useCallback(() => {
    if (countdownTimerRef.current) {
      clearInterval(countdownTimerRef.current)
      countdownTimerRef.current = null
    }
  }, [])

  const handleTimeout = useCallback(() => {
    setIsTimeout(true)
    clearCountdownTimer()
  }, [clearCountdownTimer])

  const startCountdown = useCallback(() => {
    if (countdownTimerRef.current) {
      clearInterval(countdownTimerRef.current)
    }
    setCountdown(countdownTime)
    countdownTimerRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 0) {
          handleTimeout()
          return 0
        }
        return prev - 1
      })
    }, ONE_SECOND_IN_MS)
  }, [countdownTime, handleTimeout])

  return {
    countdown,
    isTimeout,
    startCountdown,
    clearCountdownTimer,
  }
}
