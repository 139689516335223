export enum SESSION_STORAGE_KEYS {
  TOKEN = 'token',
  CURRENT_USER_DATA = 'current-user-data',

  DEPOSITS_SELECTED_EXTERNAL_BANK = 'selected-external-bank',

  DEPOSITS_CLICKED_ACCOUNT = 'deposit-clicked-account',
  DEPOSITS_CLICKED_ACCOUNT_TYPE = 'deposit-clicked-account-type',

  ENTITY_DETAILS = 'entity-details',
  IS_SIGNING_UP = 'is-signing-up',

  ONBOARDING_INITIAL_SEARCH_DATA = 'onboarding-initial-search-data',
  ONBOARDING_METADATA = 'onboarding-metadata',
  ONBOARDING_CUSTOMERS_TEMP_DATA = 'customers-temp-data',
  ONBOARDING_CUSTOMERS_INITIAL_MEMBERS_DATA = 'customers-initial-members-data',
  ONBOARDING_CUSTOMERS_CURRENT_CUSTOMER_TEMP_ID = 'customers-current-customer-temp-id',
  ONBOARDING_COMPANY_SEARCH_UNSUPPORTED_ABN_OR_ACNS = 'company-search-unsupported-abn-or-acns',
  ONBOARDING_COMPANY_SEARCH_INACTIVE_ABN_OR_ACNS = 'company-search-inactive-abn-or-acns',
  ONBOARDING_COMPANY_SEARCH_THIRD_PARTY_ERROR = 'company-search-third-party-error',
  ONBOARDING_IS_GOVERNMENT_CUSTOMER_UPDATED = 'onboarding-is-government-customer-updated',

  ONBOARDING_SHOP_CART_ITEMS = 'onboarding-shop-cart-items',
  ONBOARDING_SHOP_CHECKOUT_ADDRESS = 'checkout-address',

  SITES_NEW_SITES = 'new-sites',
  SITES_LAST_SELECTED_SITE_ID = 'last-site',

  ACCOUNTS_LAST_SELECTED_TRANSACTION = 'last-selected-transaction',

  FILTER_SELECTED_DATES = 'filter-selected-dates',
  PREVIOUS_PATH = 'previous-path',

  AUTH_E2E_IN_PROGRESS = 'auth-e2e-in-progress',

  KYC_DATA = 'kyc-data',

  ZELLER_SESSION_ID = 'zellerSessionId',

  HAS_SEEN_PUSH_APP_MODAL = 'has-seen-push-app-modal',
  HAS_SEEN_OVERIVEW_BANNER = 'has-seen-overview-banner',

  HIDE_OVERVIEW_BANNER = 'hide-overview-banner',
}
