import { TableCellByline, TableCellText } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { useIsMobileResolution } from '../../../../../hooks/useIsMobileResolution'
import { ItemsTableData } from '../ItemsTable.types'

export const ItemsRowPriceCell = (
  cellProps: CellContext<ItemsTableData, string>
) => {
  const isMobile = useIsMobileResolution()

  const { cell, getValue, row, table } = cellProps

  const byline = row.original.sku ? `SKU: ${row.original.sku}` : ''
  const isLoading = row.original?.id === null || table.options.meta?.isLoading
  const value = isLoading ? '' : getValue<string>()

  if (isMobile) {
    return (
      <TableCellByline
        byline={byline}
        dataTestId={cell.id}
        forwardedProps={{ style: { padding: 0 } }}
        hasDivider={false}
        isLoading={isLoading}
        justifyContent="flex-end"
        key={cell.id}
        label={value}
        skeletonProps={{ justifyContent: 'flex-end', width: '50%' }}
      />
    )
  }

  const width = row.index % 2 ? '80%' : '100%'

  return (
    <TableCellText
      dataTestId={cell.id}
      forwardedProps={{ style: { padding: 0 } }}
      hasDivider={false}
      isLoading={isLoading}
      justifyContent="flex-end"
      key={cell.id}
      skeletonProps={{ width }}
      text={value}
    />
  )
}
