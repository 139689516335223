import { useTranslations } from '@npco/utils-translations'
import { SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as LogoIcon } from 'assets/svg/zeller-logo-icon.svg'

import { HideDetailsButton } from './HideDetailsButton'
import { transferConfirmationHeaderTranslations } from './TransferConfirmationHeader.i18n'
import * as styled from './TransferConfirmationHeader.styled'
import { TransferConfirmationHeaderTitle } from './TransferConfirmationHeaderTitle'

type TransferDetailsRevealedProps = {
  toggleShowDetails: () => void
  paddingBottom: string
  isExpanded: boolean
  isTransferRemittancesFlagOn: boolean
}

export const TransferDetailsRevealed = ({
  toggleShowDetails,
  paddingBottom,
  isExpanded,
  isTransferRemittancesFlagOn,
}: TransferDetailsRevealedProps) => {
  const t = useTranslations(transferConfirmationHeaderTranslations)

  return (
    <>
      {isTransferRemittancesFlagOn && (
        <HideDetailsButton
          isExpanded={isExpanded}
          paddingBottom={paddingBottom}
          toggleShowDetails={toggleShowDetails}
          hideDetailsText={t('hideDetailsText')}
          isTransferRemittancesFlagOn={isTransferRemittancesFlagOn}
        />
      )}
      <SvgIcon alt="zeller-logo">
        <LogoIcon width="69" height="24" />
      </SvgIcon>
      <styled.TitleWrapper>
        <TransferConfirmationHeaderTitle />
        {!isTransferRemittancesFlagOn && (
          <HideDetailsButton
            isExpanded={isExpanded}
            paddingBottom={paddingBottom}
            toggleShowDetails={toggleShowDetails}
            hideDetailsText={t('hideDetailsText')}
            isTransferRemittancesFlagOn={isTransferRemittancesFlagOn}
          />
        )}
      </styled.TitleWrapper>
    </>
  )
}
