import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { gql } from '@apollo/client'
import { Table, TableData, useTable } from '@npco/ui-table'
import { Row } from '@tanstack/react-table'

import { ROOT } from 'const/routes'
import { YourCardListBalanceCell } from 'components/CardsView/components/CardsTable/components/CardBalanceCell/YourCardListBalanceCell'
import { CardNameIconCell } from 'components/CardsView/components/CardsTable/components/CardNameIconCell/CardNameIconCell'

import { columnsConfig } from './TableConfig'
import { YourCardListTableDebitCardV2Fragment } from './YourCardListTable.generated'

interface YourCardListTableProps {
  cards: YourCardListTableDebitCardV2Fragment[]
  isLoading?: boolean
  hasDivider?: boolean
  isReadOnly?: boolean
}

export const YourCardListTable = ({
  cards,
  isLoading = false,
  hasDivider = false,
  isReadOnly = false,
}: YourCardListTableProps) => {
  const { table } = useTable<YourCardListTableDebitCardV2Fragment>({
    columnsConfig,
    isLoading,
    listData: cards,
    loadingRowCount: 3,
  })
  const navigate = useNavigate()
  const handleRowClick = useCallback(
    (row: Row<TableData<YourCardListTableDebitCardV2Fragment>> | null) => {
      const cardId = row?.original.data?.id

      if (cardId) {
        navigate(ROOT.PORTAL.CARDS.CARD(cardId).path)
      }
    },
    [navigate]
  )

  return (
    <Table<TableData<YourCardListTableDebitCardV2Fragment>>
      hideHeader
      hasBorderBottom={hasDivider}
      hasLastTrBorderBottom={false}
      onRowClick={!isReadOnly ? handleRowClick : undefined}
      table={table}
    />
  )
}

YourCardListTable.fragments = {
  DebitCardV2: gql`
    fragment YourCardListTableDebitCardV2Fragment on DebitCardV2 {
      id
      ...CardNameIconCellDebitCardV2Fragment
      ...YourCardListBalanceCellDebitCardV2Fragment
    }
    ${CardNameIconCell.fragments.DebitCardV2}
    ${YourCardListBalanceCell.fragments.DebitCardV2}
  `,
}
