import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type GetCategoriesTableCoreFieldsFragment = {
  __typename: 'ItemCategory'
  color: string
  id: string
  itemsNumber: number | null
  name: string
  status: Types.ItemCategoryStatus | null
}

export const GetCategoriesTableCoreFieldsDoc = gql`
  fragment GetCategoriesTableCoreFields on ItemCategory {
    __typename
    color
    id
    itemsNumber
    name
    status
  }
` as unknown as TypedDocumentNode<
  GetCategoriesTableCoreFieldsFragment,
  undefined
>
