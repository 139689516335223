import { gql } from '@apollo/client'

export const GetInvoicePreview = gql`
  query GetInvoicePreview($invoice: PreviewInvoiceInput!) {
    getInvoicePreview(input: $invoice) {
      downloadLink
      expire
    }
  }
`
