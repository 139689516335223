import { gql } from '@apollo/client'

export const UpdateInvoiceNotes = gql`
  mutation UpdateInvoiceNotes($input: UpdatedInvoiceNotesInput!) {
    updateInvoiceNotes(input: $input) {
      __typename
      notes
      id
      referenceNumber
    }
  }
`
