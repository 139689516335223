import {
  StyledButtonGhost,
  StyledModalBasic,
} from '../../../../ContactsLayout/AddContactModalMobile/AddContactModalMobile.styled'

interface SubContactActionsMobileProps {
  isOpen: boolean
  closeModal: () => void
  openUnlinkModal: () => void
}
export const SubContactActionsMobile = ({
  isOpen,
  closeModal,
  openUnlinkModal,
}: SubContactActionsMobileProps) => (
  <StyledModalBasic onCancel={closeModal} isOpen={isOpen}>
    <StyledButtonGhost
      onClick={() => {
        openUnlinkModal()
        closeModal()
      }}
    >
      Unlink
    </StyledButtonGhost>
  </StyledModalBasic>
)
