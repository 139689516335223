import { CustomerAddressInput } from '@npco/mp-gql-types'
import { Box } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { validateRequired } from 'utils/formValidation'
import { Country } from 'forms/formSections/Country'
import { Identity } from 'forms/formSections/Identity'
import { PersonalAddress } from 'forms/formViews/PersonalAddress/PersonalAddress'
import { form, page } from 'translations'

import { StyledText } from './AddCustomerForm.styled'

export type AddCustomerFormValues = CustomerAddressInput & {
  isBeneficialOwner?: boolean
  firstName?: string
  middleName?: string
  lastName?: string
  dob?: string
}

export const AddCustomerForm = () => {
  const { values } = useFormikContext<AddCustomerFormValues>()

  return (
    <>
      {values.isBeneficialOwner && (
        <StyledText>
          {page.onboarding.addBeneficiaries.personalInformation}
        </StyledText>
      )}
      <Identity withDoB={values.isBeneficialOwner} values={values} />
      {values.isBeneficialOwner && (
        <>
          <StyledText>{form.shared.residentalAddress}</StyledText>
          <Box mb="2rem">
            <Country validator={validateRequired} label={form.shared.country} />
          </Box>
          <PersonalAddress />
        </>
      )}
    </>
  )
}
