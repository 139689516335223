import { useCallback } from 'react'
import { ApolloError } from '@apollo/client'

import { useGetCustomisationInvoicePreviewLazyQuery } from '../../../Settings/SettingsCustomisation/graphql/getInvoiceCustomisationPreview.generated'
import { useGetInvoicePdf } from '../../hooks/useGetInvoicePdf'
import { InvoiceCustomisationFormFieldsAndPreviewDefaults } from '../Invoice.types'
import {
  convertInvoiceCustomisationFormFieldsToPreviewInput,
  convertInvoiceFormFieldsToPreviewInput,
} from '../Invoice.utils'

type UseGetInvoicePreviewDownloadLinkProps = {
  isCustomisationPreview?: boolean
  onError?: (error: ApolloError) => void
}

export const useGetInvoicePreviewDownloadLink = ({
  isCustomisationPreview,
  onError,
}: UseGetInvoicePreviewDownloadLinkProps = {}) => {
  const { getDownloadLink: getInvoiceDownloadLink } = useGetInvoicePdf()
  const [getCustomisationInvoicePreview] =
    useGetCustomisationInvoicePreviewLazyQuery({
      fetchPolicy: 'no-cache',
      onError,
    })

  const getDownloadLink = useCallback(
    async (
      values: InvoiceCustomisationFormFieldsAndPreviewDefaults | string
    ) => {
      if (typeof values === 'string' || !isCustomisationPreview) {
        const { downloadLink } = await getInvoiceDownloadLink(
          typeof values === 'string'
            ? values
            : convertInvoiceFormFieldsToPreviewInput(values)
        )
        return downloadLink
      }

      const result = await getCustomisationInvoicePreview({
        variables: {
          invoice: convertInvoiceCustomisationFormFieldsToPreviewInput(values),
        },
      })

      return result?.data?.getCustomisationInvoicePreview?.downloadLink
    },
    [
      getCustomisationInvoicePreview,
      getInvoiceDownloadLink,
      isCustomisationPreview,
    ]
  )

  return { getDownloadLink }
}
