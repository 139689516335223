import { gql } from '@apollo/client'

export const GetCustomers = gql`
  query GetCustomers {
    getCustomers {
      id
      firstname
      middlename
      lastname
      role
      isInvitationPending
      kycStatus
      siteCount
    }
  }
`

export const GetCustomerBasicInfo = gql`
  query GetCustomerBasicInfo($customerUuid: ID!) {
    getCustomer(customerUuid: $customerUuid) {
      id
      firstname
      middlename
      lastname
      role
      isInvitationPending
      kycStatus
    }
  }
`

export const GetCustomerPhone = gql`
  query GetCustomerPhone($customerUuid: ID!) {
    getCustomer(customerUuid: $customerUuid) {
      phone
    }
  }
`

export const GetCustomerReferralCode = gql`
  query GetCustomerReferralCode($customerUuid: ID!) {
    getCustomer(customerUuid: $customerUuid) {
      referralCode
    }
  }
`

export const GetCustomerDetails = gql`
  query GetCustomerDetails($customerUuid: ID) {
    getCustomer(customerUuid: $customerUuid) {
      id
      entityUuid
      firstname
      middlename
      lastname
      email
      phone
      role
      registeringIndividual
      sites {
        id
        name
        devices {
          name
        }
      }
      isInvitationPending
      kycStatus
      permissions {
        allowDiscountManagement
        allowItemManagement
        allowZellerInvoices
        allowXeroPaymentServices
      }
    }
  }
`
