import { gql } from '@apollo/client'

export const GetUpdatedOutstandingTransactionsLocalState = gql`
  query GetUpdatedOutstandingTransactionsLocalState {
    local @client {
      updatedOutstandingTransactions {
        transaction
        timestamp
      }
    }
  }
`
