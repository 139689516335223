import { useMutation, useReactiveVar } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { UpdateSite } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/sites'
import { rvSiteDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/sites'

import { SettingsPayments } from 'types/settings'
import { SiteCache } from 'types/site'
import { getPaymentsData } from 'pages/Settings/SitePayments/SitePayments.utils'

export const useSitePayments = () => {
  const siteData = useReactiveVar(rvSiteDetails) as SiteCache
  const paymentsData = getPaymentsData(siteData) as SettingsPayments

  const [updateSite, { loading: loadingUpdateSite }] = useMutation<
    {
      updateSite: boolean
    },
    { input: SiteCache }
  >(UpdateSite, {
    onError: (error) => showApiErrorToast(error),
  })

  return {
    updateSite,
    loadingUpdateSite,
    siteData,
    paymentsData,
  }
}
