import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { SimTableFragmentDoc } from '../SimTable/SimTable.generated'

const defaultOptions = {} as const
export type GetSimsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input']
  nextToken: Types.InputMaybe<Types.Scalars['ID']['input']>
}>

export type GetSimsQueryResponse = {
  __typename?: 'Query'
  getSims: {
    __typename: 'SimConnection'
    nextToken: string | null
    sims: Array<{
      __typename?: 'Sim'
      id: string
      billingStatus: Types.SimBillingStatus
      deviceName: string | null
      siteName: string | null
    } | null>
  }
}

export const GetSimsQuery = gql`
  query GetSimsQuery($limit: Int!, $nextToken: ID) {
    getSims(limit: $limit, nextToken: $nextToken) {
      __typename
      sims {
        ...SimTableFragment
      }
      nextToken
    }
  }
  ${SimTableFragmentDoc}
` as unknown as TypedDocumentNode<GetSimsQueryResponse, GetSimsQueryVariables>

/**
 * __useGetSimsQuery__
 *
 * To run a query within a React component, call `useGetSimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetSimsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSimsQueryResponse,
    GetSimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSimsQueryResponse, GetSimsQueryVariables>(
    GetSimsQuery,
    options
  )
}
export function useGetSimsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSimsQueryResponse,
    GetSimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSimsQueryResponse, GetSimsQueryVariables>(
    GetSimsQuery,
    options
  )
}
export type GetSimsQueryHookResult = ReturnType<typeof useGetSimsQuery>
export type GetSimsQueryLazyQueryHookResult = ReturnType<
  typeof useGetSimsQueryLazyQuery
>
export type GetSimsQueryQueryResult = Apollo.QueryResult<
  GetSimsQueryResponse,
  GetSimsQueryVariables
>
