import { makeVar } from '@apollo/client'

import { EntityDetails } from 'types/common'

export const rvEntityDetails = makeVar({} as EntityDetails)

export const rvFinishedLoading = makeVar<boolean>(false)

export const rvIsUserSigningUp = makeVar(false)

export const rvHasSeenPushAppModal = makeVar(false)
