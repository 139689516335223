import { useEffect } from 'react'
import { MutationFunction } from '@apollo/client'

import {
  CreateCustomer,
  CreateCustomerVariables,
} from 'types/gql-types/CreateCustomer'

import { submitCreateForm } from '../../utils'
import { useCreateAdminMFAState } from './MFAState/useCreateAdminMFAState'

export const useCreateAdminOnMFARedirect = (
  createCustomer: MutationFunction<CreateCustomer, CreateCustomerVariables>
) => {
  const {
    createAdminState,
    hasRedirectedBackToApp,
    setHasRedirectedBackToApp,
  } = useCreateAdminMFAState()

  useEffect(() => {
    if (createAdminState && hasRedirectedBackToApp) {
      setHasRedirectedBackToApp(false)
      submitCreateForm({
        createCustomer,
        values: createAdminState.variables,
        isXeroPaymentServicesEnabled: true,
      })
    }
  }, [
    createAdminState,
    createCustomer,
    hasRedirectedBackToApp,
    setHasRedirectedBackToApp,
  ])
}
