import { useMutation } from '@apollo/client'

import {
  MarkInvoiceAsPaid as MarkInvoiceAsPaidResponse,
  MarkInvoiceAsPaidVariables,
} from 'types/gql-types/MarkInvoiceAsPaid'

import { MarkInvoiceAsPaid } from '../../graphql/markInvoiceAsPaid'

export const useMarkInvoiceAsPaid = () => {
  const [markInvoiceAsPaid, { loading: isMarkingAsPaid }] = useMutation<
    MarkInvoiceAsPaidResponse,
    MarkInvoiceAsPaidVariables
  >(MarkInvoiceAsPaid)

  return {
    markInvoiceAsPaid: (referenceNumber: string) =>
      markInvoiceAsPaid({
        variables: {
          referenceNumber,
        },
      }),
    isMarkingAsPaid,
  }
}
