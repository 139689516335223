import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { Source, TransactionStatus, TransactionType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Box, Divider } from '@npco/zeller-design-system'

import { useIsInvoicingEnabled } from 'hooks/invoices/useIsInvoicingEnabled'
import { getTransactionType } from 'hooks/useTransactionsList/useTransactionsList.utils'
import { issuerTitles } from 'const/payment-providers'
import { ROOT } from 'const/routes'
import { currencyFormatter, getFormattedTransactionAmount } from 'utils/common'
import { formatWeekdayDateTime } from 'utils/date'
import { getNonNullString } from 'utils/string'
import { checkIfMotoTransaction } from 'utils/transactions'
import { translate } from 'utils/translations'
import { Transaction, TransactionInList } from 'types/transactions'
import { DetailsField } from 'components/DetailsField/DetailsField'
import { getTransactionBadgeVariant } from 'components/StatusBadge/TransactionStatusBadge/TransactionStatusBadge.utils'
import { TooltipLabel } from 'components/TooltipLabel/TooltipLabel'
import { component } from 'translations'

import { translations } from '../TransactionDetails.i18n'
import * as styled from '../TransactionDetails.styled'
import {
  getCardDetails,
  getInvoiceExternalReferenceUrl,
  getThreeDSOutcomeVariant,
  getTransactionSourceValue,
  isHLPosTransaction,
  isInvoiceTransaction,
  isLinklyPosTransaction,
  isOraclePosTransaction,
  isPosLiteTransaction,
  isVirtualTerminalOrPayByLinkTransaction,
} from '../TransactionDetails.utils'
import {
  DetailsFieldWithButtonLink,
  ShortIdLink,
} from './DetailsField/DetailsField'
import { EcommerceType } from './EcommerceType/EcommerceType'
import { PosLiteDetails } from './PosLiteDetails/PosLiteDetails'
import { SplitPayment } from './SplitPayment/SplitPayment'
import { TransactionRefunds } from './TransactionRefunds/TransactionRefunds'

interface DetailsContainerProps {
  fetchedTransaction?: Transaction
  selectedTransaction: TransactionInList
  isNavigatingTransaction: boolean
  updateSelectedTransaction: (id: string) => void
  closeDrawer: () => void
}

export const DetailsContainer = ({
  selectedTransaction,
  fetchedTransaction,
  isNavigatingTransaction,
  updateSelectedTransaction,
  closeDrawer,
}: DetailsContainerProps) => {
  const navigate = useNavigate()
  const t = useTranslations(translations)
  const isInvoicingEnabled = useIsInvoicingEnabled()
  const isDeclined = selectedTransaction.status === TransactionStatus.DECLINED
  const isRefundType = selectedTransaction.type === TransactionType.REFUND
  const isPurchaseType = selectedTransaction.type === TransactionType.PURCHASE
  const isMotoCardMedia = checkIfMotoTransaction(selectedTransaction)
  const shouldShowInvoiceDetails =
    isInvoicingEnabled && isInvoiceTransaction(selectedTransaction)
  const source = selectedTransaction.source ?? undefined
  const shouldShowPosLiteDetails =
    isPosLiteTransaction(source) &&
    !!fetchedTransaction?.externalReference &&
    fetchedTransaction?.status === TransactionStatus.APPROVED

  const onVTReferenceClick = useCallback(() => {
    navigate(ROOT.PORTAL.VIRTUAL_TERMINAL.HISTORY.path, {
      state: {
        VTReference: fetchedTransaction?.externalReference,
      },
    })
  }, [fetchedTransaction?.externalReference, navigate])

  return (
    <>
      <DetailsField
        label={component.transaction.date}
        value={formatWeekdayDateTime(selectedTransaction.timestamp)}
        isLoading={isNavigatingTransaction}
      />
      <DetailsField
        label={component.transaction.type}
        value={getTransactionType(selectedTransaction.type)}
        isLoading={isNavigatingTransaction}
      />
      <DetailsField
        label={component.transaction.status}
        value={
          <styled.StatusValue
            color={getTransactionBadgeVariant(selectedTransaction).color}
          >
            {getTransactionBadgeVariant(selectedTransaction).text}
          </styled.StatusValue>
        }
        isLoading={isNavigatingTransaction}
      />
      {fetchedTransaction?.threeDSOutcome && (
        <DetailsField
          label={
            <TooltipLabel
              label={t('threeDSStatus')}
              tooltipContent={
                <Box maxWidth="409px" textAlign="center">
                  {t('threeDSStatusTooltip')}
                </Box>
              }
            />
          }
          value={
            <styled.StatusValue
              color={
                getThreeDSOutcomeVariant(fetchedTransaction?.threeDSOutcome)
                  .color
              }
            >
              {
                getThreeDSOutcomeVariant(fetchedTransaction?.threeDSOutcome)
                  .text
              }
            </styled.StatusValue>
          }
          isLoading={isNavigatingTransaction}
        />
      )}

      {!isDeclined && (
        <ShortIdLink
          label={component.transaction.settlement}
          value={fetchedTransaction?.depositShortId}
          onClick={() =>
            navigate(ROOT.PORTAL.PAYMENTS.SETTLEMENTS.path, {
              replace: true,
              state: {
                depositUuid: fetchedTransaction?.depositUuid,
              },
            })
          }
          isLoading={isNavigatingTransaction}
        />
      )}

      <Divider />
      {selectedTransaction.cardMedia && isPurchaseType && (
        <DetailsField
          label={component.transaction.method}
          value={
            <>
              {getCardDetails({
                scheme: selectedTransaction.scheme,
                maskedPan: selectedTransaction.maskedPan,
              })}
              {isMotoCardMedia && ` · ${component.transaction.methodType.moto}`}
            </>
          }
          isLoading={isNavigatingTransaction}
        />
      )}
      {selectedTransaction.issuer && (
        <DetailsField
          value={issuerTitles[selectedTransaction.issuer]}
          isLoading={isNavigatingTransaction}
        />
      )}
      <DetailsField
        label={component.transaction.source}
        value={getTransactionSourceValue(
          selectedTransaction.sourceFilter,
          selectedTransaction.source
        )}
        isLoading={isNavigatingTransaction}
      />
      {isVirtualTerminalOrPayByLinkTransaction(source) && (
        <EcommerceType
          source={source}
          reference={fetchedTransaction?.externalReference ?? ''}
        />
      )}
      {fetchedTransaction?.externalReference &&
        (isLinklyPosTransaction(selectedTransaction) ||
          isOraclePosTransaction(selectedTransaction)) && (
          <DetailsField
            value={translate('component.transaction.externalReference', {
              externalReference: fetchedTransaction?.externalReference,
            })}
            isLoading={isNavigatingTransaction}
          />
        )}
      {shouldShowInvoiceDetails && fetchedTransaction?.externalReference && (
        <DetailsFieldWithButtonLink
          label={translate('component.transaction.invoiceDetails')}
          isExternalUrl={selectedTransaction.source === Source.XERO_INVOICE}
          redirectTo={getInvoiceExternalReferenceUrl(
            selectedTransaction.source,
            fetchedTransaction?.externalReference,
            fetchedTransaction?.externalReferenceUrl
          )}
          value={fetchedTransaction?.externalReference}
          isLoading={isNavigatingTransaction}
        />
      )}
      {!shouldShowInvoiceDetails && (
        <>
          <DetailsField
            label={component.transaction.site}
            value={getNonNullString(selectedTransaction.siteName, '-')}
            isLoading={isNavigatingTransaction}
          />
          {selectedTransaction.deviceName && (
            <DetailsField
              label={translate('component.transaction.device')}
              value={selectedTransaction.deviceName}
              isLoading={isNavigatingTransaction}
            />
          )}
        </>
      )}
      {isVirtualTerminalOrPayByLinkTransaction(source) && (
        <DetailsFieldWithButtonLink
          label={translate('component.transaction.virtualTerminalDetails')}
          value={fetchedTransaction?.externalReference}
          isLoading={isNavigatingTransaction}
          onClick={onVTReferenceClick}
        />
      )}
      {isLinklyPosTransaction(selectedTransaction) &&
        fetchedTransaction?.posName && (
          <DetailsField
            value={fetchedTransaction.posName}
            isLoading={isNavigatingTransaction}
          />
        )}
      {isHLPosTransaction(selectedTransaction) &&
        fetchedTransaction?.externalReference && (
          <DetailsField
            label={translate('component.transaction.tableNumber')}
            value={fetchedTransaction?.externalReference}
            isLoading={isNavigatingTransaction}
          />
        )}
      {shouldShowPosLiteDetails && (
        <PosLiteDetails
          externalReference={fetchedTransaction?.externalReference}
          isNavigatingTransaction={isNavigatingTransaction}
        />
      )}
      {!isRefundType && (
        <>
          <Divider />
          <DetailsField
            label={component.transaction.sale}
            value={currencyFormatter(fetchedTransaction?.saleAmount ?? 0)}
            isLoading={isNavigatingTransaction}
          />
          {!!selectedTransaction?.surchargeAmount && (
            <DetailsField
              label={component.transaction.surcharge}
              value={currencyFormatter(selectedTransaction?.surchargeAmount)}
              isLoading={isNavigatingTransaction}
            />
          )}
          {selectedTransaction?.taxAmounts?.map((tax) => (
            <DetailsField
              key={tax.name}
              label={tax.name}
              value={currencyFormatter(tax.amount)}
              isLoading={isNavigatingTransaction}
            />
          ))}
          <DetailsField
            label={component.transaction.tip}
            value={currencyFormatter(selectedTransaction?.tipAmount ?? 0)}
            isLoading={isNavigatingTransaction}
          />
          <DetailsField
            label={component.transaction.total}
            value={getFormattedTransactionAmount(selectedTransaction)}
            isLoading={isNavigatingTransaction}
          />
        </>
      )}
      {fetchedTransaction?.feeCharged &&
        fetchedTransaction?.feeAmount !== null && (
          <DetailsField
            label={component.transaction.processingFees}
            value={currencyFormatter(fetchedTransaction?.feeAmount)}
            isLoading={isNavigatingTransaction}
          />
        )}
      {fetchedTransaction?.refundedTransactionUuid && (
        <>
          <Divider />
          <DetailsFieldWithButtonLink
            label="Refunded Transaction"
            value={`#${fetchedTransaction?.refundedTransaction?.reference}`}
            onClick={() => {
              updateSelectedTransaction(
                fetchedTransaction.refundedTransactionUuid as string
              )
            }}
            isLoading={isNavigatingTransaction}
          />
        </>
      )}
      {fetchedTransaction?.splitPayment && (
        <SplitPayment
          transaction={fetchedTransaction}
          closeDrawer={closeDrawer}
        />
      )}
      {fetchedTransaction?.refunded && (
        <TransactionRefunds
          transaction={fetchedTransaction}
          onClickReference={updateSelectedTransaction}
        />
      )}
    </>
  )
}
