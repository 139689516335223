import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CardDisplayDebitCardV2FragmentDoc } from '../CardSummary/components/CardDisplay/CardDisplay.generated'
import { ZellerCardDebitCardV2FragmentDoc } from '../ZellerCard/ZellerCard.generated'
import { ZellerCardDetailsDebitCardV2FragmentDoc } from './ZellerCardDetails/ZellerCardDetails.generated'

export type ZellerCardSummaryDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  colour: Types.DebitCardColour
  productType: Types.DebitCardProductType | null
  status: Types.DebitCardStatus
  maskedPan: string
  accessibleProfile: boolean | null
  id: string
  format: Types.DebitCardFormat | null
  debitCardAccount: {
    __typename: 'DebitCardAccountV2'
    id: string
    name: string
    balance: { __typename?: 'Money'; value: string } | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
    } | null
  }
  customer: {
    __typename: 'Customer'
    id: string
    firstname: string | null
    lastname: string | null
    icon: {
      __typename?: 'Icon'
      image: string | null
      images: Array<{
        __typename?: 'Image'
        url: string
        size: Types.ImageSize
      }> | null
    } | null
  } | null
  velocityControl: {
    __typename?: 'VelocityControlType'
    isPendingSubscriptionUpdate: boolean | null
    resetsAt: any
    velocityWindow: Types.VelocityWindowEnum
    amountLimit: { __typename?: 'Money'; value: string }
    availableAmount: { __typename?: 'Money'; value: string }
    totalSpentAmount: { __typename?: 'Money'; value: string }
  } | null
}

export const ZellerCardSummaryDebitCardV2FragmentDoc = gql`
  fragment ZellerCardSummaryDebitCardV2Fragment on DebitCardV2 {
    colour
    ...ZellerCardDetailsDebitCardV2Fragment
    ...ZellerCardDebitCardV2Fragment
    ...CardDisplayDebitCardV2Fragment
  }
  ${ZellerCardDetailsDebitCardV2FragmentDoc}
  ${ZellerCardDebitCardV2FragmentDoc}
  ${CardDisplayDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  ZellerCardSummaryDebitCardV2Fragment,
  undefined
>
