import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import {
  ButtonFill,
  COLOR,
  SvgIcon,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { isNil, join, reject } from 'ramda'

import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg'

import { ROOT } from '../../../../../const/routes'
import { rvSelectedContact } from '../../../rv-deprecated/contacts'
import * as styled from '../../ContactLayout.styled'
import { translations } from './ContactCreateInvoiceButton.i18n'

export const ContactCreateInvoiceButtonDeps = {
  useIsMobileResolution,
  useNavigate,
  useReactiveVar,
  useTranslations,
}

export const ContactCreateInvoiceButton = () => {
  const {
    useIsMobileResolution,
    useNavigate,
    useReactiveVar,
    useTranslations,
  } = ContactCreateInvoiceButtonDeps

  const navigate = useNavigate()
  const isMobileResolution = useIsMobileResolution()
  const t = useTranslations(translations)

  const contact = useReactiveVar(rvSelectedContact)

  const handleOnClick = useCallback(() => {
    const contactName = join(
      ' ',
      reject(isNil, [
        // NOTE: first and last name will be null when business contact
        // type and vice ve
        contact?.firstName,
        contact?.lastName,
        contact?.businessName,
      ])
    )

    navigate(ROOT.PORTAL.INVOICING.INVOICES.NEW.path, {
      state: {
        initialValuesOverrides: {
          customer: {
            payerContact: {
              contactName,
              contactType: contact?.contactType,
              contactUuid: contact?.id,
            },
          },
        },
      },
    })
  }, [contact, navigate])

  if (isMobileResolution) {
    return (
      <styled.Button aria-label={t('createNewInvoice')} onClick={handleOnClick}>
        <SvgIcon color={COLOR.WHITE} width="16" height="16">
          <PlusIcon />
        </SvgIcon>
      </styled.Button>
    )
  }

  return (
    <ButtonFill aria-label={t('createNewInvoice')} onClick={handleOnClick}>
      {t('plusInvoice')}
    </ButtonFill>
  )
}
