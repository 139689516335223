import { gql, useApolloClient, useQuery } from '@apollo/client'

import {
  GetXeroSiteSettings as GetXeroSiteSettingsResponse,
  GetXeroSiteSettings_getXeroSiteSettings as GetXeroSiteSettingsResponseData,
} from 'types/gql-types/GetXeroSiteSettings'

export const GetXeroSiteSettings = gql`
  query GetXeroSiteSettings {
    getXeroSiteSettings {
      __typename
      id
      name
      receipt {
        email
        facebook
        instagram
        logo
        message
        name
        phone
        returnsMessage
        twitter
        website
      }
    }
  }
`

export const useGetXeroSiteSettings = () => {
  const { data, loading } =
    useQuery<GetXeroSiteSettingsResponse>(GetXeroSiteSettings)

  const client = useApolloClient()

  const updateSiteSettingsCache = (
    updatedValues: GetXeroSiteSettingsResponseData
  ) => {
    client.cache.writeQuery<GetXeroSiteSettingsResponse>({
      query: GetXeroSiteSettings,
      data: {
        getXeroSiteSettings: updatedValues,
      },
    })
  }

  return {
    siteSettings: data?.getXeroSiteSettings,
    updateSiteSettingsCache,
    isLoading: loading,
  }
}
