import { lazy, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { CompatRouter, Route, Routes } from 'react-router-dom-v5-compat'
import { FeatureFlagsProvider } from '@npco/mp-utils-mp-feature-flags'
import { DEFAULT_LOCALE, LocaleContextProvider } from '@npco/utils-translations'
import { theme, ToastRoot } from '@npco/zeller-design-system'
import { AuthorizedApolloProvider } from 'api/AuthorizedApolloProvider'
import { useAppReferrer } from 'features/AppReferrer'
import { useInitStoryblok } from 'features/StoryblokContent/hooks/useInitStoryblok'
import { ThemeProvider } from 'styled-components'

import { useGtm } from 'hooks/useGtm'
import { useSentry } from 'hooks/useSentry/useSentry'
import { ROOT } from 'const/routes'
import { AnalyticsProvider } from 'services/Analytics/AnalyticsProvider'
import { GlobalStyles } from 'styles/globalStyles.styled'
import { EmailVerified, ForgotPassword, SignUpRoute } from 'pages'
import { CardHolderDetailsOptOut } from 'pages/CardHolderDetailsOptOut/CardHolderDetailsOptOut'
import { CompleteEmailChange } from 'pages/CompleteEmailChange/CompleteEmailChange'
import { LoginErrorEntityAbandoned } from 'pages/ErrorPages/LoginErrorEntityAbandoned/LoginErrorEntityAbandoned'
import { LoginErrorPage } from 'pages/ErrorPages/LoginErrorPage/LoginErrorPage'
import { UnexpectedError } from 'pages/ErrorPages/UnexpectedError/UnexpectedError'
import { JoinWaitList } from 'pages/JoinWaitList/JoinWaitList'
import { Logout } from 'pages/Logout/Logout'
import { LogoutInactivityTimeout } from 'pages/Logout/LogoutInactivityTimeout'
import { App } from 'components/App'
import { LazyLoadComponent } from 'components/LazyLoadComponent/LazyLoadComponent'
import { page } from 'translations'

import { useSetZellerSessionIdAndSetupSardine } from './hooks/useSetZellerSessionIdAndSetupSardine/useSetZellerSessionIdAndSetupSardine'

const PublicOnfidoBiometricsPage = lazy(
  () =>
    import(
      'features/OnboardingApp/PublicOnfidoBiometrics/PublicOnfidoBiometrics'
    )
)

export const SIGNUP_FROM_APP_PARAM_KEY = 'signupfromapp'

export const Root = () => {
  useGtm()
  useSentry()
  useInitStoryblok()
  useSetZellerSessionIdAndSetupSardine()

  const AppReferrer = useAppReferrer()

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.get(SIGNUP_FROM_APP_PARAM_KEY) === 'true') {
      AppReferrer.setIsFromApp(true)
    }
  }, [AppReferrer])

  return (
    <FeatureFlagsProvider>
      <ThemeProvider theme={theme}>
        <ToastRoot />
        <GlobalStyles />
        <Router>
          <CompatRouter>
            <AnalyticsProvider>
              <Routes>
                <Route
                  path={ROOT.JOIN_WAITLIST.relative}
                  element={<JoinWaitList />}
                />
                <Route path={ROOT.SIGNUP.relative} element={<SignUpRoute />} />
                <Route path={`${ROOT.LOGOUT.relative}/*`}>
                  <Route index element={<Logout />} />
                  <Route
                    path={ROOT.LOGOUT.INACTIVITY_TIMEOUT.relative}
                    element={<LogoutInactivityTimeout />}
                  />
                </Route>
                <Route
                  path={ROOT.PORTAL.BIOMETRICS.PUBLIC_ONFIDO.path}
                  element={
                    <LazyLoadComponent>
                      <PublicOnfidoBiometricsPage />
                    </LazyLoadComponent>
                  }
                />
                <Route
                  path={ROOT.UNEXPECTED_ERROR.relative}
                  element={<UnexpectedError />}
                />

                <Route
                  path={ROOT.LOGIN_ERROR_ONBOARDING_STATUS.relative}
                  element={
                    <LoginErrorPage title={page.errorPages.inactiveAccount} />
                  }
                />
                <Route
                  path={ROOT.LOGIN_ERROR_ENTITY_DISABLED.relative}
                  element={
                    <LoginErrorPage title={page.errorPages.disabledAccount} />
                  }
                />
                <Route
                  path={ROOT.LOGIN_ERROR_ENTITY_ABANDONED.relative}
                  element={<LoginErrorEntityAbandoned />}
                />
                <Route
                  path={ROOT.FORGOT_PASSWORD.relative}
                  element={<ForgotPassword />}
                />
                <Route
                  path={ROOT.EMAIL_VERIFIED.relative}
                  element={<EmailVerified />}
                />
                <Route
                  path={ROOT.CHANGE_EMAIL.relative}
                  element={<CompleteEmailChange />}
                />
                <Route
                  path={ROOT.CUSTOMER_PREFERENCES.relative}
                  element={<CardHolderDetailsOptOut />}
                />
                <Route
                  path={`${ROOT.relative}/*`}
                  element={
                    <AuthorizedApolloProvider>
                      <LocaleContextProvider initialLocale={DEFAULT_LOCALE}>
                        <App />
                      </LocaleContextProvider>
                    </AuthorizedApolloProvider>
                  }
                />
              </Routes>
            </AnalyticsProvider>
          </CompatRouter>
        </Router>
      </ThemeProvider>
    </FeatureFlagsProvider>
  )
}
