import { matchPath, useLocation, useParams } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'

import { ROOT } from 'const/routes'
import { BreadcrumbPath } from 'components/Breadcrumbs/Breadcrumbs.types'

import { translations } from './useInvoicesBreadcrumbs.i18n'

export const useInvoicesBreadcrumbs = (
  referenceNumber?: string
): BreadcrumbPath[] => {
  const t = useTranslations(translations)
  const location = useLocation()
  const { invoiceRefNumber: referenceNumberFromState } = useParams()
  const invoiceRefNumber = referenceNumber || referenceNumberFromState

  const isInCreateInvoice = matchPath(
    location.pathname,
    ROOT.PORTAL.INVOICING.INVOICES.NEW.path
  )

  const isInEditInvoice = matchPath(
    location.pathname,
    ROOT.PORTAL.INVOICING.INVOICES.INVOICE(invoiceRefNumber).EDIT.path
  )

  const isInSettings = location.pathname.includes(
    ROOT.PORTAL.INVOICING.INVOICES.SETTINGS.relative
  )

  const invoicesBreadcrumbPath: BreadcrumbPath = {
    name: t('invoices'),
    path: ROOT.PORTAL.INVOICING.INVOICES.path,
  }
  const invoiceBreadcrumbPath: BreadcrumbPath = {
    name: invoiceRefNumber ?? '',
    path: ROOT.PORTAL.INVOICING.INVOICES.INVOICE(invoiceRefNumber).EDIT.path,
  }

  if (isInCreateInvoice) {
    const draftInvoiceBreadcrumbPath: BreadcrumbPath = {
      name: t('draft'),
      path: ROOT.PORTAL.INVOICING.INVOICES.NEW.path,
    }

    return [
      invoicesBreadcrumbPath,
      invoiceRefNumber ? invoiceBreadcrumbPath : draftInvoiceBreadcrumbPath,
    ]
  }

  if (isInEditInvoice) {
    return [invoicesBreadcrumbPath, invoiceBreadcrumbPath]
  }

  if (isInSettings) {
    let firstPart = { ...invoicesBreadcrumbPath }

    if (
      location.pathname.includes(ROOT.PORTAL.INVOICING.INVOICES.NEW.relative)
    ) {
      firstPart = {
        name: t('draft'),
        path: ROOT.PORTAL.INVOICING.INVOICES.NEW.path,
      }
    }

    if (invoiceRefNumber) {
      firstPart = {
        name: invoiceRefNumber,
        path: ROOT.PORTAL.INVOICING.INVOICES.INVOICE(invoiceRefNumber).EDIT
          .path,
      }
    }

    return [
      firstPart,
      {
        name: t('settings'),
        path: ROOT.PORTAL.INVOICING.INVOICES.SETTINGS.path,
      },
    ]
  }

  return []
}
