import { Route, Routes } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'

import { ROOT } from 'const/routes'
import { DepositsStorageHandler } from 'services/sessionStorage/handlers/DepositsStorageHandler/DepositsStorageHandler'
import { Deposits } from 'pages'
import { DepositsStatements } from 'pages/Deposits/DepositsStatements/DepositsStatements'
import { NotFound } from 'pages/NotFound'
import { RoleGuardedRoutes } from 'components/RoleGuardedRoutes'

import { DepositsSettingsRoutes } from './DepositsSettingsRoutes/DepositsSettingsRoutes'

export const DepositsRoutes = () => {
  return (
    <>
      <DepositsStorageHandler />

      <Routes>
        <Route
          element={<RoleGuardedRoutes allowedRoles={[CustomerRole.ADMIN]} />}
        >
          <Route index element={<Deposits />} />
          <Route
            path={ROOT.PORTAL.PAYMENTS.DEPOSITS.STATEMENTS.relative}
            element={<DepositsStatements />}
          />
          <Route
            path={`${ROOT.PORTAL.PAYMENTS.DEPOSITS.SETTINGS.relative}/*`}
            element={<DepositsSettingsRoutes />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}
