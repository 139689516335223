import { useEffect } from 'react'
import { MutationFunction } from '@apollo/client'
import {
  rvCurrentUserSites,
  rvSelectedUser,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import {
  UpdateCustomer as UpdateCustomerResponse,
  UpdateCustomerVariables,
} from 'types/gql-types/UpdateCustomer'

import { submitEditForm } from '../../utils'
import { useUpdateAdminMFAState } from './MFAState/useUpdateAdminMFAState'

export const useUpdateAdminOnMFARedirect = (
  updateCustomer: MutationFunction<
    UpdateCustomerResponse,
    UpdateCustomerVariables
  >
) => {
  const {
    updateAdminState,
    hasRedirectedBackToApp,
    setHasRedirectedBackToApp,
  } = useUpdateAdminMFAState()

  useEffect(() => {
    if (updateAdminState && hasRedirectedBackToApp) {
      rvSelectedUser(updateAdminState.variables.id)
      rvCurrentUserSites(updateAdminState.variables.sites)

      setHasRedirectedBackToApp(false)
      submitEditForm({
        initialValues: updateAdminState.initialValues,
        updateCustomer,
        values: updateAdminState.variables,
        isXeroPaymentServicesEnabled: true,
      })
    }
  }, [
    updateAdminState,
    updateCustomer,
    hasRedirectedBackToApp,
    setHasRedirectedBackToApp,
  ])
}
