import { useCallback } from 'react'

import { UNEXPECTED_ERROR } from '../../const/errors'
import { useGetTransferRemittancePdfLazyQuery } from '../../graphql/getTransferRemittancePdf.generated'

type useGetTransferRemittancePdfProps = {
  dcaTransactionUuid: string
}

export const useGetTransferRemittancePdf = ({
  dcaTransactionUuid,
}: useGetTransferRemittancePdfProps) => {
  const [getRemittancePdf, { loading: isLoadingPdfRemittance }] =
    useGetTransferRemittancePdfLazyQuery({
      variables: {
        dcaTransactionUuid,
      },
      fetchPolicy: 'network-only',
    })

  const getTransferRemittancePdf = useCallback(async () => {
    try {
      const result = await getRemittancePdf()

      if (
        !result.data?.getTransferRemittancePdf.downloadLink ||
        !!result.data.getTransferRemittancePdf.error
      ) {
        return UNEXPECTED_ERROR
      }

      return result.data.getTransferRemittancePdf.downloadLink
    } catch (err) {
      return UNEXPECTED_ERROR
    }
  }, [getRemittancePdf])

  return {
    getTransferRemittancePdf,
    isLoadingPdfRemittance,
  }
}
