import { useCallback, useMemo } from 'react'
import { InvoiceDiscountConfig } from '@npco/mp-gql-types'
import { Flex } from '@npco/zeller-design-system'
import currency from 'currency.js'
import {
  INVOICE_DEFAULT_PERCENTAGE,
  INVOICE_DEFAULT_PRICE,
  INVOICE_DISCOUNT_PERCENTAGE_FIELD,
  INVOICE_DISCOUNT_PRICE_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import {
  InvoiceDiscountFormFields,
  InvoiceFormFields,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash-es'

import { translate } from 'utils/translations'

import { useInvoiceItemsCalculations } from '../../../hooks/useInvoiceItemsCalculations'
import {
  InvoiceDiscountResetDiscount,
  InvoiceDiscountSubtotalText,
  InvoiceDiscountSubtotalValue,
  InvoiceDiscountTotalErrorText,
  InvoiceDiscountTotalText,
  InvoiceDiscountTotalValue,
  InvoiceDiscountWrapper,
} from './InvoiceDiscountTotals.styled'

export const translations = {
  discount: translate('page.invoice.formSections.items.discount'),
  resetDiscount: translate('page.invoice.formSections.items.resetDiscount'),
  subtotal: translate('page.invoice.formSections.items.invoiceSubTotal'),
  taxExcluded: translate('page.invoice.formSections.items.taxExcludedShort'),
  taxIncluded: translate('page.invoice.formSections.items.taxIncludedShort'),
  total: translate('page.invoice.formSections.items.total'),
  totalMinAmountError: (value: string) =>
    translate('page.invoice.formSections.items.itemsMinAmountError', { value }),
}

interface InvoiceDiscountTotalsProps {
  invoiceValues: InvoiceFormFields
}

export const InvoiceDiscountTotals = ({
  invoiceValues,
}: InvoiceDiscountTotalsProps) => {
  const {
    errors: discountErrors,
    setFieldValue,
    values: discountValues,
  } = useFormikContext<InvoiceDiscountFormFields>()

  const {
    discountFormatted,
    isTotalBelowMinAmount,
    subTotalFormatted,
    taxesFormatted,
    totalFormatted,
  } = useInvoiceItemsCalculations({
    values: invoiceValues,
    overrides: { discount: discountValues },
  })

  const { itemsTaxInclusive: isItemsTaxInclusive, minimumLimit } = invoiceValues

  const isPercentageDiscount =
    discountValues.config === InvoiceDiscountConfig.PERCENTAGE

  // NOTE: minimum has centi cents precision so wrap in new
  // currency object to get nearest cent precision (formatted/displayed total)
  const formattedMinimumLimit = currency(minimumLimit).format()

  const hasModifiedDiscountValue = useMemo(() => {
    if (isPercentageDiscount) {
      return discountValues.percentage !== INVOICE_DEFAULT_PERCENTAGE
    }

    return discountValues.price !== INVOICE_DEFAULT_PRICE
  }, [discountValues, isPercentageDiscount])

  const handleOnResetClick = useCallback(() => {
    if (isPercentageDiscount) {
      setFieldValue(
        INVOICE_DISCOUNT_PERCENTAGE_FIELD,
        INVOICE_DEFAULT_PERCENTAGE
      )

      return
    }

    setFieldValue(INVOICE_DISCOUNT_PRICE_FIELD, INVOICE_DEFAULT_PRICE)
  }, [isPercentageDiscount, setFieldValue])

  const showPercentageDiscount = isPercentageDiscount && isEmpty(discountErrors)

  return (
    <>
      <InvoiceDiscountWrapper>
        <InvoiceDiscountResetDiscount
          dataTestId="invoicing-invoice-discount-reset-discount"
          disabled={!hasModifiedDiscountValue}
          onClick={handleOnResetClick}
        >
          {translations.resetDiscount}
        </InvoiceDiscountResetDiscount>
        <Flex alignItems="flex-end" alignSelf="flex-end" flexDirection="column">
          <Flex
            data-testid="invoicing-invoice-discount-totals-wrapper"
            gridGap="8px"
          >
            <Flex align-items="flex-end" flexDirection="column" gridGap="12px">
              <InvoiceDiscountSubtotalText>
                {translations.subtotal}
              </InvoiceDiscountSubtotalText>
              <InvoiceDiscountSubtotalText data-testid="invoicing-invoice-discount-sub-total-text">
                {translations.discount}{' '}
                {showPercentageDiscount && `(${discountValues.percentage}%)`}
              </InvoiceDiscountSubtotalText>
              <InvoiceDiscountSubtotalText>
                {isItemsTaxInclusive
                  ? translations.taxIncluded
                  : translations.taxExcluded}
              </InvoiceDiscountSubtotalText>
              <InvoiceDiscountTotalText $hasError={isTotalBelowMinAmount}>
                {translations.total}
              </InvoiceDiscountTotalText>
            </Flex>
            <Flex flexDirection="column" gridGap="12px">
              <InvoiceDiscountSubtotalValue data-testid="invoicing-invoice-discount-sub-total-value">
                {subTotalFormatted}
              </InvoiceDiscountSubtotalValue>
              <InvoiceDiscountSubtotalValue data-testid="invoicing-invoice-discount-value">
                {discountFormatted}
              </InvoiceDiscountSubtotalValue>
              <InvoiceDiscountSubtotalValue data-testid="invoicing-invoice-discount-tax-value">
                {taxesFormatted}
              </InvoiceDiscountSubtotalValue>
              <InvoiceDiscountTotalValue
                $hasError={isTotalBelowMinAmount}
                dataTestId="invoicing-invoice-discount-total-value"
              >
                {totalFormatted}
              </InvoiceDiscountTotalValue>
            </Flex>
          </Flex>
        </Flex>
      </InvoiceDiscountWrapper>
      {isTotalBelowMinAmount && (
        <InvoiceDiscountTotalErrorText data-testid="invoicing-invoice-total-exceeded-min-amount-error">
          {translations.totalMinAmountError(formattedMinimumLimit)}
        </InvoiceDiscountTotalErrorText>
      )}
    </>
  )
}
