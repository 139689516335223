import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { OnboardingStatus } from '@npco/mp-gql-types'
import {
  rvEntityDetails,
  rvOnboardingMetadata,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { OnboardingLayout } from 'features/OnboardingApp/OnboardingLayout'
import { OnboardingPageLayout } from 'features/OnboardingApp/OnboardingPageLayout'
import { useOnboardingEntityStatus } from 'features/OnboardingApp/shared/useOnboardingEntityStatus'
import { Formik } from 'formik'

import { ROOT, ROUTE } from 'const/routes'
import { SettledSumValue } from 'types/onboarding'
import { page } from 'translations'

import { FormValues, SettledSumFieldsGroup } from './SettledSumFieldsGroup'

const NEXT_ROUTE_MAP = {
  [SettledSumValue.LessThan10k]: ROUTE.ONBOARDING_TRUST_CUSTOMERS_BENEFICIARIES,
  [SettledSumValue.MoreOrEqual10k]:
    ROUTE.ONBOARDING_TRUST_CUSTOMERS_SETTLOR_INFORMATION,
}

export const SettledSum = () => {
  const navigate = useNavigate()
  const previousMetadata = rvOnboardingMetadata()
  const { saveLastOnboardingEntityStatus } = useOnboardingEntityStatus(
    OnboardingStatus.SETTLORS_ESTABLISHED
  )
  const isStatusIDVComplete =
    rvEntityDetails().onboardingStatus === OnboardingStatus.IDV_COMPLETE

  const formSubmitHandler = useCallback(
    (values: FormValues) => {
      rvOnboardingMetadata({
        ...previousMetadata,
        helperFields: {
          ...previousMetadata.helperFields,
          settledSum: values.settledSum,
        },
      })

      const nextRoute = NEXT_ROUTE_MAP[values.settledSum as SettledSumValue]

      navigate(nextRoute)
    },
    [navigate, previousMetadata]
  )

  useEffect(() => {
    if (previousMetadata?.membersFilters?.settlor) {
      saveLastOnboardingEntityStatus(OnboardingStatus.SETTLORS_ESTABLISHED)
      navigate(ROOT.ONBOARDING.TRUST.CUSTOMERS.BENEFICIARIES.path)
    }
  }, [
    navigate,
    previousMetadata,
    isStatusIDVComplete,
    saveLastOnboardingEntityStatus,
  ])

  return (
    <OnboardingLayout.Content>
      <Formik
        initialValues={{
          settledSum:
            previousMetadata.helperFields?.settledSum ??
            SettledSumValue.LessThan10k,
        }}
        enableReinitialize
        onSubmit={formSubmitHandler}
      >
        {({ values, isSubmitting, submitForm }) => (
          <OnboardingPageLayout
            dataTestId="settled-sum"
            title={page.onboarding.settledSum.title}
            description={page.onboarding.settledSum.description}
            onConfirmClick={submitForm}
            confirmDisabled={!values.settledSum || isSubmitting}
            isVerticallyCentered={false}
            isBackButtonHidden
          >
            <SettledSumFieldsGroup />
          </OnboardingPageLayout>
        )}
      </Formik>
    </OnboardingLayout.Content>
  )
}
