import { useMemo } from 'react'

import { useGetOutstandingFilterSettingsQuery } from './graphql/getOutstandingFilterSettings.generated'

export const useOutstandingFilterSettings = () => {
  const { loading, data } = useGetOutstandingFilterSettingsQuery()

  const outstandingFilterSettings = useMemo(
    () => data?.getEntity.outstandingTransactionRequirementConfig,
    [data]
  )

  const outstandingSettings = useMemo(
    () => ({
      isReceiptOutstandingFilterSettingsOn:
        outstandingFilterSettings?.attachments ?? false,
      isNoteOutstandingFilterSettingsOn:
        outstandingFilterSettings?.note ?? false,
      isCategoryOutstandingFilterSettingsOn:
        outstandingFilterSettings?.category ?? false,
    }),
    [outstandingFilterSettings]
  )

  return {
    isLoadingOutstandingFilterSettings: loading,
    outstandingSettings,
    isOutstandingFilterSettingsAllInactive: Object.values(
      outstandingSettings
    ).every((value) => !value),
  }
}
