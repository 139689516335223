import { Flex, PillBasic, PillSize } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { StyledTagPillsWrapper } from './TagPills.styled'

interface TagPillsProps {
  tags: (string | null)[] | null
}
export const TagPills = ({ tags }: TagPillsProps) => {
  const isMobile = useIsMobileResolution()
  const tagCount = tags?.length || 0

  const pillsGenerator = (data: string) => {
    const dataLength = data?.length || 0
    const tagLengthLimit = isMobile ? 6 : 10

    const dataText =
      dataLength > tagLengthLimit
        ? `${data?.slice(0, tagLengthLimit)}...`
        : data

    return dataLength > 0 ? (
      <Flex p="2px 4px">
        <PillBasic
          text={dataText}
          size={PillSize.Small}
          dataTestId="contact-tag"
        />
      </Flex>
    ) : null
  }

  if (!tags) {
    return null
  }

  const defaultTag = tags[0] ? pillsGenerator(tags[0]) : null

  if (isMobile) {
    return tagCount > 1 ? pillsGenerator(`+${tagCount}`) : defaultTag
  }

  return (
    <StyledTagPillsWrapper>
      {defaultTag}
      {tagCount > 1 ? pillsGenerator(`+${tagCount - 1}`) : null}
    </StyledTagPillsWrapper>
  )
}
