import { useTranslations } from '@npco/utils-translations'
import { QuestionMarkIcon } from '@npco/zeller-design-system'

import { ROUTE } from 'const/routes'
import { translationsShared } from 'translations'

import { SidebarItem } from '../components/Item/Item'
import { useSidebarLocalCache } from '../hooks/useSidebarLocalCache'

export const NeedHelpItem = () => {
  const { isFolded } = useSidebarLocalCache()
  const t = useTranslations(translationsShared)

  return (
    <SidebarItem
      icon={<QuestionMarkIcon />}
      isFolded={isFolded}
      route={ROUTE.PORTAL_HELP}
      isNavLink
    >
      {t('help')}
    </SidebarItem>
  )
}
