import { useEffect } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'

import { ROOT, ROUTE } from 'const/routes'
import { useBreadcrumbParts } from 'components/Breadcrumbs/Breadcrumbs.hooks'

const OVERRIDE_PAGE_TITLE = {
  [ROUTE.PORTAL_HELP]: 'Help',
  [ROOT.PORTAL.REFERRAL.path]: 'Refer',
}

export const usePageTitle = () => {
  const { pathname } = useLocation()
  const breadcrumbParts = useBreadcrumbParts()

  const overrideTitle = OVERRIDE_PAGE_TITLE?.[pathname]

  const pageTitle =
    overrideTitle || breadcrumbParts[breadcrumbParts.length - 1]?.name

  useEffect(() => {
    document.title = pageTitle
      ? `${pageTitle} | Zeller Dashboard | All Your Business Finances in One Place`
      : 'Zeller Dashboard | All Your Business Finances in One Place'
  }, [pageTitle])
}
