import { Navigate, Outlet, Route, Routes } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'

import { ROOT } from 'const/routes'
import { NotFound } from 'pages/NotFound'
import { FeesAndPricing } from 'pages/Settings/FeesAndPricing/FeesAndPricing'
import { DashboardLayoutContent } from 'layouts/DashboardLayout'
import { RoleGuardedRoutes } from 'components/RoleGuardedRoutes'

import { SettingsConnectionsRoutes } from './SettingsConnectionsRoutes/SettingsConnectionsRoutes'
import { SettingsProfileRoutes } from './SettingsProfileRoutes/SettingsProfileRoutes'
import { SettingsUsersRoutes } from './SettingsUsersRoutes/SettingsUsersRoutes'

export const SettingsRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        }
      >
        <Route
          index
          element={
            <Navigate to={ROOT.PORTAL.SETTINGS.PROFILE.relative} replace />
          }
        />
        <Route
          path={`${ROOT.PORTAL.SETTINGS.PROFILE.relative}/*`}
          element={<SettingsProfileRoutes />}
        />
        <Route
          path={`${ROOT.PORTAL.SETTINGS.CONNECTIONS.relative}/*`}
          element={<SettingsConnectionsRoutes />}
        />
        <Route
          path={ROOT.PORTAL.SETTINGS.FEES_AND_PRICING.relative}
          element={<FeesAndPricing />}
        />
        <Route
          element={<RoleGuardedRoutes allowedRoles={[CustomerRole.ADMIN]} />}
        >
          <Route
            path={`${ROOT.PORTAL.SETTINGS.USERS.relative}/*`}
            element={<SettingsUsersRoutes />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}
