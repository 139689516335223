import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { ConnectionStatus, ConnectionType } from '@npco/mp-gql-types'
import {
  BodyDefault,
  Box,
  ButtonFill,
  ButtonGhost,
  DrawerPaddedContainer,
  DrawerWithIcon,
  Flex,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { Formik, FormikErrors } from 'formik'

import { ReactComponent as XeroLogo } from 'assets/svg/logos/xero.svg'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { SpinnerWrapped } from 'components/Spinner'

import { DisconnectModal } from '../components/DisconnectModal'
import { generateXeroOauthScopeAuthorizeURI } from '../Connections.utils'
import { useMutationDisconnect } from '../hooks/useMutationDisconnect'
import { TextRow } from '../XeroPaymentServices/components/TextRow'
import { Description } from './BankFeedsConnection.styled'
import { AccountSelect } from './Components/AccountSelect'
import { useXeroAccounts } from './hooks/useXeroAccounts'
import { useMutationManualSync } from './ManageXeroBankFeeds/useMutationManualSync'
import { useMutationUpdate } from './ManageXeroBankFeeds/useMutationUpdate'

interface FormikData {
  accounts: string[]
}

export const ManageXeroBankFeeds = () => {
  const navigate = useNavigate()
  const [isOpenDisconnectModal, setIsDisconnectModalOpen] = useState(false)
  const { loading, error, data } = useXeroAccounts()
  const [manualSync] = useMutationManualSync()
  const [update] = useMutationUpdate()
  const [disconnect] = useMutationDisconnect()

  const onClose = useCallback(
    () => navigate(ROOT.PORTAL.SETTINGS.CONNECTIONS.path),
    [navigate]
  )

  const handleReconnect = () =>
    window.location.replace(
      generateXeroOauthScopeAuthorizeURI(ConnectionType.XERO_BANKFEEDS)
    )

  const validate = useCallback((values: FormikData) => {
    const errors: FormikErrors<FormikData> = {}
    if (values.accounts?.length === 0) {
      errors.accounts = translate(
        'page.settings.connections.xeroBankFeeds.errors.noAccountSelected'
      )
    }
    return errors
  }, [])

  const onSubmit = useCallback(
    async (values) => {
      const result = await update(values)
      if (result.error) {
        return
      }

      showSuccessToast(
        translate('page.settings.connections.xeroBankFeeds.toast.saved')
      )
      onClose()
    },
    [update, onClose]
  )

  const onClickRemoveConnection = () => setIsDisconnectModalOpen(true)

  const onCancelDisconnectModal = () => setIsDisconnectModalOpen(false)

  const onConfirmDisconnectModal = useCallback(async () => {
    const result = await disconnect()
    if (result.error) {
      return
    }

    setIsDisconnectModalOpen(false)
    showSuccessToast(
      translate('page.settings.connections.xeroBankFeeds.toast.success')
    )
    onClose()
  }, [disconnect, onClose])

  return (
    <>
      <DrawerWithIcon
        isOpen
        icon={
          <Flex justifyContent="left">
            <XeroLogo width={48} height={48} />
          </Flex>
        }
        drawerTitle={translate('page.settings.connections.xeroBankFeeds.title')}
        onClose={onClose}
        overlayClassName="animated-drawer-overlay"
        hasBodyPadding={false}
      >
        {loading && <SpinnerWrapped variant="top" />}
        {!loading && (
          <Formik<FormikData>
            onSubmit={onSubmit}
            initialValues={{ accounts: data.selectedAccountIds }}
            validate={validate}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <Flex flexDirection="column" height="100%">
                  <Flex flexDirection="column" flex="1">
                    <DrawerPaddedContainer $hasPaddingTop>
                      <Box mb="24px">
                        <TextRow
                          label={translate(
                            'page.settings.connections.xeroBankFeeds.accountSelect.organisation'
                          )}
                          details={data.organisationName ?? ''}
                        />
                      </Box>
                      <Box mb="32px">
                        <Description>
                          {translate(
                            'page.settings.connections.xeroBankFeeds.accountSelect.description'
                          )}
                        </Description>
                      </Box>
                    </DrawerPaddedContainer>
                    {!error ? (
                      <AccountSelect accounts={data.accounts} />
                    ) : (
                      <div>
                        {translate(
                          'page.settings.connections.xeroBankFeeds.errors.accountsDataError'
                        )}
                      </div>
                    )}
                  </Flex>
                  <br />
                  <DrawerPaddedContainer>
                    <Flex flexDirection="column">
                      {data.connection.status ===
                        ConnectionStatus.CONNECTED_WITH_ERROR &&
                        data.connection.errorType === 'TOKEN_EXPIRED' && (
                          <ButtonFill onClick={handleReconnect}>
                            {translate(
                              'page.settings.connections.xeroBankFeeds.cta.reconnect'
                            )}
                          </ButtonFill>
                        )}
                      {data.connection.status ===
                        ConnectionStatus.CONNECTED_WITH_ERROR &&
                        data.connection.errorType === 'DATA_SYNC_ERROR' && (
                          <ButtonFill onClick={manualSync}>
                            {translate(
                              'page.settings.connections.xeroBankFeeds.cta.manualSync'
                            )}
                          </ButtonFill>
                        )}
                      {data.connection.status ===
                        ConnectionStatus.CONNECTED && (
                        <ButtonFill type="submit" isLoading={isSubmitting}>
                          {translate(
                            'page.settings.connections.xeroBankFeeds.cta.save'
                          )}
                        </ButtonFill>
                      )}

                      <ButtonGhost
                        dataTestId="remove-connection-trigger"
                        onClick={onClickRemoveConnection}
                      >
                        {translate(
                          'page.settings.connections.xeroBankFeeds.cta.removeConnection'
                        )}
                      </ButtonGhost>
                    </Flex>
                  </DrawerPaddedContainer>
                </Flex>
              </form>
            )}
          </Formik>
        )}
      </DrawerWithIcon>
      <DisconnectModal
        isOpen={isOpenDisconnectModal}
        onCancel={onCancelDisconnectModal}
        onConfirm={onConfirmDisconnectModal}
      >
        <BodyDefault>
          {translate(
            'page.settings.connections.xeroBankFeeds.removeConnectionDialogue.body.lineOne'
          )}
        </BodyDefault>
        <BodyDefault>
          {translate(
            'page.settings.connections.xeroBankFeeds.removeConnectionDialogue.body.lineTwo'
          )}
        </BodyDefault>
      </DisconnectModal>
    </>
  )
}
