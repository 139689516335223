import { useCallback } from 'react'
import { GetItemsSortColumnName } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Flex, TableHeaderCellIcon } from '@npco/zeller-design-system'
import { HeaderContext } from '@tanstack/react-table'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { SortAlphabeticallyList, SortIndicator } from 'components/Sort'

import { useItemManagementContext } from '../../ItemManagementContext/ItemManagementContext'
import { translations } from '../ItemsTable.i18n'
import { ItemsTableData } from '../ItemsTable.types'

export const ItemsHeaderRowNameCell = ({
  header,
}: HeaderContext<ItemsTableData, string>) => {
  const isMobile = useIsMobileResolution()

  const {
    items: { sortInput, setSortInput },
  } = useItemManagementContext()

  const t = useTranslations(translations)

  const isApplied = sortInput?.columnName === GetItemsSortColumnName.Name
  const isAscending = sortInput?.ascending === true

  const handleClick = useCallback(
    (setIsOpen: (state: boolean) => void) => (ascending: boolean) => {
      setIsOpen(false)

      if (
        sortInput?.ascending === ascending &&
        sortInput?.columnName === GetItemsSortColumnName.Name
      ) {
        setSortInput(null)
      } else {
        setSortInput({ ascending, columnName: GetItemsSortColumnName.Name })
      }
    },
    [sortInput, setSortInput]
  )

  if (isMobile) {
    return null
  }

  return (
    <TableHeaderCellIcon
      dataTestId={header.id}
      disablePopper={false}
      forwardedProps={{ style: { padding: 0 } }}
      icon={() => <SortIndicator $ascending={isAscending} />}
      isFiltering={isApplied}
      isSorting={isApplied}
      key={header.id}
      label={t('nameHeader')}
      popperWidth="160px"
    >
      {({ setIsOpen }) => (
        <Flex
          data-testid="header-row-name-popper"
          flexDirection="column"
          padding="8px"
        >
          <SortAlphabeticallyList
            onClick={handleClick(setIsOpen)}
            sort={isApplied ? isAscending : null}
          />
        </Flex>
      )}
    </TableHeaderCellIcon>
  )
}
