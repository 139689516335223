import { gql, type TypedDocumentNode } from '@apollo/client'

export type ItemDescriptionFragment = {
  __typename?: 'Item'
  description: string | null
  id: string
}

export const ItemDescriptionFragmentDoc = gql`
  fragment ItemDescriptionFragment on Item {
    description
    id
  }
` as unknown as TypedDocumentNode<ItemDescriptionFragment, undefined>
