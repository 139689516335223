import { Reference } from '@apollo/client'

import { useDeleteItemMutation } from '../graphql/deleteItem.generated'

export const useDeleteItem = () => {
  const [deleteItem, { loading: isDeletingItem, error, data }] =
    useDeleteItemMutation({
      update: (cache, result) => {
        cache.modify({
          broadcast: false,
          fields: {
            getItems: (existingItemRef, { readField }) => {
              return {
                ...existingItemRef,
                items: existingItemRef.items?.filter((itemRef: Reference) => {
                  const id = readField('id', itemRef)
                  return result.data?.deleteItem.id !== id
                }),
              }
            },
          },
        })
        cache.gc()
      },
    })

  return {
    deleteItem: (referenceNumber: string) =>
      deleteItem({
        variables: { referenceNumber },
      }),
    isDeletingItem,
    error,
    data,
  }
}
