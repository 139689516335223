import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { SavingAccountHeaderEntityFragmentDoc } from '../../../SavingsAccountHeader/SavingsAccountHeader.generated'

const defaultOptions = {} as const
export type EntitySavingsAccountProductQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type EntitySavingsAccountProductQueryResponse = {
  __typename?: 'Query'
  getEntity: {
    __typename?: 'Entity'
    id: string
    canCreateAccount: boolean | null
    savingsAccountProduct: {
      __typename?: 'EntitySavingsAccountProduct'
      effectiveInterestRate: string | null
    } | null
  }
}

export const EntitySavingsAccountProductQuery = gql`
  query EntitySavingsAccountProductQuery {
    getEntity {
      id
      ...SavingAccountHeaderEntityFragment
    }
  }
  ${SavingAccountHeaderEntityFragmentDoc}
` as unknown as TypedDocumentNode<
  EntitySavingsAccountProductQueryResponse,
  EntitySavingsAccountProductQueryVariables
>

/**
 * __useEntitySavingsAccountProductQuery__
 *
 * To run a query within a React component, call `useEntitySavingsAccountProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntitySavingsAccountProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntitySavingsAccountProductQuery({
 *   variables: {
 *   },
 * });
 */
export function useEntitySavingsAccountProductQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EntitySavingsAccountProductQueryResponse,
    EntitySavingsAccountProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EntitySavingsAccountProductQueryResponse,
    EntitySavingsAccountProductQueryVariables
  >(EntitySavingsAccountProductQuery, options)
}
export function useEntitySavingsAccountProductQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EntitySavingsAccountProductQueryResponse,
    EntitySavingsAccountProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EntitySavingsAccountProductQueryResponse,
    EntitySavingsAccountProductQueryVariables
  >(EntitySavingsAccountProductQuery, options)
}
export type EntitySavingsAccountProductQueryHookResult = ReturnType<
  typeof useEntitySavingsAccountProductQuery
>
export type EntitySavingsAccountProductQueryLazyQueryHookResult = ReturnType<
  typeof useEntitySavingsAccountProductQueryLazyQuery
>
export type EntitySavingsAccountProductQueryQueryResult = Apollo.QueryResult<
  EntitySavingsAccountProductQueryResponse,
  EntitySavingsAccountProductQueryVariables
>
