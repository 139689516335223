import { Box, Flex, SkeletonBasic } from '@npco/zeller-design-system'

export const CategoryDrawerLoader = () => {
  return (
    <Flex flexDirection="column" flexGrow={1} data-testid="loader">
      <SkeletonBasic height={20} width={201} borderRadius={10} />
      <Flex justifyContent="space-between" mt="23px" mb="20px">
        <SkeletonBasic height={12} width={120} borderRadius={10} />
        <SkeletonBasic height={12} width={80} borderRadius={10} />
      </Flex>
      <Flex justifyContent="space-between" mb="51px">
        <SkeletonBasic height={12} width={80} borderRadius={10} />
        <SkeletonBasic height={12} width={64} borderRadius={10} />
      </Flex>
      <SkeletonBasic height={20} width={120} borderRadius={10} />
      <Box mt="27px">
        <SkeletonBasic height={12} width={307} borderRadius={10} />
      </Box>
      <Box my="24px">
        <SkeletonBasic height={12} width={240} borderRadius={10} />
      </Box>
      <SkeletonBasic height={12} width={286} borderRadius={10} />
    </Flex>
  )
}
