import { Avatar } from '@npco/ui-avatar'
import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  Flex,
  InputAdaptiveField,
  InputAdaptiveTextArea,
  RadioPickerFormik,
} from '@npco/zeller-design-system'
import { Field } from 'formik'

import { ITEM_CATEGORY_COLOURS } from 'components/ItemBaseModalForm'

import {
  CATEGORY_DESCRIPTION_FIELD,
  CATEGORY_NAME_FIELD,
} from '../CategoryCreateModal/CategoryCreateModal.constants'
import { translations } from './CategoryModalFormFields.i18n'

interface CategoryModalFormFieldsProps {
  showDescriptionField?: boolean
}

export const CategoryModalFormFields = ({
  showDescriptionField = true,
}: CategoryModalFormFieldsProps) => {
  const t = useTranslations(translations)
  return (
    <Flex flexDirection="column" gridGap="20px" pt="12px">
      <div>
        <InputAdaptiveField
          data-testid="items-item-fields-name"
          name={CATEGORY_NAME_FIELD}
          label={t('nameLabel')}
        />
      </div>
      {showDescriptionField && (
        <div>
          <Field
            component={InputAdaptiveTextArea}
            id="description"
            label={t('descriptionLabel')}
            maxLength={1000}
            marginBottom="0"
            name={CATEGORY_DESCRIPTION_FIELD}
            rows={4}
          />
        </div>
      )}
      <div>
        <RadioPickerFormik name="color" color={COLOR.BLUE_1000}>
          {ITEM_CATEGORY_COLOURS.map((color) => (
            <RadioPickerFormik.Option
              key={color}
              id={color}
              value={color}
              dataTestId={`color-${color}`}
            >
              <Avatar color={color} text="" withCursorPointer />
            </RadioPickerFormik.Option>
          ))}
        </RadioPickerFormik>
      </div>
    </Flex>
  )
}
