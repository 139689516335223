import { useEffect } from 'react'
import { ModalBasic } from '@npco/zeller-design-system'

import { useGoToSetPinStage } from 'hooks/useGoToSetPinStage/useGoToSetPinStage'
import { SET_PIN_APPLICATION_ID, SET_PIN_URL } from 'const/envs'
import { ROUTE } from 'const/routes'

import { useSetPinTokens } from '../hooks/useSetPinTokens'
import { LoadingSetPin } from './LoadingSetPin/LoadingSetPin'
import { PinTokenError } from './PinTokenError/PinTokenError'
import { SetPinLoaded } from './SetPinLoaded/SetPinLoaded'

interface SetPinFrameProps {
  isReplacement?: boolean
}
export const SetPinFrame = ({ isReplacement = false }: SetPinFrameProps) => {
  const { oneTimeToken, userToken, cardToken, isLoading, hasError, retry } =
    useSetPinTokens()

  const { goToSetPinStage } = useGoToSetPinStage()

  useEffect(() => {
    window.closePinModal = () => {
      goToSetPinStage(undefined)
    }

    return () => {
      window.closePinModal = undefined
    }
  }, [goToSetPinStage])

  if (isLoading) {
    return (
      <ModalBasic.Body position="relative" height="280px" canScroll={false}>
        <LoadingSetPin />
      </ModalBasic.Body>
    )
  }

  if (hasError) {
    return (
      <ModalBasic.Body canScroll={false}>
        <PinTokenError retry={retry} />
      </ModalBasic.Body>
    )
  }

  const successUrl = `https://${window.location.host}${ROUTE.PIN_SUCCESS}?isReplacement=${isReplacement}`

  return (
    <SetPinLoaded
      src={`${SET_PIN_URL}?one_time_token=${oneTimeToken}&user_token=${userToken}&card_token=${cardToken}&application_id=${SET_PIN_APPLICATION_ID}&display_headers=false&success_url=${successUrl}`}
    />
  )
}
