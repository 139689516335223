import { useCallback } from 'react'

import { UNEXPECTED_ERROR } from 'types/errors'

import { usePersonaliseSavingsAccountMutation } from './graphql/PersonaliseSavingsAccount.generated'

type PersonaliseSavingsAccountArgs = {
  id: string
  displayName: string
}

export const usePersonaliseSavingsAccount = () => {
  const [personaliseSavingsAccountMutation, { loading }] =
    usePersonaliseSavingsAccountMutation()

  const personaliseSavingsAccount = useCallback(
    async ({ id, displayName }: PersonaliseSavingsAccountArgs) => {
      try {
        const result = await personaliseSavingsAccountMutation({
          variables: {
            input: {
              savingsAccountUuid: id,
              displayName,
            },
          },
        })

        if (!result.data?.personaliseSavingsAccount) {
          return UNEXPECTED_ERROR
        }

        return result.data.personaliseSavingsAccount
      } catch (err) {
        return UNEXPECTED_ERROR
      }
    },
    [personaliseSavingsAccountMutation]
  )

  return { personaliseSavingsAccount, isUpdatingSavingsAccount: loading }
}
