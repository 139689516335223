import { gql } from '@apollo/client'

export const GetOraclePosLocalState = gql`
  query GetOraclePosLocalState {
    local @client {
      oraclePos {
        isOverridePairingModalOpen
        currentDeviceUuid
        currentDeviceName
        newDeviceUuid
        newDeviceName
        workstationId
      }
    }
  }
`
