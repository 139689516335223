import { useTranslations } from '@npco/utils-translations'
import {
  NotesBasic,
  showErrorToast,
  showSuccessToast,
  useModalState,
} from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { EditNotesModal } from 'components/EditNotesModal/EditNotesModal'
import { translationsShared } from 'translations'

import { useItemsAnalytics } from '../../../../hooks/useItemsAnalytics'
import { useUpdateItemCategoryMutation } from '../graphql/updateItemCategory.generated'
import { CategoryDrawerDescriptionFragment } from './CategoryDrawerDescription.generated'
import { translations } from './CategoryDrawerDescription.i18n'

export const CategoryDrawerDescription = ({
  description: initialDescription,
  id,
}: CategoryDrawerDescriptionFragment) => {
  const isMobileResolution = useIsMobileResolution()
  const { trackEditCategory } = useItemsAnalytics()
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const { isModalOpen, openModal, closeModal } = useModalState()

  const [updateItemCategory, { loading: isUpdatingItemCategory }] =
    useUpdateItemCategoryMutation()

  const handleSubmit = (description: string) => {
    async function sendRequest() {
      await updateItemCategory({ variables: { input: { id, description } } })

      trackEditCategory(
        { description: initialDescription ?? '' },
        { description }
      )

      showSuccessToast(t('successNotification'))
      closeModal()
    }

    sendRequest().catch(() => showErrorToast(t('errorNotification')))
  }

  return (
    <div>
      <NotesBasic
        addLabel={tShared('edit')}
        editLabel={tShared('edit')}
        // Note: should update NotesDisplay component to display placeholder with '' input.
        // This bug was introduced by changing || to ?? in NotesDisplay:13
        note={initialDescription === '' ? null : initialDescription}
        onEdit={openModal}
        placeholder={t('noData')}
        title={t('heading')}
        maxDisplayHeight={isMobileResolution ? 'unset' : 360}
      />

      <EditNotesModal
        handleSubmit={handleSubmit}
        isLoading={isUpdatingItemCategory}
        isOpen={isModalOpen}
        notes={initialDescription}
        onCancel={closeModal}
        placeholder={t('placeholder')}
        title={t('heading')}
      />
    </div>
  )
}
