import { useEffect, useState } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import { BillingAccountType } from '@npco/mp-gql-types'
import { SelectBillingAccount } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/sim'
import { rvSimBillingAccount } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/simBillingAccount'

import { useAccounts } from 'hooks/banking'
import { useGetThirdPartyAccounts } from 'hooks/useGetThirdPartyAccounts'
import { DebitCardAccount, ThirdPartyBankAccount } from 'types/accounts'
import {
  SelectBillingAccount as SelectBillingAccountResponse,
  SelectBillingAccountVariables,
} from 'types/gql-types/SelectBillingAccount'

import { getAccountFromList } from '../SimBillingAccount.util'
import { useSimBillingAccount } from './useSimBillingAccount'

export const useFilterSimBillingAccount = () => {
  const { isLoadingAccounts, accounts: zellerAccounts } = useAccounts()

  const { isLoading: isLoadingThirdPartyAccount, thirdPartyAccounts } =
    useGetThirdPartyAccounts()

  const {
    simBillingAccount: fetchedSimBillingAccount,
    isLoadingSimBillingAccount,
  } = useSimBillingAccount()
  const accountIdFromApi = fetchedSimBillingAccount?.id

  const cachedAccountValue = useReactiveVar(rvSimBillingAccount)

  const [simBillingAccount, setSimBillingAccount] = useState<
    DebitCardAccount | ThirdPartyBankAccount | null
  >(null)

  useEffect(() => {
    if (!cachedAccountValue && accountIdFromApi) {
      const foundAccountFromApi = getAccountFromList(
        accountIdFromApi,
        zellerAccounts,
        thirdPartyAccounts
      )

      if (foundAccountFromApi) {
        rvSimBillingAccount(foundAccountFromApi)
        return setSimBillingAccount(foundAccountFromApi)
      }
    }

    return setSimBillingAccount(cachedAccountValue)
  }, [cachedAccountValue, accountIdFromApi, zellerAccounts, thirdPartyAccounts])

  return {
    isLoading:
      isLoadingAccounts ||
      isLoadingThirdPartyAccount ||
      isLoadingSimBillingAccount,
    simBillingAccount,
  }
}

interface BankAccount {
  id: string
}

export const useSimBillingAccountSelection = <T extends BankAccount>() => {
  const [selectSimBillingAccount, { loading }] = useMutation<
    SelectBillingAccountResponse,
    SelectBillingAccountVariables
  >(SelectBillingAccount)
  return {
    isLoading: loading,
    selectSimBillingAccount: async (account: T, type: BillingAccountType) => {
      await selectSimBillingAccount({
        variables: {
          input: {
            accountUuid: account.id,
            type,
          },
        },
      })
    },
  }
}
