import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom-v5-compat'
import { DebitCardAccountType } from '@npco/mp-gql-types'
import { ACCOUNT, useAccountNavigation } from '@npco/mp-utils-accounts-routing'
import { TabItemProps } from '@npco/zeller-design-system'

import { page } from 'translations'

export const useAccountLayoutTabsDeps = {
  useLocation,
  useAccountNavigation,
}

type useAccountLayoutTabsProps = {
  accountId: string
  accountType?: DebitCardAccountType
  isLoadingAccount: boolean
}

const TabValues = (accountId: string) => ({
  TRANSACTIONS: ACCOUNT(accountId).TRANSACTIONS.path,
  CARDS: ACCOUNT(accountId).CARDS.path,
  DETAILS: ACCOUNT(accountId).DETAILS.path,
  EDIT: ACCOUNT(accountId).EDIT.path,
})

export const useAccountLayoutTabs = ({
  accountId,
  accountType,
  isLoadingAccount,
}: useAccountLayoutTabsProps) => {
  const { useLocation, useAccountNavigation } = useAccountLayoutTabsDeps

  const { pathname } = useLocation()

  const {
    navigateToAccountCards,
    navigateToAccountDetails,
    navigateToAccountTransactions,
  } = useAccountNavigation()

  const tabs: TabItemProps[] = useMemo(() => {
    const tabData = [
      {
        id: 'account-layout-tabs-transactions',
        children: page.accounts.tabs.transactions,
        value: TabValues(accountId).TRANSACTIONS,
        onClick: () => navigateToAccountTransactions({ accountId }),
      },
      {
        id: 'account-layout-tabs-cards',
        children: page.accounts.tabs.cards,
        value: TabValues(accountId).CARDS,
        onClick: () => navigateToAccountCards({ accountId }),
      },
      {
        id: 'account-layout-tabs-details',
        children: page.accounts.tabs.details,
        value: TabValues(accountId).DETAILS,
        onClick: () => navigateToAccountDetails({ accountId }),
      },
    ]

    if (isLoadingAccount) {
      return []
    }

    if (accountType === DebitCardAccountType.SAVINGS) {
      return tabData.filter((item) => item.id !== 'account-layout-tabs-cards')
    }

    return tabData
  }, [
    accountType,
    accountId,
    isLoadingAccount,
    navigateToAccountCards,
    navigateToAccountDetails,
    navigateToAccountTransactions,
  ])

  return {
    tabs,
    currentTab: (value: string) => {
      return matchPath(pathname, TabValues(accountId).EDIT)
        ? value === TabValues(accountId).DETAILS
        : Boolean(matchPath(pathname, value))
    },
  }
}
