import { useCallback, useState } from 'react'
import { GetItemsPriceFilterColumnName, InputMaybe } from '@npco/mp-gql-types'

import { AmountRange } from 'components/AmountRange/AmountRange'

const MAX = 99999
const MIN = 0

type PriceFilter = InputMaybe<{
  priceFilter?: InputMaybe<{
    from?: InputMaybe<number>
    to?: InputMaybe<number>
  }>
}>

interface PriceFilterProps<T extends PriceFilter = PriceFilter> {
  filterInput: T
  setFilterInput: (filter: T) => void
  rangeDebounceDuration?: number
}

export const PriceFilter = <T extends PriceFilter>({
  rangeDebounceDuration = 300,
  filterInput,
  setFilterInput,
}: PriceFilterProps<T>) => {
  const [rangeInitialValues] = useState<[number, number]>(() => [
    filterInput?.priceFilter?.from ? filterInput.priceFilter.from / 10000 : MIN,
    filterInput?.priceFilter?.to ? filterInput.priceFilter.to / 10000 : MAX,
  ])

  const handleRangeChange = useCallback(
    (changedRange) => {
      setFilterInput({
        ...filterInput,
        priceFilter: {
          columnName: GetItemsPriceFilterColumnName.price,
          to: changedRange[1] * 10000,
          from: changedRange[0] * 10000,
        },
      })
    },
    [filterInput, setFilterInput]
  )

  return (
    <div data-testid="price-filter-items">
      <AmountRange
        debounceDuration={rangeDebounceDuration}
        initialValues={rangeInitialValues}
        onChange={handleRangeChange}
      />
    </div>
  )
}
