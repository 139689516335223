import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  aToZ: 'A to Z',
  category: 'Category',
  filterAndSortItems: 'Filter and Sort Items',
  filterByCategory: 'Filter by Category',
  filterByPrice: 'Filter by Price',
  filterByKeyword: 'Filter by Keyword',
  filters: 'Filters',
  highToLow: 'High to Low',
  itemName: 'Item Name',
  itemPrice: 'Item Price',
  lowToHigh: 'Low to High',
  price: 'Price',
  search: 'Search',
  searchItemOrCategory: 'Search Item or Category',
  sortBy: 'Sort By',
  unknownCategory: 'Unknown Category',
  zToA: 'Z to A',
})
