import { useEffect, useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetOverview } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/overview'
import {
  rvSelectedDates,
  rvSelectedStatuses,
  rvSelectedTypes,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { isEmpty } from 'lodash-es'

import { useOverviewFilters } from 'hooks/useOverviewFilters'
import {
  REPORTS_TRANSACTION_STATUSES,
  TRANSACTION_SALE_TYPE,
} from 'const/common'
import { resetFilters } from 'utils/common'
import dayjs from 'utils/dayjs'
import { mapFiltersInputToOverviewApiFilter } from 'utils/mapFiltersInputToApiFilter'
import {
  GetOverview as GetOverviewType,
  GetOverviewVariables,
} from 'types/gql-types/GetOverview'

import {
  checkIfFiltersNotSelected,
  convertFromApiTotals,
  fillMissingData,
  getDayOfWeekChartData,
  getInitialPeriod,
  getPlaceholderTransactions,
  getTimeOfDayChartData,
  getTotalSaleChartData,
  setLastSelectedOverviewDates,
  sumTransactionTotals,
} from './Reports.utils'

export const useReportsFetch = () => {
  const filters = useOverviewFilters()

  const filterObject = mapFiltersInputToOverviewApiFilter(filters, true)
  const areFiltersSelected = !checkIfFiltersNotSelected(filters)

  const [fetchOverviewData, { data, loading }] = useLazyQuery<
    GetOverviewType,
    GetOverviewVariables
  >(GetOverview, {
    variables: {
      filter: filterObject,
    },
    onCompleted: () => {
      if (filters.dates.from) {
        setLastSelectedOverviewDates()
      }
    },
    onError: (error) => {
      showApiErrorToast(error)
    },
  })

  const hasNoResults = isEmpty(data?.getTransactionTotalsBigInt)
  const hasNoInitialResults = !areFiltersSelected && hasNoResults
  useEffect(() => {
    resetFilters()

    rvSelectedDates(getInitialPeriod())
    rvSelectedStatuses([REPORTS_TRANSACTION_STATUSES[0]])
    rvSelectedTypes([TRANSACTION_SALE_TYPE])

    // As by default the date filter's value isn't set to the correct values
    // we fetch the data only after the filters' values have changed
    fetchOverviewData()

    return () => {
      setLastSelectedOverviewDates()
      resetFilters()
    }
  }, [fetchOverviewData])

  const transactionTotals = useMemo(
    () => convertFromApiTotals(data?.getTransactionTotalsBigInt || []),
    [data]
  )

  const chartsDataTransactionTotals = useMemo(() => {
    if (!hasNoInitialResults) {
      return transactionTotals
    }

    return getPlaceholderTransactions()
  }, [hasNoInitialResults, transactionTotals])

  const transactionsSum = useMemo(
    () => sumTransactionTotals(transactionTotals),
    [transactionTotals]
  )

  const isSelectionMoreThenTwoMonths = useMemo(
    () =>
      dayjs(filters.dates.to ?? new Date()).diff(
        filters.dates.from ?? new Date(),
        'month',
        true
      ) > 2,
    [filters.dates.from, filters.dates.to]
  )

  const totalSaleChartData = useMemo(() => {
    const chartData = getTotalSaleChartData(
      chartsDataTransactionTotals,
      isSelectionMoreThenTwoMonths
    )

    if (hasNoResults && hasNoInitialResults) {
      return chartData
    }

    const defaultDates = getInitialPeriod()

    return fillMissingData(
      chartData,
      filters.dates.from ?? defaultDates.from,
      filters.dates.to ?? defaultDates.to,
      isSelectionMoreThenTwoMonths ? 'month' : 'day'
    )
  }, [
    chartsDataTransactionTotals,
    isSelectionMoreThenTwoMonths,
    hasNoResults,
    hasNoInitialResults,
    filters.dates.from,
    filters.dates.to,
  ])

  const totalDeclinedChartData = useMemo(() => {
    const chartData = getTotalSaleChartData(
      chartsDataTransactionTotals,
      isSelectionMoreThenTwoMonths,
      true
    )

    if (hasNoResults && hasNoInitialResults) {
      return chartData
    }

    const defaultDates = getInitialPeriod()

    return fillMissingData(
      chartData,
      filters.dates.from ?? defaultDates.from,
      filters.dates.to ?? defaultDates.to,
      isSelectionMoreThenTwoMonths ? 'month' : 'day'
    )
  }, [
    chartsDataTransactionTotals,
    isSelectionMoreThenTwoMonths,
    hasNoResults,
    hasNoInitialResults,
    filters.dates.from,
    filters.dates.to,
  ])

  const timeOfDayChartData = useMemo(
    () => getTimeOfDayChartData(chartsDataTransactionTotals),
    [chartsDataTransactionTotals]
  )

  const timeOfDayDeclinedChartData = useMemo(
    () => getTimeOfDayChartData(chartsDataTransactionTotals, true),
    [chartsDataTransactionTotals]
  )

  const dayOfWeekChartData = useMemo(
    () => getDayOfWeekChartData(chartsDataTransactionTotals),
    [chartsDataTransactionTotals]
  )

  const dayOfWeekDeclinedChartData = useMemo(
    () => getDayOfWeekChartData(chartsDataTransactionTotals, true),
    [chartsDataTransactionTotals]
  )

  return {
    isLoading: loading,
    hasNoResults,
    hasNoInitialResults,
    transactionsSum,
    totalSaleChartData,
    timeOfDayChartData,
    dayOfWeekChartData,
    totalDeclinedChartData,
    timeOfDayDeclinedChartData,
    dayOfWeekDeclinedChartData,
    isSelectionMoreThenTwoMonths,
  }
}
