import { gql } from '@apollo/client'

export const AddThirdPartyBankAccount = gql`
  mutation AddThirdPartyBankAccount($input: CreateThirdPartyBankAccountInput!) {
    addThirdPartyBankAccount(input: $input) {
      accountBsb
      accountName
      accountNumber
      id
      name
    }
  }
`

export const SelectDepositAccount = gql`
  mutation SelectDepositAccount($id: ID!, $remitToCard: Boolean!) {
    selectDepositAccount(id: $id, remitToCard: $remitToCard)
  }
`

export const UpdateThirdPartyBankAccount = gql`
  mutation UpdateThirdPartyBankAccount(
    $input: UpdateThirdPartyBankAccountInput!
  ) {
    updateThirdPartyBankAccount(input: $input)
  }
`

export const RemoveThirdPartyBankAccount = gql`
  mutation RemoveThirdPartyBankAccount($thirdPartyBankAccountUuid: ID!) {
    removeThirdPartyBankAccount(
      thirdPartyBankAccountUuid: $thirdPartyBankAccountUuid
    )
  }
`
