import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useMutation, useReactiveVar } from '@apollo/client'
import { UpdateSiteInput } from '@npco/mp-gql-types'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { UpdateSite } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/sites'
import { rvSelectedSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import {
  rvSiteDetails,
  rvSiteFetchError,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables/sites'
import { Formik } from 'formik'
import { omit } from 'ramda'

import { ROOT } from 'const/routes'
import { ReceiptProps } from 'types/settings'
import { SiteCache } from 'types/site'
import { SettingsFormLayout } from 'layouts/SettingsFormLayout'
import { Receipt } from 'forms/formViews/Receipt/Receipt'
import { SpinnerWrapped } from 'components/Spinner'

const defaultValues = {
  email: '',
  facebook: '',
  instagram: '',
  merchantCopy: false,
  message: '',
  name: '',
  phone: '',
  printDeclinedTransaction: false,
  printLogo: false,
  printSocials: false,
  qrCode: false,
  returnsMessage: '',
  twitter: '',
  website: '',
}

export const SiteReceiptEdit = () => {
  const navigate = useNavigate()

  const siteDetails = useReactiveVar(rvSiteDetails) as SiteCache
  const siteError = useReactiveVar(rvSiteFetchError)
  const receiptData = (siteDetails?.receipt as ReceiptProps) || {}
  const { logo, ...restSettings } = receiptData
  const siteUuid = rvSelectedSite()
  const [temporaryLogo, setTemporaryLogo] = useState<string>('')

  const [updateSite, { loading }] = useMutation<UpdateSiteInput>(UpdateSite, {
    onError: (error) => showApiErrorToast(error),
  })

  const handleSubmit = (values: Omit<ReceiptProps, 'logo'>) => {
    const variables = {
      input: {
        id: siteUuid,
        receipt: omit(['logo'], values),
      },
    }

    updateSite({
      variables,
      update: () => {
        rvSiteDetails({
          ...siteDetails,
          address: siteDetails.address,
          devices: siteDetails.devices,
          id: siteUuid,
          name: siteDetails.name,
          pin: siteDetails.pin,
          type: siteDetails.type,
          customers: siteDetails.customers,
          receipt: { ...values, logo: temporaryLogo || logo },
        })
        navigate(ROOT.PORTAL.PAYMENTS.SITES.SITE.RECEIPT.path)
      },
    })
  }

  const handleRemoveLogo = () => {
    setTemporaryLogo('')
  }

  const initialValues = useMemo(
    () => restSettings || defaultValues,
    [restSettings]
  )

  return (
    <>
      {loading || (!siteDetails && !siteError) ? (
        <SpinnerWrapped variant="centre" />
      ) : (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, isSubmitting, submitForm, validateForm, resetForm }) => {
            return (
              <SettingsFormLayout
                dataTestId="site-receipt-edit"
                isSubmitButtonDisabled={Boolean(isSubmitting)}
                onBackButtonClick={resetForm}
                onSubmitButtonClick={() => {
                  submitForm()
                }}
              >
                <Receipt
                  values={{
                    logo,
                    ...values,
                  }}
                  logo={temporaryLogo || logo}
                  validateForm={validateForm}
                  handleRemoveLogo={handleRemoveLogo}
                  setTemporaryLogo={setTemporaryLogo}
                />
              </SettingsFormLayout>
            )
          }}
        </Formik>
      )}
    </>
  )
}
