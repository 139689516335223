export enum BreadcrumbParts {
  DEPOSITS = 'DEPOSITS',
  DEPOSITS_STATEMENTS = 'DEPOSITS_STATEMENTS',
  SITES = 'SITES',
  SITE = 'SITE',
  SITE_USERS_ADD = 'SITE_USERS_ADD',
  SITE_USERS_EDIT = 'SITE_USERS_EDIT',
  SITE_EDIT = 'SITE_EDIT',
  PAYMENT_DEVICES = 'PAYMENT_DEVICES',
  CREATE_NEW_SITE = 'CREATE_NEW_SITE',
  PROFILE_PERSONAL = 'PROFILE_PERSONAL',
  PROFILE_PERSONAL_EDIT = 'PROFILE_PERSONAL_EDIT',
  PROFILE_SETTINGS_CHANGE_NUMBER = 'PROFILE_SETTINGS_CHANGE_NUMBER',
  PROFILE_SETTINGS_VALIDATE_CODE = 'PROFILE_SETTINGS_VALIDATE_CODE',
  FEES_AND_PRICING = 'FEES_AND_PRICING',
  DEPOSIT_SETTINGS = 'DEPOSIT_SETTINGS',
  DEPOSTI_ACCOUNT_DETAIL = 'DEPOSIT_ACCOUNT_DETATIL',
  DEPOSTI_ACCOUNT_EDIT_ACCOUNT_DETAIL = 'DEPOSIT_ACCOUNT_EDIT_ACCOUNT_DETAIL',
  DEPOSIT_SETTINGS_CHANGE_ACCOUNT = 'DEPOSIT_SETTINGS_CHANGE_ACCOUNT',
  DEPOSIT_SETTINGS_ADD_ACCOUNT = 'DEPOSIT_SETTINGS_ADD_ACCOUNT',
  INVOICING = 'INVOICING',
  INVOICING_OVERVIEW = 'INVOICING_OVERVIEW',
  INVOICING_INVOICES = 'INVOICING_INVOICES',
  INVOICING_INVOICES_CREATE = 'INVOICING_INVOICES_CREATE',
  INVOICING_INVOICES_EDIT = 'INVOICING_INVOICES_EDIT',
  INVOICING_QUOTES = 'INVOICING_QUOTES',
  INVOICING_RECURRING = 'INVOICING_RECURRING',
  INVOICING_SETTINGS = 'INVOICING_SETTINGS',
  TRANSACTIONS = 'TRANSACTIONS',
  SIM = 'SIM',
  SIM_BILLING_HISTORY = 'SIM_BILLING_HISTORY',
  SIM_BILLING_ACCOUNT = 'SIM_BILLING_ACCOUNT',
  SIM_BILLING_HISTORY_STATEMENTS = 'SIM_BILLING_HISTORY_STATEMENTS',
  CARDS = 'CARDS',
  CONTACTS_PEOPLE = 'CONTACTS_PEOPLE',
  CONTACTS_BUSINESSES = 'CONTACTS_BUSINESSES',
  CONTACT_PERSON = 'CONTACT_PERSON',
  CONTACT_BUSINESS = 'CONTACT_BUSINESS',
  TRANSFER_CREATE = 'PORTAL_ACCOUNTS_TRANSFER_CREATE',
  REPORTS = 'REPORTS',
  OVERVIEW = 'OVERVIEW',
  USERS = 'USERS',
  USERS_CREATE = 'USERS_CREATE',
  USERS_EDIT = 'USERS_EDIT',
  SETTINGS_CONNECTIONS = 'SETTINGS_CONNECTIONS',
  XERO_PAYMENT_SERVICES_MANAGE = 'XERO_PAYMENT_SERVICES_MANAGE',
}

export enum BreadcrumbsVariable {
  siteName = ':siteName',
  cardName = ':cardName',
  depositAccountName = ':depositAccountName',
  simBillingAccountName = ':simBillingAccountName',
  contactName = ':contactName',
  invoiceRefNumber = ':invoiceRefNumber',
}

export interface BreadcrumbPath {
  name: string | BreadcrumbsVariable
  path: string
  parent?: BreadcrumbParts
  applyToPaths?: string[]
}

export type BreadcrumbVariableType = {
  [index: string]: string | null
}
