import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type SubmitDynamicCrnBpayPaymentMutationVariables = Types.Exact<{
  input: Types.SubmitDynamicCrnBpayPaymentInput
}>

export type SubmitDynamicCrnBpayPaymentMutationResponse = {
  __typename?: 'Mutation'
  submitDynamicCrnBpayPayment: {
    __typename?: 'SubmitBpayPaymentResponse'
    result: {
      __typename?: 'SubmitBpayPaymentResponseResult'
      id: string
      debitCardAccountUuid: string
      paymentInstrumentUuid: string
      billerName: string
      billerCode: string
      crn: string
      amount: { __typename?: 'Money'; currency: string; value: string }
    } | null
  }
}

export const SubmitDynamicCrnBpayPayment = gql`
  mutation SubmitDynamicCrnBpayPayment(
    $input: SubmitDynamicCrnBpayPaymentInput!
  ) {
    submitDynamicCrnBpayPayment(input: $input) {
      result {
        id
        debitCardAccountUuid
        paymentInstrumentUuid
        billerName
        billerCode
        crn
        amount {
          currency
          value
        }
      }
    }
  }
` as unknown as TypedDocumentNode<
  SubmitDynamicCrnBpayPaymentMutationResponse,
  SubmitDynamicCrnBpayPaymentMutationVariables
>
export type SubmitDynamicCrnBpayPaymentMutationFn = Apollo.MutationFunction<
  SubmitDynamicCrnBpayPaymentMutationResponse,
  SubmitDynamicCrnBpayPaymentMutationVariables
>

/**
 * __useSubmitDynamicCrnBpayPaymentMutation__
 *
 * To run a mutation, you first call `useSubmitDynamicCrnBpayPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitDynamicCrnBpayPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitDynamicCrnBpayPaymentMutation, { data, loading, error }] = useSubmitDynamicCrnBpayPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitDynamicCrnBpayPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitDynamicCrnBpayPaymentMutationResponse,
    SubmitDynamicCrnBpayPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubmitDynamicCrnBpayPaymentMutationResponse,
    SubmitDynamicCrnBpayPaymentMutationVariables
  >(SubmitDynamicCrnBpayPayment, options)
}
export type SubmitDynamicCrnBpayPaymentMutationHookResult = ReturnType<
  typeof useSubmitDynamicCrnBpayPaymentMutation
>
export type SubmitDynamicCrnBpayPaymentMutationResult =
  Apollo.MutationResult<SubmitDynamicCrnBpayPaymentMutationResponse>
export type SubmitDynamicCrnBpayPaymentMutationOptions =
  Apollo.BaseMutationOptions<
    SubmitDynamicCrnBpayPaymentMutationResponse,
    SubmitDynamicCrnBpayPaymentMutationVariables
  >
