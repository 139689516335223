import { gql } from '@apollo/client'

export const AccountLayoutQuery = gql`
  query AccountLayoutQuery($accountId: ID!) {
    getDebitCardAccountV2(debitCardAccountUuid: $accountId) {
      id
      balance {
        value
      }
      name
      status
      type
    }
    getEntity {
      __typename
      id
      canTransferOut
    }
  }
`
