import {
  Box,
  Flex,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_VARIANT,
  ModalBasic,
} from '@npco/zeller-design-system'
import { InvoiceItemCalculateFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { Form, Formik } from 'formik'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

import { useInvoiceItemCalculateModalHandlers } from './hooks/useInvoiceItemCalculateModalHandlers.ts/useInvoiceItemCalculateModalHandlers'
import {
  InvoiceItemCalculatePriceWrapper,
  InvoiceItemCalculateQuantityWrapper,
} from './InvoiceItemCalculateModal.styled'
import { validateForm } from './InvoiceItemCalculateModal.utils'
import { InvoiceItemCalculatePrice } from './InvoiceItemCalculatePrice/InvoiceItemCalculatePrice'
import { InvoiceItemCalculateQuantity } from './InvoiceItemCalculateQuantity/InvoiceItemCalculateQuantity'
import { InvoiceItemsCalculateTotals } from './InvoiceItemCalculateTotals/InvoiceItemsCalculateTotals'
import { InvoiceItemCalculateUnit } from './InvoiceItemCalculateUnit/InvoiceItemCalculateUnit'

export const translations = {
  cancel: translate('shared.cancel'),
  footerBody: translate(
    'page.invoice.formSections.items.itemsCalculateModalFooterBody'
  ),
  headerTitle: translate(
    'page.invoice.formSections.items.itemsCalculateModalHeaderTitle'
  ),
  save: translate('shared.save'),
}

export interface InvoiceItemCalculateModalProps {
  closeModal: () => void
  isTaxApplicable: boolean
  name: string
}

export const InvoiceItemCalculateModal = ({
  closeModal,
  isTaxApplicable,
  name,
}: InvoiceItemCalculateModalProps) => {
  const isMobileViewport = useIsMobileResolution()

  const { initialValues, invoiceValues, handleSubmit } =
    useInvoiceItemCalculateModalHandlers({
      closeModal,
      name,
    })

  const isTaxInclusive = invoiceValues.itemsTaxInclusive

  return (
    <ModalBasic
      isFullScreen={isMobileViewport}
      isOpen
      onCancel={closeModal}
      testId="invoicing-invoice-line-item-calculate-modal"
    >
      <Formik<InvoiceItemCalculateFormFields>
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        validate={(values) => validateForm(values)}
      >
        {({
          handleSubmit: handleFormikSubmit,
          submitForm: handleFormikSubmitForm,
        }) => (
          <Form
            data-testid="invoicing-invoice-line-item-calculate-form"
            onSubmit={handleFormikSubmit}
          >
            <ModalBasic.HeaderForm
              onClickPrimary={handleFormikSubmitForm}
              onClickSecondary={closeModal}
              primaryButtonLabel={translations.save}
              secondaryButtonLabel={translations.cancel}
              title={translations.headerTitle}
            />
            <ModalBasic.Body canScroll={false}>
              <Box
                paddingBottom="24px"
                paddingTop="12px"
                display="grid"
                gridGap="20px"
                gridTemplateColumns={{
                  _: 'repeat(2, 1fr)',
                  XS: 'repeat(3, 1fr)',
                }}
              >
                <InvoiceItemCalculateQuantityWrapper>
                  <InvoiceItemCalculateQuantity />
                </InvoiceItemCalculateQuantityWrapper>

                <Flex flexDirection="column">
                  <InvoiceItemCalculateUnit />
                </Flex>

                <InvoiceItemCalculatePriceWrapper>
                  <InvoiceItemCalculatePrice
                    isTaxApplicable={isTaxApplicable}
                    isTaxInclusive={isTaxInclusive}
                  />
                </InvoiceItemCalculatePriceWrapper>
              </Box>
              <InvoiceItemsCalculateTotals
                isTaxApplicable={isTaxApplicable}
                isTaxInclusive={isTaxInclusive}
              />
            </ModalBasic.Body>
            <ModalBasic.Footer>
              <Box paddingTop="22px">
                <InfoBox
                  dataTestId="invoicing-invoice-line-item-calculate-form-infobox"
                  priority={INFOBOX_PRIORITY.DEFAULT}
                  variant={INFOBOX_VARIANT.POSITIVE}
                >
                  {translations.footerBody}
                </InfoBox>
              </Box>
            </ModalBasic.Footer>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  )
}
