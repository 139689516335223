import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { ErrorLogger } from '@npco/utils-error-logger'
import { useLocationState } from '@npco/utils-routing'
import {
  DecisionModal,
  InfoBox,
  INFOBOX_VARIANT,
  showErrorToast,
  showSuccessToast,
  useModalState,
} from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { ConfigureXeroPaymentServicesVariables } from 'types/gql-types/ConfigureXeroPaymentServices'

import { useConfigurePaymentServices } from '../hooks/useConfigurePaymentServices'
import { updateOnSuccess } from '../XeroPaymentServices.utils'

export const RetryModal = () => {
  const { isModalOpen, openModal, closeModal } = useModalState()
  const { configureXeroPaymentServices } = useConfigurePaymentServices()
  const navigate = useNavigate()
  const locationState = useLocationState()

  useEffect(() => {
    if (locationState) {
      openModal()
    }
  }, [locationState, openModal])

  const handleOnClose = useCallback(() => {
    navigate(ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES.path)
    closeModal()
  }, [closeModal, navigate])

  const handleOnClickPrimary = useCallback(async () => {
    try {
      await configureXeroPaymentServices({
        variables: locationState as
          | ConfigureXeroPaymentServicesVariables
          | undefined,
        update: updateOnSuccess,
      })
      showSuccessToast(
        translate(
          'page.settings.connections.xeroBankFeeds.toast.connectionSuccess'
        )
      )
    } catch (err) {
      ErrorLogger.report(
        '[Payment] Error retry configure Xero Payment Services:',
        err
      )
      showErrorToast(
        translate(
          'page.settings.connections.xeroPaymentServices.toast.accessError'
        )
      )
    } finally {
      navigate(ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES.path)
      closeModal()
    }
  }, [closeModal, configureXeroPaymentServices, locationState, navigate])

  return (
    <DecisionModal
      title={translate(
        'page.settings.connections.xeroPaymentServices.retryModal.title'
      )}
      isOpen={isModalOpen}
      onCancel={handleOnClose}
      onClickSecondary={handleOnClose}
      onClickPrimary={handleOnClickPrimary}
      secondaryButtonLabel={translate('shared.cancel')}
      primaryButtonLabel={translate('shared.tryAgain')}
    >
      <InfoBox variant={INFOBOX_VARIANT.NEGATIVE}>
        <InfoBox.Header>
          {translate(
            'page.settings.connections.xeroPaymentServices.retryModal.infoTitle'
          )}
        </InfoBox.Header>
        <InfoBox.Message>
          {translate(
            'page.settings.connections.xeroPaymentServices.retryModal.infoDescription'
          )}
        </InfoBox.Message>
      </InfoBox>
    </DecisionModal>
  )
}
