import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { DebitCardTransactionFragmentDoc } from './debitCardTransactionFragment.generated'

const defaultOptions = {} as const
export type GetDebitCardTransactionsNewQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input']
  filter: Types.InputMaybe<Types.DebitCardTransactionFilterInput>
  nextToken: Types.InputMaybe<Types.Scalars['String']['input']>
  debitCardId: Types.Scalars['ID']['input']
  isOutstanding: Types.InputMaybe<Types.Scalars['Boolean']['input']>
}>

export type GetDebitCardTransactionsNewQueryResponse = {
  __typename?: 'Query'
  getDebitCardTransactions: {
    __typename?: 'DebitCardAccountTransactionsConnection'
    nextToken: string | null
    transactions: Array<{
      __typename: 'DebitCardAccountTransaction'
      attachments: Array<string> | null
      cardAcceptorName: string | null
      debitCardName: string | null
      id: string
      debitCardAccountUuid: string
      debitCardId: string | null
      debitCardMaskedPan: string | null
      description: string | null
      category: Types.EntityCategories | null
      subcategory: string | null
      status: Types.DebitCardAccountTransactionStatusEnum
      timestamp: any
      reference: string | null
      referencePayee: string | null
      type: Types.DebitCardAccountTransactionTypeEnum
      note: string | null
      tags: Array<string> | null
      amount: { __typename?: 'Money'; value: string }
      deposit: { __typename?: 'Deposit'; siteName: string; id: string } | null
      subcategoryDetails: {
        __typename?: 'Subcategory'
        id: string
        name: string
        predefined: boolean
      } | null
      contact: {
        __typename?: 'Contact'
        businessName: string | null
        category: Types.EntityCategories | null
        contactType: Types.ContactType
        firstName: string | null
        id: string
        lastName: string | null
        isSelf: boolean | null
        icon: {
          __typename?: 'Icon'
          colour: string | null
          letter: string | null
          images: Array<{
            __typename?: 'Image'
            size: Types.ImageSize
            url: string
          }> | null
        } | null
      } | null
      merchant: {
        __typename: 'MerchantDetails'
        id: string
        name: string | null
        hours: string | null
        phone: string | null
        email: string | null
        url: string | null
        abn: string | null
        location: string | null
        locationAccuracy: number | null
        icon: {
          __typename?: 'Icon'
          colour: string | null
          letter: string | null
          images: Array<{
            __typename?: 'Image'
            size: Types.ImageSize
            url: string
          }> | null
        } | null
        address: {
          __typename?: 'MerchantAddress'
          country: string | null
          postcode: string | null
          state: string | null
          street1: string | null
          street2: string | null
          suburb: string | null
        } | null
      } | null
      payerDetails: {
        __typename?: 'DebitCardTransactionCounterparty'
        debitCardAccountUuid: string | null
        paymentInstrumentUuid: string | null
        senderUuid: string | null
        accountDetails: {
          __typename?: 'BankAccountDetails'
          bsb: string | null
          account: string | null
          name: string | null
        } | null
        debitCardAccount: {
          __typename?: 'DebitCardAccountV2'
          id: string
          name: string
          type: Types.DebitCardAccountType
          icon: {
            __typename?: 'Icon'
            colour: string | null
            letter: string | null
            image: string | null
            animation: string | null
            images: Array<{
              __typename?: 'Image'
              size: Types.ImageSize
              url: string
            }> | null
          } | null
        } | null
      } | null
      payeeDetails: {
        __typename?: 'DebitCardTransactionCounterparty'
        debitCardAccountUuid: string | null
        paymentInstrumentUuid: string | null
        accountDetails: {
          __typename?: 'BankAccountDetails'
          bsb: string | null
          account: string | null
          name: string | null
        } | null
        bpayDetails: {
          __typename?: 'BpayDetails'
          billerName: string
          billerCode: string
          crn: string
          nickname: string
        } | null
        debitCardAccount: {
          __typename?: 'DebitCardAccountV2'
          id: string
          name: string
          type: Types.DebitCardAccountType
          icon: {
            __typename?: 'Icon'
            colour: string | null
            letter: string | null
            image: string | null
            animation: string | null
            images: Array<{
              __typename?: 'Image'
              size: Types.ImageSize
              url: string
            }> | null
          } | null
        } | null
      } | null
    } | null>
  }
}

export const GetDebitCardTransactionsNew = gql`
  query GetDebitCardTransactionsNew(
    $limit: Int!
    $filter: DebitCardTransactionFilterInput
    $nextToken: String
    $debitCardId: ID!
    $isOutstanding: Boolean
  ) {
    getDebitCardTransactions(
      limit: $limit
      filter: $filter
      nextToken: $nextToken
      debitCardId: $debitCardId
      isOutstanding: $isOutstanding
    ) {
      transactions {
        ...DebitCardTransactionFragment
      }
      nextToken
    }
  }
  ${DebitCardTransactionFragmentDoc}
` as unknown as TypedDocumentNode<
  GetDebitCardTransactionsNewQueryResponse,
  GetDebitCardTransactionsNewQueryVariables
>

/**
 * __useGetDebitCardTransactionsNewQuery__
 *
 * To run a query within a React component, call `useGetDebitCardTransactionsNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebitCardTransactionsNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebitCardTransactionsNewQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      nextToken: // value for 'nextToken'
 *      debitCardId: // value for 'debitCardId'
 *      isOutstanding: // value for 'isOutstanding'
 *   },
 * });
 */
export function useGetDebitCardTransactionsNewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDebitCardTransactionsNewQueryResponse,
    GetDebitCardTransactionsNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDebitCardTransactionsNewQueryResponse,
    GetDebitCardTransactionsNewQueryVariables
  >(GetDebitCardTransactionsNew, options)
}
export function useGetDebitCardTransactionsNewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDebitCardTransactionsNewQueryResponse,
    GetDebitCardTransactionsNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDebitCardTransactionsNewQueryResponse,
    GetDebitCardTransactionsNewQueryVariables
  >(GetDebitCardTransactionsNew, options)
}
export type GetDebitCardTransactionsNewQueryHookResult = ReturnType<
  typeof useGetDebitCardTransactionsNewQuery
>
export type GetDebitCardTransactionsNewLazyQueryHookResult = ReturnType<
  typeof useGetDebitCardTransactionsNewLazyQuery
>
export type GetDebitCardTransactionsNewQueryResult = Apollo.QueryResult<
  GetDebitCardTransactionsNewQueryResponse,
  GetDebitCardTransactionsNewQueryVariables
>
