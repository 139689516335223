import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import {
  GetHasInverseExpenseOrIncomeCashFlowPeriodicTotalAmountFragmentDoc,
  IncomeAndExpenseChartCashFlowPeriodicTotalAmountFragmentDoc,
} from '@npco/mp-feature-cashflow-reporting'
import * as Types from '@npco/mp-gql-types'

import { AccountsIncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragmentDoc } from '../../../AccountsIncomeAndExpenseTopSection/AccountsIncomeAndExpenseTopSection.generated'

const defaultOptions = {} as const
export type AccountsIncomeAndExpenseQueryVariables = Types.Exact<{
  date: Types.Scalars['AWSDate']['input']
  timeZone: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type AccountsIncomeAndExpenseQueryResponse = {
  __typename?: 'Query'
  getCashFlowTotalAmounts: {
    __typename?: 'CashFlowTotalAmountsAmounts'
    cashFlowPeriodicTotalAmounts: Array<{
      __typename?: 'cashFlowPeriodicTotalAmount'
      transactionDirectionTotalAmounts: {
        __typename?: 'TransactionDirectionTotalAmounts'
        income: { __typename?: 'Money'; value: string }
        expense: { __typename?: 'Money'; value: string }
      }
      range: { __typename?: 'CashFlowReportRange'; start: any }
    }> | null
  }
}

export const AccountsIncomeAndExpenseQuery = gql`
  query AccountsIncomeAndExpenseQuery($date: AWSDate!, $timeZone: String) {
    getCashFlowTotalAmounts(date: $date, timeZone: $timeZone) {
      cashFlowPeriodicTotalAmounts {
        ...AccountsIncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragment
        ...IncomeAndExpenseChartCashFlowPeriodicTotalAmountFragment
        ...GetHasInverseExpenseOrIncomeCashFlowPeriodicTotalAmountFragment
      }
    }
  }
  ${AccountsIncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragmentDoc}
  ${IncomeAndExpenseChartCashFlowPeriodicTotalAmountFragmentDoc}
  ${GetHasInverseExpenseOrIncomeCashFlowPeriodicTotalAmountFragmentDoc}
` as unknown as TypedDocumentNode<
  AccountsIncomeAndExpenseQueryResponse,
  AccountsIncomeAndExpenseQueryVariables
>

/**
 * __useAccountsIncomeAndExpenseQuery__
 *
 * To run a query within a React component, call `useAccountsIncomeAndExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsIncomeAndExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsIncomeAndExpenseQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useAccountsIncomeAndExpenseQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountsIncomeAndExpenseQueryResponse,
    AccountsIncomeAndExpenseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AccountsIncomeAndExpenseQueryResponse,
    AccountsIncomeAndExpenseQueryVariables
  >(AccountsIncomeAndExpenseQuery, options)
}
export function useAccountsIncomeAndExpenseQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountsIncomeAndExpenseQueryResponse,
    AccountsIncomeAndExpenseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AccountsIncomeAndExpenseQueryResponse,
    AccountsIncomeAndExpenseQueryVariables
  >(AccountsIncomeAndExpenseQuery, options)
}
export type AccountsIncomeAndExpenseQueryHookResult = ReturnType<
  typeof useAccountsIncomeAndExpenseQuery
>
export type AccountsIncomeAndExpenseQueryLazyQueryHookResult = ReturnType<
  typeof useAccountsIncomeAndExpenseQueryLazyQuery
>
export type AccountsIncomeAndExpenseQueryQueryResult = Apollo.QueryResult<
  AccountsIncomeAndExpenseQueryResponse,
  AccountsIncomeAndExpenseQueryVariables
>
