import { useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { DebitCardStatus } from '@npco/mp-gql-types'

import { isNotNull } from 'utils/common'
import { GetAssignedCardsQueryResponse } from 'components/AssignedCardsList/graphql/GetAssignedCards.generated'

import { GetAssignedCards } from '../../graphql/GetAssignedCards'

type Props = {
  selectedUserId: string
}
export const useGetActiveAssignedCards = ({ selectedUserId }: Props) => {
  const { data, loading, error, refetch, networkStatus } =
    useQuery<GetAssignedCardsQueryResponse>(GetAssignedCards, {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    })

  const assignedCards = useMemo(
    () =>
      (data?.getDebitCardsV2.cards || [])
        .filter(isNotNull)
        .filter((card) => card.status === DebitCardStatus.ACTIVE)
        .filter((card) => card.customerUuid === selectedUserId),
    [data?.getDebitCardsV2.cards, selectedUserId]
  )

  return {
    isCardsLoading: loading || networkStatus === NetworkStatus.refetch,
    cards: assignedCards,
    refetch,
    error,
  }
}
