import { useCallback, useRef, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { ButtonFill, ButtonGhost, Flex } from '@npco/zeller-design-system'

import * as styled from 'components/MobileFilters/MobileFilters.styled'
import { SearchInput } from 'components/SearchInput/SearchInput'
import { translationsShared } from 'translations'

type FilterBySearchProps<FilterType> = Readonly<{
  defaultValue?: string
  nextFilterInput: FilterType
  searchDebounceDuration?: number
  searchPlaceholder: string
  setDefaultVisibilityState: () => void
  setNextFilterInput: (nextFilterInput: FilterType) => void
}>

export const FilterBySearch = <FilterType,>({
  defaultValue = '',
  nextFilterInput,
  searchDebounceDuration = 300,
  searchPlaceholder,
  setNextFilterInput,
  setDefaultVisibilityState,
}: FilterBySearchProps<FilterType>) => {
  const tShared = useTranslations(translationsShared)

  const inputRef = useRef<HTMLInputElement>(null)

  const [nextLocalFilterInput, setNextLocalFilterInput] =
    useState<FilterType>(nextFilterInput)

  const handleApply = useCallback(() => {
    setDefaultVisibilityState()
    setNextFilterInput(nextLocalFilterInput)
  }, [nextLocalFilterInput, setNextFilterInput, setDefaultVisibilityState])

  const handleChange = useCallback(
    (nextSearch: string) =>
      setNextLocalFilterInput((previous) => ({
        ...previous,
        textSearchFilter: nextSearch,
      })),
    [setNextLocalFilterInput]
  )

  const handleClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }

    setNextLocalFilterInput((previous) => ({
      ...previous,
      textSearchFilter: null,
    }))
  }, [inputRef, setNextLocalFilterInput])

  return (
    <Flex data-testid="filters-search" flexDirection="column" flexGrow={1}>
      <Flex flexDirection="column" flexGrow={1}>
        <SearchInput
          aria-label="search input"
          autoFocus
          data-testid="filters-search-input"
          debounceDuration={searchDebounceDuration}
          defaultValue={defaultValue}
          name="search-input"
          onChange={handleChange}
          onClear={handleClear}
          placeholder={searchPlaceholder}
          ref={inputRef}
        />
      </Flex>
      <styled.MobileFiltersButtonWrapper>
        <ButtonGhost dataTestId="filters-clear" onClick={handleClear}>
          {tShared('clear')}
        </ButtonGhost>
        <ButtonFill dataTestId="filters-apply" onClick={handleApply}>
          {tShared('apply')}
        </ButtonFill>
      </styled.MobileFiltersButtonWrapper>
    </Flex>
  )
}
