import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { DebitCardAccountBalanceCellDebitCardV2FragmentDoc } from '../components/CardBalanceCell/DebitCardAccountBalanceCell.generated'
import { CardNameIconCellDebitCardV2FragmentDoc } from '../components/CardNameIconCell/CardNameIconCell.generated'
import { CardOwnerCellDebitCardV2FragmentDoc } from '../components/CardOwnerCell/CardOwnerCell.generated'

export type AccountCardsTableDebitCardV2Fragment = {
  __typename: 'DebitCardV2'
  id: string
  name: string
  owner: string | null
  status: Types.DebitCardStatus
  colour: Types.DebitCardColour
  maskedPan: string
  customer: { __typename?: 'Customer'; id: string } | null
}

export const AccountCardsTableDebitCardV2FragmentDoc = gql`
  fragment AccountCardsTableDebitCardV2Fragment on DebitCardV2 {
    __typename
    ...DebitCardAccountBalanceCellDebitCardV2Fragment
    ...CardNameIconCellDebitCardV2Fragment
    ...CardOwnerCellDebitCardV2Fragment
  }
  ${DebitCardAccountBalanceCellDebitCardV2FragmentDoc}
  ${CardNameIconCellDebitCardV2FragmentDoc}
  ${CardOwnerCellDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  AccountCardsTableDebitCardV2Fragment,
  undefined
>
