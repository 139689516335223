import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useShowScrollbar } from 'design-system/hooks/useShowScrollbar/useShowScrollbar'
import { ControllerStateAndHelpers } from 'downshift'
import { includes } from 'ramda'

import { conditionalClassName as cn } from 'utils/conditionalClassName'

import { usePopperConfig } from '../../../../shared/hooks/usePopperConfig'
import { StyledOptionsListContent } from '../../../Select/SelectBasic/SelectBasicDesktop/SelectBasicDesktop.styled'
import {
  ALL_VALUE,
  MultiSelectBasicProps,
  type MultiSelectItemBasic as Option,
} from '../../MultiSelect.types'
import {
  StyledMultiSelectPopper,
  StyledWrapper,
} from '../MultiSelectBasic.styled'
import { MultiSelectA11yHandler } from './MultiSelectA11yHandler'

type Props<TOption> = {
  allItems: TOption[]
  clearAllSelected: () => void
} & Omit<MultiSelectBasicProps<TOption>, 'onChange'> &
  ControllerStateAndHelpers<TOption>

export type { Props as MultiSelectBasicDesktopContentProps }

export const MultiSelectBasicDesktopContent = <TOption extends Option>({
  items,
  selectedItems,
  renderTopSection,
  renderBottomSection,
  renderAdditionalControls,
  allItemsLabel,
  renderItem,
  renderTrigger,
  renderLabel,
  renderNoItemsPlaceholder,
  selectStyle,
  selectSize,
  onClose,
  getToggleButtonProps,
  getItemProps,
  getMenuProps,
  getLabelProps,
  isOpen,
  closeMenu,
  getRootProps,
  reset,
  setHighlightedIndex,
  allItems,
  clearAllSelected,
  isDisabled,
  hasError,
  popperWidth,
  maxHeight = '12.5rem',
  shouldAutoFocusControl,
  onScrollAtBottom,
}: Props<TOption>) => {
  const triggerRef = useRef<HTMLButtonElement>(null)

  const focusTrigger = () => {
    triggerRef?.current?.focus()
  }

  const { menuAttributes, wrapperElementRef, menuElementRef } = usePopperConfig(
    undefined,
    popperWidth
  )

  const onCloseMultiselect = () => {
    onClose?.(selectedItems)
    closeMenu()
    reset()
    focusTrigger()
  }

  const {
    isCustomScrollbarAllowed,
    isScrollbarVisible,
    childRef,
    scrollWrapperRef,
  } = useShowScrollbar({})

  const shouldDisplayNoItemsPlaceholder = items.length === 0

  const bottomRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!isOpen) {
      return undefined
    }

    const root = scrollWrapperRef.current
    const target = bottomRef.current
    if (!(root && target)) {
      return undefined
    }

    // Skip if not using the new API and the IntersectionObserver isn't available.
    // This stops tests failing if a IntersectionObserver mock is missing but
    // the onScrollAtBottom prop isn't used.
    if (!onScrollAtBottom && !window.IntersectionObserver) {
      return undefined
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries
        if (entry?.isIntersecting) {
          onScrollAtBottom?.()
        }
      },
      { root }
    )
    observer.observe(target)
    return () => {
      observer.disconnect()
    }
  }, [scrollWrapperRef, isOpen, onScrollAtBottom])

  return (
    <StyledWrapper
      {...getRootProps()}
      ref={wrapperElementRef}
      data-testid="multiselect-desktop"
    >
      {renderLabel?.(getLabelProps())}
      {renderTrigger({
        isOpen,
        selectStyle,
        ...getToggleButtonProps({ ref: triggerRef }),
        size: selectSize,
        disabled: isDisabled,
        hasError,
      })}
      {ReactDOM.createPortal(
        <StyledMultiSelectPopper
          {...getMenuProps({
            ref: menuElementRef,
            ...menuAttributes,
          })}
          isOpen={isOpen}
          menuStyle={selectStyle}
          data-testid="multi-select-options-list"
          $width={popperWidth}
        >
          {isOpen && (
            <MultiSelectA11yHandler
              menuId={menuElementRef?.current?.id as string}
              onClose={onCloseMultiselect}
              highlightFirstItem={() => {
                setHighlightedIndex(0)
                focusTrigger()
              }}
              highlightLastItem={() => {
                setHighlightedIndex(allItems.length - 1)
                focusTrigger()
              }}
              shouldAutoFocusControl={shouldAutoFocusControl}
            />
          )}

          {renderTopSection?.({
            isOpen,
            onClose: onCloseMultiselect,
          })}
          {renderAdditionalControls?.({ closeMenu: onCloseMultiselect })}
          <StyledOptionsListContent
            ref={scrollWrapperRef}
            $maxHeight={maxHeight}
            className={cn({
              isScrollbarVisible,
              isCustomScrollbarAllowed,
              [selectStyle]: true,
            })}
          >
            <div ref={childRef}>
              {allItemsLabel &&
                renderItem?.({
                  label: allItemsLabel,
                  isChecked: selectedItems.length === items.length,
                  ...getItemProps({
                    item: {
                      value: ALL_VALUE,
                      label: allItemsLabel,
                    } as TOption,
                    index: 0,
                  }),
                })}
              {shouldDisplayNoItemsPlaceholder &&
                renderNoItemsPlaceholder?.({ closeMenu })}
              {!shouldDisplayNoItemsPlaceholder &&
                items.map((item, index) =>
                  renderItem({
                    ...getItemProps({
                      item,
                      key: item.value,
                      index: index + (allItemsLabel ? 1 : 0),
                      disabled: item.isDisabled,
                    }),
                    label: item.label,
                    item,
                    // NOTE: using ramda to ensure non primitive equality
                    isChecked: includes(item, selectedItems),
                  })
                )}
              {isOpen && (
                <div
                  ref={bottomRef}
                  style={{
                    width: '100%',
                    background: 'transparent',
                    height: 1,
                  }}
                />
              )}
            </div>
          </StyledOptionsListContent>
          {renderBottomSection?.({
            isOpen,
            onClick: clearAllSelected,
            onClose: onCloseMultiselect,
          })}
        </StyledMultiSelectPopper>,
        document.querySelector('body')!
      )}
    </StyledWrapper>
  )
}
