import { useHistory } from 'react-router-dom'
import { OnboardingLayout } from 'features/OnboardingApp/OnboardingLayout'
import { OnboardingPageLayout } from 'features/OnboardingApp/OnboardingPageLayout'
import { Formik } from 'formik'

import { ROUTE } from 'const/routes'
import { translate } from 'utils/translations'
import { useValidateCode } from 'components/ValidateCode/useValidateCode'

import { Description } from './Description/Description'
import { useOnboardingValidateCode } from './hooks/useOnboardingValidateCode'
import { ValidateCodeForm } from './ValidateCodeForm'

export const ValidateCode = () => {
  const history = useHistory()
  const { onSubmitCallback } = useOnboardingValidateCode()
  const { loading, handleSubmit, initialCode, hasError } = useValidateCode({
    onSubmitCallback,
  })

  return (
    <OnboardingLayout data-testid="validate-code">
      <Formik
        initialValues={initialCode}
        onSubmit={handleSubmit}
        component={({ submitForm }) => (
          <OnboardingPageLayout
            isLoading={loading}
            title={translate('page.validateCode.headline')}
            description={<Description />}
            nextButtonLabel={translate('shared.submit')}
            confirmDisabled={loading}
            onConfirmClick={submitForm}
            onBackClick={() => {
              history.push(ROUTE.REGISTER_PHONE)
            }}
            isForm={false}
          >
            <ValidateCodeForm hasError={hasError} />
          </OnboardingPageLayout>
        )}
      />
    </OnboardingLayout>
  )
}
