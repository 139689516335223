import { useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { ConnectionStatus } from '@npco/mp-gql-types'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { showApiErrorToast } from '@npco/zeller-design-system'

import { GetConnections as GetConnectionsResponse } from 'types/gql-types/GetConnections'

import { buildConnections } from '../connectionConfigs.utils'
import { Connection } from '../Connections.types'
import { GetConnections } from '../graphql/GetConnections'

export const useConnections = () => {
  const {
    loading,
    data,
    error,
    networkStatus,
    refetch: refetchConnections,
  } = useQuery<GetConnectionsResponse>(GetConnections, {
    onError: (err) => {
      showApiErrorToast(err)
    },
    notifyOnNetworkStatusChange: true,
  })
  const flags = useFeatureFlags()

  const connections: Connection[] = useMemo(
    () => buildConnections(data, flags),
    [data, flags]
  )

  const linkedConnections = connections.filter((connection) =>
    [
      ConnectionStatus.CONNECTED,
      ConnectionStatus.CONNECTED_WITH_ERROR,
    ].includes(connection.status as ConnectionStatus)
  )
  const unlinkedConnections = connections.filter((connection) =>
    [
      ConnectionStatus.NOT_CONNECTED,
      ConnectionStatus.PARTIALLY_CONNECTED,
    ].includes(connection.status as ConnectionStatus)
  )

  return {
    isLoading: loading || networkStatus === NetworkStatus.refetch,
    connections,
    linkedConnections,
    unlinkedConnections,
    error,
    refetchConnections,
    data,
  }
}
