import { ApolloCache } from '@apollo/client'

import { ActivateSavingsAccountMutationResponse } from '../hooks/useActivateSavingsAccount/graphql/ActivateSavingsAccount.generated'
import { SavingsAccountsListQuery } from './graphql/SavingsAccountsListQuery.generated'

type ListPosition = 'start-of-list' | 'end-of-list'

export const cacheNewSavingsAccount = (
  cache: ApolloCache<unknown>,
  newAccount: ActivateSavingsAccountMutationResponse['createSavingsAccount'],
  position: ListPosition = 'end-of-list'
) => {
  cache.updateQuery({ query: SavingsAccountsListQuery }, (data) => {
    // eslint-disable-next-line no-underscore-dangle
    if (!data) {
      return data
    }

    // eslint-disable-next-line no-underscore-dangle
    if (newAccount.__typename !== 'DebitCardAccountV2') {
      return undefined
    }

    return {
      ...data,
      getSavingsAccounts:
        position === 'start-of-list'
          ? [newAccount, ...data.getSavingsAccounts]
          : [...data.getSavingsAccounts, newAccount],
    }
  })
}
