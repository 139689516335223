import { useCallback, useMemo } from 'react'
import { GetCategoriesSortColumnName } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  ButtonGhost,
  Divider,
  Flex,
  TableHeaderCellIcon,
} from '@npco/zeller-design-system'
import { HeaderContext } from '@tanstack/react-table'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { SortAlphabeticallyList, SortIndicator } from 'components/Sort'
import { translationsShared } from 'translations'

import { CategoriesFilter } from '../../CategoriesFilter/CategoriesFilter'
import { useItemManagementContext } from '../../ItemManagementContext/ItemManagementContext'
import { translations } from '../CategoriesTable.i18n'
import { CategoryTableData } from '../CategoriesTable.types'

export const CategoriesHeaderRowNameCell = ({
  header,
}: HeaderContext<CategoryTableData, string>) => {
  const {
    categories: { filterInput, setFilterInput, setSortInput, sortInput },
  } = useItemManagementContext()

  const isMobile = useIsMobileResolution()
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const isSortApplied =
    sortInput?.columnName === GetCategoriesSortColumnName.Name
  const isAscending = sortInput?.ascending === true

  const isActive =
    !!filterInput?.idFilter?.categoryUuids?.length || isSortApplied

  const selectedCategories = useMemo(
    () => filterInput?.idFilter?.categoryUuids ?? [],
    [filterInput?.idFilter?.categoryUuids]
  )

  const setCategories = useCallback(
    (uuids: string[]) => {
      setFilterInput({
        idFilter: {
          categoryUuids: uuids,
        },
      })
    },
    [setFilterInput]
  )

  const handleSortClick = useCallback(
    (setIsOpen: (state: boolean) => void) => (ascending: boolean) => {
      setIsOpen(false)

      if (sortInput?.ascending === ascending && isSortApplied) {
        setSortInput(null)
      } else {
        setSortInput({
          ascending,
          columnName: GetCategoriesSortColumnName.Name,
        })
      }
    },
    [sortInput?.ascending, isSortApplied, setSortInput]
  )

  const handleClearClick = useCallback(() => {
    if (isSortApplied) {
      setSortInput(null)
    }

    setCategories([])
  }, [setCategories, setSortInput, isSortApplied])

  if (isMobile) {
    return null
  }

  const icon = () => <SortIndicator $ascending={isAscending} />

  return (
    <TableHeaderCellIcon
      dataTestId={header.id}
      icon={icon}
      isFiltering={isActive}
      isSorting={isActive}
      forwardedProps={{ style: { padding: 0 } }}
      key={header.id}
      label={t('name')}
      popperWidth="235px"
    >
      {({ setIsOpen }) => (
        <Flex
          data-testid="header-row-name-popper"
          flexDirection="column"
          padding="8px"
        >
          <SortAlphabeticallyList
            onClick={handleSortClick(setIsOpen)}
            sort={isSortApplied ? isAscending : null}
          />
          <Divider margin="20px 0px" />
          <CategoriesFilter
            selectedCategories={selectedCategories}
            setCategories={setCategories}
          />
          <Divider margin="20px 0px" />
          <Flex
            alignItems="flex-end"
            data-testid="categories-filter-clear"
            flexDirection="column"
          >
            <ButtonGhost onClick={handleClearClick}>
              {tShared('clear')}
            </ButtonGhost>
          </Flex>
        </Flex>
      )}
    </TableHeaderCellIcon>
  )
}
