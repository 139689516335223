import { CategoryFormFields } from './CategoryCreateModal.types'

export const CATEGORY_NAME_FIELD = 'name'
export const CATEGORY_DESCRIPTION_FIELD = 'description'
export const CATEGORY_COLOR_FIELD = 'color'

export const NAME_MAX_CHARACTERS = 256
export const DESCRIPTION_MAX_CHARACTERS = 1000

export const initialValues: CategoryFormFields = {
  [CATEGORY_COLOR_FIELD]: '',
  [CATEGORY_DESCRIPTION_FIELD]: '',
  [CATEGORY_NAME_FIELD]: '',
}
