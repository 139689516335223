import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type UpdateItemMutationVariables = Types.Exact<{
  item: Types.UpdateItemInput
}>

export type UpdateItemMutationResponse = {
  __typename?: 'Mutation'
  updateItem: {
    __typename: 'Item'
    id: string
    referenceNumber: string | null
    name: string
    sku: string | null
    price: number
    description: string | null
    taxes: Array<{
      __typename?: 'ItemTax'
      enabled: boolean
      name: string
      percent: number | null
    }> | null
    categories: Array<{
      __typename?: 'ItemCategory'
      id: string
      name: string
      color: string
    }> | null
  }
}

export const UpdateItem = gql`
  mutation UpdateItem($item: UpdateItemInput!) {
    updateItem(input: $item) {
      __typename
      id
      referenceNumber
      name
      sku
      price
      description
      taxes {
        enabled
        name
        percent
      }
      categories {
        id
        name
        color
      }
    }
  }
` as unknown as TypedDocumentNode<
  UpdateItemMutationResponse,
  UpdateItemMutationVariables
>
export type UpdateItemMutationFn = Apollo.MutationFunction<
  UpdateItemMutationResponse,
  UpdateItemMutationVariables
>

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useUpdateItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateItemMutationResponse,
    UpdateItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateItemMutationResponse,
    UpdateItemMutationVariables
  >(UpdateItem, options)
}
export type UpdateItemMutationHookResult = ReturnType<
  typeof useUpdateItemMutation
>
export type UpdateItemMutationResult =
  Apollo.MutationResult<UpdateItemMutationResponse>
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateItemMutationResponse,
  UpdateItemMutationVariables
>
