import { useCallback } from 'react'

import { UNEXPECTED_ERROR } from 'types/errors'

import {
  UnlinkPaymentInstrumentFromContactMutationVariables,
  useUnlinkPaymentInstrumentFromContactMutation,
} from './BpayInstrument.generated'

export const useUnlinkBpayPaymentInstrument = () => {
  const [unlinkPaymentInstrumentFromContactMutation, { loading }] =
    useUnlinkPaymentInstrumentFromContactMutation()

  const handleUnlinkBpayPaymentInstrument = useCallback(
    async (variables: UnlinkPaymentInstrumentFromContactMutationVariables) => {
      try {
        const response = await unlinkPaymentInstrumentFromContactMutation({
          variables,
        })

        if (!response.data || response.errors) {
          return UNEXPECTED_ERROR
        }

        return response.data.unlinkPaymentInstrumentFromContact
      } catch (error) {
        return UNEXPECTED_ERROR
      }
    },
    [unlinkPaymentInstrumentFromContactMutation]
  )

  return { handleUnlinkBpayPaymentInstrument, loading }
}
