import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetDeviceInformationQueryVariables = Types.Exact<{
  deviceUuid: Types.Scalars['ID']['input']
}>

export type GetDeviceInformationQueryResponse = {
  __typename?: 'Query'
  getDeviceInformation: {
    __typename?: 'DeviceInformation'
    id: string
    network: {
      __typename?: 'DeviceNetworkInformation'
      activeConnection: Types.ActiveConnection | null
      cellularInfo: {
        __typename?: 'DeviceNetworkCellularInformation'
        operatorName: string | null
        strength: string | null
      } | null
      ethernetInfo: {
        __typename?: 'DeviceNetworkEthernetInformation'
        ipAddress: string | null
        macAddress: string | null
      } | null
      wifiInfo: {
        __typename?: 'DeviceNetworkWifiInformation'
        ipAddress: string | null
        macAddress: string | null
        ssid: string | null
        strength: string | null
      } | null
    } | null
  }
}

export const GetDeviceInformation = gql`
  query GetDeviceInformation($deviceUuid: ID!) {
    getDeviceInformation(deviceUuid: $deviceUuid) {
      id
      network {
        activeConnection
        cellularInfo {
          operatorName
          strength
        }
        ethernetInfo {
          ipAddress
          macAddress
        }
        wifiInfo {
          ipAddress
          macAddress
          ssid
          strength
        }
      }
    }
  }
` as unknown as TypedDocumentNode<
  GetDeviceInformationQueryResponse,
  GetDeviceInformationQueryVariables
>

/**
 * __useGetDeviceInformationQuery__
 *
 * To run a query within a React component, call `useGetDeviceInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceInformationQuery({
 *   variables: {
 *      deviceUuid: // value for 'deviceUuid'
 *   },
 * });
 */
export function useGetDeviceInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDeviceInformationQueryResponse,
    GetDeviceInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDeviceInformationQueryResponse,
    GetDeviceInformationQueryVariables
  >(GetDeviceInformation, options)
}
export function useGetDeviceInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeviceInformationQueryResponse,
    GetDeviceInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDeviceInformationQueryResponse,
    GetDeviceInformationQueryVariables
  >(GetDeviceInformation, options)
}
export type GetDeviceInformationQueryHookResult = ReturnType<
  typeof useGetDeviceInformationQuery
>
export type GetDeviceInformationLazyQueryHookResult = ReturnType<
  typeof useGetDeviceInformationLazyQuery
>
export type GetDeviceInformationQueryResult = Apollo.QueryResult<
  GetDeviceInformationQueryResponse,
  GetDeviceInformationQueryVariables
>
