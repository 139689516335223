import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import {
  ModalBasic,
  showErrorToast,
  showSuccessToast,
  Typography,
} from '@npco/zeller-design-system'

import { GetOraclePosLocalState } from 'hooks/oraclePos/graphql/getOraclePosLocalState'
import { usePairOraclePosMutation } from 'hooks/oraclePos/graphql/pairOraclePos.generated'
import { OracleDefaultLocalState } from 'hooks/oraclePos/oraclePos.constants'
import { CacheLocalStateOraclePos } from 'hooks/oraclePos/oraclePos.types'
import { useOraclePosLocalCache } from 'hooks/oraclePos/useOraclePosLocalCache'
import { updateCacheOnPair } from 'hooks/oraclePos/utils/updateCacheOnPair'
import { updateCacheOnUnpair } from 'hooks/oraclePos/utils/updateCacheOnUnpair'

import { translations } from './OverrideOraclePosPairModal.i18n'

type OverrideOraclePosPairModalProps = {
  onSuccess?: () => void
}

export const OverrideOraclePosPairModal = ({
  onSuccess,
}: OverrideOraclePosPairModalProps) => {
  const t = useTranslations(translations)
  const { data: oraclePosCacheData } = useQuery<CacheLocalStateOraclePos>(
    GetOraclePosLocalState
  )
  const {
    isOverridePairingModalOpen,
    currentDeviceUuid,
    currentDeviceName,
    newDeviceName,
    newDeviceUuid,
    workstationId,
  } = oraclePosCacheData?.local.oraclePos ?? OracleDefaultLocalState
  const { updateCache } = useOraclePosLocalCache()
  const [pairOraclePosMutation, { loading: isPairingOraclePos }] =
    usePairOraclePosMutation({
      variables: {
        config: {
          deviceUuid: newDeviceUuid,
          workstationId: workstationId ?? '',
          overrideConfig: true,
        },
      },
      onCompleted: (data) => {
        if (data?.pairOraclePos) {
          showSuccessToast(t('successToast'))
          onSuccess?.()
        } else {
          showErrorToast(t('errorToast'))
        }
      },
      onError: () => {
        showErrorToast(t('errorToast'))
      },
      update: (cache, { data }) => {
        if (data?.pairOraclePos) {
          updateCacheOnPair({ cache, id: newDeviceUuid, workstationId })
          updateCacheOnUnpair({ cache, id: currentDeviceUuid })
          updateCache(OracleDefaultLocalState)
        }
      },
    })

  const onReplace = useCallback(async () => {
    await pairOraclePosMutation()
  }, [pairOraclePosMutation])

  const onCancel = useCallback(
    () => updateCache(OracleDefaultLocalState),
    [updateCache]
  )

  return (
    <ModalBasic
      isOpen={isOverridePairingModalOpen}
      onCancel={onCancel}
      title={t('title')}
    >
      <ModalBasic.Body>
        <span>
          {t('currentPairingInfo', {
            pairedDeviceName: (
              <Typography variant="heading-md" component="span">
                {currentDeviceName}
              </Typography>
            ),
          })}
        </span>
        <br />
        <span>
          {t('newPairingConfirmation', {
            newDeviceName: (
              <Typography variant="heading-md" component="span">
                {newDeviceName}
              </Typography>
            ),
          })}
        </span>
      </ModalBasic.Body>
      <ModalBasic.Footer>
        <ModalBasic.SecondaryButton onClick={onCancel}>
          {t('secondaryButton')}
        </ModalBasic.SecondaryButton>
        <ModalBasic.PrimaryButton
          isLoading={isPairingOraclePos}
          onClick={onReplace}
        >
          {t('primaryButton')}
        </ModalBasic.PrimaryButton>
      </ModalBasic.Footer>
    </ModalBasic>
  )
}
