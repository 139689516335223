import { useNavigate } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'
import { page } from 'translations'

import { StyledViewReportLink } from './ViewReportLink.styled'

export const ViewReportLink = () => {
  const navigate = useNavigate()
  return (
    <StyledViewReportLink
      onClick={() => navigate(ROOT.PORTAL.PAYMENTS.REPORTS.path)}
    >
      {page.dashboard.payments.sales.reportingButtonLabel}
    </StyledViewReportLink>
  )
}
