import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type DeleteAllItemsCategoriesMutationVariables = Types.Exact<{
  [key: string]: never
}>

export type DeleteAllItemsCategoriesMutationResponse = {
  __typename?: 'Mutation'
  deleteAllItemsAndCategories: boolean
}

export const DeleteAllItemsCategories = gql`
  mutation DeleteAllItemsCategories {
    deleteAllItemsAndCategories
  }
` as unknown as TypedDocumentNode<
  DeleteAllItemsCategoriesMutationResponse,
  DeleteAllItemsCategoriesMutationVariables
>
export type DeleteAllItemsCategoriesMutationFn = Apollo.MutationFunction<
  DeleteAllItemsCategoriesMutationResponse,
  DeleteAllItemsCategoriesMutationVariables
>

/**
 * __useDeleteAllItemsCategoriesMutation__
 *
 * To run a mutation, you first call `useDeleteAllItemsCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllItemsCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllItemsCategoriesMutation, { data, loading, error }] = useDeleteAllItemsCategoriesMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAllItemsCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAllItemsCategoriesMutationResponse,
    DeleteAllItemsCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAllItemsCategoriesMutationResponse,
    DeleteAllItemsCategoriesMutationVariables
  >(DeleteAllItemsCategories, options)
}
export type DeleteAllItemsCategoriesMutationHookResult = ReturnType<
  typeof useDeleteAllItemsCategoriesMutation
>
export type DeleteAllItemsCategoriesMutationResult =
  Apollo.MutationResult<DeleteAllItemsCategoriesMutationResponse>
export type DeleteAllItemsCategoriesMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteAllItemsCategoriesMutationResponse,
    DeleteAllItemsCategoriesMutationVariables
  >
