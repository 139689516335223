import { Box, ButtonGhostIconOnly } from '@npco/zeller-design-system'

import { ReactComponent as SearchIcon } from 'assets/svg/search.svg'

import * as styled from './SearchIconButtonWithIndicator.styled'

type SearchIconButtonWithIndicatorProps = {
  isActive: boolean
  onClick: () => void
}

export const SearchIconButtonWithIndicator = ({
  isActive,
  onClick: handleClick,
}: SearchIconButtonWithIndicatorProps) => (
  <Box position="relative">
    {isActive && <styled.ActiveIndicator data-testid="active-indicator" />}
    <ButtonGhostIconOnly
      aria-label="search icon"
      dataTestId="search-icon"
      icon={SearchIcon}
      isActive={isActive}
      onClick={handleClick}
    />
  </Box>
)
