import { KycStatus } from '@npco/mp-gql-types'

import { ROUTE } from 'const/routes'

// Add RC_VERIFIED when backend schema is ready
export const VERIFIED_STATUSES = [KycStatus.VERIFIED]

export const IN_REVIEW_STATUSES = [KycStatus.IN_REVIEW, KycStatus.RC_ABANDONED]

export const UNVERIFIED_STATUSES = [
  KycStatus.REQUIRED,
  KycStatus.RC_REJECTED,
  KycStatus.NOT_REQUIRED,
  null,
]

export const REVIEW_ALLOWED_ROUTES = [
  ROUTE.PORTAL_KYC_REVIEW_DOCUMENT_UPLOAD,
  ROUTE.PORTAL_KYC_DOCUMENT_UPLOAD,
  ROUTE.PORTAL_KYC_FINALISE,
  ROUTE.PORTAL_KYC_UPLOAD_SUCCESS,
]
