import { gql } from '@apollo/client'

import { DebitCardsTable } from 'components/CardsView/components/CardsTable/DebitCardsTable/DebitCardsTable'

import { useOnAddCard } from '../../../../../../hooks/useOnAddCard/useOnAddCard'

export const GetDebitCardsList = gql`
  query GetDebitCardsList($filter: DebitCardFilterInput) {
    getDebitCardsV2(limit: 50, filter: $filter) {
      cards {
        productType
        ...DebitCardsTableDebitCardV2Fragment
      }
    }
    getEntity {
      __typename
      ...UseOnAddCardEntityFragment
    }
  }

  ${DebitCardsTable.fragments.DebitCardV2}
  ${useOnAddCard.fragments.Entity}
`
