import type { SearchResults } from '../useBPayTargetItems'
import type { useGetBillersByCodeLazyQuery } from './GetBillersByCode.generated'
import type { useGetContactsByBillerCodeLazyQuery } from './GetContactsByBillerCode.generated'
import type { useGetContactsByNameLazyQuery } from './GetContactsByName.generated'
import { getItemsByCode } from './getItemsByQuery/getItemsByCode'
import { getItemsByName } from './getItemsByQuery/getItemsByName'

type GetItemsByQueryOptions = {
  query: string
  getBillersByCode: ReturnType<typeof useGetBillersByCodeLazyQuery>[0]
  getContactsByBillerCode: ReturnType<
    typeof useGetContactsByBillerCodeLazyQuery
  >[0]
  getContactsByName: ReturnType<typeof useGetContactsByNameLazyQuery>[0]
}

const CODE_REGEX = /^\d+$/

type Result = OmitVariant<SearchResults, 'Pending'>
type OmitVariant<TEnum, TKey> = TEnum extends { type: TKey } ? never : TEnum

export const getItemsByQuery = async ({
  query,
  getBillersByCode,
  getContactsByBillerCode,
  getContactsByName,
}: GetItemsByQueryOptions): Promise<Result> => {
  if (!query.length) {
    return { type: 'Ok', value: { items: [] } }
  }

  if (CODE_REGEX.exec(query)) {
    if (query.length < 4) {
      return { type: 'Ok', value: { items: [] } }
    }

    return getItemsByCode({
      code: query,
      getBillersByCode,
      getContactsByBillerCode,
    })
  }

  return getItemsByName({
    name: query,
    getContactsByName,
  })
}
