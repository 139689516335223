import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { TabItemProps } from '@npco/zeller-design-system'

import { ROOT, ROUTE } from 'const/routes'

import { useContactContext } from '../ContactContext/ContactContext'
import { translations } from '../Contacts.i18n'

const getActiveTab = (path: string) => path.substring(path.lastIndexOf('/') + 1)

const getRoutes = (pathname: string) => {
  if (pathname.includes(ROUTE.PORTAL_CONTACTS_PEOPLE)) {
    return {
      generalRoute: ROOT.PORTAL.CONTACTS.PEOPLE.CONTACT().GENERAL.relative,
      paymentsRoute: ROOT.PORTAL.CONTACTS.PEOPLE.CONTACT().PAYMENTS.relative,
      accountsRoute: ROOT.PORTAL.CONTACTS.PEOPLE.CONTACT().ACCOUNTS.relative,
      invoicesRoute: ROOT.PORTAL.CONTACTS.PEOPLE.CONTACT().INVOICES.relative,
    }
  }

  return {
    generalRoute: ROOT.PORTAL.CONTACTS.BUSINESSES.CONTACT().GENERAL.relative,
    paymentsRoute: ROOT.PORTAL.CONTACTS.BUSINESSES.CONTACT().PAYMENTS.relative,
    accountsRoute: ROOT.PORTAL.CONTACTS.BUSINESSES.CONTACT().ACCOUNTS.relative,
    invoicesRoute: ROOT.PORTAL.CONTACTS.BUSINESSES.CONTACT().INVOICES.relative,
  }
}

const PAYMENTS_TAB = 'payments'
const ACCOUNTS_TAB = 'accounts'
const INVOICES_TAB = 'invoices'

export const useContactLayout = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isPaymentsFilterButtonVisible } = useContactContext()

  const t = useTranslations(translations)

  const activeTab = getActiveTab(pathname)

  const tabs: TabItemProps[] = useMemo(() => {
    const { generalRoute, paymentsRoute, accountsRoute, invoicesRoute } =
      getRoutes(pathname)

    return [
      {
        id: 'contact-tabs-general',
        children: t('general'),
        value: generalRoute,
        onClick: () => navigate(generalRoute),
      },
      {
        id: 'contact-tabs-payments',
        children: t('payments'),
        value: paymentsRoute,
        onClick: () => navigate(paymentsRoute),
      },
      {
        id: 'contact-tabs-accounts',
        children: t('accounts'),
        value: accountsRoute,
        onClick: () => navigate(accountsRoute),
      },
      {
        id: 'contact-tabs-invoices',
        children: t('invoices'),
        value: invoicesRoute,
        onClick: () => navigate(invoicesRoute),
      },
    ]
  }, [navigate, pathname, t])

  const showAccountsFilterButton = activeTab === ACCOUNTS_TAB
  const showPaymentsFilterButton =
    activeTab === PAYMENTS_TAB && isPaymentsFilterButtonVisible
  const showInvoiceCreateButton = activeTab === INVOICES_TAB

  return {
    activeTab,
    showAccountsFilterButton,
    showInvoiceCreateButton,
    showPaymentsFilterButton,
    tabs,
  }
}
