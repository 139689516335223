import { ROOT, ROUTE } from 'const/routes'

import { ExpandableSidebarItems } from './Sidebar.types'

const expandableSidebarInclusionMap = {
  [ROUTE.PORTAL_PAYMENTS]: 'Payments',
  [ROUTE.PORTAL_SETTINGS]: 'Settings',
  [ROOT.PORTAL.ITEMS.path]: 'Items',
  [ROOT.PORTAL.REPORTS.path]: 'Reports',
} as const

const expandableSidebarExclusionList = ['/sites', '/virtual-terminal'] as const

export const getExpandableSidebarItemFromPath = (
  pathname: string
): ExpandableSidebarItems => {
  const isAnExcludedPath = expandableSidebarExclusionList.some((excludedPath) =>
    pathname.includes(excludedPath)
  )

  if (isAnExcludedPath) {
    return 'NONE'
  }

  const matchedKey = Object.keys(expandableSidebarInclusionMap).find((key) =>
    pathname.includes(key)
  )

  return matchedKey ? expandableSidebarInclusionMap[matchedKey] : 'NONE'
}
