import { PersonIcon } from '@npco/zeller-design-system'

import { ROUTE } from 'const/routes'
import { component } from 'translations'

import { SidebarItem } from '../components/Item/Item'
import { useSidebarLocalCache } from '../hooks/useSidebarLocalCache'

export const ContactsItem = () => {
  const { isFolded } = useSidebarLocalCache()

  return (
    <SidebarItem
      icon={<PersonIcon />}
      isFolded={isFolded}
      isNavLink
      route={ROUTE.PORTAL_CONTACTS}
      className="nav-item-contacts"
    >
      {component.sidebar.items.contacts}
    </SidebarItem>
  )
}
