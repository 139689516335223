import { useMutation } from '@apollo/client'
import { showErrorToast } from '@npco/zeller-design-system'
import { UnlinkContactWithCardholder } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/transactions'

import {
  UnlinkContactWithCardholder as UnlinkContactWithCardholderResponse,
  UnlinkContactWithCardholderVariables,
} from 'types/gql-types/UnlinkContactWithCardholder'
import { errorMessages } from 'translations'

interface UseUnlinkContactWithCardholderProps {
  suppressErrorToast?: boolean
}

export const useUnlinkContactWithCardholder = (
  props: UseUnlinkContactWithCardholderProps = {}
) => {
  const { suppressErrorToast = false } = props

  const [
    unlinkContactWithCardholder,
    { error, loading: isUnlinkingContactWithCardholder },
  ] = useMutation<
    UnlinkContactWithCardholderResponse,
    UnlinkContactWithCardholderVariables
  >(UnlinkContactWithCardholder, {
    onError: () => {
      if (!suppressErrorToast) {
        showErrorToast(errorMessages.unlinkCardholderFromContactError)
      }
    },
  })

  const unlinkContact = async (
    cardholderUuid: string,
    contactUuid: string
  ): Promise<boolean> => {
    const response = await unlinkContactWithCardholder({
      variables: { cardholderUuid, contactUuid },
    })

    const hasErrors = Boolean(response.errors)
    const result = Boolean(response.data?.unlinkContactWithCardholder)

    if (!result && !hasErrors && !suppressErrorToast) {
      showErrorToast(errorMessages.unlinkCardholderFromContactError)
    }

    return result
  }

  return {
    error,
    isUnlinkingContactWithCardholder,
    unlinkContactWithCardholder: unlinkContact,
  }
}
