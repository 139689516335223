import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { object, string } from 'yup'

import { ITEM_CATEGORY_COLOURS } from 'components/ItemBaseModalForm'

import {
  CATEGORY_COLOR_FIELD,
  CATEGORY_DESCRIPTION_FIELD,
  CATEGORY_NAME_FIELD,
  DESCRIPTION_MAX_CHARACTERS,
  NAME_MAX_CHARACTERS,
} from '../CategoryCreateModal.constants'
import { translations } from '../CategoryCreateModal.i18n'

export const useValidation = () => {
  const t = useTranslations(translations)
  return useMemo(
    () =>
      object({
        [CATEGORY_DESCRIPTION_FIELD]: string()
          .trim()
          .max(
            DESCRIPTION_MAX_CHARACTERS,
            t('descriptionMaxLengthError', {
              length: `${DESCRIPTION_MAX_CHARACTERS}`,
            })?.toString()
          ),
        [CATEGORY_NAME_FIELD]: string()
          .trim()
          .required(t('nameRequiredError'))
          .max(
            NAME_MAX_CHARACTERS,
            t('nameMaxLengthError', {
              length: `${NAME_MAX_CHARACTERS}`,
            })?.toString()
          ),
        [CATEGORY_COLOR_FIELD]: string().oneOf(ITEM_CATEGORY_COLOURS),
      }),
    [t]
  )
}
