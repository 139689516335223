import { gql } from '@apollo/client'

export const GetSidebarLocalState = gql`
  query GetSidebarLocalState {
    local @client {
      application {
        sidebar {
          isFolded
          itemExpanded
        }
      }
    }
  }
`
