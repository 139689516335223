import { useQuery } from '@apollo/client'
import { GetXeroSurchargeSettings } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/xeroSurchargeSettings'

import { GetXeroSurchargeSettings as GetXeroSurchargeSettingsResponse } from 'types/gql-types/GetXeroSurchargeSettings'

export const useXeroSurchargeSettings = () => {
  const { data, loading } = useQuery<GetXeroSurchargeSettingsResponse>(
    GetXeroSurchargeSettings
  )

  return {
    xeroSurchargeSettings:
      data?.getXeroSiteSettings.surchargesTaxes?.feesSurchargeXinv,
    isLoadingXeroSettings: loading,
  }
}
