import { BUTTON_SIZE, ButtonGhostType } from '@npco/zeller-design-system'
import { omit } from 'ramda'

import { shared } from 'translations'

import { StyledButtonGhostBack } from './ButtonBack.styled'

interface ButtonBackProps extends ButtonGhostType {
  label?: string
}
export const ButtonBack = ({ label, ...buttonProps }: ButtonBackProps) => {
  return (
    <StyledButtonGhostBack
      size={BUTTON_SIZE.LARGE}
      type="button"
      data-testid="back-button"
      {...omit(['size'], buttonProps)}
    >
      {label ?? shared.cancel}
    </StyledButtonGhostBack>
  )
}
