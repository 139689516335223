import styled from 'styled-components'

export const List = styled.ol`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  max-height: 240px;
  overflow-y: scroll;
  overflow-x: none;
  padding: 0px;
`
