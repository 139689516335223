import { useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'

import {
  GetSetupFlowDebitCardAccounts,
  GetSetupFlowDebitCardAccountsQueryResponse,
} from './graphql/getAccounts.generated'

export const useSetupFlowAccountsQuery = () => {
  const {
    loading: isLoadingAccounts,
    data,
    refetch,
    networkStatus,
  } = useQuery<GetSetupFlowDebitCardAccountsQueryResponse, never>(
    GetSetupFlowDebitCardAccounts,
    {
      notifyOnNetworkStatusChange: true,
    }
  )

  const accounts = useMemo(() => {
    return data?.getDebitCardAccountsV2.accounts
  }, [data?.getDebitCardAccountsV2?.accounts])

  return {
    isLoadingAccounts:
      isLoadingAccounts || networkStatus === NetworkStatus.refetch,
    accounts,
    refetchAccounts: refetch,
  }
}
