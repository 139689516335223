import { useEffect } from 'react'
import { getSardineConfig, setupSardine } from '@sardine-ai/web-sdk'
import { v4 as randomUUID } from 'uuid'

import { IS_PRODUCTION, ZELLER_SESSION_CLIENT_ID } from 'const/envs'
import { SESSION_STORAGE_KEYS } from 'services/sessionStorage/keys'
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'services/sessionStorage/utils'

export const useSetZellerSessionIdAndSetupSardine = () => {
  useEffect(() => {
    const sardineConfig = getSardineConfig()
    if (ZELLER_SESSION_CLIENT_ID && !sardineConfig) {
      setupSardine({
        clientId: ZELLER_SESSION_CLIENT_ID,
        environment: IS_PRODUCTION ? 'production' : 'sandbox',
      })
      const zellerSessionId = getSessionStorageItem(
        SESSION_STORAGE_KEYS.ZELLER_SESSION_ID
      )

      if (!zellerSessionId) {
        const zellerSessionId = randomUUID()

        setSessionStorageItem(
          SESSION_STORAGE_KEYS.ZELLER_SESSION_ID,
          zellerSessionId
        )
      }
    }
  }, [])
}
