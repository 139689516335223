import { gql } from '@apollo/client'

export const GetSettlementAccountInfo = gql`
  query GetSettlementAccountInfo(
    $debitCardAccountUuid: ID!
    $thirdPartyBankAccountUuid: ID!
    $remitToCard: Boolean!
  ) {
    zellerAccount: getDebitCardAccountV2(
      debitCardAccountUuid: $debitCardAccountUuid
    ) {
      name
      icon {
        colour
      }
    }
    thirdPartyBankAccount: getThirdPartyBankAccount(
      thirdPartyBankAccountUuid: $thirdPartyBankAccountUuid
    ) @skip(if: $remitToCard) {
      name
      accountBsb
      accountName
      accountNumber
    }
  }
`
