import { useEffect, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { ModalSize } from '@npco/zeller-design-system'

import { MultiStageModal } from 'components/MultiStageModal'

import { useItemsAnalytics } from '../../hooks/useItemsAnalytics'
import { useItemOnboarding } from '../ItemOnboarding/hooks/useItemOnboarding'
import {
  ItemsImportStage,
  useItemsImportModalStage,
} from './hooks/useItemsImportModalStage'
import { translations } from './ItemsImportModal.i18n'
import { ItemsImport } from './ItemsImportModal.types'
import { ItemsImportModalMatch } from './ItemsImportModalMatch/ItemsImportModalMatch'
import { ItemsImportModalUpload } from './ItemsImportModalUpload/ItemsImportModalUpload'

interface ItemsImportModalProps {
  initialItems?: ItemsImport | null
}

export const ItemsImportModal = ({
  initialItems = null,
}: ItemsImportModalProps) => {
  const { trackImportStarted } = useItemsAnalytics()

  const t = useTranslations(translations)
  const [items, setItems] = useState<ItemsImport | null>(initialItems)

  const { turnOffOnboardingModal } = useItemOnboarding()

  useEffect(() => {
    trackImportStarted()

    // NOTE: this function returns a promise so could throw, however any request
    // error is handled within the underlying update customer mutation
    turnOffOnboardingModal().catch(() => undefined)
    // NOTE: only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { stage, goToItems } = useItemsImportModalStage()

  return (
    <MultiStageModal
      title={t('title')}
      size={ModalSize.MEDIUM}
      isOpen
      onCancel={goToItems}
    >
      <MultiStageModal.ProgressBarProvider
        initialCurrentStep={5}
        initialTotalSteps={10}
      >
        <MultiStageModal.ProgressBar
          pr={['24px', '32px']}
          py="12px"
          dataTestId="progress-bar"
        />
        {stage === ItemsImportStage.UploadStage && (
          <ItemsImportModalUpload items={items} setItems={setItems} />
        )}
        {stage === ItemsImportStage.MatchStage && (
          <ItemsImportModalMatch items={items} />
        )}
      </MultiStageModal.ProgressBarProvider>
    </MultiStageModal>
  )
}
