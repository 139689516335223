import { gql, type TypedDocumentNode } from '@apollo/client'

import type * as GetSitesGQL from 'types/gql-types/GetSites'

export const GetSites = gql`
  query GetSites($limit: Int!, $nextToken: SiteNextTokenInput) {
    getSites(limit: $limit, nextToken: $nextToken) {
      sites {
        id
        name
        devices {
          id
          name
          model
          serial
        }
      }
      nextToken {
        type
        siteNameSortKey
      }
    }
  }
` as TypedDocumentNode<GetSitesGQL.GetSites, GetSitesGQL.GetSitesVariables>

export const GetSitesForPricing = gql`
  query GetSitesForPricing($limit: Int!, $nextToken: SiteNextTokenInput) {
    getSites(limit: $limit, nextToken: $nextToken) {
      sites {
        surchargesTaxes {
          feePercent
          feePercentMoto
          feeFixedCnp
          feePercentCnp
        }
        id
        name
        type
      }
      nextToken {
        type
        siteNameSortKey
      }
    }
  }
`

export const GetSite = gql`
  query GetSite($siteUuid: ID!) {
    getSite(siteUuid: $siteUuid) {
      id
      name
      type
      address {
        street
        suburb
        state
        postcode
      }
      customers {
        id
        firstname
        middlename
        lastname
        role
      }
      screensaver {
        customColours
        logos {
          logoUuid
          url
        }
        primaryColour
        primaryLogoUrl
        primaryLogoUuid
      }
      devices {
        id
        name
        model
        serial
      }
      pin
      surchargesTaxes {
        surchargeEnabled
        surchargeFullFees
        surchargePercent
        feePercent
        gstEnabled
        gstPercent
        taxes {
          name
          percent
        }
        surchargeEnabledMoto
        surchargeFullFeesMoto
        surchargePercentMoto
        feePercentMoto
        feesSurchargeCpoc {
          feeFixed
          feePercent
          surchargeEnabled
          surchargeFullFees
          surchargePercent
        }
        feesSurchargeVt {
          feeFixed
          feePercent
          surchargeEnabled
          surchargeFullFees
          surchargePercent
        }
        feesSurchargePbl {
          feeFixed
          feePercent
          surchargeEnabled
          surchargeFullFees
          surchargePercent
        }
      }
      moto {
        enabled
        defaultEntryMethod
        requiresPin
      }
      tipping {
        enabled
        tipPercent1
        tipPercent2
        tipPercent3
        customTipAllowed
      }
      schemes {
        name
      }
      schemesMoto {
        name
      }
      refundRequiresPin
      refundPin
      refundPinType
      receipt {
        merchantCopy
        printLogo
        printSocials
        name
        address1
        address2
        number
        phone
        printDeclinedTransaction
        email
        message
        returnsMessage
        website
        instagram
        facebook
        twitter
        logo
        qrCode
      }
      features {
        restrictReportAccessEnabled
        splitPaymentEnabled
      }
      vtEnabled
    }
  }
`

export const GetSiteWithUsers = gql`
  query GetSiteWithUsers($siteUuid: ID!) {
    getSite(siteUuid: $siteUuid) {
      id
      name
      address {
        street
        suburb
        state
        postcode
      }
      pin
      customers {
        id
        firstname
        middlename
        lastname
        role
        sites {
          name
          devices {
            name
          }
        }
      }
    }
  }
`

export const GetReceiptSettingsLogoUploadUrl = gql`
  query GetReceiptSettingsLogoUploadUrl($siteUuid: ID!, $fileName: String) {
    getReceiptSettingsLogoUploadUrl(siteUuid: $siteUuid, fileName: $fileName)
  }
`

export const GetSitesWithTypes = gql`
  query GetSitesWithTypes($limit: Int!) {
    getSites(limit: $limit) {
      sites {
        id
        name
        type
      }
      nextToken {
        type
        siteNameSortKey
      }
    }
  }
`
