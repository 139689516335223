import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { currencyFormatter } from '@npco/component-mp-common'
import { useTranslations } from '@npco/utils-translations'
import { Divider, Flex } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { ROOT } from 'const/routes'
import { formatAccountNumber } from 'utils/common'
import { useBPayTransferModal } from 'pages/Transfer/BPay/bpay-transfer-routing'
import { TransferFieldTypes } from 'pages/Transfer/Transfer.types'
import {
  BPAY_TRANSFER_MODAL_HEIGHT,
  formatTimeDate,
} from 'pages/Transfer/Transfer.utils'
import { TransferConfirmationModal } from 'pages/Transfer/TransferConfirmationModal/TransferConfirmationModal'
import { DefaultRow } from 'pages/Transfer/TransferModal/components/DefaultRow/DefaultRow'
import { SectionWrapper } from 'pages/Transfer/TransferModal/TransferModal.styled'

import { TransactionDetails } from '../../../bpay-transfer-routing/BPayTransfer.types'
import { formatDetailsWithSpace } from '../../utils/formatBPayDetails'
import { getBillerCode } from '../../utils/getBillerCode/getBillerCode'
import { completeStageTranslations } from './CompleteStage.i18n'

type CompleteStageProps = {
  transferDetails: TransactionDetails
  transactionTimestamp?: string
  transactionID: string
}

export const CompleteStage = ({
  transferDetails,
  transactionTimestamp,
  transactionID,
}: CompleteStageProps) => {
  const { from, to, amount, nickname, crn, paymentInstrumentUuid } =
    transferDetails
  const t = useTranslations(completeStageTranslations)
  const history = useHistory()
  const { resetForm } = useFormikContext<TransferFieldTypes>()
  const { closeBPayTransferModal } = useBPayTransferModal()
  const billerDetail = to?.billerContacts?.find(
    (item) => item.id === paymentInstrumentUuid?.id
  )

  const handleNewTransfer = useCallback(() => {
    resetForm()
    closeBPayTransferModal()
  }, [closeBPayTransferModal, resetForm])

  const handleClose = useCallback(() => {
    closeBPayTransferModal()
    history.push(ROOT.PORTAL.ACCOUNTS.TRANSACTIONS.path)
  }, [history, closeBPayTransferModal])

  const billerCodeValue = getBillerCode(transferDetails)
  return (
    <TransferConfirmationModal
      decisionModalProps={{
        isOpen: true,
        secondaryButtonLabel: t('newTransfer'),
        primaryButtonLabel: t('close'),
        onCancel: handleNewTransfer,
        onClickSecondary: handleNewTransfer,
        onClickPrimary: handleClose,
      }}
      paddingTop="120px"
      paddingBottom="120px"
      modalMinHeight={BPAY_TRANSFER_MODAL_HEIGHT}
      transactionID={transactionID}
      contactEmail={to?.email?.email}
      contactPhone={to?.phoneV2?.phone ?? to?.phone}
    >
      <Flex flexDirection="column" pt="16px">
        <SectionWrapper aria-label="sender details">
          <DefaultRow
            label={t('amount')}
            value={currencyFormatter(+amount * 100)}
          />
          <DefaultRow
            label={t('date')}
            value={formatTimeDate(transactionTimestamp)}
          />
          <DefaultRow
            label={t('from')}
            value={`${from.name} · ${formatAccountNumber(from.accountNumber)}`}
          />
        </SectionWrapper>
        <Divider margin="20px 0" />
        <SectionWrapper aria-label="recipient details">
          <DefaultRow label={t('to')} value={to?.name ?? '-'} />
          <DefaultRow
            label={t('billerNickname')}
            value={nickname ?? billerDetail?.nickname ?? '-'}
          />
          <DefaultRow
            label={t('billerCode')}
            value={formatDetailsWithSpace(billerCodeValue) ?? '-'}
          />
          <DefaultRow
            label={t('crn')}
            value={crn ?? billerDetail?.reference ?? '-'}
          />
        </SectionWrapper>
      </Flex>
    </TransferConfirmationModal>
  )
}
