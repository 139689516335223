import { gql } from '@apollo/client'

export const SkipReminder = gql`
  mutation SkipReminder($input: SkipReminderInput!) {
    skipReminder(input: $input) {
      __typename
      id
      activities {
        __typename
        balance
        cnpTxnRefNum
        completedTime
        contactName
        contactUuid
        dueDate
        failureReason
        id
        paidAmount
        status
        reminderIndex
        title
        type
      }
    }
  }
`
