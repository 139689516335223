import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import { HlPosPairConfig } from '@npco/mp-gql-types'
import { ErrorLogger } from '@npco/utils-error-logger'

import { UNEXPECTED_ERROR } from 'types/errors'
import {
  PairHlPos as PairHlPosResponse,
  PairHlPosVariables,
} from 'types/gql-types/PairHlPos'

export const PairHlPos = gql`
  mutation PairHlPos($config: [HlPosPairConfig!]!) {
    pairHlPos(config: $config) {
      venueId
      locationId
      siteUuid
      stationId
    }
  }
`

export const usePairHlPos = () => {
  const [privatePairHlPos] = useMutation<PairHlPosResponse, PairHlPosVariables>(
    PairHlPos,
    {
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            getHlPosPairConfiguration: () => {
              // "data" is always non-null/non-undefined if response successful.
              return data!.pairHlPos
            },
          },
        })
      },
    }
  )

  const pairHlPos = useCallback(
    async (input: HlPosPairConfig[]) => {
      try {
        const result = await privatePairHlPos({ variables: { config: input } })

        return result.data?.pairHlPos
      } catch (err) {
        ErrorLogger.report('[Payment] Error pairing HLPos ', err)
        return UNEXPECTED_ERROR
      }
    },
    [privatePairHlPos]
  )

  return {
    pairHlPos,
  }
}
