import { Dispatch, useEffect } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { DepositStatus } from '@npco/mp-gql-types'
import {
  Box,
  ButtonLink,
  Divider,
  Flex,
  Heading,
  HeadingStyles,
} from '@npco/zeller-design-system'
import {
  rvLastSelectedDates,
  rvSearchPhrase,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ROOT } from 'const/routes'
import { currencyFormatter } from 'utils/common'
import { formatWeekdayDate, formatWeekdayDateTime } from 'utils/date'
import dayjs from 'utils/dayjs'
import { getNonNullString } from 'utils/string'
import { translate } from 'utils/translations'
import { Deposit } from 'types/deposits'
import { TIME_FILTER_ENABLED_COMPONENTS } from 'types/picker'
import { useFetchDeposit } from 'pages/Deposits/hooks/useFetchDeposit'
import { DetailsField } from 'components/DetailsField/DetailsField'
import { useTransactionsLocalCache } from 'components/Filters/PillFilters/TransactionsFilters/useTransactionsLocalCache'
import { GenericErrorPage } from 'components/Placeholders/GenericErrorPage/GenericErrorPage'
import DepositStatusBadge from 'components/StatusBadge/DepositStatusBadge/DepositStatusBadge'
import { TooltipLabel } from 'components/TooltipLabel/TooltipLabel'

import * as styled from './DepositDetails.styled'
import { getSettlementCutOffTime } from './DepositDetails.utils'

interface Props {
  selectedDeposit: Deposit
  setSelectedDeposit: Dispatch<React.SetStateAction<Deposit | null>>
}

export const DepositDetails = ({
  selectedDeposit,
  setSelectedDeposit,
}: Props) => {
  const navigate = useNavigate()
  const { setSearch, setSelectedDates } = useTransactionsLocalCache()

  const { deposit, hasError, refetchDeposit } = useFetchDeposit(
    selectedDeposit.id
  )

  useEffect(() => {
    if (deposit) {
      setSelectedDeposit(deposit)
    }
  }, [deposit, setSelectedDeposit])

  if (hasError) {
    return (
      <GenericErrorPage
        retry={() => refetchDeposit?.()}
        data-testid="fetch-deposit-error"
      />
    )
  }

  return (
    <styled.DepositDetailsContainer>
      <styled.ContentWrapper>
        <DetailsField
          label={
            <TooltipLabel
              label={translate('component.deposit.dateSettled')}
              tooltipContent={
                <styled.TooltipContent>
                  {translate('component.deposit.dateSettledTooltip')}
                </styled.TooltipContent>
              }
            />
          }
          value={
            selectedDeposit?.status === DepositStatus.COMPLETED
              ? formatWeekdayDateTime(selectedDeposit?.timestamp)
              : '-'
          }
          dataTestId="deposit-date-settled"
        />
        {Boolean(selectedDeposit?.skippedTimestamp) && (
          <DetailsField
            label={
              <TooltipLabel
                label={translate('component.deposit.dateHeld')}
                tooltipContent={
                  <styled.TooltipContent>
                    {translate('component.deposit.dateHeldTooltip')}
                  </styled.TooltipContent>
                }
              />
            }
            value={formatWeekdayDate(selectedDeposit?.skippedTimestamp)}
            dataTestId="deposit-date-held"
          />
        )}
        <DetailsField
          label={translate('component.deposit.site')}
          value={selectedDeposit?.siteName}
          dataTestId="deposit-site"
        />
        <DetailsField
          label={translate('component.deposit.status')}
          value={
            <Box>
              {selectedDeposit?.status && (
                <DepositStatusBadge status={selectedDeposit?.status} />
              )}
            </Box>
          }
          dataTestId="deposit-status"
          pb={selectedDeposit?.status !== DepositStatus.FAILED ? '8px' : '0px'}
        />

        {selectedDeposit?.status &&
          selectedDeposit?.status !== DepositStatus.FAILED && (
            <DetailsField
              label={translate('component.deposit.reference')}
              value={getNonNullString(selectedDeposit?.shortId, '-')}
              dataTestId="deposit-reference"
              pb="0px"
            />
          )}
        {selectedDeposit?.shortId &&
          selectedDeposit?.status !== DepositStatus.FAILED && (
            <Flex justifyContent="end" py="8px">
              <ButtonLink
                onClick={() => {
                  navigate(ROOT.PORTAL.TRANSACTIONS.path, {
                    state: {
                      depositShortId: selectedDeposit?.shortId,
                      siteUuid: selectedDeposit?.siteUuid,
                    },
                  })
                  const depositDate = dayjs(selectedDeposit?.timestamp)
                  rvSearchPhrase(selectedDeposit?.shortId ?? '')
                  setSearch(selectedDeposit?.shortId ?? '')
                  rvLastSelectedDates({
                    ...rvLastSelectedDates(),
                    [TIME_FILTER_ENABLED_COMPONENTS.TRANSACTIONS]: {
                      from: depositDate.subtract(2, 'day').toDate(),
                      to: depositDate.toDate(),
                    },
                  })
                  setSelectedDates({
                    start: depositDate.subtract(14, 'day').toDate(),
                    end: depositDate.toDate(),
                  })
                }}
              >
                {translate('component.deposit.viewTransactions')}
              </ButtonLink>
            </Flex>
          )}

        <Divider margin="24px 0" />
        <DetailsField
          label={
            <TooltipLabel
              label={translate('component.deposit.settlementCutOff')}
              tooltipContent={
                <styled.TooltipContent>
                  {translate('component.deposit.settlementCutOffTooltip')}
                </styled.TooltipContent>
              }
            />
          }
          value={getSettlementCutOffTime(selectedDeposit?.tradingSlot)}
          dataTestId="deposit-settlement-cut-Off"
          pb="0px"
        />
        {selectedDeposit?.status === DepositStatus.COMPLETED && (
          <DetailsField
            label={translate('component.deposit.settlementAccount')}
            value={getNonNullString(selectedDeposit?.accountName)}
            dataTestId="deposit-settlement-account"
            pt="8px"
          />
        )}
        <Divider margin="24px 0" />
        <DetailsField
          label={translate('component.deposit.processed')}
          value={currencyFormatter(selectedDeposit.processedAmount)}
          dataTestId="deposit-processed"
        />
        <DetailsField
          label={translate('component.deposit.processingFee')}
          value={currencyFormatter(selectedDeposit.processedFeeAmount)}
          dataTestId="deposit-processing-fee"
        />
        <DetailsField
          label={translate('component.deposit.refunds')}
          value={currencyFormatter(selectedDeposit.refundsAmount)}
          dataTestId="deposit-refunds"
        />
        <DetailsField
          label={
            <TooltipLabel
              label={translate('component.deposit.pending')}
              tooltipContent={
                <styled.TooltipContent>
                  {translate('component.deposit.pendingTooltip')}
                </styled.TooltipContent>
              }
            />
          }
          value={currencyFormatter(selectedDeposit.withHeld)}
          dataTestId="deposit-pending"
          pb="0px"
        />
        {!!selectedDeposit?.rolledOverProcessedAmount && (
          <>
            <Divider margin="8px 0" />
            <DetailsField
              label={
                <TooltipLabel
                  label={translate('component.deposit.processedRollOver')}
                  tooltipContent={
                    <styled.TooltipContent>
                      {translate('component.deposit.processedRollOverTooltip')}
                    </styled.TooltipContent>
                  }
                />
              }
              value={currencyFormatter(
                selectedDeposit.rolledOverProcessedAmount
              )}
              dataTestId="deposit-rolled-over-processed-amount"
            />
            <DetailsField
              label={
                <TooltipLabel
                  label={translate('component.deposit.processingFeeRollOver')}
                  tooltipContent={
                    <styled.TooltipContent>
                      {translate(
                        'component.deposit.processingFeeRollOverTooltip'
                      )}
                    </styled.TooltipContent>
                  }
                />
              }
              value={currencyFormatter(selectedDeposit.rolledOverFeeAmount)}
              dataTestId="deposit-processing-fee-roll-over"
            />
          </>
        )}
        <Divider margin="8px 0" />
        <DetailsField
          label={translate('component.deposit.totalAmount')}
          value={
            <Heading.H4 withStyles={HeadingStyles.H5}>
              {currencyFormatter(selectedDeposit.amountSettled)}
            </Heading.H4>
          }
          dataTestId="deposit-total-amount"
          pb="0px"
        />
        <Divider margin="8px 0" />
      </styled.ContentWrapper>
    </styled.DepositDetailsContainer>
  )
}
