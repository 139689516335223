import { gql } from '@apollo/client'

export const ConfigureXeroPaymentServices = gql`
  mutation ConfigureXeroPaymentServices(
    $config: XeroPaymentServicesConfigInput!
  ) {
    configureXeroPaymentServices(config: $config) {
      connection {
        connectionError
        errorType
        id
        status
      }
      enabledThemes {
        xeroThemeId
      }
    }
  }
`
