import { useCallback, useState } from 'react'
import { ErrorCode, FileRejection } from 'react-dropzone'
import { ItemExportFormat } from '@npco/mp-gql-types'
import { renameAndDownloadFile } from '@npco/utils-file'
import { useTranslations } from '@npco/utils-translations'

import { useMultiStageModalProgressBar } from 'components/MultiStageModal'

import { useItemsImportModalStage } from '../../hooks/useItemsImportModalStage'
import { ItemsImport } from '../../ItemsImportModal.types'
import { translations } from '../ItemsImportModalUpload.i18n'

const MIN_COLUMNS_COUNT = 4

interface UseItemsImportModalUploadProps {
  items: ItemsImport | null
  setItems: (data: ItemsImport | null) => void
}

export const useItemsImportModalUpload = ({
  items,
  setItems,
}: UseItemsImportModalUploadProps) => {
  const [validationError, setValidationError] = useState('')
  const t = useTranslations(translations)
  const { goToItems, goToMatchStage } = useItemsImportModalStage()

  useMultiStageModalProgressBar({ newCurrentStep: 5 })

  const handleUploadAccepted = useCallback(
    (files: File[]) => {
      import('xlsx')
        .then(async ({ utils, read }) => {
          const workbook = read(Buffer.from(await files[0].arrayBuffer()))
          const sheetName = workbook.SheetNames[0]
          const sheet = workbook.Sheets[sheetName]

          const sheetData = utils.sheet_to_json<string[]>(sheet, {
            // NOTE: Assume that row 1 is always the header
            header: 1,
            defval: null,
            blankrows: false,
            // NOTE: Just use formatted string for the values
            // since all cells are being validated in match screen
            raw: false,
          })

          const [headers, ...rows] = sheetData
          const filteredHeaders = headers?.filter(Boolean)
          const columnCount = filteredHeaders?.length

          // Empty spreadsheet
          if (!columnCount) {
            setValidationError(t('errorNodata'))
            return
          }

          // Minimum column count
          if (columnCount < MIN_COLUMNS_COUNT) {
            setValidationError(t('errorMinimumColumns'))
            return
          }

          // Dupe header check
          const trimmedHeaders = filteredHeaders.map((header) => header.trim())
          const hasDuplicateHeader = trimmedHeaders.some(
            (header, index) => trimmedHeaders.indexOf(header) !== index
          )
          if (hasDuplicateHeader) {
            setValidationError(t('errorDuplicateHeaders'))
            return
          }

          // No row data but with header
          if (!rows.length) {
            setValidationError(t('errorNodata'))
            return
          }

          setValidationError('')

          setItems({
            file: { file: files[0], id: files[0].name },
            // NOTE: Use original column header positioning
            headers,
            rows,
          })
        })
        // eslint-disable-next-line no-console
        .catch(() => setValidationError(t('unknownError')))
    },
    [setItems, t]
  )

  const handleUploadRejected = useCallback(
    (files: FileRejection[]) => {
      const errors = files?.[0]?.errors
      if (errors?.some((err) => err.code === ErrorCode.FileInvalidType)) {
        setValidationError(t('errorInvalidFileType'))
        return
      }
      if (errors?.some((err) => err.code === ErrorCode.FileTooLarge)) {
        setValidationError(t('errorFileTooLarge'))
      }
    },
    [t]
  )

  const handleRemoveFile = useCallback(() => {
    setItems(null)
  }, [setItems])

  const handleNextStage = useCallback(() => {
    if (!items) {
      setValidationError(t('errorFileRequired'))
      return
    }
    goToMatchStage()
  }, [goToMatchStage, items, t])

  const handleTemplateDownload = useCallback(() => {
    const templateUrl = new URL(
      '../../../../../../assets/documents/items-template.csv',
      import.meta.url
    ).href

    renameAndDownloadFile({
      filename: 'items-template',
      link: templateUrl,
      format: ItemExportFormat.CSV,
    }).catch(() => undefined)
  }, [])

  return {
    file: items?.file,
    handleCancel: goToItems,
    handleNextStage,
    handleRemoveFile,
    handleTemplateDownload,
    handleUploadAccepted,
    handleUploadRejected,
    validationError,
  }
}
