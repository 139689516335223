import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import {
  ModalForm,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { Form, Formik } from 'formik'

import { ROOT } from 'const/routes'
import { translationsShared } from 'translations'

import { useItemsAnalytics } from '../../../../hooks/useItemsAnalytics'
import { useValidation } from '../../../CategoryCreateModal/hooks/useValidation'
import { CategoryModalFormFields } from '../../../CategoryModalFormFields/CategoryModalFormFields'
import { useUpdateItemCategoryMutation } from '../graphql/updateItemCategory.generated'
import { translations } from './CategoryEditModal.i18n'
import { CategoryFormFields } from './CategoryEditModal.types'

interface CategoryEditModalProps {
  categoryId: string
  initialValues: CategoryFormFields
}

export const CategoryEditModal = ({
  categoryId,
  initialValues,
}: CategoryEditModalProps) => {
  const { trackEditCategory } = useItemsAnalytics()
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const validationSchema = useValidation()

  const navigate = useNavigate()

  const [updateItemCategory, { loading: isUpdatingItemCategory }] =
    useUpdateItemCategoryMutation()

  const handleClose = useCallback(
    () =>
      navigate(
        ROOT.PORTAL.ITEM_MANAGEMENT.CATEGORIES.CATEGORY(categoryId).path
      ),
    [categoryId, navigate]
  )

  const handleOnSubmit = useCallback(
    (formValues: CategoryFormFields) => {
      async function sendRequest() {
        const result = await updateItemCategory({
          variables: {
            input: {
              id: categoryId,
              ...formValues,
              name: formValues.name.trim(),
            },
          },
        })

        if (result.data?.updateItemCategory) {
          trackEditCategory(initialValues, formValues)
          showSuccessToast(t('successNotification'))
          handleClose()
        } else {
          showErrorToast(t('errorNotification'))
        }
      }

      sendRequest().catch(() => showErrorToast(t('errorNotification')))
    },
    [
      categoryId,
      handleClose,
      initialValues,
      t,
      trackEditCategory,
      updateItemCategory,
    ]
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit: handleFormikSubmit,
        submitForm: handleFormikSubmitForm,
      }) => (
        <Form data-testid="category-edit-form" onSubmit={handleFormikSubmit}>
          <ModalForm
            isOpen
            isLoading={isUpdatingItemCategory}
            onCancel={handleClose}
            onClickPrimary={() => {
              handleFormikSubmitForm().catch(() => undefined)
            }}
            primaryButtonLabel={tShared('save')}
            secondaryButtonLabel={tShared('cancel')}
            testId="item-edit-modal"
            title={t('heading')}
          >
            <CategoryModalFormFields showDescriptionField={false} />
          </ModalForm>
        </Form>
      )}
    </Formik>
  )
}
