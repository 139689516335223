import { useLocation } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'

import { TableTabStateEnum } from '../../ItemManagement.types'
import { CategoryCreateModal } from '../CategoryCreateModal/CategoryCreateModal'
import { ItemCreateModal } from '../ItemCreateModal/ItemCreateModal'

const {
  ITEMS: {
    NEW: { path: newItemPath },
  },
  CATEGORIES: {
    NEW: { path: newCategoryPath },
  },
} = ROOT.PORTAL.ITEM_MANAGEMENT

const isModalLauncherState = (
  state?: unknown
): state is { modalToLaunch: TableTabStateEnum } =>
  typeof state === 'object' &&
  state !== null &&
  'modalToLaunch' in state &&
  typeof state.modalToLaunch === 'string' &&
  (state.modalToLaunch === TableTabStateEnum.CATEGORIES ||
    state.modalToLaunch === TableTabStateEnum.ITEMS)

export const CreateModalsLauncher = () => {
  const { pathname, state } = useLocation()

  const modalToLaunch = isModalLauncherState(state) && state.modalToLaunch

  if (!modalToLaunch) {
    if (pathname === newItemPath) {
      return <ItemCreateModal />
    }

    if (pathname === newCategoryPath) {
      return <CategoryCreateModal />
    }
  }

  if (modalToLaunch === TableTabStateEnum.ITEMS) {
    return <ItemCreateModal />
  }

  if (modalToLaunch === TableTabStateEnum.CATEGORIES) {
    return <CategoryCreateModal />
  }

  return null
}
