import { useInfiniteLoader } from 'hooks/useInfiniteLoader/useInfiniteLoader'

import { DEFAULT_GET_LIMIT } from '../../../ItemManagement.utils'
import { useItemManagementContext } from '../../ItemManagementContext/ItemManagementContext'

export const useCategoriesTableInfiniteLoader = () => {
  const {
    categories: { filterInput, isLoading, nextToken, loadMore, sortInput },
  } = useItemManagementContext()

  return useInfiniteLoader({
    isLoading,
    onLoadMore: async () => {
      await loadMore?.({
        variables: {
          limit: DEFAULT_GET_LIMIT,
          filter: filterInput,
          nextToken,
          sort: sortInput,
        },
      })
    },
    shouldLoadMore: !!nextToken,
  })
}
