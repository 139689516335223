import { useNavigate } from 'react-router-dom-v5-compat'
import { InvoiceStatus } from '@npco/mp-gql-types'
import {
  Box,
  BREAKPOINT,
  COLOR,
  SkeletonTypographyBase,
  TOOLTIP_ZINDEX,
  TooltipBasic,
  TypographyTypes,
} from '@npco/zeller-design-system'
import { getCurrencyAmountFormatted } from 'features/Invoicing/components/Invoices/Invoice/components/InvoiceFormAccordions/InvoiceFormAccordions.utils'
import { StatusLabel } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.utils'
import { v4 as randomUUID } from 'uuid'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { ROOT } from 'const/routes'
import dayjs from 'utils/dayjs'
import type { GetInvoices_getInvoices_invoices as Invoices } from 'types/gql-types/GetInvoices'
import { GroupedList } from 'components/Lists/GroupedList/GroupedList'
import { StyledListWrapper } from 'components/Lists/ListWrapper/ListWrapper.styled'

import * as styled from './ContactInvoicesTable.styled'

const DueDate = ({
  dueDate,
  status,
}: {
  dueDate: Date
  status: InvoiceStatus
}) => {
  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: 'a few seconds',
      m: '1 minute',
      mm: '%d minutes',
      h: '1 hour',
      hh: '%d hours',
      d: '1 day',
      dd: '%d days',
      M: '1 month',
      MM: '%d months',
      y: '1 year',
      yy: '%d years',
    },
  })

  const isMobile = useIsMobileResolution(BREAKPOINT.SM)
  const isOverdue = status === InvoiceStatus.OVERDUE

  const date = dayjs(dueDate)

  let dateTime = ''
  let dueDateText = 'No Date'

  if (date.isValid()) {
    dateTime = date.format('YYYY-MM-DD')
    dueDateText = isOverdue && !isMobile ? date.fromNow() : date.format('D MMM')
  }

  return isOverdue && !isMobile ? (
    <TooltipBasic
      placement="top"
      renderTrigger={({ handlers }) => (
        <time {...handlers} dateTime={dateTime}>
          {dueDateText}
        </time>
      )}
      showArrow={false}
      zIndex={Number.parseInt(TOOLTIP_ZINDEX, 10)}
    >
      <Box textAlign="center">{date.format('D MMMM')}</Box>
    </TooltipBasic>
  ) : (
    <time dateTime={dateTime}>{dueDateText}</time>
  )
}

export const TableRowDeps = {
  useNavigate,
}

const TableRow = ({ item }: { item: Invoices }) => {
  const { useNavigate } = TableRowDeps

  const navigate = useNavigate()

  return (
    <styled.Row
      $status={item.status}
      onClick={() =>
        navigate(
          ROOT.PORTAL.INVOICING.INVOICES.INVOICE(item.referenceNumber).relative
        )
      }
    >
      <td className="date" data-testid="date">
        <DueDate dueDate={item.dueDate} status={item.status} />
      </td>
      <td className="status">{StatusLabel[item.status]}</td>
      <td className="title">{item.title}</td>
      <td className="reference-number">{item.referenceNumber}</td>
      <td className="total-amount" data-testid="total-amount">
        {item.totalAmount ? getCurrencyAmountFormatted(item.totalAmount) : ''}
      </td>
    </styled.Row>
  )
}

const TableGroup = ({
  group,
  heading,
}: {
  group: Invoices[]
  heading: string
}) => {
  return (
    <>
      <styled.StickyHeading>
        <span id={`heading-${heading}`}>{heading}</span>
      </styled.StickyHeading>

      <styled.Table aria-labelledby={`heading-${heading}`}>
        <thead>
          <tr>
            <th className="date">Date</th>
            <th className="status">Status</th>
            <th className="title">Title</th>
            <th className="reference-number">Reference number</th>
            <th className="total-amount">Total amount</th>
          </tr>
        </thead>
        <tbody>
          {group.map((item) => (
            <TableRow key={item.id} item={item} />
          ))}
        </tbody>
      </styled.Table>
    </>
  )
}

const SkeletonLoader = () => {
  const rows = Array.from({ length: 10 }).map(() => randomUUID())
  const columns = Array.from({ length: 5 }).map(() => randomUUID())

  return (
    <>
      <Box borderBottom={`1px solid ${COLOR.GREY_90}`} padding="16px 0">
        <SkeletonTypographyBase type={TypographyTypes.H4} width="90px" />
      </Box>
      <styled.Table>
        <tbody>
          {rows.map((key) => {
            return (
              <styled.SkeletonRow key={key}>
                {columns.map((k) => {
                  return (
                    <td key={k}>
                      <SkeletonTypographyBase
                        margin={0}
                        type={TypographyTypes.BodySmall}
                        width="100%"
                      />
                    </td>
                  )
                })}
              </styled.SkeletonRow>
            )
          })}
        </tbody>
      </styled.Table>
    </>
  )
}

export const ContactInvoicesTable = ({
  hasMore,
  invoicesByYear,
  isLoading,
  onScrollEnd,
}: {
  hasMore: boolean
  invoicesByYear: Invoices[][]
  isLoading: boolean
  onScrollEnd: () => void
}) => {
  return isLoading ? (
    <SkeletonLoader />
  ) : (
    <StyledListWrapper>
      <GroupedList
        data={invoicesByYear}
        hasMore={hasMore}
        onScrollEnd={onScrollEnd}
        renderGroup={(group) => {
          const date = dayjs(group[0].dueDate)
          const dateText = date.isValid() ? date.format('YYYY') : 'No Date'

          return (
            <TableGroup group={group} heading={dateText} key={group[0].id} />
          )
        }}
        renderItem={(item) => <TableRow key={item.id} item={item} />}
      />
    </StyledListWrapper>
  )
}
