import { Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import { ContactType } from '@npco/mp-gql-types'

import { ROOT } from 'const/routes'
import { NotFound } from 'pages/NotFound'

import { AccountFilterValuesWrapper } from '../../../layouts/AccountLayout/AccountFilterValuesWrapper/AccountFilterValuesWrapper'
import { TransactionUpdates } from '../../../services/transactionUpdates/TransactionUpdates'
import { Businesses } from '../Businesses/Businesses'
import { ContactAccounts } from '../Contact/ContactAccounts/ContactAccounts'
import { ContactGeneral } from '../Contact/ContactGeneral/ContactGeneral'
import { ContactInvoiceDrawer } from '../Contact/ContactInvoices/ContactInvoiceDrawer/ContactInvoiceDrawer'
import { ContactInvoices } from '../Contact/ContactInvoices/ContactInvoices'
import { ContactPayments } from '../Contact/ContactPayments/ContactPayments'
import { ContactProvider } from '../ContactContext/ContactContext'
import { ContactLayout } from '../ContactLayout/ContactLayout'
import { ContactsProvider } from '../ContactsContext/ContactsContext'
import { ContactsLayout } from '../ContactsLayout/ContactsLayout'
import { People } from '../People/People'

const { CONTACTS } = ROOT.PORTAL

export const ContactsRoutes = () => (
  <Routes>
    <Route
      element={
        <ContactsProvider>
          <ContactsLayout />
        </ContactsProvider>
      }
    >
      <Route
        index
        element={<Navigate to={CONTACTS.BUSINESSES.path} replace />}
      />

      <Route path={CONTACTS.BUSINESSES.relative} element={<Businesses />} />

      <Route path={CONTACTS.PEOPLE.relative} element={<People />} />
    </Route>

    <Route path={CONTACTS.BUSINESSES.relative}>
      <Route
        path={`${CONTACTS.BUSINESSES.CONTACT().relative}/*`}
        element={
          // NOTE: we must wrap with account filter values wrapper as needed in
          // filter header row
          <ContactProvider>
            <AccountFilterValuesWrapper>
              <ContactLayout />
            </AccountFilterValuesWrapper>
          </ContactProvider>
        }
      >
        <Route
          index
          element={
            <Navigate
              to={CONTACTS.BUSINESSES.CONTACT().GENERAL.relative}
              replace
            />
          }
        />
        <Route
          path={CONTACTS.BUSINESSES.CONTACT().GENERAL.relative}
          element={<ContactGeneral />}
        />

        <Route
          path={CONTACTS.BUSINESSES.CONTACT().ACCOUNTS.relative}
          element={<ContactAccounts />}
        />

        <Route
          path={CONTACTS.BUSINESSES.CONTACT().PAYMENTS.relative}
          element={
            <TransactionUpdates>
              <ContactPayments />
            </TransactionUpdates>
          }
        />

        <Route
          path={`${CONTACTS.BUSINESSES.CONTACT().INVOICES.relative}`}
          element={<ContactInvoices />}
        >
          <Route
            path={CONTACTS.BUSINESSES.CONTACT().INVOICES.INVOICE().relative}
            element={
              <ContactInvoiceDrawer contactType={ContactType.BUSINESS} />
            }
          />
        </Route>
      </Route>
    </Route>

    <Route path={`${CONTACTS.PEOPLE.relative}`}>
      <Route
        path={`${CONTACTS.PEOPLE.CONTACT().relative}/*`}
        element={
          // NOTE: we must wrap with account filter values wrapper as needed in
          // filter header row
          <ContactProvider>
            <AccountFilterValuesWrapper>
              <ContactLayout />
            </AccountFilterValuesWrapper>
          </ContactProvider>
        }
      >
        <Route
          index
          element={
            <Navigate to={CONTACTS.PEOPLE.CONTACT().GENERAL.relative} replace />
          }
        />
        <Route
          path={CONTACTS.PEOPLE.CONTACT().GENERAL.relative}
          element={<ContactGeneral />}
        />

        <Route
          path={CONTACTS.PEOPLE.CONTACT().ACCOUNTS.relative}
          element={<ContactAccounts />}
        />

        <Route
          path={CONTACTS.PEOPLE.CONTACT().PAYMENTS.relative}
          element={
            <TransactionUpdates>
              <ContactPayments />
            </TransactionUpdates>
          }
        />

        <Route
          path={CONTACTS.PEOPLE.CONTACT().INVOICES.relative}
          element={<ContactInvoices />}
        >
          <Route
            path={CONTACTS.PEOPLE.CONTACT().INVOICES.INVOICE().relative}
            element={<ContactInvoiceDrawer contactType={ContactType.PERSON} />}
          />
        </Route>
      </Route>
    </Route>

    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default ContactsRoutes
