import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { PaymentInstrumentType } from '@npco/mp-gql-types'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetPaymentInstruments } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/paymentInstruments'

import {
  GetPaymentInstruments as GetPaymentInstrumentsResponse,
  GetPaymentInstruments_getPaymentInstruments as GetPaymentInstrument,
  GetPaymentInstruments_getPaymentInstruments_details_BankAccountDetails as BankAccountDetails,
  GetPaymentInstrumentsVariables,
} from 'types/gql-types/GetPaymentInstruments'

export interface BsbPaymentInstrument
  extends Omit<GetPaymentInstrument, 'details'> {
  details: BankAccountDetails
}

export const useBsbPaymentInstruments = () => {
  const { data, loading } = useQuery<
    GetPaymentInstrumentsResponse,
    GetPaymentInstrumentsVariables
  >(GetPaymentInstruments, {
    errorPolicy: 'all',
    onError: (error) => {
      showApiErrorToast(error)
    },
    variables: {
      filter: {
        type: { eq: PaymentInstrumentType.BSB },
      },
    },
  })

  const paymentInstruments = useMemo(
    () =>
      (data?.getPaymentInstruments || []).filter(
        (item): item is BsbPaymentInstrument => {
          if (!item?.id) {
            return false
          }

          const details = item?.details as BankAccountDetails
          return Boolean(details?.name)
        }
      ),
    [data?.getPaymentInstruments]
  )

  return {
    paymentInstruments,
    loading,
  }
}
