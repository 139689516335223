import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  breadcrumb: 'Transfer',
  recentTransactionsLinkMobile: 'Repeat past transaction',
})

export const contactTransferTranslations = createTranslations({
  accountNameLabel: 'Account Name',
  accountNumberLabel: 'Account Number',
  accountBsbLabel: 'BSB',
})

export const externalTransferTranslations = createTranslations({
  accountNameLabel: 'Account Name',
  nicknameLabel: 'Nickname (Optional)',
  accountBsbLabel: 'BSB',
  accountNumberLabel: 'Account Number',
  recipientDescriptionPlaceholder: 'Up to 18 characters',
})

export const zellerTransferTranslations = createTranslations({
  amountLabel: 'Amount',
  referencePlaceholder: 'Up to {maxLength} characters',
})

export const bPayTransferTranslations = createTranslations({
  transferFailed: 'Your transfer was unsuccessful. Please try again.',
})
