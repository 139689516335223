import {
  CreateItemInput,
  ItemTaxInput,
  UpdateItemInput,
} from '@npco/mp-gql-types'
import currency from 'currency.js'
import { yupToFormErrors } from 'formik'

import {
  ITEM_CATEGORIES_FIELD,
  ITEM_DEFAULT_PRICE,
  ITEM_DESCRIPTION_FIELD,
  ITEM_IS_TAX_INCLUSIVE,
  ITEM_NAME_FIELD,
  ITEM_PRICE_FIELD,
  ITEM_SKU_FIELD,
  ITEM_TAX_APPLICABLE_FIELD,
  ITEM_TAX_EXCLUSIVE_RATE,
  ITEM_TAX_INCLUSIVE_RATE,
} from './ItemBaseModalForm.constants'
import { itemSchema } from './ItemBaseModalForm.schemas'
import {
  ItemBasicFields,
  ItemFormFields,
  ItemTaxApplicableItems,
} from './ItemBaseModalForm.types'

const TAX_INCLUSIVE_RATE = 1.1
const TAX_EXCLUSIVE_RATE = 1

const getTaxRate = (isTaxApplicable: boolean, isTaxInclusive: boolean) => {
  return isTaxApplicable && isTaxInclusive
    ? TAX_INCLUSIVE_RATE
    : TAX_EXCLUSIVE_RATE
}

export const getPriceWithTax = ({
  isTaxInclusive,
  price,
  taxes,
}: {
  isTaxInclusive: boolean | undefined
  price: number | undefined
  taxes: ItemBasicFields['taxes'] | undefined
}) => {
  const isTaxApplicable = taxes?.some((tax) => tax.enabled) || false
  const taxRate = getTaxRate(isTaxApplicable, isTaxInclusive || false)

  // NOTE: item total has centi cents precision so wrap in new currency object
  // to get nearest cent precision (formatted/displayed total)
  return currency(
    currency(price || 0, { fromCents: true, precision: 4 }).multiply(taxRate)
  )
}

const initialDescriptionFieldValue = {
  [ITEM_DESCRIPTION_FIELD]: '',
}

export const initialItemDrawerEditFieldValues = {
  [ITEM_CATEGORIES_FIELD]: [],
  [ITEM_IS_TAX_INCLUSIVE]: false,
  [ITEM_NAME_FIELD]: '',
  [ITEM_PRICE_FIELD]: ITEM_DEFAULT_PRICE,
  [ITEM_TAX_APPLICABLE_FIELD]: ItemTaxApplicableItems.TaxApplicable,
  [ITEM_SKU_FIELD]: '',
}

export const initialValues: ItemFormFields = {
  ...initialItemDrawerEditFieldValues,
  ...initialDescriptionFieldValue,
}

export const validateItemForm = (values: Partial<ItemFormFields>) => {
  return itemSchema
    .validate(values, { abortEarly: false })
    .then(() => ({}))
    .catch(yupToFormErrors)
}

export const convertItemFormFieldsToItemTaxInput = (
  formValues: Partial<ItemFormFields>
): ItemTaxInput => {
  const { taxApplicable, isTaxInclusive } = formValues

  const isTaxApplicable = taxApplicable === ItemTaxApplicableItems.TaxApplicable
  const taxRate = isTaxInclusive
    ? ITEM_TAX_INCLUSIVE_RATE
    : ITEM_TAX_EXCLUSIVE_RATE

  if (!isTaxApplicable) {
    return {
      enabled: false,
      name: 'GST',
      percent: null,
    }
  }

  return {
    enabled: true,
    name: 'GST',
    percent: taxRate,
  }
}

export const convertItemFormFieldsToItemCategoriesInput = (
  formValues: Partial<ItemFormFields>
) => {
  return formValues.categories
    ? formValues.categories.map((category) => category.value)
    : null
}

export const convertItemFormFieldsToItemPriceInput = (
  formValues: Partial<ItemFormFields>
) => {
  if (!formValues.price) return 0

  return formValues.isTaxInclusive &&
    formValues.taxApplicable === ItemTaxApplicableItems.TaxApplicable
    ? currency(formValues.price, {
        errorOnInvalid: true,
        precision: 4,
      }).divide(1.1).intValue
    : currency(formValues.price, { errorOnInvalid: true, precision: 4 })
        .intValue
}

export const convertItemFormFieldsToUpdateItemInput = (
  formValues: Partial<ItemFormFields>
): Omit<UpdateItemInput, 'id'> => {
  const categories = convertItemFormFieldsToItemCategoriesInput(formValues)
  const unitPrice = convertItemFormFieldsToItemPriceInput(formValues)
  const taxes = convertItemFormFieldsToItemTaxInput(formValues)

  return {
    name: formValues.name || '',
    price: unitPrice,
    sku: formValues.sku || null,
    taxes: [taxes],
    categories,
  }
}

export const convertItemFormFieldsToCreateItemInput = (
  formValues: Partial<ItemFormFields>
): CreateItemInput => {
  const categories = convertItemFormFieldsToItemCategoriesInput(formValues)
  const unitPrice = convertItemFormFieldsToItemPriceInput(formValues)
  const taxes = convertItemFormFieldsToItemTaxInput(formValues)

  return {
    categories,
    description: formValues.description || null,
    image: null,
    name: formValues.name || '',
    price: unitPrice,
    productId: '',
    sites: null,
    sku: formValues.sku || null,
    taxes: [taxes],
  }
}

export const getInitialItemEditFormValues = (
  item: ItemBasicFields | undefined,
  isTaxInclusive: boolean
): Omit<ItemFormFields, 'description'> => {
  const isTaxApplicable = item?.taxes?.some((tax) => tax.enabled) || false
  const taxApplicable = isTaxApplicable
    ? ItemTaxApplicableItems.TaxApplicable
    : ItemTaxApplicableItems.NoTax

  const price = getPriceWithTax({
    price: item?.price,
    isTaxInclusive,
    taxes: item?.taxes,
  }).toString()

  return {
    ...initialItemDrawerEditFieldValues,
    name: item?.name || initialItemDrawerEditFieldValues.name,
    sku: item?.sku || initialItemDrawerEditFieldValues.sku,
    categories:
      item?.categories?.map(({ name, id }) => ({
        label: name,
        value: id,
      })) || [],
    taxApplicable,
    isTaxInclusive,
    price,
  }
}
