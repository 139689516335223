import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'
import { PortalKYCLayout } from 'pages/PortalKYC/components/PortalKYCLayout'

import { BiometricsOnfidoPageTemplate } from './BiometricsOnfidoPageTemplate'

export const BiometricsOnfidoPageDeps = {
  BiometricsOnfidoPageTemplate,
}

export const BiometricsOnfidoPage = () => {
  const { BiometricsOnfidoPageTemplate } = BiometricsOnfidoPageDeps

  const navigate = useNavigate()
  const ctaSaveAndClose = useMemo(
    () => ({
      onClick: () => navigate(ROOT.PORTAL.SETTINGS.PROFILE.SECURITY.path),
    }),
    [navigate]
  )

  return (
    <PortalKYCLayout ctaSaveAndClose={ctaSaveAndClose}>
      <BiometricsOnfidoPageTemplate
        onSubmitRoute={ROOT.PORTAL.SETTINGS.PROFILE.SECURITY.path}
        onNoSelectedDocumentRoute={ROOT.PORTAL.BIOMETRICS.path}
        onChooseDifferentIDRoute={ROOT.PORTAL.BIOMETRICS.path}
      />
    </PortalKYCLayout>
  )
}
