import { gql, TypedDocumentNode } from '@apollo/client'

import * as CreateCustomerGQL from 'types/gql-types/CreateCustomer'
import * as UpdateCustomerGQL from 'types/gql-types/UpdateCustomer'

export const CreateCustomer = gql`
  mutation CreateCustomer($input: [CreateCustomerInput!]!) {
    createCustomer(input: $input) {
      id
      entityUuid
      role
      type
      permissions {
        allowDiscountManagement
        allowItemManagement
        allowZellerInvoices
      }
    }
  }
` as TypedDocumentNode<
  CreateCustomerGQL.CreateCustomer,
  CreateCustomerGQL.CreateCustomerVariables
>

export const UpdateCustomer = gql`
  mutation UpdateCustomer($customer: UpdateCustomerInput!) {
    updateCustomer(customer: $customer)
  }
` as TypedDocumentNode<
  UpdateCustomerGQL.UpdateCustomer,
  UpdateCustomerGQL.UpdateCustomerVariables
>

export const DeleteCustomer = gql`
  mutation DeleteCustomer($customerUuid: ID!) {
    deleteCustomer(customerUuid: $customerUuid)
  }
`

export const AssignCustomerToSite = gql`
  mutation AssignCustomerToSite($customerUuid: ID!, $siteUuid: ID!) {
    assignCustomerToSite(customerUuid: $customerUuid, siteUuid: $siteUuid)
  }
`

export const RemoveCustomerFromSite = gql`
  mutation RemoveCustomerFromSite($customerUuid: ID!, $siteUuid: ID!) {
    removeCustomerFromSite(customerUuid: $customerUuid, siteUuid: $siteUuid)
  }
`

export const SendInviteEmail = gql`
  mutation SendInviteEmail($customerUuid: ID!) {
    sendInviteEmail(customerUuid: $customerUuid)
  }
`
export const FinaliseCustomerKYC = gql`
  mutation FinaliseCustomerKYC {
    finaliseCustomerKYC {
      result
      uploadDocument
    }
  }
`
