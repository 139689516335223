import { lazy, Suspense } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Tutorial } from '@npco/zeller-design-system'
import isMobile from 'ismobilejs'

import SetupFlowPaymentsLarge from 'assets/images/setup-flow/setup-flow-payments-large.png'
import SetupFlowPaymentsMedium from 'assets/images/setup-flow/setup-flow-payments-medium.png'
import SetupFlowPaymentsSmall from 'assets/images/setup-flow/setup-flow-payments-small.png'
import { translationsShared } from 'translations'

import { Illustration } from '../../../components/Illustration/Illustration'
import { useSetupFlowModal } from '../../hooks/useSetupFlowModal'
import { MotionFooter, MotionMainContent } from '../../SetupFlowModal.styled'
import { getIsAndriod } from '../../SetupFlowModal.utils'
import { setupFlowPaymentsStageTranslations } from './FeaturePaymentsStage.i18n'
import { MainContent } from './MainContent/MainContent'

const IllustrationAnimation = lazy(
  () =>
    import('../../../components/IllustrationAnimation/IllustrationAnimation')
)

export const FeaturePaymentsStageContent = () => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(setupFlowPaymentsStageTranslations)
  const { goToStage } = useSetupFlowModal()
  const preStage =
    getIsAndriod() || isMobile(window.navigator).apple.tablet
      ? 'DownloadAppStage'
      : 'TapToPayStage'

  return (
    <Tutorial.TutorialLayout
      badgeName={t('badge')}
      Footer={
        <MotionFooter>
          <Tutorial.TutorialFooter
            onPrimaryButtonClick={() => goToStage('FeatureAccountsStage')}
            onSecondaryButtonClick={() => goToStage(preStage)}
            primaryButtonLabel={tShared('next')}
          />
        </MotionFooter>
      }
      Illustration={
        <Suspense
          fallback={
            <Illustration
              imageSmall={SetupFlowPaymentsSmall}
              imageMedium={SetupFlowPaymentsMedium}
              imageLarge={SetupFlowPaymentsLarge}
            />
          }
        >
          <IllustrationAnimation
            fallbackImageSmall={SetupFlowPaymentsSmall}
            fallbackImageMedium={SetupFlowPaymentsMedium}
            fallbackImageLarge={SetupFlowPaymentsLarge}
            animationDataFilenameSmall={t('animationDataFilenameSmall')}
            animationDataFilenameMedium={t('animationDataFilenameMedium')}
            animationDataFilenameLarge={t('animationDataFilenameLarge')}
          />
        </Suspense>
      }
      MainContent={
        <MotionMainContent>
          <MainContent />
        </MotionMainContent>
      }
    />
  )
}
