import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { DebitCardStatus } from '@npco/mp-gql-types'
import { ErrorLogger } from '@npco/utils-error-logger'
import { LockDebitCard } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/card'

import { cacheDebitCardStatus } from 'utils/banking/cacheDebitCardStatus/cacheDebitCardStatus'
import { UNEXPECTED_ERROR } from 'types/errors'
import {
  LockDebitCard as LockDebitCardResponse,
  LockDebitCardVariables,
} from 'types/gql-types/LockDebitCard'

export const useLockCard = (cardId: string | undefined) => {
  const [lockCardMutation, { loading }] = useMutation<
    LockDebitCardResponse,
    LockDebitCardVariables
  >(LockDebitCard, {
    update: (cache, result) => {
      if (result.data?.lockDebitCard && cardId) {
        cacheDebitCardStatus({
          cache,
          cardId,
          status: DebitCardStatus.LOCKED,
        })
      }
    },
  })

  const lockCard = useCallback(async () => {
    try {
      const lockCardResult = await lockCardMutation({
        variables: {
          cardId: { cardUuid: cardId },
        },
      })

      if (!lockCardResult.data?.lockDebitCard) {
        throw new Error('Failed to lock card with false flag')
      }

      return lockCardResult.data.lockDebitCard
    } catch (err) {
      ErrorLogger.report('[Banking] Error locking card', err)
      return UNEXPECTED_ERROR
    }
  }, [lockCardMutation, cardId])

  return { lockCard, isLockingCard: loading }
}
