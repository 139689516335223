import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CorporateCardDetailsDebitCardV2FragmentDoc } from './CorporateCardDetails/CorporateCardDetails.generated'
import { DebitCardDetailsDebitCardV2FragmentDoc } from './DebitCardDetails/DebitCardDetails.generated'

export type ZellerCardDetailsDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  productType: Types.DebitCardProductType | null
  status: Types.DebitCardStatus
  debitCardAccount: {
    __typename: 'DebitCardAccountV2'
    id: string
    name: string
    balance: { __typename?: 'Money'; value: string } | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
    } | null
  }
  customer: {
    __typename: 'Customer'
    id: string
    firstname: string | null
    lastname: string | null
    icon: {
      __typename?: 'Icon'
      image: string | null
      images: Array<{
        __typename?: 'Image'
        url: string
        size: Types.ImageSize
      }> | null
    } | null
  } | null
  velocityControl: {
    __typename?: 'VelocityControlType'
    isPendingSubscriptionUpdate: boolean | null
    resetsAt: any
    velocityWindow: Types.VelocityWindowEnum
    amountLimit: { __typename?: 'Money'; value: string }
    availableAmount: { __typename?: 'Money'; value: string }
    totalSpentAmount: { __typename?: 'Money'; value: string }
  } | null
}

export const ZellerCardDetailsDebitCardV2FragmentDoc = gql`
  fragment ZellerCardDetailsDebitCardV2Fragment on DebitCardV2 {
    productType
    ...DebitCardDetailsDebitCardV2Fragment
    ...CorporateCardDetailsDebitCardV2Fragment
  }
  ${DebitCardDetailsDebitCardV2FragmentDoc}
  ${CorporateCardDetailsDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  ZellerCardDetailsDebitCardV2Fragment,
  undefined
>
