import { useCallback, useMemo, useState } from 'react'
import { debounce } from 'lodash-es'

import { useGetBillersByCodeLazyQuery } from './useBPayTargetItems/GetBillersByCode.generated'
import { useGetContactsByBillerCodeLazyQuery } from './useBPayTargetItems/GetContactsByBillerCode.generated'
import { useGetContactsByNameLazyQuery } from './useBPayTargetItems/GetContactsByName.generated'
import { getItemsByQuery } from './useBPayTargetItems/getItemsByQuery'

export type ItemContactOrBiller = ItemContact | ItemBiller

export type ItemContact = {
  type: 'Contact'
  id: string
  name: string
  icon: {
    colour: string | null
    image: string | null
    letter: string | null
    images: Array<{
      size: string
      url: string
    }> | null
  } | null
  email?: {
    email: string | null
  }
  phone?: string | null
  phoneV2?: {
    phone: string | null
  }
  isSelf: boolean | null
  billerContacts?: {
    id: string
    name: string
    nickname: string
    code: string
    reference?: string
  }[]
}

export type ItemBiller = {
  type: 'Biller'
  name: string
  nickname: string
  code: string
  icon: {
    colour: string | null
    image: string | null
    letter: string | null
    images: Array<{
      size: string
      url: string
    }> | null
  } | null
  email?: {
    email: string | null
  }
  phone?: string | null
  phoneV2?: {
    phone: string | null
  }
}

export type SearchResults =
  | { type: 'Initialising' }
  | { type: 'Pending' }
  | { type: 'Ok'; value: { items: ItemContactOrBiller[] } }
  | { type: 'Error' }

export type Actions = { getItemsByQuery: (query: string) => Promise<void> }

export type ReturnTuple = [state: SearchResults, actions: Actions]

export const useBPayTargetItems = (): ReturnTuple => {
  const [state, setState] = useState<SearchResults>({ type: 'Initialising' })

  const [getBillersByCode] = useGetBillersByCodeLazyQuery()
  const [getContactsByBillerCode] = useGetContactsByBillerCodeLazyQuery()
  const [getContactsByName] = useGetContactsByNameLazyQuery()

  const getItemsByQueryAction: Actions['getItemsByQuery'] = useCallback(
    async (query) => {
      setState({ type: 'Pending' })
      await getItemsByQuery({
        query,
        getBillersByCode,
        getContactsByBillerCode,
        getContactsByName,
      })
        .then((result) => setState(result))
        .catch(() => setState({ type: 'Error' }))
    },
    [getBillersByCode, getContactsByBillerCode, getContactsByName]
  )

  const actions: Actions = { getItemsByQuery: getItemsByQueryAction }

  return [state, actions]
}

type UseBPayTargetItemsDebouncedOptions = {
  debounceDelayMs: number
}

export const useBPayTargetItemsDebounced = ({
  debounceDelayMs,
}: UseBPayTargetItemsDebouncedOptions): ReturnType<
  typeof useBPayTargetItems
> => {
  const [state, { getItemsByQuery }] = useBPayTargetItems()
  const [debouncedLoading, setDebounceLoading] = useState(false)

  const stateWrapped: typeof state = useMemo(
    () => (debouncedLoading ? { type: 'Pending' } : state),
    [state, debouncedLoading]
  )

  const getItemsByQueryWrapped = useMemo(() => {
    const getItemsByQueryDebounced = debounce<typeof getItemsByQuery>(
      async (query) => {
        await getItemsByQuery(query).finally(() => setDebounceLoading(false))
      },
      debounceDelayMs
    )
    const getItemsByQueryWrapped: typeof getItemsByQuery = async (query) => {
      setDebounceLoading(true)
      await getItemsByQueryDebounced(query)
    }
    return getItemsByQueryWrapped
  }, [getItemsByQuery, debounceDelayMs])

  return [stateWrapped, { getItemsByQuery: getItemsByQueryWrapped }]
}
