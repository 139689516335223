import { ApolloCache, FetchResult } from '@apollo/client'
import { ConnectionStatus, DebitCardAccountStatus } from '@npco/mp-gql-types'

import { Disconnect } from 'types/gql-types/Disconnect'
import { GetConnections as GetConnectionsType } from 'types/gql-types/GetConnections'
import { RunConfigureXeroBankfeeds } from 'types/gql-types/RunConfigureXeroBankfeeds'

import { GetConnections } from '../graphql/GetConnections'
import { XeroAccount } from './Components/AccountSelect.types'

export const sleep = (duration: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, duration)
  })

interface WriteConnectionStatus {
  cache: ApolloCache<object>
  connectionId: string
  status: string
}

export const writeConnectionStatusToCache = ({
  cache,
  connectionId,
  status,
}: WriteConnectionStatus) => {
  cache.modify({
    id: cache.identify({ id: connectionId, __typename: 'Connection' }),
    fields: {
      status() {
        return status
      },
    },
  })
}

export const updateOnSuccess = (
  cache: ApolloCache<any>,
  result: FetchResult<RunConfigureXeroBankfeeds>
) => {
  if (result?.data?.configureXeroBankfeeds?.connection) {
    const connections = cache.readQuery<GetConnectionsType>({
      query: GetConnections,
    })
    cache.writeQuery<GetConnectionsType>({
      query: GetConnections,
      data: {
        ...(connections as GetConnectionsType),
        xeroBankFeeds: {
          id: result?.data?.configureXeroBankfeeds?.connection.id,
          status: result?.data?.configureXeroBankfeeds?.connection.status,
          errorType: result?.data?.configureXeroBankfeeds?.connection.errorType,
          __typename: 'Connection',
        },
      },
    })
  }
}

export const updateOnRemove = (
  cache: ApolloCache<any>,
  result: FetchResult<Disconnect>
) => {
  if (result?.data?.disconnectConnection.id) {
    writeConnectionStatusToCache({
      cache,
      connectionId: result?.data?.disconnectConnection.id,
      status: ConnectionStatus.NOT_CONNECTED,
    })
  }
}

export const isActiveAccount = (account: XeroAccount | null) =>
  account !== null && account?.status !== DebitCardAccountStatus.CLOSED

export const onlyActiveAccounts = (accounts: (XeroAccount | null)[]) =>
  accounts.filter(isActiveAccount)
