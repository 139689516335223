import { useCallback, useEffect, useRef, useState } from 'react'

import { ONE_SECOND_IN_MS } from '../../SessionTimeoutModal.mocks'

type useActivityTrackerProps = {
  inactivityTimeout: number
}
export const useActivityTimer = ({
  inactivityTimeout,
}: useActivityTrackerProps) => {
  const [isUserInactive, setIsUserInactive] = useState(false)
  const [isTimerClear, setIsTimerClear] = useState(false)
  const inactivityTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const resetInactivityTimerRef = useRef<() => void>()

  const resetInactivityTimer = useCallback(() => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current)
    }
    if (!inactivityTimeout || isUserInactive || isTimerClear) {
      return
    }
    inactivityTimerRef.current = setTimeout(() => {
      setIsUserInactive(true)
    }, inactivityTimeout * ONE_SECOND_IN_MS)
  }, [inactivityTimeout, isTimerClear, isUserInactive])

  const clearActivityTimer = useCallback(() => {
    setIsTimerClear(true)
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current)
    }
  }, [])

  const handleActivity = useCallback(() => {
    if (resetInactivityTimerRef.current) {
      resetInactivityTimerRef.current()
    }
  }, [])

  const addEventListeners = useCallback(() => {
    document.addEventListener('click', handleActivity)
    document.addEventListener('mousemove', handleActivity)
    document.addEventListener('keydown', handleActivity)
  }, [handleActivity])

  const removeEventListeners = useCallback(() => {
    document.removeEventListener('click', handleActivity)
    document.removeEventListener('mousemove', handleActivity)
    document.removeEventListener('keydown', handleActivity)
  }, [handleActivity])

  useEffect(() => {
    resetInactivityTimerRef.current = resetInactivityTimer
    resetInactivityTimerRef.current()
  }, [resetInactivityTimer])

  useEffect(() => {
    if (inactivityTimeout === 0) {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current)
      }
      removeEventListeners()
    } else {
      addEventListeners()
    }
    return () => {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current)
      }
      removeEventListeners()
    }
  }, [addEventListeners, inactivityTimeout, removeEventListeners])

  return {
    isUserInactive,
    clearActivityTimer,
    setIsUserInactive,
  }
}
