import { useEffect } from 'react'
import { useTranslations } from '@npco/utils-translations'

import { Onboarding } from 'components/Onboarding/Onboarding'

import { ItemIllustration } from '../ItemIllustration/ItemIllustration'
import { useItemManagementContext } from '../ItemManagementContext/ItemManagementContext'
import { useGetSteps } from './hooks/useGetSteps'
import { translations } from './ItemOnboarding.i18n'
import { ItemOnboardingTooltip } from './ItemOnboardingTooltip'

export const ItemOnboarding = () => {
  const t = useTranslations(translations)
  const steps = useGetSteps()
  const { setHasSeenItemOnboarding } = useItemManagementContext()

  useEffect(() => {
    // NOTE: run this on unmount to prevent user seeing this again in the same
    // session
    return () => setHasSeenItemOnboarding(true)
  }, [setHasSeenItemOnboarding])

  return (
    <Onboarding
      illustrationComponent={<ItemIllustration />}
      steps={steps}
      heading={t('modalTitle')}
      description={t('modalBody')}
      primaryButtonLabel={t('modalPrimaryButton')}
      secondaryButtonLabel={t('modalSecondaryButton')}
      tooltipComponent={ItemOnboardingTooltip}
    />
  )
}
