import { gql } from '@apollo/client'

export const GetTransactionsLocalState = gql`
  query GetTransactionsLocalState {
    local @client {
      transactions {
        filters {
          amount
          dates
          statuses
          types
          sources
          search
          terminals
          siteUuid
        }
        isFiltersVisible
      }
    }
  }
`
