import {
  ButtonLink,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'

import { translate } from 'utils/translations'
import { EditNotesModal } from 'components/EditNotesModal/EditNotesModal'

import { useUpdateContact } from '../../../hooks/useUpdateContact/useUpdateContact'
import { rvSelectedContact } from '../../../rv-deprecated/contacts'
import { updateRvContactsOnContactUpdate } from '../../../rv-deprecated/contacts.utils'

export const EDIT_BUTTON_TESTID = 'contact-edit-notes'

interface EditNotesProps {
  contactId: string
  initialNote: string
}

export const EditNotes = ({ contactId, initialNote }: EditNotesProps) => {
  const { isModalOpen, openModal, closeModal } = useModalState()
  const { isUpdatingContact, updateContact } = useUpdateContact()

  const handleSubmit = async (notes: string) => {
    if (initialNote === notes) {
      closeModal()
      return
    }

    try {
      const { data } = await updateContact({ notes }, contactId)

      if (!data?.updateContact) {
        showErrorToast(
          translate('page.contact.sections.notes.saveNotesFailure')
        )
        return
      }

      showSuccessToast(
        !initialNote
          ? translate('page.contact.sections.notes.addNotesSuccess')
          : translate('page.contact.sections.notes.editNotesSuccess')
      )

      const selectedContact = rvSelectedContact()

      if (selectedContact) {
        rvSelectedContact({ ...selectedContact, notes })
        updateRvContactsOnContactUpdate(contactId, { notes })
      }

      closeModal()
    } catch (error) {
      showErrorToast(translate('page.contact.sections.notes.saveNotesFailure'))
    }
  }

  return (
    <>
      <ButtonLink dataTestId={EDIT_BUTTON_TESTID} onClick={openModal}>
        {translate('shared.edit')}
      </ButtonLink>
      <EditNotesModal
        handleSubmit={handleSubmit}
        isLoading={isUpdatingContact}
        isOpen={isModalOpen}
        notes={initialNote}
        onCancel={closeModal}
        placeholder={translate('page.contact.sections.notes.placeholder')}
        title={translate('page.contact.sections.notes.title')}
      />
    </>
  )
}
