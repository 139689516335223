import { useMutation } from '@apollo/client'
import { UpdateZellerInvoiceSettingsInput } from '@npco/mp-gql-types'

import {
  UpdateZellerInvoiceSettings as UpdateZellerInvoiceSettingsResponse,
  UpdateZellerInvoiceSettingsVariables,
} from 'types/gql-types/UpdateZellerInvoiceSettings'

import { UpdateZellerInvoiceSettings } from '../graphql/updateZellerInvoiceSettings'

export const useUpdateZellerInvoiceSettings = () => {
  const [
    updateZellerInvoiceSettings,
    { loading: isUpdatingZellerInvoiceSettings },
  ] = useMutation<
    UpdateZellerInvoiceSettingsResponse,
    UpdateZellerInvoiceSettingsVariables
  >(UpdateZellerInvoiceSettings)

  return {
    updateZellerInvoiceSettings: (
      settings: UpdateZellerInvoiceSettingsInput,
      options?: Parameters<typeof updateZellerInvoiceSettings>[0]
    ) => updateZellerInvoiceSettings({ ...options, variables: { settings } }),
    isUpdatingZellerInvoiceSettings,
  }
}
