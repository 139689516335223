import { ApolloCache, ApolloClient } from '@apollo/client'
import { DebitCardProductType } from '@npco/mp-gql-types'
import { GetDebitCardsList } from 'features/Cards/AllCardsDebit/hooks/useQueryDebitCards/graphql/getDebitCardsList'
import {
  GetDebitCardsListQueryResponse,
  GetDebitCardsListQueryVariables,
} from 'features/Cards/AllCardsDebit/hooks/useQueryDebitCards/graphql/getDebitCardsList.generated'

type Entity = GetDebitCardsListQueryResponse['getEntity']

type DebitCard =
  GetDebitCardsListQueryResponse['getDebitCardsV2']['cards'][number]

export interface CacheDebitCardListProps {
  cache: ApolloCache<any>
  mapToNewList: (oldCards: (DebitCard | null)[]) => (DebitCard | null)[]
  client?: ApolloClient<any>
}

export const cacheDebitCardList = ({
  cache,
  mapToNewList,
  client,
}: CacheDebitCardListProps) => {
  const variables = {
    filter: {
      productType: {
        eq: DebitCardProductType.DEBIT,
      },
    },
  }

  const writeDebitCardsQuery = (
    oldCards: (DebitCard | null)[],
    entity: Entity
  ) => {
    cache.writeQuery<GetDebitCardsListQueryResponse>({
      query: GetDebitCardsList,
      variables,
      data: {
        getDebitCardsV2: {
          cards: mapToNewList(oldCards),
        },
        getEntity: entity,
      },
    })
  }

  const cardList = cache.readQuery<GetDebitCardsListQueryResponse>({
    query: GetDebitCardsList,
    variables,
  })

  if (cardList) {
    writeDebitCardsQuery(cardList.getDebitCardsV2.cards, cardList.getEntity)
    return
  }

  if (client) {
    client
      .query<GetDebitCardsListQueryResponse, GetDebitCardsListQueryVariables>({
        query: GetDebitCardsList,
        variables,
        errorPolicy: 'all',
      })
      .then(({ data }) => {
        writeDebitCardsQuery(data.getDebitCardsV2.cards, data.getEntity)
      })
  }
}
