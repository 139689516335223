import { useTranslations } from '@npco/utils-translations'

import { SortList, SortListProps } from '../SortList/SortList'
import { translations } from './SortAmountList.i18n'

type SortAmountListProps = Omit<
  SortListProps,
  'ascendingLabel' | 'descendingLabel'
>

export const SortAmountList = ({
  dataTestId = 'sort-amount-list',
  sort,
  onClick,
}: SortAmountListProps) => {
  const t = useTranslations(translations)

  return (
    <SortList
      ascendingLabel={t('sortLowToHigh')}
      dataTestId={dataTestId}
      descendingLabel={t('sortHighToLow')}
      onClick={onClick}
      sort={sort}
    />
  )
}
