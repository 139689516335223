import { useQuery } from '@apollo/client'

import { XeroBankfeed as XeroBankfeedResponse } from 'types/gql-types/XeroBankfeed'

import { XeroBankfeed } from './XeroBankfeed.graphql'

export const useXeroBankfeed = () => {
  const { loading, data, error } = useQuery<XeroBankfeedResponse>(XeroBankfeed)

  return {
    isLoading: loading,
    xeroBankFeed: data?.xeroBankFeed,
    error,
  }
}
