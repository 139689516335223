import { parseToDisplayFullDate } from '@npco/zeller-design-system'
import parseNumber, { CountryCode } from 'libphonenumber-js/max'

import { ALPHA_2_COUNTRY_CODES, AUSTRALIA_COUNTRY_CODE } from 'const/common'
import {
  DATE_FULL_DISPLAY_FORMAT,
  DATE_YEAR_MONTH_DISPLAY_FORMAT,
} from 'const/date'
import { IS_PRODUCTION } from 'const/envs'
import dayjs from 'utils/dayjs'
import { parsePriceToCents } from 'utils/parsePriceToCents'
import { CustomValidator } from 'types/common'
import { errorMessages, page } from 'translations'

import { getIsAbnValid } from './abnHelper'
import { getIsAcnValid } from './acnHelper'
import { removeWhiteSpaces } from './common'
import { toAlpha2CodeCountry } from './countries'
import { formatDateMonthYear } from './date'
import { translate } from './translations'

type TextInputValue = string | number | undefined

type validateMaxLengthAndCharsBecsType = {
  maxLength: number
  errorMsgLengthAndSpecialChars?: string
  errorMsgSpecialChars?: string
}

export const VALID_EMAIL_REGEX =
  /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z-]{2,63}$/g

const getIsEmailValid = (value: string): boolean =>
  new RegExp(VALID_EMAIL_REGEX).test(value)

export const getIsValidInputString = (value?: string | null) =>
  value && Boolean(value.toString().trim())

const getHasFourDigits = (value: string): boolean => /^\d{4}$/.test(value)
export const getHasOnlyDigits = (value: string): boolean => /^\d+$/.test(value)
export const getHasOnlyASCII = (value: string): boolean =>
  /^[ -~]+$/.test(value)
export const getHasTwoDecimalPlaces = (value: string): boolean =>
  /^\d*(\.\d{0,2})?$/.test(value)

const getIsPhoneNumberValid = (
  value: string,
  countryCode: string | null = AUSTRALIA_COUNTRY_CODE
): boolean => {
  const phoneNumber = parseNumber(value, {
    defaultCountry: toAlpha2CodeCountry(countryCode) as CountryCode,
    extract: false,
  })

  if (phoneNumber) {
    return phoneNumber.isValid()
  }

  return false
}

export const getIsMobileNumberValid = (
  value: string,
  countryCode: string | null = AUSTRALIA_COUNTRY_CODE
): boolean => {
  const phoneNumber = parseNumber(value, {
    defaultCountry: toAlpha2CodeCountry(countryCode) as CountryCode,
    extract: false,
  })

  if (phoneNumber) {
    const isMobile = phoneNumber.getType() === 'MOBILE'
    const isValid = phoneNumber.isValid()
    const mobileNumberCountry = phoneNumber?.country || ''

    if (
      mobileNumberCountry === ALPHA_2_COUNTRY_CODES.AUSTRALIA ||
      mobileNumberCountry === ALPHA_2_COUNTRY_CODES.NORFOLK_ISLAND ||
      (!IS_PRODUCTION &&
        (mobileNumberCountry === ALPHA_2_COUNTRY_CODES.POLAND ||
          mobileNumberCountry === ALPHA_2_COUNTRY_CODES.INDIA)) // Allow Poland and India country mobile number to be registered when the env is not Prod
    ) {
      return isValid && isMobile
    }
  }

  return false
}

const getIsMedicareCardNumberValid = (value: string): boolean =>
  /^\d{10}$/.test(value)

const getIsMedicareCardRefNumberValid = (value: string): boolean =>
  /^[1-9]$/.test(value)

const getIsMedicareCardDateShortValid = (value: string): boolean =>
  /^\d{2}\/\d{4}$/.test(value) &&
  dayjs(value, DATE_YEAR_MONTH_DISPLAY_FORMAT, true).isValid()

const getIsMedicareCardDateLongValid = (value: string): boolean =>
  /^\d{2}\/\d{2}\/\d{4}$/.test(value) &&
  dayjs(value, DATE_FULL_DISPLAY_FORMAT, true).isValid()

const getIsPassportNumberValid = (value: string): boolean =>
  /^[a-zA-Z]{1,2}\d{7}$/.test(value)

const alphaNumericRegex = /^[a-zA-Z0-9]*$/

const getIsAlphaNumeric = (value: string): boolean =>
  alphaNumericRegex.test(value)

const getIsIdentityStringValid = (value: string): boolean =>
  /^(?=.{2,40}$)([a-zA-Z]+[-' ])*[a-zA-Z]+$/.test(value)

const getIsMiddleNameStringValid = (value: string): boolean =>
  /^(?=.{1,40}$)([a-zA-Z]+[-' ])*[a-zA-Z]+$/.test(value)

const getIsCardNumberLengthValidForEMLIssuedCard = (value: string): boolean =>
  /^\w{9}$/.test(value)

export const getIsCardNumberLengthValidForEZTAIssuedCard = (
  value: string
): boolean => /^\w{10}$/.test(value)

const getIsVerificationCodeLengthValid = (value: string): boolean =>
  /^\d{8}$/.test(value)

interface StateRegex {
  [key: string]: RegExp
}

const driverLicenceStateRegex: StateRegex = {
  ACT: /^\d{1,10}$/,
  NT: /^\d{1,10}$/,
  QLD: /^\d{8,9}$/,
  NSW: /^[a-zA-Z0-9]{6,8}$/,
  SA: /^[a-zA-Z0-9]{6}$/,
  TAS: /^[a-zA-Z0-9]{6,8}$/,
  VIC: /^\d{1,10}$/,
  WA: /^\d{7}$/,
}

const driverLicenceCardNumberByStateRegex: StateRegex = {
  ACT: /^[a-zA-Z0-9]{10}$/,
  NT: /^\d{6,8}$/,
  QLD: /^[a-zA-Z0-9]{10}$/,
  NSW: /^\d{10}$/,
  SA: /^[a-zA-Z0-9]{9}$/,
  TAS: /^[a-zA-Z0-9]{9}$/,
  VIC: /^[a-zA-Z0-9]{8}$/,
  WA: /^[a-zA-Z0-9]{8,10}$/,
}

const getIsDrivingLicenceNumberValid = (value: string, state: string) =>
  driverLicenceStateRegex[state].test(value)

const getIsDrivingLicenceCardNumberValid = (value: string, state: string) =>
  driverLicenceCardNumberByStateRegex[state].test(value)

const getIsDateValid = (value: string) =>
  /^\d{2}\/\d{2}\/\d{4}$/.test(value) &&
  dayjs(value, DATE_FULL_DISPLAY_FORMAT, true).isValid()

const getIsDoBMatureValid = (value: string) =>
  /^\d{2}\/\d{2}\/\d{4}$/.test(value) &&
  dayjs().diff(dayjs(value, DATE_FULL_DISPLAY_FORMAT), 'year') >= 18

const getIsSuburbStringValid = (value: string) =>
  /^[0-9a-zA-Z-'.\s]{3,40}$/.test(value)

const getIsStreetStringValid = (value: string) =>
  /^[0-9a-zA-Z-'/.,\s]{4,40}$/.test(value)

const getIsOptionalStreetStringValid = (value: string) =>
  /^[0-9a-zA-Z-'/.,\s]{0,40}$/.test(value)

const getIsSocialMediaValid = (value: string) =>
  /^[a-zA-Z0-9!@#$%^&*)(+=._-\s]{2,30}$/.test(value)

const getIsCompanyNameValid = (value: string) => /^.{4,50}$/.test(value)

export const makeOptional = (
  validatingFunction: CustomValidator<any, any>,
  value: string | undefined,
  isFieldOptional = true
) => {
  if (!value && isFieldOptional) {
    return undefined
  }

  return validatingFunction(value)
}

export const validateMaxLength =
  (maxLength: number, errorMsg: string = errorMessages.maxLength) =>
  (value: TextInputValue) => {
    if (!value) {
      return undefined
    }
    const parsedVal = typeof value === 'string' ? value : value.toString(10)
    return parsedVal.length > maxLength ? errorMsg : undefined
  }

export const validateEmail: CustomValidator<string, boolean> = (
  value,
  isFieldRequired = true
) => {
  if (!value && !isFieldRequired) {
    return undefined
  }

  let error

  if (!getIsEmailValid(value)) {
    error = errorMessages.invalidEmail
  }

  if (!value) {
    error = errorMessages.emailRequired
  }

  return error
}

export const validateConfirmEmail: CustomValidator<string, string | null> = (
  value,
  enteredEmail
) => {
  if (!value && !enteredEmail) {
    return undefined
  }

  if (!value && enteredEmail) {
    return errorMessages.emailRequired
  }

  if (!getIsEmailValid(value)) {
    return errorMessages.invalidEmail
  }

  if (value !== enteredEmail) {
    return errorMessages.confirmEmail
  }

  return undefined
}

const HAS_NUMBER_REGEX = /\d/
export const validateHasNumber =
  (errorMsg = ''): CustomValidator<string> =>
  (value) => {
    return HAS_NUMBER_REGEX.test(value) ? undefined : errorMsg
  }

const ONE_UPPERCASE_REGEX = /[A-Z]/
export const validateHasUppercase =
  (errorMsg = ''): CustomValidator<string> =>
  (value) =>
    ONE_UPPERCASE_REGEX.test(value) ? undefined : errorMsg

const SPECIAL_CHAR_REGEX = /[^a-zA-Z0-9]/
export const validateHasSpecialChar =
  (errorMsg = ''): CustomValidator<string> =>
  (value) => {
    return SPECIAL_CHAR_REGEX.test(value) ? undefined : errorMsg
  }

// Too many usages of validateRequired to refactor to use custom msg.
// new function made instead.
/*
 * @deprecated please use validateRequiredCustomMsg instead
 */
export const validateRequired: CustomValidator<string | undefined> = (
  value
) => {
  if (!getIsValidInputString(value)) {
    return errorMessages.fieldRequired
  }

  return undefined
}

export const validateRequiredCustomMsg =
  (errorMsg = errorMessages.fieldRequired): CustomValidator<string> =>
  (value) => {
    if (!getIsValidInputString(value)) {
      return errorMsg
    }

    return undefined
  }

export const validateUnique =
  (value: string, initialValue: string, list: string[], errorMessage: string) =>
  () => {
    return (
      value &&
      (list?.includes(value) && value !== initialValue
        ? errorMessage
        : undefined)
    )
  }

export const validateIsInteger =
  (errorMessage: string) => (value: string | number) => {
    if (!getHasOnlyDigits(value?.toString())) {
      return errorMessage
    }

    return undefined
  }

export const validateIsASCII =
  (errorMessage: string): CustomValidator<string> =>
  (value: string | number) => {
    if (!getHasOnlyASCII(value?.toString())) {
      return errorMessage
    }
    return undefined
  }

export const validateMaxValue =
  (maxThreshold: number, errorMsg: string, skip?: boolean) =>
  (value: TextInputValue) => {
    if (!value || skip) {
      return undefined
    }

    const parsedValue = parsePriceToCents(value.toString())
    const threshold = parsePriceToCents(maxThreshold.toString())

    if (Number.isNaN(parsedValue)) {
      return errorMsg
    }

    return parsedValue > threshold ? errorMsg : undefined
  }

export const validateMinValue =
  (minThreshold: number, errorMsg: string) => (value: TextInputValue) => {
    if (!value) {
      return undefined
    }

    const parsedValue = parsePriceToCents(value.toString())
    const threshold = parsePriceToCents(minThreshold.toString())

    if (Number.isNaN(parsedValue)) {
      return errorMsg
    }

    return parsedValue < threshold ? errorMsg : undefined
  }

export const validateOnlyTwoDecimalPlaces =
  (errorMsg: string) => (value: TextInputValue) => {
    const MAX_DECIMAL_PLACES = 2
    if (!value) {
      return undefined
    }
    const parsedValue = value.toString()
    if (parsedValue.includes('.')) {
      return parsedValue.split('.')[1].length > MAX_DECIMAL_PLACES
        ? errorMsg
        : undefined
    }

    if (parsedValue.includes(',')) {
      return parsedValue.split(',')[1].length > MAX_DECIMAL_PLACES
        ? errorMsg
        : undefined
    }
    return undefined
  }

export const validateNumberWithoutDigits =
  (errorMsg: string) => (value: TextInputValue) => {
    if (!value) {
      return undefined
    }

    const parsedValue = value.toString()
    return parsedValue.includes('.') ? errorMsg : undefined
  }

export function combineValidators<Arg, Result>(
  ...validators: (CustomValidator<Arg, Result> | undefined)[]
) {
  return (value: Arg) => {
    return validators
      .filter((validator) => validator !== undefined)
      .reduce((errorMessage = '', validator = () => '') => {
        return errorMessage || validator(value)
      }, '')
  }
}

export const validateCardNumberLength: CustomValidator<string | undefined> = (
  value
) => {
  if (!value) {
    return errorMessages.cardNumberRequired
  }

  if (
    !getIsCardNumberLengthValidForEMLIssuedCard(value.toString()) &&
    !getIsCardNumberLengthValidForEZTAIssuedCard(value.toString())
  ) {
    return errorMessages.invalidCardNumber
  }

  return undefined
}

const EML_CARD_NICKNAME_MAX_LENGTH = 25

export const validateEMLCardNickname: CustomValidator<string | undefined> =
  combineValidators(
    validateRequired,
    validateMaxLength(
      EML_CARD_NICKNAME_MAX_LENGTH,
      translate('errorMessages.emlCardNicknameMaxLength')
    )
  )

export const validateEMLCardNumberLength: CustomValidator<
  string | undefined
> = (value) => {
  if (!value) {
    return errorMessages.cardNumberRequired
  }

  if (!getIsCardNumberLengthValidForEMLIssuedCard(value.toString())) {
    return errorMessages.invalidCardNumber
  }

  return undefined
}

export const validateVerificationCode: CustomValidator<string | undefined> = (
  value
) => {
  if (!value) {
    return errorMessages.verificationCodeRequired
  }

  if (!getIsVerificationCodeLengthValid(value.toString())) {
    return page.onboarding.linkCard.verifyCode.invalid
  }

  return undefined
}

export const validateAbnOrAcn: CustomValidator<string | undefined> = (
  value
) => {
  let error: string | undefined = errorMessages.invalidAbnOrAcn
  const preparedValue = removeWhiteSpaces(value || '')

  if (
    (preparedValue?.length === 9 && getIsAcnValid(preparedValue)) ||
    (preparedValue?.length === 11 && getIsAbnValid(preparedValue))
  ) {
    error = undefined
  }

  return error
}

export const validateAbnOrAcnManual =
  (
    hasServerError: boolean | undefined = false,
    isAbnUnsupported: boolean | undefined = false
  ): CustomValidator<string | undefined> =>
  (value) => {
    if (!value || isAbnUnsupported) {
      return undefined
    }

    if (hasServerError) {
      return errorMessages.notFoundAbnOrAcn
    }

    const error = validateAbnOrAcn(removeWhiteSpaces(value))

    return error
  }

export const validateAbn: CustomValidator<string | undefined> = (value) => {
  const parsedValue = removeWhiteSpaces(String(value))
  if (!parsedValue) {
    return errorMessages.invalidAbn
  }

  if (parsedValue && !getIsAbnValid(parsedValue)) {
    return errorMessages.invalidAbn
  }

  return undefined
}

export const validateAbnNotRequired: CustomValidator<string | undefined> = (
  value
) => {
  const parsedValue = String(value)

  if (value === null) {
    return undefined
  }

  if (parsedValue && !getIsAbnValid(parsedValue)) {
    return errorMessages.invalidAbn
  }

  return undefined
}

export const validateAcnNotRequired: CustomValidator<string | undefined> = (
  value
) => {
  const parsedValue = String(value)

  if (value === null) {
    return undefined
  }

  if (parsedValue && !getIsAcnValid(parsedValue)) {
    return errorMessages.invalidAcn
  }

  return undefined
}

export const validatePostcode: CustomValidator<TextInputValue> = (value) => {
  if (!value) {
    return errorMessages.postcodeRequired
  }

  if (value && !getHasFourDigits(value.toString())) {
    return errorMessages.invalidPostcode
  }

  return undefined
}

export const validatePin: CustomValidator<string | undefined> = (value) => {
  if (!value) {
    return errorMessages.invalidPin
  }

  if (value && !getHasFourDigits(value.toString())) {
    return errorMessages.invalidPin
  }

  return undefined
}

// NOTE: doesn't appear to be used - should remove?
export const getPhoneValidatorByCountry =
  (countryCode?: string | null): CustomValidator<TextInputValue, boolean> =>
  (value, isFieldRequired = true) => {
    if (!value && !isFieldRequired) {
      return undefined
    }

    if (!value) {
      return errorMessages.phoneNumberRequired
    }

    if (!getIsPhoneNumberValid(value.toString(), countryCode)) {
      return errorMessages.invalidPhoneNumber
    }

    return undefined
  }

// NOTE: doesn't appear to be used - should remove?
export const getMobileValidatorByCountry =
  (countryCode?: string | null): CustomValidator<TextInputValue, boolean> =>
  (value, isFieldRequired = true) => {
    if (!value && !isFieldRequired) {
      return undefined
    }

    if (!value) {
      return errorMessages.mobileNumberRequired
    }

    if (!getIsPhoneNumberValid(value.toString(), countryCode)) {
      return errorMessages.invalidMobileNumber
    }

    return undefined
  }

export const validatePhoneNumber: CustomValidator<TextInputValue, boolean> = (
  value,
  isFieldRequired = true
) => {
  if (!value && !isFieldRequired) {
    return undefined
  }

  if (!value) {
    return errorMessages.phoneNumberRequired
  }

  if (!getIsPhoneNumberValid(value.toString())) {
    return errorMessages.invalidAustralianPhoneNumber
  }

  return undefined
}

export const validateMobileNumber: CustomValidator<TextInputValue, boolean> = (
  value,
  isFieldRequired = true
) => {
  if (!value && !isFieldRequired) {
    return undefined
  }

  if (!value) {
    return errorMessages.mobileNumberRequired
  }

  const isMobileNumberValid = getIsMobileNumberValid(value.toString())

  if (!isMobileNumberValid) {
    return errorMessages.invalidAustralianMobileNumber
  }

  return undefined
}

export const validateMedicareCardNumber: CustomValidator<number | undefined> = (
  value
) => {
  if (value === 0) {
    return errorMessages.invalidMedicareCardNumber
  }

  if (value && !getIsMedicareCardNumberValid(value.toString())) {
    return errorMessages.invalidMedicareCardNumber
  }

  if (!value) {
    return errorMessages.cardNumberRequired
  }

  return undefined
}

export const validateMedicareCardRefNumber: CustomValidator<
  number | undefined
> = (value) => {
  if (value === 0) {
    return errorMessages.invalidMedicareCardRefNumber
  }

  if (value && !getIsMedicareCardRefNumberValid(value.toString())) {
    return errorMessages.invalidMedicareCardRefNumber
  }

  if (!value) {
    return errorMessages.referenceNumberRequired
  }

  return undefined
}

export const validateMedicareCardDate: CustomValidator<string, boolean> = (
  value,
  isShortDate
) => {
  let error
  const validateMethod = isShortDate
    ? getIsMedicareCardDateShortValid
    : getIsMedicareCardDateLongValid

  if (!validateMethod(value)) {
    error = errorMessages.invalidMedicareCardDate
  }

  if (!value) {
    error = errorMessages.expirationDateRequired
  }

  return error
}

export const validateAustralianPassportNumber: CustomValidator<string> = (
  value
) => {
  let error

  if (!getIsPassportNumberValid(value)) {
    error = errorMessages.invalidPassportNumber
  }

  if (!value) {
    error = errorMessages.passportNumberRequired
  }

  return error
}

export const validateIsPassportNumberAlphaNumeric: CustomValidator<string> = (
  value
) => {
  if (!getIsAlphaNumeric(value)) {
    return errorMessages.invalidPassportNumber
  }

  if (!value) {
    return errorMessages.passportNumberRequired
  }

  return undefined
}

interface PassportNumberValidationArguments {
  countryCode: string
}

export const validatePassportNumber = ({
  countryCode,
}: PassportNumberValidationArguments) => {
  if (countryCode === AUSTRALIA_COUNTRY_CODE) {
    return validateAustralianPassportNumber
  }

  return validateIsPassportNumberAlphaNumeric
}

export const validateIdentityString: CustomValidator<string> = (value) => {
  let error

  if (!getIsIdentityStringValid(value)) {
    error = errorMessages.invalidIdentityString
  }

  if (!getIsValidInputString(value)) {
    error = errorMessages.identityStringRequired
  }

  return error
}

export const validateDrivingLicenceNumber: CustomValidator<string, string> = (
  value,
  state
) => {
  if (!value) {
    return errorMessages.drivingLicenceNumberRequired
  }

  if (!state) {
    return undefined
  }

  if (!getIsDrivingLicenceNumberValid(value, state)) {
    return errorMessages.invalidDrivingLicenceNumber
  }

  return undefined
}

export const validateDrivingLicenceCardNumber: CustomValidator<
  string,
  string
> = (value, state) => {
  if (!value || !state) {
    return undefined
  }

  if (!getIsDrivingLicenceCardNumberValid(value, state)) {
    return translate('errorMessages.invalidDrivingLicenceCardNumber')
  }

  return undefined
}

export const validateDoB: CustomValidator<string> = (value) => {
  let error

  if (!getIsDateValid(value)) {
    error = errorMessages.invalidDateOfBirth
  }

  if (getIsDateValid(value) && !getIsDoBMatureValid(value)) {
    error = errorMessages.invalidUserAge
  }

  if (!value) {
    error = errorMessages.dateOfBirthRequired
  }

  return error
}

export const validateDate: CustomValidator<string> = (value) => {
  if (!value) {
    return errorMessages.fieldRequired
  }

  if (!getIsDateValid(value)) {
    return errorMessages.invalidDate
  }

  return undefined
}

export const validateAddressString: CustomValidator<string> = (value) => {
  if (!getIsValidInputString(value)) {
    return errorMessages.fieldRequired
  }

  if (!getIsStreetStringValid(value)) {
    return errorMessages.invalidAddressField
  }

  return undefined
}

export const validateOptionalAddressString: CustomValidator<string> = (
  value
) => {
  if (!getIsOptionalStreetStringValid(value)) {
    return errorMessages.invalidAddressField
  }

  return undefined
}

export const validateSuburb: CustomValidator<string> = (value) => {
  if (!getIsValidInputString(value)) {
    return errorMessages.fieldRequired
  }

  if (!getIsSuburbStringValid(value)) {
    return errorMessages.invalidSuburb
  }

  return undefined
}

export const validateSocialMedia: CustomValidator<string> = (value) => {
  let error

  if (value && !getIsSocialMediaValid(value)) {
    error = errorMessages.invalidSocialMedia
  }

  return error
}

export const validateCompanyName: CustomValidator<string> = (value) => {
  let error

  if (!getIsValidInputString(value)) {
    error = errorMessages.fieldRequired
  }

  const valueTrimmed = value?.trim()

  if (valueTrimmed && !getIsCompanyNameValid(valueTrimmed)) {
    error = errorMessages.invalidCompanyName
  }

  return error
}

export const validateMinLength =
  (
    min: number,
    errorMsg: string = errorMessages.tooShort
  ): CustomValidator<string | undefined> =>
  (value) => {
    if (!value) {
      return errorMessages.fieldRequired
    }

    if (value && String(value).length < min) {
      return errorMsg
    }

    return undefined
  }

export const validateExactLength =
  (correctLength: number, errorMsg: string) =>
  (value: string | number | undefined) => {
    if (!value) {
      return errorMsg
    }
    const stringVal = value.toString()
    return stringVal.length === correctLength ? undefined : errorMsg
  }

export const PASSWORD_MIN_LENGTH = 8
export const validatePasswordMinLength = (errorMsg = errorMessages.tooShort) =>
  validateMinLength(PASSWORD_MIN_LENGTH, errorMsg)

export const validatePassword = combineValidators(
  validateRequiredCustomMsg(errorMessages.passwordRequired),
  validatePasswordMinLength(errorMessages.invalidPassword),
  validateHasUppercase(errorMessages.invalidPassword),
  validateHasNumber(errorMessages.invalidPassword),
  validateHasSpecialChar(errorMessages.invalidPassword)
)

const FIRST_NAME_INVALID_REGEX = /^[^a-zA-Z'-]+$/
const EMPTY_NAME_CHARACTER = '-'

type NameValidatorPayload = string | undefined | null

export const validateFirstName: CustomValidator<NameValidatorPayload> = (
  name
) => {
  const trimmedName = (name ?? '').trim()
  const doesNameHaveInvalidCharacters =
    FIRST_NAME_INVALID_REGEX.test(trimmedName)
  const isNameAHyphen = trimmedName === EMPTY_NAME_CHARACTER

  if (isNameAHyphen) {
    return undefined
  }

  if ((doesNameHaveInvalidCharacters && !isNameAHyphen) || !trimmedName) {
    return errorMessages.invalidFirstName
  }

  return validateIdentityString(trimmedName)
}

export const validateLastName: CustomValidator<NameValidatorPayload> = (
  name
) => {
  const trimmedName = (name ?? '').trim()

  if (!trimmedName) {
    return errorMessages.invalidIdentityString
  }

  return validateIdentityString(trimmedName)
}

export const validateMiddleName: CustomValidator<NameValidatorPayload> = (
  name
) => {
  let error

  const trimmedName = (name ?? '').trim()

  if (!trimmedName) {
    return error
  }

  if (!getIsMiddleNameStringValid(trimmedName)) {
    error = errorMessages.invalidIdentityString
  }

  return error
}

export const validatePhoneNumberForContact: CustomValidator<
  TextInputValue,
  boolean
> = (value, isFieldRequired = true) => {
  if (!value && !isFieldRequired) {
    return undefined
  }

  if (!value) {
    return errorMessages.phoneNumberOrEmailRequired
  }

  if (!getIsPhoneNumberValid(value.toString())) {
    return errorMessages.invalidAustralianPhoneNumber
  }

  return undefined
}

export const validateMobileNumberForContact: CustomValidator<
  TextInputValue,
  boolean
> = (value, isFieldRequired = true) => {
  if (!value && !isFieldRequired) {
    return undefined
  }

  if (!value) {
    return errorMessages.emailOrMobileRequired
  }

  if (!getIsMobileNumberValid(value.toString())) {
    return errorMessages.invalidAustralianMobileNumber
  }

  return undefined
}

export const validateEmailForContact =
  (
    errorMessageForIsRequiredValidation = errorMessages.emailRequired
  ): CustomValidator<string, boolean> =>
  (value) => {
    let error

    if (!getIsEmailValid(value)) {
      error = errorMessages.invalidEmail
    }

    if (!value) {
      error = errorMessageForIsRequiredValidation
    }

    return error
  }

export const validateDateYearRange: CustomValidator<
  string,
  {
    min: number
    max: number
    errorMessage?: string
  }
> = (value, options) => {
  if (!value || !options) {
    return undefined
  }

  const { errorMessage, min, max } = options

  if (
    !dayjs(value, DATE_FULL_DISPLAY_FORMAT).isBetween(
      dayjs().year(min),
      dayjs().year(max),
      'year'
    )
  ) {
    return (
      errorMessage ??
      translate('errorMessages.invalidDateRangeByYear', {
        minYear: min,
        maxYear: max,
      })
    )
  }

  return undefined
}

export const validateDateIsSameOrAfterByDate: CustomValidator<
  string,
  {
    dateValidator: dayjs.ConfigType
    isSameOrAfter?: boolean
    errorMessageSameOrAfter?: string
    errorMessageDateIsAfter?: string
  }
> = (value, options) => {
  if (!value || !options) {
    return undefined
  }
  const {
    dateValidator,
    isSameOrAfter,
    errorMessageSameOrAfter,
    errorMessageDateIsAfter,
  } = options

  if (!dayjs(dateValidator).isValid()) {
    return undefined
  }

  const errorMessageDateDisplay = parseToDisplayFullDate(
    dayjs(dateValidator).toString()
  )
  const inputValue = formatDateMonthYear(
    dayjs(value, DATE_FULL_DISPLAY_FORMAT).toString()
  )

  const formattedDateValidator = formatDateMonthYear(
    dayjs(dateValidator, DATE_FULL_DISPLAY_FORMAT).toString()
  )

  if (isSameOrAfter) {
    if (!dayjs(inputValue).isSameOrAfter(formattedDateValidator)) {
      return (
        errorMessageSameOrAfter ??
        translate('errorMessages.invalidDateIsSameOrAfter', {
          date: errorMessageDateDisplay,
        })
      )
    }

    return undefined
  }

  if (!dayjs(inputValue).isAfter(formattedDateValidator)) {
    return (
      errorMessageDateIsAfter ??
      translate('errorMessages.invalidDateIsAfter', {
        date: errorMessageDateDisplay,
      })
    )
  }

  return undefined
}

const getIsBECSStringValid = (value: string) =>
  // Bulk Electronic Clearing System (BECS) validation
  /^[A-Za-z0-9^_[\]',?;:=#/.*()&%!$ @+-]+$/.test(value)

// Validate field value length and invalid characters using BECS validation
export const validateMaxLengthAndCharsBecs =
  ({
    maxLength,
    errorMsgLengthAndSpecialChars = errorMessages.invalidLengthAndCharsBecs,
    errorMsgSpecialChars = errorMessages.invalidCharsBecs,
  }: validateMaxLengthAndCharsBecsType) =>
  (value: string) => {
    if (!value || typeof value !== 'string') {
      return undefined
    }

    const isValidLength = Boolean(value?.length && value.length <= maxLength)
    const isValidBECSString = getIsBECSStringValid(value)

    if (isValidLength && !isValidBECSString) {
      // When the field value length is within the limit of {maxLength} and has invalid characters
      return errorMsgSpecialChars
    }

    if (!isValidLength && !isValidBECSString) {
      // When field value length is over the limit of {maxLength} and has invalid characters
      return errorMsgLengthAndSpecialChars
    }

    return undefined
  }
