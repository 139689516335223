import { useCallback } from 'react'
import { Box, Flex, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as IconRemove } from 'assets/svg/close-grey.svg'
import { ReactComponent as Document } from 'assets/svg/document.svg'
import { ReactComponent as SuccessIcon } from 'assets/svg/toasts/success.svg'
import { translate } from 'utils/translations'

import { FileListSelect } from '../FileListSelect/FileListSelect'
import { useFileContext } from '../hooks/useFileContext'
import * as styled from './FileListItem.styled'

interface FileListItemProps {
  children?: React.ReactNode
  className?: string
  id: string
  isUploaded?: boolean
  onDelete: (id: string) => void
  title: string
}

export const FileListItem = ({
  children,
  className,
  id,
  isUploaded,
  onDelete,
  title,
}: FileListItemProps) => {
  const { optionItems } = useFileContext()
  const hasOptionItems = Boolean(optionItems?.length)
  const handleDelete = useCallback(() => {
    onDelete(id)
  }, [id, onDelete])

  return (
    <styled.ItemBox className={className}>
      <styled.FileContainer width="16" height="16">
        <Document data-testid="document-icon" />
      </styled.FileContainer>

      <Box
        display={{
          _: 'block',
          XS: 'flex',
        }}
        flexDirection={{
          _: 'column',
          XS: 'row',
        }}
        flexGrow={1}
        justifyContent="space-between"
        alignItems={{
          _: 'baseline',
          XS: 'center',
        }}
        overflow="hidden"
        gridGap={{
          _: '0',
          XS: '16px',
        }}
      >
        <styled.Title as="div">{title}</styled.Title>

        {hasOptionItems && <FileListSelect id={id} isDisabled={isUploaded} />}
      </Box>

      {isUploaded ? (
        <Flex
          height="2.5rem"
          width="2.5rem"
          alignItems="center"
          justifyContent="center"
        >
          <SvgIcon alt={translate('shared.uploaded')} height="16" width="16">
            <SuccessIcon data-testid="success-icon" />
          </SvgIcon>
        </Flex>
      ) : (
        <Flex height="2.5rem" alignItems="center">
          <styled.CloseButton
            type="button"
            data-testid="remove-button"
            aria-label={translate('component.fileItem.removeButtonLabel')}
            onClick={handleDelete}
          >
            <SvgIcon height="16" width="16">
              <IconRemove />
            </SvgIcon>
          </styled.CloseButton>
        </Flex>
      )}
      {children}
    </styled.ItemBox>
  )
}
