import { Reference } from '@apollo/client'

import { useDeleteItemCategoryMutation } from '../graphql/deleteItemCategory.generated'

export const useDeleteCategory = () => {
  const [deleteCategory, { loading: isDeletingCategory, error, data }] =
    useDeleteItemCategoryMutation({
      update: (cache, result) => {
        cache.modify({
          broadcast: false,
          fields: {
            getItemCategories: (existingCategoriesRef, { readField }) => {
              return {
                ...existingCategoriesRef,
                categories: existingCategoriesRef.categories?.filter(
                  (categoryRef: Reference) => {
                    const id = readField('id', categoryRef)
                    return result.data?.deleteItemCategory.id !== id
                  }
                ),
              }
            },
          },
        })
        cache.gc()
      },
    })

  return {
    deleteCategory: (id: string, newCategoryId?: string) =>
      deleteCategory({
        variables: { categoryUuid: id, newCategoryUuid: newCategoryId },
      }),
    isDeletingCategory,
    error,
    data,
  }
}
