import { Route, Routes } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ROOT } from 'const/routes'
import { NotFound } from 'pages/NotFound'

import { EmptyState } from '../components/EmptyState/EmptyState'
import { Transactions } from '../components/Transactions/Transactions'
import { TransactionsProvider } from '../components/TransactionsContext/TransactionsContext'
import { TransactionsLayout } from '../components/TransactionsLayout/TransactionsLayout'

const deps = {
  TransactionsProvider,
  TransactionsLayout,
  Transactions,
  NotFound,
  EmptyState,
}
export { deps as TransactionsRoutesDeps }

export const TransactionsRoutes = () => {
  const entityDetails = useReactiveVar(rvEntityDetails)
  const yetToMakeTransation =
    entityDetails?.transactionMetaData?.yetToMakeTransaction

  if (yetToMakeTransation) {
    return <deps.EmptyState />
  }

  return (
    <Routes>
      <Route
        element={
          <deps.TransactionsProvider>
            <deps.TransactionsLayout />
          </deps.TransactionsProvider>
        }
      >
        <Route index element={<deps.Transactions />} />
        <Route path={`${ROOT.PORTAL.TRANSACTIONS.SPLITPAYMENT.relative}/*`}>
          <Route
            path={`${
              ROOT.PORTAL.TRANSACTIONS.SPLITPAYMENT.SPLIT_PAYMENT_ID().relative
            }/*`}
            element={<deps.Transactions />}
          />
        </Route>
        <Route path="*" element={<deps.NotFound />} />
      </Route>
    </Routes>
  )
}
