import { GraphQLError } from 'graphql'
import { ErrorType, ServerError } from 'types/errors'

export const getIsCardNotFoundError = (graphQLError: GraphQLError) => {
  const serverError = graphQLError as ServerError
  return (
    serverError.errorType === ErrorType.NOT_FOUND &&
    serverError.path?.length === 1 &&
    serverError.path[0] === 'getDebitCardV2'
  )
}
