import React, { useEffect, useState } from 'react'
import { Flex, INPUT_SIZE, InputWithoutLabel } from '@npco/zeller-design-system'
import { rvSearchPhrase } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvDeferredFilterPayload } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/filters'

import { ReactComponent as SearchIcon } from 'assets/svg/search.svg'
import { TIME_FILTER_ENABLED_COMPONENTS } from 'types/picker'
import { DatePicker } from 'components/Filters/NewFilters/DatePicker'
import { RangePicker } from 'components/Filters/NewFilters/RangePicker'
import { SourcePicker } from 'components/Filters/NewFilters/SourcePicker/SourcePicker'
import { StatusPicker } from 'components/Filters/NewFilters/StatusPicker'
import { TypePicker } from 'components/Filters/NewFilters/TypePicker/TypePicker'
import { SiteDevicePicker } from 'components/SiteDevicePicker/SiteDevicePicker'
import { component } from 'translations'

export interface FiltersProps {
  isDateTime?: boolean
  widgetKey?: TIME_FILTER_ENABLED_COMPONENTS
}

export const Filters = ({ isDateTime, widgetKey }: FiltersProps) => {
  const [searchValue, setSearchValue] = useState(rvSearchPhrase())

  const handleOnChange = (e: React.BaseSyntheticEvent) => {
    const target = e.target as HTMLInputElement

    setSearchValue(target.value)
    rvDeferredFilterPayload({
      ...rvDeferredFilterPayload(),
      Search: !!target.value,
    })
  }

  useEffect(() => {
    rvSearchPhrase(searchValue)
  }, [searchValue])

  return (
    <Flex
      data-testid="filters"
      width="100%"
      flexDirection="row"
      flexWrap="wrap"
    >
      <Flex
        mb={{ _: '12px', SM: '20px' }}
        pr={{ XS: '6px', SM: '8px', MD: '12px' }}
        width={{ _: '100%', XS: '50%', MD: '33.33%' }}
      >
        <InputWithoutLabel
          name="search-input"
          size={INPUT_SIZE.SMALL}
          value={searchValue}
          icon={SearchIcon}
          onChange={handleOnChange}
          placeholder={component.filters.placeholder}
        />
      </Flex>
      <Flex
        mb={{ _: '12px', SM: '20px' }}
        pl={{ XS: '6px', SM: '8px', MD: '4px' }}
        pr={{ MD: '8px' }}
        width={{ _: '100%', XS: '50%', MD: '33.33%' }}
      >
        <SiteDevicePicker />
      </Flex>
      <Flex
        mb={{ _: '12px', SM: '20px' }}
        pl={{ MD: '8px' }}
        pr={{ XS: '6px', SM: '8px', MD: '0px' }}
        width={{ _: '100%', XS: '50%', MD: '33.33%' }}
      >
        <SourcePicker />
      </Flex>
      <Flex
        mb={{ _: '12px', SM: '20px' }}
        pl={{ XS: '6px', SM: '8px', MD: '0px' }}
        pr={{ MD: '12px' }}
        width={{ _: '100%', XS: '50%', MD: '33.33%' }}
      >
        <TypePicker />
      </Flex>
      <Flex
        mb={{ _: '12px', SM: '20px' }}
        pl={{ MD: '4px' }}
        pr={{ XS: '6px', SM: '8px' }}
        width={{ _: '100%', XS: '50%', MD: '33.33%' }}
      >
        <StatusPicker />
      </Flex>
      <Flex
        width={{ _: '100%', XS: '50%', MD: '33.33%' }}
        pl={{ XS: '6px', SM: '8px' }}
        mb={{ _: '12px', SM: '20px' }}
      >
        <RangePicker />
      </Flex>
      <Flex pr={{ MD: '8px' }} width={{ _: '100%', XS: '100%', MD: '66.66%' }}>
        <DatePicker isDateTime={isDateTime} widgetKey={widgetKey} />
      </Flex>
    </Flex>
  )
}
