import { useCallback } from 'react'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { DocumentUploadedType } from '@npco/mp-gql-types'
import { showApiErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { GetDocumentUploadUrls } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/documentUpload'

import {
  getHasAllFileUploaded,
  getToastMessage,
  handleUploadFile,
} from 'utils/fileUploads'
import {
  GetDocumentUploadUrls as GetDocumentUploadUrlsPayload,
  GetDocumentUploadUrls_getDocumentUploadUrls as UrlData,
  GetDocumentUploadUrlsVariables,
} from 'types/gql-types/GetDocumentUploadUrls'
import { FileQueueItem } from 'components/File/File.types'
import { fetchUploadFileToS3Bucket } from 'components/File/File.utils'

import { Values } from '../Form/Form.types'
import { getOtherDocumentValue } from './useGetUploadUrls.utils'

interface UseGetUploadUrlsProps {
  fileQueueItems: FileQueueItem[]
  succeededFileQueueItems: FileQueueItem[]
  addSuccededFileQueueItem: (fileQueueItem: FileQueueItem) => void
  setFailedFailedNames: React.Dispatch<React.SetStateAction<string[]>>
  onSuccessCallback?: () => void
  setPersistedSubject: React.Dispatch<React.SetStateAction<string>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const useGetUploadUrls = ({
  fileQueueItems,
  succeededFileQueueItems,
  setFailedFailedNames,
  addSuccededFileQueueItem,
  onSuccessCallback,
  setPersistedSubject,
  setIsLoading,
}: UseGetUploadUrlsProps) => {
  const [getUploadUrls] = useLazyQuery<
    GetDocumentUploadUrlsPayload,
    GetDocumentUploadUrlsVariables
  >(GetDocumentUploadUrls, {
    onError: (err: ApolloError) => {
      showApiErrorToast(err)
      setIsLoading(false)
    },
    onCompleted: (responseData) => {
      const filesQueueMap = fileQueueItems.reduce(
        (currentMap, fileQueueItem) => ({
          ...currentMap,
          [fileQueueItem.file.name]: fileQueueItem,
        }),
        {} as Record<string, FileQueueItem>
      )
      Promise.all(
        responseData.getDocumentUploadUrls
          .filter((urlData): urlData is UrlData => !!urlData)
          .map(async (urlData: UrlData) => {
            const uploadFile = async (data: UrlData) => {
              if (!data.uploadUrl) {
                return false
              }

              const fileQueueItem = filesQueueMap[data.fileName]
              let hasSuccess = false

              await fetchUploadFileToS3Bucket({
                s3BucketUrl: data.uploadUrl,
                file: fileQueueItem.file,
                onSuccess: () => {
                  hasSuccess = true
                  if (fileQueueItems.length > succeededFileQueueItems.length) {
                    addSuccededFileQueueItem(fileQueueItem)
                  }
                },
                onError: () => {
                  hasSuccess = false
                  setFailedFailedNames((prev) => [
                    ...prev,
                    fileQueueItem.file.name,
                  ])
                },
              })

              return hasSuccess
            }

            return handleUploadFile(urlData, uploadFile)
          })
      ).then((uploadStatuses) => {
        if (getHasAllFileUploaded(uploadStatuses)) {
          onSuccessCallback?.()
          showSuccessToast(getToastMessage(fileQueueItems.length))
        }
      })
    },
  })

  const onSubmit = useCallback(
    async (values: Values) => {
      getUploadUrls({
        variables: {
          documents: fileQueueItems.map((item) => ({
            fileName: item.file.name,
            documentType: getOtherDocumentValue(
              values.fileItem?.[item.id]?.value
            ) as DocumentUploadedType,
            consentToViewDocument:
              values.fileItem?.[item.id]?.value ===
              DocumentUploadedType.ID_DOCUMENT,
          })),
          subject: values.subject || null,
        },
      })
      setPersistedSubject(values.subject ?? '')
      setIsLoading(true)
    },
    [fileQueueItems, getUploadUrls, setIsLoading, setPersistedSubject]
  )

  return { onSubmit }
}
