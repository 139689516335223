import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetItemsWithOneCategoryQueryVariables = Types.Exact<{
  categoryUuid: Types.Scalars['ID']['input']
}>

export type GetItemsWithOneCategoryQueryResponse = {
  __typename?: 'Query'
  getItemsWithOneCategory: {
    __typename?: 'ItemConnection'
    items: Array<{ __typename?: 'Item'; name: string; id: string }> | null
  } | null
}

export const GetItemsWithOneCategory = gql`
  query GetItemsWithOneCategory($categoryUuid: ID!) {
    getItemsWithOneCategory(categoryUuid: $categoryUuid) {
      items {
        name
        id
      }
    }
  }
` as unknown as TypedDocumentNode<
  GetItemsWithOneCategoryQueryResponse,
  GetItemsWithOneCategoryQueryVariables
>

/**
 * __useGetItemsWithOneCategoryQuery__
 *
 * To run a query within a React component, call `useGetItemsWithOneCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsWithOneCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsWithOneCategoryQuery({
 *   variables: {
 *      categoryUuid: // value for 'categoryUuid'
 *   },
 * });
 */
export function useGetItemsWithOneCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetItemsWithOneCategoryQueryResponse,
    GetItemsWithOneCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetItemsWithOneCategoryQueryResponse,
    GetItemsWithOneCategoryQueryVariables
  >(GetItemsWithOneCategory, options)
}
export function useGetItemsWithOneCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetItemsWithOneCategoryQueryResponse,
    GetItemsWithOneCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetItemsWithOneCategoryQueryResponse,
    GetItemsWithOneCategoryQueryVariables
  >(GetItemsWithOneCategory, options)
}
export type GetItemsWithOneCategoryQueryHookResult = ReturnType<
  typeof useGetItemsWithOneCategoryQuery
>
export type GetItemsWithOneCategoryLazyQueryHookResult = ReturnType<
  typeof useGetItemsWithOneCategoryLazyQuery
>
export type GetItemsWithOneCategoryQueryResult = Apollo.QueryResult<
  GetItemsWithOneCategoryQueryResponse,
  GetItemsWithOneCategoryQueryVariables
>
