import { ItemsImportFields } from '../ItemsImportModal.types'

const acceptableColumnHeaders = {
  name: ['item name', 'item', 'name'],
  description: ['description'],
  sku: ['sku'],
  category: ['category', 'categories'],
  price: ['price', 'amount'],
  gst: ['gst applicable', 'gst', 'tax applicable', 'tax'],
}

// This is a curried function that takes all parsed headers and match
// those headers to an acceptable header name for each attribute
const makeGetColumnName = (headers: string[]) => (key: ItemsImportFields) => {
  return (
    headers.find((headerName) => {
      return acceptableColumnHeaders[key].some((acceptableName) =>
        headerName?.trim().toLowerCase().includes(acceptableName.toLowerCase())
      )
    }) ?? ''
  )
}

export const getColumnHeaders = (headers: string[]) => {
  const getColumnName = makeGetColumnName(headers)

  return {
    name: getColumnName('name'),
    description: getColumnName('description'),
    sku: getColumnName('sku'),
    category: getColumnName('category'),
    price: getColumnName('price'),
    gst: getColumnName('gst'),
  }
}
