import { Dispatch, SetStateAction, useState } from 'react'
import { useMutation } from '@apollo/client'
import { showSuccessToast } from '@npco/zeller-design-system'
import { CreateCustomer } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/customer'
import {
  rvNewUserDetails,
  rvNewUserType,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { ApiCallType, useRedirectToMFA } from 'features/MFA'

import { AuthScope } from 'types/auth'
import { CustomerDetailsForm } from 'types/customers'
import {
  CreateCustomer as CreateCustomerResponse,
  CreateCustomerVariables,
} from 'types/gql-types/CreateCustomer'
import { page } from 'translations'

import { useCustomerUtils } from '../useCustomerUtils'
import {
  addCreatedCustomerToStorage,
  handleCustomerError,
  initialValuesCreate,
} from '../utils'

export const useCreateCustomer = (
  redirect: () => void,
  setIsPendingAssignSites: Dispatch<SetStateAction<boolean>>
) => {
  const { addCustomerToCache } = useCustomerUtils()

  const { redirectToMFA } = useRedirectToMFA()
  const [createCustomerInput, setCreateCustomerInput] =
    useState<CustomerDetailsForm>(initialValuesCreate)

  const [createCustomer, { loading: loadingCreateCustomer }] = useMutation<
    CreateCustomerResponse,
    CreateCustomerVariables
  >(CreateCustomer, {
    onCompleted: async (data) => {
      const customer = data.createCustomer?.[0]

      if (customer) {
        setIsPendingAssignSites(true)

        const newCustomer: CustomerDetailsForm & { __typename: 'Customer' } = {
          ...rvNewUserDetails(),
          __typename: 'Customer',
          isInvitationPending: true,
        }
        addCustomerToCache(customer.id, newCustomer)
        addCreatedCustomerToStorage(newCustomer, customer.id)
        setIsPendingAssignSites(false)
        rvNewUserType(null)
        redirect()
        showSuccessToast(page.settings.users.successCreateUser)
      }
    },
    onError: (err) => {
      handleCustomerError(err, redirectToMFA, {
        apiCallType: ApiCallType.CreateAdmin,
        scope: AuthScope.SENSITIVE,
        variables: createCustomerInput,
      })
    },
  })

  const submitValuesForMFA = (inputValues: CustomerDetailsForm) => {
    setCreateCustomerInput(inputValues)
  }

  return {
    createCustomer,
    loadingCreateCustomer,
    submitValuesForMFA,
  }
}
