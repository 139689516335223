import { useEffect, useMemo } from 'react'
import { NetworkStatus, useLazyQuery } from '@apollo/client'
import { EntityCategories } from '@npco/mp-gql-types'
import { GetSubcategories } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/subcategories'

import {
  GetSubcategories as GetSubcategoriesResponse,
  GetSubcategoriesVariables,
} from 'types/gql-types/GetSubcategories'

import { Subcategory } from './subcategories.types'

interface Props {
  category: EntityCategories | null
}

export const useSubcategories = ({ category }: Props) => {
  const [
    getSubcategories,
    { data, loading, error, refetch, networkStatus, called },
  ] = useLazyQuery<GetSubcategoriesResponse, GetSubcategoriesVariables>(
    GetSubcategories,
    {
      notifyOnNetworkStatusChange: true,
    }
  )

  useEffect(() => {
    if (category) {
      getSubcategories({
        variables: {
          category,
        },
      })
    }
  }, [getSubcategories, category])

  const subcategories = useMemo(
    () => data?.getEntitySubcategories?.filter(Boolean) || [],
    [data]
  ) as Subcategory[]

  return {
    subcategories,
    isLoading:
      loading ||
      networkStatus === NetworkStatus.refetch ||
      Boolean(!called && category),
    error,
    refetch,
  }
}
