import { gql } from '@apollo/client'

export const GetReportsLocalState = gql`
  query GetReportsLocalState {
    local @client {
      reports {
        filters {
          dates
          statuses
          terminals
          types
          sources
        }
        isFiltersVisible
      }
    }
  }
`
