import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { ItemDrawerFragmentDoc } from '../ItemDrawer.generated'

const defaultOptions = {} as const
export type GetItemDrawerQueryVariables = Types.Exact<{
  referenceNumber: Types.Scalars['String']['input']
}>

export type GetItemDrawerQueryResponse = {
  __typename?: 'Query'
  getItemV2: {
    __typename: 'Item'
    name: string
    sku: string | null
    price: number
    createdTime: number | null
    id: string
    referenceNumber: string | null
    description: string | null
    categories: Array<{
      __typename?: 'ItemCategory'
      name: string
      color: string
      id: string
    }> | null
    taxes: Array<{
      __typename?: 'ItemTax'
      enabled: boolean
      name: string
      percent: number | null
    }> | null
  }
}

export const GetItemDrawer = gql`
  query GetItemDrawer($referenceNumber: String!) {
    getItemV2(referenceNumber: $referenceNumber) {
      __typename
      name
      ...ItemDrawerFragment
    }
  }
  ${ItemDrawerFragmentDoc}
` as unknown as TypedDocumentNode<
  GetItemDrawerQueryResponse,
  GetItemDrawerQueryVariables
>

/**
 * __useGetItemDrawerQuery__
 *
 * To run a query within a React component, call `useGetItemDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemDrawerQuery({
 *   variables: {
 *      referenceNumber: // value for 'referenceNumber'
 *   },
 * });
 */
export function useGetItemDrawerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetItemDrawerQueryResponse,
    GetItemDrawerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetItemDrawerQueryResponse,
    GetItemDrawerQueryVariables
  >(GetItemDrawer, options)
}
export function useGetItemDrawerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetItemDrawerQueryResponse,
    GetItemDrawerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetItemDrawerQueryResponse,
    GetItemDrawerQueryVariables
  >(GetItemDrawer, options)
}
export type GetItemDrawerQueryHookResult = ReturnType<
  typeof useGetItemDrawerQuery
>
export type GetItemDrawerLazyQueryHookResult = ReturnType<
  typeof useGetItemDrawerLazyQuery
>
export type GetItemDrawerQueryResult = Apollo.QueryResult<
  GetItemDrawerQueryResponse,
  GetItemDrawerQueryVariables
>
