import { useTranslations } from '@npco/utils-translations'
import {
  BodyDefault,
  Box,
  Flex,
  ModalBasic,
  Subtitle,
} from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { MultiStageModal } from 'components/MultiStageModal'
import { translationsShared } from 'translations'

import { useItemsImportModalStage } from '../hooks/useItemsImportModalStage'
import { ItemsImport, ItemsImportData } from '../ItemsImportModal.types'
import { useItemsImportModalMatch } from './hooks/useItemsImportModalMatch'
import { translations } from './ItemsImportModalMatch.i18n'
import { getColumnHeaders } from './ItemsImportModalMatch.utils'
import { ItemsImportModalMatchField as Field } from './ItemsImportModalMatchField'
import { ItemsImportModalMatchInfoBox } from './ItemsImportModalMatchInfoBox'

const excludedColumnNames = [
  'Date Created (System Generated)',
  'Item ID (System Generated)',
]
interface ItemsImportModalMatchProps {
  items: ItemsImport | null
}

export const ItemsImportModalMatch = ({
  items,
}: ItemsImportModalMatchProps) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const { goToUploadStage } = useItemsImportModalStage()
  const { handleFormSubmit, validateForm } = useItemsImportModalMatch({ items })

  if (!items) {
    return null
  }

  const { headers, rows } = items

  const filteredHeaders = headers.filter(
    (column): column is string =>
      !!column && !excludedColumnNames.includes(column)
  )

  const initialValues = getColumnHeaders(filteredHeaders)

  return (
    <Formik<ItemsImportData>
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnMount
      validate={validateForm(headers, rows)}
    >
      {({ errors, handleSubmit, isSubmitting }) => (
        <>
          <ModalBasic.Body>
            <Box mb="24px">
              <BodyDefault as="div">{t('description')}</BodyDefault>
            </Box>
            <Flex mb="18px">
              <Flex flex={1}>
                <Subtitle as="div">{t('column1')}</Subtitle>
              </Flex>
              <Flex flex={1}>
                <Subtitle as="div">{t('column2')}</Subtitle>
              </Flex>
            </Flex>
            <Field
              label={t('nameLabel')}
              isLoading={isSubmitting}
              items={filteredHeaders}
              name="name"
            />
            <Field
              label={t('descriptionLabel')}
              isLoading={isSubmitting}
              items={filteredHeaders}
              name="description"
            />
            <Field
              label={t('skuLabel')}
              isLoading={isSubmitting}
              items={filteredHeaders}
              name="sku"
            />
            <Field
              label={t('categoryLabel')}
              isLoading={isSubmitting}
              items={filteredHeaders}
              name="category"
            />
            <Field
              label={t('priceLabel')}
              isLoading={isSubmitting}
              items={filteredHeaders}
              name="price"
            />
            <Field
              isLoading={isSubmitting}
              isLastField
              label={t('gstLabel')}
              items={filteredHeaders}
              name="gst"
            />
          </ModalBasic.Body>
          <ItemsImportModalMatchInfoBox errors={errors} />
          <MultiStageModal.FooterButtons
            nextButtonLabel={tShared('confirm')}
            onConfirm={handleSubmit}
            onCancel={goToUploadStage}
            isLoading={isSubmitting}
            isCancelDisabled={isSubmitting}
          />
        </>
      )}
    </Formik>
  )
}
