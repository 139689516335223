import { useCallback } from 'react'
import { useHistory } from 'react-router'
import {
  DecisionModal,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { useInvoiceAnalytics } from 'features/Invoicing/components/Invoices/hooks/useInvoiceAnalytics'

import { ROUTE } from 'const/routes'
import { translate } from 'utils/translations'
import { AnalyticsEventNames } from 'services/Analytics/events'

import { useDeleteInvoice } from '../hooks/useDeleteInvoice'
import { useInvoiceDecisionModal } from '../hooks/useInvoiceDecisionModal'
import { InvoiceDecisionModalTypes } from '../InvoiceDrawerDetails.utils'

export const translations = {
  confirm: translate('shared.confirm'),
  cancel: translate('shared.cancel'),
  title: translate(
    'component.invoiceDrawer.decisionModals.deleteInvoice.title'
  ),
  body: (referenceNumber: string) =>
    translate('component.invoiceDrawer.decisionModals.deleteInvoice.body', {
      referenceNumber,
    }),
  successToast: (referenceNumber: string) =>
    translate('component.invoiceDrawer.decisionModals.deleteInvoice.success', {
      referenceNumber,
    }),
  errorToast: (referenceNumber: string) =>
    translate('component.invoiceDrawer.decisionModals.deleteInvoice.error', {
      referenceNumber,
    }),
}

export const DeleteInvoiceModal = () => {
  const {
    activeModal,
    closeModal,
    details: { id, referenceNumber },
  } = useInvoiceDecisionModal()
  const { deleteInvoice, isDeletingInvoice } = useDeleteInvoice()
  const { push } = useHistory()
  const { trackInvoiceAction } = useInvoiceAnalytics(referenceNumber, id)

  const handleDeleteInvoice = useCallback(async () => {
    trackInvoiceAction(AnalyticsEventNames.INVOICE_DELETED)
    try {
      const response = await deleteInvoice(referenceNumber, () =>
        push(ROUTE.PORTAL_INVOICES_INVOICE_LIST)
      )

      if (!response.data?.deleteInvoice) {
        showErrorToast(translations.errorToast(referenceNumber))
        closeModal()
        return
      }

      showSuccessToast(translations.successToast(referenceNumber))
    } catch {
      showErrorToast(translations.errorToast(referenceNumber))
      closeModal()
    }
  }, [deleteInvoice, push, referenceNumber, closeModal, trackInvoiceAction])

  return (
    <DecisionModal
      isOpen={activeModal === InvoiceDecisionModalTypes.DeleteInvoice}
      isLoadingPrimary={isDeletingInvoice}
      onCancel={closeModal}
      onClickPrimary={handleDeleteInvoice}
      onClickSecondary={closeModal}
      primaryButtonLabel={translations.confirm}
      secondaryButtonLabel={translations.cancel}
      title={translations.title}
    >
      {translations.body(referenceNumber)}
    </DecisionModal>
  )
}
