import { useCallback } from 'react'
import {
  type GetInvoicesFilterInput,
  type GetInvoicesSortInput,
} from '@npco/mp-gql-types'
import { useGetInvoices } from 'features/Invoicing/components/Invoices/hooks/useGetInvoices'

import { DEFAULT_GET_LIMIT } from '../InvoiceTable/InvoiceTable.utils'

export interface UseInvoicesProps {
  filterInput: GetInvoicesFilterInput
  sortingObject: GetInvoicesSortInput | null
}

export const useInvoices = ({
  filterInput,
  sortingObject,
}: UseInvoicesProps) => {
  const {
    getInvoices,
    getInvoicesError,
    invoices,
    isLoadingInvoices,
    loadMore,
    nextToken,
    subscribeToMore,
    variables,
  } = useGetInvoices()

  const refetchInvoices = useCallback(async () => {
    getInvoices({
      variables: {
        input: {
          filter: filterInput,
          limit: DEFAULT_GET_LIMIT,
          sort: sortingObject?.columnName
            ? {
                ascending: sortingObject.ascending,
                columnName: sortingObject.columnName,
              }
            : null,
        },
      },
    })
  }, [filterInput, getInvoices, sortingObject])

  const hasNoInitialResults = invoices === undefined
  const hasNoResults = Boolean(invoices && invoices.length === 0)

  return {
    getInvoices,
    hasError: Boolean(getInvoicesError) && !invoices?.length,
    hasNoInitialResults,
    hasNoResults,
    invoices,
    isLoading: hasNoInitialResults || isLoadingInvoices,
    loadMore,
    nextToken,
    refetchInvoices,
    subscribeToMore,
    variables,
  }
}
