import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type AbortSimCancellationMutationVariables = Types.Exact<{
  simId: Types.Scalars['ID']['input']
}>

export type AbortSimCancellationMutationResponse = {
  __typename?: 'Mutation'
  abortSimCancellation: boolean
}

export const AbortSimCancellationMutation = gql`
  mutation AbortSimCancellationMutation($simId: ID!) {
    abortSimCancellation(simId: $simId)
  }
` as unknown as TypedDocumentNode<
  AbortSimCancellationMutationResponse,
  AbortSimCancellationMutationVariables
>
export type AbortSimCancellationMutationMutationFn = Apollo.MutationFunction<
  AbortSimCancellationMutationResponse,
  AbortSimCancellationMutationVariables
>

/**
 * __useAbortSimCancellationMutation__
 *
 * To run a mutation, you first call `useAbortSimCancellationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAbortSimCancellationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [abortSimCancellationMutation, { data, loading, error }] = useAbortSimCancellationMutation({
 *   variables: {
 *      simId: // value for 'simId'
 *   },
 * });
 */
export function useAbortSimCancellationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AbortSimCancellationMutationResponse,
    AbortSimCancellationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AbortSimCancellationMutationResponse,
    AbortSimCancellationMutationVariables
  >(AbortSimCancellationMutation, options)
}
export type AbortSimCancellationMutationHookResult = ReturnType<
  typeof useAbortSimCancellationMutation
>
export type AbortSimCancellationMutationMutationResult =
  Apollo.MutationResult<AbortSimCancellationMutationResponse>
export type AbortSimCancellationMutationMutationOptions =
  Apollo.BaseMutationOptions<
    AbortSimCancellationMutationResponse,
    AbortSimCancellationMutationVariables
  >
