import { ReactNode, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import {
  GetCustomer,
  GetCustomerQueryResponse,
  GetCustomerQueryVariables,
  useLoggedInUser,
} from '@npco/mp-utils-logged-in-user'
import {
  rvProfileFetchError,
  rvProfileSettings,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables/users'

import { SpinnerWrapped } from 'components/Spinner'

interface Props {
  children: ReactNode
}

export const ProfileDetailsFetcher = ({ children }: Props) => {
  const { userData } = useLoggedInUser()
  const customerUuid = useMemo(() => userData?.id as string, [userData])

  const { loading } = useQuery<
    GetCustomerQueryResponse,
    GetCustomerQueryVariables
  >(GetCustomer, {
    fetchPolicy: 'network-only',
    variables: {
      customerUuid,
    },
    onCompleted: (customerData) => {
      if (customerData?.getCustomer) {
        rvProfileSettings(customerData.getCustomer)
        rvProfileFetchError(null)
      }
    },
    onError: rvProfileFetchError,
  })

  if (loading) {
    return <SpinnerWrapped variant="centre" />
  }

  return <>{children}</>
}
