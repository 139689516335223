import { useEffect, useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GetDevicePosSettings } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/devices'
import { GetTransactionsTimestamp } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/transactions'

import {
  GetDevicePosSettings as GetDevicePosSettingsType,
  GetDevicePosSettings_getDeviceSettings_posSettings as PosSettingsType,
  GetDevicePosSettingsVariables,
} from 'types/gql-types/GetDevicePosSettings'
import {
  GetTransactionsTimestamp as GetTransactionsTimestampType,
  GetTransactionsTimestampVariables,
} from 'types/gql-types/GetTransactionsTimestamp'

export const useGetPosDetails = (terminalUuid: string) => {
  const [getPosDetails, { loading, error, data }] = useLazyQuery<
    GetDevicePosSettingsType,
    GetDevicePosSettingsVariables
  >(GetDevicePosSettings, {
    variables: {
      deviceUuid: terminalUuid,
    },
  })

  useEffect(() => {
    if (terminalUuid) {
      getPosDetails()
    }
  }, [getPosDetails, terminalUuid])
  const posDetails: PosSettingsType = useMemo(() => {
    const posSettings = data?.getDeviceSettings?.posSettings

    return {
      posSoftwareName: posSettings?.posSoftwareName ?? '',
      posRegisterName: posSettings?.posRegisterName ?? '',
      exitRequiresPin: Boolean(posSettings?.exitRequiresPin),
      posReceipt: Boolean(posSettings?.posReceipt),
      customIdleText: posSettings?.customIdleText ?? '',
      customIdleTextUsed: Boolean(posSettings?.customIdleTextUsed),
      connectionMethod: posSettings?.connectionMethod || null,
      posVenue: posSettings?.posVenue || null,
      active: posSettings?.active || null,
      hlSettings: posSettings?.hlSettings || null,
      oracleSettings: posSettings?.oracleSettings || null,
    }
  }, [data])

  return {
    error,
    isLoading: loading,
    getPosDetails,
    posDetails,
  }
}

export const useGetPosLastTransaction = (terminalUuid: string) => {
  const [getPosLastTransaction, { loading, error, data }] = useLazyQuery<
    GetTransactionsTimestampType,
    GetTransactionsTimestampVariables
  >(GetTransactionsTimestamp, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        deviceUuid: { eq: terminalUuid },
        source: { in: ['LINKLY', 'QUICKPOS'] },
      },
      limit: 1,
    },
  })

  useEffect(() => {
    if (terminalUuid) {
      getPosLastTransaction()
    }
  }, [getPosLastTransaction, terminalUuid])

  const lastTransaction: string | null = useMemo(() => {
    const transactions = data?.getTransactions?.transactions

    return transactions?.length ? transactions[0]?.timestamp : null
  }, [data])

  return {
    error,
    isLoading: loading,
    getPosLastTransaction,
    lastTransaction,
  }
}
