import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type CreateItemCategoryMutationVariables = Types.Exact<{
  category: Types.CreateItemCategoryInput
}>

export type CreateItemCategoryMutationResponse = {
  __typename?: 'Mutation'
  createItemCategory: { __typename: 'ItemCategory'; id: string; name: string }
}

export const CreateItemCategory = gql`
  mutation CreateItemCategory($category: CreateItemCategoryInput!) {
    createItemCategory(input: $category) {
      __typename
      id
      name
    }
  }
` as unknown as TypedDocumentNode<
  CreateItemCategoryMutationResponse,
  CreateItemCategoryMutationVariables
>
export type CreateItemCategoryMutationFn = Apollo.MutationFunction<
  CreateItemCategoryMutationResponse,
  CreateItemCategoryMutationVariables
>

/**
 * __useCreateItemCategoryMutation__
 *
 * To run a mutation, you first call `useCreateItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemCategoryMutation, { data, loading, error }] = useCreateItemCategoryMutation({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useCreateItemCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateItemCategoryMutationResponse,
    CreateItemCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateItemCategoryMutationResponse,
    CreateItemCategoryMutationVariables
  >(CreateItemCategory, options)
}
export type CreateItemCategoryMutationHookResult = ReturnType<
  typeof useCreateItemCategoryMutation
>
export type CreateItemCategoryMutationResult =
  Apollo.MutationResult<CreateItemCategoryMutationResponse>
export type CreateItemCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateItemCategoryMutationResponse,
  CreateItemCategoryMutationVariables
>
