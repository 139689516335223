import { gql, type TypedDocumentNode } from '@apollo/client'

import { CategoryDeleteModalFragmentDoc } from './CategoryDeleteModal/CategoryDeleteModal.generated'
import { CategoryDrawerDescriptionFragmentDoc } from './CategoryDrawerDescription/CategoryDrawerDescription.generated'

export type CategoryDrawerDetailsFragment = {
  __typename?: 'ItemCategory'
  color: string
  id: string
  createdTime: number | null
  description: string | null
  name: string
}

export const CategoryDrawerDetailsFragmentDoc = gql`
  fragment CategoryDrawerDetailsFragment on ItemCategory {
    color
    id
    createdTime
    ...CategoryDrawerDescriptionFragment
    ...CategoryDeleteModalFragment
  }
  ${CategoryDrawerDescriptionFragmentDoc}
  ${CategoryDeleteModalFragmentDoc}
` as unknown as TypedDocumentNode<CategoryDrawerDetailsFragment, undefined>
