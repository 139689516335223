import { useGetItemsTableLazyQuery } from './graphql/getItemsTableQuery.generated'

export const useGetItemsTable = (
  hookOptions: Parameters<typeof useGetItemsTableLazyQuery>[0] = {}
) => {
  const [getQuery, { data, fetchMore, loading: isLoading, error }] =
    useGetItemsTableLazyQuery(hookOptions)

  const items = data?.getItems.items
  const nextToken = data?.getItems.nextToken

  const hasError = Boolean(error)
  const hasNoInitialResults = items === undefined
  const hasNoResults = Boolean(items && items.length === 0)

  return {
    getItems: getQuery,
    hasError,
    hasMore: Boolean(nextToken),
    hasNoInitialResults,
    hasNoResults,
    isLoading,
    items,
    loadMore: fetchMore,
    nextToken,
  }
}
