import { useCallback } from 'react'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'

import { UNEXPECTED_ERROR } from '../../../../const/errors'
import { cacheNewSavingsAccount } from '../../utils/cacheNewSavingsAccount'
import {
  NewSavingsAccountFragment,
  NewSavingsAccountFragmentDoc,
} from '../useActivateSavingsAccount/graphql/NewSavingsAccountFragment.generated'
import { useActivateSavingsAccountEncryptedMutation } from './graphql/ActivateSavingsAccountEncrypted.generated'

type ActivateSavingsAccountArgs = {
  encryptedTfn: string
  encryptedTfnMac: string
  secureSessionId: string
}

export const useActivateSavingsAccountEncrypted = () => {
  const [activateSavingsAccountEncryptedMutation, { loading }] =
    useActivateSavingsAccountEncryptedMutation({
      update: (cache, result) => {
        if (result.data?.createSavingsAccountV3.id) {
          cache.writeFragment<NewSavingsAccountFragment>({
            fragment: NewSavingsAccountFragmentDoc,
            id: cache.identify({
              __typename: 'DebitCardAccountV2',
              id: result.data.createSavingsAccountV3.id,
            }),
            data: {
              ...result.data.createSavingsAccountV3,
              __typename: 'DebitCardAccountV2',
              type: DebitCardAccountType.SAVINGS,
              cardsCount: {
                debit: 0,
                expense: 0,
                total: 0,
                __typename: 'DebitCardAccountV2CardsCount',
              },
              status: DebitCardAccountStatus.ACTIVE,
            },
          })
          cacheNewSavingsAccount(
            cache,
            {
              ...result.data.createSavingsAccountV3,
              __typename: 'DebitCardAccountV2',
              type: DebitCardAccountType.SAVINGS,
              cardsCount: {
                debit: 0,
                expense: 0,
                total: 0,
                __typename: 'DebitCardAccountV2CardsCount',
              },
              status: DebitCardAccountStatus.ACTIVE,
            },
            'start-of-list'
          )
        }
      },
    })

  const activateSavingsAccountEncrypted = useCallback(
    async ({
      encryptedTfn,
      encryptedTfnMac,
      secureSessionId,
    }: ActivateSavingsAccountArgs) => {
      try {
        const result = await activateSavingsAccountEncryptedMutation({
          variables: {
            input: {
              ciphertextTfn: encryptedTfn,
              ciphertextTfnMac: encryptedTfnMac,
              secureSessionId,
            },
          },
        })

        if (!result.data?.createSavingsAccountV3.id) {
          return UNEXPECTED_ERROR
        }

        return result.data.createSavingsAccountV3
      } catch (err) {
        return UNEXPECTED_ERROR
      }
    },
    [activateSavingsAccountEncryptedMutation]
  )

  return {
    activateSavingsAccountEncrypted,
    isActivatingSavingsAccountEncrypted: loading,
  }
}
