import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import { ROUTE } from 'const/routes'

interface GoToTransferOptions {
  transferFromAccountId?: string
}

export const useGoToTransfer = () => {
  const navigate = useNavigate()

  const goToTransfer = useCallback(
    ({ transferFromAccountId }: GoToTransferOptions = {}) => {
      navigate(ROUTE.PORTAL_ACCOUNTS_TRANSFER_CREATE, {
        state: {
          initialDropdownAccountId: transferFromAccountId,
        },
      })
    },
    [navigate]
  )

  return { goToTransfer }
}
