import { gql } from '@apollo/client'

export const CreateInterimBankingAccountStatement = gql`
  mutation CreateInterimBankingAccountStatement(
    $debitCardAccountUuid: String!
    $type: AccountStatementType!
  ) {
    createInterimBankingAccountStatement(
      debitCardAccountUuid: $debitCardAccountUuid
      type: $type
    ) {
      downloadLink
    }
  }
`
