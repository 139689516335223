import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { currencyFormatter } from '@npco/component-mp-common'
import { useTranslations } from '@npco/utils-translations'
import { Divider, Flex } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { formatAccountNumber } from 'utils/common'
import { DefaultRow } from 'pages/Transfer/TransferModal/components/DefaultRow/DefaultRow'
import { SectionWrapper } from 'pages/Transfer/TransferModal/TransferModal.styled'

import { ROOT } from '../../../../../../const/routes'
import { page } from '../../../../../../translations'
import { TransferFieldTypes } from '../../../../Transfer.types'
import {
  formatTimeDate,
  INTERNAL_TRANSFER_MODAL_HEIGHT,
} from '../../../../Transfer.utils'
import { TransferConfirmationModal } from '../../../../TransferConfirmationModal/TransferConfirmationModal'
import { useInternalTransferModal } from '../../../internal-transfer-routing'
import { TransactionDetails } from '../../../internal-transfer-routing/InternalTransfer.types'
import { completeStageTranslations } from './CompleteStage.i18n'

type CompleteStageProps = {
  transferDetails: TransactionDetails
  transactionTimestamp?: string
}

export const CompleteStage = ({
  transferDetails,
  transactionTimestamp,
}: CompleteStageProps) => {
  const { from, to, amount, reference } = transferDetails
  const t = useTranslations(completeStageTranslations)
  const history = useHistory()
  const { resetForm } = useFormikContext<TransferFieldTypes>()
  const { closeInternalTransferModal } = useInternalTransferModal()

  const handleNewTransfer = useCallback(() => {
    resetForm()
    closeInternalTransferModal()
  }, [closeInternalTransferModal, resetForm])

  const handleClose = useCallback(() => {
    closeInternalTransferModal()
    history.push(ROOT.PORTAL.ACCOUNTS.TRANSACTIONS.path)
  }, [history, closeInternalTransferModal])

  return (
    <TransferConfirmationModal
      decisionModalProps={{
        isOpen: true,
        secondaryButtonLabel: t('secondaryButtonLabel'),
        primaryButtonLabel: t('primaryButtonLabel'),
        onCancel: handleNewTransfer,
        onClickSecondary: handleNewTransfer,
        onClickPrimary: handleClose,
      }}
      paddingTop="84px"
      paddingBottom="84px"
      modalMinHeight={INTERNAL_TRANSFER_MODAL_HEIGHT}
      showRemittancesButtons={false}
      transactionID=""
    >
      <Flex flexDirection="column" pt="16px">
        <SectionWrapper aria-label="sender details">
          <DefaultRow
            label={page.accounts.transactionsList.filters.amount}
            value={currencyFormatter(+amount * 100)}
          />
          <DefaultRow
            label={page.accounts.transactionsList.filters.date}
            value={formatTimeDate(transactionTimestamp)}
          />
          {from.name && from.accountNumber && (
            <DefaultRow
              label={page.transfer.from}
              value={`${from.name} · ${formatAccountNumber(
                from.accountNumber
              )}`}
            />
          )}
        </SectionWrapper>
        <Divider margin="20px 0" />
        <SectionWrapper aria-label="Recipient details">
          {to.name && to.accountNumber && (
            <DefaultRow
              label={page.transfer.to}
              value={`${to.name} · ${formatAccountNumber(to.accountNumber)}`}
            />
          )}
          <DefaultRow
            label={page.transactionDetails.reference}
            value={reference}
          />
        </SectionWrapper>
      </Flex>
    </TransferConfirmationModal>
  )
}
