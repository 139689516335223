import { Flex, PageTemplate } from '@npco/zeller-design-system'

import { BreadcrumbsBase } from 'components/Breadcrumbs/BreadcrumbsBase'

import { useItemsBreadcrumbs } from '../../hooks/useItemsBreadcrumbs'

type ItemsSettingsLayoutProps = {
  children: React.ReactNode
}

export const ItemsSettingsLayout = ({ children }: ItemsSettingsLayoutProps) => {
  const breadcrumbParts = useItemsBreadcrumbs()
  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex alignItems="center" height="48px">
            <BreadcrumbsBase breadcrumbParts={breadcrumbParts} />
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      {children}
    </PageTemplate>
  )
}
