import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type GetItemsTableCoreFieldsFragment = {
  __typename: 'Item'
  id: string
  referenceNumber: string | null
  name: string
  sku: string | null
  price: number
  status: Types.ItemStatus | null
  categories: Array<{
    __typename?: 'ItemCategory'
    color: string
    id: string
    name: string
  }> | null
  taxes: Array<{
    __typename?: 'ItemTax'
    enabled: boolean
    name: string
    percent: number | null
  }> | null
}

export const GetItemsTableCoreFieldsDoc = gql`
  fragment GetItemsTableCoreFields on Item {
    __typename
    id
    referenceNumber
    categories {
      color
      id
      name
    }
    name
    sku
    price
    status
    taxes {
      enabled
      name
      percent
    }
  }
` as unknown as TypedDocumentNode<GetItemsTableCoreFieldsFragment, undefined>
