import { useNavigate } from 'react-router-dom-v5-compat'
import { ContactType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  ButtonLink,
  DecisionModal,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'

import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { ContactType as AnalyticsContactType } from 'services/Analytics/events/contact'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import { shared } from 'translations'

import { getContactName } from '../../../Contacts.utils'
import { useDeleteContact } from '../../../hooks/useDeleteContact/useDeleteContact'
import {
  rvAddedContacts,
  rvContacts,
  rvSelectedContact,
} from '../../../rv-deprecated/contacts'
import { translations } from './DeleteContact.i18n'

export const DeleteContact = ({
  linkedInvoicesLength,
}: {
  linkedInvoicesLength: number
}) => {
  const { trackAnalyticsEvent } = useAnalyticsLogger()
  const t = useTranslations(translations)

  const selectedContact = rvSelectedContact()
  const { isModalOpen, openModal, closeModal } = useModalState()
  const { deleteContact, isDeletingContact } = useDeleteContact()
  const navigate = useNavigate()

  if (!selectedContact) {
    return null
  }

  const { contactType, id: contactId } = selectedContact
  const contactName = getContactName(selectedContact)

  const isBusinessContactType = contactType === ContactType.BUSINESS

  const contactTypeCopy = isBusinessContactType ? 'Business' : 'Person'

  const handleDeleteContact = async () => {
    try {
      const response = await deleteContact(contactId)

      if (!response.data?.deleteContact) {
        showErrorToast(
          translate('page.contacts.notifications.deleteFailMessage', {
            contactName,
          })
        )
        return
      }

      trackAnalyticsEvent(AnalyticsEventNames.CONTACT_DELETED, {
        Type: isBusinessContactType
          ? AnalyticsContactType.Business
          : AnalyticsContactType.Person,
      })

      const reactiveContacts = rvContacts()

      if (reactiveContacts) {
        // NOTE: We set the rv contact to null so we know which contacts have been
        // deleted without creating another rvDeletedContacts for it
        const contactDeleted = { ...reactiveContacts, [contactId]: null }
        rvContacts(contactDeleted)
        rvAddedContacts(
          rvAddedContacts().filter((contactIds) => contactIds !== contactId)
        )
      }

      closeModal()

      showSuccessToast(
        translate('page.contacts.notifications.deleteSuccessMessage', {
          contactName,
        })
      )
      navigate(
        isBusinessContactType
          ? ROOT.PORTAL.CONTACTS.BUSINESSES.path
          : ROOT.PORTAL.CONTACTS.PEOPLE.path
      )

      rvSelectedContact(null)
    } catch (e) {
      showErrorToast(
        translate('page.contacts.notifications.deleteFailMessage', {
          contactName,
        })
      )
    }
  }

  return (
    <Box mt="2.5rem" marginBottom="0.25rem">
      <ButtonLink data-testid="contact-delete" onClick={() => openModal()}>
        {translate('page.contacts.form.deleteContact')}
      </ButtonLink>
      <DecisionModal
        title={t('title', { contactType: contactTypeCopy })}
        primaryButtonLabel={shared.confirm}
        secondaryButtonLabel={shared.cancel}
        isOpen={isModalOpen}
        isLoadingPrimary={isDeletingContact}
        onCancel={closeModal}
        onClickPrimary={handleDeleteContact}
        onClickSecondary={closeModal}
        hasCloseButton
      >
        <div data-testid="delete-contact-body">
          {linkedInvoicesLength === 0 &&
            t('confirmationMessage', {
              contactType: contactTypeCopy,
            })}
          {linkedInvoicesLength === 1 &&
            t('confirmationMessageLinkedInvoicesSingular', {
              contactType: contactTypeCopy,
            })}
          {linkedInvoicesLength > 1 &&
            t('confirmationMessageLinkedInvoices', {
              contactType: contactTypeCopy,
              draftNumber: linkedInvoicesLength,
            })}
        </div>
      </DecisionModal>
    </Box>
  )
}
