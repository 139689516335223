import { useCallback } from 'react'
import { LazyQueryHookOptions, QueryLazyOptions } from '@apollo/client'
import { CatalogItemType } from '@npco/mp-gql-types'
import { GetCatalogItemsQueryVariables } from 'features/Items/components/Catalogue/CatalogueTable/graphql/getCatalogItems.generated'

import {
  GetInvoiceItemsQueryResponse,
  GetInvoiceItemsQueryVariables,
  useGetInvoiceItemsLazyQuery,
} from './graphql/getInvoiceItems.generated'

export const DEFAULT_INVOICE_ITEMS_GET_LIMIT = 25

export const optionsWithItemTypeFilter = (
  options:
    | LazyQueryHookOptions<
        GetInvoiceItemsQueryResponse,
        GetInvoiceItemsQueryVariables
      >
    | QueryLazyOptions<GetInvoiceItemsQueryVariables>
    | undefined
) => ({
  ...options,
  variables: {
    ...options?.variables,
    input: {
      limit: DEFAULT_INVOICE_ITEMS_GET_LIMIT,
      ...options?.variables?.input,
      filter: {
        ...options?.variables?.input?.filter,
        itemTypesFilter: [CatalogItemType.SINGLE, CatalogItemType.VARIANT],
      },
    },
  },
})

export const useGetItems = (
  hookOptions?: LazyQueryHookOptions<
    GetInvoiceItemsQueryResponse,
    GetInvoiceItemsQueryVariables
  >
) => {
  const [getQuery, { data, fetchMore, loading: isLoading }] =
    useGetInvoiceItemsLazyQuery(optionsWithItemTypeFilter(hookOptions))

  const items = data?.getCatalogItems.items
  const nextToken = data?.getCatalogItems.nextToken

  const getItems = useCallback(
    (queryOptions?: QueryLazyOptions<GetCatalogItemsQueryVariables>) => {
      getQuery(optionsWithItemTypeFilter(queryOptions))
    },
    [getQuery]
  )

  const loadMore = useCallback(() => {
    fetchMore?.(
      optionsWithItemTypeFilter({
        variables: {
          input: { limit: DEFAULT_INVOICE_ITEMS_GET_LIMIT, nextToken },
        },
      })
    )
  }, [fetchMore, nextToken])

  return {
    getItems,
    hasMore: Boolean(nextToken),
    isLoading,
    items,
    loadMore,
    nextToken,
  }
}
