import {
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat'
import { DrawerBasic, SkeletonBasic, Tabs } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'

import { CategoryDrawerDetails } from './CategoryDrawerDetails/CategoryDrawerDetails'
import { CategoryEditModal } from './CategoryDrawerDetails/CategoryEditModal/CategoryEditModal'
import { useGetItemCategoryQuery } from './CategoryDrawerDetails/graphql/getItemCategory.generated'
import { CategoryDrawerItems } from './CategoryDrawerItems/CategoryDrawerItems'
import { CategoryDrawerLoader } from './CategoryDrawerLoader'
import { useCategoryDrawerTabs } from './hooks/useCategoryDrawerTabs'

const {
  relative: categoryPath,
  EDIT: { relative: categoryEditPath },
  ITEMS: { relative: categoryItemsPath },
} = ROOT.PORTAL.ITEM_MANAGEMENT.CATEGORIES.CATEGORY()

export const CategoryDrawer = () => {
  const navigate = useNavigate()
  const { categoryId = '' } = useParams()

  const { data, loading: isLoading } = useGetItemCategoryQuery({
    variables: { categoryUuid: categoryId },
    skip: !categoryId,
  })

  const { tabs, currentTab } = useCategoryDrawerTabs({
    categoryId,
    isLoading,
    itemCount: data?.getItemCategory.itemsNumber ?? 0,
  })

  const handleClose = () => {
    navigate(ROOT.PORTAL.ITEM_MANAGEMENT.CATEGORIES.path)
  }

  return (
    <DrawerBasic
      isOpen={Boolean(categoryId)}
      onClose={handleClose}
      title={
        isLoading ? (
          <SkeletonBasic height={20} width={201} borderRadius={10} />
        ) : (
          data?.getItemCategory.name || ' '
        )
      }
      headerContent={
        <Tabs currentTab={currentTab}>
          {tabs.map((tab) => (
            <Tabs.Item key={tab.value} {...tab} />
          ))}
        </Tabs>
      }
      overlayClassName="animated-drawer-overlay"
    >
      {isLoading && <CategoryDrawerLoader />}
      {!isLoading && data?.getItemCategory && (
        <Routes>
          <Route
            path={`${categoryPath}/${categoryItemsPath}`}
            element={<CategoryDrawerItems categoryId={categoryId} />}
          />

          <Route
            path={categoryPath}
            element={<CategoryDrawerDetails {...data.getItemCategory} />}
          >
            <Route
              path={categoryEditPath}
              element={
                <CategoryEditModal
                  categoryId={categoryId}
                  initialValues={{
                    color: data.getItemCategory.color,
                    name: data.getItemCategory.name,
                  }}
                />
              }
            />
          </Route>
        </Routes>
      )}
    </DrawerBasic>
  )
}
