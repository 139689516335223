import { ROUTE } from 'const/routes'
import { errorMessages } from 'translations'

import { StyledLink } from './EmailInUseError.styled'

export const EmailInUseError = () => {
  return (
    <>
      {errorMessages.emailInUse.message}
      <StyledLink
        to={ROUTE.LOGIN}
        aria-label={errorMessages.emailInUse.linkLabel}
      >
        {errorMessages.emailInUse.link}
      </StyledLink>
      {errorMessages.emailInUse.linkAction}
    </>
  )
}
