import { useMutation } from '@apollo/client'
import { DeleteEntityTagMutation } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/entityTags'
import { GetEntityTags } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/entityTags'

import {
  DeleteEntityTag as DeleteEntityTagResponse,
  DeleteEntityTagVariables,
} from 'types/gql-types/DeleteEntityTag'
import { GetEntityTags as GetEntityTagsType } from 'types/gql-types/GetEntityTags'

export const useDeleteEntityTag = () => {
  const [deleteEntityTag, { loading: isDeletingEntityTag }] = useMutation<
    DeleteEntityTagResponse,
    DeleteEntityTagVariables
  >(DeleteEntityTagMutation)

  return {
    isDeletingEntityTag,
    deleteEntityTag: async (tag: string) => {
      return deleteEntityTag({
        variables: { tag },
        update: (cache, result) => {
          if (result.data?.removeEntityTag) {
            const data = cache.readQuery<GetEntityTagsType>({
              query: GetEntityTags,
            })
            cache.writeQuery<GetEntityTagsType>({
              query: GetEntityTags,
              data: {
                getEntityTags:
                  data?.getEntityTags?.filter(
                    (cachedTag) => cachedTag !== tag
                  ) || [],
              },
            })
          }
        },
      })
    },
  }
}
