import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  heading: 'Details',
  delete: 'Delete Item',
  category: 'Category',
  categoryLabel: 'Category ({count})',
  price: 'Price',
  sku: 'SKU',
  defaultGST: 'Default GST',
  dateCreated: 'Date Created',
  itemId: 'Item ID',
  description: 'Description',
  priceTooltipGstIncluded:
    'Based on your Item Settings, GST is included by default.',
  priceTooltipGstExcluded:
    'Based on your Item Settings, GST is excluded by default.',
  applicable: 'Applicable',
  notApplicable: 'Not applicable',
})
