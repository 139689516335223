import React from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { AsideLayout } from '@npco/ui-aside-layout'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { ROOT } from 'const/routes'
import { BREAKPOINT } from 'styles/breakpoints'
import { Sidebar } from 'components/Sidebar'
import { MobileNavHeader } from 'components/Sidebar/components/MobileNavHeader/MobileNavHeader'

import { useSidebarLocalCache } from '../../components/Sidebar/hooks/useSidebarLocalCache'
import { OverviewBanner } from '../../pages/Dashboard/DashboardLayout/OverviewBanner/OverviewBanner'
import {
  StyledFullWidthBackgroundContainer,
  StyledMaxWidthContainer,
} from './DashboardLayout.styled'

interface DashboardLayoutProps {
  isLoadingCMS?: boolean
  bannerSlug?: string | null
  children: React.ReactNode
}

export const DashboardLayout = ({
  isLoadingCMS,
  bannerSlug,
  children,
}: DashboardLayoutProps) => {
  const { isFolded } = useSidebarLocalCache()
  const flags = useFeatureFlags()
  const { pathname } = useLocation()

  const isMobileResolution = useIsMobileResolution(BREAKPOINT.MD)
  const shouldBannerHide =
    ((isMobileResolution || flags.BannerInOverviewPageOnly) &&
      pathname !== ROOT.PORTAL.OVERVIEW.PAYMENTS.path) ||
    isLoadingCMS

  return (
    <StyledFullWidthBackgroundContainer>
      <StyledMaxWidthContainer>
        <AsideLayout isSideBarOpen={!isFolded} aside={<Sidebar />} isFullWidth>
          {isMobileResolution && <MobileNavHeader />}
          {!shouldBannerHide && <OverviewBanner bannerSlug={bannerSlug} />}
          {children}
        </AsideLayout>
      </StyledMaxWidthContainer>
    </StyledFullWidthBackgroundContainer>
  )
}
