import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type DeleteItemMutationVariables = Types.Exact<{
  referenceNumber: Types.Scalars['String']['input']
}>

export type DeleteItemMutationResponse = {
  __typename?: 'Mutation'
  deleteItem: {
    __typename: 'Item'
    id: string
    referenceNumber: string | null
    status: Types.ItemStatus | null
  }
}

export const DeleteItem = gql`
  mutation DeleteItem($referenceNumber: String!) {
    deleteItem(referenceNumber: $referenceNumber) {
      __typename
      id
      referenceNumber
      status
    }
  }
` as unknown as TypedDocumentNode<
  DeleteItemMutationResponse,
  DeleteItemMutationVariables
>
export type DeleteItemMutationFn = Apollo.MutationFunction<
  DeleteItemMutationResponse,
  DeleteItemMutationVariables
>

/**
 * __useDeleteItemMutation__
 *
 * To run a mutation, you first call `useDeleteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemMutation, { data, loading, error }] = useDeleteItemMutation({
 *   variables: {
 *      referenceNumber: // value for 'referenceNumber'
 *   },
 * });
 */
export function useDeleteItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteItemMutationResponse,
    DeleteItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteItemMutationResponse,
    DeleteItemMutationVariables
  >(DeleteItem, options)
}
export type DeleteItemMutationHookResult = ReturnType<
  typeof useDeleteItemMutation
>
export type DeleteItemMutationResult =
  Apollo.MutationResult<DeleteItemMutationResponse>
export type DeleteItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteItemMutationResponse,
  DeleteItemMutationVariables
>
