import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  BUTTON_SIZE,
  ButtonFill,
  Flex,
  Heading,
  MagnifyingGlassNoResults as NoSearchResultsIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as NoInvoicesYetIcon } from 'assets/svg/invoices-welcome.svg'
import { ReactComponent as ErrorInvoicesIcon } from 'assets/svg/something-went-wrong-balance.svg'
import { ROUTE } from 'const/routes'

import { translations } from '../../Contacts.i18n'
import { useGetContactInvoices } from '../../hooks/useGetContactInvoices/useGetContactInvoices'
import { rvSelectedContact } from '../../rv-deprecated/contacts'
import { groupInvoicesByYear } from '../ContactTabs.utils'
import { ContactInvoicesTable } from './ContactInvoicesTable/ContactInvoicesTable'

const EmptyState = ({
  CallToAction,
  description,
  heading,
  Icon,
}: {
  CallToAction?: React.ReactNode
  description: React.ReactNode
  heading: string
  Icon?: React.ReactNode
}) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      height="100%"
      justifyContent="start"
      padding="88px 24px 24px 24px"
    >
      <Box aria-hidden="true" paddingBottom="32px">
        {Icon}
      </Box>

      <Heading.H2 paddingBottom="16px">{heading}</Heading.H2>

      <Box maxWidth="570px" textAlign="center" paddingBottom="16px">
        {description}
      </Box>

      {CallToAction}
    </Flex>
  )
}

export const ContactInvoices = ({
  hasSearched = false,
}: {
  hasSearched?: boolean
}) => {
  const contact = useReactiveVar(rvSelectedContact)
  const navigate = useNavigate()
  const t = useTranslations(translations)

  const {
    getInvoices,
    getInvoicesError,
    invoices,
    isLoadingInvoices,
    loadMore,
    nextToken,
  } = useGetContactInvoices()

  const invoiceInput = {
    limit: 50,
    filter: {
      amountFilter: null,
      dateFilter: null,
      payerContactUuidFilter: contact?.id,
      statusFilter: null,
      textSearchFilter: '',
    },
    sort: null,
  }

  useEffect(
    () => {
      getInvoices({
        variables: {
          input: invoiceInput,
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getInvoices]
  )

  if (getInvoicesError) {
    return (
      <EmptyState
        heading={t('errorInvoicesTitle')}
        description={t('errorInvoicesDescription')}
        Icon={<ErrorInvoicesIcon />}
      />
    )
  }

  if (!invoices?.length && hasSearched) {
    return (
      <EmptyState
        heading={t('noSearchResultsTitle')}
        description={t('noSearchResultsDescription')}
        Icon={<NoSearchResultsIcon size="large" />}
      />
    )
  }

  if (!invoices?.length && !isLoadingInvoices) {
    const name = contact?.firstName ?? contact?.businessName
    const fullName = contact?.businessName
      ? contact?.businessName
      : `${contact?.firstName || ''} ${contact?.lastName || ''}`.trim()

    const locationState = {
      initialValuesOverrides: {
        customer: {
          payerContact: {
            contactUuid: contact?.id || '',
            contactName: fullName,
            contactType: contact?.contactType,
          },
        },
      },
    }

    return (
      <EmptyState
        heading={
          name
            ? t('emptyInvoicesTitle', { name })
            : t('emptyInvoicesTitleNoName')
        }
        description={t('emptyInvoicesDescription')}
        CallToAction={
          <ButtonFill
            size={BUTTON_SIZE.LARGE}
            onClick={() => {
              navigate(ROUTE.PORTAL_INVOICES_INVOICE_CREATE, {
                state: locationState,
              })
            }}
          >
            {t('createInvoice')}
          </ButtonFill>
        }
        Icon={<NoInvoicesYetIcon />}
      />
    )
  }

  const invoicesByYear = groupInvoicesByYear(invoices)

  return (
    <>
      <ContactInvoicesTable
        hasMore={Boolean(nextToken)}
        invoicesByYear={invoicesByYear}
        isLoading={isLoadingInvoices}
        onScrollEnd={() =>
          loadMore?.({
            variables: {
              input: {
                ...invoiceInput,
                nextToken,
              },
            },
          })
        }
      />
      <Outlet />
    </>
  )
}
