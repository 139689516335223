import { gql } from '@apollo/client'

import { InvoiceCoreFields } from './invoiceCoreFieldsFragment'

export const SaveAndSendInvoice = gql`
  ${InvoiceCoreFields}
  mutation SaveAndSendInvoice($invoice: SaveAndSendInvoiceInput!) {
    saveAndSendInvoice(input: $invoice) {
      ...InvoiceCoreFields
    }
  }
`
