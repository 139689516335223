import { useCallback } from 'react'
import { Outlet, useNavigate } from 'react-router-dom-v5-compat'
import { Table } from '@npco/ui-table'
import { Flex } from '@npco/zeller-design-system'
import { Row } from '@tanstack/react-table'
import { useItemOnboarding } from 'features/ItemManagement/components/ItemOnboarding/hooks/useItemOnboarding'

import { ROOT } from 'const/routes'
import { ListLoader } from 'components/Lists'

import { EmptyState } from '../EmptyState/EmptyState'
import { useGetItemsTableSubscription } from '../ItemManagementContext/hooks/useGetItemsTableSubscription'
import { useItemManagementContext } from '../ItemManagementContext/ItemManagementContext'
import { ItemOnboarding } from '../ItemOnboarding/ItemOnboarding'
import { useItemsTable } from './hooks/useItemsTable'
import { useItemsTableInfiniteLoader } from './hooks/useItemsTableInfiniteLoader'
import { ItemDrawer } from './ItemDrawer/ItemDrawer'
import { ItemsErrorState } from './ItemsErrorState/ItemsErrorState'
import { ItemsMobileFilters } from './ItemsMobileFIlters/ItemsMobileFilters'
import { ItemsNoResultsState } from './ItemsNoResultsState/ItemsNoResultsState'
import { ItemsNotFoundState } from './ItemsNotFoundState/ItemsNotFoundState'
import { ItemsTableData } from './ItemsTable.types'

export const ItemsTable = () => {
  useGetItemsTableSubscription()

  const {
    hasError,
    hasNoItemsAndCategories,
    hasHeaderBasedFilters,
    hasNoResults,
    isDefaultFilters,
    isLoading,
    table,
  } = useItemsTable()

  const { isLoadingMore, observerContainer } = useItemsTableInfiniteLoader()

  const navigate = useNavigate()
  const { shouldShowItemOnboarding } = useItemOnboarding()
  const { hasSeenItemOnboarding } = useItemManagementContext()

  const handleRowClick = useCallback(
    (row: Row<ItemsTableData> | null) => {
      if (row?.original.referenceNumber) {
        navigate(
          ROOT.PORTAL.ITEM_MANAGEMENT.ITEMS.ITEM(row.original.referenceNumber)
            .relative
        )
      }
    },
    [navigate]
  )

  const showEmptyState = hasNoItemsAndCategories && isDefaultFilters

  if (hasError) {
    return (
      <>
        <ItemsErrorState />
        <Outlet />
      </>
    )
  }

  if (showEmptyState) {
    return (
      <>
        <EmptyState />
        <Outlet />
      </>
    )
  }

  const showNotFoundState = !isLoading && !isDefaultFilters && hasNoResults
  const showNoResultsState = !isLoading && isDefaultFilters && hasNoResults
  const showItemOnboarding =
    !isLoading &&
    !hasError &&
    !hasNoItemsAndCategories &&
    !hasSeenItemOnboarding &&
    shouldShowItemOnboarding

  const hideHeader =
    showNoResultsState || (showNotFoundState && !hasHeaderBasedFilters)

  return (
    <Flex
      data-testid="items-table-container"
      flexDirection="column"
      flexGrow={1}
    >
      <Table<ItemsTableData>
        hideHeader={hideHeader}
        onRowClick={handleRowClick}
        table={table}
      />
      <div ref={observerContainer} />
      {isLoadingMore && <ListLoader />}

      {showNotFoundState && <ItemsNotFoundState />}
      {showNoResultsState && <ItemsNoResultsState />}
      {showItemOnboarding && <ItemOnboarding />}

      {!isLoading && <ItemDrawer />}
      {!isLoading && <ItemsMobileFilters />}
      {!isLoading && <Outlet />}
    </Flex>
  )
}
