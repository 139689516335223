import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Navigate } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { ContactType } from '@npco/mp-gql-types'
import { AvatarBasic, COLOR, SvgIcon } from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'
import { useCreatePaymentInstrumentMFAState } from 'features/MFA'

import { ReactComponent as IconEdit } from 'assets/svg/pen.svg'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { BasicModal } from 'components/BasicModal/BasicModal'
import { ModalScrollable } from 'components/ModalScrollable/ModalScrollable'
import { PopperAvatarEdit } from 'components/PopperAvatarEdit/PopperAvatarEdit'
import {
  Popper,
  PopperItem,
} from 'components/PopperAvatarEdit/PopperAvatarEdit.styled'

import { getContactName } from '../../Contacts.utils'
import {
  rvContactProfilePictureMap,
  rvSelectedContact,
} from '../../rv-deprecated/contacts'
import { BankAccounts } from '../ContactSections/BankAccounts/BankAccounts'
import { BpayPaymentInstrument } from '../ContactSections/Bpay/Bpay.types'
import { BPayList } from '../ContactSections/Bpay/BPayList'
import { ContactDetails } from '../ContactSections/ContactDetails/ContactDetails'
import { EditContact } from '../ContactSections/EditContact/EditContact'
import { useRemoveContactImage } from '../ContactSections/ModalContactProfilePicture/hooks/useRemoveContactImage'
import { ModalContactProfilePicture } from '../ContactSections/ModalContactProfilePicture/ModalContactProfilePicture'
import { Notes } from '../ContactSections/Notes/Notes'
import { SubContacts } from '../ContactSections/SubContacts/SubContacts'
import { Tags } from '../ContactSections/Tags/Tags'
import { ContactDetailsWrapper, ContactWrapper } from './ContactGeneral.styled'
import {
  byBankAccounts,
  byBpayAccounts,
  getContactAvatarBadgeOptions,
  getContactProfilePictureUrl,
} from './ContactGeneral.util'

export const ContactGeneral = () => {
  const { createPaymentInstrumentState } = useCreatePaymentInstrumentMFAState()

  const mfaContact = createPaymentInstrumentState?.contact || null

  const contact = useReactiveVar(rvSelectedContact) || mfaContact

  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current?.scrollIntoView()
    }
  }, [contact])

  const {
    isModalOpen: isModalOpenUpload,
    openModal: openModalUpload,
    closeModal: closeModalUpload,
  } = useModalState()

  const {
    isModalOpen: isModalOpenRemove,
    openModal: openModalRemove,
    closeModal: closeModalRemove,
  } = useModalState()

  const contactUuid = contact?.id ?? ''

  const onImageUploaded = useCallback(
    (url) =>
      rvContactProfilePictureMap({
        ...rvContactProfilePictureMap(),
        [contactUuid]: url,
      }),
    [contactUuid]
  )

  const onImageRemoved = useCallback(
    () =>
      rvContactProfilePictureMap({
        ...rvContactProfilePictureMap(),
        [contactUuid]: null,
      }),
    [contactUuid]
  )

  const { removeContactImage, isRemovingContactImage } = useRemoveContactImage(
    onImageRemoved,
    closeModalRemove
  )

  const profilePictureUrl = getContactProfilePictureUrl(contact)
  const avatarOptions = getContactAvatarBadgeOptions(contact)
  const name = getContactName(contact)
  const avatar = useCallback(
    (onClick: () => void) => (
      <AvatarBasic
        bgColor={avatarOptions.bgColor}
        hoverContent={
          <SvgIcon height="16" width="16" color={COLOR.BLACK_900}>
            <IconEdit />
          </SvgIcon>
        }
        onClick={onClick}
        svgBackground={avatarOptions.bgColor}
        text={avatarOptions.text}
        userDefinedContent={avatarOptions?.userDefinedContent}
        withCursorPointer
      />
    ),
    [avatarOptions]
  )

  const renderPopper = useCallback(
    (ref, attr) => (
      <Popper ref={ref} {...attr}>
        <PopperItem onClick={openModalUpload}>
          {translate('component.modal.uploadImage.updateImage')}
        </PopperItem>
        <PopperItem onClick={openModalRemove}>
          {translate('shared.remove')}
        </PopperItem>
      </Popper>
    ),
    [openModalRemove, openModalUpload]
  )

  const renderContactDetailsAvatar = useCallback(
    () =>
      profilePictureUrl ? (
        <PopperAvatarEdit renderPopper={renderPopper}>
          {avatar(() => undefined)}
        </PopperAvatarEdit>
      ) : (
        avatar(openModalUpload)
      ),
    [avatar, openModalUpload, profilePictureUrl, renderPopper]
  )

  const renderContactDetailsAction = useCallback(
    () => (contact ? <EditContact contact={contact} /> : <></>),
    [contact]
  )

  const handleOnRemove = useCallback(() => {
    if (contact) {
      removeContactImage(contact.id)
      rvSelectedContact({
        ...contact,
        icon: {
          colour: contact.icon?.colour ?? COLOR.GREY_90,
          images: [],
          letter: contact.icon?.letter ?? null,
        },
      })
    }
  }, [contact, removeContactImage])

  const { bankAccounts, bpayAccounts } = useMemo(() => {
    const paymentInstruments = contact?.paymentInstruments || []
    return {
      bankAccounts: paymentInstruments.filter(byBankAccounts),
      bpayAccounts: paymentInstruments.filter(
        byBpayAccounts
      ) as BpayPaymentInstrument[],
    }
  }, [contact?.paymentInstruments])

  if (!contact) {
    return <Navigate to={ROOT.PORTAL.CONTACTS.path} replace />
  }

  return (
    <>
      <ContactWrapper>
        <ContactDetailsWrapper ref={headerRef}>
          <ContactDetails
            contact={contact}
            canEditCategories
            renderHeaderAction={renderContactDetailsAction}
            renderHeaderAvatar={renderContactDetailsAvatar}
          />

          <BankAccounts
            contactBankAccounts={bankAccounts}
            contactId={contact.id}
            contactName={name}
          />
          {contact.contactType === ContactType.BUSINESS && (
            <BPayList
              bpayPaymentInstruments={bpayAccounts}
              contactBusinessName={contact.businessName}
              contactId={contact.id}
            />
          )}
          <SubContacts contact={contact} />
          <Notes contactId={contact.id} contactNotes={contact.notes} />
          <Tags contactId={contact.id} contactTags={contact.tags} />
        </ContactDetailsWrapper>
      </ContactWrapper>
      <ModalScrollable
        title={translate(
          'page.contact.sections.imageUpload.uploadContactImage'
        )}
        isOpen={isModalOpenUpload}
        onCancel={closeModalUpload}
        hasCloseButton
      >
        <ModalContactProfilePicture
          closeModal={closeModalUpload}
          contactUuid={contact.id}
          onImageUploaded={onImageUploaded}
          profilePictureUrl={profilePictureUrl}
        />
      </ModalScrollable>
      <ModalBasic
        title={translate('page.contact.sections.imageUpload.removeImage')}
        isOpen={isModalOpenRemove}
        onCancel={closeModalRemove}
        hasCloseButton
      >
        <BasicModal
          modalDescription={translate(
            'page.contact.sections.imageUpload.removeImageWarning'
          )}
          onPrimaryButtonClick={handleOnRemove}
          onSecondaryButtonClick={closeModalRemove}
          primaryButtonLabel={translate('shared.confirm')}
          isLoading={isRemovingContactImage}
        />
      </ModalBasic>
    </>
  )
}
