import { TableCellAvatarText } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { ItemsTableData } from '../ItemsTable.types'

export const ItemsRowNameCell = ({
  cell,
  getValue,
  row,
  table,
}: CellContext<ItemsTableData, string>) => {
  const isMobile = useIsMobileResolution()
  const isLoading = row.original.id === null || table.options.meta?.isLoading
  const value = isLoading ? '' : getValue<string>()

  if (isMobile) {
    const byline = row.original.categoryNames ?? ''

    return (
      <TableCellAvatarText
        avatarProps={{ text: value }}
        byline={byline}
        dataTestId={cell.id}
        forwardedProps={{ style: { padding: 0 } }}
        hasDivider={false}
        isLoading={isLoading}
        key={cell.id}
        skeletonProps={{ width: '100%' }}
        text={value}
      />
    )
  }

  const byline = row.original.sku ? `SKU: ${row.original.sku}` : ''
  const width = row.index % 2 ? '70%' : '80%'

  return (
    <TableCellAvatarText
      avatarProps={{ text: value }}
      byline={byline}
      dataTestId={cell.id}
      forwardedProps={{ style: { padding: 0 } }}
      hasDivider={false}
      isLoading={isLoading}
      key={cell.id}
      skeletonProps={{ width }}
      text={value}
    />
  )
}
