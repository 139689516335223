import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  BankAccountDetailsInput,
  PaymentInstrumentStatus,
  PaymentInstrumentType,
} from '@npco/mp-gql-types'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { ApiCallType, useRedirectToMFA } from 'features/MFA'

import { BsbPaymentInstrument } from 'hooks/paymentInstruments/useBsbPaymentInstruments'
import { useCreateBsbPaymentInstrument } from 'hooks/paymentInstruments/useCreateBsbPaymentInstrument'
import { useLinkBsbPaymentInstrument } from 'hooks/paymentInstruments/useLinkBsbPaymentInstrument'
import { useUpdatePaymentInstrument } from 'hooks/paymentInstruments/useUpdatePaymentInstrument/useUpdatePaymentInstrument'
import { translate } from 'utils/translations'
import { AuthScope } from 'types/auth'
import {
  MFA_ERROR,
  RESOURCE_ALREADY_EXISTS_ERROR,
  UNEXPECTED_ERROR,
} from 'types/errors'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { useAnalyticsContext } from 'services/Analytics/useAnalyticsContext'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'

import { rvSelectedContact } from '../../../../rv-deprecated/contacts'
import { updateRvContactsOnContactUpdate } from '../../../../rv-deprecated/contacts.utils'
import { useSavedBankAccounts } from './useSavedBankAccounts'

type PaymentInstrument = NonNullable<
  ContactCoreFieldsFragment['paymentInstruments']
>[number]

const showLinkBankAccountToast =
  (accountName?: string | null) =>
  (toastFunction: (msg: ReactNode) => void, isSuccess: boolean) =>
    toastFunction(
      translate(
        `page.contact.sections.bankAccounts.${
          isSuccess ? 'linkBankAccountSuccess' : 'linkBankAccountFailure'
        }`,
        {
          accountName,
        }
      )
    )

const showUpdateErrorToast = (accountName: string) => {
  showErrorToast(
    translate('page.contact.sections.bankAccounts.updateBankAccountFailure', {
      accountName,
    })
  )
}

const saveBankAccountsToState =
  (
    bankAccountDetails: BankAccountDetailsInput,
    contactId: string,
    id: string,
    status: PaymentInstrumentStatus,
    setRecentUpdatedBankAccount: Dispatch<
      SetStateAction<PaymentInstrument | null>
    >
  ) =>
  (currentBankAccountList: PaymentInstrument[]) => {
    const newItem = {
      id,
      status,
      bankAccountDetails,
      details: {},
    }
    const newList = [...currentBankAccountList, newItem]

    setRecentUpdatedBankAccount(newItem)
    updateRvContactsOnContactUpdate(contactId, { paymentInstruments: newList })

    const selectedContact = rvSelectedContact()
    if (selectedContact) {
      rvSelectedContact({
        ...selectedContact,
        paymentInstruments: newList,
      })
    }

    return newList
  }

export const useBankAccountHandlers = (
  closeModal: () => void,
  contactBankAccounts: ContactCoreFieldsFragment['paymentInstruments'],
  contactId: string,
  setIsExistingPaymentInstrument: Dispatch<SetStateAction<boolean>>
) => {
  const [recentUpdatedBankAccount, setRecentUpdatedBankAccount] =
    useState<PaymentInstrument | null>(null)
  const { locationName } = useAnalyticsContext()
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  const { redirectToMFA } = useRedirectToMFA()

  const { savedBankAccounts, setSavedBankAccounts, updateSavedBankAccounts } =
    useSavedBankAccounts()

  useEffect(() => {
    setSavedBankAccounts(contactBankAccounts?.filter(Boolean) ?? [])
  }, [contactBankAccounts, setSavedBankAccounts])

  const { createPaymentInstrument, isCreatingPaymentInstrument } =
    useCreateBsbPaymentInstrument()

  const { linkPaymentInstrument, isLinkingPaymentInstrument } =
    useLinkBsbPaymentInstrument()

  const { updatePaymentInstrument, isUpdatingPaymentInstrument } =
    useUpdatePaymentInstrument()

  const handleCreateBankAccountSuccess = useCallback(
    (
      paymentInstrumentId: string,
      bankAccountDetails: BankAccountDetailsInput,
      onSuccessCallback: () => void
    ) => {
      const showToast = showLinkBankAccountToast(bankAccountDetails.name)

      const createdPaymentInstrumentId = paymentInstrumentId

      setSavedBankAccounts(
        saveBankAccountsToState(
          bankAccountDetails,
          contactId,
          createdPaymentInstrumentId,
          PaymentInstrumentStatus.ACTIVE,
          setRecentUpdatedBankAccount
        )
      )

      showToast(showSuccessToast, true)
      trackAnalyticsEvent(AnalyticsEventNames.PAYMENT_INSTRUMENT_CREATED, {
        Type: PaymentInstrumentType.BSB,
        Location: locationName.current,
      })
      onSuccessCallback()
      closeModal()
    },
    [
      closeModal,
      contactId,
      locationName,
      setSavedBankAccounts,
      trackAnalyticsEvent,
    ]
  )

  const onLinkBankAccount = async (
    bankAccountDetails: BankAccountDetailsInput,
    paymentInstrumentStatus: PaymentInstrumentStatus,
    selectedPaymentInstrument: BsbPaymentInstrument | null,
    onSuccessCallback: () => void
  ) => {
    const showToast = showLinkBankAccountToast(bankAccountDetails.name)

    try {
      if (!selectedPaymentInstrument?.id) {
        const createResponse = await createPaymentInstrument(
          bankAccountDetails,
          contactId
        )

        if (createResponse === UNEXPECTED_ERROR) {
          showToast(showErrorToast, false)
          return
        }

        if (createResponse === MFA_ERROR) {
          redirectToMFA({
            apiCallType: ApiCallType.CreatePaymentInstrument,
            scope: AuthScope.SENSITIVE,
            variables: {
              contactId,
              ...bankAccountDetails,
            },
            contact: rvSelectedContact() || undefined,
          })
          return
        }

        if (createResponse === RESOURCE_ALREADY_EXISTS_ERROR) {
          showToast(showErrorToast, false)
          setIsExistingPaymentInstrument(true)
          return
        }

        handleCreateBankAccountSuccess(
          createResponse.id,
          bankAccountDetails,
          onSuccessCallback
        )

        return
      }

      if (paymentInstrumentStatus !== selectedPaymentInstrument.status) {
        const updateResponse = await updatePaymentInstrument(
          selectedPaymentInstrument.id,
          paymentInstrumentStatus
        )

        if (!updateResponse.data?.updatePaymentInstrument) {
          showToast(showErrorToast, false)
          return
        }
      }

      const linkResponse = await linkPaymentInstrument(
        contactId,
        selectedPaymentInstrument.id
      )

      if (!linkResponse.data?.linkPaymentInstrumentWithContact) {
        showToast(showErrorToast, false)
        return
      }

      setSavedBankAccounts(
        saveBankAccountsToState(
          bankAccountDetails,
          contactId,
          selectedPaymentInstrument.id,
          paymentInstrumentStatus,
          setRecentUpdatedBankAccount
        )
      )

      onSuccessCallback()
      showToast(showSuccessToast, true)

      closeModal()
    } catch (error) {
      showToast(showErrorToast, false)
    }
  }

  const updateFunc = async (
    id: string,
    status: PaymentInstrumentStatus,
    bankAccountDetails: BankAccountDetailsInput
  ) => {
    const updateResponse = await updatePaymentInstrument(id, status)

    if (!updateResponse.data?.updatePaymentInstrument) {
      showUpdateErrorToast(bankAccountDetails.name)

      return false
    }

    return true
  }

  const onUpdateBankAccount = async (
    bankAccountIdToUpdate: string,
    bankAccountDetails: BankAccountDetailsInput,
    paymentInstrumentStatus: PaymentInstrumentStatus,
    onSuccessCallback: () => void
  ) => {
    try {
      const existingPrimaryBankAccountToUpdate =
        paymentInstrumentStatus === PaymentInstrumentStatus.PRIMARY &&
        savedBankAccounts.find(
          (bankAccount) =>
            bankAccount.status === PaymentInstrumentStatus.PRIMARY
        )

      // NOTE: Contact should only have 1 primary bank account
      // Update existing primary bank account to ACTIVE
      if (existingPrimaryBankAccountToUpdate) {
        const isUpdateBankAccountToActiveSuccess = await updateFunc(
          existingPrimaryBankAccountToUpdate.id,
          PaymentInstrumentStatus.ACTIVE,
          bankAccountDetails
        )
        if (!isUpdateBankAccountToActiveSuccess) {
          return
        }
      }

      const isUpdateBankAccountToNewStatusSuccess = await updateFunc(
        bankAccountIdToUpdate,
        paymentInstrumentStatus,
        bankAccountDetails
      )

      setRecentUpdatedBankAccount({
        bankAccountDetails,
        id: bankAccountIdToUpdate,
        status: paymentInstrumentStatus,
        details: {},
      })

      if (!isUpdateBankAccountToNewStatusSuccess) {
        return
      }

      showSuccessToast(
        translate(
          'page.contact.sections.bankAccounts.updateBankAccountSuccess',
          { accountName: bankAccountDetails.name }
        )
      )

      updateSavedBankAccounts({
        bankAccountIdToUpdate,
        paymentInstrumentStatus,
        existingPrimaryBankAccountToUpdate,
        contactId,
      })

      onSuccessCallback()

      closeModal()
    } catch (error) {
      showUpdateErrorToast(bankAccountDetails.name)
    }
  }

  return {
    isLoading:
      isCreatingPaymentInstrument ||
      isLinkingPaymentInstrument ||
      isUpdatingPaymentInstrument,
    onLinkBankAccount,
    onUpdateBankAccount,
    recentUpdatedBankAccount,
    savedBankAccounts,
    setSavedBankAccounts,
    handleCreateBankAccountSuccess,
  }
}
