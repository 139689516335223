import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const AuthLinkWithAPIKey = setContext((_, { headers = {} }) => {
  return {
    headers: {
      ...headers,
      'x-api-key': `${process.env.REACT_APP_API_KEY}`,
    },
  }
})

const AuthLinkWithURI = createHttpLink({
  uri: process.env.REACT_APP_API_URI,
})

export const ApolloClientWithAPIKey = new ApolloClient({
  link: from([AuthLinkWithAPIKey.concat(AuthLinkWithURI)]),
  cache: new InMemoryCache(),
})
