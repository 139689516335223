import { useMutation } from '@apollo/client'
import {
  PaymentInstrumentStatus,
  PaymentInstrumentType,
  UpdatePaymentInstrumentInput,
} from '@npco/mp-gql-types'
import { UpdatePaymentInstrument } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/paymentInstruments'

import {
  UpdatePaymentInstrument as UpdatePaymentInstrumentResponse,
  UpdatePaymentInstrumentVariables,
} from 'types/gql-types/UpdatePaymentInstrument'

export const useUpdatePaymentInstrument = () => {
  const [updatePaymentInstrument, { loading: isUpdatingPaymentInstrument }] =
    useMutation<
      UpdatePaymentInstrumentResponse,
      UpdatePaymentInstrumentVariables
    >(UpdatePaymentInstrument)

  return {
    isUpdatingPaymentInstrument,
    updatePaymentInstrument: (
      paymentInstrumentId: string,
      paymentInstrumentStatus: PaymentInstrumentStatus,
      bpayDetails: UpdatePaymentInstrumentInput['bpayDetails'] = null,
      type: PaymentInstrumentType = PaymentInstrumentType.BSB
    ) => {
      return updatePaymentInstrument({
        variables: {
          id: paymentInstrumentId,
          type,
          payload: {
            status: paymentInstrumentStatus,
            bpayDetails,
          },
        },
      })
    },
  }
}
