import { useMutation } from '@apollo/client'
import { SkipReminderInput } from '@npco/mp-gql-types'

import {
  SkipReminder as SkipReminderResponse,
  SkipReminderVariables,
} from 'types/gql-types/SkipReminder'

import { SkipReminder } from '../../graphql/skipReminder'

export const useSkipReminder = () => {
  const [skipReminder] = useMutation<
    SkipReminderResponse,
    SkipReminderVariables
  >(SkipReminder)

  return {
    skipReminder: (input: SkipReminderInput) =>
      skipReminder({
        variables: {
          input,
        },
      }),
  }
}
