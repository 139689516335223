import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetEntityQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetEntityQueryResponse = {
  __typename?: 'Query'
  getEntity: {
    __typename?: 'Entity'
    id: string
    name: string | null
    acn: string | null
    abn: string | null
    type: Types.EntityType | null
    categoryGroup: Types.CategoryGroup | null
    category: Types.Category | null
    estimatedAnnualRevenue: number | null
    onboardingStatus: Types.OnboardingStatus | null
    canAcquire: boolean | null
    canAcquireMobile: boolean | null
    canAcquireMoto: boolean | null
    canAcquireVt: boolean | null
    remitToCard: boolean | null
    debitCardAccountUuid: string | null
    depositAccountUuid: string | null
    registeredAddress: {
      __typename?: 'EntityAddress'
      street1: string | null
      street2: string | null
      suburb: string | null
      state: string | null
      postcode: string | null
    } | null
    businessAddress: {
      __typename?: 'EntityAddress'
      street1: string | null
      street2: string | null
      suburb: string | null
      state: string | null
      postcode: string | null
    } | null
    feeRateSettings: {
      __typename?: 'FeeRateSettings'
      feePercent: number | null
      feePercentMoto: number | null
      feePercentCpoc: number | null
      feeFixedCpoc: number | null
      feeFixedVt: number | null
      feePercentVt: number | null
    } | null
    regulatorBody: {
      __typename?: 'RegulatorBody'
      name: string | null
      referenceNumber: string | null
      type: Types.RegulatorBodyType | null
    } | null
    termsOfService: {
      __typename?: 'EntityTermsOfService'
      ezta: {
        __typename?: 'EntityTermsOfServiceAcceptance'
        accepted: boolean
      } | null
    } | null
    transactionMetaData: {
      __typename?: 'EntityTransactionMetaData'
      yetToMakeTransaction: boolean
    } | null
  }
}

export const GetEntity = gql`
  query GetEntity {
    getEntity {
      id
      name
      acn
      abn
      type
      registeredAddress {
        street1
        street2
        suburb
        state
        postcode
      }
      businessAddress {
        street1
        street2
        suburb
        state
        postcode
      }
      feeRateSettings {
        feePercent
        feePercentMoto
        feePercentCpoc
        feeFixedCpoc
        feeFixedVt
        feePercentVt
      }
      categoryGroup
      category
      estimatedAnnualRevenue
      onboardingStatus
      canAcquire
      canAcquireMobile
      canAcquireMoto
      canAcquireVt
      remitToCard
      debitCardAccountUuid
      depositAccountUuid
      regulatorBody {
        name
        referenceNumber
        type
      }
      termsOfService {
        ezta {
          accepted
        }
      }
      transactionMetaData {
        yetToMakeTransaction
      }
    }
  }
` as unknown as TypedDocumentNode<
  GetEntityQueryResponse,
  GetEntityQueryVariables
>

/**
 * __useGetEntityQuery__
 *
 * To run a query within a React component, call `useGetEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEntityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEntityQueryResponse,
    GetEntityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEntityQueryResponse, GetEntityQueryVariables>(
    GetEntity,
    options
  )
}
export function useGetEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEntityQueryResponse,
    GetEntityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetEntityQueryResponse, GetEntityQueryVariables>(
    GetEntity,
    options
  )
}
export type GetEntityQueryHookResult = ReturnType<typeof useGetEntityQuery>
export type GetEntityLazyQueryHookResult = ReturnType<
  typeof useGetEntityLazyQuery
>
export type GetEntityQueryResult = Apollo.QueryResult<
  GetEntityQueryResponse,
  GetEntityQueryVariables
>
