import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'

export const useNavigateToCardSettings = () => {
  const navigate = useNavigate()

  const navigateToCardSettings = useCallback(() => {
    navigate(ROOT.PORTAL.CARDS.SETTINGS.path)
  }, [navigate])

  const navigateToCardSettingsOutstandingExpenses = useCallback(() => {
    navigate(ROOT.PORTAL.CARDS.SETTINGS.OUTSTANDING_EXPENSES.path)
  }, [navigate])

  return { navigateToCardSettings, navigateToCardSettingsOutstandingExpenses }
}
