import { useCallback, useState } from 'react'
import {
  GetCategoriesSortColumnName,
  GetCategoriesSortInput,
} from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  ButtonFill,
  ButtonGhost,
  Flex,
  theme,
  TickIcon,
} from '@npco/zeller-design-system'

import * as styled from 'components/MobileFilters/MobileFilters.styled'
import { translationsShared } from 'translations'

import { translations } from '../CategoriesMobileFilters.i18n'

type NextSortInput = NonNullable<GetCategoriesSortInput> | null

interface SortByFiltersProps {
  nextSortInput: NextSortInput
  setNextSortInput: (nextSortInput: NextSortInput) => void
  setDefaultVisibilityState: () => void
}

export const SortByFilters = ({
  nextSortInput,
  setDefaultVisibilityState,
  setNextSortInput,
}: SortByFiltersProps) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const [nextLocalSortInput, setLocalSortInput] =
    useState<NonNullable<GetCategoriesSortInput> | null>(nextSortInput)

  const handleApply = useCallback(() => {
    setNextSortInput(nextLocalSortInput)
    setDefaultVisibilityState()
  }, [nextLocalSortInput, setDefaultVisibilityState, setNextSortInput])

  const handleClear = useCallback(() => {
    setLocalSortInput(null)
  }, [setLocalSortInput])

  const handleClick = useCallback(
    (nextState: GetCategoriesSortInput) => () => setLocalSortInput(nextState),
    [setLocalSortInput]
  )

  const ascending = nextLocalSortInput?.ascending
  const columnName = nextLocalSortInput?.columnName

  const isAscending = ascending === true
  const isDescending = ascending === false
  const isSortByName = columnName === GetCategoriesSortColumnName.Name
  const isSortByItemsNumber =
    columnName === GetCategoriesSortColumnName.ItemsNumber

  return (
    <Flex
      data-testid="categories-mobile-filters-sort-by"
      flexDirection="column"
      flexGrow={1}
    >
      <Flex flexDirection="column" flexGrow={1}>
        <styled.MobileFiltersButtonIconLeftButton
          icon={() =>
            isAscending && isSortByName ? (
              <TickIcon color={theme.colors.BLACK} />
            ) : null
          }
          onClick={handleClick({
            ascending: true,
            columnName: GetCategoriesSortColumnName.Name,
          })}
        >
          {`${t('categoryName')}: ${t('aToZ')}`}
        </styled.MobileFiltersButtonIconLeftButton>

        <styled.MobileFiltersButtonIconLeftButton
          icon={() =>
            isDescending && isSortByName ? (
              <TickIcon color={theme.colors.BLACK} />
            ) : null
          }
          onClick={handleClick({
            ascending: false,
            columnName: GetCategoriesSortColumnName.Name,
          })}
        >
          {`${t('categoryName')}: ${t('zToA')}`}
        </styled.MobileFiltersButtonIconLeftButton>

        <styled.MobileFiltersButtonIconLeftButton
          icon={() =>
            isDescending && isSortByItemsNumber ? (
              <TickIcon color={theme.colors.BLACK} />
            ) : null
          }
          onClick={handleClick({
            ascending: false,
            columnName: GetCategoriesSortColumnName.ItemsNumber,
          })}
        >
          {`${t('items')}: ${t('highToLow')}`}
        </styled.MobileFiltersButtonIconLeftButton>

        <styled.MobileFiltersButtonIconLeftButton
          icon={() =>
            isAscending && isSortByItemsNumber ? (
              <TickIcon color={theme.colors.BLACK} />
            ) : null
          }
          onClick={handleClick({
            ascending: true,
            columnName: GetCategoriesSortColumnName.ItemsNumber,
          })}
        >
          {`${t('items')}: ${t('lowToHigh')}`}
        </styled.MobileFiltersButtonIconLeftButton>
      </Flex>
      <styled.MobileFiltersButtonWrapper>
        <ButtonGhost
          dataTestId="categories-mobile-filters-clear"
          onClick={handleClear}
        >
          {tShared('clear')}
        </ButtonGhost>
        <ButtonFill
          dataTestId="categories-mobile-filters-apply"
          onClick={handleApply}
        >
          {tShared('apply')}
        </ButtonFill>
      </styled.MobileFiltersButtonWrapper>
    </Flex>
  )
}
