import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import {
  rvContacts,
  rvSelectedContact,
} from '../../../../rv-deprecated/contacts'
import { updateRvContactsOnContactUpdate } from '../../../../rv-deprecated/contacts.utils'

type Contact = ContactCoreFieldsFragment
type SubContact = NonNullable<Contact['contacts']>[0]

export const removeContactWithSubContact = (
  subContactToRemove: SubContact | null
) => {
  const currentContact = rvSelectedContact()

  if (!currentContact || !subContactToRemove?.id) {
    return
  }

  const newSubContacts = currentContact?.contacts?.filter(
    (currentSubContact) => currentSubContact?.id !== subContactToRemove?.id
  )

  rvSelectedContact({
    ...currentContact,
    contacts: newSubContacts,
  } as Contact)

  // NOTE: Update current contact with the new sub contacts
  updateRvContactsOnContactUpdate(currentContact.id, {
    contacts: newSubContacts,
  })

  const newSubContactsForSubContactToRemove = rvContacts()?.[
    subContactToRemove.id
  ]?.contacts?.filter((subContact) => subContact?.id !== currentContact.id)

  // NOTE: Update sub contacts for the selected sub contact to be unlinked
  updateRvContactsOnContactUpdate(subContactToRemove.id, {
    contacts: newSubContactsForSubContactToRemove,
  })
}
