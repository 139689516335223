import { useHistory } from 'react-router-dom'
import { InvoiceStatus } from '@npco/mp-gql-types'
import { Flex } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'
import { useTheme } from 'styled-components'

import { ROOT } from 'const/routes'
import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'

import { getCurrencyAmountFormatted } from '../../../Invoice/components/InvoiceFormAccordions/InvoiceFormAccordions.utils'
import { AmountColumnEnum, DateColumnEnum } from '../../InvoiceTable.types'
import {
  getFormattedDate,
  StatusColor,
  StatusLabel,
} from '../../InvoiceTable.utils'
import {
  Dot,
  Element,
  InvoiceMobileItem,
  LoadingSkeleton,
  RefNumberElement,
  RowWrapper,
} from './InvoiceMobileRowCell.styled'

function getDateColumnValue(column: DateColumnEnum, invoice: Invoice | null) {
  switch (column) {
    case DateColumnEnum.DatePaid:
      return invoice?.paidTime
    case DateColumnEnum.Created:
      return invoice?.createdTime
    case DateColumnEnum.Issued:
      return invoice?.startDate
    case DateColumnEnum.Sent:
      return invoice?.sentTime
    case DateColumnEnum.NextDue:
      return invoice?.dueDate
    default:
      return null
  }
}

function getAmountColumnValue(
  column: AmountColumnEnum,
  invoice: Invoice | null
) {
  switch (column) {
    case AmountColumnEnum.AmountPaid:
      return invoice?.paidAmount
    case AmountColumnEnum.Outstanding:
      return invoice?.dueAmount
    case AmountColumnEnum.Total:
      return invoice?.totalAmount
    default:
      return null
  }
}

export const InvoiceMobileRowCell = ({
  cell,
  getValue,
  row,
  table,
}: CellContext<Invoice | null, unknown>) => {
  const { colors } = useTheme()
  const { push } = useHistory()

  const { isLoading, selectedAmountColumn, selectedDateColumn } =
    table.options.meta || {}

  if (isLoading) {
    return (
      <td>
        <Flex
          alignItems="center"
          data-testid={`invoice-table-row-${row.index}-loading`}
          flexGrow={1}
          height="64px"
          justifyContent="center"
          paddingLeft="6px"
          paddingRight="6px"
          width="100%"
        >
          <LoadingSkeleton />
        </Flex>
      </td>
    )
  }

  const invoice = getValue<Invoice | null>()

  // NOTE: remove this in favour of filtering nulls when defining table data
  if (!invoice) {
    return null
  }

  const amount = getAmountColumnValue(selectedAmountColumn, invoice)
  const contact = invoice.payerContactName
  const date = getDateColumnValue(selectedDateColumn, invoice)
  const { referenceNumber } = invoice
  const { status } = invoice

  return (
    <InvoiceMobileItem
      data-testid={`invoice-item-${row.index}`}
      key={cell.id}
      onClick={() =>
        push(ROOT.PORTAL.INVOICING.INVOICES.INVOICE(referenceNumber).path)
      }
    >
      <RowWrapper>
        <Element data-testid={`invoice-item-${row.index}-contact`}>
          {contact}
        </Element>
        <Element
          alignRight
          data-testid={`invoice-item-${row.index}-total-amount`}
        >
          {getCurrencyAmountFormatted(amount ?? '0')}
        </Element>
      </RowWrapper>
      <RowWrapper>
        <Element
          color={status === InvoiceStatus.OVERDUE ? colors.RED_1000 : undefined}
          data-testid={`invoice-item-${row.index}-due-date`}
        >
          {getFormattedDate(date)}
        </Element>
        <Dot />
        <RefNumberElement data-testid={`invoice-item-${row.index}-reference`}>
          {referenceNumber}
        </RefNumberElement>
        <Element
          alignRight
          data-testid={`invoice-item-${row.index}-status`}
          color={StatusColor[status]}
        >
          {StatusLabel[status]}
        </Element>
      </RowWrapper>
    </InvoiceMobileItem>
  )
}
