import { useMutation } from '@apollo/client'
import { DeleteContact } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/contacts'

import {
  DeleteContact as DeleteContactResponse,
  DeleteContactVariables,
} from 'types/gql-types/DeleteContact'

export const useDeleteContact = () => {
  const [deleteContact, { loading: isDeletingContact }] = useMutation<
    DeleteContactResponse,
    DeleteContactVariables
  >(DeleteContact)

  return {
    deleteContact: (id: string) =>
      deleteContact({
        variables: {
          id,
        },
      }),
    isDeletingContact,
  }
}
