import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'

import { GetUpdatedOutstandingTransactionsLocalState } from './graphql/getUpdatedOutstandingTransactionsLocalState'
import {
  CacheLocalStateOutstandingTransactions,
  LocalStateOutstandingTransaction,
} from './useUpdatedOutstandingTransactions.types'

export const useWriteUpdatedOutstandingTransactionsLocalCache = () => {
  const { cache } = useApolloClient()
  const updateCache = useCallback(
    async (
      updatedOutstandingTransactions: LocalStateOutstandingTransaction[]
    ) => {
      return cache.updateQuery<CacheLocalStateOutstandingTransactions>(
        {
          query: GetUpdatedOutstandingTransactionsLocalState,
        },
        () => ({
          local: {
            updatedOutstandingTransactions,
          },
        })
      )
    },
    [cache]
  )

  return {
    updateCache,
  }
}
