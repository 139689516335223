import { type ReactElement, type ReactNode, useCallback, useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  INPUT_SIZE,
  InputWithoutLabel,
  InputWithoutLabelProps,
} from '@npco/zeller-design-system'

import { ReactComponent as MagnifierIcon } from 'assets/svg/search.svg'
import { DeviceModel } from 'types/devices'

import { FilterPopper } from '../shared/FilterPopper'
import { translations } from './DevicesFilterInput.i18n'
import * as styled from './DevicesFilterInput.styled'
import { DevicesFilterInputEmpty as ItemsEmpty } from './DevicesFilterInput/DevicesFilterInputEmpty'
import { DevicesFilterInputError as ItemsError } from './DevicesFilterInput/DevicesFilterInputError'
import { DevicesFilterInputLoading as ItemsLoading } from './DevicesFilterInput/DevicesFilterInputLoading'
import { DevicesFilterInputOk as ItemsOk } from './DevicesFilterInput/DevicesFilterInputOk'

export type DevicesFilterInputProps = {
  value: string[] | undefined
  onValueChange: (value: string[] | undefined) => void
  query: string | undefined
  onQueryChange: (query: string | undefined) => void
  items: DevicesFilterInputProps.Items
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DevicesFilterInputProps {
  export type Items = Items.Ok | Items.Empty | Items.Error | Items.Loading

  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Items {
    export type Ok = {
      $type: 'Ok'
      sites: DevicesFilterInputProps.Site[]
    }
    export type Empty = { $type: 'Empty' }
    export type Error = { $type: 'Error'; onRetry: () => void }
    export type Loading = { $type: 'Loading' }
  }

  export type Site = {
    id: string
    name: string
    devices: Device[]
  }

  export type Device = {
    id: string
    name: string
    type: DeviceModel
  }
}

export const DevicesFilterInput = ({
  value,
  onValueChange,
  query,
  onQueryChange,
  items,
}: DevicesFilterInputProps): ReactElement => {
  const t = useTranslations(translations)

  const itemsSites = items.$type === 'Ok' ? items.sites : undefined
  const label: ReactNode = useMemo(() => {
    if (itemsSites && value?.length) {
      const valueAsSet = new Set(value)
      const sites = new Set<DevicesFilterInputProps.Site>()
      const devices = new Set<DevicesFilterInputProps.Device>()
      itemsSites.forEach((site) => {
        site.devices.forEach((device) => {
          if (valueAsSet.has(device.id)) {
            sites.add(site)
            devices.add(device)
          }
        })
      })
      if (devices.size > 0) {
        const devicesCount =
          devices.size === 1
            ? t('n1Device')
            : t('nDevices', { count: devices.size.toString() })
        if (sites.size === 1) {
          const [site] = Array.from(sites.values())
          return (
            <>
              <styled.LabelSiteName>{site.name}</styled.LabelSiteName>
              {`(${devicesCount})`}
            </>
          )
        }
        return `${t('nSites', {
          count: sites.size.toString(),
        })} (${devicesCount})`
      }
    }
    return t('placeholder')
  }, [itemsSites, t, value])

  const active = !!value?.length

  const onClear = useCallback(() => {
    onValueChange(undefined)
    onQueryChange('')
  }, [onValueChange, onQueryChange])

  const filtered = useMemo((): {
    value: DevicesFilterInputProps['value']
    items: DevicesFilterInputProps.Items
  } => {
    if (query && items.$type === 'Ok') {
      const valueFiltered = new Set<string>(value)
      const sitesFiltered: DevicesFilterInputProps.Site[] = []
      items.sites.forEach((site) => {
        const isSiteMatch = !!site.name
          .toLowerCase()
          .includes(query.toLowerCase())

        if (isSiteMatch) {
          sitesFiltered.push(site)
          return
        }

        const devicesFiltered: DevicesFilterInputProps.Device[] = []
        site.devices.forEach((device) => {
          const isDeviceMatch = !!device.name
            .toLowerCase()
            .includes(query.toLowerCase())
          if (isDeviceMatch) {
            devicesFiltered.push(device)
          } else {
            valueFiltered.delete(device.id)
          }
        })

        if (devicesFiltered.length) {
          sitesFiltered.push({
            ...site,
            devices: devicesFiltered,
          })
        }
      })

      if (!sitesFiltered.length) {
        return { value: undefined, items: { $type: 'Empty' } }
      }

      return {
        value: Array.from(valueFiltered),
        items: {
          ...items,
          sites: sitesFiltered,
        },
      }
    }
    return { value, items }
  }, [value, items, query])

  const onQueryInputChange: NonNullable<InputWithoutLabelProps['onChange']> =
    useCallback((e) => onQueryChange?.(e.target.value), [onQueryChange])

  return (
    <FilterPopper
      label={label}
      active={active}
      onClear={value?.length ? onClear : undefined}
    >
      {() => (
        <>
          <InputWithoutLabel
            name="query"
            value={query ?? ''}
            onChange={onQueryInputChange}
            icon={MagnifierIcon}
            size={INPUT_SIZE.SMALL}
            placeholder={t('queryPlaceholder')}
          />
          <styled.ScrollArea>
            {filtered.items.$type === 'Ok' && (
              <ItemsOk
                value={filtered.value}
                onValueChange={onValueChange}
                {...filtered.items}
              />
            )}
            {filtered.items.$type === 'Empty' && <ItemsEmpty />}
            {filtered.items.$type === 'Error' && (
              <ItemsError {...filtered.items} />
            )}
            {filtered.items.$type === 'Loading' && <ItemsLoading />}
          </styled.ScrollArea>
        </>
      )}
    </FilterPopper>
  )
}
