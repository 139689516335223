import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { EntityCategories } from '@npco/mp-gql-types'
import { EditSubcategory } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/subcategories'
import { GetSubcategories } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/subcategories'

import {
  EditSubcategory as EditSubcategoryResponse,
  EditSubcategoryVariables,
} from 'types/gql-types/EditSubcategory'
import {
  GetSubcategories as GetSubcategoriesResponse,
  GetSubcategoriesVariables,
} from 'types/gql-types/GetSubcategories'

import { Subcategory } from './subcategories.types'

type NullableString = string | null | undefined

interface Props {
  category: EntityCategories | undefined
  subcategory: NullableString
  subcategoryId: NullableString
  oldSubcategory: NullableString
  onSuccess?: () => void
  onFailure?: () => void
}

export const useEditSubcategory = ({
  category,
  subcategory,
  subcategoryId,
  oldSubcategory,
  onSuccess,
  onFailure,
}: Props) => {
  const [editSubcategoryMutation, { loading: isLoading, error }] = useMutation<
    EditSubcategoryResponse,
    EditSubcategoryVariables
  >(EditSubcategory, {
    onCompleted: (response) => {
      if (response.updateEntitySubcategory) {
        onSuccess?.()
      } else {
        onFailure?.()
      }
    },
    onError: onFailure,
  })

  const editSubcategory = useCallback(() => {
    if (category && subcategory && subcategoryId) {
      editSubcategoryMutation({
        variables: {
          subcategory,
          subcategoryUuid: subcategoryId,
        },
        update: (cache, result) => {
          if (result.data?.updateEntitySubcategory) {
            const data = cache.readQuery<
              GetSubcategoriesResponse,
              GetSubcategoriesVariables
            >({ query: GetSubcategories, variables: { category } })

            const subcategories = (data?.getEntitySubcategories?.filter(
              Boolean
            ) || []) as Subcategory[]

            cache.writeQuery<
              GetSubcategoriesResponse,
              GetSubcategoriesVariables
            >({
              query: GetSubcategories,
              variables: { category },
              data: {
                getEntitySubcategories:
                  subcategories.map((cachedSubcategory) => {
                    if (cachedSubcategory.name === oldSubcategory) {
                      return {
                        name: subcategory,
                        predefined: cachedSubcategory.predefined,
                        id: cachedSubcategory.id,
                      }
                    }

                    return cachedSubcategory
                  }) || [],
              },
            })
          }
        },
      })
    }
  }, [
    editSubcategoryMutation,
    category,
    subcategory,
    subcategoryId,
    oldSubcategory,
  ])

  return { editSubcategory, isLoading, error }
}
