import { type ReactNode } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import {
  Breadcrumb,
  Flex,
  PageTemplate,
  Tabs,
} from '@npco/zeller-design-system'

import { ROUTE } from 'const/routes'
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { page } from 'translations'

import { useProfilePageLayout } from './ProfilePageLayout.hooks'

type ProfilePageLayoutProps = {
  children?: ReactNode
}

export const ProfilePageLayout = ({ children }: ProfilePageLayoutProps) => {
  const { tabs, currentTab } = useProfilePageLayout()
  const { pathname } = useLocation()

  const shouldShowTabs = ![
    ROUTE.PORTAL_SETTINGS_PROFILE_SECURITY_CHANGE_NUMBER,
    ROUTE.PORTAL_SETTINGS_PROFILE_SECURITY_VALIDATE_CODE,
  ].includes(pathname)

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex height="48px" alignItems="center">
            {pathname === ROUTE.PORTAL_SETTINGS_PROFILE ? (
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Breadcrumb.Text>
                    {page.title.profileSettings}
                  </Breadcrumb.Text>
                </Breadcrumb.Item>
              </Breadcrumb>
            ) : (
              <Breadcrumbs />
            )}
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
      HeaderSecondaryRow={
        shouldShowTabs && (
          <PageTemplate.HeaderSecondaryRow>
            <Tabs currentTab={currentTab}>
              {tabs.map((tab) => (
                <Tabs.Item key={tab.value} {...tab} />
              ))}
            </Tabs>
          </PageTemplate.HeaderSecondaryRow>
        )
      }
    >
      <PageTemplate.Section>{children}</PageTemplate.Section>
    </PageTemplate>
  )
}
