import { useCallback } from 'react'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { useCustomersApi } from 'hooks/useCustomersApi'

export const useItemOnboarding = () => {
  const { userData, setUserData } = useLoggedInUser()
  const { updateCustomer } = useCustomersApi()

  const userShowItemInstructions =
    userData?.productTourStatus?.showItemInstructions

  const shouldShowItemOnboarding =
    userShowItemInstructions === null ? true : Boolean(userShowItemInstructions)

  const turnOffOnboardingModal = useCallback(async () => {
    if (shouldShowItemOnboarding === false) return

    if (userData?.id) {
      const result = await updateCustomer({
        variables: {
          customer: {
            id: userData.id,
            showItemInstructions: false,
          },
        },
      })

      if (result.data?.updateCustomer && userData?.productTourStatus) {
        setUserData({
          productTourStatus: {
            ...userData.productTourStatus,
            showItemInstructions: false,
          },
        })
      }
    }
  }, [
    shouldShowItemOnboarding,
    updateCustomer,
    userData?.id,
    userData?.productTourStatus,
    setUserData,
  ])

  return {
    shouldShowItemOnboarding,
    turnOffOnboardingModal,
  }
}
