import { useEffect, useMemo, useState } from 'react'
import { createColumnHelper } from '@tanstack/react-table'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { ItemsHeaderRowCategoryCell } from '../ItemsHeaderRowCategoryCell/ItemsHeaderRowCategoryCell'
import { ItemsHeaderRowNameCell } from '../ItemsHeaderRowNameCell/ItemsHeaderRowNameCell'
import { ItemsHeaderRowPriceCell } from '../ItemsHeaderRowPriceCell/ItemsHeaderRowPriceCell'
import { ItemsRowCategoryCell } from '../ItemsRowCategoryCell/ItemsRowCategoryCell'
import { ItemsRowNameCell } from '../ItemsRowNameCell/ItemsRowNameCell'
import { ItemsRowPriceCell } from '../ItemsRowPriceCell/ItemsRowPriceCell'
import { ItemsTableData } from '../ItemsTable.types'

export const useItemsTableColumns = () => {
  const isMobile = useIsMobileResolution()

  const columnHelper = createColumnHelper<ItemsTableData>()

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        id: 'name',
        cell: ItemsRowNameCell,
        header: ItemsHeaderRowNameCell,
      }),
      columnHelper.accessor('categoryNames', {
        id: 'categoryNames',
        cell: ItemsRowCategoryCell,
        header: ItemsHeaderRowCategoryCell,
      }),
      columnHelper.accessor('price', {
        id: 'price',
        cell: ItemsRowPriceCell,
        header: ItemsHeaderRowPriceCell,
      }),
    ]
  }, [columnHelper])

  const [columnVisibility, setColumnVisibility] = useState<
    Record<string, boolean>
  >({})

  useEffect(
    () =>
      setColumnVisibility({
        categoryNames: !isMobile,
        name: true,
        price: true,
      }),
    [isMobile]
  )

  return {
    columns,
    columnVisibility,
  }
}
