import { useRef } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { ApolloError } from '@apollo/client'
import {
  showErrorToast,
  showSuccessToast,
  useModalState,
} from '@npco/zeller-design-system'
import { useInvoiceAnalytics } from 'features/Invoicing/components/Invoices/hooks/useInvoiceAnalytics'
import { useSaveAndSendInvoice } from 'features/Invoicing/components/Invoices/hooks/useSaveAndSendInvoice'
import { useUpdateInvoice } from 'features/Invoicing/components/Invoices/hooks/useUpdateInvoice'
import { useGetInvoicePreview } from 'features/Invoicing/components/Invoices/Invoice/hooks/useGetInvoicePreview'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'

import { useCustomerProductTourStatus } from 'hooks/useCustomerProductTourStatus'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { CreateInvoice_createInvoice as CreateInvoiceResponse } from 'types/gql-types/CreateInvoice'
import { SaveAndSendInvoice_saveAndSendInvoice as SaveAndSendInvoiceResponse } from 'types/gql-types/SaveAndSendInvoice'

import { useCreateInvoice } from '../../../../hooks/useCreateInvoice'
import { isInvoiceFormValid, isInvoiceScheduled } from '../../../Invoice.utils'
import {
  convertFormFieldsToInvoiceInput,
  convertFormFieldsToUpdateInvoiceInput,
} from './useInvoiceCreateFormHandlers.utils'
import { useUpdateInvoiceDeliverySettings } from './useUpdateInvoiceDeliverySettings'

const {
  INVOICE,
  SETTINGS: { relative: settingsRelativePath },
} = ROOT.PORTAL.INVOICING.INVOICES

export const useInvoiceCreateFormHandlers = () => {
  const updateInvoiceDeliverySettings = useUpdateInvoiceDeliverySettings()

  const createdInvoiceRefNumber = useRef('')
  const isCreateFormDirty = useRef(false)

  const { getPreview, invoicePdf, isLoadingPreview, setInvoicePdf } =
    useGetInvoicePreview()

  const { trackSavedInvoice, trackSentInvoice } = useInvoiceAnalytics()

  const {
    createInvoice,
    data: invoiceCreated,
    isCreatingInvoice,
    resetCreatedInvoice,
  } = useCreateInvoice()
  const {
    data: invoiceSavedAndSent,
    saveAndSendInvoice,
    isSavingAndSendingInvoice: isSendingInvoice,
  } = useSaveAndSendInvoice()
  const { updateInvoice, isUpdatingInvoice } = useUpdateInvoice()
  const {
    isModalOpen: isScheduleModalOpen,
    openModal: openScheduleModal,
    closeModal: closeScheduleModal,
  } = useModalState()
  const {
    isModalOpen: isSendSuccessModalOpen,
    openModal: openSendSuccessModal,
    closeModal: closeSendSuccessModal,
  } = useModalState()
  const {
    isModalOpen: isScheduleSuccessModalOpen,
    openModal: openScheduleSuccessModal,
    closeModal: closeScheduleSuccessModal,
  } = useModalState()

  const { updateProductTourStatus } = useCustomerProductTourStatus()

  const navigate = useNavigate()

  const redirectToInvoices = () => navigate(ROOT.PORTAL.INVOICING.INVOICES.path)

  const saveInvoice = async (values: InvoiceFormFields) => {
    if (!createdInvoiceRefNumber.current) {
      const createdInvoice = await createInvoice(
        convertFormFieldsToInvoiceInput(values)
      )

      await updateProductTourStatus({ showInvoiceInstructions: false })

      return createdInvoice.data?.createInvoice
    }

    if (!isCreateFormDirty.current) {
      return createdInvoiceRefNumber.current
    }

    const updatedInvoice = await updateInvoice(
      convertFormFieldsToUpdateInvoiceInput(values)
    )

    return updatedInvoice.data?.updateInvoice
  }

  const handleSave = async ({
    handleResetForm,
    values,
    redirectUrl,
  }: {
    handleResetForm: (invoice: CreateInvoiceResponse) => void
    values: InvoiceFormFields
    redirectUrl: string
  }): Promise<void> => {
    try {
      const [invoice, validated] = await Promise.all([
        saveInvoice(values),
        isInvoiceFormValid(values),
      ])

      const scheduled = isInvoiceScheduled(values)

      if (!invoice) {
        showErrorToast(translate('page.invoice.saveInvoiceError'))
        return
      }

      // NOTE: skip track event when no changes to form, we only return the
      // reference number in this scenario
      if (typeof invoice !== 'string') {
        trackSavedInvoice(values)
      }

      const referenceNumber =
        typeof invoice === 'string' ? invoice : invoice.referenceNumber

      if (validated && scheduled) {
        openScheduleModal()
      } else {
        showSuccessToast(
          translate('page.invoice.saveInvoiceSuccess', {
            referenceNumber,
          })
        )
      }

      createdInvoiceRefNumber.current = referenceNumber

      // Redirect to edit invoice settings to properly handle routing and breadcrumbs
      if (redirectUrl?.includes(settingsRelativePath)) {
        navigate(`${INVOICE(referenceNumber).EDIT.path}/settings`)
        return
      }

      if (redirectUrl) {
        navigate(redirectUrl)
        return
      }

      if (typeof invoice !== 'string') {
        handleResetForm(invoice)
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        const hasInvalidEmail = /Invalid email/.test(err.message)

        if (hasInvalidEmail) {
          showErrorToast(translate('page.invoice.saveInvoiceInvalidEmailError'))
          return
        }
      }

      showErrorToast(translate('page.invoice.saveInvoiceError'))
    }
  }

  const handleSend = async ({
    handleResetForm,
    values,
  }: {
    handleResetForm: (invoice: SaveAndSendInvoiceResponse) => void
    values: InvoiceFormFields
  }): Promise<void> => {
    const errorMessage = values.schedule.sendEnabled
      ? translate('page.invoice.scheduleInvoiceError')
      : translate('page.invoice.sendInvoiceError')

    try {
      const invoice = await saveAndSendInvoice({
        ...convertFormFieldsToInvoiceInput(values),
        referenceNumber: createdInvoiceRefNumber.current || undefined,
      })

      if (!invoice.data?.saveAndSendInvoice) {
        showErrorToast(errorMessage)
        return
      }

      trackSentInvoice(values)

      await updateProductTourStatus({
        showInvoiceInstructions: false,
        showInvoiceSendViaInfo: false,
      })

      createdInvoiceRefNumber.current =
        invoice.data.saveAndSendInvoice.referenceNumber

      handleResetForm(invoice.data.saveAndSendInvoice)

      if (isInvoiceScheduled(values)) {
        openScheduleSuccessModal()
      } else {
        openSendSuccessModal()
      }

      updateInvoiceDeliverySettings({
        isEmailEnabled: values.delivery.email.isEnabled,
        isSMSEnabled: values.delivery.sms.isEnabled,
      })
    } catch (err) {
      if (err instanceof ApolloError) {
        const hasInvalidEmail = /Invalid email/.test(err.message)

        if (hasInvalidEmail) {
          showErrorToast(translate('page.invoice.sendInvoiceInvalidEmailError'))
          return
        }
      }

      showErrorToast(errorMessage)
    }
  }

  const handleClickSettings = () => {
    if (createdInvoiceRefNumber.current) {
      navigate(`${INVOICE(createdInvoiceRefNumber.current).EDIT.path}/settings`)
      return
    }
    navigate(settingsRelativePath)
  }

  const handleCreateAnotherInvoice = () => {
    resetCreatedInvoice()
    createdInvoiceRefNumber.current = ''
    isCreateFormDirty.current = false

    if (isScheduleSuccessModalOpen) {
      closeScheduleSuccessModal()
    }

    if (isSendSuccessModalOpen) {
      closeSendSuccessModal()
    }
  }

  return {
    closePreviewModal: () => setInvoicePdf(null),
    closeScheduleSuccessModal,
    closeScheduleModal,
    closeSendSuccessModal,
    handleClickSettings,
    handleClickPreview: getPreview,
    handleClose: redirectToInvoices,
    handleCreateAnotherInvoice,
    handleSave,
    handleSend,
    invoiceId: invoiceCreated?.id || invoiceSavedAndSent?.id,
    invoicePdf,
    isLoadingPreview,
    isSavingInvoice:
      (isCreatingInvoice || isUpdatingInvoice) && !isSendingInvoice,
    isSendingInvoice,
    isSendSuccessModalOpen,
    isScheduleModalOpen,
    isScheduleSuccessModalOpen,
    openSendSuccessModal,
    openScheduleSuccessModal,
    redirectTo: navigate,
    referenceNumber:
      invoiceCreated?.referenceNumber ?? createdInvoiceRefNumber.current ?? '',
    setIsCreateFormDirty: (isDirty: boolean) => {
      isCreateFormDirty.current = isDirty
    },
  }
}
