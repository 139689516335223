import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type UpdateScreensaverMutationVariables = Types.Exact<{
  input: Types.ScreensaverInput
}>

export type UpdateScreensaverMutationResponse = {
  __typename?: 'Mutation'
  updateScreensaver: boolean
}

export const UpdateScreensaver = gql`
  mutation UpdateScreensaver($input: ScreensaverInput!) {
    updateScreensaver(input: $input)
  }
` as unknown as TypedDocumentNode<
  UpdateScreensaverMutationResponse,
  UpdateScreensaverMutationVariables
>
export type UpdateScreensaverMutationFn = Apollo.MutationFunction<
  UpdateScreensaverMutationResponse,
  UpdateScreensaverMutationVariables
>

/**
 * __useUpdateScreensaverMutation__
 *
 * To run a mutation, you first call `useUpdateScreensaverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScreensaverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScreensaverMutation, { data, loading, error }] = useUpdateScreensaverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScreensaverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScreensaverMutationResponse,
    UpdateScreensaverMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateScreensaverMutationResponse,
    UpdateScreensaverMutationVariables
  >(UpdateScreensaver, options)
}
export type UpdateScreensaverMutationHookResult = ReturnType<
  typeof useUpdateScreensaverMutation
>
export type UpdateScreensaverMutationResult =
  Apollo.MutationResult<UpdateScreensaverMutationResponse>
export type UpdateScreensaverMutationOptions = Apollo.BaseMutationOptions<
  UpdateScreensaverMutationResponse,
  UpdateScreensaverMutationVariables
>
