import { gql } from '@apollo/client'

import { TransactionDetailsView } from '../../../TransactionDetailsView'

export const TransactionDetailsQuery = gql`
  query TransactionDetailsQuery($debitCardTransactionUuid: ID!) {
    getDebitCardTransactionV2(
      debitCardTransactionUuid: $debitCardTransactionUuid
    ) {
      __typename
      id
      ...TransactionDetailsViewDebitCardTransactionV2Fragment
    }
  }

  ${TransactionDetailsView.fragments.DebitCardTransactionV2}
`
