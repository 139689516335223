import { useCallback, useMemo } from 'react'
import {
  GetCategoriesFilterInput,
  GetCategoriesSortColumnName,
  GetCategoriesSortInput,
} from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Flex } from '@npco/zeller-design-system'

import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg'
import * as styled from 'components/MobileFilters/MobileFilters.styled'

import { useItemManagementContext } from '../../../ItemManagementContext/ItemManagementContext'
import { translations } from '../CategoriesMobileFilters.i18n'
import { CategoriesMobileFiltersVisibilityStates } from '../CategoriesMobileFilters.types'

interface FilterListProps {
  nextFilterInput?: GetCategoriesFilterInput | null
  nextSortInput?: GetCategoriesSortInput | null
  setVisibilityState: (state: CategoriesMobileFiltersVisibilityStates) => void
}

export const FilterList = ({
  nextSortInput,
  nextFilterInput,
  setVisibilityState,
}: FilterListProps) => {
  const { filterCategories } = useItemManagementContext()

  const t = useTranslations(translations)

  const filterByNameContent = useMemo(() => {
    if (!nextFilterInput?.idFilter) {
      return null
    }

    const categoryUuids = nextFilterInput?.idFilter?.categoryUuids ?? []

    if (categoryUuids.length === 1) {
      const category = filterCategories?.find(
        (item) => item.id === categoryUuids[0]
      )

      const categoryName = `(${category?.name || t('unknownCategory')})`

      return (
        <styled.MobileFiltersFilterContent>
          {categoryName}
        </styled.MobileFiltersFilterContent>
      )
    }
    if (categoryUuids.length > 1) {
      return (
        <styled.MobileFiltersFilterContent>
          {`(${categoryUuids.length})`}
        </styled.MobileFiltersFilterContent>
      )
    }

    return null
  }, [filterCategories, nextFilterInput?.idFilter, t])

  const filterBySearchContent = useMemo(() => {
    if (!nextFilterInput?.textSearchFilter) {
      return null
    }

    return (
      <styled.MobileFiltersFilterContent>
        {`(${nextFilterInput.textSearchFilter})`}
      </styled.MobileFiltersFilterContent>
    )
  }, [nextFilterInput?.textSearchFilter])

  const sortByFilterContent = useMemo(() => {
    if (!nextSortInput) {
      return null
    }

    const { ascending, columnName } = nextSortInput

    const isAscending = ascending === true
    const isDescending = ascending === false
    const isSortByName = columnName === GetCategoriesSortColumnName.Name
    const isSortByItemsNumber =
      columnName === GetCategoriesSortColumnName.ItemsNumber

    if (isAscending && isSortByName) {
      return (
        <styled.MobileFiltersFilterContent>
          {`(${t('categoryName')}: ${t('aToZ')})`}
        </styled.MobileFiltersFilterContent>
      )
    }
    if (isDescending && isSortByName) {
      return (
        <styled.MobileFiltersFilterContent>
          {`(${t('categoryName')}: ${t('zToA')})`}
        </styled.MobileFiltersFilterContent>
      )
    }
    if (isDescending && isSortByItemsNumber) {
      return (
        <styled.MobileFiltersFilterContent>
          {`(${t('items')}: ${t('highToLow')})`}
        </styled.MobileFiltersFilterContent>
      )
    }
    if (isAscending && isSortByItemsNumber) {
      return (
        <styled.MobileFiltersFilterContent>
          {`(${t('items')}: ${t('lowToHigh')})`}
        </styled.MobileFiltersFilterContent>
      )
    }

    return null
  }, [nextSortInput, t])

  const handleClick = useCallback(
    (state: CategoriesMobileFiltersVisibilityStates) => () =>
      setVisibilityState(state),
    [setVisibilityState]
  )

  return (
    <div data-testid="categories-mobile-filters-list">
      <styled.MobileFiltersButtonIconRightButton
        icon={ArrowRight}
        onClick={handleClick(CategoriesMobileFiltersVisibilityStates.SortBy)}
      >
        <Flex
          alignItems="baseline"
          data-testid="categories-mobile-filters-sort-by"
          overflow="hidden"
        >
          {t('sortBy')} {sortByFilterContent}
        </Flex>
      </styled.MobileFiltersButtonIconRightButton>
      <styled.MobileFiltersButtonIconRightButton
        icon={ArrowRight}
        onClick={handleClick(
          CategoriesMobileFiltersVisibilityStates.FilterByName
        )}
      >
        <Flex
          alignItems="baseline"
          data-testid="categories-mobile-filters-by-name"
          overflow="hidden"
        >
          {t('name')} {filterByNameContent}
        </Flex>
      </styled.MobileFiltersButtonIconRightButton>
      <styled.MobileFiltersButtonIconRightButton
        icon={ArrowRight}
        onClick={handleClick(
          CategoriesMobileFiltersVisibilityStates.FilterByKeyword
        )}
      >
        <Flex
          alignItems="baseline"
          data-testid="categories-mobile-filters-by-keyword"
          overflow="hidden"
        >
          {t('search')} {filterBySearchContent}
        </Flex>
      </styled.MobileFiltersButtonIconRightButton>
    </div>
  )
}
