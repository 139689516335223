import {
  BodyDefaultTypography,
  BodyLargeTypography,
  BodySmallTypography,
  ButtonGhostIconLeft,
  ButtonGhostIconRight,
  DrawerBasic,
  H2,
  StyledContentContainer,
  StyledGhostButton,
  StyledHeaderContainer,
  StyledIconLeft,
  StyledIconRight,
} from '@npco/zeller-design-system'
import { Flex } from 'reflexbox'
import styled from 'styled-components'

export const MobileFilters = styled(DrawerBasic)`
  padding: 0;

  ${H2} {
    ${BodyLargeTypography}
  }

  ${StyledContentContainer} {
    align-items: stretch;
    flex-flow: column;
    margin: 0;
    padding: 0;
  }

  ${StyledHeaderContainer} {
    padding-top: 12px;
    padding-bottom: 16px;
  }
`

export const MobileFiltersButtonWrapper = styled(Flex)`
  flex-flow: row nowrap;
  position: relative;
  bottom: 0;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px 16px;

  button {
    width: 100%;
  }

  button:first-of-type {
    margin-right: 8px;
  }
`

export const MobileFiltersContentWrapper = styled(Flex)`
  align-items: stretch;
  flex-flow: column;
  flex: 1 0 50%;
  padding: 0 8px;
  width: 100%;
`

export const MobileFiltersFilterContent = styled.span`
  ${BodySmallTypography};
  color: ${({ theme }) => theme.colors.BLUE_1000};
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const MobileFiltersButtonIconRightButton = styled(ButtonGhostIconRight)`
  ${BodyDefaultTypography};
  color: ${({ theme }) => theme.colors.BLACK_900};
  padding: 12px 24px;
  width: 100%;

  ${StyledGhostButton} {
    width: 100%;
  }

  div {
    width: 100%;
  }

  ${StyledIconRight} {
    right: 0;
    width: 16px;
  }
`

export const MobileFiltersButtonIconLeftButton = styled(ButtonGhostIconLeft)`
  ${BodyDefaultTypography};
  color: ${({ theme }) => theme.colors.BLACK_900};
  justify-content: flex-start;
  padding: 12px 16px;
  width: 100%;

  div {
    display: flex;
  }

  ${StyledGhostButton} {
    width: 100%;
  }

  ${StyledIconLeft} {
    left: 0;
    width: 16px;
  }
`
