import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  heading: 'Items',
  name: 'Name',
  noDataDescription:
    'There are no Items in this Category yet. Edit an Item and select this Category to see it appear here.',
  noDataTitle: 'No Items in this Category',
  primaryButtonLabel: 'Go to Items',
  sku: 'SKU: {sku}',
})
