import { ROOT } from 'const/routes'
import { getSessionStorageItem } from 'services/sessionStorage/utils'

import { LAST_DASHBOARD_TAB_KEY } from '../../utils/sessionStorageKeys'

export const useSavedDashboardRoute = () => {
  return (
    getSessionStorageItem<string>(LAST_DASHBOARD_TAB_KEY) ||
    ROOT.PORTAL.OVERVIEW.PAYMENTS.path
  )
}
