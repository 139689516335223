const SHOP = (basePath: string, customPageNamePrefix: string) => ({
  DEALS: {
    relative: 'deals',
    path: `${basePath}/deals`,
    customPageName: `${customPageNamePrefix}Deals`,
    CHECKOUT: {
      relative: 'checkout',
      path: `${basePath}/deals/checkout`,
      customPageName: `${customPageNamePrefix}DealsCheckout`,
    },
  },
})

export const ONBOARDING_SHOP = SHOP('/onboarding', 'OnboardingShop')

export const PORTAL_SHOP = SHOP('/portal', 'PortalShop')

export const ONBOARDING_SHOP_ROUTES = {
  DEALS: ONBOARDING_SHOP.DEALS.path,
  CHECKOUT: ONBOARDING_SHOP.DEALS.CHECKOUT.path,
}
