import { useEntitySavingsAccountProductQuery } from './graphql/EntitySavingsAccountProductQuery.generated'

export const useEntitySavingsAccountProduct = () => {
  const { data, loading } = useEntitySavingsAccountProductQuery()

  return {
    entity: data?.getEntity,
    isLoadingEntity: loading,
  }
}
