import { useCallback, useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { DepositExportFormat } from '@npco/mp-gql-types'
import { Box, Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'
import { rvIsDateRangeAppropriate } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'
import { Subscription } from 'rxjs'

import { ROUTE } from 'const/routes'
import { Deposit } from 'types/deposits'
import { DepositList } from 'pages/Deposits/DepositList/DepositList'
import { useFetchDeposits } from 'pages/Deposits/Deposits.hooks'
import { DepositsEmptyState } from 'pages/Deposits/DepositsEmptyState/DepositsEmptyState'
import { ExportDepositsModal } from 'pages/Deposits/ExportDepositsModal/ExportDepositsModal'
import {
  DashboardFiltersButton,
  DashboardSettingsLink,
} from 'layouts/DashboardLayout'
import { ButtonExportTransactions } from 'components/Buttons/ButtonExportTransactions/ButtonExportTransactions'
import { DepositFilters } from 'components/Filters/NewFilters/DepositFilters'
import { StyledWarningNotification } from 'components/Filters/NewFilters/DepositFilters.styled'
import { FiltersHeaderRow } from 'components/FiltersHeaderRow'
import { LoaderList } from 'components/LoaderList'
import { SkeletonH5 } from 'components/SkeletonLoader/SkeletonLoaderTypography'
import { StatementsButton } from 'components/Statements/StatementsButtons/StatementsButton/StatementsButton'
import { StatementsModal } from 'components/StatementsModal/StatementsModal'
import { page } from 'translations'

export const Deposits = () => {
  const [depositList, setDepositList] = useState<Deposit[][]>([])
  const [hasSettlements, setHasSettlements] = useState(false)
  const [areFiltersOpen, setAreFiltersOpen] = useState(false)
  const [isWarningVisible, setIsWarningVisible] = useState(false)
  const { isModalOpen, openModal, closeModal } = useModalState()
  const isDateRangeAppropriate = useReactiveVar(rvIsDateRangeAppropriate)
  const subscription = new Subscription()

  const {
    groupedDepositList$,
    areFiltersInDefaultState,
    isInitiallyLoading,
    fetchMoreDeposits,
    hasReachedEnd,
    hasLoaded,
  } = useFetchDeposits()

  const hasNoInitialDeposits = Boolean(
    areFiltersInDefaultState && hasLoaded && depositList.length < 1
  )

  useEffect(() => {
    if (isDateRangeAppropriate) {
      setIsWarningVisible(false)
    }
  }, [isDateRangeAppropriate])

  const handleClick = () => {
    if (!isDateRangeAppropriate) {
      setIsWarningVisible(true)
    } else {
      openModal()
    }
  }

  useEffect(() => {
    subscription.add(
      groupedDepositList$.subscribe((res) => {
        setDepositList(res)
      })
    )

    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!hasSettlements && depositList.length) {
      setHasSettlements(true)
    }
  }, [depositList, hasSettlements])

  const getContent = useCallback(() => {
    if (isInitiallyLoading) {
      return (
        <>
          <Flex pt="16px">
            <SkeletonH5 width="3.375rem" />
          </Flex>
          <LoaderList />
        </>
      )
    }

    if (!depositList.length) {
      return <DepositsEmptyState hasNoInitialDeposits={hasNoInitialDeposits} />
    }

    return (
      <DepositList
        groupedDeposits={depositList}
        fetchMoreDeposits={fetchMoreDeposits}
        hasReachedEnd={hasReachedEnd}
      />
    )
  }, [
    depositList,
    fetchMoreDeposits,
    hasNoInitialDeposits,
    hasReachedEnd,
    isInitiallyLoading,
  ])

  const getModalContent = useCallback(
    (fileFormat: DepositExportFormat) => (
      <ExportDepositsModal
        closeModal={closeModal}
        filename={page.exportFile.exportDepositsFilename}
        format={fileFormat}
      />
    ),
    [closeModal]
  )

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex
            alignItems="center"
            gap="10px"
            justifyContent="space-between"
            width="100%"
          >
            <Breadcrumb>
              <Breadcrumb.Item>
                <Breadcrumb.Text>{page.title.deposits}</Breadcrumb.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
            <Flex>
              {hasSettlements && (
                <DashboardFiltersButton
                  onClick={() => setAreFiltersOpen(!areFiltersOpen)}
                  isActive={areFiltersOpen}
                  disabled={hasNoInitialDeposits || !hasLoaded}
                  showIndicator={!areFiltersInDefaultState}
                />
              )}
              <DashboardSettingsLink
                path={ROUTE.PORTAL_PAYMENTS_DEPOSITS_SETTINGS}
              />
              {hasSettlements && (
                <StatementsButton
                  route={ROUTE.PORTAL_PAYMENTS_DEPOSITS_STATEMENTS}
                />
              )}
            </Flex>
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
      HeaderSecondaryRow={
        <FiltersHeaderRow isOpen={areFiltersOpen}>
          {isWarningVisible && (
            <Box mb="1rem">
              <StyledWarningNotification>
                {page.exportFile.depositsWarning}
              </StyledWarningNotification>
            </Box>
          )}

          <DepositFilters />

          <Box mt="16px">
            <ButtonExportTransactions
              label={page.exportFile.exportDeposits}
              onClick={handleClick}
            />
          </Box>
        </FiltersHeaderRow>
      }
    >
      {isModalOpen && (
        <StatementsModal
          closeModal={closeModal}
          title={page.exportFile.exportDeposits}
          type={DepositExportFormat}
          modalContent={getModalContent}
        />
      )}

      {getContent()}
    </PageTemplate>
  )
}
