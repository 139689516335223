import { useCreateSavingsAccountState } from '@npco/mp-utils-create-savings-account-routing'

import { CreateSavingsAccountForm } from './CreateSavingsAccountForm/CreateSavingsAccountForm'

export const CreateSavingsAccountModal = () => {
  const { state } = useCreateSavingsAccountState()

  if (state === null) {
    return null
  }

  return <CreateSavingsAccountForm />
}
