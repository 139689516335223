import { useEffect } from 'react'

import dayjs from 'utils/dayjs'

const THREE_SECONDS = 3000
const FIFTEEN_SECONDS = 15000
const FIFTEEN_MINUTES = 900000

interface Props {
  closeModal: () => void
  setHasTimePassed: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  hasError: boolean
  postLoadingCloseModalTime?: number
  loadingLapseCheckTime?: number
  loadingLapseTime?: number
}

export const useExportTransactionsModalContentCommon = ({
  closeModal,
  setHasTimePassed,
  isLoading,
  hasError,
  postLoadingCloseModalTime = THREE_SECONDS,
  loadingLapseCheckTime = FIFTEEN_SECONDS,
  loadingLapseTime = FIFTEEN_MINUTES,
}: Props) => {
  const mountingTime = dayjs()

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (!isLoading && !hasError) {
      timeoutId = setTimeout(() => {
        closeModal()
      }, postLoadingCloseModalTime)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [isLoading, closeModal, hasError, postLoadingCloseModalTime])

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>
    if (isLoading) {
      intervalId = setInterval(() => {
        const passedMiliseconds = dayjs().diff(mountingTime)
        if (passedMiliseconds >= loadingLapseTime) {
          setHasTimePassed(true)
          clearInterval(intervalId)
        }
      }, loadingLapseCheckTime)
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [
    isLoading,
    mountingTime,
    setHasTimePassed,
    loadingLapseTime,
    loadingLapseCheckTime,
  ])

  return {
    isLoading,
    hasError,
  }
}
