import { useReactiveVar } from '@apollo/client'

import { useAccountFiltersList } from 'components/DebitCardTransactionFilters/hooks/useAccountFiltersList/useAccountFiltersList'

import { rvSelectedContact } from '../../../rv-deprecated/contacts'
import * as styled from './AccountsFilter.styled'

export const AccountsFilter = () => {
  const selectedContact = useReactiveVar(rvSelectedContact)

  const defaultFilters = {
    selectedAccountID: undefined,
    selectedContactId: selectedContact?.id,
  }

  const { selectItems, visibleFilters, onSelect } = useAccountFiltersList({
    defaultFilters,
    hideContactsFilter: true,
  })

  return (
    <styled.FiltersList
      defaultFilters={defaultFilters}
      moreSelectItems={selectItems}
      onSelect={onSelect}
      visibleFilters={visibleFilters}
    />
  )
}
