import { useCallback, useRef, useState } from 'react'
import DayPicker from 'react-day-picker'
import { Dayjs } from 'dayjs'
import { isEqual } from 'lodash-es'

import dayjs from 'utils/dayjs'

import { DateRangeValue } from '../DateRange.types'
import { getTimeAdjustedDate } from './DateRange.utils'

export type DateRangeProps = {
  defaultRange?: DateRangeValue
  format?: 'date' | 'datetime'
  onRangeChange: (range: DateRangeValue | undefined) => void
  range: DateRangeValue | undefined
}

export const useDateRange = ({
  defaultRange,
  format,
  onRangeChange,
  range,
}: DateRangeProps) => {
  const [currentlyVisibleFirstMonth, setCurrentlyVisibleFirstMonth] = useState<
    Dayjs | undefined
  >(dayjs(range?.end).startOf('month'))
  const [firstSelectedDay, setFirstSelectedDay] = useState<Date | undefined>(
    range?.start
  )
  const [previousRangeEnd, setPreviousRangeEnd] = useState<Date | undefined>()

  const [hoveredDay, setHoveredDay] = useState<Date>()
  const isDateTime = format === 'datetime'

  const ref = useRef<DayPicker>(null)
  const handleSetPeriod = useCallback(
    (periodRange: Required<DateRangeValue>) => {
      const periodFirstMonth = dayjs(periodRange.start).startOf('month')
      const isFirstMonthVisible =
        currentlyVisibleFirstMonth?.isSame(periodFirstMonth) ??
        currentlyVisibleFirstMonth?.add(1, 'month').isSame(periodFirstMonth)

      if (periodRange.start && !isFirstMonthVisible) {
        ref.current?.showMonth(periodRange.start)
      }

      const periodRangeTimeAdjusted = {
        start: getTimeAdjustedDate({
          isDateTime,
          time: range?.start,
          date: periodRange.start,
        }),
        end: getTimeAdjustedDate({
          isDateTime,
          time: range?.end,
          date: periodRange.end,
        }),
      }

      onRangeChange(periodRangeTimeAdjusted)
    },
    [
      currentlyVisibleFirstMonth,
      isDateTime,
      onRangeChange,
      range?.end,
      range?.start,
    ]
  )

  const handleDayMouseEnter = (day: Date) => {
    if (range?.end && range.start) return
    setHoveredDay(day)
  }

  const handleDayMouseLeave = () => {
    setHoveredDay(undefined)
  }

  const onMonthChange = useCallback(
    (month: Date) => {
      setCurrentlyVisibleFirstMonth(dayjs(month).startOf('month'))
    },
    [setCurrentlyVisibleFirstMonth]
  )

  const handleDayClick = useCallback(
    (day: Date) => {
      const rangeEnd = range?.end ?? previousRangeEnd
      setPreviousRangeEnd(range?.end)

      if (dayjs(day).isAfter(dayjs().endOf('day'))) return

      const dateIfStart = getTimeAdjustedDate({
        isDateTime,
        time: range?.start,
        date: dayjs(day).startOf('day').toDate(),
      })

      const dateIfEnd = getTimeAdjustedDate({
        isDateTime,
        time: rangeEnd,
        date: dayjs(day).endOf('day').toDate(),
      })

      if (!firstSelectedDay) {
        setFirstSelectedDay(dateIfStart)
        onRangeChange({
          start: dateIfStart,
          end: undefined,
        })
        return
      }

      if (!range?.end) {
        if (dayjs(day).isBefore(firstSelectedDay)) {
          onRangeChange({
            start: dateIfStart,
            end: getTimeAdjustedDate({
              isDateTime,
              time: rangeEnd,
              date: dayjs(firstSelectedDay).endOf('day').toDate(),
            }),
          })
        } else {
          onRangeChange({
            start: firstSelectedDay,
            end: dateIfEnd,
          })
        }
        return
      }

      if (range.end) {
        setHoveredDay(undefined)
        setFirstSelectedDay(dateIfStart)
        onRangeChange({
          start: dateIfStart,
          end: undefined,
        })
      }
    },
    [firstSelectedDay, isDateTime, onRangeChange, previousRangeEnd, range]
  )

  const handleResetClick = useCallback(() => {
    onRangeChange(defaultRange)
    setFirstSelectedDay(undefined)
    setHoveredDay(undefined)
  }, [defaultRange, onRangeChange])

  const resetDisabled = isEqual(
    range,
    defaultRange ?? { start: undefined, end: undefined }
  )
  return {
    currentlyVisibleFirstMonth: currentlyVisibleFirstMonth?.toDate(),
    handleDayClick,
    handleDayMouseEnter,
    handleDayMouseLeave,
    handleResetClick,
    handleSetPeriod,
    onMonthChange,
    ref,
    resetDisabled,
    hoveredDay,
    setCurrentlyVisibleFirstMonth,
  }
}
