import { gql } from '@apollo/client'

export const GetDeposits = gql`
  query GetDeposits(
    $filter: DepositFilterInput
    $limit: Int!
    $nextToken: DepositNextTokenInput
  ) {
    getDeposits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      deposits {
        id
        siteName
        timestamp
        skippedTimestamp
        status
        reference
        shortId
        processedAmount
        processedFeeAmount
        refundsAmount
        withHeld
        rolledOverProcessedAmount
        rolledOverFeeAmount
        amountSettled
        accountName
        tradingSlot
        siteUuid
      }
      nextToken {
        type
      }
    }
  }
`

export const GetDeposit = gql`
  query GetDeposit($depositUuid: ID!) {
    getDeposit(depositUuid: $depositUuid) {
      id
      siteName
      timestamp
      skippedTimestamp
      status
      reference
      shortId
      processedAmount
      processedFeeAmount
      refundsAmount
      withHeld
      rolledOverProcessedAmount
      rolledOverFeeAmount
      amountSettled
      accountName
      tradingSlot
      siteUuid
    }
  }
`

export const GetEntityForDeposits = gql`
  query GetEntityForDeposits {
    getEntity {
      id
      remitToCard
      depositAccountUuid
      debitCardAccountUuid
    }
  }
`

export const GetThirdPartyBankAccounts = gql`
  query GetThirdPartyBankAccounts($limit: Int!, $nextToken: String) {
    getThirdPartyBankAccounts(limit: $limit, nextToken: $nextToken) {
      nextToken
      accounts {
        id
        accountBsb
        accountName
        accountNumber
        name
      }
    }
  }
`

export const ExportDeposit = gql`
  query ExportDeposit(
    $filter: DepositFilterInput!
    $format: DepositExportFormat!
  ) {
    exportDeposit(filter: $filter, format: $format) {
      downloadLink
      expire
    }
  }
`

export const ExportDepositStatement = gql`
  query ExportDepositStatement(
    $filter: DepositFilterInput!
    $format: DepositStatementExportFormat!
  ) {
    exportDepositStatement(filter: $filter, format: $format) {
      downloadLink
      expire
    }
  }
`
