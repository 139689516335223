import { gql } from '@apollo/client'

export const SendReminder = gql`
  mutation SendReminder($input: SendReminderInput!) {
    sendReminder(input: $input) {
      __typename
      id
      activities {
        __typename
        balance
        cnpTxnRefNum
        completedTime
        contactName
        contactUuid
        dueDate
        failureReason
        id
        paidAmount
        status
        reminderIndex
        title
        type
      }
    }
  }
`
