import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type UpdateDevicesMutationVariables = Types.Exact<{
  siteUuid: Types.Scalars['ID']['input']
  input: Types.SiteDevicesSettingsInput
}>

export type UpdateDevicesMutationResponse = {
  __typename?: 'Mutation'
  updateDevices: boolean
}

export const UpdateDevices = gql`
  mutation UpdateDevices($siteUuid: ID!, $input: SiteDevicesSettingsInput!) {
    updateDevices(siteUuid: $siteUuid, input: $input)
  }
` as unknown as TypedDocumentNode<
  UpdateDevicesMutationResponse,
  UpdateDevicesMutationVariables
>
export type UpdateDevicesMutationFn = Apollo.MutationFunction<
  UpdateDevicesMutationResponse,
  UpdateDevicesMutationVariables
>

/**
 * __useUpdateDevicesMutation__
 *
 * To run a mutation, you first call `useUpdateDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDevicesMutation, { data, loading, error }] = useUpdateDevicesMutation({
 *   variables: {
 *      siteUuid: // value for 'siteUuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDevicesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDevicesMutationResponse,
    UpdateDevicesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDevicesMutationResponse,
    UpdateDevicesMutationVariables
  >(UpdateDevices, options)
}
export type UpdateDevicesMutationHookResult = ReturnType<
  typeof useUpdateDevicesMutation
>
export type UpdateDevicesMutationResult =
  Apollo.MutationResult<UpdateDevicesMutationResponse>
export type UpdateDevicesMutationOptions = Apollo.BaseMutationOptions<
  UpdateDevicesMutationResponse,
  UpdateDevicesMutationVariables
>
