import { ApolloCache } from '@apollo/client'
import { DebitCardAccountType } from '@npco/mp-gql-types'
import { AccountsListQuery } from 'features/Accounts/Accounts/hooks/useAccountsListQuery/graphql/AccountsListQuery.generated'
import { ListItemAccountsDebitCardAccountV2Fragment } from 'features/Accounts/Accounts/ListAccounts/ListItemAccounts/ListItemAccounts.generated'

import { AccountUpdateFragment } from './graphql/AccountUpdateFragment.generated'

type ListPosition = 'start-of-list' | 'end-of-list'

const addToList = <T>(list: T[], item: T, position: ListPosition) => {
  return position === 'start-of-list' ? [item, ...list] : [...list, item]
}

export const cacheNewAccount = (
  cache: ApolloCache<any>,
  accountUpdate: AccountUpdateFragment,
  position: ListPosition = 'end-of-list'
) => {
  const newAccount: ListItemAccountsDebitCardAccountV2Fragment = {
    ...accountUpdate,
    __typename: 'DebitCardAccountV2' as const,
    cardsCount: {
      debit: 0,
      total: 0,
      expense: 0,
    },
  }

  const newAccountIsDebitAccount =
    newAccount.type === DebitCardAccountType.ZLR_DEBIT
  const newAccountIsSavingsAccount =
    newAccount.type === DebitCardAccountType.SAVINGS

  cache.updateQuery({ query: AccountsListQuery }, (data) => {
    if (!data) {
      return undefined
    }

    if (
      newAccount.type === DebitCardAccountType.ZLR_DEBIT &&
      data.getDebitCardAccountsV2.accounts.some(
        (account) => account?.id === newAccount.id
      )
    ) {
      return undefined
    }

    if (
      newAccount.type === DebitCardAccountType.SAVINGS &&
      data.getSavingsAccounts.some((account) => account?.id === newAccount.id)
    ) {
      return undefined
    }

    return {
      ...data,
      getDebitCardAccountsV2: {
        ...data.getDebitCardAccountsV2,
        accounts: newAccountIsDebitAccount
          ? addToList(
              data.getDebitCardAccountsV2.accounts,
              newAccount,
              position
            )
          : data.getDebitCardAccountsV2.accounts,
      },
      getSavingsAccounts: newAccountIsSavingsAccount
        ? addToList(data.getSavingsAccounts, newAccount, position)
        : data.getSavingsAccounts,
    }
  })
}
