import { gql } from '@apollo/client'
import { Box, Flex } from '@npco/zeller-design-system'

import { AccountWithAvatar } from './AccountWithAvatar/AccountWithAvatar'
import {
  UserAndAccountDetailsCustomerFragment,
  UserAndAccountDetailsDebitCardAccountV2Fragment,
} from './UserAndAccountDetails.generated'
import { UserWithAvatar } from './UserWithAvatar/UserWithAvatar'

interface UserAndAccountDetailsProps {
  user: UserAndAccountDetailsCustomerFragment | null
  account: UserAndAccountDetailsDebitCardAccountV2Fragment
}

export const UserAndAccountDetails = ({
  user,
  account,
}: UserAndAccountDetailsProps) => {
  return (
    <Flex
      width={1}
      flexDirection={{ _: 'column', XS: 'row' }}
      justifyContent={{ _: 'auto', XS: 'space-between' }}
      alignItems={{ _: 'auto', XS: 'center' }}
    >
      {user && (
        <Box width={{ _: 1, XS: 1 / 2 }}>
          <UserWithAvatar user={user} />
        </Box>
      )}
      <Box width={{ _: 1, XS: user ? 1 / 2 : 1 }}>
        <AccountWithAvatar account={account} />
      </Box>
    </Flex>
  )
}

UserAndAccountDetails.fragments = {
  Customer: gql`
    fragment UserAndAccountDetailsCustomerFragment on Customer {
      ...UserWithAvatarCustomerFragment
    }

    ${UserWithAvatar.fragments.Customer}
  `,
  DebitCardAccountV2: gql`
    fragment UserAndAccountDetailsDebitCardAccountV2Fragment on DebitCardAccountV2 {
      ...AccountWithAvatarDebitCardAccountV2Fragment
    }

    ${AccountWithAvatar.fragments.DebitCardAccountV2}
  `,
}
