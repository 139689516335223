import { ApolloError, gql } from '@apollo/client'
import {
  Box,
  COLOR,
  Flex,
  InfoFilledIcon,
  SelectSearchInput,
  TOOLTIP_ZINDEX,
  TooltipButton,
} from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'
import { ErrorContent } from 'pages/GlobalModals/AddCardModal/pages/shared/ErrorContent/ErrorContent'
import { TrackScroll } from 'pages/GlobalModals/AddCardModal/pages/shared/TrackScroll/TrackScroll'
import { StyledFormBodyWithOffset } from 'pages/GlobalModals/GlobalModals.styled'

import { CardHolderInfoBox } from '../../../../shared/CardHolderInfoBox/CardHolderInfoBox'
import { CardholderList } from './CardholderList/CardholderList'
import {
  SearchableCardholderFragment,
  useSearchCardholders,
} from './hooks/useSearchCardholders'
import { SearchableCardholderListCustomerFragment } from './SearchableCardholderList.generated'

interface SearchableCardholderListProps {
  cardholders: SearchableCardholderListCustomerFragment[]
  isLoading: boolean
  error?: ApolloError
  refetch: () => void
}

export const SearchableCardholderList = ({
  cardholders,
  isLoading,
  error,
  refetch,
}: SearchableCardholderListProps) => {
  const { searchInput, setSearchInput, filteredCardholders } =
    useSearchCardholders({ cardholders })

  const isMobile = useIsMobileResolution()

  if (error) {
    return (
      <ErrorContent
        title={translate(
          'page.addCardModal.selectCardholder.cardholderErrorPage.title'
        )}
        subtitle={translate(
          'page.addCardModal.selectCardholder.cardholderErrorPage.subtitle'
        )}
        refetch={refetch}
      />
    )
  }

  return (
    <>
      <StyledFormBodyWithOffset canScroll={false} mt="12px">
        <Flex width="100%">
          <Box width="100%">
            <SelectSearchInput
              value={searchInput}
              setValue={setSearchInput}
              hasBgColor={isMobile}
              isClearable
              placeholder={translate(
                'page.addCardModal.selectCardholder.searchPlaceholder'
              )}
            />
          </Box>
          <Flex px="26px">
            <TooltipButton
              tooltipIcon={<InfoFilledIcon color={COLOR.BLUE_1000} />}
              zIndex={parseInt(TOOLTIP_ZINDEX, 10)}
              placement="top-end"
              showArrow={false}
              label={translate(
                'page.addCardModal.selectCardholder.searchTooltipLabel'
              )}
            >
              <Box maxWidth="384px" textAlign="center">
                {translate('page.addCardModal.selectCardholder.searchTooltip')}
              </Box>
            </TooltipButton>
          </Flex>
        </Flex>
      </StyledFormBodyWithOffset>
      <StyledFormBodyWithOffset canScroll={false} mt="24px">
        <CardHolderInfoBox />
      </StyledFormBodyWithOffset>
      <StyledFormBodyWithOffset mt="14px" pt="2px">
        <CardholderList
          customers={filteredCardholders}
          isLoading={isLoading}
          searchText={searchInput}
        />
        <TrackScroll searchInput={searchInput} />
      </StyledFormBodyWithOffset>
    </>
  )
}

SearchableCardholderList.fragments = {
  Customer: gql`
    fragment SearchableCardholderListCustomerFragment on Customer {
      ...CardholderListCustomerFragment
      ...SearchableCardholderFragment
    }
    ${CardholderList.fragments.Customer}
    ${SearchableCardholderFragment}
  `,
}
