import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { TabItemProps } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { page } from 'translations'

const TabValues = {
  GENERAL:
    ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.GENERAL.path,
  RECEIPT:
    ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.RECEIPT.path,
  USERS:
    ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.USERS.path,
}

export const useManageXeroPaymentServicesTabs = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const tabs: TabItemProps[] = useMemo(
    () => [
      {
        id: 'manage-xero-payment-services-tabs-general',
        children: page.settings.connections.xeroPaymentServices.tabs.general,
        value: TabValues.GENERAL,
        onClick: () => navigate(TabValues.GENERAL),
      },
      {
        id: 'manage-xero-payment-services-tabs-receipt',
        children: page.settings.connections.xeroPaymentServices.tabs.receipt,
        value: TabValues.RECEIPT,
        onClick: () => navigate(TabValues.RECEIPT),
      },
      {
        id: 'manage-xero-payment-services-tabs-users',
        children: page.settings.connections.xeroPaymentServices.tabs.users,
        value: TabValues.USERS,
        onClick: () => navigate(TabValues.USERS),
      },
    ],
    [navigate]
  )

  return { tabs, currentTab: (value: string) => pathname.includes(value) }
}
