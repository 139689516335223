import { useMemo, useState } from 'react'
import DayPicker from 'react-day-picker'
import { FloatingFocusManager } from '@floating-ui/react'
import { useTranslations } from '@npco/utils-translations'
import {
  BUTTON_SIZE,
  ButtonGhost,
  Flex,
  PillSelectTrigger,
} from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import { translations } from './DateRange.i18n'
import * as styled from './DateRange.styled'
import { DateRangeValue } from './DateRange.types'
import { getTriggerDateLabel } from './DateRange/DateRange.utils'
import { DateRangeInputs } from './DateRange/DateRangeInputs'
import { PeriodButtons } from './DateRange/PeriodButtons'
import { translations as periodTranslations } from './DateRange/PeriodButtons.i18n'
import { getPeriodKey } from './DateRange/PeriodButtons.utils'
import { DateRangeProps, useDateRange } from './DateRange/useDateRange'
import { useFloatingPill } from './useFloatingPill'

export const DateRange = ({
  defaultRange,
  range: validRange,
  format = 'datetime',
  onRangeChange,
}: DateRangeProps) => {
  const t = useTranslations(translations)
  const tPeriod = useTranslations(periodTranslations)
  const tShared = useTranslations(translationsShared)
  const { context, floatingProps, isOpen, referenceProps, transitionProps } =
    useFloatingPill()

  const [range, setRange] = useState<DateRangeValue | undefined>({
    start: validRange?.start ?? defaultRange?.start,
    end: validRange?.end ?? defaultRange?.end,
  })

  const handleOnRangeChange = (newRange: DateRangeValue | undefined) => {
    setRange(newRange)
    if (
      (newRange?.start && newRange.end) ||
      (!newRange?.start && !newRange?.end)
    ) {
      onRangeChange(newRange)
    }
  }

  const {
    currentlyVisibleFirstMonth,
    handleDayClick,
    handleDayMouseEnter,
    handleDayMouseLeave,
    handleResetClick,
    handleSetPeriod,
    hoveredDay,
    onMonthChange,
    ref,
    resetDisabled,
  } = useDateRange({
    format,
    defaultRange,
    range,
    onRangeChange: handleOnRangeChange,
  })

  const modifiers = {
    start: range?.start,
    end: range?.end,
    endNotSelected: range?.end,
  }
  const periodKey = range?.end && getPeriodKey(range)
  const periodLabel = periodKey ? tPeriod(periodKey) : ''
  const triggerLabel =
    periodLabel ||
    (range?.start && range.end && getTriggerDateLabel(range)) ||
    t('defaultLabel')
  const selectedDays = useMemo(
    () => [
      range?.start,
      {
        from: range?.start,
        to: range?.end ?? hoveredDay,
      },
    ],
    [hoveredDay, range?.end, range?.start]
  )

  return (
    <>
      <Flex {...referenceProps}>
        <PillSelectTrigger
          open={isOpen}
          label={triggerLabel}
          filled={!!range?.start}
        />
      </Flex>
      {transitionProps.isMounted && (
        <FloatingFocusManager context={context} modal={false}>
          <div {...floatingProps}>
            <styled.DatePickerBackground style={transitionProps.style}>
              <styled.DatePickerWrapper
                isDateTime={format === 'datetime'}
                data-testid="day-time-range-picker"
              >
                <DayPicker
                  disabledDays={{ after: new Date() }}
                  modifiers={modifiers}
                  month={currentlyVisibleFirstMonth}
                  numberOfMonths={2}
                  onDayClick={handleDayClick}
                  onDayMouseEnter={handleDayMouseEnter}
                  onDayMouseLeave={handleDayMouseLeave}
                  onMonthChange={onMonthChange}
                  ref={ref}
                  selectedDays={selectedDays}
                  toMonth={new Date()}
                />
              </styled.DatePickerWrapper>
              <DateRangeInputs
                range={range}
                hoveredDay={hoveredDay}
                format={format}
                onRangeChange={handleOnRangeChange}
              />
              <styled.PeriodRow>
                <Flex gap="8px">
                  <PeriodButtons
                    handleSetPeriod={handleSetPeriod}
                    defaultPeriod={periodKey}
                  />
                </Flex>
                <ButtonGhost
                  size={BUTTON_SIZE.XSMALL}
                  aria-label={tShared('reset')}
                  onClick={handleResetClick}
                  disabled={resetDisabled}
                >
                  {tShared('reset')}
                </ButtonGhost>
              </styled.PeriodRow>
            </styled.DatePickerBackground>
          </div>
        </FloatingFocusManager>
      )}
    </>
  )
}
