import { Box } from '@npco/zeller-design-system'

import { LinkCardError } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'

import { CardIdField } from './CardIdField'
import { ErrorInfoBox } from './ErrorInfoBox/ErrorInfoBox'
import { LinkCardIdInfoBox } from './LinkCardInfoBox'

interface LinkCardIdFieldsProps {
  linkCardError: LinkCardError | undefined
}

export const LinkCardIdFields = ({ linkCardError }: LinkCardIdFieldsProps) => {
  return (
    <Box>
      <Box pb="16px">
        {linkCardError ? (
          <ErrorInfoBox linkCardError={linkCardError} />
        ) : (
          <LinkCardIdInfoBox />
        )}
      </Box>
      <CardIdField />
    </Box>
  )
}
