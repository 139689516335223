import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import { ModalBasic, useModalState } from '@npco/zeller-design-system'
import { ModalVerificationRequired } from 'features/MFA'

import { ROUTE } from 'const/routes'
import { ButtonBack } from 'components/Buttons/ButtonBack'
import { RegisterPhone as RegisterPhoneComponent } from 'components/RegisterPhone'
import { useRegisterPhone } from 'components/RegisterPhone/useRegisterPhone'
import { SpinnerWrapped } from 'components/Spinner'
import { page, shared } from 'translations'

import { useHasElevatedScope } from '../Personal/useHasElevatedScope'
import * as styled from './ChangeNumberPage.styled'

export const BackButtonChangeNumber = () => {
  const history = useHistory()
  return (
    <ButtonBack
      onClick={() => history.push(ROUTE.PORTAL_SETTINGS_PROFILE_SECURITY)}
    />
  )
}

export const ChangeNumberPage = () => {
  const history = useHistory()
  const { handleSubmitMfa, isLoadingRegisterMfa } = useRegisterPhone({
    url: ROUTE.PORTAL_SETTINGS_PROFILE_SECURITY_VALIDATE_CODE,
  })

  const { isModalOpen, closeModal, openModal } = useModalState(false)

  const returnToSecurityPage = useCallback(() => {
    history.push(ROUTE.PORTAL_SETTINGS_PROFILE_SECURITY)
  }, [history])

  const { hasElevatedScope, stepUpAuth, loading, refetch } =
    useHasElevatedScope()

  useEffect(() => {
    if (!hasElevatedScope && !loading) {
      openModal()
    }

    return () => closeModal()
  }, [loading, closeModal, hasElevatedScope, openModal])

  useEffect(() => {
    refetch()
  }, [refetch])

  const onModalClose = useCallback(() => {
    closeModal()
    returnToSecurityPage()
  }, [closeModal, returnToSecurityPage])

  return (
    <>
      {loading || isLoadingRegisterMfa ? (
        <SpinnerWrapped variant="top" />
      ) : (
        <>
          <styled.Description>
            {page.settings.profileSettings.changeNumberDescription}
          </styled.Description>
          <styled.Subtitle>
            {page.settings.profileSettings.newPhoneNumber}
          </styled.Subtitle>
          <RegisterPhoneComponent
            handleSubmit={handleSubmitMfa}
            backButton={<BackButtonChangeNumber />}
            submitButtonText={shared.next}
          />
        </>
      )}
      <ModalBasic isOpen={isModalOpen} onCancel={onModalClose}>
        <ModalVerificationRequired
          onCancel={onModalClose}
          onRedirect={stepUpAuth}
        />
      </ModalBasic>
    </>
  )
}
