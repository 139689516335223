import { useMutation } from '@apollo/client'
import { UpdateItemInput } from '@npco/mp-gql-types'

import {
  UpdateInvoiceItem as UpdateInvoiceItemResponse,
  UpdateInvoiceItemVariables,
} from 'types/gql-types/UpdateInvoiceItem'

import { UpdateInvoiceItem } from './graphql/updateInvoiceItem'

export const useUpdateInvoiceItem = () => {
  const [updateInvoiceItem, { loading: isUpdatingItem }] = useMutation<
    UpdateInvoiceItemResponse,
    UpdateInvoiceItemVariables
  >(UpdateInvoiceItem)

  return {
    updateInvoiceItem: (item: UpdateItemInput) =>
      updateInvoiceItem({ variables: { item } }),
    isUpdatingItem,
  }
}
