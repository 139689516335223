import { mock } from '@npco/utils-apollo-testing-link'

import {
  GetProducts,
  GetProductsQueryResponse,
  GetProductsQueryVariables,
} from '../GetProducts.generated'

type ProductNode = NonNullable<
  NonNullable<GetProductsQueryResponse['site']['products']['edges']>[number]
>['node']

type ProductOptionsType = ProductNode['productOptions']

export const MOCK_PICKLIST_OPTIONS = {
  edges: [
    {
      node: {
        __typename: 'MultipleChoiceOption',
        entityId: 23,
        values: {
          edges: [
            {
              node: {
                entityId: 136,
                __typename: 'ProductPickListOptionValue',
              },
              __typename: 'ProductOptionValueEdge',
            },
          ],
          __typename: 'ProductOptionValueConnection',
        },
      },
      __typename: 'ProductOptionEdge',
    },
    {
      node: {
        __typename: 'MultipleChoiceOption',
        entityId: 24,
        values: {
          edges: [
            {
              node: {
                entityId: 137,
                __typename: 'ProductPickListOptionValue',
              },
              __typename: 'ProductOptionValueEdge',
            },
            {
              node: {
                entityId: 139,
                __typename: 'ProductPickListOptionValue',
              },
              __typename: 'ProductOptionValueEdge',
            },
          ],
          __typename: 'ProductOptionValueConnection',
        },
      },
      __typename: 'ProductOptionEdge',
    },
  ],
  __typename: 'ProductOptionConnection',
} satisfies ProductOptionsType

export const GET_PRODUCT_RESPONSE = {
  site: {
    products: {
      edges: [
        {
          node: {
            __typename: 'Product',
            entityId: 150,
            name: 'Zeller Terminal 1 Bundle - White',
            description:
              "<p><strong>Exclusive to myzeller.com</strong>. Buy Australia's favourite<br />EFTPOS solution with 5 free items, <strong>for only $99</strong>.</p>",
            sku: 'TER-WHT-1-AU-BDL',
            plainTextDescription:
              "Exclusive to myzeller.com. Buy Australia's favouriteEFTPOS solution with 5 free items, for only $99.",
            defaultImage: {
              __typename: 'Image',
              url320wide:
                'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/150/843/T1_-_White_side__03907.1719893759.png',
              altText: 'Zeller Terminal 1 White',
            },
            images: {
              __typename: 'ImageConnection',
              edges: [
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/150/843/T1_-_White_side__03907.1719893759.png',
                    altText: 'Zeller Terminal',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/150/839/Debit_Card_-_White__96932.1719893755.png',
                    altText: 'Zeller Debit Card',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/150/889/T1_Dock_-_White__17594.1722916410.png',
                    altText: 'Charging Dock',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/150/838/T1_Charger_-_White__32573.1719893755.png',
                    altText: 'Charging Cable',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/150/840/Sticker_Set__53512.1719893756.png',
                    altText: 'Marketing Kit',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/150/841/Receipt_Paper__61741.1719893756.png',
                    altText: 'Receipt Roll',
                  },
                },
              ],
            },
            prices: {
              __typename: 'Prices',
              basePrice: {
                __typename: 'Money',
                value: 159,
              },
              salePrice: {
                __typename: 'Money',
                value: 99,
              },
              price: {
                __typename: 'Money',
                value: 99,
              },
            },
            productOptions: {
              __typename: 'ProductOptionConnection',
              edges: [
                {
                  __typename: 'ProductOptionEdge',
                  node: {
                    __typename: 'MultipleChoiceOption',
                    entityId: 23,
                    values: {
                      __typename: 'ProductOptionValueConnection',
                      edges: [
                        {
                          __typename: 'ProductOptionValueEdge',
                          node: {
                            __typename: 'ProductPickListOptionValue',
                            entityId: 136,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  __typename: 'ProductOptionEdge',
                  node: {
                    __typename: 'MultipleChoiceOption',
                    entityId: 24,
                    values: {
                      __typename: 'ProductOptionValueConnection',
                      edges: [
                        {
                          __typename: 'ProductOptionValueEdge',
                          node: {
                            __typename: 'ProductPickListOptionValue',
                            entityId: 137,
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
            categories: {
              __typename: 'CategoryConnection',
              edges: [
                {
                  __typename: 'CategoryEdge',
                  node: {
                    __typename: 'Category',
                    entityId: 35,
                    name: 'T1 Terminals',
                  },
                },
              ],
            },
            customFields: {
              __typename: 'CustomFieldConnection',
              edges: [
                {
                  __typename: 'CustomFieldEdge',
                  node: {
                    __typename: 'CustomField',
                    entityId: 72,
                    name: 'Colour',
                    value: 'White',
                  },
                },
                {
                  __typename: 'CustomFieldEdge',
                  node: {
                    __typename: 'CustomField',
                    entityId: 93,
                    name: 'ProductDisplayName',
                    value: 'Terminal 1',
                  },
                },
              ],
            },
          },
        },
        {
          node: {
            __typename: 'Product',
            entityId: 151,
            name: 'Zeller Terminal 1 Bundle - Black',
            description:
              "<p><strong>Exclusive to myzeller.com</strong>. Buy Australia's favourite<br />EFTPOS solution with 5 free items, <strong>for only $99</strong>.</p>",
            sku: 'TER-BLK-1-AU-BDL',
            plainTextDescription:
              "Exclusive to myzeller.com. Buy Australia's favouriteEFTPOS solution with 5 free items, for only $99.",
            defaultImage: {
              __typename: 'Image',
              url320wide:
                'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/151/835/T1_-_Black_side__51013.1719893779.png',
              altText: 'Zeller Terminal 1 Black',
            },
            images: {
              __typename: 'ImageConnection',
              edges: [
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/151/835/T1_-_Black_side__51013.1719893779.png',
                    altText: 'Zeller Terminal 1',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/151/832/Debit_Card_-_Black__62926.1719893779.png',
                    altText: 'Zeller Debit Card',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/151/888/T1_Dock_-_Black__25713.1722916382.png',
                    altText: 'Charging Dock',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/151/837/T1_Charger_-_Black__07238.1722916379.png',
                    altText: 'Charging Cable',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/151/833/Sticker_Set__80181.1722916379.png',
                    altText: 'Marketing Kit',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/151/834/Receipt_Paper__11083.1722916379.png',
                    altText: 'Receipt Roll',
                  },
                },
              ],
            },
            prices: {
              __typename: 'Prices',
              basePrice: {
                __typename: 'Money',
                value: 159,
              },
              salePrice: {
                __typename: 'Money',
                value: 99,
              },
              price: {
                __typename: 'Money',
                value: 99,
              },
            },
            productOptions: {
              __typename: 'ProductOptionConnection',
              edges: [
                {
                  __typename: 'ProductOptionEdge',
                  node: {
                    __typename: 'MultipleChoiceOption',
                    entityId: 27,
                    values: {
                      __typename: 'ProductOptionValueConnection',
                      edges: [
                        {
                          __typename: 'ProductOptionValueEdge',
                          node: {
                            __typename: 'ProductPickListOptionValue',
                            entityId: 139,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  __typename: 'ProductOptionEdge',
                  node: {
                    __typename: 'MultipleChoiceOption',
                    entityId: 28,
                    values: {
                      __typename: 'ProductOptionValueConnection',
                      edges: [
                        {
                          __typename: 'ProductOptionValueEdge',
                          node: {
                            __typename: 'ProductPickListOptionValue',
                            entityId: 140,
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
            categories: {
              __typename: 'CategoryConnection',
              edges: [
                {
                  __typename: 'CategoryEdge',
                  node: {
                    __typename: 'Category',
                    entityId: 35,
                    name: 'T1 Terminals',
                  },
                },
              ],
            },
            customFields: {
              __typename: 'CustomFieldConnection',
              edges: [
                {
                  __typename: 'CustomFieldEdge',
                  node: {
                    __typename: 'CustomField',
                    entityId: 89,
                    name: 'Colour',
                    value: 'Black',
                  },
                },
                {
                  __typename: 'CustomFieldEdge',
                  node: {
                    __typename: 'CustomField',
                    entityId: 94,
                    name: 'ProductDisplayName',
                    value: 'Terminal 1',
                  },
                },
              ],
            },
          },
        },
        {
          node: {
            __typename: 'Product',
            entityId: 161,
            name: 'Terminal 2 White',
            description: '',
            sku: 'TER-WHT-2-AU-ONB',
            plainTextDescription: '',
            defaultImage: {
              __typename: 'Image',
              url320wide:
                'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/161/883/T2_-_White_Side__24722.1722832553.png',
              altText: 'Zeller Terminal 2 White',
            },
            images: {
              __typename: 'ImageConnection',
              edges: [
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/161/883/T2_-_White_Side__24722.1722832553.png',
                    altText: 'Zeller Terminal 2 White',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/161/875/Debit_Card_-_White__56199.1722832550.png',
                    altText: 'Zeller Debit Card',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/161/876/T2_Charger_-_White__22560.1722832550.png',
                    altText: 'Charging Cable',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/161/874/Sticker_Set__67544.1722832550.png',
                    altText: 'Marketing Kit',
                  },
                },
              ],
            },
            prices: {
              __typename: 'Prices',
              basePrice: {
                __typename: 'Money',
                value: 199,
              },
              salePrice: {
                __typename: 'Money',
                value: 199,
              },
              price: {
                __typename: 'Money',
                value: 199,
              },
            },
            productOptions: {
              __typename: 'ProductOptionConnection',
              edges: [],
            },
            categories: {
              __typename: 'CategoryConnection',
              edges: [
                {
                  __typename: 'CategoryEdge',
                  node: {
                    __typename: 'Category',
                    entityId: 37,
                    name: 'T2 Terminals',
                  },
                },
              ],
            },
            customFields: {
              __typename: 'CustomFieldConnection',
              edges: [
                {
                  __typename: 'CustomFieldEdge',
                  node: {
                    __typename: 'CustomField',
                    entityId: 90,
                    name: 'Colour',
                    value: 'White',
                  },
                },
                {
                  __typename: 'CustomFieldEdge',
                  node: {
                    __typename: 'CustomField',
                    entityId: 95,
                    name: 'ProductDisplayName',
                    value: 'Terminal 2',
                  },
                },
              ],
            },
          },
        },
        {
          node: {
            __typename: 'Product',
            entityId: 162,
            name: 'Terminal 2 Black',
            description: '',
            sku: 'TER-BLK-2-AU-ONB',
            plainTextDescription: '',
            defaultImage: {
              __typename: 'Image',
              url320wide:
                'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/162/882/T2_-_Black_Side__97456.1722832515.png',
              altText: 'Zeller Terminal 2 Black',
            },
            images: {
              __typename: 'ImageConnection',
              edges: [
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/162/882/T2_-_Black_Side__97456.1722832515.png',
                    altText: 'Zeller Terminal 2 Black',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/162/878/Debit_Card_-_Black__95056.1722832282.png',
                    altText: 'Zeller Debit Card',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/162/881/T2_Charger_-_Black__65523.1722832282.png',
                    altText: 'Charging Cable',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/162/879/Sticker_Set__67544.1722824147.1280.1280__76523.1722832281.png',
                    altText: 'Marketing Kit',
                  },
                },
              ],
            },
            prices: {
              __typename: 'Prices',
              basePrice: {
                __typename: 'Money',
                value: 199,
              },
              salePrice: {
                __typename: 'Money',
                value: 199,
              },
              price: {
                __typename: 'Money',
                value: 199,
              },
            },
            productOptions: {
              __typename: 'ProductOptionConnection',
              edges: [],
            },
            categories: {
              __typename: 'CategoryConnection',
              edges: [
                {
                  __typename: 'CategoryEdge',
                  node: {
                    __typename: 'Category',
                    entityId: 37,
                    name: 'T2 Terminals',
                  },
                },
              ],
            },
            customFields: {
              __typename: 'CustomFieldConnection',
              edges: [
                {
                  __typename: 'CustomFieldEdge',
                  node: {
                    __typename: 'CustomField',
                    entityId: 91,
                    name: 'Colour',
                    value: 'Black',
                  },
                },
                {
                  __typename: 'CustomFieldEdge',
                  node: {
                    __typename: 'CustomField',
                    entityId: 96,
                    name: 'ProductDisplayName',
                    value: 'Terminal 2',
                  },
                },
              ],
            },
          },
        },
        {
          node: {
            __typename: 'Product',
            entityId: 163,
            name: 'Terminal 2 Graphite',
            description: '',
            sku: 'TER-GRT-2-AU-ONB',
            plainTextDescription: '',
            defaultImage: {
              __typename: 'Image',
              url320wide:
                'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/163/887/T2_-_Graphite_Side__94131.1722832699.png',
              altText: 'Zeller Terminal 2 Graphite',
            },
            images: {
              __typename: 'ImageConnection',
              edges: [
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/163/887/T2_-_Graphite_Side__94131.1722832699.png',
                    altText: 'Zeller Terminal 2 Graphite',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/163/886/Debit_Card_-_Black__95056.1722832282.1280.1280__62859.1722832698.png',
                    altText: 'Zeller Debit Card',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/163/885/T2_Charger_-_Black__65523.1722832282.1280.1280__19944.1722832698.png',
                    altText: 'Charging Cable',
                  },
                },
                {
                  __typename: 'ImageEdge',
                  node: {
                    __typename: 'Image',
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/163/884/Sticker_Set__67544.1722824147.1280.1280__76523.1722832281.1280.1280__57817.1722832698.png',
                    altText: 'Marketing Kit',
                  },
                },
              ],
            },
            prices: {
              __typename: 'Prices',
              basePrice: {
                __typename: 'Money',
                value: 199,
              },
              salePrice: {
                __typename: 'Money',
                value: 199,
              },
              price: {
                __typename: 'Money',
                value: 199,
              },
            },
            productOptions: {
              __typename: 'ProductOptionConnection',
              edges: [],
            },
            categories: {
              __typename: 'CategoryConnection',
              edges: [
                {
                  __typename: 'CategoryEdge',
                  node: {
                    __typename: 'Category',
                    entityId: 37,
                    name: 'T2 Terminals',
                  },
                },
              ],
            },
            customFields: {
              __typename: 'CustomFieldConnection',
              edges: [
                {
                  __typename: 'CustomFieldEdge',
                  node: {
                    __typename: 'CustomField',
                    entityId: 92,
                    name: 'Colour',
                    value: 'Graphite',
                  },
                },
                {
                  __typename: 'CustomFieldEdge',
                  node: {
                    __typename: 'CustomField',
                    entityId: 97,
                    name: 'ProductDisplayName',
                    value: 'Terminal 2',
                  },
                },
              ],
            },
          },
        },
      ],
      __typename: 'ProductConnection',
    },
    __typename: 'Site',
  },
} satisfies GetProductsQueryResponse

const MissingProducts = GET_PRODUCT_RESPONSE.site.products.edges.map(
  (data) => ({
    ...data,
    node: {
      ...data.node,
      prices: {
        ...data.node.prices,
        price: { ...data.node.prices.price, value: null },
      },
    },
  })
)

const GET_PRODUCT_RESPONSE_WITH_MISSING_PRICE = {
  site: {
    products: {
      edges: MissingProducts,
    },
  },
} satisfies GetProductsQueryResponse

export const SUCCESS_VARIABLE = {
  pageSize: 10,
} satisfies GetProductsQueryVariables

export const GetProductsMocks = mock<
  GetProductsQueryVariables,
  GetProductsQueryResponse
>(GetProducts)

export const GetProductsSuccessMocks =
  GetProductsMocks.mockVariables(SUCCESS_VARIABLE).mockData(
    GET_PRODUCT_RESPONSE
  )

export const GetMissingPriceProductsMock = GetProductsSuccessMocks.mockData(
  GET_PRODUCT_RESPONSE_WITH_MISSING_PRICE
)

export const GetProductsErrorMocks = GetProductsMocks.mockGraphQLErrors()

export const GetProductsNetworkErrorMock = GetProductsMocks.mockNetworkError()
