import { InvoiceDiscountConfig } from '@npco/mp-gql-types'
import currency from 'currency.js'
import {
  INVOICE_ITEM_MIN_AMOUNT,
  INVOICE_MIN_AMOUNT_IN_CENTI_CENTS,
  INVOICE_TAX_EXCLUSIVE_RATE,
  INVOICE_TAX_INCLUSIVE_RATE,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import {
  InvoiceDiscountFormFields,
  InvoiceFormFields,
  InvoiceItem,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'

import { convertLocaleStringToNumber } from 'utils/localeString'

import { getTaxRate } from '../InvoiceItemsAccordion.utils'

export interface ItemTotal {
  discount: currency
  discountFormatted: string
  isDiscountInvalid: boolean
  isTotalBelowMinAmount: boolean
  subTotal: currency
  subTotalFormatted: string
  tax: currency
  taxFormatted: string
  total: currency
  totalFormatted: string
}

export interface InvoiceTotals {
  discount: currency
  discountFormatted: string
  isDiscountInvalid: boolean
  isTotalAboveMaxAmount: boolean
  isTotalBelowMinAmount: boolean
  itemsTotals: ItemTotal[]
  subTotal: currency
  subTotalFormatted: string
  taxes: currency
  taxesFormatted: string
  total: currency
  totalFormatted: string
}

interface GetDiscountProps {
  discountPercentage: string
  discountPrice: string
  discountConfig: InvoiceDiscountConfig
  total: currency
}

export const getDiscount = ({
  discountPercentage,
  discountPrice,
  discountConfig,
  total,
}: GetDiscountProps) => {
  const percentage = convertLocaleStringToNumber(discountPercentage)
  const priceTotal = currency(discountPrice, { precision: 4 })

  const percentageTotal = percentage
    ? total.multiply(percentage).divide(100)
    : currency(0, { precision: 4 })

  const discount =
    discountConfig === InvoiceDiscountConfig.PERCENTAGE
      ? percentageTotal
      : priceTotal

  return discount
}

interface GetSubTotalProps {
  items: InvoiceItem[]
  isTaxInclusive: boolean
}

export const getSubTotal = ({ items, isTaxInclusive }: GetSubTotalProps) => {
  const invoiceSubTotal = items.reduce(
    (memo: currency, item: InvoiceItem): currency => {
      const taxRate = getTaxRate(item.taxApplicable, isTaxInclusive)

      const total = item.price
        .multiply(taxRate)
        .multiply(convertLocaleStringToNumber(item.quantity))

      const discount = getDiscount({
        discountConfig: item.discount.config,
        discountPrice: item.discount.price,
        discountPercentage: item.discount.percentage,
        total,
      })

      const subTotal = memo.add(total).subtract(discount)

      return subTotal
    },
    currency(0, { precision: 4 })
  )

  return invoiceSubTotal
}

interface GetItemTotalsProps {
  discount: currency
  isTaxInclusive: boolean
  item: InvoiceItem
  overrides?: {
    discount: InvoiceDiscountFormFields
  }
  subTotal: currency
}

export const getItemTotals = ({
  discount: invoiceDiscount,
  isTaxInclusive,
  item,
  overrides,
  subTotal: invoiceSubTotal,
}: GetItemTotalsProps): ItemTotal => {
  const itemTaxRate = getTaxRate(item.taxApplicable, isTaxInclusive)

  const total = item.price
    .multiply(itemTaxRate)
    .multiply(convertLocaleStringToNumber(item.quantity))

  const discountValues = overrides?.discount || item.discount

  const discount = getDiscount({
    discountConfig: discountValues.config,
    discountPrice: discountValues.price,
    discountPercentage: discountValues.percentage,
    total,
  })

  const isDiscountInvalid =
    total.subtract(discount).intValue < INVOICE_ITEM_MIN_AMOUNT

  const subTotal = total.subtract(discount)

  const isTaxApplicable = Boolean(item.taxApplicable)

  const isInvoiceNegativeOrZero =
    invoiceSubTotal.subtract(invoiceDiscount).intValue <= 0

  const isItemNegativeOrZero = total.subtract(discount).intValue <= 0

  // NOTE: if we have zero value, negative item value or items sub total or
  // item is not tax applicable return

  // NOTE: rules for skipping tax calculation
  // * item is not tax applicable
  // * item price is the same as the discount
  // * item price is negative value with discount
  // * all items sub total price is negative value
  // * all items total price is $0.00
  // * invoice discount is zero
  const subTotalTaxRate = isTaxInclusive
    ? INVOICE_TAX_INCLUSIVE_RATE
    : INVOICE_TAX_EXCLUSIVE_RATE

  // IMPORTANT: we must use the raw values from this division result as
  // otherwise the currency instance will round the value to its precision set
  // post calculation e.g 0.3158 versus 0.3157894736842105
  // JIRA: https://npco-dev.atlassian.net/browse/ZD-14470
  const totalsQuotient = subTotal.intValue / invoiceSubTotal.intValue

  const tax =
    isTaxApplicable && !isInvoiceNegativeOrZero && !isItemNegativeOrZero
      ? subTotal
          .subtract(invoiceDiscount.multiply(totalsQuotient))
          .divide(subTotalTaxRate)
      : currency(0, { precision: 4 })

  const totalFormatted =
    total.intValue >= INVOICE_MIN_AMOUNT_IN_CENTI_CENTS
      ? currency(total).format()
      : `${currency(total, { negativePattern: '-!#' }).format()}`

  // NOTE: item total has centi cents precision so wrap in new currency object
  // to get nearest cent precision (formatted/displayed total)
  const isTotalBelowMinAmount =
    currency(total).intValue < INVOICE_ITEM_MIN_AMOUNT

  return {
    discount,
    discountFormatted: `-${currency(discount).format()}`,
    isDiscountInvalid,
    isTotalBelowMinAmount,
    subTotal,
    subTotalFormatted: currency(subTotal).format(),
    tax,
    taxFormatted: currency(tax).format(),
    total,
    totalFormatted,
  }
}

interface GetItemsTotalsProps {
  discount: currency
  isTaxInclusive: boolean
  items: InvoiceItem[]
  overrides?: {
    discount: InvoiceDiscountFormFields
  }
  subTotal: currency
}

export const getItemsTotals = ({
  discount,
  isTaxInclusive,
  items,
  overrides,
  subTotal,
}: GetItemsTotalsProps): ItemTotal[] => {
  const itemsTotals = items.map((item: InvoiceItem) =>
    getItemTotals({ discount, item, isTaxInclusive, overrides, subTotal })
  )

  return itemsTotals
}

interface GetTaxProps {
  itemsTotals: Pick<ItemTotal, 'tax'>[]
}

export const getTax = ({ itemsTotals }: GetTaxProps) => {
  const totalTax = itemsTotals.reduce(
    (memo: currency, item: any) => memo.add(item.tax),
    currency(0, { precision: 4 })
  )
  return totalTax
}

interface GetInvoiceTotalsProps {
  values: InvoiceFormFields
  overrides?: {
    discount: InvoiceDiscountFormFields
  }
}

export const getInvoiceTotals = ({
  values,
  overrides,
}: GetInvoiceTotalsProps): InvoiceTotals => {
  const {
    itemsTaxInclusive: isTaxInclusive,
    maximumLimit,
    minimumLimit,
  } = values

  const subTotal = getSubTotal({
    items: values.items,
    isTaxInclusive,
  })

  const discountValues = overrides?.discount || values.discount

  const discount = getDiscount({
    discountPrice: discountValues.price,
    discountPercentage: discountValues.percentage,
    discountConfig: discountValues.config,
    total: subTotal,
  })

  const isDiscountInvalid = subTotal.subtract(discount).intValue < 0

  const itemsTotals = getItemsTotals({
    discount,
    isTaxInclusive,
    items: values.items,
    subTotal,
  })

  const taxes = getTax({ itemsTotals })

  const total = isTaxInclusive
    ? subTotal.subtract(discount)
    : subTotal.add(taxes).subtract(discount)

  const totalFormatted =
    total.intValue >= INVOICE_MIN_AMOUNT_IN_CENTI_CENTS
      ? currency(total).format()
      : `${currency(total, { negativePattern: '-!#' }).format()}`

  // NOTE: maximum and minimum limits are in cents so convert to centi cents for
  // comparison
  const isTotalAboveMaxAmount =
    total.intValue > currency(maximumLimit, { precision: 4 }).intValue

  const isTotalBelowMinAmount =
    total.intValue < currency(minimumLimit, { precision: 4 }).intValue

  // NOTE: all currency.js instances returned have a precision value of 4 (centi
  // cents), formatted values are returned with a precision value of 2 (nearest
  // cent)
  return {
    discount,
    discountFormatted: `-${currency(discount).format()}`,
    isDiscountInvalid,
    isTotalAboveMaxAmount,
    isTotalBelowMinAmount,
    itemsTotals,
    subTotal,
    subTotalFormatted: currency(subTotal).format(),
    taxes,
    taxesFormatted: currency(taxes).format(),
    total,
    totalFormatted,
  }
}

interface UseInvoiceItemsCalculationsProps {
  values: InvoiceFormFields
  overrides?: {
    discount: InvoiceDiscountFormFields
  }
}

// NOTE: All calculations are defined from the formulas within the following
// spreadsheet
// https://docs.google.com/spreadsheets/d/1sKBFdTcyXrHActj27pDDUipcjG8iDY6Yzl-h-5MdyKg/edit?usp=sharing
export const useInvoiceItemsCalculations = ({
  values,
  overrides,
}: UseInvoiceItemsCalculationsProps) => {
  const totals: InvoiceTotals = getInvoiceTotals({ values, overrides })

  return {
    ...totals,
  }
}
