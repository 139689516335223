import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { rvSelectedUser } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ROUTE } from 'const/routes'

import { UsersListState } from './useGoToUsersList.types'

export const useGoToUsersList = () => {
  const navigate = useNavigate()

  const goToUsersList = useCallback(
    ({ selectedUserId }: UsersListState = { selectedUserId: null }) => {
      rvSelectedUser(selectedUserId || '')
      navigate(ROUTE.PORTAL_SETTINGS_USERS, {
        state: {
          UsersList: { selectedUserId },
        },
      })
    },
    [navigate]
  )

  return { goToUsersList }
}
