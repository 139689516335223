import { gql } from '@apollo/client'

export const GetXeroSurchargeSettings = gql`
  query GetXeroSurchargeSettings {
    getXeroSiteSettings {
      __typename
      id
      name
      surchargesTaxes {
        feesSurchargeXinv {
          feeFixed
          feeFixedIntl
          feePercent
          feePercentIntl
        }
      }
    }
  }
`
