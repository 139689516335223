import { gql } from '@apollo/client'

export const SendInternalTransfer = gql`
  mutation SendInternalTransfer($input: FundsTransferInput!) {
    transferFundsDebitCardAccount(input: $input) {
      id
      status
      transferInUuid
    }
  }
`

export const CreateExternalRecipientMutation = gql`
  mutation CreateExternalRecipient($input: CreateTransferFundsRecipientInput!) {
    createTransferFundsRecipient(input: $input) {
      id
    }
  }
`
