import { Navigate, Route, Routes } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'
import { NotFound } from 'pages/NotFound'
import { ProfileDocuments } from 'pages/Settings/Profile/Documents/ProfileDocuments'
import { ProfilePersonal } from 'pages/Settings/Profile/Personal'
import { ProfilePersonalEdit } from 'pages/Settings/Profile/Personal/Edit/ProfilePersonalEdit'
import { ProfileDetailsFetcher } from 'pages/Settings/Profile/ProfileDetailsFetcher/ProfileDetailsFetcher'
import { ChangeNumberPage as SecurityChangeNumberPage } from 'pages/Settings/Profile/Security/ChangeNumberPage'
import { IndexPage as SecurityIndexPage } from 'pages/Settings/Profile/Security/IndexPage'
import { ValidateCodePage as SecurityValidateCodePage } from 'pages/Settings/Profile/Security/ValidateCodePage'
import { ProfilePageLayout } from 'layouts'

export const SettingsProfileRoutes = () => {
  return (
    <ProfilePageLayout>
      <ProfileDetailsFetcher>
        <Routes>
          <Route
            index
            element={
              <Navigate
                to={ROOT.PORTAL.SETTINGS.PROFILE.PERSONAL.path}
                replace
              />
            }
          />
          <Route path={`${ROOT.PORTAL.SETTINGS.PROFILE.PERSONAL.relative}/*`}>
            <Route index element={<ProfilePersonal />} />
            <Route
              path={ROOT.PORTAL.SETTINGS.PROFILE.PERSONAL.EDIT.relative}
              element={<ProfilePersonalEdit />}
            />
          </Route>

          <Route path={`${ROOT.PORTAL.SETTINGS.PROFILE.SECURITY.relative}/*`}>
            <Route index element={<SecurityIndexPage />} />
            <Route
              path={
                ROOT.PORTAL.SETTINGS.PROFILE.SECURITY.CHANGE_NUMBER.relative
              }
              element={<SecurityChangeNumberPage />}
            />
            <Route
              path={
                ROOT.PORTAL.SETTINGS.PROFILE.SECURITY.VALIDATE_CODE.relative
              }
              element={<SecurityValidateCodePage />}
            />
          </Route>
          <Route
            path={ROOT.PORTAL.SETTINGS.PROFILE.DOCUMENTS.relative}
            element={<ProfileDocuments />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ProfileDetailsFetcher>
    </ProfilePageLayout>
  )
}
