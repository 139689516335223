import { useNavigate } from 'react-router-dom-v5-compat'
import { useLocationState } from '@npco/utils-routing'
import { showSuccessToast } from '@npco/zeller-design-system'
import { z } from 'zod'

import { ROOT } from 'const/routes'
import { ButtonBack } from 'components/Buttons/ButtonBack'
import { SpinnerWrapped } from 'components/Spinner'
import { ResendCodeModalWrapper } from 'components/ValidateCode/ResendCodeModalWrapper'
import { useValidateCode } from 'components/ValidateCode/useValidateCode'
import { ValidateCode as ValidateCodeComponent } from 'components/ValidateCode/ValidateCode'
import {
  StyledCopySmall,
  StyledSubTitle,
} from 'components/ValidateCode/ValidateCode.styled'
import { page, shared } from 'translations'

const PhoneLocationStateSchema = z.object({
  phone: z.string(),
})

export const ValidateCodePage = () => {
  const navigate = useNavigate()
  const locationState = useLocationState(PhoneLocationStateSchema)
  const phone = locationState?.phone || ''

  const onSubmitCallback = () => {
    navigate(ROOT.PORTAL.SETTINGS.PROFILE.SECURITY.path)
    showSuccessToast(page.settings.profileSettings.successChangeNumber)
  }

  const { loading, handleSubmit, initialCode, hasError } = useValidateCode({
    onSubmitCallback,
  })

  return (
    <>
      {loading ? (
        <SpinnerWrapped variant="top" />
      ) : (
        <ValidateCodeComponent
          handleSubmit={handleSubmit}
          backButton={
            <ButtonBack
              onClick={() =>
                navigate(
                  ROOT.PORTAL.SETTINGS.PROFILE.SECURITY.CHANGE_NUMBER.path
                )
              }
            />
          }
          description={
            <StyledCopySmall>
              {`${page.validateCode.copyFirstLine} ${page.validateCode.copySecondLine}`}
              {phone && <ResendCodeModalWrapper />}
            </StyledCopySmall>
          }
          codeLabel={page.settings.profileSettings.verificationCode}
          hasError={hasError}
          initialValues={initialCode}
          subtitle={
            <StyledSubTitle>
              {page.settings.profileSettings.verificationCode}
            </StyledSubTitle>
          }
          submitButtonText={shared.confirm}
        />
      )}
    </>
  )
}
