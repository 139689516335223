import { Outlet } from 'react-router-dom-v5-compat'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'

import { useIsInvoicingEnabled } from 'hooks/invoices/useIsInvoicingEnabled'
import { NotFound } from 'pages/NotFound'

export const PrivateItemManagementRoute = () => {
  const flags = useFeatureFlags()

  const isInvoicingEnabled = useIsInvoicingEnabled()
  const isNewPosItemsEnabled = flags.POSItems

  if (!isInvoicingEnabled || isNewPosItemsEnabled) {
    return <NotFound />
  }

  return <Outlet />
}
