import { CustomerRole, InitialCustomerDataInput } from '@npco/mp-gql-types'
import { isEmpty } from 'lodash-es'

import { AUSTRALIA_COUNTRY_CODE } from 'const/common'
import { parseToDisplayFullDate } from 'utils/date'
import { Roles, TemporaryCustomer } from 'types/customers'
import { TrustAddCustomerFormValues as FormValues } from 'forms/formViews/TrustAddCustomerForm/TrustAddCustomerForm'

import { translate } from './translations'

export const getIsCustomerFormDisabled = (values: FormValues) =>
  values.isBeneficialOwner
    ? Boolean(
        !values.firstName ||
          !values.lastName ||
          !values.dob ||
          !values.country ||
          !values.street ||
          !values.suburb ||
          !values.state ||
          !values.postcode
      )
    : Boolean(!values.firstName || !values.lastName)

export const getCustomerFormInitialValues = (
  savedInitialChairData: InitialCustomerDataInput,
  previousCustomerData: TemporaryCustomer | undefined
) => {
  return {
    isBeneficialOwner: Boolean(
      !isEmpty(savedInitialChairData)
        ? savedInitialChairData.roles?.find(
            (role) => role === Roles.beneficialOwner
          )
        : previousCustomerData?.beneficialOwner
    ),
    firstName:
      savedInitialChairData.firstName || previousCustomerData?.firstname || '',
    middleName:
      savedInitialChairData.middleName ||
      previousCustomerData?.middlename ||
      '',
    lastName:
      savedInitialChairData.lastName || previousCustomerData?.lastname || '',
    country:
      savedInitialChairData.country ||
      previousCustomerData?.address?.country ||
      AUSTRALIA_COUNTRY_CODE,
    dob:
      parseToDisplayFullDate(
        savedInitialChairData.dob || previousCustomerData?.dob
      ) || '',
    street:
      savedInitialChairData.street || previousCustomerData?.address?.street,
    suburb:
      savedInitialChairData.suburb || previousCustomerData?.address?.suburb,
    state: savedInitialChairData.state || previousCustomerData?.address?.state,
    postcode:
      savedInitialChairData.postcode || previousCustomerData?.address?.postcode,
  } as FormValues
}

const CustomerRoles = [
  'beneficialOwner',
  'director',
  'secretary',
  'ceo',
  'beneficialOwnerAlt',
  'beneficiary',
  'partner',
  'trustee',
  'settlor',
  'shareholder',
  'treasurer',
  'chair',
  'generalContact',
  'financialContact',
]

export const getHasCustomerRole = (customer: TemporaryCustomer) => {
  return Boolean(
    customer.firstname &&
      !CustomerRoles.every((role) => !customer[role as keyof TemporaryCustomer])
  )
}

interface GetCurrentUserArgs {
  previousCustomersTempData: TemporaryCustomer[]
}

export const getCurrentUser = ({
  previousCustomersTempData,
}: GetCurrentUserArgs) => {
  return previousCustomersTempData.find(({ isCurrentUser }) =>
    Boolean(isCurrentUser)
  )
}

export const getCustomerRoleString = (role: CustomerRole | null) => {
  switch (role) {
    case CustomerRole.ADMIN:
      return translate('shared.roles.admin')
    case CustomerRole.MANAGER:
      return translate('shared.roles.manager')
    default:
      return ''
  }
}

export const getCustomerDisplayName = (customer: {
  firstname: TemporaryCustomer['firstname']
  lastname: TemporaryCustomer['lastname']
}) => {
  return `${customer.firstname || '-'} ${customer.lastname || ''}`
}
