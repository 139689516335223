import { useMemo, useState } from 'react'

import { ReactComponent as Magnifier } from 'assets/svg/search.svg'
import { ReactComponent as TagIcon } from 'assets/svg/tag.svg'
import { getSelectedItemsInfo } from 'utils/picker'
import { PickerItemProps } from 'types/picker'
import { MultiDownshift } from 'components/MultiDownshift'
import { MultiDownshiftComponentName } from 'components/MultiDownshift/MultiDownshift.hooks'
import { BottomRow, TagRow, TopRow } from 'components/PickerElements'
import { StyledItemsWrapper } from 'components/PickerElements/Elements.styled'
import { PickerTrigger } from 'components/PickerElements/PickerTrigger'
import { Search } from 'components/Search'
import { StyledDataSmall } from 'components/Shared'
import { SpinnerWrapped } from 'components/Spinner'
import { component } from 'translations'

import { NoResultsPlaceholder } from './NoResultsPlaceholder/NoResultsPlaceholder'
import { NoTagsPlaceholder } from './NoTagsPlaceholder/NoTagsPlaceholder'
import {
  StyledDropdown,
  StyledList,
  StyledSearchWrapper,
} from './TagPicker.styled'
import {
  NoResultPlaceholderMessageProps,
  NoTagsPlaceholderMessageProps,
} from './TagPicker.types'

interface TagPickerProps {
  entityTags: string[] | undefined
  isLoadingEntityTags?: boolean
  noResultPlaceHolderMessage: NoResultPlaceholderMessageProps
  noTagsPlaceHolderMessage: NoTagsPlaceholderMessageProps
}

export const TagPicker = ({
  entityTags,
  isLoadingEntityTags = false,
  noResultPlaceHolderMessage,
  noTagsPlaceHolderMessage,
}: TagPickerProps) => {
  const [input, setInput] = useState('')

  const { entityTagsPicker } = component

  const tags = useMemo<PickerItemProps[]>(() => {
    const data = entityTags || []
    const newTags: PickerItemProps[] | undefined = data.map((tag) => {
      return { id: tag, name: tag, value: tag }
    })
    return newTags
  }, [entityTags])

  const handleSearch = (e: React.SyntheticEvent): void => {
    const target = e.target as HTMLInputElement
    setInput(target.value)
  }

  const filteredTags = useMemo<PickerItemProps[]>(() => {
    const newTags: PickerItemProps[] = tags.filter((tag) => {
      return tag.name.includes(input)
    })
    return newTags
  }, [input, tags])

  const shouldDisplayNoTagsPlaceholder = tags.length === 0
  const shouldDisplayNoSearchResultFound = filteredTags.length === 0

  return (
    <MultiDownshift
      itemToString={(item: PickerItemProps | null) => (item ? item.name : '')}
      componentName={MultiDownshiftComponentName.Tag}
    >
      {({
        getToggleButtonProps,
        getRootProps,
        getItemProps,
        getInputProps,
        closeMenu,
        selectedItems,
        clearAllSelected,
        isOpen,
      }) => (
        <StyledDropdown
          {...getRootProps(undefined, { suppressRefError: true })}
          isOpen={isOpen}
        >
          <PickerTrigger
            getToggleButtonProps={getToggleButtonProps}
            isOpen={isOpen}
            label={getSelectedItemsInfo(selectedItems, 'tag')}
            placeholderIcon={<TagIcon />}
            triggerContent={
              selectedItems?.length
                ? getSelectedItemsInfo(selectedItems, 'tag')
                : undefined
            }
          />
          {isOpen && (
            <StyledItemsWrapper>
              {shouldDisplayNoTagsPlaceholder && (
                <NoTagsPlaceholder
                  placeholderMessage={noTagsPlaceHolderMessage}
                />
              )}
              {!shouldDisplayNoTagsPlaceholder && (
                <>
                  <TopRow
                    copy={entityTagsPicker.selectTags}
                    handleCloseMenu={closeMenu}
                  />
                  <StyledSearchWrapper>
                    <Search
                      isSmall
                      icon={Magnifier}
                      {...getInputProps({
                        placeholder: entityTagsPicker.inputPlaceholder,
                        value: input,
                        onChange: (e: React.SyntheticEvent) => handleSearch(e),
                      })}
                    />
                  </StyledSearchWrapper>

                  {shouldDisplayNoSearchResultFound && (
                    <NoResultsPlaceholder
                      searchInput={input}
                      placeholderMessage={noResultPlaceHolderMessage}
                    />
                  )}
                  {!shouldDisplayNoSearchResultFound && (
                    <StyledList>
                      {isLoadingEntityTags ? (
                        <SpinnerWrapped justifyContent="center" my="20px" />
                      ) : (
                        <>
                          {filteredTags.map((tag) => (
                            <TagRow
                              key={tag.name}
                              tag={tag}
                              getItemProps={getItemProps}
                              selectedItems={selectedItems}
                            />
                          ))}
                        </>
                      )}
                    </StyledList>
                  )}

                  <BottomRow
                    handleClearAll={clearAllSelected}
                    handleSetInput={setInput}
                  >
                    <StyledDataSmall data-testid="selected-items">
                      {getSelectedItemsInfo(selectedItems, 'tag')}
                    </StyledDataSmall>
                  </BottomRow>
                </>
              )}
            </StyledItemsWrapper>
          )}
        </StyledDropdown>
      )}
    </MultiDownshift>
  )
}
