import { NetworkStatus } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { useCashflowReportingAccountsListQuery as useCashflowReportingAccountsListQueryGenerated } from './graphql/getCashflowReportingAccountsList.generated'

export const useCashflowReportingAccountsListQuery = () => {
  const { data, loading, networkStatus, error, refetch } =
    useCashflowReportingAccountsListQueryGenerated({
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    })

  const VALID_ACCOUNT_STATUSES = [
    Types.DebitCardAccountStatus.ACTIVE,
    Types.DebitCardAccountStatus.SUSPENDED,
  ]

  const isNotNull = <T>(maybeNull: T | null): maybeNull is T => !!maybeNull
  const validAccountList = data?.getDebitCardAccountsV2.accounts
    .filter(isNotNull)
    .filter(({ status }) => VALID_ACCOUNT_STATUSES.includes(status))

  return {
    accounts: validAccountList,
    isLoadingAccounts: loading || networkStatus === NetworkStatus.refetch,
    error,
    refetch,
  }
}
