import { useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useMutation } from '@apollo/client'
import { useLocationState } from '@npco/utils-routing'
import {
  IdentityPhoneRegister,
  IdentityPhoneRegisterMfa,
} from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/onboarding'
import parseMobile from 'libphonenumber-js/mobile'
import { z } from 'zod'

import {
  IdentityPhoneRegister as IdentityPhoneRegisterResponse,
  IdentityPhoneRegisterVariables,
} from 'types/gql-types/IdentityPhoneRegister'
import {
  IdentityPhoneRegisterMfa as IdentityPhoneRegisterMfaResponse,
  IdentityPhoneRegisterMfaVariables,
} from 'types/gql-types/IdentityPhoneRegisterMfa'

interface Args {
  url: string
}

const RegisterPhoneLocationStateSchema = z.record(z.string(), z.any())

export const useRegisterPhone = ({ url }: Args) => {
  const navigate = useNavigate()
  const locationState = useLocationState(RegisterPhoneLocationStateSchema)
  const [phoneNumber, setPhoneNumber] = useState('')

  const [identityPhoneRegister, { loading, error }] = useMutation<
    IdentityPhoneRegisterResponse,
    IdentityPhoneRegisterVariables
  >(IdentityPhoneRegister, {
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (!data?.identityPhoneRegister?.codeSent) {
        return
      }
      navigate(url, {
        state: {
          ...locationState,
          phone: phoneNumber,
        },
      })
    },
  })

  const [identityPhoneRegisterMfa, { loading: isLoadingRegisterMfa }] =
    useMutation<
      IdentityPhoneRegisterMfaResponse,
      IdentityPhoneRegisterMfaVariables
    >(IdentityPhoneRegisterMfa, {
      errorPolicy: 'all',
      onCompleted: (data) => {
        const { codeSent } = data.identityPhoneRegisterMfa

        if (codeSent) {
          navigate(url, {
            state: {
              ...locationState,
              phone: phoneNumber,
            },
          })
        }
      },
    })

  const handleSubmit = (values: IdentityPhoneRegisterVariables) => {
    const parsedPhoneNumber = parseMobile(values.phone, 'AU')
    if (parsedPhoneNumber) {
      identityPhoneRegister({
        variables: {
          phone: parsedPhoneNumber.number as string,
        },
      })
      setPhoneNumber(parsedPhoneNumber.number as string)
    }
  }

  const handleSubmitMfa = (values: IdentityPhoneRegisterMfaVariables) => {
    const parsedPhoneNumber = parseMobile(values.phone, 'AU')

    if (parsedPhoneNumber) {
      identityPhoneRegisterMfa({
        variables: {
          phone: parsedPhoneNumber.number as string,
        },
      })
      setPhoneNumber(parsedPhoneNumber.number as string)
    }
  }

  return { error, loading, isLoadingRegisterMfa, handleSubmit, handleSubmitMfa }
}
