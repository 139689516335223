import { MockedResponse } from '@apollo/client/testing'
import { LabelType } from '@npco/mp-gql-types'

import { GraphQLError } from 'graphql'

import { createProviderMocks } from '../../../../utils/createProviderMocks'
import {
  GetLabels,
  GetLabelsQueryResponse,
  GetLabelsQueryVariables,
} from '../../graphql/getLabels.generated'

export const businessLabelsData = [
  {
    id: '1234-5678-9012-3457',
    isEditable: true,
    labelText: 'Business Label',
    type: LabelType.BUSINESS,
  },
  {
    id: '1234-5678-9012-3456',
    isEditable: true,
    labelText: 'Business Label 2',
    type: LabelType.BUSINESS,
  },
]

export const personLabelsData = [
  {
    id: '1234-5678-9012-3458',
    isEditable: true,
    labelText: 'Person Label',
    type: LabelType.PERSON,
  },
  {
    id: '1234-5678-9012-3459',
    isEditable: true,
    labelText: 'Person Label 2',
    type: LabelType.PERSON,
  },
]

export const getBusinessLabelsSuccess: MockedResponse<
  GetLabelsQueryResponse,
  GetLabelsQueryVariables
> = {
  request: {
    query: GetLabels,
    variables: {
      type: LabelType.BUSINESS,
    },
  },
  result: {
    data: {
      getLabels: businessLabelsData,
    },
  },
}

export const getBusinessLabelsError: MockedResponse<
  GetLabelsQueryResponse,
  GetLabelsQueryVariables
> = {
  request: {
    query: GetLabels,
    variables: {
      type: LabelType.BUSINESS,
    },
  },
  result: {
    errors: [new GraphQLError('Label Error')],
  },
}

export const getPersonLabelsSuccess: MockedResponse<
  GetLabelsQueryResponse,
  GetLabelsQueryVariables
> = {
  request: {
    query: GetLabels,
    variables: {
      type: LabelType.PERSON,
    },
  },
  result: {
    data: {
      getLabels: personLabelsData,
    },
  },
}

export const getLabelsProviderMocks = createProviderMocks<
  GetLabelsQueryResponse,
  GetLabelsQueryVariables
>(
  GetLabels,
  {
    type: LabelType.BUSINESS,
  },
  {
    getLabels: [],
  }
)
