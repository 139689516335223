import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type UpdateCachedCustomerFragment = {
  __typename?: 'Customer'
  role: Types.CustomerRole | null
  siteCount: number | null
}

export const UpdateCachedCustomerFragmentDoc = gql`
  fragment UpdateCachedCustomerFragment on Customer {
    role
    siteCount
  }
` as unknown as TypedDocumentNode<UpdateCachedCustomerFragment, undefined>
