import { useCallback } from 'react'

import { AnalyticsEventNames } from 'services/Analytics/events'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'

import { ItemsSettingsFormFields } from '../ItemsSettingsForm.types'

export const useItemsSettingsAnalytics = () => {
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  const trackSettingsUpdated = useCallback(
    (fields: ItemsSettingsFormFields) => {
      trackAnalyticsEvent(AnalyticsEventNames.ITEMS_GENERAL_SETTINGS_UPDATED, {
        DefaultGST: fields.isTaxApplicable,
        GSTPriceDisplay: fields.isTaxInclusive ? 'Included' : 'Excluded',
      })
    },
    [trackAnalyticsEvent]
  )

  return {
    trackSettingsUpdated,
  }
}
