import { Route, Routes } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'
import { NotFound } from 'pages/NotFound'
import { BillingHistory } from 'pages/Settings/Sim/BillingHistory/BillingHistory'
import { Sim } from 'pages/Settings/Sim/Sim'
import { SimStatements } from 'pages/Settings/Sim/SimStatements/SimStatements'

import { SimBillingAccountRoutes } from './SimBillingAccountRoutes/SimBillingAccountRoutes'

export const SimRoutes = () => {
  return (
    <Routes>
      <Route index element={<Sim />} />

      <Route path={`${ROOT.PORTAL.PAYMENTS.SIM.BILLING_HISTORY.relative}/*`}>
        <Route index element={<BillingHistory />} />
        <Route
          path={ROOT.PORTAL.PAYMENTS.SIM.BILLING_HISTORY.STATEMENTS.relative}
          element={<SimStatements />}
        />
      </Route>

      <Route
        path={`${ROOT.PORTAL.PAYMENTS.SIM.BILLING_ACCOUNT.relative}/*`}
        element={<SimBillingAccountRoutes />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
