import { LocalStateItemManagement } from './ItemManagement.types'

export const ItemManagementDefaultLocalState: LocalStateItemManagement = {
  categories: {
    filterInput: null,
    sortInput: null,
  },
  items: {
    filterInput: null,
    sortInput: null,
  },
  // NOTE: This can be overriden by the customer's product tour
  // showInvoiceInstructions property but allows us to only show the tour once
  // in the user session
  hasSeenItemOnboarding: false,
}
