import { gql } from '@apollo/client'
import { Box, Flex } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { getIsCardRestricted } from 'components/CardSummary/components/CardDisplay/CardDisplay.utils'
import { UserAndAccountDetails } from 'components/CardSummary/components/UserAndAccountDetails/UserAndAccountDetails'
import { ShowMore } from 'components/ShowMore'

import { Separator } from '../../ZellerCardSummary.styled'
import { AvailableFunds } from '../AvailableFunds'
import { ShowMoreButton } from '../ShowMoreButton'
import { DebitCardDetailsDebitCardV2Fragment } from './DebitCardDetails.generated'

interface DebitCardDetailsProps {
  cardDetails: DebitCardDetailsDebitCardV2Fragment
}

export const DebitCardDetails = ({ cardDetails }: DebitCardDetailsProps) => {
  const isMobile = useIsMobileResolution()
  const isCardRestricted = getIsCardRestricted(cardDetails.status)

  const availableFunds = isCardRestricted
    ? null
    : parseInt(cardDetails.debitCardAccount.balance?.value || '0', 10)

  return (
    <Flex
      height="100%"
      flexDirection="column"
      justifyContent="space-between"
      maxWidth={{ _: '340px', SM: '100%' }}
    >
      <ShowMore shouldShowInitially={!isMobile}>
        <Flex
          height={{ _: 'auto' }}
          flexDirection={{ _: 'column', SM: 'row' }}
          justifyContent={{ _: 'start', SM: 'space-between' }}
          alignItems={{ _: 'center', XS: 'flex-start' }}
          pt={{ _: '24px', XS: '0' }}
          pl={{ _: '0', XS: '14px' }}
        >
          {(!isCardRestricted || !isMobile) && (
            <Box mt="14px" mb={{ _: '0', XS: '24px', SM: '0' }}>
              <AvailableFunds
                availableFunds={availableFunds}
                align={isMobile ? 'center' : 'start'}
              />
            </Box>
          )}
          {isMobile && <ShowMoreButton />}
          <ShowMore.TogglableContent>
            {isMobile && <Separator />}
          </ShowMore.TogglableContent>
        </Flex>
        <ShowMore.TogglableContent>
          <UserAndAccountDetails
            user={cardDetails.customer}
            account={cardDetails.debitCardAccount}
          />
        </ShowMore.TogglableContent>
      </ShowMore>
    </Flex>
  )
}

DebitCardDetails.fragments = {
  DebitCardV2: gql`
    fragment DebitCardDetailsDebitCardV2Fragment on DebitCardV2 {
      debitCardAccount {
        __typename
        ...UserAndAccountDetailsDebitCardAccountV2Fragment
        balance {
          value
        }
      }
      customer {
        __typename
        ...UserAndAccountDetailsCustomerFragment
      }
      status
    }

    ${UserAndAccountDetails.fragments.Customer}
    ${UserAndAccountDetails.fragments.DebitCardAccountV2}
  `,
}
