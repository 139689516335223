import { PaymentInstrumentType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  ButtonLink,
  InputAdaptive,
  showApiErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { rvSelectedContact } from 'features/Contacts/rv-deprecated/contacts'
import { updateRvContactsOnContactUpdate } from 'features/Contacts/rv-deprecated/contacts.utils'
import { Formik } from 'formik'

import { useUpdatePaymentInstrument } from 'hooks/paymentInstruments/useUpdatePaymentInstrument/useUpdatePaymentInstrument'
import { Nickname } from 'pages/Transfer/TransferFields/fields/Nickname'
import { ModalFormScrollable } from 'components/ModalFormScrollable/ModalFormScrollable'

import { bpayListTranslations } from '../Bpay.i18n'
import { BpayPaymentInstrument } from '../Bpay.types'
import * as styled from '../BPayList.styled'

interface EditBPayModalDetailsProps {
  isOpen: boolean
  closeModal: () => void
  paymentInstrument: BpayPaymentInstrument
  handleUnLink: () => void
}

export const EditBPayModalDetails = ({
  isOpen,
  closeModal,
  paymentInstrument,
  handleUnLink,
}: EditBPayModalDetailsProps) => {
  const { updatePaymentInstrument, isUpdatingPaymentInstrument } =
    useUpdatePaymentInstrument()
  const t = useTranslations(bpayListTranslations)

  const handleSubmit = async (values: { nickname: string }) => {
    try {
      const updatePaymentRes = await updatePaymentInstrument(
        paymentInstrument.id,
        paymentInstrument.status,
        {
          nickname: values.nickname,
        },
        PaymentInstrumentType.BPAY_STATIC_CRN
      )

      if (updatePaymentRes?.data?.updatePaymentInstrument === false) {
        throw new Error('UpdatePaymentInstrument failed')
      }
      if (updatePaymentRes?.data?.updatePaymentInstrument) {
        const contact = rvSelectedContact() as ContactCoreFieldsFragment

        const newPaymentInstruments =
          contact.paymentInstruments?.map((contactPaymentInstrument) => {
            if (paymentInstrument.id === contactPaymentInstrument.id) {
              return {
                ...contactPaymentInstrument,
                details: {
                  ...contactPaymentInstrument.details,
                  nickname: values.nickname,
                },
              }
            }

            return contactPaymentInstrument
          }) ?? []

        rvSelectedContact({
          ...contact,
          paymentInstruments: newPaymentInstruments,
        })

        updateRvContactsOnContactUpdate(contact.id, {
          paymentInstruments: newPaymentInstruments,
        })

        showSuccessToast(t('successUpdateLabel'))
        closeModal()
      }
    } catch (err) {
      showApiErrorToast()
    }
  }

  const { details } = paymentInstrument

  return (
    <Formik
      initialValues={{
        nickname: details.nickname ?? '',
      }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ submitForm }) => (
        <ModalFormScrollable
          isOpen={isOpen}
          onCancel={() => {
            closeModal()
          }}
          onSave={submitForm}
          confirmLabel={t('saveLabel')}
          cancelLabel={t('cancelLabel')}
          title={t('editTitle')}
          isLoading={isUpdatingPaymentInstrument}
        >
          <Box>
            <styled.FieldWrapper>
              <InputAdaptive
                name="billerCode"
                label={t('billerCodeLabel')}
                value={details.billerCode}
                isRequired
                disabled
              />
            </styled.FieldWrapper>
            <styled.BillerName>{details.billerName}</styled.BillerName>
            {details.crn && (
              <styled.FieldWrapper>
                <InputAdaptive
                  name="crn"
                  label={t('crn')}
                  value={details.crn}
                  isRequired
                  disabled
                />
              </styled.FieldWrapper>
            )}
            <Nickname />
            <ButtonLink onClick={handleUnLink}>{t('unLinkLabel')}</ButtonLink>
          </Box>
        </ModalFormScrollable>
      )}
    </Formik>
  )
}
