import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { LinkSubcategoryToContact } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/contacts'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import {
  LinkSubcategoryToContact as LinkSubcategoryToContactResponse,
  LinkSubcategoryToContactVariables,
} from 'types/gql-types/LinkSubcategoryToContact'

import { rvSelectedContact } from '../../rv-deprecated/contacts'
import { updateRvContactsOnContactUpdate } from '../../rv-deprecated/contacts.utils'
import { CategoryOptions } from './useUpdateCategory.types'

interface Props {
  contactUuid: string | undefined
  onSuccess?: () => void
  onFailure?: () => void
}

export const useUpdateCategory = ({
  contactUuid,
  onSuccess,
  onFailure,
}: Props) => {
  const [linkSubcategoryToContact, { loading: isLinkingSubcategoryToContact }] =
    useMutation<
      LinkSubcategoryToContactResponse,
      LinkSubcategoryToContactVariables
    >(LinkSubcategoryToContact, { onError: onFailure })

  const updateCategories = useCallback(
    async ({ category, subcategoryName, subcategoryId }: CategoryOptions) => {
      if (contactUuid && category && subcategoryName && subcategoryId) {
        const result = await linkSubcategoryToContact({
          variables: {
            category,
            subcategoryUuid: subcategoryId,
            contactUuid,
          },
        })

        if (result.data?.linkContactWithSubcategory) {
          onSuccess?.()

          const contact = rvSelectedContact()

          rvSelectedContact({
            ...contact,
            category,
            subcategory: subcategoryName,
            subcategoryUuid: subcategoryId,
          } as ContactCoreFieldsFragment)

          updateRvContactsOnContactUpdate(contactUuid, {
            category,
            subcategory: subcategoryName,
            subcategoryUuid: subcategoryId,
          })
        } else if (!result.errors) {
          onFailure?.()
        }

        return result
      }

      return null
    },
    [contactUuid, linkSubcategoryToContact, onSuccess, onFailure]
  )

  return { updateCategories, isLinkingSubcategoryToContact }
}
