import { useMemo } from 'react'
import { NetworkStatus } from '@apollo/client'
import { getHasInverseExpenseOrIncome } from '@npco/mp-feature-cashflow-reporting'
import dayjs from 'dayjs'

import { useTotalIncomeAndExpenseQuery } from './graphql/TotalIncomeAndExpenseQuery.generated'

type UseTotalIncomeAndExpenseDataProps = {
  accountUuid: string | undefined
  date: string
  timeZone: string | undefined
  skip: boolean
}

const isNotNull = <T,>(maybeNull: T | null): maybeNull is T => !!maybeNull

export const useTotalIncomeAndExpenseData = ({
  accountUuid,
  date,
  timeZone,
  skip,
}: UseTotalIncomeAndExpenseDataProps) => {
  const { data, error, refetch, networkStatus } = useTotalIncomeAndExpenseQuery(
    {
      fetchPolicy:
        dayjs(date).month() < dayjs().month()
          ? 'cache-first'
          : 'cache-and-network',
      variables: {
        accountUuid,
        date,
        timeZone,
      },
      skip,
      notifyOnNetworkStatusChange: true,
    }
  )

  const incomeAndExpenses = useMemo(
    () =>
      data?.getCashFlowTotalAmounts.cashFlowPeriodicTotalAmounts?.filter(
        isNotNull
      ) ?? [],
    [data?.getCashFlowTotalAmounts.cashFlowPeriodicTotalAmounts]
  )

  const hasInverseExpenseOrIncome = useMemo(() => {
    return getHasInverseExpenseOrIncome(incomeAndExpenses)
  }, [incomeAndExpenses])

  const isFetchingMore = useMemo(() => {
    return (
      networkStatus === NetworkStatus.setVariables &&
      incomeAndExpenses.length === 0
    )
  }, [incomeAndExpenses, networkStatus])

  return {
    incomeAndExpenses,
    isLoadingIncomeAndExpenses: networkStatus === NetworkStatus.loading,
    isRefetching: networkStatus === NetworkStatus.refetch,
    error,
    refetch,
    hasInverseExpenseOrIncome,
    isFetchingMore,
  }
}
