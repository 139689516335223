import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { ContactCoreFieldsDoc } from './ContactCoreFields.generated'

const defaultOptions = {} as const
export type GetContactsQueryVariables = Types.Exact<{
  contactType: Types.InputMaybe<Types.ContactType>
  limit: Types.Scalars['Int']['input']
  filter: Types.InputMaybe<Types.ContactFilterInput>
  nextToken: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type GetContactsQueryResponse = {
  __typename?: 'Query'
  getContacts: {
    __typename?: 'ContactConnection'
    nextToken: string | null
    contacts: Array<{
      __typename: 'Contact'
      abn: string | null
      acn: string | null
      businessName: string | null
      contactType: Types.ContactType
      firstName: string | null
      id: string
      jobTitle: string | null
      lastName: string | null
      notes: string | null
      isSelf: boolean | null
      phone: string | null
      tags: Array<string | null> | null
      website: string | null
      location: string | null
      locationAccuracy: number | null
      category: Types.EntityCategories | null
      subcategory: string | null
      subcategoryUuid: string | null
      additionalEmails: Array<{
        __typename?: 'ContactEmail'
        email: string
        label: {
          __typename?: 'Label'
          id: string
          isEditable: boolean
          labelText: string
          type: Types.LabelType
        } | null
      }> | null
      additionalPhones: Array<{
        __typename?: 'ContactPhone'
        phone: string
        label: {
          __typename?: 'Label'
          id: string
          isEditable: boolean
          labelText: string
          type: Types.LabelType
        } | null
      }> | null
      address: {
        __typename?: 'ContactAddress'
        country: string | null
        postcode: string | null
        state: string | null
        street: string | null
        suburb: string | null
      } | null
      contacts: Array<{
        __typename: 'Contact'
        abn: string | null
        acn: string | null
        businessName: string | null
        contactType: Types.ContactType
        firstName: string | null
        id: string
        jobTitle: string | null
        lastName: string | null
        phone: string | null
        website: string | null
        location: string | null
        locationAccuracy: number | null
        category: Types.EntityCategories | null
        subcategory: string | null
        subcategoryUuid: string | null
        additionalEmails: Array<{
          __typename?: 'ContactEmail'
          email: string
          label: {
            __typename?: 'Label'
            id: string
            isEditable: boolean
            labelText: string
            type: Types.LabelType
          } | null
        }> | null
        additionalPhones: Array<{
          __typename?: 'ContactPhone'
          phone: string
          label: {
            __typename?: 'Label'
            id: string
            isEditable: boolean
            labelText: string
            type: Types.LabelType
          } | null
        }> | null
        address: {
          __typename?: 'ContactAddress'
          country: string | null
          postcode: string | null
          state: string | null
          street: string | null
          suburb: string | null
        } | null
        email: {
          __typename?: 'ContactEmail'
          email: string
          label: {
            __typename?: 'Label'
            id: string
            isEditable: boolean
            labelText: string
            type: Types.LabelType
          } | null
        } | null
        icon: {
          __typename?: 'Icon'
          colour: string | null
          letter: string | null
          images: Array<{
            __typename?: 'Image'
            size: Types.ImageSize
            url: string
          }> | null
        } | null
        phoneV2: {
          __typename?: 'ContactPhone'
          phone: string
          label: {
            __typename?: 'Label'
            id: string
            isEditable: boolean
            labelText: string
            type: Types.LabelType
          } | null
        } | null
      } | null> | null
      email: {
        __typename?: 'ContactEmail'
        email: string
        label: {
          __typename?: 'Label'
          id: string
          isEditable: boolean
          labelText: string
          type: Types.LabelType
        } | null
      } | null
      icon: {
        __typename?: 'Icon'
        colour: string | null
        letter: string | null
        images: Array<{
          __typename?: 'Image'
          size: Types.ImageSize
          url: string
        }> | null
      } | null
      paymentInstruments: Array<{
        __typename?: 'PaymentInstrument'
        id: string
        status: Types.PaymentInstrumentStatus
        bankAccountDetails: {
          __typename?: 'BankAccountDetails'
          account: string | null
          bsb: string | null
          name: string | null
        } | null
        details:
          | { __typename?: 'BankAccountDetails' }
          | { __typename?: 'BpayDetails' }
          | { __typename?: 'NppDetails' }
          | {
              __typename: 'PaymentInstrumentBpayDynamicCrnDetails'
              billerName: string
              billerCode: string
              nickname: string | null
            }
          | {
              __typename: 'PaymentInstrumentBpayStaticCrnDetails'
              billerName: string
              billerCode: string
              nickname: string | null
              crn: string
            }
      }> | null
      phoneV2: {
        __typename?: 'ContactPhone'
        phone: string
        label: {
          __typename?: 'Label'
          id: string
          isEditable: boolean
          labelText: string
          type: Types.LabelType
        } | null
      } | null
    } | null>
  }
}

export const GetContacts = gql`
  query GetContacts(
    $contactType: ContactType
    $limit: Int!
    $filter: ContactFilterInput
    $nextToken: String
  ) {
    getContacts(
      contactType: $contactType
      limit: $limit
      filter: $filter
      nextToken: $nextToken
    ) {
      contacts {
        ...ContactCoreFields
      }
      nextToken
    }
  }
  ${ContactCoreFieldsDoc}
` as unknown as TypedDocumentNode<
  GetContactsQueryResponse,
  GetContactsQueryVariables
>

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *      contactType: // value for 'contactType'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactsQueryResponse,
    GetContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContactsQueryResponse, GetContactsQueryVariables>(
    GetContacts,
    options
  )
}
export function useGetContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactsQueryResponse,
    GetContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContactsQueryResponse,
    GetContactsQueryVariables
  >(GetContacts, options)
}
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>
export type GetContactsLazyQueryHookResult = ReturnType<
  typeof useGetContactsLazyQuery
>
export type GetContactsQueryResult = Apollo.QueryResult<
  GetContactsQueryResponse,
  GetContactsQueryVariables
>
