import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { TabItemProps } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'

const TabValues = {
  PERSONAL: ROOT.PORTAL.SETTINGS.PROFILE.PERSONAL.path,
  SECURITY: ROOT.PORTAL.SETTINGS.PROFILE.SECURITY.path,
  DOCUMENTS: ROOT.PORTAL.SETTINGS.PROFILE.DOCUMENTS.path,
}

export const useProfilePageLayout = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const tabs: TabItemProps[] = useMemo(
    () => [
      {
        id: 'profile-layout-tabs-personal',
        children: translate('page.settings.profileSettings.personal'),
        value: TabValues.PERSONAL,
        onClick: () => navigate(TabValues.PERSONAL),
      },
      {
        id: 'profile-layout-tabs-security',
        children: translate('page.settings.profileSettings.security'),
        value: TabValues.SECURITY,
        onClick: () => navigate(TabValues.SECURITY),
      },
      {
        id: 'profile-layout-tabs-documents',
        children: translate('page.settings.profileSettings.documents'),
        value: TabValues.DOCUMENTS,
        onClick: () => navigate(TabValues.DOCUMENTS),
      },
    ],
    [navigate]
  )

  return {
    tabs,
    currentTab: (value: string) => pathname.includes(value),
  }
}
