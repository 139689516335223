import { ApolloCache } from '@apollo/client'
import { DebitCardAccountType } from '@npco/mp-gql-types'
import { AccountsListQuery } from 'features/Accounts/Accounts/hooks/useAccountsListQuery/graphql/AccountsListQuery.generated'

import { GetDebitCardAccountsForTransfer } from 'pages/Transfer/hooks/graphql/getDebitCardAccountsQuery.generated'

import { CreateDebitCardAccountMutationResponse } from './graphql/CreateAccount.generated'

type ListPosition = 'start-of-list' | 'end-of-list'

const addToList = <T>(list: T[], item: T, position: ListPosition) => {
  return position === 'start-of-list' ? [item, ...list] : [...list, item]
}

export const cacheNewAccount = (
  cache: ApolloCache<any>,
  newAccount: CreateDebitCardAccountMutationResponse['createDebitCardAccount'],
  position: ListPosition = 'end-of-list'
) => {
  const isDebitCardAccount =
    newAccount.__typename === 'DebitCardAccountV2' &&
    newAccount.type === DebitCardAccountType.ZLR_DEBIT
  const isSavingsAccount =
    newAccount.__typename === 'DebitCardAccountV2' &&
    newAccount.type === DebitCardAccountType.SAVINGS

  const getDoesDebitCardAccountExist = (
    newAccount: CreateDebitCardAccountMutationResponse['createDebitCardAccount'],
    data: { getDebitCardAccountsV2: { accounts: ({ id: string } | null)[] } }
  ) => {
    return (
      isDebitCardAccount &&
      data.getDebitCardAccountsV2.accounts.some(
        (existingAccount) => existingAccount?.id === newAccount.id
      )
    )
  }

  const getDoesSavingsAccountExist = (
    newAccount: CreateDebitCardAccountMutationResponse['createDebitCardAccount'],
    data: { getSavingsAccounts: ({ id: string } | null)[] }
  ) => {
    return (
      isSavingsAccount &&
      data.getSavingsAccounts.some(
        (existingAccount) => existingAccount?.id === newAccount.id
      )
    )
  }

  const getDoesAccountExist = (
    newAccount: CreateDebitCardAccountMutationResponse['createDebitCardAccount'],
    data: {
      getDebitCardAccountsV2: { accounts: ({ id: string } | null)[] }
      getSavingsAccounts: ({ id: string } | null)[]
    }
  ) => {
    return (
      getDoesDebitCardAccountExist(newAccount, data) ||
      getDoesSavingsAccountExist(newAccount, data)
    )
  }

  cache.updateQuery({ query: GetDebitCardAccountsForTransfer }, (data) => {
    if (!data) {
      return undefined
    }

    if (getDoesAccountExist(newAccount, data)) {
      return data
    }

    return {
      ...data,
      getDebitCardAccountsV2: {
        ...data.getDebitCardAccountsV2,
        accounts: isDebitCardAccount
          ? addToList(
              data.getDebitCardAccountsV2.accounts,
              newAccount,
              position
            )
          : data.getDebitCardAccountsV2.accounts,
      },
      getSavingsAccounts: isSavingsAccount
        ? addToList(data.getSavingsAccounts, newAccount, position)
        : data.getSavingsAccounts,
    }
  })

  cache.updateQuery({ query: AccountsListQuery }, (data) => {
    if (!data) {
      return undefined
    }

    if (getDoesAccountExist(newAccount, data)) {
      return data
    }

    return {
      ...data,
      getDebitCardAccountsV2: {
        ...data.getDebitCardAccountsV2,
        accounts: isDebitCardAccount
          ? addToList(
              data.getDebitCardAccountsV2.accounts,
              newAccount,
              position
            )
          : data.getDebitCardAccountsV2.accounts,
      },
      getSavingsAccounts: isSavingsAccount
        ? addToList(data.getSavingsAccounts, newAccount, position)
        : data.getSavingsAccounts,
    }
  })
}
