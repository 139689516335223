import { useMemo } from 'react'

import { isNotNull } from 'utils/common'

import { useAccountTotalBalanceQuery as useAccountTotalBalanceQueryGql } from './graphql/AccountTotalBalanceQuery.generated'

export const useAccountTotalBalanceQuery = () => {
  const { data } = useAccountTotalBalanceQueryGql()

  const totalBalance = useMemo(() => {
    const accounts =
      data?.getDebitCardAccountsV2.accounts.filter(isNotNull) ?? []

    return accounts.reduce((prevValue, nextAccount) => {
      return prevValue + parseInt(nextAccount.balance?.value || '0', 10)
    }, 0)
  }, [data?.getDebitCardAccountsV2.accounts])

  return { totalBalance }
}
