import { useMutation } from '@apollo/client'
import { ContactUpdateInput } from '@npco/mp-gql-types'
import { UpdateContact } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/contacts'

import {
  UpdateContact as UpdateContactResponse,
  UpdateContactVariables,
} from 'types/gql-types/UpdateContact'

export const useUpdateContact = () => {
  const [updateContact, { loading: isUpdatingContact }] = useMutation<
    UpdateContactResponse,
    UpdateContactVariables
  >(UpdateContact)

  return {
    updateContact: (contact: ContactUpdateInput, id: string) =>
      updateContact({
        variables: {
          contact,
          id,
        },
      }),
    isUpdatingContact,
  }
}
