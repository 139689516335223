import { useEffect, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import { TEXT_INPUT_SIZE } from '@npco/zeller-design-system'
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs'

import { useEntityTags } from 'hooks/entityTags/useEntityTags'
import { PickerItemProps } from 'types/picker'
import { TagPicker } from 'components/TagPicker/TagPicker'
import {
  NoResultPlaceholderMessageProps,
  NoTagsPlaceholderMessageProps,
} from 'components/TagPicker/TagPicker.types'
import { page } from 'translations'

import { useContactsContext } from '../../ContactsContext/ContactsContext'
import { rvSelectedTags } from '../../rv-deprecated/contacts'
import {
  StyledFiltersContainer,
  StyledFiltersSelectContainer,
} from './ContactsFilters.styled'
import { ContactsFiltersInput } from './ContactsFiltersInput/ContactsFiltersInput'

export const ContactsFiltersDeps = {
  useContactsContext,
  useEntityTags,
  useReactiveVar,
}

interface ContactFiltersProps {
  debounceDuration?: number
}

export const ContactsFilters = (props: ContactFiltersProps) => {
  const { useContactsContext, useEntityTags, useReactiveVar } =
    ContactsFiltersDeps

  const { filters, setFilters } = useContactsContext()

  const debounceDuration = props?.debounceDuration ?? 500

  const search$ = useMemo(() => new Subject<string>(), [])
  const contactTags$ = useMemo(() => new Subject<PickerItemProps[]>(), [])

  useEffect(() => {
    const subscription = new Subscription()

    subscription.add(
      search$
        .pipe(debounceTime(debounceDuration), distinctUntilChanged())
        .subscribe((nextName) =>
          setFilters((prev) => ({ ...prev, name: nextName }))
        )
    )

    subscription.add(
      contactTags$.subscribe((nextTags) =>
        setFilters((prev) => ({ ...prev, tags: nextTags }))
      )
    )

    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectedTags = useReactiveVar(rvSelectedTags)
  const { entityTags, isLoadingEntityTags } = useEntityTags()

  useEffect(() => {
    contactTags$.next(selectedTags)
  }, [contactTags$, selectedTags])

  const noTagsMessageProps: NoTagsPlaceholderMessageProps = {
    title: page.contacts.filters.noTagsPlaceholder.line1,
    description: page.contacts.filters.noTagsPlaceholder.line2,
  }

  const noResultsMessageProps: NoResultPlaceholderMessageProps = {
    title: 'page.contacts.filters.noResultsPlaceholder.line1',
    description: 'page.contacts.filters.noResultsPlaceholder.line2',
  }

  return (
    <StyledFiltersContainer data-testid="contacts-filters-content">
      <ContactsFiltersInput
        defaultValue={filters.name}
        onChange={(value) => search$.next(value?.trim())}
        size={TEXT_INPUT_SIZE.SMALL}
      />
      <StyledFiltersSelectContainer>
        <TagPicker
          entityTags={entityTags}
          isLoadingEntityTags={isLoadingEntityTags}
          noResultPlaceHolderMessage={noResultsMessageProps}
          noTagsPlaceHolderMessage={noTagsMessageProps}
        />
      </StyledFiltersSelectContainer>
    </StyledFiltersContainer>
  )
}
