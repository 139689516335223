import { useState } from 'react'
import { TransactionExportFormat } from '@npco/mp-gql-types'
import { rvCurrentUserData } from '@npco/mp-utils-logged-in-user'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { renameAndDownloadFile } from '@npco/utils-file'
import { ExportTransaction } from 'apps/component-merchant-portal/src/graphql/merchant-portal/subscriptions/transactions'

import { useSubscription } from 'hooks/useSubscription'
import { useTransactionFilters } from 'hooks/useTransactionFilters'
import { mapFiltersInputToTransactionsApiFilter } from 'utils/mapFiltersInputToApiFilter'
import {
  ExportTransaction as ExportTransactionResponse,
  ExportTransactionVariables,
} from 'types/gql-types/ExportTransaction'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import { useTransactionsFilters } from 'components/Filters/PillFilters/TransactionsFilters/useTransactionsFilters'

const deps = {
  useTransactionsFilters,
  useTransactionFilters,
  useSubscription,
}
export { deps as UseExportTransactionDeps }

export const useExportTransaction = (
  filename: string,
  format: TransactionExportFormat
) => {
  const { trackAnalyticsEvent } = useAnalyticsLogger()
  const [hasLoaded, setHasLoaded] = useState(false)
  const { filters: oldFilters } = deps.useTransactionFilters()
  const { filters: newFilters } = deps.useTransactionsFilters({
    key: 'transactions',
  })
  const flags = useFeatureFlags()
  const mappedFilters = flags?.TransactionsFilterUplift
    ? newFilters
    : mapFiltersInputToTransactionsApiFilter(oldFilters, true, true)

  const data = rvCurrentUserData()

  const id = data?.id ?? ''
  const entityUuid = data?.entityUuid ?? ''

  const { loading, error } = deps.useSubscription<
    ExportTransactionResponse,
    ExportTransactionVariables
  >(ExportTransaction, {
    onSubscriptionData: (res) => {
      const response = res.subscriptionData.data?.exportTransaction

      if (response?.downloadLink) {
        renameAndDownloadFile({
          link: response?.downloadLink,
          filename,
          format,
        })
        trackAnalyticsEvent(AnalyticsEventNames.TRANSACTIONS_EXPORTED, {
          Type: format,
        })
      }
      setHasLoaded(true)
    },
    variables: {
      entityUuid,
      customerUuid: id,
      filter: JSON.stringify(mappedFilters),
      format,
    },
  })

  return {
    isLoading: loading,
    hasError: Boolean(error),
    hasLoaded,
  }
}
