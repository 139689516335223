import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetEntityAddressTimezoneQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type GetEntityAddressTimezoneQueryResponse = {
  __typename?: 'Query'
  getEntityAddressTimeZone: string
}

export const GetEntityAddressTimezone = gql`
  query GetEntityAddressTimezone {
    getEntityAddressTimeZone
  }
` as unknown as TypedDocumentNode<
  GetEntityAddressTimezoneQueryResponse,
  GetEntityAddressTimezoneQueryVariables
>

/**
 * __useGetEntityAddressTimezoneQuery__
 *
 * To run a query within a React component, call `useGetEntityAddressTimezoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityAddressTimezoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityAddressTimezoneQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEntityAddressTimezoneQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEntityAddressTimezoneQueryResponse,
    GetEntityAddressTimezoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetEntityAddressTimezoneQueryResponse,
    GetEntityAddressTimezoneQueryVariables
  >(GetEntityAddressTimezone, options)
}
export function useGetEntityAddressTimezoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEntityAddressTimezoneQueryResponse,
    GetEntityAddressTimezoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEntityAddressTimezoneQueryResponse,
    GetEntityAddressTimezoneQueryVariables
  >(GetEntityAddressTimezone, options)
}
export type GetEntityAddressTimezoneQueryHookResult = ReturnType<
  typeof useGetEntityAddressTimezoneQuery
>
export type GetEntityAddressTimezoneLazyQueryHookResult = ReturnType<
  typeof useGetEntityAddressTimezoneLazyQuery
>
export type GetEntityAddressTimezoneQueryResult = Apollo.QueryResult<
  GetEntityAddressTimezoneQueryResponse,
  GetEntityAddressTimezoneQueryVariables
>
