import { useApolloClient, useMutation } from '@apollo/client'

import { cacheNewDebitCard } from 'utils/banking/cacheNewDebitCard/cacheNewDebitCard'

import {
  RequestNewDebitCardMutation,
  RequestNewDebitCardMutationResponse,
  RequestNewDebitCardMutationVariables,
} from './graphql/RequestDebitCard.generated'

export const useRequestDebitCard = () => {
  const client = useApolloClient()

  const [requestDebitCardMutation, { loading }] = useMutation<
    RequestNewDebitCardMutationResponse,
    RequestNewDebitCardMutationVariables
  >(RequestNewDebitCardMutation, {
    update: (cache, result) => {
      const response = result.data?.requestNewDebitCard
      if (!response?.card) {
        return
      }

      cacheNewDebitCard({ cache, newCard: response.card, client })
    },
  })

  return { requestDebitCardMutation, isLoading: loading }
}
