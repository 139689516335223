import { DebitCardTransactionUpdate } from 'apps/component-merchant-portal/src/graphql/merchant-portal/subscriptions/debitCardTransactions'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { useSubscription } from 'hooks/useSubscription'
import {
  DebitCardTransactionUpdate as DebitCardTransactionUpdateResponse,
  DebitCardTransactionUpdate_onDebitCardTransactionUpdate as Update,
  DebitCardTransactionUpdateVariables,
} from 'types/gql-types/DebitCardTransactionUpdate'

interface Props {
  onUpdate: (update: Update) => void
  debitCardId?: string
  debitCardAccountUuid?: string
  skip?: boolean
}

export const useSubscribeToDebitCardTransactions = ({
  onUpdate,
  debitCardId,
  debitCardAccountUuid,
  skip,
}: Props) => {
  const entityUuid = rvEntityDetails()?.id

  useSubscription<
    DebitCardTransactionUpdateResponse,
    DebitCardTransactionUpdateVariables
  >(DebitCardTransactionUpdate, {
    onSubscriptionData: (result) => {
      const doesNotMatchDebitCardId =
        debitCardId !== undefined &&
        result.subscriptionData.data?.onDebitCardTransactionUpdate
          ?.debitCardId !== debitCardId

      const doesNotMatchDebitCardAccountUuid =
        debitCardAccountUuid !== undefined &&
        result.subscriptionData.data?.onDebitCardTransactionUpdate
          ?.debitCardAccountUuid !== debitCardAccountUuid

      if (doesNotMatchDebitCardAccountUuid || doesNotMatchDebitCardId) {
        return
      }

      if (result.subscriptionData.data?.onDebitCardTransactionUpdate) {
        onUpdate(result.subscriptionData.data.onDebitCardTransactionUpdate)
      }
    },
    variables: {
      entityUuid,
    },
    skip,
  })
}
