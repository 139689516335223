import { useRef } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Flex } from '@npco/zeller-design-system'
import { isEmpty } from 'lodash-es'

import { useSelectedTransaction } from 'hooks/useSelectedTransaction/useSelectedTransaction'
import dayjs from 'utils/dayjs'
import { GetTransactions_getTransactions_transactions as Transaction } from 'types/gql-types/GetTransactions'
import { EmptyStateMessageOnly } from 'components/EmptyState/EmptyStateMessageOnly'
import { ListGroupTransactionItem } from 'components/ListGroupTransactionItem/ListGroupTransactionItem'
import { renderGroup } from 'components/Lists/GroupedList/Group/Group.utils'
import { GroupedList } from 'components/Lists/GroupedList/GroupedList'
import { ListGroupTitle } from 'components/Lists/ListGroupTitle/ListGroupTitle'
import { StyledListWrapper } from 'components/Lists/ListWrapper/ListWrapper.styled'
import { LoaderList } from 'components/LoaderList'
import { NoSearchResults } from 'components/Placeholders/NoAccountTransactions'
import { useNextPreviousTransaction } from 'components/TransactionsDrawer/hooks/useNextPreviousTransaction/useNextPreviousTransaction'
import { TransactionsDrawer } from 'components/TransactionsDrawer/TransactionsDrawer'

import { translations } from './PaymentsLIst.i18n'

interface PaymentsListProps {
  areFiltersInDefaultState: boolean
  hasMore: boolean
  isLoading: boolean
  isLoadingMore: boolean
  isMappingDone: boolean
  onLoadMore: () => Promise<void>
  transactionList: Transaction[][]
  onLink?: () => void
  onUnlink?: (unlinkedCardHolderUuid: string) => void
}

export const PaymentsList = ({
  areFiltersInDefaultState,
  hasMore,
  isLoading,
  isLoadingMore,
  isMappingDone,
  onLoadMore,
  transactionList,
  onLink,
  onUnlink,
}: PaymentsListProps) => {
  const t = useTranslations(translations)
  const transactionsRefs = useRef<Record<string, HTMLDivElement | null>>({})
  const { areDetailsOpen, selectedTransaction, onOpen, onClose } =
    useSelectedTransaction()
  const { onSelectNextTransaction, onSelectPreviousTransaction } =
    useNextPreviousTransaction({
      groupedTransactions: transactionList,
      selectedTransaction,
      transactionsRefs,
    })

  const handleRenderItem = (listItemsData: Transaction) => (
    <ListGroupTransactionItem
      ref={(ref) => {
        transactionsRefs.current[listItemsData.id] = ref
      }}
      key={listItemsData.id}
      data={listItemsData}
      onRecordSelect={onOpen}
      timestampFormat="date"
    />
  )

  const getMainContent = () => {
    if (!isLoadingMore && (isLoading || !isMappingDone)) {
      return (
        <Flex flex="1" pt="40px">
          <LoaderList />
        </Flex>
      )
    }

    if (isEmpty(transactionList) && !areFiltersInDefaultState) {
      return <NoSearchResults />
    }

    if (isEmpty(transactionList)) {
      return (
        <EmptyStateMessageOnly
          title={t('title')}
          description={t('description')}
          illustrationName="AccountCreated"
        />
      )
    }

    return (
      <StyledListWrapper>
        <GroupedList
          data={transactionList}
          onScrollEnd={onLoadMore}
          renderItem={handleRenderItem}
          renderGroup={renderGroup({
            getGroupKey: ([firstElement]) => {
              return dayjs(firstElement?.timestamp).startOf('year')
            },
            renderHeader: ([firstElement]) => (
              <ListGroupTitle data-testid="list-group-header">
                {dayjs(firstElement?.timestamp).year()}
              </ListGroupTitle>
            ),
          })}
          hasMore={hasMore}
        />
      </StyledListWrapper>
    )
  }

  return (
    <>
      {getMainContent()}
      <TransactionsDrawer
        areDetailsOpen={areDetailsOpen}
        onClose={onClose}
        onLink={onLink}
        onUnlink={onUnlink}
        selectedTransaction={selectedTransaction}
        selectNextTransaction={onSelectNextTransaction}
        selectPreviousTransaction={onSelectPreviousTransaction}
      />
    </>
  )
}
