import { useCallback, useMemo } from 'react'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { ErrorLogger } from '@npco/utils-error-logger'
import { showErrorToast } from '@npco/zeller-design-system'
import { v4 as randomUUID } from 'uuid'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import {
  AddCardStage,
  CardFormatOption,
  CardTypeOption,
  CreateReviewCardState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useGoToCompleteCardStage } from 'hooks/useGoToCompleteCardStage/useGoToCompleteCardStage'
import { CompleteCardStage } from 'hooks/useGoToCompleteCardStage/useGoToCompleteCardStage.types'
import { useGoToSetPinStage } from 'hooks/useGoToSetPinStage/useGoToSetPinStage'
import { ROUTE } from 'const/routes'
import { getCustomerDisplayName } from 'utils/customers'
import { UNEXPECTED_ERROR } from 'types/errors'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'
import { useModalCloseTransitionContext } from 'pages/GlobalModals/contexts/ModalCloseTransitionContext/useModalCloseTransitionContext'

import { useGetCardNickname } from '../CardDetailsReview/hooks/useGetCardNickname'
import { useRequestNewCard } from './useRequestNewCard'

export const useReviewCard = () => {
  const { userData } = useLoggedInUser()
  const { state } = useAddCardState<CreateReviewCardState>()
  const { goToAddCardStage } = useGoToAddCardStage()
  const { goToCompleteCardStage } = useGoToCompleteCardStage()
  const { goToSetPinStage } = useGoToSetPinStage()
  const { requestNewCard, isLoading } = useRequestNewCard()
  const { getCardNickname } = useGetCardNickname()
  const { closeWithTransition } = useModalCloseTransitionContext()
  const idempotencyKey = useMemo(() => randomUUID(), [])

  const checkStateIsDefined = (
    stateToCheck: CreateReviewCardState | null
  ): stateToCheck is CreateReviewCardState => {
    if (stateToCheck === null) {
      ErrorLogger.report('[Banking] Card review stage has no state')
      showErrorToast()
      return false
    }

    return true
  }

  const handleConfirm = useCallback(async () => {
    if (!checkStateIsDefined(state)) {
      return
    }

    const redirectPath =
      state.cardType === CardTypeOption.DebitCard
        ? ROUTE.PORTAL_CARDS_DEBIT
        : ROUTE.PORTAL_CARDS_CORPORATE

    const result = await requestNewCard({
      accountUuid: state.account.id,
      address: state.address,
      cardholderUuid: state.cardholder.id,
      colour: state.colour,
      format: state.cardFormat,
      nickname: getCardNickname({
        nickname: state.nickname,
        firstName: state.cardholder.firstName,
        cardType: state.cardType,
      }),
      type: state.cardType,
      usePin: state.usePin,
      velocityControl: state.velocityControl
        ? {
            ...state.velocityControl,
            idempotencyKey,
          }
        : undefined,
      entityAddressTimezone: state?.entityAddressTimezone,
    })

    if (result === UNEXPECTED_ERROR) {
      showErrorToast()
      return
    }

    if (state.cardFormat === CardFormatOption.Physical) {
      closeWithTransition(() => {
        goToCompleteCardStage(
          {
            stage: CompleteCardStage.CompleteCardDeliveryStage,
            cardholderName: getCustomerDisplayName({
              firstname: state.cardholder.firstName,
              lastname: state.cardholder.lastName,
            }),
          },
          {
            hasOpenTransition: true,
            pathname: redirectPath,
          }
        )
      })
      return
    }

    if (state.cardholder.id !== userData?.id) {
      closeWithTransition(() => {
        goToCompleteCardStage(
          {
            stage: CompleteCardStage.CompleteCardInviteStage,
            cardholderFirstName: state.cardholder.firstName,
          },
          {
            hasOpenTransition: true,
            pathname: redirectPath,
          }
        )
      })

      return
    }

    goToSetPinStage(
      { stage: 'SetPin', cardId: result },
      {
        pathname: redirectPath,
      }
    )
  }, [
    idempotencyKey,
    state,
    requestNewCard,
    userData?.id,
    goToCompleteCardStage,
    goToSetPinStage,
    closeWithTransition,
    getCardNickname,
  ])

  const goBackToCardDesignStage = useCallback(() => {
    if (!checkStateIsDefined(state)) {
      return
    }

    goToAddCardStage({
      ...state,
      stage: AddCardStage.CreateSelectCardDesignStage,
    })
  }, [goToAddCardStage, state])

  const goBackToCardTypeStage = useCallback(() => {
    if (!checkStateIsDefined(state)) {
      return
    }

    goToAddCardStage({
      ...state,
      stage: AddCardStage.CreateSelectCardTypeStage,
    })
  }, [goToAddCardStage, state])

  const goBackToDeliveryAddressStage = useCallback(() => {
    if (!checkStateIsDefined(state)) {
      return
    }

    goToAddCardStage({
      ...state,
      stage: AddCardStage.CreateSelectDeliveryAddressStage,
    })
  }, [goToAddCardStage, state])

  const goBackToSpendManagementControlStage = useCallback(() => {
    if (!checkStateIsDefined(state)) {
      return
    }

    goToAddCardStage({
      ...state,
      stage: AddCardStage.CreateSpendControlsStage,
      cardType: CardTypeOption.ExpenseCard,
    })
  }, [goToAddCardStage, state])

  return {
    goBackToCardDesignStage,
    goBackToCardTypeStage,
    goBackToDeliveryAddressStage,
    goBackToSpendManagementControlStage,
    state: state as CreateReviewCardState,
    handleConfirm,
    isLoading,
  }
}
