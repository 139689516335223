import { useMemo } from 'react'
import {
  BREAKPOINT,
  ModalBasic,
  ModalBasicProps,
  zIndexMap,
} from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import highlightUrl from 'assets/svg/highlight.svg'
import { useElementRect } from 'hooks/useElementRect'
import { FadeUpAnimation } from 'components/FadeUpAnimation/FadeUpAnimation'

import { ModalOverlay } from '../ModalOverlay/ModalOverlay'

const SpotlightCaret = styled.div`
  --width: 10px;

  width: 0;
  height: 0;
  border-top: calc(var(--width) * 1.5) solid transparent;
  border-bottom: calc(var(--width) * 1.5) solid transparent;
  border-right: var(--width) solid white;

  position: absolute;
  top: 12px;
  margin-top: calc(var(--width) / 2 * -1);
  transition: all 0.3s;
`

export const SidebarSpotlightModal = styled(
  ({
    spotlightSelector,
    children,
    ...props
  }: ModalBasicProps & {
    spotlightSelector?: string
  }) => {
    const rect = useElementRect(spotlightSelector)

    const caretStyle = useMemo(() => {
      if (!rect) {
        return undefined
      }
      return {
        top: `calc(${rect.y}px - var(--modal-top))`,
      }
    }, [rect])

    return (
      <ModalBasic
        {...props}
        overlayElement={(_, element) => (
          <ModalOverlay
            defaultOverlayStyle={{}}
            spotlight={{
              selector: spotlightSelector,
              padding: { top: 14, bottom: 14, left: 10, right: 10 },
              backgroundPath: highlightUrl,
            }}
          >
            <FadeUpAnimation
              {...(spotlightSelector && {
                style: {
                  position: 'absolute',
                  top: 0,
                  zIndex: zIndexMap.modal,
                  height: '100%',
                  width: '100%',
                },
              })}
            >
              {element}
            </FadeUpAnimation>
          </ModalOverlay>
        )}
      >
        {!!(spotlightSelector && rect && caretStyle) && (
          <SpotlightCaret data-testid="caret" style={caretStyle} />
        )}
        {children}
      </ModalBasic>
    )
  }
)<{ $isSpotlightVisible?: boolean }>`
  --modal-top: 60px;
  --sidebar-md-width: 200px;
  --sidebar-width: 230px;
  --spotlight-caret-width: 20px;
  --page-max-width: 1920px;
  --modal-left-offset: max(0px, calc((100vw - var(--page-max-width)) / 2));

  z-index: ${zIndexMap.modal};
  border-radius: 12px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  width: calc(100vw - 32px);
  height: calc(100vh - 32px);

  ${SpotlightCaret} {
    display: none;
  }

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    width: calc(100vw - 48px);
    height: calc(100vh - 36px);
  }

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    transform: unset;
    transition: all 0.3s;

    position: absolute;
    top: 41px;
    left: calc(
      var(--sidebar-md-width) + (100vw - var(--sidebar-md-width) - 720px) / 2
    );

    width: calc(720px + var(--spotlight-caret-width));
    height: calc(100% - 82px);

    background-color: unset;

    & > div:not(${SpotlightCaret}) {
      background-color: white;
      border-radius: 12px;

      margin-left: var(--spotlight-caret-width);
    }
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    left: calc(
      var(--spotlight-caret-width) + var(--sidebar-width) + 14px +
        var(--modal-left-offset)
    );
    top: var(--modal-top);
    width: calc(var(--spotlight-caret-width) + 1024px);
    height: 636px;
    min-height: 636px;

    ${SpotlightCaret} {
      display: unset;
      --width: var(--spotlight-caret-width);
    }
  }

  ${({ $isSpotlightVisible }) =>
    !$isSpotlightVisible &&
    css`
      @media screen and (min-width: 0px) {
        position: unset;
        transform: translate(0, 0);
      }
    `}
`
