import { gql } from '@apollo/client'

export const LinkContactWithCardholder = gql`
  mutation LinkContactWithCardholder($cardholderUuid: ID!, $contactUuid: ID!) {
    linkContactWithCardholder(
      cardholderUuid: $cardholderUuid
      contactUuid: $contactUuid
    )
  }
`

export const SendReceiptMutation = gql`
  mutation SendReceipt($input: SendReceiptInput!) {
    sendReceipt(input: $input)
  }
`

export const UpdateTransactionNotesMutation = gql`
  mutation UpdateTransactionNotes($notes: String!, $transactionUuid: ID!) {
    updateTransactionNotes(notes: $notes, transactionUuid: $transactionUuid)
  }
`

export const DeleteTransactionNotesMutation = gql`
  mutation DeleteTransactionNotes($transactionUuid: ID!) {
    deleteTransactionNotes(transactionUuid: $transactionUuid)
  }
`

export const UnlinkContactWithCardholder = gql`
  mutation UnlinkContactWithCardholder(
    $cardholderUuid: ID!
    $contactUuid: ID!
  ) {
    unlinkContactWithCardholder(
      cardholderUuid: $cardholderUuid
      contactUuid: $contactUuid
    )
  }
`
export const RefundTransaction = gql`
  mutation RefundTransaction($input: RefundTransactionInput!) {
    refundTransaction(input: $input) {
      approvalCode
      id
      responseCode
      rrn
    }
  }
`
