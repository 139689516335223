import { gql } from '@apollo/client'

export const AddSubcategory = gql`
  mutation AddSubcategory($input: EntitySubcategoryInput!) {
    addEntitySubcategory(input: $input)
  }
`

export const EditSubcategory = gql`
  mutation EditSubcategory($subcategoryUuid: ID!, $subcategory: String!) {
    updateEntitySubcategory(
      subcategoryUuid: $subcategoryUuid
      subcategory: $subcategory
    )
  }
`

export const DeleteSubcategory = gql`
  mutation DeleteSubcategory($subcategoryUuid: ID!) {
    removeEntitySubcategory(subcategoryUuid: $subcategoryUuid)
  }
`
