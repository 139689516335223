import { useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Flex, HeadingStyles, ToggleForm } from '@npco/zeller-design-system'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'
import { Field, useFormikContext } from 'formik'

import { SettingsPayments } from 'types/settings'
import { SiteCache } from 'types/site'
import { BasicModal } from 'components/BasicModal'

import { getDisplaySurchargeFeePercentage } from '../../SitePayments.utils'
import { IconsBar } from '../IconsBar/IconsBar'
import { Warning } from '../Warning/Warning'
import { translations } from './Moto.i18n'
import * as styled from './Moto.styled'
import { MotoSurchargeOptions } from './MotoSurchargeOptions.fields'

interface Props {
  siteData: SiteCache
}

const MOTO_ENABLED = 'motoEnabled'

export const Moto = ({ siteData }: Props) => {
  const { values, initialValues, setFieldValue } =
    useFormikContext<SettingsPayments>()
  const { isModalOpen, closeModal, setIsModalOpen } = useModalState()
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const t = useTranslations(translations)
  const entityDetails = useReactiveVar(rvEntityDetails)

  const exitModal = (e: KeyboardEvent) => {
    if (isModalOpen && !isFirstLoad && e.code === 'Escape') {
      setFieldValue(MOTO_ENABLED, false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', exitModal, false)

    return () => {
      document.removeEventListener('keydown', exitModal, false)
    }
  })

  useEffect(() => {
    setIsModalOpen(Boolean(values.motoEnabled))

    if (values.motoEnabled !== initialValues.motoEnabled) {
      setIsFirstLoad(false)
    }
  }, [values.motoEnabled, initialValues.motoEnabled, setIsModalOpen])

  if (!entityDetails.canAcquire || !entityDetails.canAcquireMoto) {
    return (
      <>
        <styled.Title>{t('moto')}</styled.Title>
        <Warning />
      </>
    )
  }

  return (
    <Flex flexDirection="column" gridGap="24px">
      <ModalBasic
        isOpen={isModalOpen && !isFirstLoad}
        onCancel={() => {
          setFieldValue(MOTO_ENABLED, false)
          closeModal()
        }}
      >
        <styled.ModalWrapper>
          <BasicModal
            dataTestId="enable-moto-modal"
            modalTitle={t('turnMotoOn')}
            modalDescription={t('turnMotoOnDescription')}
            primaryButtonLabel={t('accept')}
            onPrimaryButtonClick={() => {
              setFieldValue(MOTO_ENABLED, true)
              closeModal()
            }}
            onSecondaryButtonClick={() => {
              setFieldValue(MOTO_ENABLED, false)
              closeModal()
            }}
          />
        </styled.ModalWrapper>
      </ModalBasic>

      <Flex
        alignItems="center"
        justifyContent="space-between"
        data-testid="moto"
      >
        <styled.Title>{t('moto')}</styled.Title>
        <div data-testid="motoEnabled-switch">
          <Field
            component={ToggleForm}
            name="motoEnabled"
            value={values.motoEnabled}
          />
        </div>
      </Flex>
      <styled.Description>{t('motoDescription')}</styled.Description>
      {Boolean(values.schemesMoto?.length) && (
        <IconsBar values={values.schemesMoto} />
      )}
      {values.motoEnabled && (
        <>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            data-testid="motoFee"
          >
            <styled.Subtitle withStyles={HeadingStyles.H5}>
              {t('processingFee')}
            </styled.Subtitle>
            <styled.Subtitle>
              {getDisplaySurchargeFeePercentage(
                siteData.surchargesTaxes?.feePercentMoto
              )}
            </styled.Subtitle>
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            data-testid="motoDefault"
          >
            <styled.Subtitle withStyles={HeadingStyles.H5}>
              {t('defaultMoto')}
            </styled.Subtitle>
            <div>
              <Field
                component={ToggleForm}
                name="motoDefault"
                value={values.motoDefault}
              />
            </div>
          </Flex>
          <Flex flexDirection="column" gridGap="16px">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              data-testid="motoRequirePIN"
            >
              <styled.Subtitle withStyles={HeadingStyles.H5}>
                {t('requirePIN')}
              </styled.Subtitle>
              <div>
                <Field
                  component={ToggleForm}
                  name="motoRequirePIN"
                  value={values.motoRequirePIN}
                />
              </div>
            </Flex>
            <styled.Description>
              {t('requirePINdescription')}
            </styled.Description>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            data-testid="moto-options"
          >
            <styled.Subtitle withStyles={HeadingStyles.H5}>
              {t('motoSurcharge')}
            </styled.Subtitle>
            <div data-testid="motoPaymentsSurchargeEnabled">
              <Field
                component={ToggleForm}
                name="motoPaymentsSurchargeEnabled"
                value={values.motoPaymentsSurchargeEnabled}
              />
            </div>
          </Flex>

          {values.motoPaymentsSurchargeEnabled && (
            <MotoSurchargeOptions siteData={siteData} />
          )}
        </>
      )}
    </Flex>
  )
}
