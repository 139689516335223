import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetInvoiceSettingsLogoUploadUrlQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type GetInvoiceSettingsLogoUploadUrlQueryResponse = {
  __typename?: 'Query'
  getInvoiceSettingsLogoUploadUrl: string
}

export const GetInvoiceSettingsLogoUploadUrl = gql`
  query GetInvoiceSettingsLogoUploadUrl {
    getInvoiceSettingsLogoUploadUrl
  }
` as unknown as TypedDocumentNode<
  GetInvoiceSettingsLogoUploadUrlQueryResponse,
  GetInvoiceSettingsLogoUploadUrlQueryVariables
>

/**
 * __useGetInvoiceSettingsLogoUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetInvoiceSettingsLogoUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceSettingsLogoUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceSettingsLogoUploadUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoiceSettingsLogoUploadUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvoiceSettingsLogoUploadUrlQueryResponse,
    GetInvoiceSettingsLogoUploadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetInvoiceSettingsLogoUploadUrlQueryResponse,
    GetInvoiceSettingsLogoUploadUrlQueryVariables
  >(GetInvoiceSettingsLogoUploadUrl, options)
}
export function useGetInvoiceSettingsLogoUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceSettingsLogoUploadUrlQueryResponse,
    GetInvoiceSettingsLogoUploadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetInvoiceSettingsLogoUploadUrlQueryResponse,
    GetInvoiceSettingsLogoUploadUrlQueryVariables
  >(GetInvoiceSettingsLogoUploadUrl, options)
}
export type GetInvoiceSettingsLogoUploadUrlQueryHookResult = ReturnType<
  typeof useGetInvoiceSettingsLogoUploadUrlQuery
>
export type GetInvoiceSettingsLogoUploadUrlLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceSettingsLogoUploadUrlLazyQuery
>
export type GetInvoiceSettingsLogoUploadUrlQueryResult = Apollo.QueryResult<
  GetInvoiceSettingsLogoUploadUrlQueryResponse,
  GetInvoiceSettingsLogoUploadUrlQueryVariables
>
