import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'

export enum ItemsImportStage {
  UploadStage = 'UploadStage',
  MatchStage = 'MatchStage',
}

interface ItemsImportModalState {
  ItemsImportModal: { stage: ItemsImportStage } | undefined
}

export const useItemsImportModalStage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const state = location.state as ItemsImportModalState
  const { pathname } = location

  const stage = useMemo(() => {
    if (!state?.ItemsImportModal?.stage) {
      return ItemsImportStage.UploadStage
    }

    return state.ItemsImportModal.stage
  }, [state?.ItemsImportModal?.stage])

  const goToStage = useCallback(
    (newStage: ItemsImportStage) => {
      navigate(pathname, {
        state: { ItemsImportModal: { stage: newStage } },
      })
    },
    [navigate, pathname]
  )
  const goToUploadStage = useCallback(() => {
    goToStage(ItemsImportStage.UploadStage)
  }, [goToStage])

  const goToMatchStage = useCallback(() => {
    goToStage(ItemsImportStage.MatchStage)
  }, [goToStage])

  const goToItems = useCallback(() => {
    navigate(ROOT.PORTAL.ITEM_MANAGEMENT.ITEMS.path)
  }, [navigate])

  return { stage, goToUploadStage, goToMatchStage, goToItems }
}
