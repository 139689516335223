import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { ConnectionType } from '@npco/mp-gql-types'
import { ErrorLogger } from '@npco/utils-error-logger'
import {
  DrawerWithIcon,
  Flex,
  showErrorToast,
  showSuccessToast,
  useModalState,
} from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { ReactComponent as XeroLogo } from 'assets/svg/logos/xero.svg'
import { useAbsolutePath } from 'hooks/useAbsolutePath'
import { useQueryParams } from 'hooks/useQueryParams'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { ConfigureXeroPaymentServicesVariables } from 'types/gql-types/ConfigureXeroPaymentServices'

import { Syncing } from '../../components/Syncing'
import { useCompleteConnectionOAuthFlow } from '../../hooks/useCompleteConnectionOAuthFlow'
import { ConfirmNavigationModal } from '../components/ConfirmNavigationModal'
import { useConfigurePaymentServices } from '../hooks/useConfigurePaymentServices'
import { updateOnSuccess } from '../XeroPaymentServices.utils'
import { StepOne } from './StepOne'
import { StepThree } from './StepThree'
import { StepTwo } from './StepTwo'
import { Form } from './XeroPaymentServicesConnectionSetup.styled'
import { XeroPaymentServicesConnetionFormValues } from './XeroPaymentServicesConnectionSetup.types'

const initialValues: XeroPaymentServicesConnetionFormValues = {
  step: 1,
  organisation: '',
  street: '',
  suburb: '',
  state: '',
  postcode: '',
  businessName: '',
  xeroThemeIds: [],
}

const prepareConfigureXeroPaymentServicesVariables = (
  values: XeroPaymentServicesConnetionFormValues
): ConfigureXeroPaymentServicesVariables => {
  return {
    config: {
      enabledThemes: values.xeroThemeIds.map((xeroThemeId) => {
        return { xeroThemeId }
      }),
      site: {
        address: {
          street: values.street,
          suburb: values.suburb,
          state: values.state,
          postcode: values.postcode,
          country: 'Australia',
        },
        businessName: values.businessName,
      },
      xeroOrganisationUuid: values.organisation,
    },
  }
}

export const XeroPaymentServicesConnectionSetup = () => {
  const navigate = useNavigate()
  const params = useQueryParams()
  const absolutePath = useAbsolutePath()
  const { completeConnectionOAuthFlow } = useCompleteConnectionOAuthFlow()
  const { configureXeroPaymentServices } = useConfigurePaymentServices()
  const [isFinalisingConnection, setIsFinalisingConnection] = useState(true)
  const {
    isModalOpen,
    openModal: openConfirmNavigationModal,
    closeModal,
  } = useModalState()

  const handleConfigure = useCallback(
    async (values: XeroPaymentServicesConnetionFormValues) => {
      try {
        await configureXeroPaymentServices({
          variables: prepareConfigureXeroPaymentServicesVariables(values),
          update: updateOnSuccess,
        })
        showSuccessToast(
          translate(
            'page.settings.connections.xeroBankFeeds.toast.connectionSuccess'
          )
        )
        navigate(ROOT.PORTAL.SETTINGS.CONNECTIONS.path)
      } catch (err) {
        ErrorLogger.report(
          '[Payment] Error configure Xero Payment Services:',
          err
        )
        showErrorToast(
          translate(
            'page.settings.connections.xeroPaymentServices.toast.accessError'
          )
        )
        navigate(ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES.path, {
          state: prepareConfigureXeroPaymentServicesVariables(values),
        })
      }
    },
    [configureXeroPaymentServices, navigate]
  )

  const handleXeroOauthConnection = useCallback(async () => {
    const authCode = params.get('code')

    if (!authCode) {
      navigate(ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES.path)
      showErrorToast(
        translate(
          'page.settings.connections.xeroPaymentServices.toast.accessError'
        )
      )
      return
    }
    await completeConnectionOAuthFlow({
      variables: {
        connectionType: ConnectionType.XERO_PAYMENT_SERVICES,
        url: absolutePath,
      },
    })

    setIsFinalisingConnection(false)
  }, [params, completeConnectionOAuthFlow, absolutePath, navigate])

  const handleOnConfirmNavigation = useCallback(
    () => navigate(ROOT.PORTAL.SETTINGS.CONNECTIONS.path),
    [navigate]
  )

  useEffect(() => {
    handleXeroOauthConnection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <DrawerWithIcon
        isOpen
        icon={
          <Flex justifyContent="left">
            <XeroLogo width={48} height={48} />
          </Flex>
        }
        drawerTitle={translate(
          'page.settings.connections.xeroPaymentServices.title'
        )}
        onClose={openConfirmNavigationModal}
        overlayClassName="animated-drawer-overlay"
      >
        <Flex
          flexDirection="column"
          width="100%"
          height="100%"
          alignItems="center"
        >
          {isFinalisingConnection && (
            <Syncing connectionType={ConnectionType.XERO_PAYMENT_SERVICES} />
          )}
          {!isFinalisingConnection && (
            <Formik<XeroPaymentServicesConnetionFormValues>
              onSubmit={handleConfigure}
              initialValues={initialValues}
            >
              {({ values, handleSubmit, isSubmitting }) => (
                <>
                  {isSubmitting && (
                    <Syncing
                      connectionType={ConnectionType.XERO_PAYMENT_SERVICES}
                    />
                  )}
                  {!isSubmitting && (
                    <Form onSubmit={handleSubmit}>
                      {values.step === 1 && <StepOne />}
                      {values.step === 2 && <StepTwo />}
                      {values.step === 3 && <StepThree />}
                    </Form>
                  )}
                </>
              )}
            </Formik>
          )}
        </Flex>
      </DrawerWithIcon>
      <ConfirmNavigationModal
        isOpen={isModalOpen}
        onCancel={closeModal}
        onConfirm={handleOnConfirmNavigation}
        description={translate(
          'page.settings.connections.xeroPaymentServices.interruptFlowModal.createFlowDescription'
        )}
      />
    </>
  )
}
