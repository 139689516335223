import { useCallback, useEffect, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { ButtonGhost, Divider } from '@npco/zeller-design-system'
import { isEqual } from 'lodash-es'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translationsShared } from 'translations'

import * as styled from './MobileFilters.styled'

interface MobileFiltersProps<FilterType, SortType> {
  children: (props: {
    nextFilterInput: FilterType | null
    nextSortInput: SortType | null
    setNextFilterInput: (state: FilterType | null) => void
    setNextSortInput: (state: SortType | null) => void
  }) => React.ReactNode
  closeMobileFilters: () => void
  filterInput?: FilterType
  onNextFilterInput?: (nextFilterInput: FilterType | null) => void
  onNextSortInput?: (nextFilterInput: SortType | null) => void
  isMobileFiltersOpen: boolean
  sortInput?: SortType
  isDefaultVisibilityState: boolean
  setDefaultVisibilityState: () => void
  title?: string
}

export const MobileFilters = <FilterType = void, SortType = void>({
  children,
  closeMobileFilters,
  filterInput,
  isDefaultVisibilityState,
  isMobileFiltersOpen,
  onNextFilterInput: handleNextFilterInput,
  onNextSortInput: handleNextSortInput,
  setDefaultVisibilityState,
  sortInput,
  title,
}: MobileFiltersProps<FilterType, SortType>) => {
  const tShared = useTranslations(translationsShared)
  const isMobile = useIsMobileResolution()

  const [nextFilterInput, setNextFilterInput] = useState<FilterType | null>(
    filterInput ?? null
  )

  const [nextSortInput, setNextSortInput] = useState<SortType | null>(
    sortInput ?? null
  )

  useEffect(() => {
    // NOTE: if user viewport changes to desktop close mobile filters if open
    // and reset visibility state to filters list view
    if (!isMobile && isMobileFiltersOpen) {
      setDefaultVisibilityState()
      closeMobileFilters()
    }
  }, [
    closeMobileFilters,
    isMobile,
    isMobileFiltersOpen,
    setDefaultVisibilityState,
  ])

  useEffect(() => {
    // NOTE: if this component is continually mounted and a user switches
    // between desktop and mobile view we want to keep the next filters in sync
    if (isMobileFiltersOpen) {
      return
    }

    if (filterInput) {
      setNextFilterInput(filterInput)
    }

    if (sortInput !== undefined) {
      setNextSortInput(sortInput)
    }
  }, [isMobileFiltersOpen, filterInput, sortInput])

  const handleClear = useCallback(() => {
    setNextFilterInput(null)
    setNextSortInput(null)
  }, [setNextFilterInput, setNextSortInput])

  const handleModalClose = useCallback(() => {
    // NOTE: if not on default view return user to it
    if (!isDefaultVisibilityState) {
      setDefaultVisibilityState()
      return
    }

    // NOTE: only update filter input if it has changed to prevent unnecessary
    // re-renders and requests
    if (!isEqual(filterInput, nextFilterInput) && handleNextFilterInput) {
      handleNextFilterInput(nextFilterInput)
    }

    // NOTE: only update sort input if it has changed to prevent unnecessary
    // re-renders and requests
    if (!isEqual(sortInput, nextSortInput) && handleNextSortInput) {
      handleNextSortInput(nextSortInput)
    }

    closeMobileFilters()
  }, [
    closeMobileFilters,
    filterInput,
    handleNextFilterInput,
    handleNextSortInput,
    isDefaultVisibilityState,
    nextFilterInput,
    nextSortInput,
    setDefaultVisibilityState,
    sortInput,
  ])

  return (
    <styled.MobileFilters
      isOpen={isMobileFiltersOpen}
      onClose={handleModalClose}
      title={title}
      overlayClassName="animated-drawer-overlay"
    >
      <Divider margin="0 0 8px" />

      <styled.MobileFiltersContentWrapper>
        {children({
          nextFilterInput,
          nextSortInput,
          setNextFilterInput,
          setNextSortInput,
        })}
      </styled.MobileFiltersContentWrapper>

      {isDefaultVisibilityState && (
        <styled.MobileFiltersButtonWrapper>
          <ButtonGhost dataTestId="mobile-filters-clear" onClick={handleClear}>
            {tShared('clear')}
          </ButtonGhost>
        </styled.MobileFiltersButtonWrapper>
      )}
    </styled.MobileFilters>
  )
}
