import { useGetDrawerItemsLazyQuery } from './graphql/getDrawerItems.generated'

export const useGetDrawerItems = (
  hookOptions?: Parameters<typeof useGetDrawerItemsLazyQuery>[0]
) => {
  const [getQuery, { data, fetchMore, loading: isLoading }] =
    useGetDrawerItemsLazyQuery(hookOptions)

  const items = data?.getItems.items
  const nextToken = data?.getItems.nextToken

  return {
    getItems: getQuery,
    hasMore: Boolean(nextToken),
    isLoading,
    items,
    loadMore: fetchMore,
    nextToken,
  }
}
