import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  finish: 'Finish',
  hideTips: 'Hide Tips',
  modalBody:
    'Organise and keep track of the details for the items you sell regularly, all in one convenient location.',
  modalPrimaryButton: "Let's Go!",
  modalSecondaryButton: 'Skip Tour',
  modalTitle: 'Simplify Your Items',
  manageTitle: 'Managing Items',
  manageBody: 'Keep your list up to date by adding items and categories.',
  settingsTitle: 'Settings for Items',
  settingsBody:
    'Manage your item settings such as adjusting your GST preferences.',
  startAgain: 'Start Again',
  next: 'Next',
})
