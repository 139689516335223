import 'utils/yup'

import { InvoiceDiscountConfig, InvoiceItemUnit } from '@npco/mp-gql-types'
import { array, boolean, mixed, number, object, string } from 'yup'

import { convertLocaleStringToNumber } from 'utils/localeString'
import { translate } from 'utils/translations'

import {
  getDiscount,
  getInvoiceTotals,
  getItemTotals,
  getSubTotal,
} from '../components/InvoiceFormAccordions/components/InvoiceItemsAccordion/hooks/useInvoiceItemsCalculations'
import {
  INVOICE_DISCOUNT_PERCENTAGE_MAX_AMOUNT,
  INVOICE_DISCOUNT_PERCENTAGE_MIN_AMOUNT,
  INVOICE_DISCOUNT_PRICE_MIN_AMOUNT,
  INVOICE_ITEM_CALCULATE_QUANTITY_MAX_AMOUNT,
  INVOICE_ITEM_CALCULATE_QUANTITY_MIN_AMOUNT,
  INVOICE_ITEM_DESCRIPTION_MAX_CHARACTERS,
  INVOICE_ITEM_MIN_AMOUNT,
  INVOICE_ITEM_NAME_MAX_CHARACTERS,
} from '../Invoice.constants'
import { InvoiceFormFields } from '../Invoice.types'

export const itemsSchemaErrors = {
  itemsCalculatePriceMaxError: translate(
    'page.invoice.formSections.items.itemsCalculatePriceMaxError'
  ),
  itemsCalculatePriceMinError: translate(
    'page.invoice.formSections.items.itemsCalculatePriceMinError'
  ),
  itemsCalculatePriceTypeError: translate(
    'page.invoice.formSections.items.itemsCalculatePriceTypeError'
  ),
  itemsCalculateQuantityMaxError: translate(
    'page.invoice.formSections.items.itemsCalculateQuantityMaxError'
  ),
  itemsCalculateQuantityMinError: translate(
    'page.invoice.formSections.items.itemsCalculateQuantityMinError'
  ),
  itemsCalculateQuantityTypeError: translate(
    'page.invoice.formSections.items.itemsCalculateQuantityTypeError'
  ),
  itemsDescriptionMaxLengthError: translate(
    'page.invoice.formSections.items.itemsDescriptionMaxLengthError',
    {
      descriptionLength: INVOICE_ITEM_DESCRIPTION_MAX_CHARACTERS,
    }
  ),
  itemsMaxAmountError: (value: string) =>
    translate('page.invoice.formSections.items.itemsMaxAmountError', {
      value,
    }),
  itemsMinAmountError: (value: string) =>
    translate('page.invoice.formSections.items.itemsMinAmountError', { value }),
  itemsNameMaxLengthError: translate(
    'page.invoice.formSections.items.itemsNameMaxLengthError',
    {
      nameLength: INVOICE_ITEM_NAME_MAX_CHARACTERS,
    }
  ),
  itemsNameRequiredError: translate(
    'page.invoice.formSections.items.itemsNameRequiredError'
  ),
  itemsPriceMinAmountError: translate(
    'page.invoice.formSections.items.itemsPriceMinAmountError'
  ),
  itemsPriceRequiredError: translate(
    'page.invoice.formSections.items.itemsPriceRequiredError'
  ),
  itemsPriceTypeError: translate(
    'page.invoice.formSections.items.itemsPriceTypeError'
  ),
  itemsPriceBelowDiscountError: translate(
    'page.invoice.formSections.items.itemsPriceBelowDiscountError'
  ),
  itemsDiscountPercentageMaxError: translate(
    'page.invoice.formSections.items.invoiceDiscountPercentageMaxError'
  ),
  itemsDiscountPercentageMinError: translate(
    'page.invoice.formSections.items.invoiceDiscountPercentageMinError'
  ),
  itemsDiscountPercentageTypeError: translate(
    'page.invoice.formSections.items.invoiceDiscountPercentageTypeError'
  ),
  itemsDiscountPriceMinError: translate(
    'page.invoice.formSections.items.invoiceDiscountPriceMinError'
  ),
  itemsDiscountPriceTypeError: translate(
    'page.invoice.formSections.items.invoiceDiscountPriceTypeError'
  ),
  itemsDiscountAbovePriceError: translate(
    'page.invoice.formSections.items.itemsPriceBelowDiscountError'
  ),
}

export const currencySchema = object({
  intValue: number(),
  p: number(),
  s: mixed(),
  value: number(),
})

export const itemDiscountSchema = object({
  config: string().oneOf([
    InvoiceDiscountConfig.AMOUNT,
    InvoiceDiscountConfig.PERCENTAGE,
  ]),
  percentage: string()
    .isStringNumber(itemsSchemaErrors.itemsDiscountPercentageTypeError)
    .minStringNumber(
      INVOICE_DISCOUNT_PERCENTAGE_MIN_AMOUNT,
      itemsSchemaErrors.itemsDiscountPercentageMinError
    )
    .maxStringNumber(
      INVOICE_DISCOUNT_PERCENTAGE_MAX_AMOUNT,
      itemsSchemaErrors.itemsDiscountPercentageMaxError
    ),
  price: string()
    .isStringNumber(itemsSchemaErrors.itemsDiscountPriceTypeError)
    .minStringNumber(
      INVOICE_DISCOUNT_PRICE_MIN_AMOUNT,
      itemsSchemaErrors.itemsDiscountPriceMinError
    ),
})

export const itemSchema = object({
  description: string()
    .trim()
    .max(
      INVOICE_ITEM_DESCRIPTION_MAX_CHARACTERS,
      itemsSchemaErrors.itemsDescriptionMaxLengthError
    ),
  discount: object({
    config: string().oneOf([
      InvoiceDiscountConfig.AMOUNT,
      InvoiceDiscountConfig.PERCENTAGE,
    ]),
    percentage: string()
      .isStringNumber(itemsSchemaErrors.itemsDiscountPercentageTypeError)
      .maxStringNumber(
        INVOICE_DISCOUNT_PERCENTAGE_MAX_AMOUNT,
        itemsSchemaErrors.itemsDiscountPercentageMaxError
      )
      .minStringNumber(
        INVOICE_DISCOUNT_PERCENTAGE_MIN_AMOUNT,
        itemsSchemaErrors.itemsDiscountPercentageMinError
      ),
    price: string()
      .isStringNumber(itemsSchemaErrors.itemsDiscountPriceTypeError)
      .minStringNumber(
        INVOICE_DISCOUNT_PRICE_MIN_AMOUNT,
        itemsSchemaErrors.itemsDiscountPriceMinError
      ),
  }),
  name: string()
    .trim()
    .max(
      INVOICE_ITEM_NAME_MAX_CHARACTERS,
      itemsSchemaErrors.itemsNameMaxLengthError
    ),
  price: currencySchema
    .typeError(itemsSchemaErrors.itemsPriceTypeError)
    .test((_, context) => {
      const formValues = context?.options?.context?.values as InvoiceFormFields
      const item = context.parent

      const subTotal = getSubTotal({
        items: formValues.items,
        isTaxInclusive: formValues.itemsTaxInclusive,
      })

      const discount = getDiscount({
        discountConfig: formValues.discount.config,
        discountPercentage: formValues.discount.percentage,
        discountPrice: formValues.discount.price,
        total: subTotal,
      })

      const { isDiscountInvalid, isTotalBelowMinAmount } = getItemTotals({
        discount,
        subTotal,
        item,
        isTaxInclusive: formValues.itemsTaxInclusive,
      })

      if (isTotalBelowMinAmount) {
        return context.createError({
          message: itemsSchemaErrors.itemsPriceMinAmountError,
        })
      }

      if (isDiscountInvalid) {
        return context.createError({
          message: itemsSchemaErrors.itemsPriceBelowDiscountError,
        })
      }

      return true
    }),
  taxApplicable: boolean(),
})

export const itemSchemaRequired = object({
  name: string().trim().required(itemsSchemaErrors.itemsNameRequiredError),
})

export const itemsExceedsMaxAmountSchema = boolean().test(
  async (_, context) => {
    const totals = getInvoiceTotals({ values: context.parent })

    if (totals.isTotalAboveMaxAmount) {
      const { maximumLimit } = context.parent

      const value = maximumLimit.format()

      return context.createError({
        message: itemsSchemaErrors.itemsMaxAmountError(value),
      })
    }

    return true
  }
)

export const itemsExceedsMinAmountSchema = boolean().test(
  async (_, context) => {
    const { minimumLimit } = context.parent

    const totals = getInvoiceTotals({ values: context.parent })

    if (totals.isTotalBelowMinAmount) {
      const value = minimumLimit.format()

      return context.createError({
        message: itemsSchemaErrors.itemsMinAmountError(value),
      })
    }

    return true
  }
)

export const itemsApplyTaxSchema = boolean().required()
export const itemsTaxInclusiveSchema = boolean().required()
export const itemsOptionalSchema = array().of(itemSchema)
export const itemsRequiredSchema = array().of(itemSchemaRequired)

export const itemsCalculateSchema = object({
  quantity: string()
    .isStringNumber(itemsSchemaErrors.itemsCalculateQuantityTypeError)
    .minStringNumber(
      INVOICE_ITEM_CALCULATE_QUANTITY_MIN_AMOUNT,
      itemsSchemaErrors.itemsCalculateQuantityMinError
    )
    .maxStringNumber(
      INVOICE_ITEM_CALCULATE_QUANTITY_MAX_AMOUNT,
      itemsSchemaErrors.itemsCalculateQuantityMaxError
    ),
  price: currencySchema
    .typeError(itemsSchemaErrors.itemsCalculatePriceTypeError)
    .test((_, context) => {
      const { quantity, price } = context.parent

      const total = price.multiply(convertLocaleStringToNumber(quantity))

      if (total.intValue < INVOICE_ITEM_MIN_AMOUNT) {
        return context.createError({
          message: itemsSchemaErrors.itemsCalculatePriceMinError,
        })
      }

      return true
    }),
  unit: string().oneOf([
    InvoiceItemUnit.QUANTITY,
    InvoiceItemUnit.DAY,
    InvoiceItemUnit.HOUR,
  ]),
})
