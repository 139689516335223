import { useMutation } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'

import { RunTriggerManualBankfeedsSync } from './TriggerManualBankfeedsSync.graphql'

export const useTriggerManualBankfeedsSync = () => {
  const [manualBankfeedsSync, { loading, error }] = useMutation<boolean>(
    RunTriggerManualBankfeedsSync,
    {
      onError: (err) => {
        showApiErrorToast(err)
      },
      notifyOnNetworkStatusChange: true,
    }
  )
  return {
    isLoading: loading,
    error,
    manualBankfeedsSync,
  }
}

useTriggerManualBankfeedsSync.gql = RunTriggerManualBankfeedsSync
useTriggerManualBankfeedsSync.gqlData = undefined as unknown as boolean
