import { useContactHeadingQuery as useContactHeadingQueryApollo } from './graphql/ContactHeadingQuery.generated'

type UseContactHeadingQueryProps = {
  contactUuid: string
}

export const useContactHeadingQuery = ({
  contactUuid,
}: UseContactHeadingQueryProps) => {
  const { data, loading, error, refetch } = useContactHeadingQueryApollo({
    variables: { contactUuid },
    fetchPolicy: 'cache-only',
  })

  return {
    contact: data?.getContact ?? null,
    isLoadingContact: loading,
    error,
    refetch,
  }
}
