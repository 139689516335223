import { useMutation } from '@apollo/client'
import { AddEntityTagMutation } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/entityTags'
import { GetEntityTags } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/entityTags'

import {
  AddEntityTag as AddEntityTagResponse,
  AddEntityTagVariables,
} from 'types/gql-types/AddEntityTag'
import { GetEntityTags as GetEntityTagsType } from 'types/gql-types/GetEntityTags'

export const useAddEntityTag = () => {
  const [addEntityTag, { loading: isAddingEntityTag }] = useMutation<
    AddEntityTagResponse,
    AddEntityTagVariables
  >(AddEntityTagMutation)

  return {
    isAddingEntityTag,
    addEntityTag: async (tag: string) => {
      return addEntityTag({
        variables: { tag },
        update: (cache, result) => {
          if (result.data?.addEntityTag) {
            const data = cache.readQuery<GetEntityTagsType>({
              query: GetEntityTags,
            })

            cache.writeQuery<GetEntityTagsType>({
              query: GetEntityTags,
              data: {
                getEntityTags: [...(data?.getEntityTags || []), tag],
              },
            })
          }
        },
      })
    },
  }
}
