import { ContactType } from '@npco/mp-gql-types'

import { InfiniteList } from 'components/Lists/InfiniteList/InfiniteList'
import { NoContacts } from 'components/Placeholders/NoContacts/NoContacts'
import { SpinnerWrapped } from 'components/Spinner'
import { component } from 'translations'

import { useContactsContext } from '../ContactsContext/ContactsContext'
import { EmptyState } from '../EmptyState/EmptyState'
import { useContacts } from '../hooks/useContacts/useContacts'
import { PeopleListItem } from './PeopleListItem'

export const People = () => {
  const { filters, hasContact } = useContactsContext()

  const { called, contacts, loading, hasMore, loadMore } = useContacts({
    contactType: ContactType.PERSON,
    filters,
    limit: 50,
  })

  if (!called || loading) {
    return <SpinnerWrapped variant="centre" />
  }

  if (!hasContact) {
    return <EmptyState />
  }

  if (!loading && !contacts.length) {
    const hasSearchOrFilter = filters?.name || filters?.tags?.length > 0

    const description = hasSearchOrFilter
      ? [component.noResults.descriptionContactsPeople]
      : [
          component.noInitialResults.descriptionContactsPeople1,
          component.noInitialResults.descriptionContactsPeople2,
        ]

    const title = hasSearchOrFilter
      ? component.noResults.titleContactsPeople
      : component.noInitialResults.titleContactsPeople

    return <NoContacts description={description} title={title} />
  }

  return (
    <InfiniteList
      data={contacts}
      hasMore={hasMore}
      onScrollEnd={loadMore}
      renderItem={(contact, index) => (
        <PeopleListItem key={contact?.id ?? index} contact={contact} />
      )}
    />
  )
}
