import { ConnectionType } from '@npco/mp-gql-types'

import {
  CONNECTION_XERO_BANKFEEDS_CLIENTID,
  CONNECTION_XERO_PAYMENT_SERVICES_CLIENTID,
} from 'const/envs'
import { ROOT } from 'const/routes'

export const zellerRedirectURI = (connectionType: ConnectionType) => {
  const { host, protocol } = window.location
  switch (connectionType) {
    case ConnectionType.XERO_PAYMENT_SERVICES:
      return `${protocol}//${host}${ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_SETUP.path}`
    case ConnectionType.XERO_BANKFEEDS:
    default:
      return `${protocol}//${host}${ROOT.PORTAL.SETTINGS.CONNECTIONS.XERO_BANK_FEEDS_SETUP.path}`
  }
}

export const XERO_BANK_FEED_SCOPES =
  'email profile openid accounting.settings accounting.transactions bankfeeds offline_access'
export const XERO_PAYMENT_SERVICES_SCOPES =
  'email profile openid paymentservices accounting.settings accounting.transactions accounting.contacts offline_access'

export const getXeroConnectionScopes = (connectionType: ConnectionType) => {
  switch (connectionType) {
    case ConnectionType.XERO_PAYMENT_SERVICES:
      return XERO_PAYMENT_SERVICES_SCOPES
    case ConnectionType.XERO_BANKFEEDS:
    default:
      return XERO_BANK_FEED_SCOPES
  }
}

export const getXeroConnectionClientId = (connectionType: ConnectionType) => {
  switch (connectionType) {
    case ConnectionType.XERO_PAYMENT_SERVICES:
      return CONNECTION_XERO_PAYMENT_SERVICES_CLIENTID
    case ConnectionType.XERO_BANKFEEDS:
    default:
      return CONNECTION_XERO_BANKFEEDS_CLIENTID
  }
}

export const generateXeroOauthScopeAuthorizeURI = (
  connectionType: ConnectionType
) => {
  const xeroURI = new URL('https://login.xero.com/identity/connect/authorize')
  xeroURI.searchParams.set('response_type', 'code')
  xeroURI.searchParams.set(
    'client_id',
    getXeroConnectionClientId(connectionType) as string
  )
  xeroURI.searchParams.set('redirect_uri', zellerRedirectURI(connectionType))
  xeroURI.searchParams.set('scope', getXeroConnectionScopes(connectionType))
  return xeroURI.toString()
}
