import { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { DepositExportFormat } from '@npco/mp-gql-types'
import { renameAndDownloadFile } from '@npco/utils-file'
import { ExportDeposit } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/deposits'

import { ExportDeposit as ExportDepositType } from 'types/gql-types/ExportDeposit'
import { useDepositFilters } from 'pages/Deposits/Deposits.hooks'

export const useExportDeposits = (
  filename: string,
  format: DepositExportFormat
) => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)

  const { filters } = useDepositFilters(true)

  const [getFile] = useLazyQuery<ExportDepositType>(ExportDeposit, {
    variables: {
      filter: filters,
      format,
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setIsLoading(false)
      setHasError(true)
    },
    onCompleted: (data) => {
      if (!data?.exportDeposit?.downloadLink) {
        setIsLoading(false)
        setHasError(true)
        return
      }

      renameAndDownloadFile({
        link: data?.exportDeposit.downloadLink,
        filename,
        format,
      }).then(() => {
        setIsLoading(false)
        setHasLoaded(true)
      })
    },
  })

  const exportFile = () => {
    setHasLoaded(false)
    setHasError(false)
    setIsLoading(true)
    getFile()
  }

  return {
    exportFile,
    isLoading,
    hasError,
    hasLoaded,
  }
}
