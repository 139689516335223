import { useCallback, useMemo } from 'react'
import {
  GetItemFilterInput,
  GetItemsSortColumnName,
  GetItemsSortInput,
} from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Flex } from '@npco/zeller-design-system'
import currency from 'currency.js'
import { isNil } from 'lodash-es'

import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg'
import * as styled from 'components/MobileFilters/MobileFilters.styled'

import { useItemManagementContext } from '../../../ItemManagementContext/ItemManagementContext'
import { translations } from '../ItemsMobileFilters.i18n'
import { ItemMobileFiltersVisibilityStates } from '../ItemsMobileFilters.types'

interface FilterListProps {
  nextFilterInput?: GetItemFilterInput | null
  nextSortInput?: GetItemsSortInput | null
  setVisibilityState: (state: ItemMobileFiltersVisibilityStates) => void
}

export const FilterList = ({
  nextSortInput,
  nextFilterInput,
  setVisibilityState,
}: FilterListProps) => {
  const { filterCategories } = useItemManagementContext()

  const t = useTranslations(translations)

  const filterByCategoryContent = useMemo(() => {
    if (!nextFilterInput?.categoryUuidFilter) {
      return null
    }

    const categoryUuids =
      nextFilterInput?.categoryUuidFilter?.categoryUuids ?? []

    if (categoryUuids.length === 1) {
      const category = filterCategories?.find(
        (item) => item.id === categoryUuids[0]
      )

      const categoryName = `(${category?.name ?? t('unknownCategory')})`

      return (
        <styled.MobileFiltersFilterContent>
          {categoryName}
        </styled.MobileFiltersFilterContent>
      )
    }
    if (categoryUuids.length > 1) {
      return (
        <styled.MobileFiltersFilterContent>
          {`(${categoryUuids.length})`}
        </styled.MobileFiltersFilterContent>
      )
    }

    return null
  }, [filterCategories, nextFilterInput?.categoryUuidFilter, t])

  const filterByPriceContent = useMemo(() => {
    if (!nextFilterInput?.priceFilter) {
      return null
    }

    const from = nextFilterInput?.priceFilter?.from
    const to = nextFilterInput?.priceFilter?.to

    // NOTE: be wary of falsey values such as 0
    if (!isNil(from) && !isNil(to)) {
      const fromPrice = currency(
        currency(from, {
          fromCents: true,
          precision: 4,
        })
      ).format()

      const toPrice = currency(
        currency(to, {
          fromCents: true,
          precision: 4,
        })
      ).format()

      return (
        <styled.MobileFiltersFilterContent>
          {`(${fromPrice} - ${toPrice})`}
        </styled.MobileFiltersFilterContent>
      )
    }

    return null
  }, [nextFilterInput?.priceFilter])

  const filterBySearchContent = useMemo(() => {
    if (!nextFilterInput?.textSearchFilter) {
      return null
    }

    return (
      <styled.MobileFiltersFilterContent>
        {`(${nextFilterInput.textSearchFilter})`}
      </styled.MobileFiltersFilterContent>
    )
  }, [nextFilterInput?.textSearchFilter])

  const sortByFilterContent = useMemo(() => {
    if (!nextSortInput) {
      return null
    }

    const { ascending, columnName } = nextSortInput

    const isAscending = ascending === true
    const isDescending = ascending === false
    const isSortByName = columnName === GetItemsSortColumnName.Name
    const isSortByPrice = columnName === GetItemsSortColumnName.Price

    if (isAscending && isSortByName) {
      return (
        <styled.MobileFiltersFilterContent>
          {`(${t('itemName')}: ${t('aToZ')})`}
        </styled.MobileFiltersFilterContent>
      )
    }
    if (isDescending && isSortByName) {
      return (
        <styled.MobileFiltersFilterContent>
          {`(${t('itemName')}: ${t('zToA')})`}
        </styled.MobileFiltersFilterContent>
      )
    }
    if (isDescending && isSortByPrice) {
      return (
        <styled.MobileFiltersFilterContent>
          {`(${t('itemPrice')}: ${t('highToLow')})`}
        </styled.MobileFiltersFilterContent>
      )
    }
    if (isAscending && isSortByPrice) {
      return (
        <styled.MobileFiltersFilterContent>
          {`(${t('itemPrice')}: ${t('lowToHigh')})`}
        </styled.MobileFiltersFilterContent>
      )
    }

    return null
  }, [nextSortInput, t])

  const handleClick = useCallback(
    (state: ItemMobileFiltersVisibilityStates) => () =>
      setVisibilityState(state),
    [setVisibilityState]
  )

  return (
    <div data-testid="items-mobile-filters-list">
      <styled.MobileFiltersButtonIconRightButton
        icon={ArrowRight}
        onClick={handleClick(ItemMobileFiltersVisibilityStates.SortBy)}
      >
        <Flex
          alignItems="baseline"
          data-testid="items-mobile-filters-sort-by"
          overflow="hidden"
        >
          {t('sortBy')} {sortByFilterContent}
        </Flex>
      </styled.MobileFiltersButtonIconRightButton>
      <styled.MobileFiltersButtonIconRightButton
        icon={ArrowRight}
        onClick={handleClick(
          ItemMobileFiltersVisibilityStates.FilterByCategory
        )}
      >
        <Flex
          alignItems="baseline"
          data-testid="items-mobile-filters-by-categories"
          overflow="hidden"
        >
          {t('category')} {filterByCategoryContent}
        </Flex>
      </styled.MobileFiltersButtonIconRightButton>
      <styled.MobileFiltersButtonIconRightButton
        icon={ArrowRight}
        onClick={handleClick(ItemMobileFiltersVisibilityStates.FilterByPrice)}
      >
        <Flex
          alignItems="baseline"
          data-testid="items-mobile-filters-by-price"
          overflow="hidden"
        >
          {t('price')} {filterByPriceContent}
        </Flex>
      </styled.MobileFiltersButtonIconRightButton>
      <styled.MobileFiltersButtonIconRightButton
        icon={ArrowRight}
        onClick={handleClick(ItemMobileFiltersVisibilityStates.FilterByKeyword)}
      >
        <Flex
          alignItems="baseline"
          data-testid="items-mobile-filters-by-keyword"
          overflow="hidden"
        >
          {t('search')} {filterBySearchContent}
        </Flex>
      </styled.MobileFiltersButtonIconRightButton>
    </div>
  )
}
