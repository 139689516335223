import { CustomerRole } from '@npco/mp-gql-types'
import { ACCOUNT } from '@npco/mp-utils-accounts-routing'

import { ROOT, ROUTE } from 'const/routes'

export const ROUTE_PERMISSIONS = {
  [ROUTE.PORTAL_OVERVIEW]: [CustomerRole.MANAGER, CustomerRole.ADMIN],
  [ROUTE.PORTAL_NOTIFICATIONS]: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  [ROUTE.PORTAL_ACCOUNTS_TRANSFER_CREATE]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_PAYMENTS_TRANSACTIONS]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_SETTINGS]: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  [ROUTE.PORTAL_PAYMENTS_TRANSACTIONS]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_PAYMENTS_DEPOSITS]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_PAYMENTS_REPORTS]: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  [ROUTE.PORTAL_PAYMENTS_SITES]: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  [ROUTE.PORTAL_PAYMENTS_SITES_SITE]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_PAYMENTS_SITES_CREATE_SITE]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_PAYMENTS_SITES_SITE_USERS_EDIT]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_TYPE]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_DETAILS]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_PAYMENTS_SITES_SITE_GENERAL_EDIT]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_PAYMENTS_SITES_SITE_GENERAL]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_PAYMENTS_SITES_SITE_DEVICES]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_PAYMENTS_SITES_SITE_USERS]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_PAYMENTS_SITES_SITE_PAYMENTS]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_PAYMENTS_SITES_SITE_RECEIPT]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_PAYMENTS_SITES_SITE_APPEARANCE]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_PAYMENTS_DEVICES]: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  [ROUTE.PORTAL_INVOICES]: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  [ROOT.PORTAL.ITEM_MANAGEMENT.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_ACCOUNTS]: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  [ROUTE.PORTAL_ACCOUNTS_TRANSACTIONS]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_ACCOUNTS_ACCOUNT]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_ACCOUNTS_ACCOUNT_TRANSACTIONS]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_ACCOUNTS_ACCOUNT_CARDS]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_ACCOUNTS_ACCOUNT_TRANSACTIONS_TRANSACTION]: [
    CustomerRole.ADMIN,
  ],
  [ACCOUNT().DETAILS.path]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_CARDS]: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  [ROUTE.PORTAL_CARDS_ACTIVATE]: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  [ROUTE.PORTAL_CARDS_CORPORATE]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_CARDS_DEBIT]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_PAYMENTS_DEPOSITS_STATEMENTS]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_PAYMENTS_DEPOSITS_SETTINGS]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_DETAILS]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_EDIT_ACCOUNT_DETAIL]: [
    CustomerRole.ADMIN,
  ],
  [ROUTE.PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT_SUCCESS]: [
    CustomerRole.ADMIN,
  ],
  [ROUTE.PORTAL_PAYMENTS_DEPOSITS_SETTINGS_EDIT_ACCOUNT]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_SETTINGS_PROFILE]: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  [ROUTE.PORTAL_SETTINGS_PROFILE_PERSONAL]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_SETTINGS_PROFILE_PERSONAL_EDIT]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_SETTINGS_PROFILE_SECURITY]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_SETTINGS_PROFILE_SECURITY_CHANGE_NUMBER]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_SETTINGS_PROFILE_SECURITY_VALIDATE_CODE]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROUTE.PORTAL_SETTINGS_USERS]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_SETTINGS_USERS_USER_EDIT]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_SETTINGS_USERS_USER_CREATE_TYPE]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_SETTINGS_USERS_USER_CREATE_DETAILS]: [CustomerRole.ADMIN],
  [ROUTE.PORTAL_SETTINGS_PROFILE_DOCUMENTS]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.PORTAL.SETTINGS.CONNECTIONS.path]: [CustomerRole.ADMIN],
}
