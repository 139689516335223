import { useState } from 'react'
import { Box, InfoBox } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { ContactType as AnalyticsContactType } from 'services/Analytics/events/contact'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import { ModalFormScrollable } from 'components/ModalFormScrollable/ModalFormScrollable'
import { page } from 'translations'

import { BusinessComboBoxSelect } from '../../../../components/BusinessComboBoxSelect'
import { PersonComboBoxSelect } from '../../../../components/PersonComboBoxSelect'
import {
  DEFAULT_BUSINESS_NAME,
  DEFAULT_PERSON_NAME,
} from '../../../../Contacts.constants'
import { InputSelectComboBoxItem } from '../../../../Contacts.types'
import { getContactName } from '../../../../Contacts.utils'
import { useLinkContacts } from '../../../../hooks/useLinkContacts/useLinkContacts'
import {
  rvSelectedContact,
  rvSelectedSubContact,
} from '../../../../rv-deprecated/contacts'
import {
  displaySubContactErrorToast,
  displaySubContactSuccessToast,
  getSubContactFormTitle,
  updateRvSelectedContactWithSubContact,
} from './AddSubContact.utils'
import { AddSubContactForm } from './AddSubContactForm'

interface AddSubContactProps {
  isSubContactTypeBusiness: boolean
  isModalOpen: boolean
  closeModal: () => void
}

export const AddSubContact = ({
  isSubContactTypeBusiness,
  isModalOpen,
  closeModal,
}: AddSubContactProps) => {
  const [newContactDefaultName, setNewContactDefaultName] = useState<
    string | null
  >(null)
  const [selectedItem, setSelectedItem] = useState<
    InputSelectComboBoxItem | null | undefined
  >(null)
  const [validationError, setValidationError] = useState('')
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  const currentContactName = getContactName(rvSelectedContact())
  const formTitle = getSubContactFormTitle(isSubContactTypeBusiness)

  const onModalClose = () => {
    setValidationError('')
    setNewContactDefaultName(null)
    setSelectedItem(null)
    closeModal()
  }

  const { linkContacts, isLinkingContacts } = useLinkContacts()

  const handleExistingContactLinking = async () => {
    const contact1Uuid = rvSelectedContact()?.id
    const contact2Uuid = rvSelectedSubContact()?.id

    if (!contact2Uuid) {
      // NOTE: if we already have a validation error persist
      if (validationError) {
        return
      }

      setValidationError(
        translate(
          isSubContactTypeBusiness
            ? 'page.contacts.form.subContactBusinessRequiredValidation'
            : 'page.contacts.form.subContactPersonRequiredValidation'
        )
      )

      return
    }

    if (contact1Uuid && contact2Uuid) {
      const contactName = getContactName(rvSelectedSubContact())

      try {
        const response = await linkContacts(contact1Uuid, contact2Uuid)

        if (response?.data?.linkContacts) {
          updateRvSelectedContactWithSubContact()
          displaySubContactSuccessToast(contactName)
          onModalClose()
          trackAnalyticsEvent(AnalyticsEventNames.CONTACT_LINKED_CONTACT, {
            Type: isSubContactTypeBusiness
              ? AnalyticsContactType.Business
              : AnalyticsContactType.Person,
          })
        } else {
          displaySubContactErrorToast(contactName)
        }
      } catch (error) {
        displaySubContactErrorToast(contactName)
      }
    }
  }

  const handleSetIsAddingNew = (_: boolean, inputValue: string | null = null) =>
    setNewContactDefaultName(inputValue)

  const isAddingNew = newContactDefaultName !== null

  const personContact = isSubContactTypeBusiness
    ? currentContactName || DEFAULT_PERSON_NAME
    : selectedItem?.label

  const businessContact = isSubContactTypeBusiness
    ? selectedItem?.label
    : currentContactName || DEFAULT_BUSINESS_NAME

  return (
    <>
      {!isAddingNew && (
        <ModalFormScrollable
          cancelLabel={String(translate('shared.cancel'))}
          confirmLabel={String(translate('shared.save'))}
          isLoading={isLinkingContacts}
          isOpen={isModalOpen}
          onCancel={onModalClose}
          onSave={handleExistingContactLinking}
          title={formTitle}
        >
          {isSubContactTypeBusiness ? (
            <BusinessComboBoxSelect
              isAddingNew={isAddingNew}
              isOptional={false}
              placeholder={page.contacts.form.addBusiness}
              selectedItem={selectedItem}
              setIsAddingNew={handleSetIsAddingNew}
              setSelectedItem={setSelectedItem}
              setValidationError={setValidationError}
              validationError={validationError}
            />
          ) : (
            <PersonComboBoxSelect
              isAddingNew={isAddingNew}
              isOptional={false}
              placeholder={page.contacts.form.addPerson}
              selectedItem={selectedItem}
              setIsAddingNew={handleSetIsAddingNew}
              setSelectedItem={setSelectedItem}
              setValidationError={setValidationError}
              validationError={validationError}
            />
          )}
          {selectedItem && (
            <Box mt="24px" data-testid="info-box-wrapper">
              <InfoBox>
                <InfoBox.Message>
                  {translate('page.contacts.infoMessage.linkedTo', {
                    contact1: personContact,
                    contact2: businessContact,
                  })}
                </InfoBox.Message>
              </InfoBox>
            </Box>
          )}
        </ModalFormScrollable>
      )}
      {isModalOpen && isAddingNew && (
        <AddSubContactForm
          isModalOpen
          closeModal={onModalClose}
          isSubContactTypeBusiness={isSubContactTypeBusiness}
          contactDefaultName={newContactDefaultName || ''}
        />
      )}
    </>
  )
}
