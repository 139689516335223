import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type HasActiveAccountOrTransactionsQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type HasActiveAccountOrTransactionsQueryResponse = {
  __typename?: 'Query'
  getDebitCardAccountsV2: {
    __typename?: 'DebitCardAccountConnectionV2'
    accounts: Array<{
      __typename?: 'DebitCardAccountV2'
      id: string
      status: Types.DebitCardAccountStatus
    } | null>
  }
  getDebitCardTransactionsV2: {
    __typename?: 'DebitCardTransactionConnectionV2'
    transactions: Array<{
      __typename?: 'DebitCardTransactionV2'
      id: string
    } | null>
  }
}

export const HasActiveAccountOrTransactionsQuery = gql`
  query HasActiveAccountOrTransactionsQuery {
    getDebitCardAccountsV2(limit: 50) {
      accounts {
        id
        status
      }
    }
    getDebitCardTransactionsV2(limit: 1) {
      transactions {
        id
      }
    }
  }
` as unknown as TypedDocumentNode<
  HasActiveAccountOrTransactionsQueryResponse,
  HasActiveAccountOrTransactionsQueryVariables
>

/**
 * __useHasActiveAccountOrTransactionsQuery__
 *
 * To run a query within a React component, call `useHasActiveAccountOrTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasActiveAccountOrTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasActiveAccountOrTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasActiveAccountOrTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HasActiveAccountOrTransactionsQueryResponse,
    HasActiveAccountOrTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    HasActiveAccountOrTransactionsQueryResponse,
    HasActiveAccountOrTransactionsQueryVariables
  >(HasActiveAccountOrTransactionsQuery, options)
}
export function useHasActiveAccountOrTransactionsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasActiveAccountOrTransactionsQueryResponse,
    HasActiveAccountOrTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    HasActiveAccountOrTransactionsQueryResponse,
    HasActiveAccountOrTransactionsQueryVariables
  >(HasActiveAccountOrTransactionsQuery, options)
}
export type HasActiveAccountOrTransactionsQueryHookResult = ReturnType<
  typeof useHasActiveAccountOrTransactionsQuery
>
export type HasActiveAccountOrTransactionsQueryLazyQueryHookResult = ReturnType<
  typeof useHasActiveAccountOrTransactionsQueryLazyQuery
>
export type HasActiveAccountOrTransactionsQueryQueryResult = Apollo.QueryResult<
  HasActiveAccountOrTransactionsQueryResponse,
  HasActiveAccountOrTransactionsQueryVariables
>
