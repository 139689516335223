import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom-v5-compat'
import { TabItemProps } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { page } from 'translations'

export const useTabs = () => {
  const { pathname } = useLocation()

  const tabs: TabItemProps<typeof Link>[] = useMemo(
    () => [
      {
        id: 'accounts-all-tab',
        children: page.accounts.tabs.allAccounts,
        value: ROOT.PORTAL.ACCOUNTS.path,
        to: ROOT.PORTAL.ACCOUNTS.path,
        component: Link,
      },
      {
        id: 'accounts-transactions-tab',
        children: page.accounts.tabs.allTransactions,
        value: ROOT.PORTAL.ACCOUNTS.TRANSACTIONS.path,
        to: ROOT.PORTAL.ACCOUNTS.TRANSACTIONS.path,
        component: Link,
      },
    ],

    []
  )

  return { tabs, currentTab: pathname }
}
