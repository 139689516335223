import { gql, type TypedDocumentNode } from '@apollo/client'

import { CardholderInformationCustomerFragmentDoc } from './components/CardholderInformation.generated'

export type SensitiveCardInformationDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  id: string
  customer: {
    __typename?: 'Customer'
    firstname: string | null
    lastname: string | null
  } | null
}

export const SensitiveCardInformationDebitCardV2FragmentDoc = gql`
  fragment SensitiveCardInformationDebitCardV2Fragment on DebitCardV2 {
    id
    customer {
      ...CardholderInformationCustomerFragment
    }
  }
  ${CardholderInformationCustomerFragmentDoc}
` as unknown as TypedDocumentNode<
  SensitiveCardInformationDebitCardV2Fragment,
  undefined
>
