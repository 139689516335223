import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetBpayBillerDetailQueryVariables = Types.Exact<{
  billerCode: Types.Scalars['String']['input']
}>

export type GetBpayBillerDetailQueryResponse = {
  __typename?: 'Query'
  getBpayBillerDetail: {
    __typename?: 'BpayBillerDetail'
    billerCode: string
    billerName: string
    icrnIndicator: boolean
    crnLengths: Array<number>
  }
}

export const GetBpayBillerDetailQuery = gql`
  query GetBpayBillerDetailQuery($billerCode: String!) {
    getBpayBillerDetail(billerCode: $billerCode) {
      billerCode
      billerName
      icrnIndicator
      crnLengths
    }
  }
` as unknown as TypedDocumentNode<
  GetBpayBillerDetailQueryResponse,
  GetBpayBillerDetailQueryVariables
>

/**
 * __useGetBpayBillerDetailQuery__
 *
 * To run a query within a React component, call `useGetBpayBillerDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBpayBillerDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBpayBillerDetailQuery({
 *   variables: {
 *      billerCode: // value for 'billerCode'
 *   },
 * });
 */
export function useGetBpayBillerDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBpayBillerDetailQueryResponse,
    GetBpayBillerDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetBpayBillerDetailQueryResponse,
    GetBpayBillerDetailQueryVariables
  >(GetBpayBillerDetailQuery, options)
}
export function useGetBpayBillerDetailQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBpayBillerDetailQueryResponse,
    GetBpayBillerDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBpayBillerDetailQueryResponse,
    GetBpayBillerDetailQueryVariables
  >(GetBpayBillerDetailQuery, options)
}
export type GetBpayBillerDetailQueryHookResult = ReturnType<
  typeof useGetBpayBillerDetailQuery
>
export type GetBpayBillerDetailQueryLazyQueryHookResult = ReturnType<
  typeof useGetBpayBillerDetailQueryLazyQuery
>
export type GetBpayBillerDetailQueryQueryResult = Apollo.QueryResult<
  GetBpayBillerDetailQueryResponse,
  GetBpayBillerDetailQueryVariables
>
