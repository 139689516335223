import { useCallback } from 'react'
import { Outlet, useNavigate } from 'react-router-dom-v5-compat'
import { Table } from '@npco/ui-table'
import { Flex } from '@npco/zeller-design-system'
import { Row } from '@tanstack/react-table'

import { ROOT } from 'const/routes'
import { ListLoader } from 'components/Lists'

import { CategoryDrawer } from '../CategoryDrawer/CategoryDrawer'
import { CategoriesErrorState } from './CategoriesErrorState/CategoriesErrorState'
import { CategoriesMobileFilters } from './CategoriesMobileFilters/CategoriesMobileFilters'
import { CategoriesNotFoundState } from './CategoriesNotFoundState/CategoriesNotFoundState'
import { CategoryTableData } from './CategoriesTable.types'
import { useCategoriesTable } from './hooks/useCategoriesTable'
import { useCategoriesTableInfiniteLoader } from './hooks/useCategoriesTableInfiniteLoader'

export const CategoriesTable = () => {
  const {
    isLoading,
    isDefaultFilters,
    hasError,
    hasNoResults,
    hasNoItemsAndCategories,
    hasHeaderBasedFilters,
    table,
  } = useCategoriesTable()

  const { isLoadingMore, observerContainer } =
    useCategoriesTableInfiniteLoader()

  const navigate = useNavigate()

  const handleRowClick = useCallback(
    (row: Row<CategoryTableData> | null) => {
      if (row?.original.id) {
        navigate(
          ROOT.PORTAL.ITEM_MANAGEMENT.CATEGORIES.CATEGORY(row.original.id)
            .relative
        )
      }
    },
    [navigate]
  )

  if (hasError) {
    return (
      <>
        <CategoriesErrorState />
        <Outlet />
      </>
    )
  }

  const showEmptyState = hasNoItemsAndCategories && isDefaultFilters

  if (showEmptyState) {
    navigate(ROOT.PORTAL.ITEM_MANAGEMENT.ITEMS.path)
    return null
  }

  const showNotFoundState = !isLoading && !isDefaultFilters && hasNoResults

  const hideHeader = showNotFoundState && !hasHeaderBasedFilters

  return (
    <Flex
      data-testid="categories-table-container"
      flexDirection="column"
      flexGrow={1}
    >
      <Table<CategoryTableData>
        onRowClick={handleRowClick}
        table={table}
        hideHeader={hideHeader}
      />
      <div ref={observerContainer} />
      {isLoadingMore && <ListLoader />}

      {showNotFoundState && <CategoriesNotFoundState />}

      {!isLoading && <CategoryDrawer />}
      {!isLoading && <CategoriesMobileFilters />}
      {!isLoading && <Outlet />}
    </Flex>
  )
}
