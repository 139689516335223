import { NetworkStatus, useQuery } from '@apollo/client'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'

import { GetDebitCardAccountsForCardCreation } from '../graphql/accounts'
import { GetDebitCardAccountsForCardCreationQueryResponse } from '../graphql/accounts.generated'

type Account = NonNullable<
  GetDebitCardAccountsForCardCreationQueryResponse['getDebitCardAccountsV2']['accounts'][0]
>

export const useGetDebitCardAccounts = () => {
  const { data, loading, error, refetch, networkStatus } =
    useQuery<GetDebitCardAccountsForCardCreationQueryResponse>(
      GetDebitCardAccountsForCardCreation,
      { notifyOnNetworkStatusChange: true }
    )

  const accounts =
    data?.getDebitCardAccountsV2?.accounts?.filter(
      (account): account is Account =>
        Boolean(account) &&
        account?.status === DebitCardAccountStatus.ACTIVE &&
        account.type === DebitCardAccountType.ZLR_DEBIT
    ) || []

  const isAccountsLoading = loading || networkStatus === NetworkStatus.refetch
  const hasError = Boolean(error)

  return { accounts, isAccountsLoading, hasError, refetch }
}
