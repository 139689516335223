import { showApiErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { DebitCardAccount } from 'types/accounts'
import { Account } from 'types/settings'
import { useDepositAccountSelection } from 'pages/Settings/DepositsSettings/hooks/DepositSettings.hooks'
import { page } from 'translations'

export const useSelectZellerAccountListItem = () => {
  const { isLoading, selectDepositAccount, isMfaRequiredOrExpired } =
    useDepositAccountSelection(Account.ZELLER)
  const changeToZellerAccount = async (account: DebitCardAccount) => {
    try {
      const response = await selectDepositAccount(account.id, account.name)

      if (isMfaRequiredOrExpired(response)) {
        return true
      }

      rvEntityDetails({
        ...rvEntityDetails(),
        debitCardAccountUuid: account.id,
        remitToCard: true,
      })

      showSuccessToast(
        page.settings.deposits.changingDepositAccountSuccessToast
      )
      return true
    } catch (err) {
      showApiErrorToast()
      return false
    }
  }
  return {
    changeToZellerAccount,
    isLoading,
  }
}
