import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { PORTAL_SHOP } from '@npco/mp-feature-onboarding-shop'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { rvEntityDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useZellerAuthenticationContext } from 'auth/ZellerAuthenticationContext'

import { ROOT } from 'const/routes'

interface UseRedirectAfterLoginProps {
  skip: boolean
}

export const useRedirectAfterLogin = ({ skip }: UseRedirectAfterLoginProps) => {
  const flags = useFeatureFlags()
  const { redirectTo, shouldRedirectAfterLogin, setShouldRedirectAfterLogin } =
    useZellerAuthenticationContext()

  const entityDetails = useReactiveVar(rvEntityDetails)
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const { isDataAvailable, userData } = useLoggedInUser()

  useEffect(() => {
    // NOTE: if we are still fetching dependant data return early
    if (skip || !isDataAvailable) {
      return
    }

    if (!userData?.phoneVerified) {
      navigate(ROOT.REGISTER_PHONE.path)
      setShouldRedirectAfterLogin(false)
      return
    }

    if (
      userData.productTourStatus?.showOnboardingShop &&
      flags.OnboardingShop &&
      !pathname.includes(PORTAL_SHOP.DEALS.relative)
    ) {
      navigate(PORTAL_SHOP.DEALS.path)
      setShouldRedirectAfterLogin(false)
      return
    }

    if (shouldRedirectAfterLogin && redirectTo) {
      navigate(redirectTo)
      setShouldRedirectAfterLogin(false)
      return
    }

    setShouldRedirectAfterLogin(false)
  }, [
    navigate,
    isDataAvailable,
    skip,
    redirectTo,
    setShouldRedirectAfterLogin,
    shouldRedirectAfterLogin,
    userData,
    entityDetails,
    flags.OnboardingShop,
    pathname,
  ])
}
