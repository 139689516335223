import { useCallback, useMemo } from 'react'
import { InvoiceDiscountConfig } from '@npco/mp-gql-types'
import { Flex } from '@npco/zeller-design-system'
import {
  INVOICE_DEFAULT_PERCENTAGE,
  INVOICE_DEFAULT_PRICE,
  INVOICE_ITEMS_DISCOUNT_PERCENTAGE_FIELD,
  INVOICE_ITEMS_DISCOUNT_PRICE_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import {
  InvoiceDiscountFormFields,
  InvoiceFormFields,
  InvoiceItem,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash-es'

import { translate } from 'utils/translations'

import {
  getItemTotals,
  useInvoiceItemsCalculations,
} from '../../../hooks/useInvoiceItemsCalculations'
import {
  InvoiceDiscountErrorText,
  InvoiceDiscountResetDiscount,
  InvoiceDiscountSubtotalText,
  InvoiceDiscountSubtotalValue,
  InvoiceDiscountTotalText,
  InvoiceDiscountTotalValue,
} from './InvoiceItemDiscountTotals.styled'

export const translations = {
  discount: translate('page.invoice.formSections.items.discount'),
  discountAbovePriceError: translate(
    'page.invoice.formSections.items.itemsDiscountAbovePriceError'
  ),
  resetDiscount: translate('page.invoice.formSections.items.resetDiscount'),
  subtotal: translate('page.invoice.formSections.items.itemAmount'),
  total: translate('page.invoice.formSections.items.itemTotal'),
}

interface InvoiceDiscountTotalsProps {
  invoiceValues: InvoiceFormFields
  itemValues: InvoiceItem
}

export const InvoiceItemDiscountTotals = ({
  invoiceValues,
  itemValues,
}: InvoiceDiscountTotalsProps) => {
  const {
    errors: discountErrors,
    setFieldValue,
    values: discountValues,
  } = useFormikContext<InvoiceDiscountFormFields>()

  const { discount, subTotal } = useInvoiceItemsCalculations({
    values: invoiceValues,
  })

  const {
    discountFormatted,
    isDiscountInvalid,
    subTotalFormatted,
    totalFormatted,
  } = getItemTotals({
    discount,
    isTaxInclusive: invoiceValues.itemsTaxInclusive,
    item: itemValues,
    overrides: { discount: discountValues },
    subTotal,
  })

  const isPercentageDiscount =
    discountValues.config === InvoiceDiscountConfig.PERCENTAGE

  const hasModifiedDiscountValue = useMemo(() => {
    if (isPercentageDiscount) {
      return discountValues.percentage !== INVOICE_DEFAULT_PERCENTAGE
    }

    return discountValues.price !== INVOICE_DEFAULT_PRICE
  }, [discountValues, isPercentageDiscount])

  const handleOnResetClick = useCallback(() => {
    if (isPercentageDiscount) {
      setFieldValue(
        INVOICE_ITEMS_DISCOUNT_PERCENTAGE_FIELD,
        INVOICE_DEFAULT_PERCENTAGE
      )

      return
    }

    setFieldValue(INVOICE_ITEMS_DISCOUNT_PRICE_FIELD, INVOICE_DEFAULT_PRICE)
  }, [isPercentageDiscount, setFieldValue])

  const showPercentageDiscount = isPercentageDiscount && isEmpty(discountErrors)

  return (
    <>
      <Flex
        alignItems="flex-end"
        flexDirection={['column', 'row']}
        gridGap={['8px', 0]}
      >
        <Flex flexGrow={1}>
          <InvoiceDiscountResetDiscount
            dataTestId="invoicing-invoice-item-discount-reset-discount"
            disabled={!hasModifiedDiscountValue}
            onClick={handleOnResetClick}
          >
            {translations.resetDiscount}
          </InvoiceDiscountResetDiscount>
        </Flex>
        <Flex alignItems="flex-end" alignSelf="flex-end" flexDirection="column">
          <Flex
            data-testid="invoicing-invoice-item-discount-totals-wrapper"
            gridGap="8px"
          >
            <Flex align-items="flex-end" flexDirection="column" gridGap="12px">
              <InvoiceDiscountSubtotalText>
                {translations.subtotal}
              </InvoiceDiscountSubtotalText>
              <InvoiceDiscountSubtotalText data-testid="invoicing-invoice-item-discount-sub-total-text">
                {translations.discount}{' '}
                {showPercentageDiscount && `(${discountValues.percentage}%)`}
              </InvoiceDiscountSubtotalText>
              <InvoiceDiscountTotalText $hasError={isDiscountInvalid}>
                {translations.total}
              </InvoiceDiscountTotalText>
            </Flex>
            <Flex flexDirection="column" gridGap="12px">
              <InvoiceDiscountSubtotalValue data-testid="invoicing-invoice-item-discount-total-value">
                {totalFormatted}
              </InvoiceDiscountSubtotalValue>
              <InvoiceDiscountSubtotalValue data-testid="invoicing-invoice-item-discount-value">
                {discountFormatted}
              </InvoiceDiscountSubtotalValue>
              <InvoiceDiscountTotalValue
                $hasError={isDiscountInvalid}
                dataTestId="invoicing-invoice-item-discount-sub-total-value"
              >
                {subTotalFormatted}
              </InvoiceDiscountTotalValue>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {isDiscountInvalid && (
        <InvoiceDiscountErrorText data-testid="invoicing-invoice-item-discount-above-price-error">
          {translations.discountAbovePriceError}
        </InvoiceDiscountErrorText>
      )}
    </>
  )
}
