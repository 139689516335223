import { useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  ButtonFill,
  Flex,
  MessageSize,
  MessageWithIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as Dominoes } from 'assets/svg/Something went wrong - dominoes.svg'
import { ROOT } from 'const/routes'

import { translations } from './ItemsNoResultsState.i18n'

export const ItemsNoResultsState = () => {
  const navigate = useNavigate()

  const t = useTranslations(translations)

  return (
    <Flex
      data-testid="items-no-results-state"
      flexDirection="column"
      flexGrow="1"
      justifyContent="center"
    >
      <MessageWithIcon
        align="center"
        description={
          <Box textAlign="center">
            <div>{t('description')}</div>
          </Box>
        }
        image={<Dominoes />}
        primaryButton={
          <ButtonFill
            onClick={() => navigate(ROOT.PORTAL.ITEM_MANAGEMENT.ITEMS.NEW.path)}
          >
            {t('addItem')}
          </ButtonFill>
        }
        size={MessageSize.LARGE}
        title={<Box textAlign="center">{t('title')}</Box>}
      />
    </Flex>
  )
}
