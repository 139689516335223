import { useMemo } from 'react'
import { createColumnHelper } from '@tanstack/react-table'

import { CategoriesHeaderRowItemsCell } from '../CategoriesHeaderRowItemsCell/CategoriesHeaderRowItemsCell'
import { CategoriesHeaderRowNameCell } from '../CategoriesHeaderRowNameCell/CategoriesHeaderRowNameCell'
import { CategoriesRowItemsCell } from '../CategoriesRowItemsCell/CategoriesRowItemsCell'
import { CategoriesRowNameCell } from '../CategoriesRowNameCell/CategoriesRowNameCell'
import { CategoryTableData } from '../CategoriesTable.types'

export const useCategoriesTableColumns = () => {
  const columnHelper = createColumnHelper<CategoryTableData>()

  return useMemo(() => {
    return [
      columnHelper.accessor('name', {
        id: 'name',
        cell: CategoriesRowNameCell,
        header: CategoriesHeaderRowNameCell,
      }),
      columnHelper.accessor('items', {
        id: 'items',
        cell: CategoriesRowItemsCell,
        header: CategoriesHeaderRowItemsCell,
      }),
    ]
  }, [columnHelper])
}
