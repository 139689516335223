import { gql } from '@apollo/client'

export const CompleteConncetionOAuthFlow = gql`
  mutation CompleteConncetionOAuthFlow(
    $url: String!
    $connectionType: ConnectionType!
  ) {
    completeConnectionOAuthFlow(connectionType: $connectionType, url: $url) {
      id
      status
      errorType
      connectionError
    }
  }
`
