import { useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { TransactionExportFormat } from '@npco/mp-gql-types'
import { renameAndDownloadFile } from '@npco/utils-file'
import { showErrorToast } from '@npco/zeller-design-system'
import { GetDebitCardTransactionSummary } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/debitCardTransactions'

import { translate } from 'utils/translations'
import {
  GetDebitCardTransactionSummary as GetDebitCardTransactionSummaryResponse,
  GetDebitCardTransactionSummaryVariables,
} from 'types/gql-types/GetDebitCardTransactionSummary'

interface Props {
  debitCardTransactionUuid: string
  onSuccess?: () => void
}

export const useDownloadSummary = ({
  debitCardTransactionUuid,
  onSuccess,
}: Props) => {
  const timeZone = useMemo(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone,
    []
  )

  const [isDownloadingFile, setIsDownloadingFile] = useState(false)

  const [downloadSummary, { loading: isLoadingSummary }] = useLazyQuery<
    GetDebitCardTransactionSummaryResponse,
    GetDebitCardTransactionSummaryVariables
  >(GetDebitCardTransactionSummary, {
    variables: {
      transactionUuid: debitCardTransactionUuid,
      timeZone,
    },
    fetchPolicy: 'network-only',
    onCompleted: async (result) => {
      onSuccess?.()

      setIsDownloadingFile(true)

      await renameAndDownloadFile({
        link: result.getDebitCardTransactionSummary.downloadLink,
        format: TransactionExportFormat.PDF,
        filename: translate(
          'page.transactionDetails.downloadStatement.downloadName',
          { id: debitCardTransactionUuid }
        ),
      })

      setIsDownloadingFile(false)
    },
    onError: () => {
      showErrorToast(
        translate('page.transactionDetails.downloadStatement.errorMessage')
      )
    },
  })

  return {
    downloadSummary,
    isLoadingSummary: isLoadingSummary || isDownloadingFile,
  }
}
