import { useMutation } from '@apollo/client'
import { TagContact } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/contacts'

import {
  TagContact as TagContactResponse,
  TagContactVariables,
} from 'types/gql-types/TagContact'

export const useTagContact = () => {
  const [tagContact, { loading: isSavingContactTags }] = useMutation<
    TagContactResponse,
    TagContactVariables
  >(TagContact)

  return {
    tagContact: (contactId: string, tagNames: string[]) =>
      tagContact({
        variables: {
          contactUuid: contactId,
          tagNames,
        },
      }),
    isSavingContactTags,
  }
}
