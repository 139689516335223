import { useMemo } from 'react'
import { InvoiceDiscountConfig } from '@npco/mp-gql-types'
import { Flex, useModalState } from '@npco/zeller-design-system'
import {
  INVOICE_DEFAULT_PERCENTAGE,
  INVOICE_DEFAULT_PRICE,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceFormFieldsWithTotalErrors } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { useInvoiceItemsCalculations } from '../hooks/useInvoiceItemsCalculations'
import { InvoiceDiscountModal } from './InvoiceDiscountModal/InvoiceDiscountModal'
import {
  InvoiceAmountErrorText,
  InvoiceSubtotalButton,
  InvoiceSubtotalText,
  InvoiceSubtotalValue,
  InvoiceTotalsWrapper,
  InvoiceTotalText,
  InvoiceTotalValue,
} from './InvoiceItemsTotals.styled'
import { InvoiceTaxModal } from './InvoiceTaxModal/InvoiceTaxModal'

export const translations = {
  discount: translate('page.invoice.formSections.items.discount'),
  discountAriaLabel: translate(
    'page.invoice.formSections.items.discountAriaLabel'
  ),
  subtotal: translate('page.invoice.formSections.items.subtotal'),
  taxAriaLabel: translate('page.invoice.formSections.items.taxAriaLabel'),
  taxExcluded: translate('page.invoice.formSections.items.taxExcludedShort'),
  taxIncluded: translate('page.invoice.formSections.items.taxIncludedShort'),
  total: translate('page.invoice.formSections.items.total'),
}

export const InvoiceItemsTotals = () => {
  const {
    closeModal: closeDiscountModal,
    isModalOpen: isDiscountModalOpen,
    openModal: openDiscountModal,
  } = useModalState()

  const {
    closeModal: closeTaxModal,
    isModalOpen: isTaxModalOpen,
    openModal: openTaxModal,
  } = useModalState()

  const { errors, touched, values } =
    useFormikContext<InvoiceFormFieldsWithTotalErrors>()

  const {
    discountFormatted,
    subTotalFormatted,
    taxesFormatted,
    totalFormatted,
  } = useInvoiceItemsCalculations({ values })

  const isDiscountsEnabled = values.discountsEnabled
  const isItemsTaxInclusive = values.itemsTaxInclusive

  const isAnyLineItemTouched = useMemo(
    () =>
      Boolean(
        touched?.items?.some(
          (item) => item?.description || item?.name || item?.price
        )
      ),
    [touched.items]
  )

  const isInvoiceDiscountApplied =
    values.discount.config === InvoiceDiscountConfig.PERCENTAGE
      ? values.discount.percentage !== INVOICE_DEFAULT_PERCENTAGE
      : values.discount.price !== INVOICE_DEFAULT_PRICE

  // NOTE: itemsExceedsMaxAmount and isItemsExceedingMinAmount is an adhoc
  // validation error reported on the errors schema.
  const showItemsExceedingMaxAmount = Boolean(
    (isAnyLineItemTouched || isInvoiceDiscountApplied) &&
      errors.itemsExceedsMaxAmount
  )

  const showItemsExceedingMinAmount = Boolean(
    (isAnyLineItemTouched || isInvoiceDiscountApplied) &&
      errors.itemsExceedsMinAmount
  )

  const isPercentageDiscount =
    values.discount.config === InvoiceDiscountConfig.PERCENTAGE

  const showPercentageDiscount =
    isPercentageDiscount &&
    values.discount.percentage !== INVOICE_DEFAULT_PERCENTAGE

  return (
    <Flex alignItems="flex-end" flexDirection="column" paddingRight="24px">
      <InvoiceTotalsWrapper data-testid="invoicing-invoice-totals-wrapper">
        <Flex alignItems="flex-end" flexDirection="column" gridGap="8px">
          <InvoiceSubtotalText>{translations.subtotal}</InvoiceSubtotalText>
          {isDiscountsEnabled && (
            <InvoiceSubtotalButton
              aria-label={translations.discountAriaLabel}
              data-testid="invoicing-invoice-discount"
              onClick={openDiscountModal}
            >
              <InvoiceSubtotalText>
                {translations.discount}{' '}
                {showPercentageDiscount && `(${values.discount.percentage}%)`}
              </InvoiceSubtotalText>
            </InvoiceSubtotalButton>
          )}
          <InvoiceSubtotalButton
            aria-label={translations.taxAriaLabel}
            data-testid="invoicing-invoice-tax"
            onClick={openTaxModal}
          >
            <InvoiceSubtotalText>
              {isItemsTaxInclusive
                ? translations.taxIncluded
                : translations.taxExcluded}
            </InvoiceSubtotalText>
          </InvoiceSubtotalButton>
          <InvoiceTotalText
            $hasError={
              showItemsExceedingMaxAmount || showItemsExceedingMinAmount
            }
          >
            {translations.total}
          </InvoiceTotalText>
        </Flex>
        <Flex flexDirection="column" gridGap="8px">
          <InvoiceSubtotalValue data-testid="invoicing-invoice-sub-total-value">
            {subTotalFormatted}
          </InvoiceSubtotalValue>
          {isDiscountsEnabled && (
            <InvoiceSubtotalValue data-testid="invoicing-invoice-discount-value">
              {discountFormatted}
            </InvoiceSubtotalValue>
          )}
          <InvoiceSubtotalValue data-testid="invoicing-invoice-tax-value">
            {taxesFormatted}
          </InvoiceSubtotalValue>
          <InvoiceTotalValue
            $hasError={
              showItemsExceedingMaxAmount || showItemsExceedingMinAmount
            }
            dataTestId="invoicing-invoice-total-value"
          >
            {totalFormatted}
          </InvoiceTotalValue>
        </Flex>
      </InvoiceTotalsWrapper>
      {showItemsExceedingMaxAmount && (
        <InvoiceAmountErrorText data-testid="invoicing-invoice-exceeded-max-amount-error">
          {errors.itemsExceedsMaxAmount}
        </InvoiceAmountErrorText>
      )}
      {showItemsExceedingMinAmount && (
        <InvoiceAmountErrorText data-testid="invoicing-invoice-exceeded-min-amount-error">
          {errors.itemsExceedsMinAmount}
        </InvoiceAmountErrorText>
      )}
      {isDiscountModalOpen && (
        <InvoiceDiscountModal closeModal={closeDiscountModal} />
      )}
      {isTaxModalOpen && <InvoiceTaxModal closeModal={closeTaxModal} />}
    </Flex>
  )
}
