import { SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as SimpleLoaderIcon } from 'assets/svg/simple-loader.svg'

import { StyledLoaderWrapper } from './LoaderSimple.styled'

interface Props {
  size?: string
  isVisible?: boolean
}

export const LoaderSimple = ({ size = '16', isVisible = true }: Props) => {
  return (
    <StyledLoaderWrapper isVisible={isVisible} data-testid="loader-simple">
      <SvgIcon width={size} height={size}>
        <SimpleLoaderIcon />
      </SvgIcon>
    </StyledLoaderWrapper>
  )
}
