import { gql, type TypedDocumentNode } from '@apollo/client'

export type CategoryDeleteModalItemsReassignFragment = {
  __typename?: 'ItemCategory'
  id: string
  name: string
}

export const CategoryDeleteModalItemsReassignFragmentDoc = gql`
  fragment CategoryDeleteModalItemsReassignFragment on ItemCategory {
    id
    name
  }
` as unknown as TypedDocumentNode<
  CategoryDeleteModalItemsReassignFragment,
  undefined
>
