import { useMutation } from '@apollo/client'
import { UpdateXeroSiteSettingsInput } from '@npco/mp-gql-types'

import {
  UpdateXeroSiteSettings as UpdateXeroSiteSettingsResponse,
  UpdateXeroSiteSettingsVariables,
} from 'types/gql-types/UpdateXeroSiteSettings'
import { UpdateXeroSiteSettings } from 'pages/Settings/Connections/XeroPaymentServices/graphql/UpdateXeroSiteSettings'

export const useUpdateXeroSiteSettings = () => {
  const [updateXeroSiteSettings, { loading: isUpdatingXeroSiteSettings }] =
    useMutation<
      UpdateXeroSiteSettingsResponse,
      UpdateXeroSiteSettingsVariables
    >(UpdateXeroSiteSettings)

  return {
    updateXeroSiteSettings: (input: UpdateXeroSiteSettingsInput) =>
      updateXeroSiteSettings({ variables: { input } }),
    isUpdatingXeroSiteSettings,
  }
}
