import { useHistory } from 'react-router'
import { rvSelectedSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ROUTE } from 'const/routes'
import { getSessionStorageItem } from 'services/sessionStorage/utils'

const SELECTED_SITE_KEY = `selected-site-key`

export const useRedirectToUsers = (isUsers = false) => {
  const history = useHistory()

  if (isUsers) {
    return () => history.push(ROUTE.PORTAL_SETTINGS_USERS)
  }

  return () => history.push(ROUTE.PORTAL_PAYMENTS_SITES_SITE_USERS)
}

export const getSelectedSite = (): string => {
  return getSessionStorageItem(SELECTED_SITE_KEY) || rvSelectedSite()
}

export function getDataValue<T, K extends keyof T>(
  data: T | null,
  key: K
): T[K] | string {
  const defaultValue = '-'
  if (data) {
    return data[key] || defaultValue
  }
  return defaultValue
}
