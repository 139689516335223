import { gql } from '@apollo/client'

export const GetPaymentInstruments = gql`
  query GetPaymentInstruments($filter: PaymentInstrumentFilterInput) {
    getPaymentInstruments(filter: $filter) {
      id
      bankAccountDetails {
        account
        bsb
        name
      }
      contactUuid
      contact {
        id
        contactType
        firstName
        lastName
        businessName
      }
      details {
        ... on BankAccountDetails {
          bsb
          account
          name
        }
        ... on NppDetails {
          payId
        }
        ... on BpayDetails {
          billerCode
          crn
        }
      }
      status
    }
  }
`

export const GetPaymentInstrumentsWithoutContact = gql`
  query GetPaymentInstrumentsWithoutContact(
    $filter: PaymentInstrumentFilterInput
  ) {
    getPaymentInstrumentsWithoutContact(filter: $filter) {
      id
      contactUuid
      contact {
        id
        contactType
        firstName
        lastName
        businessName
      }
      details {
        ... on BankAccountDetails {
          bsb
          account
          name
        }
        ... on NppDetails {
          payId
        }
        ... on BpayDetails {
          billerCode
          crn
        }
      }
      status
    }
  }
`
