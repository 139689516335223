import { useMemo } from 'react'
import { DebitCardAccountStatus } from '@npco/mp-gql-types'

import { useHasActiveSavingsAccountQuery as useHasActiveSavingsAccountQueryApollo } from './graphql/HasActiveSavingsAccountQuery.generated'

export const useHasActiveSavingsAccountQuery = () => {
  const { data, loading } = useHasActiveSavingsAccountQueryApollo()

  const hasActiveSavingsAccount = useMemo(
    () =>
      data?.getSavingsAccounts.some(
        (account) => account?.status === DebitCardAccountStatus.ACTIVE
      ) ?? true,
    [data]
  )

  return {
    hasActiveSavingsAccount,
    isLoadingHasActiveSavingsAccount: loading,
  }
}
