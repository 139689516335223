import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CategoryDrawerDetailsFragmentDoc } from '../CategoryDrawerDetails.generated'

const defaultOptions = {} as const
export type GetItemCategoryQueryVariables = Types.Exact<{
  categoryUuid: Types.Scalars['ID']['input']
}>

export type GetItemCategoryQueryResponse = {
  __typename?: 'Query'
  getItemCategory: {
    __typename: 'ItemCategory'
    name: string
    itemsNumber: number | null
    color: string
    id: string
    createdTime: number | null
    description: string | null
  }
}

export const GetItemCategory = gql`
  query GetItemCategory($categoryUuid: ID!) {
    getItemCategory(categoryUuid: $categoryUuid) {
      __typename
      name
      itemsNumber
      ...CategoryDrawerDetailsFragment
    }
  }
  ${CategoryDrawerDetailsFragmentDoc}
` as unknown as TypedDocumentNode<
  GetItemCategoryQueryResponse,
  GetItemCategoryQueryVariables
>

/**
 * __useGetItemCategoryQuery__
 *
 * To run a query within a React component, call `useGetItemCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemCategoryQuery({
 *   variables: {
 *      categoryUuid: // value for 'categoryUuid'
 *   },
 * });
 */
export function useGetItemCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetItemCategoryQueryResponse,
    GetItemCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetItemCategoryQueryResponse,
    GetItemCategoryQueryVariables
  >(GetItemCategory, options)
}
export function useGetItemCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetItemCategoryQueryResponse,
    GetItemCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetItemCategoryQueryResponse,
    GetItemCategoryQueryVariables
  >(GetItemCategory, options)
}
export type GetItemCategoryQueryHookResult = ReturnType<
  typeof useGetItemCategoryQuery
>
export type GetItemCategoryLazyQueryHookResult = ReturnType<
  typeof useGetItemCategoryLazyQuery
>
export type GetItemCategoryQueryResult = Apollo.QueryResult<
  GetItemCategoryQueryResponse,
  GetItemCategoryQueryVariables
>
