import { useGetItemSettingsQuery } from './graphql/getItemSettings.generated'

export const useGetItemSettings = () => {
  const {
    data,
    error,
    loading: isLoading,
  } = useGetItemSettingsQuery({
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  })

  const settings = data?.getItemSettings

  return {
    hasError: Boolean(error),
    // NOTE: If value already exist in cache, loading should be false
    // to reduce flicker
    isLoading: !settings ? isLoading : false,
    settings,
  }
}
