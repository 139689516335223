import { Navigate, Route, Routes } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'
import {
  SettingsSiteDevices,
  SettingsSiteGeneral,
  SettingsSiteGeneralEdit,
  SettingsSitePayments,
  SettingsSiteReceiptEdit,
} from 'pages'
import { NotFound } from 'pages/NotFound'
import { SiteDetailsFetcher } from 'pages/Settings/FetchSiteDetails/SiteDetailsFetcher'
import { SiteAppearance } from 'pages/Settings/SiteAppearance/SiteAppearance'
import { SiteUsersRoutes } from 'components/App/components/PortalRoutes/PaymentsRoutes/SitesRoutes/SiteRoutes/SiteUsersRoutes/SiteUsersRoutes'

export const SiteRoutes = () => {
  return (
    <SiteDetailsFetcher>
      <Routes>
        <Route
          index
          element={
            <Navigate
              to={ROOT.PORTAL.PAYMENTS.SITES.SITE.GENERAL.relative}
              replace
            />
          }
        />
        <Route path={`${ROOT.PORTAL.PAYMENTS.SITES.SITE.GENERAL.relative}/*`}>
          <Route index element={<SettingsSiteGeneral />} />
          <Route
            path={ROOT.PORTAL.PAYMENTS.SITES.SITE.GENERAL.EDIT.relative}
            element={<SettingsSiteGeneralEdit />}
          />
        </Route>
        <Route
          path={`${ROOT.PORTAL.PAYMENTS.SITES.SITE.USERS.relative}/*`}
          element={<SiteUsersRoutes />}
        />
        <Route
          path={ROOT.PORTAL.PAYMENTS.SITES.SITE.DEVICES.relative}
          element={<SettingsSiteDevices />}
        />
        <Route
          path={ROOT.PORTAL.PAYMENTS.SITES.SITE.PAYMENTS.relative}
          element={<SettingsSitePayments />}
        />
        <Route
          path={ROOT.PORTAL.PAYMENTS.SITES.SITE.RECEIPT.relative}
          element={<SettingsSiteReceiptEdit />}
        />
        <Route
          path={ROOT.PORTAL.PAYMENTS.SITES.SITE.APPEARANCE.relative}
          element={<SiteAppearance />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </SiteDetailsFetcher>
  )
}
