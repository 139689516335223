import React from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { translate } from 'utils/translations'
import { GetDevicePosSettings_getDeviceSettings_posSettings as PosSettingsType } from 'types/gql-types/GetDevicePosSettings'

import { useUpdatePosSettings } from '../PosSettings/PosSettings.hooks'
import { EditPosRegisterNameFormValues } from './EditPosRegisterNameModal.types'
import { EditPosRegisterNameModalForm } from './EditPosRegisterNameModalForm/EditPosRegisterNameModalForm'

interface EditPosRegisterNameModalProps {
  posDetails: PosSettingsType
  deviceId: string
  onCancel: () => void
  isOpen: boolean
}

export const EditPosRegisterNameModal = ({
  posDetails,
  deviceId,
  onCancel,
  isOpen,
}: EditPosRegisterNameModalProps) => {
  const { updateDevicePosSettings, isLoading } = useUpdatePosSettings()

  const handleOnSubmit = async (values: EditPosRegisterNameFormValues) => {
    try {
      const response = await updateDevicePosSettings({
        variables: {
          deviceSettings: {
            id: deviceId,
            posSettings: {
              posRegisterName: values.posRegisterName.trim(),
            },
          },
        },
        update: (cache, result) => {
          if (result.data?.updateDeviceSettings) {
            cache.modify({
              id: cache.identify({
                id: deviceId,
                __typename: 'DeviceSettings',
              }),
              fields: {
                posSettings() {
                  return {
                    posSoftwareName: posDetails.posSoftwareName,
                    posRegisterName: values.posRegisterName.trim(),
                    exitRequiresPin: posDetails.exitRequiresPin,
                    posReceipt: posDetails.posReceipt,
                    customIdleText: posDetails.customIdleText,
                    customIdleTextUsed: posDetails.customIdleTextUsed,
                    connectionMethod: posDetails.connectionMethod,
                  }
                },
              },
            })
          }
        },
      })

      if (!response.data?.updateDeviceSettings) {
        showErrorToast(translate('errorMessages.somethingWentWrong'))
        return
      }

      showSuccessToast(
        translate('component.editPosRegisterNameModal.updateSuccessToast')
      )

      onCancel()
    } catch (error) {
      showErrorToast()
      ErrorLogger.report(
        '[Payment] Failed to edit POS Register Name of a terminal',
        error
      )
    }
  }

  return (
    <Formik<EditPosRegisterNameFormValues>
      enableReinitialize
      initialValues={{
        posRegisterName: posDetails.posRegisterName ?? '',
      }}
      onSubmit={async (values) => {
        await handleOnSubmit(values)
      }}
    >
      <EditPosRegisterNameModalForm
        isOpen={isOpen}
        isLoading={isLoading}
        onCancel={onCancel}
      />
    </Formik>
  )
}
