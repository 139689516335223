import { gql } from '@apollo/client'

export const GetInvoiceItemSettings = gql`
  query GetInvoiceItemSettings($entityUuid: ID!) {
    getItemSettings {
      __typename
      itemsTaxInclusive
      itemsApplyTax
    }
    getCatalogSettings(entityUuid: $entityUuid) {
      __typename
      itemsTaxInclusive
      itemsApplyTax
    }
  }
`
