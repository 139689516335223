import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  aToZ: 'A to Z',
  categoryName: 'Category Name',
  filterAndSortCategories: 'Filter and Sort Categories',
  filterByKeyword: 'Filter by Keyword',
  filterByName: 'Filter by Name',
  filters: 'Filters',
  highToLow: 'High to Low',
  items: 'Items',
  lowToHigh: 'Low to High',
  name: 'Name',
  price: 'Price',
  search: 'Search',
  searchItemOrCategory: 'Search Item or Category',
  sortBy: 'Sort By',
  unknownCategory: 'Unknown Category',
  zToA: 'Z to A',
})
