import { useCallback } from 'react'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import {
  Address,
  AddressPage,
  CreateSelectDeliveryAddressState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { AUSTRALIA_COUNTRY_CODE } from 'const/common'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'

export const useSelectDeliveryAddressPageToggle = () => {
  const { state } = useAddCardState<CreateSelectDeliveryAddressState>()
  const { goToAddCardStage } = useGoToAddCardStage()

  const goToPredefinedAddressPage = useCallback(
    (address: Omit<Address, 'country'>) => {
      if (state === null) {
        return
      }

      goToAddCardStage({
        ...state,
        address: {
          ...address,
          country: AUSTRALIA_COUNTRY_CODE,
        },
        addressPage: AddressPage.PredefinedAddress,
      })
    },
    [goToAddCardStage, state]
  )

  const goToCustomAddressPage = useCallback(() => {
    if (state === null) {
      return
    }

    goToAddCardStage({
      ...state,
      addressPage: AddressPage.CustomAddress,
    })
  }, [goToAddCardStage, state])

  return {
    goToPredefinedAddressPage,
    goToCustomAddressPage,
    currentPage: state?.addressPage ?? AddressPage.PredefinedAddress,
  }
}
