import { useMutation } from '@apollo/client'

import {
  SendReminder as SendReminderResponse,
  SendReminderVariables,
} from 'types/gql-types/SendReminder'

import { SendReminder } from '../../graphql/sendReminder'

export const useSendReminder = () => {
  const [sendReminder, { loading: isSendingReminder }] = useMutation<
    SendReminderResponse,
    SendReminderVariables
  >(SendReminder)

  return {
    sendReminder: (referenceNumber: string, reminderUuid?: string) =>
      sendReminder({
        variables: {
          input: {
            referenceNumber,
            reminderUuid,
          },
        },
      }),
    isSendingReminder,
  }
}
