import { useCallback, useEffect, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Flex,
  INPUT_SIZE,
  InputAdaptiveBasic,
  theme,
} from '@npco/zeller-design-system'
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs'

import { translationsShared } from 'translations'

import {
  RangePickerValue,
  RangeSliderValue,
  RangeType,
  RangeValue,
} from '../Filters/NewFilters/RangePicker/RangePicker.types'
import {
  adjustRangeValuesForEdgeConditions,
  getNewRangeFromEvent,
} from '../Filters/NewFilters/RangePicker/RangePicker.utils'
import { Range } from '../Input/Range'
import * as styled from './AmountRange.styled'

const MAX = 99999
const MIN = 0
const STEP = 100

interface AmountRangeProps {
  debounceDuration?: number
  initialValues: [number, number]
  max?: number
  min?: number
  onChange: (range: RangePickerValue) => void
  step?: number
}

export const AmountRange = ({
  debounceDuration = 300,
  initialValues,
  max = MAX,
  min = MIN,
  onChange,
  step = STEP,
}: AmountRangeProps) => {
  const tShared = useTranslations(translationsShared)

  const [[inputMin, inputMax], setInputRange] =
    useState<[RangeValue, RangeValue]>(initialValues)

  const [sliderValues, setSliderRange] =
    useState<RangePickerValue>(initialValues)

  const [filterChange$] = useState(new Subject<RangePickerValue>())

  useEffect(() => {
    const filterSubscription = new Subscription()

    filterSubscription.add(
      filterChange$
        .pipe(debounceTime(debounceDuration), distinctUntilChanged())
        .subscribe((changedRange: RangePickerValue) => onChange(changedRange))
    )

    return () => {
      filterSubscription.unsubscribe()
    }
  }, [debounceDuration, filterChange$, onChange])

  useEffect(() => {
    // NOTE: if initial values change we want to update input and slider values
    // e.g reset back to default values
    setInputRange(initialValues)
    setSliderRange(initialValues)
  }, [initialValues])

  const handleOnAfterChange = useCallback(
    (value: number | readonly number[]) => {
      const changedRange = value as RangePickerValue
      setInputRange(changedRange)

      filterChange$.next(changedRange)
    },
    [filterChange$, setInputRange]
  )

  const handleOnInputChange = useCallback(
    (rangeType: RangeType) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.includes('-')) {
        return
      }

      if (rangeType === 'min' && event.target.value >= inputMax) {
        return
      }

      if (rangeType === 'max' && +event.target.value > max) {
        return
      }

      const newRangeFromInput = getNewRangeFromEvent(
        [inputMin, inputMax],
        event,
        rangeType
      )

      setInputRange(newRangeFromInput)

      const changedRange = adjustRangeValuesForEdgeConditions(
        newRangeFromInput,
        rangeType,
        [min, max]
      )

      setSliderRange(changedRange)

      filterChange$.next(changedRange)
    },
    [filterChange$, inputMax, inputMin, max, min]
  )

  const handleSliderOnChange = useCallback(
    (range: RangeSliderValue) => {
      setSliderRange(range as RangePickerValue)
      setInputRange(range as RangePickerValue)
    },
    [setInputRange, setSliderRange]
  )

  return (
    <div data-testid="amount-range">
      <Box marginBottom="16px">
        <Range
          margin="0px"
          max={max}
          min={min}
          onAfterChange={handleOnAfterChange}
          onChange={handleSliderOnChange}
          step={step}
          thumbBackgroundColor={theme.colors.BLUE_1000}
          value={sliderValues}
        />
      </Box>
      <Flex gridGap="16px">
        <Flex flexDirection="column" flexGrow={1}>
          <InputAdaptiveBasic
            aria-label={tShared('from')}
            data-testid="amount-range-min-input"
            label={tShared('from')}
            onChange={handleOnInputChange('min')}
            placeholder={inputMin.toString()}
            renderLeftControls={() => (
              <styled.CurrencySymbol>$</styled.CurrencySymbol>
            )}
            size={INPUT_SIZE.SMALL}
            value={inputMin.toString()}
          />
        </Flex>
        <Flex flexDirection="column" flexGrow={1}>
          <InputAdaptiveBasic
            aria-label={tShared('to')}
            data-testid="amount-range-max-input"
            label={tShared('to')}
            onChange={handleOnInputChange('max')}
            placeholder={inputMax.toString()}
            renderLeftControls={() => (
              <styled.CurrencySymbol>$</styled.CurrencySymbol>
            )}
            size={INPUT_SIZE.SMALL}
            value={inputMax.toString()}
          />
        </Flex>
      </Flex>
    </div>
  )
}
