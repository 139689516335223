import { useEffect } from 'react'
import { Outlet } from 'react-router-dom-v5-compat'
import { PageTemplate } from '@npco/zeller-design-system'

import { useRefreshAccounts } from 'hooks/banking'
import { useTransactionsLocalCache } from 'components/Filters/PillFilters/TransactionsFilters/useTransactionsLocalCache'

import { ContactPrimaryHeaderRow } from './ContactPrimaryHeaderRow/ContactPrimaryHeaderRow'
import { ContactSecondaryHeaderRow } from './ContactSecondaryHeaderRow/ContactSecondaryHeaderRow'
import { ContactTertiaryHeaderRow } from './ContactTertiaryHeaderRow/ContactTertiaryHeaderRow'

export const ContactLayout = () => {
  useRefreshAccounts()

  const { resetFilters } = useTransactionsLocalCache('contactTransactions')

  useEffect(() => {
    return () => resetFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageTemplate
      HeaderPrimaryRow={<ContactPrimaryHeaderRow />}
      HeaderSecondaryRow={<ContactSecondaryHeaderRow />}
      HeaderTertiaryRow={<ContactTertiaryHeaderRow />}
    >
      <Outlet />
    </PageTemplate>
  )
}
