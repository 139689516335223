import { gql } from '@apollo/client'

import { InvoiceCoreFields } from './invoiceCoreFieldsFragment'

export const CreateInvoice = gql`
  ${InvoiceCoreFields}
  mutation CreateInvoice($invoice: CreateInvoiceInput!) {
    createInvoice(input: $invoice) {
      ...InvoiceCoreFields
    }
  }
`
