import { TooltipRenderProps } from 'react-joyride'
import { useTranslations } from '@npco/utils-translations'
import { Flex } from '@npco/zeller-design-system'

import * as styled from 'components/Onboarding/OnboardingTooltip.styled'

import { STEPS_LAST_INDEX } from './hooks/useGetSteps'
import { translations } from './ItemOnboarding.i18n'

export const ItemOnboardingTooltip = ({
  index,
  step,
  closeProps,
  primaryProps,
  tooltipProps,
}: TooltipRenderProps) => {
  const t = useTranslations(translations)

  const isLastStep = index === STEPS_LAST_INDEX
  return (
    <styled.TooltipContainer
      {...tooltipProps}
      data-testid="items-onboarding-tooltip"
    >
      {step.title && <styled.TooltipTitle>{step.title}</styled.TooltipTitle>}
      <styled.TooltipBody>{step.content}</styled.TooltipBody>
      <Flex justifyContent="space-between">
        <>
          {!isLastStep ? (
            <>
              <styled.SecondaryTooltipButton
                {...closeProps}
                title={t('hideTips')}
              >
                {t('hideTips')}
              </styled.SecondaryTooltipButton>
              <styled.PrimaryTooltipButton {...primaryProps} title={t('next')}>
                {t('next')}
              </styled.PrimaryTooltipButton>
            </>
          ) : (
            <styled.PrimaryTooltipButton
              fullWidth
              {...primaryProps}
              title={t('finish')}
            >
              {t('finish')}
            </styled.PrimaryTooltipButton>
          )}
        </>
      </Flex>
    </styled.TooltipContainer>
  )
}
