import { useReactiveVar } from '@apollo/client'

import { DashboardFiltersButton } from 'layouts/DashboardLayout'
import { useTransactionsFilters } from 'components/Filters/PillFilters/TransactionsFilters/useTransactionsFilters'

import { useContactContext } from '../../../ContactContext/ContactContext'

export const ContactFiltersButtonDeps = {
  useContactContext,
  useReactiveVar,
  useTransactionsFilters,
}

export const ContactFiltersButton = () => {
  const { useContactContext, useTransactionsFilters } = ContactFiltersButtonDeps

  const {
    isPaymentsFilterButtonDisabled,
    isPaymentsFiltersVisible,
    togglePaymentsFiltersVisible,
  } = useContactContext()

  const { isDefaultFilters } = useTransactionsFilters({
    key: 'contactTransactions',
  })

  return (
    <DashboardFiltersButton
      disabled={isPaymentsFilterButtonDisabled}
      isActive={isPaymentsFiltersVisible}
      onClick={togglePaymentsFiltersVisible}
      showIndicator={!isDefaultFilters}
    />
  )
}
