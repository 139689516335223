import { useCallback } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { SaveTags } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/debitCardTransactions'

import {
  SaveTags as SaveTagsResponse,
  SaveTagsVariables,
} from 'types/gql-types/SaveTags'

interface Props {
  transactionUuid: string
  onSuccess?: (newTags: string[]) => void
  onError?: (error?: ApolloError) => void
}

export const useTransactionTags = ({
  transactionUuid,
  onSuccess,
  onError,
}: Props) => {
  const [saveTags, { loading: isSavingTags }] = useMutation<
    SaveTagsResponse,
    SaveTagsVariables
  >(SaveTags, {
    onError,
  })

  const handleSaveTags = useCallback(
    async (newTags: string[]) => {
      const result = await saveTags({
        variables: {
          debitCardTransactionUuid: transactionUuid,
          tags: newTags,
        },
      })

      if (!result.errors) {
        if (result.data?.updateDebitCardTransactionAnnotations) {
          onSuccess?.(newTags)
        } else {
          onError?.()
        }
      }

      return result
    },
    [saveTags, transactionUuid, onSuccess, onError]
  )

  return {
    saveTags: handleSaveTags,
    isSavingTags,
  }
}
