import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type ViewDetailsDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  maskedPan: string
  colour: Types.DebitCardColour
  status: Types.DebitCardStatus
  customer: { __typename?: 'Customer'; id: string } | null
}

export const ViewDetailsDebitCardV2FragmentDoc = gql`
  fragment ViewDetailsDebitCardV2Fragment on DebitCardV2 {
    maskedPan
    colour
    status
    customer {
      id
    }
  }
` as unknown as TypedDocumentNode<ViewDetailsDebitCardV2Fragment, undefined>
