import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { TabItemProps } from '@npco/zeller-design-system'
import { useTheme } from 'styled-components'

import { ROOT } from 'const/routes'
import { INVOICING_SETTINGS_CUSTOMISATION } from 'services/localStorage/keys'
import { page } from 'translations'

const TabValues = {
  GENERAL: ROOT.PORTAL.INVOICING.INVOICES.SETTINGS.GENERAL.relative,
  REMINDERS: ROOT.PORTAL.INVOICING.INVOICES.SETTINGS.REMINDERS.relative,
  RECEIPTS: ROOT.PORTAL.INVOICING.INVOICES.SETTINGS.RECEIPTS.relative,
  USERS: ROOT.PORTAL.INVOICING.INVOICES.SETTINGS.USERS.relative,
  CUSTOMISATION: ROOT.PORTAL.INVOICING.INVOICES.SETTINGS.CUSTOMISATION.relative,
}

export const useInvoicingSettingsTabs = () => {
  const { colors } = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isCustomisationTab = pathname.includes(TabValues.CUSTOMISATION)
  const hasVisitedCustomisationTab =
    localStorage.getItem(INVOICING_SETTINGS_CUSTOMISATION) === 'true'

  const indicator = useMemo(
    () => ({
      color: colors.BRIGHT_GREEN,
      isActive: !hasVisitedCustomisationTab && !isCustomisationTab,
    }),
    [hasVisitedCustomisationTab, isCustomisationTab, colors]
  )

  const tabs: TabItemProps[] = useMemo(
    () => [
      {
        id: 'invoicing-settings-tabs-general',
        children: page.invoicesSettings.tabs.general,
        value: TabValues.GENERAL,
        onClick: () => navigate(TabValues.GENERAL),
      },
      {
        id: 'invoicing-settings-tabs-reminders',
        children: page.invoicesSettings.tabs.reminders,
        value: TabValues.REMINDERS,
        onClick: () => navigate(TabValues.REMINDERS),
      },
      {
        id: 'invoicing-settings-tabs-receipts',
        children: page.invoicesSettings.tabs.receipts,
        value: TabValues.RECEIPTS,
        onClick: () => navigate(TabValues.RECEIPTS),
      },
      {
        id: 'invoicing-settings-tabs-users',
        children: page.invoicesSettings.tabs.users,
        value: TabValues.USERS,
        onClick: () => navigate(TabValues.USERS),
      },
      {
        id: 'invoicing-settings-tabs-customisation',
        indicator,
        children: page.invoicesSettings.tabs.customisation,
        value: TabValues.CUSTOMISATION,
        onClick: () => navigate(TabValues.CUSTOMISATION),
      },
    ],
    [navigate, indicator]
  )

  return {
    tabs,
    currentTab: (value: string) => pathname.includes(value),
    isCustomisationTab,
  }
}
