import { Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import { Flex, Invoicing } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { BreadcrumbsBase } from 'components/Breadcrumbs/BreadcrumbsBase'

import { useInvoicesBreadcrumbs } from '../../components/Invoices/hooks/useInvoicesBreadcrumbs/useInvoicesBreadcrumbs'
import { useGetZellerInvoiceSettings } from '../../components/Settings/hooks/useGetZellerInvoiceSettings'
import { useUpdateZellerInvoiceSettings } from '../../components/Settings/hooks/useUpdateZellerInvoiceSettings'
import { SettingsCustomisation } from '../../components/Settings/SettingsCustomisation/SettingsCustomisation'
import { SettingsGeneral } from '../../components/Settings/SettingsGeneral/SettingsGeneral'
import { SettingsReceipts } from '../../components/Settings/SettingsReceipts/SettingsReceipts'
import { SettingsReminders } from '../../components/Settings/SettingsReminders/SettingsReminders'
import { SettingsUsers } from '../../components/Settings/SettingsUsers/SettingsUsers'
import { useInvoicingSettingsTabs } from './hooks/useInvoicingSettingsTabs'
import { InvoiceEditSettingsModal } from './InvoiceEditSettingsModal/InvoiceEditSettingsModal'

const {
  CUSTOMISATION: { relative: customisationPath },
  GENERAL: { relative: generalPath },
  REMINDERS: { relative: remindersPath },
  RECEIPTS: { relative: receiptsPath },
  USERS: { relative: usersPath, USER },
} = ROOT.PORTAL.INVOICING.INVOICES.SETTINGS
const { relative: userPath } = USER()

const deps = {
  SettingsGeneral,
  SettingsReminders,
  SettingsReceipts,
  SettingsCustomisation,
}

export { deps as InvoicingSettingsRoutesDeps }

export const InvoicingSettingsRoutes = () => {
  const { tabs, isCustomisationTab, currentTab } = useInvoicingSettingsTabs()
  const { isLoading } = useGetZellerInvoiceSettings()
  const { isUpdatingZellerInvoiceSettings } = useUpdateZellerInvoiceSettings()
  const breadcrumbParts = useInvoicesBreadcrumbs()

  const isHalfWidth =
    !isCustomisationTab && !isLoading && !isUpdatingZellerInvoiceSettings

  return (
    <Invoicing.InvoicingSettingsLayout
      Title={
        <Flex alignItems="center" height="48px">
          <BreadcrumbsBase breadcrumbParts={breadcrumbParts} />
        </Flex>
      }
      isHalfWidth={isHalfWidth}
      tabs={tabs}
      currentTab={currentTab}
    >
      <InvoiceEditSettingsModal />
      <Routes>
        <Route path={generalPath} element={<deps.SettingsGeneral />} />
        <Route path={remindersPath} element={<deps.SettingsReminders />} />
        <Route path={receiptsPath} element={<deps.SettingsReceipts />} />
        <Route path={usersPath}>
          <Route path={userPath} element={<SettingsUsers />} />
          <Route index element={<SettingsUsers />} />
        </Route>
        <Route
          path={customisationPath}
          element={<deps.SettingsCustomisation />}
        />
        <Route index element={<Navigate to={generalPath} />} />
      </Routes>
    </Invoicing.InvoicingSettingsLayout>
  )
}
