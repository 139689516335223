import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { gql } from '@apollo/client'
import { ContactType } from '@npco/mp-gql-types'
import { ButtonLink } from '@npco/zeller-design-system'
import { ContactCoreFieldsDoc } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { rvSelectedContact } from 'features/Contacts/rv-deprecated/contacts'

import { ROUTE } from 'const/routes'
import { translate } from 'utils/translations'

import { GoToContactsContactFragment } from './GoToContacts.generated'
import {
  StyledGoToContactsDescription,
  StyledGoToContactsTitle,
} from './GoToContacts.styled'

interface Props {
  contact: GoToContactsContactFragment
  onNavigate?: () => void
}

export const GoToContacts = ({ contact, onNavigate }: Props) => {
  const history = useHistory()

  const navigateToContacts = useCallback(() => {
    rvSelectedContact(contact)

    const contactPath =
      contact.contactType === ContactType.BUSINESS
        ? ROUTE.PORTAL_CONTACTS_BUSINESSES_GENERAL
        : ROUTE.PORTAL_CONTACTS_PEOPLE_GENERAL

    history.push(contactPath)
    onNavigate?.()
  }, [history, contact, onNavigate])

  return (
    <div>
      <StyledGoToContactsTitle>
        {translate('page.transactionContact.goToContacts.title')}
      </StyledGoToContactsTitle>
      <StyledGoToContactsDescription>
        {translate('page.transactionContact.goToContacts.description')}
      </StyledGoToContactsDescription>
      <ButtonLink onClick={navigateToContacts} dataTestId="go-to-contacts">
        {translate('page.transactionContact.goToContacts.buttonLabel')}
      </ButtonLink>
    </div>
  )
}

GoToContacts.fragments = {
  Contact: gql`
    fragment GoToContactsContactFragment on Contact {
      ...ContactCoreFields
    }

    ${ContactCoreFieldsDoc}
  `,
}
