import {
  ButtonAdditionalAction,
  COLOR,
  ModalBasic,
  SvgIcon,
  useModalState,
} from '@npco/zeller-design-system'

import { ReactComponent as Close } from 'assets/svg/close.svg'
import { translate } from 'utils/translations'

export const translations = {
  cancel: translate('shared.cancel'),
  confirm: translate('shared.confirm'),
  description: (price: string) =>
    translate(
      'page.invoice.formSections.items.itemsClearPriceModalDescription',
      { price }
    ),
  title: translate(
    'page.invoice.formSections.items.itemsClearPriceModalHeaderTitle'
  ),
}

interface InvoiceItemPriceClearProps {
  onClearPrice: () => void
  unitPrice: string
}

export const InvoiceItemPriceClear = ({
  onClearPrice: handleClearPrice,
  unitPrice,
}: InvoiceItemPriceClearProps) => {
  const { closeModal, isModalOpen, openModal } = useModalState()

  return (
    <>
      <ButtonAdditionalAction
        data-testid="input-clear-button"
        onClick={openModal}
        padding={0}
      >
        <SvgIcon width="10" height="10" color={COLOR.BLUE_1000}>
          <Close />
        </SvgIcon>
      </ButtonAdditionalAction>

      {isModalOpen && (
        <ModalBasic
          isOpen
          onCancel={closeModal}
          testId="invoicing-invoice-line-item-price-clear-modal"
          title={translations.title}
        >
          <ModalBasic.Body>
            {translations.description(unitPrice)}
          </ModalBasic.Body>
          <ModalBasic.Footer>
            <ModalBasic.SecondaryButton onClick={closeModal}>
              {translations.cancel}
            </ModalBasic.SecondaryButton>
            <ModalBasic.PrimaryButton onClick={handleClearPrice}>
              {translations.confirm}
            </ModalBasic.PrimaryButton>
          </ModalBasic.Footer>
        </ModalBasic>
      )}
    </>
  )
}
