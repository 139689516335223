import { useCallback } from 'react'
import {
  useCreateSavingsAccountState,
  useGoToCreateSavingsAccount,
} from '@npco/mp-utils-create-savings-account-routing'
import { useTranslations } from '@npco/utils-translations'
import { Box, DecisionModal } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { createSavingsAccountTranslations } from './CreateSavingsAccount.i18n'
import { CreateSavingsAccountPrompt } from './CreateSavingsAccountPrompt/CreateSavingsAccountPrompt'
import { TaxFileNumberField } from './TaxFileNumberField/TaxFileNumberField'
import { TermsAndConditions } from './TermsAndConditions/TermsAndConditions'

type CreateSavingsAccountProps = {
  isActivatingAccount: boolean
}

export const CreateSavingsAccount = ({
  isActivatingAccount,
}: CreateSavingsAccountProps) => {
  const { submitForm } = useFormikContext()
  const { state } = useCreateSavingsAccountState()

  const { goToCreateSavingsAccount } = useGoToCreateSavingsAccount()

  const closeModal = useCallback(() => {
    goToCreateSavingsAccount(null)
  }, [goToCreateSavingsAccount])

  const t = useTranslations(createSavingsAccountTranslations)

  const modalClasses = state?.hasBlurAndFade
    ? { className: 'animated-content', overlayClassName: 'animated-overlay' }
    : {}

  return (
    <DecisionModal
      title={t('title')}
      secondaryButtonLabel={t('cancel')}
      onClickSecondary={closeModal}
      onCancel={closeModal}
      primaryButtonLabel={t('agreeAndActivate')}
      onClickPrimary={submitForm}
      isOpen
      isLoadingPrimary={isActivatingAccount}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...modalClasses}
    >
      <Box pb="24px">
        <CreateSavingsAccountPrompt />
      </Box>
      <TaxFileNumberField />
      <Box pt="24px">
        <TermsAndConditions />
      </Box>
    </DecisionModal>
  )
}
