import { useEffect } from 'react'

import { useInfiniteLoader } from 'hooks/useInfiniteLoader/useInfiniteLoader'

import { DEFAULT_GET_LIMIT } from '../../../../ItemManagement.utils'
import { useGetDrawerItems } from './useGetDrawerItems'

export const useGetDrawerItemsInfiniteLoader = (categoryId: string) => {
  const { getItems, isLoading, items, loadMore, nextToken } = useGetDrawerItems(
    {
      variables: {
        limit: DEFAULT_GET_LIMIT,
        filter: { categoryUuidFilter: { categoryUuids: [categoryId] } },
        nextToken: undefined,
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    }
  )

  useEffect(() => {
    getItems()
  }, [getItems])

  const { isLoadingMore, observerContainer } = useInfiniteLoader({
    isLoading,
    onLoadMore: async () => {
      await loadMore?.({
        variables: { limit: DEFAULT_GET_LIMIT, nextToken },
      })
    },
    shouldLoadMore: !!nextToken,
  })

  return { isLoading, isLoadingMore, items, observerContainer }
}
