import styled, { css } from 'styled-components'

import ChevronLeft from 'assets/svg/chevron-left.svg'
import ChevronRight from 'assets/svg/chevron-right.svg'
import { BREAKPOINT } from 'styles/breakpoints'

const afterBgStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  content: '';
  height: 2rem;
  width: 2rem;
  z-index: -1;
  background-color: ${({ theme }) => theme.colors.BLUE_1000};
  border-radius: 4px;
  transform: translateY(-50%) translateX(-50%);
`

const afterTodayBgStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  content: '';
  height: 2rem;
  width: 2rem;
  z-index: -1;
  background-color: ${({ theme }) => theme.colors.GREY_150};
  border-radius: 4px;
  transform: translateY(-50%) translateX(-50%);
`

export const DatePickerWrapper = styled.div<{ isDateTime: boolean }>`
  background-color: ${({ theme }) => theme.colors.WHITE};
  text-align: center;

  .DayPicker {
    display: inline-block;
    width: 100%;
    font-size: 1rem;
  }

  .DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    padding-bottom: ${({ isDateTime }) => (isDateTime ? 0 : '1.5rem')};
    user-select: none;
    outline: none;

    @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
      flex-grow: 2;
    }
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 16px;
    gap: 8px;

    @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
      justify-content: center;
    }
  }

  .DayPicker-Month {
    display: table;
    border-collapse: separate;
    border-spacing: 0 8px;
    width: 272px;
    user-select: none;
    align-self: flex-start;
  }

  .DayPicker-NavButton {
    display: inline-block;
    position: absolute;
    top: 0;
    margin-top: 1px;
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    outline: none;
  }

  .DayPicker-NavButton:hover {
    opacity: 0.8;
  }

  .DayPicker-NavButton--prev {
    left: 16px;
    background-image: url(${ChevronLeft});
  }

  .DayPicker-NavButton--next {
    right: 16px;
    background-image: url(${ChevronRight});
  }

  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }

  .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 24px;
    text-align: center;
    color: ${({ theme }) => theme.colors.BLACK};
  }

  .DayPicker-Caption > div {
    font-weight: 500;
    font-size: 1.15em;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    color: ${({ theme }) => theme.colors.GREY_550};
    text-align: center;
    font-size: 0.875em;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day {
    display: table-cell;
    padding: 6px 2px;
    vertical-align: middle;
    color: ${({ theme }) => theme.colors.BLACK};
    text-align: center;
    cursor: pointer;
    outline: none;

    transition: background-color 0.2s ease-in-out;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Day--today:not(.DayPicker-Day--selected):not(
      .DayPicker-Day--outside
    ) {
    position: relative;
    z-index: 2;

    &::after {
      ${afterTodayBgStyle}
    }
  }

  .DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected.DayPicker-Day--today:not(
      .DayPicker-Day--end
    ) {
    background-color: ${({ theme }) => theme.colors.BLUE_80};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .DayPicker-Day--today.DayPicker-Day--selected:not(
      .DayPicker-Day--disabled
    ):not(.DayPicker-Day--outside) {
    &::after {
      ${afterBgStyle}
    }
  }

  .DayPicker-Day--disabled {
    color: ${({ theme }) => theme.colors.GREY_250};
    cursor: default;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ):not(.DayPicker-Day--endNotSelected) {
    position: relative;
    background-color: ${({ theme }) => theme.colors.BLUE_80};
  }

  .DayPicker-Day--start:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ).DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    position: relative;
    background: none;

    &:not(.DayPicker-Day--endNotSelected):not(
        .DayPicker-Day--start.DayPicker-Day--end
      )::before {
      display: block;
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.BLUE_80};
      z-index: -1;
    }

    &::after {
      ${afterBgStyle}
    }
  }

  .DayPicker-Day--end:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ).DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    position: relative;
    background: none;

    &:not(.DayPicker-Day--start.DayPicker-Day--end)::before {
      display: block;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.BLUE_80};
      z-index: -1;
    }

    &::after {
      ${afterBgStyle}
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ):hover {
    color: ${({ theme }) => theme.colors.WHITE};
    z-index: 2;

    &::after {
      ${afterBgStyle}
    }
  }

  .DayPicker-Day--start:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ),
  .DayPicker-Day--end:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    color: ${({ theme }) => theme.colors.WHITE};
    z-index: 2;
  }

  .DayPicker-Day--start:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .DayPicker-Day--end:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

export const DatePickerBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: 1px solid ${({ theme }) => `${theme.colors.GREY_250}`};
  border-radius: 4px;
  overflow: hidden;
  width: max-content;
  max-width: calc(100dvw - 32px);
  padding-top: 16px;
  box-shadow: 2px 2px 6px 0px #0000000a, -2px 2px 6px 0px #0000000a;
`

export const PeriodRow = styled.div`
  ${({ theme }) => theme.typography['body-sm']}
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.GREY_250};
  align-items: center;
  justify-content: space-between;
`
