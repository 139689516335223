import { useCallback } from 'react'
import { isNil } from 'lodash-es'
import { reject } from 'ramda'

import { AnalyticsEventNames } from 'services/Analytics/events'
import {
  ItemsEditCategoryProperties,
  ItemsEditItemProperties,
  ItemsExportProperties,
  ItemsImportCompleteProperties,
} from 'services/Analytics/events/items'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import {
  ItemFormFields,
  ItemTaxApplicableItems,
} from 'components/ItemBaseModalForm'

import { CategoryFormFields } from '../components/CategoryCreateModal/CategoryCreateModal.types'
import { ItemsImport } from '../components/ItemsImportModal/ItemsImportModal.types'

export const getOptionalFieldUpdatedValue = <T>(
  initialValue?: T,
  value?: T
) => {
  if (value === initialValue || (!value && !initialValue)) {
    return undefined
  }

  if (!initialValue && value) {
    return 'Added'
  }

  if (initialValue && !value) {
    return 'Removed'
  }

  return 'Updated'
}

const getFileFormat = (
  fileName: string
): ItemsImportCompleteProperties['ImportFormat'] => {
  if (fileName.includes('.csv')) {
    return 'CSV'
  }

  if (fileName.includes('.xlsx')) {
    return 'XLSX'
  }

  return 'XLS'
}

export const useItemsAnalytics = () => {
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  const trackAddItem = useCallback(
    (fields: ItemFormFields) => {
      trackAnalyticsEvent(AnalyticsEventNames.ITEMS_ADD_ITEM, {
        Category: fields.categories.length,
        DefaultGST:
          fields.taxApplicable === ItemTaxApplicableItems.TaxApplicable,
        Description: Boolean(fields.description),
        SKU: Boolean(fields.sku),
      })
    },
    [trackAnalyticsEvent]
  )

  const trackAddCategory = useCallback(
    (fields: CategoryFormFields) => {
      trackAnalyticsEvent(AnalyticsEventNames.ITEMS_ADD_CATEGORY, {
        Description: Boolean(fields.description),
      })
    },
    [trackAnalyticsEvent]
  )

  const trackDeleteCategory = useCallback(() => {
    trackAnalyticsEvent(AnalyticsEventNames.ITEMS_DELETE_CATEGORY)
  }, [trackAnalyticsEvent])

  const trackDeleteItem = useCallback(() => {
    trackAnalyticsEvent(AnalyticsEventNames.ITEMS_DELETE_ITEM)
  }, [trackAnalyticsEvent])

  const trackDeleteAll = useCallback(() => {
    trackAnalyticsEvent(AnalyticsEventNames.ITEMS_DELETE_ALL)
  }, [trackAnalyticsEvent])

  const trackImportStarted = useCallback(() => {
    trackAnalyticsEvent(AnalyticsEventNames.ITEMS_IMPORT_STARTED)
  }, [trackAnalyticsEvent])

  const trackImportCompleted = useCallback(
    (items: ItemsImport | null) => {
      if (!items) {
        return
      }

      const itemIdIndex = items.headers.findIndex((header) =>
        header?.includes('Item ID')
      )
      const isUpdate = itemIdIndex !== -1
      trackAnalyticsEvent(AnalyticsEventNames.ITEMS_IMPORT_COMPLETE, {
        ImportFormat: getFileFormat(items.file.id),
        ImportType: isUpdate ? 'Update' : 'New',
        Items: items.rows.length,
        ...(isUpdate && {
          NewItems: items.rows.filter((row) => !row[itemIdIndex]).length,
        }),
      })
    },
    [trackAnalyticsEvent]
  )

  const trackItemExport = useCallback(
    (format: ItemsExportProperties['ExportFormat']) => {
      trackAnalyticsEvent(AnalyticsEventNames.ITEMS_EXPORT, {
        ExportFormat: format,
      })
    },
    [trackAnalyticsEvent]
  )

  const trackEditItem = useCallback(
    (
      initialValues: Partial<ItemFormFields>,
      values: Partial<ItemFormFields>
    ) => {
      const payload: ItemsEditItemProperties = {
        Category: getOptionalFieldUpdatedValue(
          initialValues.categories?.length,
          values.categories?.length
        ),
        DefaultGST:
          initialValues.taxApplicable !== values.taxApplicable
            ? 'Updated'
            : undefined,
        Description: getOptionalFieldUpdatedValue(
          initialValues.description,
          values.description
        ),
        Name: initialValues.name !== values.name ? 'Updated' : undefined,
        Price: initialValues.price !== values.price ? 'Updated' : undefined,
        SKU: getOptionalFieldUpdatedValue(initialValues.sku, values.sku),
      }

      trackAnalyticsEvent(
        AnalyticsEventNames.ITEMS_EDIT_ITEM,
        reject(isNil)(payload)
      )
    },
    [trackAnalyticsEvent]
  )

  const trackEditCategory = useCallback(
    (
      initialValues: Partial<CategoryFormFields>,
      values: Partial<CategoryFormFields>
    ) => {
      const payload: ItemsEditCategoryProperties = {
        Colour: initialValues.color !== values.color ? 'Updated' : undefined,
        Description: getOptionalFieldUpdatedValue(
          initialValues.description,
          values.description
        ),
        Name: initialValues.name !== values.name ? 'Updated' : undefined,
      }

      trackAnalyticsEvent(
        AnalyticsEventNames.ITEMS_EDIT_CATEGORY,
        reject(isNil)(payload)
      )
    },
    [trackAnalyticsEvent]
  )

  return {
    trackAddCategory,
    trackAddItem,
    trackDeleteAll,
    trackDeleteCategory,
    trackDeleteItem,
    trackEditCategory,
    trackEditItem,
    trackImportCompleted,
    trackImportStarted,
    trackItemExport,
  }
}
