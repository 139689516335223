import { createTranslations } from '@npco/utils-translations'

export const gstDisplayInfoboxtranslations = createTranslations({
  gstDirtyMessage:
    'Item prices will be displayed as GST {GSTStatusIndicator} to customers by default.',
  gstDefaultMessage:
    'Note that changing these settings will affect new Zeller Invoices, but existing Zeller Invoices will not be impacted.',
  included: 'included',
  excluded: 'excluded',
})
