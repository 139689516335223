import { useGetFilterCategoriesLazyQuery } from './graphql/getFilterCategoriesQuery.generated'

export const useGetFilterCategories = (
  hookOptions?: Parameters<typeof useGetFilterCategoriesLazyQuery>[0]
) => {
  const [getQuery, { data, fetchMore, loading: isLoading }] =
    useGetFilterCategoriesLazyQuery(hookOptions)

  const categories = data?.getItemCategories.categories
  const nextToken = data?.getItemCategories.nextToken

  return {
    categories,
    getFilterCategories: getQuery,
    hasMore: Boolean(nextToken),
    isLoading,
    loadMore: fetchMore,
    nextToken,
  }
}
