import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { xeroPaymentServicesConfig } from 'pages/Settings/Connections/connectionConfigs.utils'
import { Connection } from 'pages/Settings/Connections/Connections.types'
import { useConnections } from 'pages/Settings/Connections/hooks/useConnections'

export const useDisplayXeroPaymentServiceConnectionInfo = () => {
  const { userRole } = useLoggedInUser()

  const isAdmin = userRole === CustomerRole.ADMIN
  const { linkedConnections } = useConnections()
  const isPaymentServiceConnected = linkedConnections.some(
    (connection: Connection) => {
      return connection.title === xeroPaymentServicesConfig.title
    }
  )

  return isPaymentServiceConnected && isAdmin
}
