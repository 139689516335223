import { forwardRef, useMemo } from 'react'
import { zIndexMap } from '@npco/zeller-design-system'

import { Rect, useElementRect } from 'hooks/useElementRect'
import { ForwardedProps } from 'utils/ForwardedProps'

export type Padding = {
  top: number
  bottom: number
  left: number
  right: number
}

export const DEFAULT_OVERLAY_STYLE: React.CSSProperties = {
  zIndex: zIndexMap.modalOverlay,
  position: 'absolute',
  background: 'rgba(33,35,34,0.25)',
  transition: 'all 0.3s',
  backdropFilter: 'blur(2px)',
} as const

export const DEFAULT_OVERLAY_SPOTLIGHT_STYLE: React.CSSProperties = {
  backgroundPositionX: 'center',
  backgroundPositionY: 'center',
  backgroundSize: 'cover',
} as const

export const DEFAULT_OVERLAY: React.CSSProperties = {
  ...DEFAULT_OVERLAY_STYLE,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const DEFAULT_SPOTLIGHT_RESIZE_DEBOUNCE_MILLISECONDS: NonNullable<
  ModalOverlayProps['spotlight']
>['resizeDebounceMilliseconds'] = 250

export const DEFAULT_SPOTLIGHT_PADDING: Required<
  NonNullable<ModalOverlayProps['spotlight']>['padding']
> = {
  top: 20,
  bottom: 20,
  left: 20,
  right: 20,
}

export const DEFAULT_SPOTLIGHT_SHADOW_RADIUS: NonNullable<
  ModalOverlayProps['spotlight']
>['shadowRadius'] = 10

export interface ModalOverlayProps {
  spotlight?: {
    selector?: string
    padding?: Partial<Padding>
    shadowRadius?: number | string
    resizeDebounceMilliseconds?: number
    backgroundPath?: string
  }
  children?: React.ReactNode
  className?: string
  defaultOverlayStyle?: React.CSSProperties
  forwardedProps?: ForwardedProps<HTMLDivElement>
}

export const ModalOverlay = forwardRef<HTMLDivElement, ModalOverlayProps>(
  (
    {
      spotlight,
      children,
      className,
      forwardedProps,
      defaultOverlayStyle = DEFAULT_OVERLAY,
    },
    ref
  ) => {
    const spotlightSelector = spotlight?.selector

    const spotlightPadding = spotlight?.padding

    const spotlightShadowRadius =
      spotlight?.shadowRadius ?? DEFAULT_SPOTLIGHT_SHADOW_RADIUS
    const spotlightResizeDebounceMilliseconds =
      spotlight?.resizeDebounceMilliseconds ??
      DEFAULT_SPOTLIGHT_RESIZE_DEBOUNCE_MILLISECONDS

    const spotlightRect = useElementRect(spotlightSelector, {
      resizeDebounceMilliseconds: spotlightResizeDebounceMilliseconds,
    })

    const spotlightBackground = spotlight?.backgroundPath

    const spotlightOverlay = useMemo(() => {
      if (!spotlightRect) {
        return undefined
      }

      const spotlightPaddingTop =
        spotlightPadding?.top ?? DEFAULT_SPOTLIGHT_PADDING.top
      const spotlightPaddingBottom =
        spotlightPadding?.bottom ?? DEFAULT_SPOTLIGHT_PADDING.bottom
      const spotlightPaddingLeft =
        spotlightPadding?.left ?? DEFAULT_SPOTLIGHT_PADDING.left
      const spotlightPaddingRight =
        spotlightPadding?.right ?? DEFAULT_SPOTLIGHT_PADDING.right

      const maskRect: Rect = {
        x: spotlightRect.x - spotlightPaddingLeft,
        y: spotlightRect.y - spotlightPaddingTop,
        width:
          spotlightRect.width + spotlightPaddingLeft + spotlightPaddingRight,
        height:
          spotlightRect.height + spotlightPaddingTop + spotlightPaddingBottom,
      }

      const shadowRadiusResolved =
        typeof spotlightShadowRadius === 'number'
          ? `${spotlightShadowRadius}px`
          : spotlightShadowRadius

      return {
        top: {
          ...DEFAULT_OVERLAY_STYLE,
          top: 0,
          height: Math.max(0, maskRect.y),
          left: 0,
          right: 0,
        } as React.CSSProperties,
        bottom: {
          ...DEFAULT_OVERLAY_STYLE,
          top: maskRect.y + maskRect.height,
          bottom: 0,
          left: 0,
          right: 0,
        } as React.CSSProperties,
        left: {
          ...DEFAULT_OVERLAY_STYLE,
          top: maskRect.y,
          height: maskRect.height,
          left: Math.min(0, maskRect.x),
          width: Math.max(0, maskRect.x),
        } as React.CSSProperties,
        right: {
          ...DEFAULT_OVERLAY_STYLE,
          top: maskRect.y,
          height: maskRect.height,
          left: maskRect.x + maskRect.width,
          right: 0,
        } as React.CSSProperties,
        center: {
          ...DEFAULT_OVERLAY_STYLE,
          background: undefined,
          top: maskRect.y,
          left: maskRect.x,
          width: maskRect.width,
          height: maskRect.height,
          backdropFilter: 'unset',
          ...(spotlightBackground
            ? {
                ...DEFAULT_OVERLAY_SPOTLIGHT_STYLE,
                backgroundImage: `url(${spotlightBackground})`,
              }
            : {
                boxShadow: `rgba(33,35,34,0.25) 0px 0px ${shadowRadiusResolved} ${shadowRadiusResolved} inset`,
              }),
        } as React.CSSProperties,
      }
    }, [
      spotlightRect,
      spotlightPadding,
      spotlightShadowRadius,
      spotlightBackground,
    ])

    return (
      <div ref={ref} className={className} {...forwardedProps}>
        {spotlightOverlay ? (
          <>
            <div style={spotlightOverlay.top} />
            <div style={spotlightOverlay.left} />
            <div style={spotlightOverlay.center} />
            <div style={spotlightOverlay.right} />
            <div style={spotlightOverlay.bottom} />
          </>
        ) : (
          <div style={defaultOverlayStyle} />
        )}
        {children}
      </div>
    )
  }
)
