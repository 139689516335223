import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import {
  DebitCardTransactionStatusV2,
  FundsTransferFrequency,
  FundsTransferPayeeType,
} from '@npco/mp-gql-types'
import { ErrorLogger } from '@npco/utils-error-logger'
import { SendInternalTransfer } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/transfers'

import {
  SendInternalTransfer as SendInternalTransferResponse,
  SendInternalTransferVariables,
} from 'types/gql-types/SendInternalTransfer'

import { UNEXPECTED_ERROR } from '../../../../types/errors'
import { useUpdateAfterZellerTransfer } from '../useUpdateAfterZellerTransfer'

const CURRENCY = 'AUD'

type SendInternalTransferProps = {
  to: string
  from: string
  amountInCents: number
  reference: string
}

export const useSendInternalTransfer = () => {
  const updateAfterZellerTransfer = useUpdateAfterZellerTransfer()

  const [sendTransfer, { loading }] = useMutation<
    SendInternalTransferResponse,
    SendInternalTransferVariables
  >(SendInternalTransfer)

  const sendInternalTransfer = useCallback(
    async ({
      to,
      from,
      amountInCents,
      reference,
    }: SendInternalTransferProps) => {
      try {
        const result = await sendTransfer({
          variables: {
            input: {
              payerAccountUuid: from,
              payeeAccountUuid: to,
              amount: {
                value: Math.round(amountInCents).toString(),
                currency: CURRENCY,
              },
              payeeReference: reference,
              frequency: FundsTransferFrequency.IMMEDIATE,
              payeeType: FundsTransferPayeeType.INTERNAL,
            },
          },
        })

        if (
          !result.data?.transferFundsDebitCardAccount.id ||
          [
            DebitCardTransactionStatusV2.DECLINED,
            DebitCardTransactionStatusV2.CANCELLED,
          ].includes(result.data.transferFundsDebitCardAccount.status)
        ) {
          ErrorLogger.report(
            '[Banking] Mutation response for SendInternalTransfer failed with undefined id or declined/cancelled status'
          )
          return UNEXPECTED_ERROR
        }

        updateAfterZellerTransfer({
          id: result.data.transferFundsDebitCardAccount.id,
          transferInUuid:
            result.data.transferFundsDebitCardAccount.transferInUuid,
          from,
          to,
          amountInCents,
          reference,
        })
        return result.data.transferFundsDebitCardAccount
      } catch (error) {
        ErrorLogger.report('Error on internal transfer:', error)
        return UNEXPECTED_ERROR
      }
    },
    [sendTransfer, updateAfterZellerTransfer]
  )

  return { sendInternalTransfer, sendTransferLoading: loading }
}
