import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetItemImportFileUploadUrlQueryVariables = Types.Exact<{
  fileName: Types.Scalars['String']['input']
  fileMeta: Types.InputMaybe<Types.ItemImportFileMetadata>
}>

export type GetItemImportFileUploadUrlQueryResponse = {
  __typename?: 'Query'
  getItemImportFileUploadUrl: {
    __typename?: 'FileUploadUrl'
    uploadUrl: string
  }
}

export const GetItemImportFileUploadUrl = gql`
  query GetItemImportFileUploadUrl(
    $fileName: String!
    $fileMeta: ItemImportFileMetadata
  ) {
    getItemImportFileUploadUrl(fileName: $fileName, fileMeta: $fileMeta) {
      uploadUrl
    }
  }
` as unknown as TypedDocumentNode<
  GetItemImportFileUploadUrlQueryResponse,
  GetItemImportFileUploadUrlQueryVariables
>

/**
 * __useGetItemImportFileUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetItemImportFileUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemImportFileUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemImportFileUploadUrlQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      fileMeta: // value for 'fileMeta'
 *   },
 * });
 */
export function useGetItemImportFileUploadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetItemImportFileUploadUrlQueryResponse,
    GetItemImportFileUploadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetItemImportFileUploadUrlQueryResponse,
    GetItemImportFileUploadUrlQueryVariables
  >(GetItemImportFileUploadUrl, options)
}
export function useGetItemImportFileUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetItemImportFileUploadUrlQueryResponse,
    GetItemImportFileUploadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetItemImportFileUploadUrlQueryResponse,
    GetItemImportFileUploadUrlQueryVariables
  >(GetItemImportFileUploadUrl, options)
}
export type GetItemImportFileUploadUrlQueryHookResult = ReturnType<
  typeof useGetItemImportFileUploadUrlQuery
>
export type GetItemImportFileUploadUrlLazyQueryHookResult = ReturnType<
  typeof useGetItemImportFileUploadUrlLazyQuery
>
export type GetItemImportFileUploadUrlQueryResult = Apollo.QueryResult<
  GetItemImportFileUploadUrlQueryResponse,
  GetItemImportFileUploadUrlQueryVariables
>
