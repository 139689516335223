import { gql } from '@apollo/client'

export const GetRecentlyUsedLineItems = gql`
  query GetRecentlyUsedLineItems {
    getRecentlyUsedLineItems {
      __typename
      catalogItem {
        id
      }
      description
      id
      name
      price
      taxes {
        enabled
      }
    }
  }
`
