import {
  CardScheme,
  Source,
  SourceFilter,
  ThreeDSOutcome,
} from '@npco/mp-gql-types'
import { COLOR } from '@npco/zeller-design-system'

import { cardSchemaDescription } from 'const/payment-providers'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { GetTransactions_getTransactions_transactions as Transaction } from 'types/gql-types/GetTransactions'
import { component } from 'translations'

import { translations } from './TransactionDetails.i18n'

const NO_RECORD_ERROR = 'no-record'

export const getCardDetails = ({
  scheme,
  maskedPan,
}: {
  scheme: CardScheme
  maskedPan: string | null
}) => {
  if (maskedPan) {
    const re = /\d{4}/
    const match = re.exec(maskedPan)
    const schema = cardSchemaDescription[scheme]

    if (maskedPan === NO_RECORD_ERROR || !match) {
      return component.transaction.error
    }
    return `${schema} ••• ${maskedPan.slice(-4)}`
  }
  return scheme
}

export const getTransactionSource = (source: Source | null) => {
  switch (source) {
    case Source.DASHBOARD:
    case Source.PAY_BY_LINK:
    case Source.VIRTUAL_TERMINAL:
      return component.transaction.details.sources.dashboard
    case Source.MOBILE_PHONE:
      return component.transaction.details.sources.mobile
    case Source.LINKLY:
    case Source.HL_POS:
    case Source.ORACLE_POS:
      return component.transaction.details.sources.pos
    case Source.QUICKPOS:
      return component.transaction.details.sources.quickPos
    case Source.ZELLER_INVOICE:
      return component.transaction.details.sources.zellerInvoices
    case Source.XERO_INVOICE:
      return component.transaction.details.sources.xeroInvoices
    case Source.STANDALONE:
    default:
      return component.transaction.details.sources.terminal
  }
}

export const getTransactionSourceValue = (
  sourceFilter: SourceFilter | null,
  source: Source | null
) => {
  if (source === Source.ZELLER_POS) {
    return translate('component.transaction.details.sources.posLite')
  }

  switch (sourceFilter) {
    case SourceFilter.INVOICE:
      return getTransactionSource(source)
    case SourceFilter.POINT_OF_SALE:
      return translate('component.transaction.details.sources.pos')
    case SourceFilter.ZELLER_APP:
      return translate('component.transaction.details.sources.mobile')
    case SourceFilter.DASHBOARD:
      return translate('component.transaction.details.sources.dashboard')
    case SourceFilter.TERMINAL:
      return translate('component.transaction.details.sources.terminal')
    case SourceFilter.ZELLER_ADMIN:
      return translate('component.transaction.details.sources.zellerAdmin')
    default:
      return getTransactionSource(source)
  }
}

export const isLinklyPosTransaction = (data: Transaction) => {
  return data.source === Source.LINKLY
}

export const isOraclePosTransaction = (data: Transaction) => {
  return data.source === Source.ORACLE_POS
}

export const isVirtualTerminalOrPayByLinkTransaction = (source?: Source) => {
  return source === Source.VIRTUAL_TERMINAL || source === Source.PAY_BY_LINK
}

export const isHLPosTransaction = (data: Transaction) => {
  return data.source === Source.HL_POS
}

export const isPosLiteTransaction = (source?: Source) => {
  return source === Source.ZELLER_POS
}

export const isInvoiceTransaction = (data: Transaction) => {
  return (
    data.source === Source.ZELLER_INVOICE || data.source === Source.XERO_INVOICE
  )
}

export const getInvoiceExternalReferenceUrl = (
  source: Source | null,
  externalReference: string,
  externalReferenceUrl: string | null
) => {
  return source === Source.XERO_INVOICE
    ? externalReferenceUrl ?? ''
    : ROOT.PORTAL.INVOICING.INVOICES.INVOICE(externalReference).path
}

export const getThreeDSOutcomeVariant = (
  threeDSOutcome?: ThreeDSOutcome | null
) => {
  switch (threeDSOutcome) {
    case ThreeDSOutcome.PASSED:
      return {
        text: translations.DEFAULT.passed,
        color: COLOR.GREEN_1600,
      }
    case ThreeDSOutcome.FAILED:
      return {
        text: translations.DEFAULT.failed,
        color: COLOR.BLACK,
      }
    default:
      return {
        text: '-',
        color: COLOR.BLACK,
      }
  }
}
